import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Colors } from "sigil";
// todo extract this to common components and merge with ListItemNavLink
export const StyledListItem = withStyles((theme) => ({
  root: {
    color: theme.palette.text.hint,
    borderLeft: "2px solid transparent",

    paddingTop: 9,
    paddingBottom: 9,
    marginTop: 6,
    marginBottom: 6,

    "&$selected, &$selected:hover, &$selected:focus": {
      backgroundColor: Colors.rustLightest,
      color: theme.palette.primary.main,
      borderLeft: `2px solid ${Colors.rustLighter}`,
    },

    "&:hover": {
      backgroundColor: Colors.rustLightest,
    },

    "&:focus": {
      backgroundColor: Colors.rustLightest,
    },
  },
  selected: {},
}))(ListItem);

export const StyledListItemText = withStyles({
  root: {
    display: "flex",
    alignItems: "center",
  },
  primary: {
    color: "inherit",
  },
  secondary: {
    order: -1,
    paddingRight: 14,
    color: "inherit",
    opacity: 0.5,
  },
})(ListItemText);

export const StyledListItemIcon = withStyles({
  root: {
    marginRight: 0,
  },
})(ListItemIcon);
