import React, { useState } from "react";
import { gql } from "@apollo/client";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { MotorInputsTableRow } from "./row";
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { MotorInputsTableRow_baselineMotor } from "generated-gql-types/MotorInputsTableRow_baselineMotor";
import { MotorInputsTable_site } from "generated-gql-types/MotorInputsTable_site";
import { MotorInputsTable } from "./MotorInputsTable";
import { useUpdateBaselineMotorMutation } from "modules/common/mutations/UpdateBaselineMotorMutation";
import { useSnackbar } from "notistack";
import {
  ApplicationType,
  EfficiencyType,
  UpdateBaselineMotorInput,
} from "generated-gql-types/globalTypes";
import { useIsMounted } from "modules/common/hooks";
import { isNone } from "modules/common/utils/guards";
import {
  MotorSetupDialog_site,
  MotorSetupDialog_site_baselineMotors,
} from "generated-gql-types/MotorSetupDialog_site";
import { isSiteSetup, SiteSetupDialog } from "../SiteSetup";

const isBaselineMotorEqual = (
  bm1: MotorInputsTableRow_baselineMotor,
  bm2?: MotorInputsTableRow_baselineMotor
) => {
  return (
    bm2 &&
    Object.keys(bm1).every(
      (key) => key === "motor" || (!bm1[key] && !bm2[key]) || bm1[key] === bm2[key]
    )
  );
};

// The mutation doesn't take null for an answer
const isBaselineMotorValid = (baselineMotor: MotorInputsTableRow_baselineMotor) =>
  !!(
    baselineMotor.motor?.id &&
    baselineMotor.voltage &&
    baselineMotor.maxSpeed &&
    baselineMotor.motorPower &&
    !isNone<number>(baselineMotor.tonnage) &&
    baselineMotor.efficiencyType &&
    baselineMotor.applicationType
  );

const getChangedMotors = (
  site?: MotorInputsTable_site,
  baselineMotors?: MotorInputsTableRow_baselineMotor[]
) => {
  const changedMotors: MotorInputsTableRow_baselineMotor[] = [];
  baselineMotors &&
    site &&
    baselineMotors.forEach((baselineMotor) => {
      if (
        !isBaselineMotorEqual(
          baselineMotor,
          site.baselineMotors?.find(
            (siteBaselineMotor) => siteBaselineMotor.id === baselineMotor.id
          )
        )
      ) {
        changedMotors.push(baselineMotor);
      }
    });
  return changedMotors;
};

const convertMotor = (baselineMotor: MotorInputsTableRow_baselineMotor) => {
  const updateBaselineMotorInput: UpdateBaselineMotorInput = {
    motorId: baselineMotor.motor.id,
    voltage: baselineMotor.voltage || 0,
    maxSpeed: baselineMotor.maxSpeed || 0,
    motorPower: baselineMotor.motorPower || 0,
    tonnage: baselineMotor.tonnage || 0,
    efficiencyType: baselineMotor.efficiencyType || EfficiencyType.STANDARD,
    applicationType: baselineMotor.applicationType || ApplicationType.SUPPLY_FAN,
    replacedAt: baselineMotor.replacedAt || null,
  };
  return updateBaselineMotorInput;
};

export interface MotorInputsDialogProps {
  site: MotorSetupDialog_site;
}

export const MOTOR_SETUP_BUTTON_LABEL = "MOTOR SETUP";
export const MOTOR_SETUP_PROMPT =
  "Enter baseline motor details to calculate Lifetime Energy Savings from Turntide install date";

export function MotorSetupDialogComponent({ site }: MotorInputsDialogProps) {
  const [open, setOpen] = useState(
    isSiteSetup(site) && site.baselineMotors?.length === 0 && site.motors?.length > 0
  );
  const [motorSaveEnabled, setMotorSaveEnabled] = useState(false);
  const [baselineMotors, setBaselineMotors] = useState<MotorSetupDialog_site_baselineMotors[]>(
    site?.baselineMotors as MotorSetupDialog_site_baselineMotors[]
  );
  const { enqueueSnackbar } = useSnackbar();
  const updateMotor = useUpdateBaselineMotorMutation();
  const isMounted = useIsMounted();

  const onSave = async () => {
    const changedMotors = getChangedMotors(site, baselineMotors);
    if (changedMotors.length) {
      try {
        // Trigger one mutation per row
        await Promise.all(
          changedMotors.map((changedMotor) => updateMotor(convertMotor(changedMotor)))
        );
        requestAnimationFrame(() => {
          if (isMounted.current) {
            enqueueSnackbar(`Saved ${changedMotors.length} motors`, { variant: "success" });
            setMotorSaveEnabled(false);
            setOpen(false);
          }
        });
      } catch (e: any) {
        enqueueSnackbar(`Error saving motor setup: ${e.message || e}`, {
          variant: "error",
        });
      }
    }
  };

  return (
    <>
      {baselineMotors && (
        <Dialog open={!!open} onClose={() => setOpen(false)} maxWidth="xl" fullWidth>
          <DialogTitle>Motor Setup</DialogTitle>
          <DialogContent>
            <Box ml={1} mb={2}>
              {MOTOR_SETUP_PROMPT}
            </Box>
            <MotorInputsTable
              site={site}
              baselineMotors={baselineMotors}
              onUpdateBaselineMotor={(updatedMotor) => {
                const slice = baselineMotors.slice();
                const index = slice.findIndex(
                  (baselineMotor) => updatedMotor.motor.id === baselineMotor.motor.id
                );
                if (index > -1) {
                  slice[index] = updatedMotor;
                } else {
                  slice.push(updatedMotor);
                }
                setBaselineMotors(slice as MotorInputsTableRow_baselineMotor[]);
                setMotorSaveEnabled(
                  slice.reduce(
                    (
                      previousValue: boolean,
                      currentValue: MotorSetupDialog_site_baselineMotors
                    ): boolean => previousValue && isBaselineMotorValid(currentValue),
                    true
                  )
                );
              }}
              disabled={!site?.viewerIsAdmin}
            />
          </DialogContent>
          <Grid
            container
            direction="row"
            pr={2}
            pb={1}
            pt={2}
            textAlign="right"
            width="100%"
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => onSave()}
              disabled={!motorSaveEnabled}
              sx={{ width: 164, marginBottom: 1 }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              onClick={() => setOpen(false)}
              disabled={false}
              sx={{ width: 164, marginLeft: "8px", marginBottom: 1 }}
            >
              Cancel
            </Button>
          </Grid>
        </Dialog>
      )}
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={() => setOpen(true)}
        title={MOTOR_SETUP_PROMPT}
      >
        {MOTOR_SETUP_BUTTON_LABEL}
      </Button>
    </>
  );
}

export const MotorSetupDialog = createFragmentContainer(MotorSetupDialogComponent, {
  site: gql`
    fragment MotorSetupDialog_site on Site {
      id
      viewerIsAdmin
      motors {
        id
        ...MotorInputsTableRow_motor
      }
      baselineMotors {
        id
        ...MotorInputsTableRow_baselineMotor
      }
      ...SiteSetupDialog_site
    }
    ${MotorInputsTableRow.fragments.motor}
    ${MotorInputsTableRow.fragments.baselineMotor}
    ${SiteSetupDialog.fragments.site}
  `,
});
