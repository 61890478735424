import * as React from "react";
import { useCallback } from "react";
import {
  ListSubheader,
  Menu,
  MenuItem,
  Box,
  IconButton,
  MenuItemProps,
  IconButtonProps,
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import * as H from "history";
import { withRouter, RouteComponentProps } from "react-router-dom";

export const ActionMenu = withStyles((_) => ({
  paper: {
    borderBottom: `2px solid #FCBDB2`, // fixme: this color should be in the palette
  },
}))(Menu);

export const ActionMenuItem = withStyles((_) => ({
  root: {
    flexDirection: "row-reverse",
  },
}))(MenuItem);

interface ActionMenuItemLinkProps extends MenuItemProps {
  to: H.Path;
  button?: true;
}

const ActionMenuLinkComponent = (props: RouteComponentProps & ActionMenuItemLinkProps) => {
  const { to, history, location, match, staticContext, ...other } = props;

  const navigate = useCallback(() => {
    history.push(to);
  }, [to, history]);

  return <ActionMenuItem onClick={navigate} {...other} />;
};

export const ActionMenuItemLink = withRouter(ActionMenuLinkComponent);

export const ActionMenuSubheader = withStyles((theme) => ({
  root: {
    textAlign: "right",
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: 0,
    color: theme.palette.text.hint,
  },
}))(ListSubheader);

export const ActionMenuButton = (props: IconButtonProps) => (
  <Box color="text.disabled">
    <IconButton color="inherit" {...props}>
      <i className="fas fa-ellipsis-v fa-xs" style={{ width: "1em" }} />
    </IconButton>
  </Box>
);
