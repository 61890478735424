export const MuiPaper = {
  root: {
    // border: "1px solid #e8e8e8",
  },

  // default elevation for cards
  elevation1: {
    boxShadow: ["0px 1px 3px rgba(0, 0, 0, 0.08)", "0px 5px 10px rgba(0, 0, 0, 0.03)"].join(", "),
  },

  // raised elevation for cards
  elevation8: {
    boxShadow: ["0px 2px 6px rgba(0, 0, 0, 0.2)", "0px 5px 16px rgba(0, 0, 0, 0.05)"].join(", "),
  },
};
