import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { Typography } from "@material-ui/core";
import { autocompleteStyles } from "modules/site-manager/components/MultiSelectAutocomplete/MultiSelectAutocomplete";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";

// Type Guard for SearchOption
const isSearchOption = (test: any): test is SearchOption => {
  return test.value !== undefined || test.label !== undefined;
};

export interface SearchOption {
  value: string;
  label: string;
}

export interface SortOption {
  comparator: (a: any, b: any) => number;
  label: string;
}

export interface SearchBarProps {
  searchOptions: SearchOption[];
  onSearch: (query: string) => void;
  sortOptions?: SortOption[];
  onSort?: { (index: number, sortAscending: boolean): void };
  backgroundColor?: string;
}

export const SearchBar = ({
  searchOptions,
  onSearch,
  sortOptions,
  onSort,
  backgroundColor = "rgb(240, 240, 240)",
}: SearchBarProps) => {
  const [query, setQuery] = useState("");
  const [value, setValue] = useState<SearchOption | string | null>("");
  const [sort, setSort] = useState<number>(1);
  const [sortAscending, setSortAscending] = useState(true);

  const classes = autocompleteStyles();

  return (
    <FormControl fullWidth>
      <Grid container alignItems="center" justifyContent="space-between" p={2}>
        <Grid item xs={12} sm={true} pr={2}>
          <Autocomplete
            options={searchOptions}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              return option.label;
            }}
            value={value}
            autoComplete
            freeSolo
            inputValue={query}
            onInputChange={(e, value, reason) => {
              const searchTerm = value ?? "";
              setQuery(searchTerm);
              onSearch(searchTerm);
            }}
            onChange={(e, value, reason) => {
              const searchTerm = (value && isSearchOption(value) ? value.value : value) || "";
              setValue(value);
              onSearch(searchTerm);
            }}
            sx={{ ...classes.autocomplete }}
            selectOnFocus
            clearOnBlur={false}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Search..."
                sx={{
                  border: "0px solid transparent",
                  backgroundColor: { backgroundColor },
                  color: "black",
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.value}>
                <Typography style={{ fontWeight: selected ? 700 : 500 }}>{option.label}</Typography>
              </li>
            )}
          />
        </Grid>
        {sortOptions?.length && (
          <>
            <Grid item xs="auto">
              <Typography>Sort By:</Typography>
            </Grid>
            {sortOptions?.map((sortOption, index) => (
              <Grid item container xs="auto" key={sortOption.label}>
                <Button
                  onClick={() => {
                    if (sort === index) {
                      setSortAscending(!sortAscending);
                      onSort && onSort(index, !sortAscending);
                    } else {
                      setSort(index);
                      onSort && onSort(index, sortAscending);
                    }
                  }}
                >
                  <Typography style={{ fontWeight: sort === index ? 900 : 500 }}>
                    {sortOption.label}
                  </Typography>
                </Button>
                {sort === index && (
                  <IconButton
                    onClick={() => {
                      setSortAscending(!sortAscending);
                      onSort && onSort(sort, !sortAscending);
                    }}
                    style={{ paddingLeft: 0 }}
                  >
                    {sortAscending ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                  </IconButton>
                )}
              </Grid>
            )) || null}
          </>
        )}
      </Grid>
    </FormControl>
  );
};
