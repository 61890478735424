import React from "react";
import { Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { TooltipProps } from "recharts";
import { Typography } from "@material-ui/core";
import { CO2E, KWH, UTILITY_COST } from "modules/site-manager/constants";
import { Colors } from "sigil/src";
import { BOXED_CARD_STYLE } from "../../utils/cardStyles";
import { formatNumber } from "modules/site-manager/utils/chartUtils";

const TOOLTIP_LABEL_MAP = {
  baselineKwh: {
    label: "Baseline Power",
    currency: "",
    units: KWH,
  },
  turntideKwh: {
    label: "Turntide Power",
    currency: "",
    units: KWH,
  },
  baselineUtilityCost: {
    label: `Baseline ${UTILITY_COST}`,
    currency: "USD",
    units: "",
  },
  turntideUtilityCost: {
    label: `Turntide ${UTILITY_COST}`,
    currency: "USD",
    units: "",
  },
  baselineCo2eEmissions: {
    label: `Baseline ${CO2E}`,
    currency: "",
    units: "tons",
  },
  turntideCo2eEmissions: {
    label: `Turntide ${CO2E}`,
    currency: "",
    units: "tons",
  },
};

const MAX_UNABBREVIATED_VALUE = 10000;

export const MonthlyBarChartTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<string, string>) => {
  if (active && payload && payload.length) {
    const firstSeries = payload[0].payload;
    return (
      <Grid
        container
        p={2}
        sx={{
          ...BOXED_CARD_STYLE,
          border: `1px solid ${Colors.dust}`,
        }}
      >
        <Grid item xs={12}>
          <Typography>
            {firstSeries ? `${firstSeries.label} ${firstSeries.year}` : label}
          </Typography>
        </Grid>
        <Table size="small">
          <TableBody>
            {payload.map((series) => (
              <TableRow
                key={series.dataKey}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={{ padding: 0, width: "90px", minWidth: 0 }}>
                  <Typography variant="caption">{`${
                    series.dataKey && TOOLTIP_LABEL_MAP[series.dataKey].label
                  }:`}</Typography>
                </TableCell>
                <TableCell align="right" sx={{ padding: 0, width: "48px", minWidth: 0 }}>
                  {series.dataKey && (
                    <Typography variant="caption">{`${
                      typeof series.value === "number" &&
                      formatNumber(
                        series.value,
                        MAX_UNABBREVIATED_VALUE,
                        series.value < MAX_UNABBREVIATED_VALUE &&
                          TOOLTIP_LABEL_MAP[series.dataKey].currency
                          ? 2
                          : 0,
                        series.value < MAX_UNABBREVIATED_VALUE &&
                          TOOLTIP_LABEL_MAP[series.dataKey].currency
                          ? 2
                          : 1,
                        TOOLTIP_LABEL_MAP[series.dataKey].currency
                      )
                    } ${TOOLTIP_LABEL_MAP[series.dataKey].units}`}</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    );
  }

  return null;
};
