import React, { useEffect, useState } from "react";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import { ListItemNavLink } from "modules/common/components/ListItemNavLink";
import classNames from "classnames";
import { Colors } from "sigil";
import { useHistory } from "react-router-dom";
import { BottomNavigationAction } from "@mui/material";

type ToggleNavButtonProps = {
  caption: string;
  sitesTo: string;
  organizationsTo: string;
  sitesIconName: string;
  organizationsIconName: string;
};

// The Sites | Org button should behave as follows:
// If we're on /sites specifically, show the map icon and link to /organizations
// if we're on any /organizations route, show the building icon and link to /sites
// otherwise, show the current icon and link to the corresponding, last-viewed route
const ToggleNavButtonComponent = ({
  caption,
  sitesTo,
  organizationsTo,
  sitesIconName,
  organizationsIconName,
  classes,
}: ToggleNavButtonProps & WithStyles) => {
  const history = useHistory();
  const path = history.location.pathname;
  const isSitesPage = path === sitesTo;
  const isOrganizationsPage = path.includes(organizationsTo);
  const [savedIconName, setSavedIconName] = useState(
    isSitesPage ? sitesIconName : organizationsIconName
  );

  const to = isSitesPage
    ? organizationsTo
    : isOrganizationsPage
    ? sitesTo
    : savedIconName === sitesIconName
    ? sitesTo
    : organizationsTo;
  const iconName = isSitesPage
    ? sitesIconName
    : isOrganizationsPage
    ? organizationsIconName
    : savedIconName;
  const iconClasses = classNames("fas", `fa-${iconName}`, classes.icon);

  useEffect(() => {
    setSavedIconName(iconName);
  }, [iconName]);

  return (
    <div className={classes.root}>
      <ListItemNavLink
        to={to}
        dense={false}
        exact={false}
        classes={{ root: classes.navlink }}
        onClick={() => {
          if (isSitesPage) {
            setSavedIconName(organizationsIconName);
          } else if (isOrganizationsPage) {
            setSavedIconName(sitesIconName);
          }
        }}
      >
        {iconName && <i className={iconClasses} />}
        {caption}
      </ListItemNavLink>
    </div>
  );
};

export const ToggleNavButton = withStyles((_) => ({
  root: {
    marginRight: 10,
  },
  navlink: {
    borderLeft: "none",
    fontSize: "14px",
    fontWeight: 500,
    paddingLeft: 24,
    paddingRight: 24,
    justifyContent: "center",
    minWidth: "110px",

    "a.active > &": {
      border: `1px solid ${Colors.ttBlue}`,
    },
  },
  icon: {
    paddingRight: 6,
    marginTop: 2, // line height does nothing for us since icon is higher than line
    "a.active > div > &": {
      color: Colors.ttBlue,
    },
  },
}))(ToggleNavButtonComponent);

type ToggleBottomNavigationActionProps = {
  label: string;
  sitesTo: string;
  organizationsTo: string;
  sitesIcon: JSX.Element;
  organizationsIcon: JSX.Element;
  handleChange: (event: React.SyntheticEvent, newValue: string) => void;
};

// The Sites | Org button should behave as follows:
// If we're on /sites specifically, show the map icon and link to /organizations
// if we're on any /organizations route, show the building icon and link to /sites
// otherwise, show the current icon and link to the corresponding, last-viewed route
export const ToggleBottomNavigationAction = ({
  label,
  sitesTo,
  organizationsTo,
  sitesIcon,
  organizationsIcon,
  handleChange,
}: ToggleBottomNavigationActionProps) => {
  const history = useHistory();
  const path = history.location.pathname;
  const isSitesPage = path === sitesTo;
  const isOrganizationsPage = path.includes(organizationsTo);
  const [isSites, setIsSites] = useState(isSitesPage);

  const to = isSitesPage
    ? organizationsTo
    : isOrganizationsPage
    ? sitesTo
    : isSites
    ? sitesTo
    : organizationsTo;
  const icon = isSitesPage
    ? sitesIcon
    : isOrganizationsPage
    ? organizationsIcon
    : isSites
    ? sitesIcon
    : organizationsIcon;

  useEffect(() => {
    setIsSites(isSitesPage || (!isOrganizationsPage && isSites));
  }, [isSitesPage, isOrganizationsPage, isSites]);

  return (
    <BottomNavigationAction
      label={label}
      value={to}
      icon={icon}
      showLabel={true}
      onClick={(e) => handleChange(e, to)}
    />
  );
};
