import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { OrganizationSitesTable } from "./OrganizationSitesTable";
import { autocompleteStyles } from "modules/site-manager/components/MultiSelectAutocomplete/MultiSelectAutocomplete";
import { gql, useQuery } from "@apollo/client";
import { Typography } from "@material-ui/core";
import { GetViewerOrganizations } from "generated-gql-types/GetViewerOrganizations";
import {
  Organization,
  Organization_node_Organization,
  Organization_node_Organization_sites_edges_node,
} from "generated-gql-types/Organization";
import { labelFontStyle } from "global-styles";
import { SOFT_BOX_SHADOW, BOXED_CARD_STYLE } from "../../utils/cardStyles";
import { SearchOption } from "modules/site-manager/components/SearchBar";
import { pluralize } from "modules/common/utils/pluralize";
import { unmarshalStringId } from "modules/common/utils/relay";
import { OrganizationMonthlySavingsChart } from "./OrganizationMonthlySavingsChart";
import { EnergySavingsComponent } from "modules/site-manager/components/EnergySavingsComponent";
import {
  SiteKpiSet,
  KpiCardProps,
  getEmptyOrgKpis,
  hydrateSiteSavingsKpis,
  hydrateSiteCarbonKpis,
} from "../Site/SiteEnergySavings/EnergySavings/kpis";
import { isNone } from "modules/common/utils/guards";
import { hasUtilityRate } from "../Site/SiteEnergySavings/SiteSetup";

const viewerOrganizationsQuery = gql`
  query GetViewerOrganizations {
    viewer {
      id
      organizations(first: 500) {
        nodes {
          id
          name
          viewerIsAdmin
        }
      }
    }
  }
`;

const organizationQuery = gql`
  query Organization($orgId: ID!, $first: Int) {
    node(id: $orgId) {
      id
      ... on Organization {
        ...OrganizationSitesTable_organization
        ...OrganizationMonthlySavingsChart_org
        energySavings {
          id
          baselineKwh
          turntideKwh
          utilitySavings
          co2eEmissionsReduction
        }
      }
    }
  }
  ${OrganizationSitesTable.fragments.organization}
  ${OrganizationMonthlySavingsChart.fragments.organization}
`;

const findEnergySavingsSite = (org: Organization_node_Organization) => {
  const sites = org?.sites?.edges;
  if (sites) {
    const carbonEnabledSite = org?.sites?.edges.find(
      (edge) => edge.node.energySavingsEnabled && edge.node.co2eGPerKwh
    )?.node;
    if (carbonEnabledSite) {
      return carbonEnabledSite;
    }
    return org?.sites?.edges.find((edge) => edge.node.energySavingsEnabled)?.node;
  }
  return undefined;
};

export const TURNTIDE_ORGANIZATION_ID = "15813564-a390-0e10-992e-cd64f1e61178";

// OrganizationDashboard displays:
// - a dropdown for choosing among the authenticated user's organizations,
//   defaulting to the one provided in props, if any
// - energy savings KPIs for the organization
// - a monthly energy savings chart for the organization
export const OrganizationDashboard = (props: { orgId?: string }) => {
  const {
    data,
    loading: loadingViewerOrgs,
    error: viewerError,
  } = useQuery<GetViewerOrganizations>(viewerOrganizationsQuery);
  const [selectedOrg, setSelectedOrg] = useState<string>(props.orgId || "");
  const {
    data: organizationData,
    loading: loadingOrg,
    error: orgError,
  } = useQuery<Organization>(organizationQuery, {
    variables: { orgId: selectedOrg, first: 500 },
    skip: !selectedOrg,
  });
  const [organizationOptions, setOrganizationOptions] = useState<SearchOption[]>([]);
  const [organization, setOrganization] = useState<
    Organization_node_Organization | null | undefined
  >();
  const [kpiSet, setKpiSet] = useState<SiteKpiSet>(SiteKpiSet.SAVINGS);
  const [kpis, setKpis] = useState<KpiCardProps[]>(getEmptyOrgKpis(kpiSet));
  const [energySavingsSite, setEnergySavingsSite] = useState<
    Organization_node_Organization_sites_edges_node | undefined
  >(undefined);

  const classes = autocompleteStyles();

  // Effect 1: populate organization dropdown
  useEffect(() => {
    if (loadingViewerOrgs || viewerError) {
      return;
    }
    setOrganizationOptions(
      data?.viewer?.organizations?.nodes?.map(({ id, name }) => ({ value: id, label: name })) || []
    );
    if (!selectedOrg && !organization && data?.viewer?.organizations?.nodes) {
      if (data.viewer.organizations.nodes.length === 1) {
        setSelectedOrg(data.viewer.organizations.nodes[0].id);
      } else {
        const turntide = data.viewer.organizations.nodes.find(
          ({ id }) => unmarshalStringId(id) === TURNTIDE_ORGANIZATION_ID
        );
        if (turntide) {
          setSelectedOrg(turntide.id);
        }
      }
    }
  }, [loadingViewerOrgs, viewerError, data, selectedOrg, organization]);

  // Effect 2: query selected organization's sites
  useEffect(() => {
    if (loadingOrg || orgError) {
      setEnergySavingsSite(undefined);
      return;
    }
    const org = organizationData?.node as Organization_node_Organization;
    setOrganization(org);
    setEnergySavingsSite(findEnergySavingsSite(org));
  }, [loadingOrg, orgError, organizationData]);

  // Effect 3: Load energy savings for the org
  useEffect(() => {
    if (organization) {
      const blankKpis = getEmptyOrgKpis(kpiSet);
      const hydratedKpis =
        kpiSet === SiteKpiSet.SAVINGS
          ? hydrateSiteSavingsKpis(organization, !!energySavingsSite, blankKpis)
          : hydrateSiteCarbonKpis(
              organization,
              !!energySavingsSite,
              !isNone(energySavingsSite?.co2eGPerKwh),
              blankKpis
            );
      setKpis(hydratedKpis);
    }
  }, [organization, kpiSet, energySavingsSite]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      style={{ overflowY: "auto", overflowX: "hidden", width: "100%" }}
      justifyContent="center"
      mb={isMobile ? 8 : 1}
    >
      <Grid
        item
        container
        xs={12}
        sm={10}
        ml={0}
        mt={2}
        style={{
          minWidth: "200px",
          maxWidth: "1200px",
          padding: "0px 8px 0px 8px ",
        }}
      >
        <Grid item container xs={12} sx={SOFT_BOX_SHADOW} mb={2}>
          <Grid
            item
            container
            xs={12}
            sx={{ ...BOXED_CARD_STYLE, borderRadius: "3px" }}
            p={1}
            justifyContent="stretch"
            alignItems="flex-start"
          >
            <Autocomplete
              title="Organization"
              options={organizationOptions}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                return option.label;
              }}
              isOptionEqualToValue={(option: SearchOption, value: SearchOption): boolean =>
                option?.value === value?.value
              }
              autoComplete
              onChange={(e, value, reason) => {
                let orgId: string;
                if (typeof value === "string") {
                  orgId = value || "";
                } else {
                  orgId = value?.value || "";
                }
                if (orgId) {
                  // don't clear the page just because someone clears the search bar
                  setSelectedOrg(orgId);
                }
              }}
              sx={{ ...classes.autocomplete, width: "100%" }}
              selectOnFocus
              clearOnBlur={false}
              renderInput={(params) => (
                <TextField {...params} size="small" placeholder="Select Organization..." />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <Typography style={{ fontWeight: selected ? 700 : 500 }}>
                    {option.label}
                  </Typography>
                </li>
              )}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} sx={SOFT_BOX_SHADOW} mb={2} mt={1}>
          {organization && (
            <Grid
              item
              container
              xs={12}
              sx={{ ...BOXED_CARD_STYLE, borderRadius: "4px" }}
              pl={4}
              pr={4}
              pt={1}
              pb={1}
              justifyContent="stretch"
              alignItems="center"
            >
              <Grid item xs>
                <Typography variant="h1" style={{ fontFamily: "Barlow" }}>
                  {organization.name}
                </Typography>
              </Grid>
              <Grid item xs="auto">
                {loadingOrg && (
                  <Grid item xs={12}>
                    <CircularProgress size={28} />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" style={{ ...labelFontStyle }}>
                  {`${organization.sites?.totalCount || 0} ${pluralize(
                    organization.sites?.totalCount || 0
                  )("Site", "Sites")}`}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        {(loadingViewerOrgs || (loadingOrg && !organization)) && (
          <Grid item xs={12} p={2}>
            <CircularProgress size={28} />
          </Grid>
        )}
        {organization && (
          <>
            <Grid item xs={12} md={energySavingsSite ? 7 : 12}>
              <OrganizationSitesTable organization={organization} key={organization.id} />
            </Grid>
            {energySavingsSite && (
              <>
                <Grid
                  item
                  container
                  xs={12}
                  md={5}
                  pl={isMobile ? 0 : 2}
                  alignContent={"flex-start"}
                >
                  <Grid item mb={2} xs={12}>
                    <EnergySavingsComponent
                      site={organization}
                      kpis={kpis}
                      kpiSet={kpiSet}
                      setKpiSet={setKpiSet}
                    />
                  </Grid>
                  <Grid item mb={2} xs={12}>
                    <OrganizationMonthlySavingsChart
                      organization={organization}
                      hasUtilityRate={
                        !!organization.energySavings?.utilitySavings ||
                        hasUtilityRate(energySavingsSite || organization.sites.edges[0])
                      }
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};
