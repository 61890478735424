import { gql } from "@apollo/client";
import {
  SubscribeToSiteAlertsInput,
  UnsubscribeFromSiteAlertsInput,
} from "generated-gql-types/globalTypes";
import { SubscribeToSiteAlerts } from "generated-gql-types/SubscribeToSiteAlerts";
import { UnsubscribeFromSiteAlerts } from "generated-gql-types/UnsubscribeFromSiteAlerts";
import { composeMutationHook } from "./helpers";

const subscribeMutation = gql`
  mutation SubscribeToSiteAlerts($input: SubscribeToSiteAlertsInput!) {
    response: subscribeToSiteAlerts(input: $input) {
      subscribed
      site {
        id
      }
    }
  }
`;

const useSubscribeToSiteAlertsMutation = composeMutationHook<
  SubscribeToSiteAlerts,
  SubscribeToSiteAlertsInput
>(subscribeMutation);

const unsubscribeMutation = gql`
  mutation UnsubscribeFromSiteAlerts($input: UnsubscribeFromSiteAlertsInput!) {
    response: unsubscribeFromSiteAlerts(input: $input) {
      subscribed
      site {
        id
      }
    }
  }
`;

const useUnsubscribeFromSiteAlertsMutation = composeMutationHook<
  UnsubscribeFromSiteAlerts,
  UnsubscribeFromSiteAlertsInput
>(unsubscribeMutation);

export { useSubscribeToSiteAlertsMutation, useUnsubscribeFromSiteAlertsMutation };
