import * as React from "react";
import { render } from "react-dom";
import { Provider } from "mobx-react";
import { Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { ApolloProvider, ApolloClient, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/link-context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { createUploadLink } from "third-party/apollo-upload-client";
import urljoin from "url-join";
import possibleTypes from "./generated-gql-types/fragmentTypes.json";
import * as FullStory from "@fullstory/browser";

import {
  AuthProvider,
  AuthService,
  Logout,
  OAuthCallback,
  PrivateRoute,
} from "modules/common/auth";
import { APIStore, ModelsStore, StoreRegistry } from "modules/common/stores";
import { APIClient } from "./modules/common/api";
import Root from "modules/root/components/Root";
import { GlobalStyles } from "./global-styles";
import { SmcMuiTheme } from "./theme";
import { StyledSnackbarProvider } from "./theme/components/StyledSnackbarProvider";
import { ServerContext } from "modules/common/types";
import { configure, action } from "mobx";
import { printError } from "graphql";

// set up mapbox
import mapboxgl from "mapbox-gl";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN!;

// mobx enforceActions prevents altering observed properties outside of actions
configure({ enforceActions: "always" });

const context: ServerContext = {
  apiHost: window.APP_CONFIG.API_HOST,
  graphqlHost: window.APP_CONFIG.GRAPHQL_HOST,

  auth0Domain: window.APP_CONFIG.AUTH0_DOMAIN,
  auth0ClientId: window.APP_CONFIG.AUTH0_CLIENT_ID,
  auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE,

  sentryDsn: window.APP_CONFIG.SENTRY_DSN,
  environment: window.APP_CONFIG.SENTRY_ENVIRONMENT,
  release: window.APP_CONFIG.SENTRY_RELEASE,
};

const apiHost = context.apiHost || `${window.location.protocol}//${window.location.host}`;
const graphqlUrl = urljoin(context.graphqlHost || apiHost, "/graphql");

// Setup Sentry Error Tracker
if (context.sentryDsn) {
  Sentry.init({
    dsn: context.sentryDsn,
    release: context.release,
    environment: context.environment,
  });
}

// Need to use same history for auth service to allow it to redirect to previous location after
// handling login callback, otherwise the router doesn't pick up the location change.
const history = createBrowserHistory();

// Setup Authentication
const authService = new AuthService({
  domain: context.auth0Domain || "",
  clientID: context.auth0ClientId || "",
  audience: context.auth0Audience || "",
  redirectPath: "/oauth2/callback",
  onRedirectCallback: (location) => history.replace(location),
});

const client = new APIClient(apiHost, authService);

// TODO: Register stores inside their modules, so the entire module can be
// loaded asynchronously
const registry = new StoreRegistry();

registry.registerStore(
  "models",
  action(
    () =>
      new ModelsStore(
        {
          baseUrl: apiHost.replace(/\/+$/, "") + "/",
        },
        authService
      )
  )()
);
registry.registerStore("api", new APIStore(client));

const cache = new InMemoryCache({ possibleTypes });

const httpLink = createUploadLink({
  uri: graphqlUrl,
});

const authLink = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    Authorization: `Bearer ${await authService.getToken()}`,
  },
}));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  // Consolidate errors, to reduce Sentry spend
  const errorMessage =
    graphQLErrors?.reduce(
      (accumulator, currentValue) => accumulator + "\n\n" + printError(currentValue),
      networkError?.message || "" // if we have graphQLErrors, prepend the network error (if any)
    ) ||
    networkError?.message || // presumably, if no graphQLErrors, there's a network error
    "GraphQL Error reported by Error Link"; // just in case
  if (context.sentryDsn) {
    Sentry.captureMessage(
      errorMessage,
      "info" // keep it on the DL
    );
  } else {
    console.log(errorMessage);
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 15,
    retryIf: (error, _operation) => !!error,
  },
});

// Set up graphql client
const apolloClient = new ApolloClient({
  cache,
  link: from([authLink, retryLink, errorLink, httpLink]),
  connectToDevTools: true,
});

FullStory.init(
  {
    orgId: "o-1CVE7A-na1",
    devMode:
      window.APP_CONFIG.CLIENT_METRICS_ENABLED === "false" ||
      window.APP_CONFIG.SENTRY_ENVIRONMENT !== "production",
  },
  ({ sessionUrl }) => console.log(`Started FullStory session: ${sessionUrl}`)
);

// Heap analytics
if (window.APP_CONFIG.CLIENT_METRICS_ENABLED === "true") {
  (window as any).heap?.load(
    window.APP_CONFIG.SENTRY_ENVIRONMENT === "production" ? "3552401264" : "2288413316"
  );
}

const App = (
  <ThemeProvider theme={SmcMuiTheme}>
    <CssBaseline>
      <GlobalStyles>
        <AuthProvider authService={authService}>
          <Provider storeRegistry={registry}>
            <ApolloProvider client={apolloClient}>
              <StyledSnackbarProvider>
                <Router history={history}>
                  <Switch>
                    <Route path="/oauth2/callback" component={OAuthCallback} />
                    <Route path="/oauth2/logout" component={Logout} />
                    <PrivateRoute component={Root} />
                  </Switch>
                </Router>
              </StyledSnackbarProvider>
            </ApolloProvider>
          </Provider>
        </AuthProvider>
      </GlobalStyles>
    </CssBaseline>
  </ThemeProvider>
);

render(App, document.getElementById("root"));
