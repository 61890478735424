import * as React from "react";
import { useSnackbar } from "notistack";
import { SimpleConfirmationDialog } from "modules/common/components/SimpleConfirmationDialog";
import { useDissociateMotorMutation } from "modules/site-manager/mutations/MotorUserActionMutation";
import { MotorSection_site_motors } from "generated-gql-types/MotorSection_site";

type DissociateMotorDialogProps = {
  open: boolean;
  onClose: (success: boolean) => void;
  motor: MotorSection_site_motors;
};

export const DissociateMotorDialog = (props: DissociateMotorDialogProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const dissociate = useDissociateMotorMutation();

  return (
    <SimpleConfirmationDialog
      open={props.open}
      onClose={() => props.onClose(false)}
      title="Remove Motor from Site?"
      body={
        <>
          The motor <strong>{`${props.motor.displayName || props.motor.driveSerialNumber}`}</strong>{" "}
          will be dissociated from the site. Do you want to proceed?
        </>
      }
      confirmLabel="Yes"
      onConfirm={async () => {
        try {
          await dissociate(props.motor.id);
          enqueueSnackbar(`Motor ${props.motor.driveSerialNumber} has been dissociated`, {
            variant: "success",
          });
          props.onClose(true);
        } catch (e) {
          enqueueSnackbar("There was an error removing the motor from the site.", {
            variant: "error",
          });
          props.onClose(false);
        }
      }}
    />
  );
};
