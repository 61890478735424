import * as React from "react";
import { PrimaryCard } from "modules/common/components/PrimaryCard";
import { Typography, CardHeader, CardContent, Box } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import { FontIcon } from "modules/common/components/FontIcon";
import {
  NotificationChannels_notificationChannels_EmailChannel as EmailChannel,
  NotificationChannels_notificationChannels_SMSChannel as SMSChannel,
  NotificationChannels_notificationChannels as NotificationChannel,
  NotificationChannels,
} from "generated-gql-types/NotificationChannels";
import { DeleteNotificationChannel } from "./DeleteNotificationChannel";
import { useQuery, gql } from "@apollo/client";
import CreateChannelForm from "./CreateChannelForm";
import { RouteComponentProps } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { Colors } from "sigil";
import { useTheme } from "@mui/material/styles";
import { Grid, useMediaQuery } from "@mui/material";

const query = gql`
  query NotificationChannels {
    notificationChannels {
      id
      isVerified
      ... on EmailChannel {
        emailAddress
      }
      ... on SMSChannel {
        phoneNumber
      }

      ...DeleteNotificationChannel_channel
    }
  }
  ${DeleteNotificationChannel.fragments.channel}
`;

export const NotificationSettingsRoute: React.FC<RouteComponentProps<{}>> = () => {
  const { data, loading, error } = useQuery<NotificationChannels>(query);
  if (loading || error || !data || !data.notificationChannels) {
    return null;
  }
  return (
    <PrimaryCard>
      <CardHeader title="Notifications" />
      <CardContent>
        <Typography>
          Choose how you will receive notifications. These settings will apply to any alerts
          triggered at your sites.
        </Typography>
        <Box>
          {data.notificationChannels.map((channel: NotificationChannel) => (
            <Box key={channel.id} display="flex" alignItems="center" my={2}>
              <BorderBox>
                <ChannelRow channel={channel} />
              </BorderBox>
              <DeleteNotificationChannel channel={channel} />
            </Box>
          ))}
          <CreateChannelForm />
        </Box>
      </CardContent>
    </PrimaryCard>
  );
};
export default NotificationSettingsRoute;

const BorderBoxComponent: React.FC<WithStyles> = ({ children, classes }) => (
  <Box display="flex" flexGrow={1} className={classes.root}>
    {children}
  </Box>
);
const BorderBox = withStyles((theme) => ({
  root: {
    border: `1px solid ${Colors.silver}`,
    borderRadius: 1,
    padding: theme.spacing(1),
    color: theme.palette.action.active,
    "& > div": {
      paddingLeft: 2 * theme.spacing(1),
    },
  },
}))(BorderBoxComponent);

type ChannelProps = { channel: NotificationChannel };
const ChannelRow: React.FC<ChannelProps> = ({ channel }) => {
  if (isEmail(channel)) {
    // My kingdom for pattern matching
    return (
      <ChannelRowItem
        title={"e-mail"}
        value={channel.emailAddress}
        iconName="envelope"
      ></ChannelRowItem>
    );
  } else if (isSMS(channel)) {
    return <SMSRow channel={channel} />;
  }
  return null;
};

const isEmail = (channel: NotificationChannel): channel is EmailChannel =>
  channel.__typename === "EmailChannel";

const ChannelRowItem: React.FC<{ title: string; value: string; iconName: string }> = ({
  title,
  value,
  iconName,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const sx = isMobile
    ? {}
    : {
        borderRight: `1px solid ${Colors.silver}`,
        marginRight: 1,
      };
  return (
    <Grid container>
      <Grid item minWidth={115} xs={12} sm="auto" sx={sx}>
        <Box display="inline-block" pr={1}>
          <FontIcon name={iconName} color="ash" />
        </Box>
        {title}
      </Grid>
      <Grid item xs={12} sm>
        {value}
      </Grid>
    </Grid>
  );
};

const isSMS = (channel: NotificationChannel): channel is SMSChannel =>
  channel.__typename === "SMSChannel";
const SMSRow: React.FC<{ channel: SMSChannel }> = ({ channel }) => {
  const parsedPhoneNumber = parsePhoneNumberFromString(channel.phoneNumber, "US");
  const formattedPhoneNumber = parsedPhoneNumber
    ? parsedPhoneNumber.formatNational()
    : channel.phoneNumber;
  return (
    <ChannelRowItem title={"SMS"} value={formattedPhoneNumber} iconName="comment"></ChannelRowItem>
  );
};
