import React from "react";
import { gql } from "@apollo/client";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { OrganizationMonthlySavingsChart_org } from "generated-gql-types/OrganizationMonthlySavingsChart_org";

import { MonthlySavingsChart } from "modules/site-manager/components/MonthlySavingsChart";

interface OrganizationMonthlySavingsChartProps {
  organization: OrganizationMonthlySavingsChart_org;
  hasUtilityRate?: boolean;
  co2eGPerKwh?: number;
}

export const OrganizationMonthlySavingsChartComponent = ({
  organization,
  hasUtilityRate,
  co2eGPerKwh,
}: OrganizationMonthlySavingsChartProps) => {
  return (
    <>
      {organization && organization.monthlyEnergySavings ? (
        <MonthlySavingsChart
          hasUtilityRate={hasUtilityRate}
          co2eGPerKwh={co2eGPerKwh}
          monthlyEnergySavings={organization.monthlyEnergySavings}
        />
      ) : null}
    </>
  );
};

export const OrganizationMonthlySavingsChart = createFragmentContainer(
  OrganizationMonthlySavingsChartComponent,
  {
    organization: gql`
      fragment OrganizationMonthlySavingsChart_org on Organization {
        id
        monthlyEnergySavings {
          id
          month
          baselineKwh
          turntideKwh
          baselineUtilityCost
          turntideUtilityCost
          baselineCo2eEmissions
          turntideCo2eEmissions
        }
      }
    `,
  }
);
