import React, { ReactNode, useState } from "react";
import { gql } from "@apollo/client";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import {
  Organization_node_Organization,
  Organization_node_Organization_sites_edges,
  Organization_node_Organization_sites_edges_node,
} from "generated-gql-types/Organization";
import { Grid, Typography } from "@mui/material";
import { SOFT_BOX_SHADOW, BOXED_CARD_STYLE } from "../../utils/cardStyles";
import { SearchBar } from "modules/site-manager/components/SearchBar";
import { DataTable, createColumn } from "modules/site-manager/components/DataTable";
import { GridColumns, GridComparatorFn, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { LightCaption } from "modules/common/components/Typography";
import { ALERT_GRID_FONT_SIZE } from "../Alerts/components";
import { formatNumber } from "modules/site-manager/utils/chartUtils";
import { useHistory } from "react-router-dom";
import { unmarshalNumberId } from "modules/common/utils/relay";

enum EnergySavingsStatus {
  DISABLED = 0,
  NOT_CONFIGURED,
  CONFIGURED,
}

type OrganizationDashboardComponentProps = {
  organization: Organization_node_Organization;
};

type EnergySavingsValue = {
  utilitySavings: number;
  energySavingsStatus: EnergySavingsStatus;
};

type OrgRollupRow = {
  id: string;
  site: Organization_node_Organization_sites_edges_node;
  energySavings: EnergySavingsValue;
  motors: number;
};

const renderSiteTitle = (params: GridRenderCellParams): ReactNode => {
  if (params && params.id) {
    return (
      <>
        <Typography
          fontSize={ALERT_GRID_FONT_SIZE}
          title={params.row.site.name}
          mt={2}
          mb={2}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.row.site.name}
          <br />
          <LightCaption style={{ textOverflow: "ellipsis" }}>
            <Typography component={"span"} fontSize={12} title={params.row.site.address || ""}>
              {params.row.site.address || ""}
            </Typography>
          </LightCaption>
        </Typography>
      </>
    );
  }
  return params.value;
};

const sortSiteTitle: GridComparatorFn<Organization_node_Organization_sites_edges_node> = (a, b) => {
  return a.name.localeCompare(b.name);
};

const energySavingsStatusMessages = {
  [EnergySavingsStatus.DISABLED]: "Disabled",
  [EnergySavingsStatus.NOT_CONFIGURED]: "Not Configured",
  [EnergySavingsStatus.CONFIGURED]: "Configured",
};

const renderEnergySavings = (params: GridRenderCellParams<EnergySavingsValue>): ReactNode => {
  if (params.row.energySavings.energySavingsStatus === EnergySavingsStatus.CONFIGURED) {
    return formatNumber(params.value?.utilitySavings || 0, 10000, 0, 0, "USD");
  }
  return energySavingsStatusMessages[params.row.energySavings.energySavingsStatus];
};

const sortEnergySavings: GridComparatorFn<EnergySavingsValue> = (
  v1: EnergySavingsValue,
  v2: EnergySavingsValue
) => {
  if (v2.energySavingsStatus === v1.energySavingsStatus) {
    return v1.utilitySavings - v2.utilitySavings;
  }
  return v1.energySavingsStatus - v2.energySavingsStatus;
};

const getEnergySavingsStatus = (
  node: Organization_node_Organization_sites_edges_node
): EnergySavingsStatus => {
  if (!node.energySavingsEnabled) {
    return EnergySavingsStatus.DISABLED;
  }
  // For performance, we don't actually receive baselineMotors when requesting them with an org's sites in bulk,
  // so we don't know for sure whether a site is fully configured.
  // For now, we just assume they are configured and show $0 for those that aren't.
  return EnergySavingsStatus.CONFIGURED;
};

// OrganizationSitesTableComponent displays:
// - a title card with the organization name and site count
// - a grid of the sites belonging to the given organization
const OrganizationSitesTableComponent = ({ organization }: OrganizationDashboardComponentProps) => {
  const [searchFilter, setSearchFilter] = useState("");
  const history = useHistory();

  const columns: GridColumns = [
    createColumn({
      field: "site",
      headerName: "Site",
      minWidth: 100,
      flex: 0.6,
      renderCell: renderSiteTitle,
      sortable: true,
      sortComparator: sortSiteTitle,
    }),
    createColumn({
      field: "motors",
      headerName: "Motors",
      minWidth: 100,
      flex: 0.2,
      maxWidth: 180,
      sortable: true,
    }),
    createColumn({
      field: "energySavings",
      headerName: "Energy Savings",
      minWidth: 100,
      flex: 0.2,
      renderCell: renderEnergySavings,
      sortable: true,
      sortComparator: sortEnergySavings,
    }),
  ];

  const rows = organization.sites.edges
    .filter(
      (edge) =>
        edge.node.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
        edge.node.id === searchFilter
    )
    .map(
      (edge) =>
        ({
          id: edge.node.id,
          site: edge.node,
          motors: edge.node.motors?.length || 0,
          energySavings: {
            utilitySavings: edge.node.energySavings?.utilitySavings || 0,
            energySavingsStatus: getEnergySavingsStatus(edge.node),
          },
        } as OrgRollupRow)
    );

  return (
    <Grid item container xs={12} sx={SOFT_BOX_SHADOW} mb={1}>
      <Grid
        item
        container
        xs={12}
        sx={{ ...BOXED_CARD_STYLE, borderRadius: "3px" }}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={10} p={1}>
          <SearchBar
            onSearch={(query: string) => {
              setSearchFilter(query);
            }}
            searchOptions={(organization?.sites?.edges || []).map(
              (edge: Organization_node_Organization_sites_edges) => ({
                value: edge.node.id,
                label: edge.node.name,
              })
            )}
            backgroundColor="white"
          />
        </Grid>
        <Grid item xs={12}>
          <DataTable
            autoHeight
            columns={columns}
            rows={rows}
            rowCount={rows.length}
            pagination
            paginationMode="client"
            disableSelectionOnClick
            noRowsMessage={`No sites`}
            sortingMode="client"
            sortingOrder={["desc", "asc"]}
            onRowClick={(params) => {
              // link to the site page
              const url = `/sites/${unmarshalNumberId(params.row.site.id)}/summary`;
              history.push(url);
            }}
            sx={{
              border: "0px transparent",
              "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                overflow: "visible",
                lineHeight: "1.43rem",
                whiteSpace: "normal",
              },
              "& .MuiDataGrid-row": {
                cursor: "pointer",
              },
              fontFamily: "Barlow",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const OrganizationSitesTable = createFragmentContainer(OrganizationSitesTableComponent, {
  organization: gql`
    fragment OrganizationSitesTable_organization on Organization {
      id
      name
      viewerIsAdmin
      sites(first: $first, includeCount: true) {
        totalCount
        edges {
          node {
            ... on Site {
              id
              name
              address
              motors {
                id
              }
              energySavingsEnabled
              energySavings {
                id
                utilitySavings
              }
              utilityRates {
                id
                rate
              }
              co2eGPerKwh
            }
          }
        }
      }
    }
  `,
});
