import * as React from "react";
import classNames from "classnames";
import { Theme } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { getLuminance } from "@material-ui/core/styles/colorManipulator";

type SlantedRectColor = "primary" | "plain" | "light" | "active" | "acked";
type SlantedRectVariant = "solid" | "outline";

const styles = (theme: Theme) => {
  const { palette } = theme;

  const skew = -12;
  const height = 23;

  function generateColor(color: string, textColor: string = "") {
    const lum = getLuminance(color);
    return {
      backgroundColor: color,
      borderColor: color,
      color: textColor || (lum < 0.5 ? "white" : palette.text.primary),

      "&$outline": {
        backgroundColor: "transparent",
        color: color,
      },
    };
  }

  return createStyles({
    root: {
      transform: `skew(${skew}deg)`,
      height: height,
      padding: `0 ${theme.spacing(1)}px`,
      lineHeight: `${height - 4}px`,

      fontSize: 13,
      fontWeight: 500,
      textAlign: "center",

      borderRadius: 1,
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: "transparent",
    },
    outline: {},

    colorPrimary: generateColor(palette.primary.main),
    colorPlain: generateColor(palette.text.hint),
    colorLight: generateColor(palette.text.disabled),

    // alerts 2
    colorActive: generateColor(palette.warning.main, "black"),
    colorAcked: generateColor("rgb(192, 194, 198)", "black"),

    reverse: {
      transform: `skew(${skew * -1}deg)`,
    },
  });
};

type SlantedRectProps = WithStyles<typeof styles> & {
  color?: SlantedRectColor;
  variant?: SlantedRectVariant;
  children?: React.ReactNode;
};

function SlantedRectComponent({
  color = "primary",
  variant = "solid",
  children,
  classes,
}: SlantedRectProps) {
  return (
    <div
      className={classNames(classes.root, {
        [classes.outline]: variant === "outline",
        [classes.colorPrimary]: color === "primary",
        [classes.colorPlain]: color === "plain",
        [classes.colorLight]: color === "light",
        [classes.colorActive]: color === "active",
        [classes.colorAcked]: color === "acked",
      })}
    >
      <div className={classes.reverse}>{children}</div>
    </div>
  );
}

export const SlantedRect = withStyles(styles)(SlantedRectComponent);
