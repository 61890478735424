import React from "react";
import { gql } from "@apollo/client";
import { Card, Grid, Tooltip } from "@mui/material";
import { Section } from "../SiteConfiguration/ConfigSection";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import LeafOutlinedIcon from "@mui/icons-material/EnergySavingsLeafOutlined";
import { SiteStaticInputsForm } from "./SiteStaticInputsForm";
import { UtilityRatesForm } from "./UtilityRatesForm";
import { isSiteEqual } from "../SiteEnergySavings/SiteSetup";
import { useUpdateSiteMutation } from "modules/common/mutations/UpdateSiteInputMutation";
import { useSnackbar } from "notistack";
import { UtilityRatesForm_site_utilityRates } from "generated-gql-types/UtilityRatesForm_site";

export type EnergySavingsSection_site = any;

export const EnergySavingsSectionComponent = ({ site }: { site: EnergySavingsSection_site }) => {
  const updateSite = useUpdateSiteMutation();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Section title="Energy Savings Settings" icon={<LeafOutlinedIcon />}>
      <Grid container={true} direction="row" spacing={2}>
        <Grid item={true} xs={12}>
          <Tooltip
            title={
              site.viewerIsAdmin
                ? ""
                : "Only organization admins may change energy savings settings"
            }
          >
            <Card sx={{ padding: "16px 24px" }}>
              <UtilityRatesForm
                site={site}
                onChange={function (utilityRate: UtilityRatesForm_site_utilityRates): void {
                  enqueueSnackbar(`Saved utility rate`, { variant: "success" });
                }}
                disabled={!site.viewerIsAdmin}
              />
              <SiteStaticInputsForm
                site={site}
                onChange={function (updatedSite: any): void {
                  if (!isSiteEqual(updatedSite, site)) {
                    try {
                      // Trigger mutation
                      updateSite({
                        siteId: site.id,
                        peakDemandCharge: updatedSite?.peakDemandCharge,
                        co2eGPerKwh: updatedSite?.co2eGPerKwh,
                      }).then(() => {
                        enqueueSnackbar(`Saved site setup`, { variant: "success" });
                      });
                    } catch (e: any) {
                      enqueueSnackbar(`Error saving site setup: ${e.message || e}`, {
                        variant: "error",
                      });
                    }
                  }
                }}
                disabled={!site.viewerIsAdmin}
              />
            </Card>
          </Tooltip>
        </Grid>
      </Grid>
    </Section>
  );
};

export const EnergySavingsSection = createFragmentContainer(EnergySavingsSectionComponent, {
  site: gql`
    fragment EnergySavingsSection_site on Site {
      id
      viewerIsAdmin
      ...UtilityRatesForm_site
      ...SiteStaticInputsForm_site
    }
    ${UtilityRatesForm.fragments.site}
    ${SiteStaticInputsForm.fragments.site}
  `,
});
