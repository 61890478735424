import * as React from "react";
import { Typography } from "@material-ui/core";
import { gql } from "@apollo/client";
import { ProfileDropdown_viewer } from "generated-gql-types/ProfileDropdown_viewer";
import {
  ActionMenu,
  ActionMenuItem,
  ActionMenuItemLink,
} from "modules/common/components/ActionMenu";
import { useMenuState } from "modules/common/hooks";
import OrgMenuItems from "./OrgMenuItems";
import { InitialsIconButton } from "./InitialsIconButton";
import { createFragmentContainer } from "../createFragmentContainer";
import { Grid, Link } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const ProfileDropdownComponent = ({ viewer }: { viewer?: ProfileDropdown_viewer }) => {
  const menu = useMenuState();

  return (
    <React.Fragment>
      <InitialsIconButton
        firstName={viewer?.firstName}
        lastName={viewer?.lastName}
        onClick={menu.handleOpen}
      />

      <ActionMenu
        {...menu.props}
        disableAutoFocusItem={true}
        onClick={menu.handleClose}
        MenuListProps={{ style: { minWidth: 220 } }}
      >
        <ActionMenuItem>
          <Link
            href="https://support.turntide.com/hc/en-us/sections/360008469352-BOS"
            color="primary"
            target="_blank"
            rel="noopener"
          >
            <Grid container pb={0}>
              <Grid item xs mr={1}>
                <HelpOutlineIcon />
              </Grid>
              <Grid item xs="auto">
                <Typography>Turntide Knowledge Base</Typography>
              </Grid>
            </Grid>
          </Link>
        </ActionMenuItem>
        <ActionMenuItemLink to="/settings/account">
          <Typography>Profile</Typography>
        </ActionMenuItemLink>

        {viewer && <OrgMenuItems organizations={viewer.organizations} />}

        <ActionMenuItemLink to="/oauth2/logout">
          <Typography>Log Out</Typography>
        </ActionMenuItemLink>
      </ActionMenu>
    </React.Fragment>
  );
};

export const ProfileDropdown = createFragmentContainer(ProfileDropdownComponent, {
  viewer: gql`
    fragment ProfileDropdown_viewer on User {
      id
      firstName
      lastName
      organizations(first: 5) {
        ...OrgMenuItems_organizations
      }
    }
    ${OrgMenuItems.fragments.organizations}
  `,
});
