import { action, computed, observable } from "mobx";
import {
  Model as DModel,
  IPatch,
  getModelType,
  ReferenceType,
  initModelRef,
  withPatches,
} from "datx";
import { jsonapi } from "datx-jsonapi";
import camelCase from "lodash/camelCase";

import { transformKeysOnto } from "modules/common/utils/transform-keys";

export class Model extends withPatches(jsonapi(DModel)) {
  static compare(m1: Model, m2: Model) {
    return Number(m1.id > m2.id);
  }

  @computed
  get id() {
    return this.meta.id.toString();
  }

  applyPatchesWithRollback = (patches: IPatch[]) => {
    patches.forEach((patch) => this.applyPatch(patch));
    return () => {
      // rollback the patches
      patches.reverse().forEach((patch) => this.undoPatch(patch));
    };
  };

  @action
  addRelations = <T extends Model>(key: string, ...rels: T[]) => {
    const existing = this[key] || observable([]);
    const newRelations = rels.filter((rel) => {
      return existing.indexOf(rel) < 0;
    });
    if (newRelations[0]) {
      initModelRef(
        this,
        key,
        {
          model: getModelType(existing[0]),
          type: ReferenceType.TO_ONE_OR_MANY,
        },
        existing.concat(...newRelations)
      );
    }
  };
}

export class TSPointModel {
  constructor(data: object) {
    transformKeysOnto(this, data, camelCase);
  }
}
