import { createMuiTheme } from "@material-ui/core/styles";
import createPalette from "@material-ui/core/styles/createPalette";
import { MuiButton } from "./Button";
import { MuiCardOverrides, MuiCardProps } from "./Card";
import { MuiDialogOverrides } from "./Dialog";
import { MuiFormOverrides, MuiFormProps } from "./Form";
import { light } from "./palette";
import { MuiPaper } from "./Paper";
import { typography, MuiTypographyProps } from "./typography";
import { MuiTableOverrides } from "./Table";
import { MuiTooltipOverrides } from "./Tooltip";
import { MuiSwitchOverrides } from "./Switch";

const palette = createPalette(light);

export const SmcMuiTheme = createMuiTheme({
  palette: light,

  typography: typography(palette),

  shape: {
    borderRadius: 1,
  },

  overrides: {
    MuiButton,
    ...MuiCardOverrides,
    ...MuiDialogOverrides(palette),
    ...MuiFormOverrides(palette),
    MuiPaper,
    ...MuiTableOverrides,
    ...MuiTooltipOverrides,
    ...MuiSwitchOverrides,
  },

  props: {
    ...MuiCardProps,
    ...MuiFormProps,
    ...MuiTypographyProps,
  },

  // breakpoints customized to match react grid breakpoints on the summary page
  breakpoints: {
    values: {
      xs: 0,
      sm: 320,
      md: 480,
      lg: 940,
      xl: 1200,
    },
  },
});

// Generate light / dark shades for custom color types
SmcMuiTheme.palette.augmentColor(SmcMuiTheme.palette.success);
SmcMuiTheme.palette.augmentColor(SmcMuiTheme.palette.danger);

// Augment shadows
SmcMuiTheme.shadows[1] = "0px 5px 25px rgba(0, 0, 0, 0.1)";
