import { SiteSummary_node_Site } from "generated-gql-types/SiteSummary";
import {
  SummaryLayout,
  addModule,
} from "modules/site-manager/routes/Site/SiteSummary/hooks/useLayouts";
import { useState } from "react";
import {
  SimpleStatProps,
  SimpleStatForm,
} from "modules/site-manager/routes/Site/SiteSummary/modules/SimpleStat";
import { Box, Dialog, DialogContent } from "@material-ui/core";
import { Button } from "sigil";
import React from "react";
import { useUpdateSiteLayoutMutation } from "modules/common/mutations/UpdateSiteLayoutMutation";

export const ModuleFormDialog = ({
  handleClose,
  siteId,
  handleSubmit,
  open,
  initial,
}: {
  handleClose: () => void;
  siteId: string;
  handleSubmit: (props: SimpleStatProps) => Promise<void>;
  open: boolean;
  initial?: SimpleStatProps;
}) => {
  return (
    <Dialog fullScreen disableAutoFocus disableEnforceFocus open={open} onClose={handleClose}>
      <DialogContent>
        <SimpleStatForm
          siteId={siteId}
          onClose={handleClose}
          onSubmit={handleSubmit}
          initial={initial}
        />
      </DialogContent>
    </Dialog>
  );
};

export const ModuleForm = ({
  site,
  layouts,
}: {
  site: SiteSummary_node_Site;
  layouts: SummaryLayout;
}) => {
  const [mutate] = useUpdateSiteLayoutMutation();
  const [open, setOpen] = useState(false);
  if (!site.viewerCanEditDashboards) return null;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = async (props: SimpleStatProps) => {
    const layout = addModule(layouts, "simplestat", props);
    await mutate({
      siteId: site.id,
      layout: layout,
    });
  };
  return (
    <Box pb={1} ml={0}>
      <Button onClick={handleOpen} color="primary" size="small">
        Add Module
      </Button>
      <ModuleFormDialog
        open={open}
        handleClose={handleClose}
        siteId={site.id}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};
