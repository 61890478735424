import { useEffect, useRef } from "react";

// useIsMounted is a helper for guarding against making state changes after a component unmounts.
// Consider this hook if you see "Warning: Can't perform a React state update on an unmounted component"
export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return function cleanup() {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
}
