import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import { WithSnackbarProps, withSnackbar } from "notistack";
import { FontIcon } from "modules/common/components/FontIcon";
import { AlertChartSection } from "modules/site-manager/routes/Alerts/Alert/AlertSummary/AlertChartSection";
import { useHistory } from "react-router-dom";
import { Box, Button, Grid, Stack } from "@mui/material";
import { ActionConfirmationDialog, FancyDivider } from "../../components";
import { AlertDetailsCard } from "./AlertDetailsCard";
import { useAckAlertMutation, useCloseAlertMutation } from "../../components/useAlertMutations";
import { AlertDetailsProps } from "../types";
import { presentError } from "modules/site-manager/utils/errors";

function Component({
  alert,
  apiStore,
  siteStore,
  enqueueSnackbar,
}: AlertDetailsProps & WithSnackbarProps) {
  const history = useHistory();
  const { ackAlert, ackResult } = useAckAlertMutation();
  const { closeAlert, closeResult } = useCloseAlertMutation();
  const acknowledgeSelected = () => {
    ackAlert(alert.id);
  };
  const closeSelected = () => {
    closeAlert(alert.id);
  };
  // Display error when value changes
  const error = (ackResult.error || closeResult.error)?.message;
  useEffect(() => {
    error &&
      enqueueSnackbar(presentError("Error updating alert", error), {
        variant: "error",
      });
  }, [enqueueSnackbar, error]);

  const canClose = alert.canBeClosed && alert.site.viewerIsAdmin;
  const performingAction = ackResult.loading || closeResult.loading;

  try {
    return (
      <Box mt={1}>
        {/* Back and Ack/Close */}
        <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
          {/* TODO: goBack() will navigate among tabs once we have multiple tabs.  
              Instead we'll need to "replace" with an explicit route, which should be provided by the calling page somehow 
              Maybe there's a way to count tab renders and back up accordingly...
              */}
          <Grid item xs>
            <Button
              variant="outlined"
              onClick={() => history.goBack()}
              style={{ borderColor: "#808080", color: "#727780" }}
            >
              <FontIcon name="chevron-left" shrink={true} className="mr-2" />
              Back to Alerts
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Stack direction="row" mr={0} spacing={1}>
              <ActionConfirmationDialog
                actionName="Acknowledge"
                actionCount={1}
                objectName="alert"
                callback={acknowledgeSelected}
                disabled={!alert.canBeAcked || performingAction}
                iconName="eye"
              />
              <ActionConfirmationDialog
                actionName="Close"
                actionCount={1}
                objectName="alert"
                callback={closeSelected}
                disabled={!canClose || performingAction}
                iconName="check"
              />
            </Stack>
          </Grid>
        </Grid>
        {/* "Alert Info" Divider with bell icon */}
        <FancyDivider>Alert Info</FancyDivider>
        {/* Alert Details Card */}
        <AlertDetailsCard alert={alert} apiStore={apiStore} siteStore={siteStore} />

        {/* Alert Chart Section */}
        <AlertChartSection alert={alert} apiStore={apiStore} siteStore={siteStore} />
        {/* TODO pending design: "Related Items" Divider with pencil icon */}
        {/* TODO pending design: Split between Notes and Related Alerts */}
      </Box>
    );
  } catch (exception) {
    enqueueSnackbar("There was an error displaying the alert.", {
      variant: "error",
    });
    return <Redirect to="/alerts" />;
  }
}

export const AlertSummary = withSnackbar(observer(Component));
