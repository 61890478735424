export function convertToCSV(objArray: string) {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";

  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line !== "") line += ",";

      line += array[i][index];
    }

    str += line + "\r\n";
  }

  return str;
}

const cleanItem = (item: any) => {
  const cleanedItem = {};
  Object.keys(item).forEach((key) => {
    cleanedItem[key.replace(/,/g, "")] = item[key];
  });
  return cleanedItem;
};

export function downloadFile(content: string, fileName: string) {
  const blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return true;
  }
  return false;
}

export function exportCSVFile(headers: any, items: any[], fileTitle: string) {
  if (headers) {
    items.unshift(headers);
  }

  items = items.map(cleanItem);

  // Convert Object to JSON
  const jsonObject = JSON.stringify(items);

  const csv = convertToCSV(jsonObject);

  const exportedFilename = fileTitle + ".csv" || "export.csv";

  return downloadFile(csv, exportedFilename) && exportedFilename;
}
