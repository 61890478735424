import React from "react";
import { InputAdornment, OutlinedInput, Typography } from "@mui/material";
import { RefNumberFormatCustom } from "./NumberFormatCustom";
import { labelFontStyle } from "global-styles";

export type NumericInputProps = {
  id: string;
  value: number | null;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  units?: string;
  autoFocus?: boolean;
  isError?: boolean;
  disabled?: boolean;
  maxValue?: number;
  minValue?: number;
  extraStyle?: any;
  decimalScale?: number;
};

export const NumericInput = ({
  id,
  value,
  label,
  onChange,
  onBlur,
  units,
  autoFocus = false,
  isError = false,
  disabled = false,
  maxValue = undefined,
  minValue = undefined,
  extraStyle = {},
  decimalScale = undefined,
}: NumericInputProps) => (
  <OutlinedInput
    value={value || ""}
    required
    onChange={onChange}
    onBlur={onBlur ? onBlur : () => {}}
    endAdornment={
      <InputAdornment position="end">
        <Typography style={labelFontStyle}>{units || ""}</Typography>
      </InputAdornment>
    }
    type="text"
    className="input"
    size="small"
    margin="dense"
    sx={{
      backgroundColor: "#ffffff",
      paddingRight: 1,
      margin: 1,
      ...labelFontStyle,
      ...extraStyle,
    }}
    aria-describedby={`${id}-helper-text`}
    autoFocus={autoFocus}
    inputComponent={RefNumberFormatCustom as any}
    inputProps={{
      "aria-label": label,
      minValue,
      maxValue,
      decimalScale,
    }}
    fullWidth
    error={isError}
    notched={false}
    label={label}
    disabled={disabled}
  />
);
