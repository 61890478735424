const LOGIC_POINT_REGEX = /^([a|b]v)_(\d+)$/;

export enum LOGIC_POINT_KIND {
  ANALOG = 0,
  BINARY,
  ENUM,
}

export class LogicPoint {
  isValid: boolean;
  kind?: LOGIC_POINT_KIND;
  kindName?: string;
  index?: number;
  indexStr?: string;

  constructor(public pointName: string, public isEnum?: boolean) {
    const matches = pointName.match(LOGIC_POINT_REGEX);

    this.isValid = !!matches;
    if (matches) {
      this.kindName = matches[1];
      if (isEnum) {
        this.kind = LOGIC_POINT_KIND.ENUM;
      } else if (matches[1] === "av") {
        this.kind = LOGIC_POINT_KIND.ANALOG;
      } else {
        this.kind = LOGIC_POINT_KIND.BINARY;
      }
      this.indexStr = matches[2];
      this.index = parseInt(matches[2], 10);
    }
  }

  get name() {
    return this.isValid && [this.kindName!.toUpperCase(), this.indexStr].join(" ");
  }

  get isAnalog() {
    return this.kind === LOGIC_POINT_KIND.ANALOG;
  }
}
