import { Collection, Model } from "datx";
import { config, IRawResponse, ICollectionFetchOpts, jsonapi } from "datx-jsonapi";
import { IDictionary } from "datx-utils";
import camelCase from "lodash/camelCase";
import snakeCase from "lodash/snakeCase";
import { AuthService } from "modules/common/auth";
import { IRecord, IDefinition, IRelationship } from "datx-jsonapi/dist/interfaces/JsonApi";
import { transformKeys } from "../utils/transform-keys";
import { parseUrl } from "modules/common/utils/url";

export class ModelsStore extends jsonapi(Collection) {
  // NOTE: Configuration is shared across all mobx-jsonapi-stores, so the latest
  // call to this constructor will enforce its configuration globally. Hope is
  // to get this changed upstream.
  // Ref: https://github.com/infinum/mobx-jsonapi-store/issues/25
  constructor(configOverrides: object, authService: AuthService, data?: Array<object>) {
    super(data);
    config.transformResponse = this.transformResponse;
    config.transformRequest = this.transformRequest;
    const defaultFetch = config.baseFetch.bind(config);
    config.baseFetch = async (method, url, body, requestHeaders) => {
      const headers = {
        ...requestHeaders,
        Authorization: `Bearer ${await authService.getToken()}`,
      };
      return defaultFetch(method, url, body, headers);
    };

    Object.keys(configOverrides).forEach((k) => {
      config[k] = configOverrides[k];
    });
  }

  static addTypes(...types: typeof Model[]) {
    this.types.push(...types);
  }

  // transformResponse will be called immediately after the response is received
  // from the server. Our custom implementation iterates through all the records
  // in the response (in both `data` and `included`) and calls transformRecord
  transformResponse = (response: IRawResponse): IRawResponse => {
    const data = response.data;
    if (!data) {
      return response;
    }
    if (Array.isArray(data.data)) {
      data.data = data.data.map(this.transformRecord);
    } else if (data.data) {
      data.data = this.transformRecord(data.data);
    }

    if (data.included) {
      data.included = data.included.map(this.transformRecord);
    }

    return response;
  };

  // transformRecord is called on each record in the API response. We want to
  // map all the snake-cased attribute and relationship names to camelCase for
  // use in client code
  transformRecord = (record: IRecord): IRecord => {
    const transformed = { ...record };

    // transform record type
    transformed.type = camelCase(record.type);

    // transform attributes object
    transformed.attributes = transformKeys(record.attributes as object, camelCase);

    // transform the names of each relationship
    if (record.relationships) {
      const relationships = record.relationships;
      transformed.relationships = Object.keys(relationships).reduce((rels, k) => {
        const rel = relationships[k];
        if (!rel.data) {
          return rels;
        }
        rel.data = this.transformDefinition(rel.data);
        rels[camelCase(k)] = rel;
        return rels;
      }, {});
    }

    return transformed;
  };

  // transformIdentifier is called on record references, e.g. for relationships.
  transformDefinition = (data: IDefinition | IDefinition[]): IDefinition | IDefinition[] => {
    if (Array.isArray(data)) {
      // Note: needs explicit typing due to recursion
      return data.map((d: IDefinition) => {
        return this.transformDefinition(d) as IDefinition;
      });
    }
    data.type = camelCase(data.type);
    return data;
  };

  // transformRequest is called on outgoing requests to the server
  transformRequest = (options: ICollectionFetchOpts): ICollectionFetchOpts => {
    options.url = this.transformRequestURL(options.url);
    options.data = this.transformRequestPayload(options.data as ICollectionFetchOpts | undefined);
    return options;
  };

  transformPathSegment = (segment: string): string => {
    return snakeCase(segment);
  };

  transformRequestURL = (url: string) => {
    const parsed = parseUrl(url);
    return parsed.href;
  };

  transformRequestPayload = (data?: ICollectionFetchOpts): ICollectionFetchOpts | undefined => {
    if (!data) {
      return;
    }
    const record = data.data as IRecord | undefined;

    // Although the mobx-jsonapi-store types do not indicate it, data.record
    // can be undefined when saving empty relationships via #saveRelationship.
    // This is primarily due to buggy behavior that required patching by SMC:
    // tslint:disable-next-line:max-line-length
    // https://github.com/softwaremotor/mobx-jsonapi-store/commit/d07a75d0cebd5c4a008cb1d8215bb62665225d36
    if (record) {
      record.type = snakeCaseJoinNumbers(record.type);

      if (record.attributes) {
        record.attributes = transformKeys(record.attributes, snakeCaseJoinNumbers);
      }

      const relationships = record.relationships;
      if (relationships) {
        Object.keys(relationships).forEach((rKey) => {
          const rData = relationships[rKey].data;
          if (Array.isArray(rData)) {
            rData.forEach(
              (rDataEntry) => (rDataEntry.type = snakeCaseJoinNumbers(rDataEntry.type))
            );
          } else if (rData) {
            rData.type = snakeCaseJoinNumbers(rData.type);
          }

          record.relationships = transformKeys(relationships, snakeCaseJoinNumbers) as
            | IDictionary<IRelationship>
            | undefined;
        });
      }
    }
    return { ...data, data: record };
  };
}

// We use a custom snake_case implementation so that numbers will be joined to
// their preceding words. E.g. `api_v2` rather than `api_v_2` or `cool1_power`
// rather than `cool_1_power`
function snakeCaseJoinNumbers(str: string): string {
  return snakeCase(str).replace(/_(\d+)/g, "$1");
}
