export function marshalNumberId(typename: string, id: number) {
  return btoa(`${typename}:${id}`);
}

export function unmarshalNumberId(relaySpec: string): number {
  const decoded = atob(relaySpec),
    colon = decoded.indexOf(":");
  return +decoded.slice(colon + 1);
}

export function marshalStringId(typename: string, id: string) {
  return btoa(`${typename}:"${id}"`);
}

export function unmarshalStringId(relaySpec: string) {
  const decoded = atob(relaySpec),
    colon = decoded.indexOf(":");
  return decoded.slice(colon + 2, decoded.length - 1);
}
