import * as React from "react";
import { gql } from "@apollo/client";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { Button } from "sigil";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";

import { RenameSiteDialog_site } from "generated-gql-types/RenameSiteDialog_site";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { FormikSubmitButton, FormikTextField } from "modules/common/components/MuiFormik";
import { useUpdateSiteMutation } from "modules/common/mutations/UpdateSiteInputMutation";

type RenameSiteDialogComponentProps = {
  site: RenameSiteDialog_site;
  open: boolean;
  onClose: () => void;
};

function RenameSiteDialogComponent(props: RenameSiteDialogComponentProps) {
  const { enqueueSnackbar } = useSnackbar();
  const updateSite = useUpdateSiteMutation();

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="xs" fullWidth>
      <Formik
        initialValues={{
          siteName: props.site.name,
        }}
        onSubmit={async (values) => {
          try {
            await updateSite({ siteId: props.site.id, name: values.siteName });
            enqueueSnackbar("Saved", { variant: "success" });
            props.onClose();
          } catch (e: any) {
            enqueueSnackbar(`Error renaming site: ${e.message || e}`, { variant: "error" });
          }
        }}
      >
        <Form>
          <DialogTitle disableTypography>
            <Typography variant="h3">Rename {props.site.name}</Typography>
          </DialogTitle>

          <DialogContent>
            <FormikTextField label="Site Name" name="siteName" autoFocus fullWidth />
          </DialogContent>

          <DialogActions style={{ padding: 16 }}>
            <Button variant="knockout" onClick={props.onClose}>
              Cancel
            </Button>
            <FormikSubmitButton>Rename Site</FormikSubmitButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
}

export const RenameSiteDialog = createFragmentContainer(RenameSiteDialogComponent, {
  site: gql`
    fragment RenameSiteDialog_site on Site {
      id
      name
    }
  `,
});
