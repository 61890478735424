import * as React from "react";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { Typography } from "@material-ui/core";
import { Card, Grid } from "@mui/material";
import { GroupedList } from "modules/common/components/GroupedList";
import { MotorOpStatPresenter } from "modules/site-manager/presenters/motor-op-stat";
import { MotorOp } from "modules/site-manager/models/motor-op";
import { BmsRolePresenter, CtrlVariablePresenter } from "modules/site-manager/presenters";
import { AnalysisStore } from "modules/site-manager/stores";
import { CtrlVariable, Motor } from "modules/site-manager/models";
import { BmsRole } from "modules/site-manager/constants";
import { TextFilterField } from "modules/common/components/TextFilterField";
import { LightCaption, BoldTitle } from "modules/common/components/Typography";

export type SelectionModifiers = {
  isMulti: boolean;
};

interface ExplorerFieldSelectorProps {
  analysisStore: AnalysisStore;
  onClose?: () => void;
  onCtrlVariableSelect: (ctrlVariable: CtrlVariable, modifiers: SelectionModifiers) => void;
  onMotorOpSelect: (motorOp: MotorOp, modifiers: SelectionModifiers) => void;
}

@observer
export class ExplorerFieldSelector extends React.Component<ExplorerFieldSelectorProps> {
  @observable selectionFilter = "";

  handleCtrlVariableSelect = (ctrlVariable: CtrlVariable, evt: React.MouseEvent<HTMLElement>) => {
    this.props.onCtrlVariableSelect(ctrlVariable, { isMulti: evt.shiftKey });
  };

  handleMotorOpSelect = (motorOp: MotorOp, evt: React.MouseEvent<HTMLElement>) => {
    this.props.onMotorOpSelect(motorOp, { isMulti: evt.shiftKey });
  };

  @action
  setSelectionFilter(filter: string) {
    this.selectionFilter = filter;
    this.props.analysisStore.setSelectionFilter(filter.trim().toLocaleLowerCase());
  }

  render() {
    const { analysisStore, onClose } = this.props;

    return (
      <Card style={{ padding: 3 }}>
        <Grid container alignItems="center" mb={1} p={2}>
          <Grid item xs mr={1}>
            <TextFilterField
              fullWidth={true}
              autoFocus={true}
              value={this.selectionFilter}
              onChange={({ target }) => this.setSelectionFilter(target.value)}
            />
          </Grid>
          <Grid
            item
            xs="auto"
            onClick={onClose}
            style={{ marginLeft: 8, cursor: "pointer" }}
            title="Close field selector"
          >
            Close &nbsp;
            <i className="fa fa-times" />
          </Grid>
        </Grid>

        <Grid container alignItems="stretch" spacing={2}>
          <Grid item container mb={3} xs={12} sm={6}>
            <Grid item xs={12} pl={1}>
              <Typography variant="h4">Site Metrics</Typography>
            </Grid>
            <Grid item xs={12}>
              <GroupedList
                getItems={() => analysisStore.unusedCtrlVariables}
                getItemKey={(ctrlVariable: CtrlVariable) => ctrlVariable.id}
                formatItem={(ctrlVariable: CtrlVariable) => {
                  const presenter = new CtrlVariablePresenter(ctrlVariable);
                  return (
                    <Grid
                      container
                      key={ctrlVariable.id}
                      alignItems="center"
                      justifyContent="stretch"
                      style={{ userSelect: "none" }}
                    >
                      <Grid item xs="auto" mr={1.5}>
                        <span>
                          <i className="fa fa-plus-circle u-text-primary" />
                        </span>
                      </Grid>
                      <Grid item xs={7}>
                        <BoldTitle>{presenter.displayName}</BoldTitle>
                        <LightCaption>{presenter.role}</LightCaption>
                      </Grid>
                      <Grid item xs={4} ml="auto">
                        <Typography>{presenter.logicPoint}</Typography>
                        <LightCaption>{presenter.controllerDisplayName}</LightCaption>
                      </Grid>
                    </Grid>
                  );
                }}
                getItemSort={(ctrlVariable: CtrlVariable) =>
                  new CtrlVariablePresenter(ctrlVariable).name
                }
                getGroup={(ctrlVariable: CtrlVariable) => ctrlVariable.bmsRoleId || undefined}
                getGroupKey={(role: BmsRole) => role.toString()}
                formatGroup={(role: BmsRole) => new BmsRolePresenter(role).name}
                getGroupSort={(role: BmsRole) => new BmsRolePresenter(role).name}
                isItemTest={(maybeItem: CtrlVariable | BmsRole): maybeItem is CtrlVariable =>
                  maybeItem instanceof CtrlVariable
                }
                onItemClick={this.handleCtrlVariableSelect}
              />
            </Grid>
          </Grid>

          <Grid item container mb={3} xs={12} sm={6} alignContent={"flex-start"}>
            <Grid item xs={12} pl={1}>
              <Typography variant="h4">Motor Metrics</Typography>
            </Grid>
            <Grid item xs={12}>
              <GroupedList
                getItems={() => analysisStore.unusedMotorOps}
                getItemKey={(motorOp: MotorOp) => motorOp.id}
                formatItem={(motorOp: MotorOp) => (
                  <span style={{ userSelect: "none" }}>
                    <i className="fa fa-plus-circle u-text-primary mr-2" />
                    {new MotorOpStatPresenter(motorOp.op).name}
                  </span>
                )}
                getItemSort={(motorOp: MotorOp) => new MotorOpStatPresenter(motorOp.op).name}
                getGroup={(motorOp: MotorOp) => motorOp.motor}
                getGroupKey={(motor: Motor) => motor.id}
                formatGroup={(motor: Motor) => motor.displayName}
                getGroupSort={(motor: Motor) => motor.displayName}
                isItemTest={(maybeItem: MotorOp | Motor): maybeItem is MotorOp =>
                  maybeItem instanceof MotorOp
                }
                onItemClick={this.handleMotorOpSelect}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  }
}
