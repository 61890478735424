import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { BottomNav, Navbar, TopNav } from "modules/common/components/Navbar";
import { AlertsRoute } from "modules/site-manager/routes/Alerts";
import { SitesRoute } from "modules/site-manager/routes/Sites";
import { SettingsRoute } from "modules/user-settings/routes/Settings";
import { OrganizationsRoute } from "modules/site-manager/routes/Organizations";
import { Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { gql, useQuery } from "@apollo/client";
import * as FullStory from "@fullstory/browser";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const useRootStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexDirection: "column",
      flexWrap: "nowrap",
      maxHeight: "100dvh",
    },
  })
);

const Root = () => {
  const theme = useTheme();
  const styles = useRootStyles(theme);
  // window.APP_CONFIG.BUILD_ID stems from stannis.build_id value in Pulumi.<environment>.yaml, and may be over-written
  // with CI ID info by a script in ci/run.sh
  window.APP_CONFIG?.BUILD_ID && console.log(window.APP_CONFIG.BUILD_ID);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMetricsEnabled = window?.APP_CONFIG?.CLIENT_METRICS_ENABLED === "true";
  const { data, loading } = useQuery(
    gql`
      query UserIdentity {
        viewer {
          id
          email
          isActivated
          isViewer
          canCreateSitesUnrestricted
          ...TopNav_viewer
        }
      }
      ${TopNav.fragments.viewer}
    `,
    { skip: !(isMobile || isMetricsEnabled) }
  );
  const [identified, setIdentified] = React.useState(false);

  React.useEffect(() => {
    if (data && !loading && !identified && isMetricsEnabled) {
      const atIndex = data.viewer.email.indexOf("@") + 1;
      const domain = atIndex > 0 ? data.viewer.email.slice(atIndex) : "redacted";
      const displayName = atob(data.viewer.id);
      const userProperties = {
        displayName: displayName,
        domain: domain,
        email: `${displayName}@${domain}`,
        isActivated: data.viewer.isActivated,
        isViewer: data.viewer.isViewer,
        canCreateSitesUnrestricted: data.viewer.canCreateSitesUnrestricted,
      };
      FullStory.identify(displayName, userProperties);

      const heap = (window as any).heap;
      if (heap) {
        heap.identify(displayName);
        heap.addUserProperties(userProperties);
      }
      setIdentified(true);
    }
  }, [identified, setIdentified, data, loading, isMetricsEnabled]);

  return (
    <>
      <Grid container className={styles.root} justify="center">
        {isMobile ? <TopNav viewer={data?.viewer} /> : <Navbar />}
        <Switch>
          <Route path="/settings" component={SettingsRoute} />{" "}
          <Route path="/organizations" component={OrganizationsRoute} />
          <Route path="/sites" component={SitesRoute} />
          <Route path="/alerts" component={AlertsRoute} />
          <Redirect to={"/sites"} />
        </Switch>
        {isMobile && <BottomNav />}
      </Grid>
    </>
  );
};

export default Root;
