import React, { ReactNode, useState, useEffect, useCallback } from "react";
import {
  AutocompleteChangeReason,
  AutocompleteValue,
  Box,
  createFilterOptions,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  MultiSelectAutocompleteOptionType,
  MultiSelectAutocomplete,
} from "modules/site-manager/components/MultiSelectAutocomplete/MultiSelectAutocomplete";
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";
import { unmarshalNumberId } from "modules/common/utils/relay";
import { uniq, uniqBy, cloneDeep, isEqual } from "lodash";
import { ActionMenu, ActionMenuItem, ActionMenuButton } from "modules/common/components/ActionMenu";
import { useMenuState } from "modules/common/hooks";
import {
  AlertFilter,
  AlertOrderByInput,
  AlertStatus as AlertStatusEnum,
  AlertType,
  MotorError,
  MotorWarning,
  Sort,
} from "generated-gql-types/globalTypes";
import { newAlertPresenter } from "modules/site-manager/presenters";
import {
  useAckAlertMutation,
  useCloseAlertMutation,
  ACKNOWLEDGED_LABEL,
  CLOSED_LABEL,
} from "../components/useAlertMutations";
import { TextLink } from "modules/common/components/TextLink";
import {
  GridCallbackDetails,
  GridColumns,
  GridRenderCellParams,
  GridRowId,
  GridSelectionModel,
  GridSortModel,
  GridValidRowModel,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { createColumn, DataTable } from "modules/site-manager/components/DataTable/DataTable";
import { LightCaption } from "modules/common/components/Typography";
import { useAlertsGridQuery, DEFAULT_PAGE_SIZE, NON_CLOSED_STATUSES } from "./useAlertsGridQuery";
import { useAlertsSitesQuery, ALERTS_SITES_QUERY, SITE_NODE_QUERY } from "./useAlertsSitesQuery";
import { ActionConfirmationDialog } from "../components/ActionConfirmationDialog";
import {
  AlertsGrid_alerts_edges_node,
  AlertsGrid_alerts_edges,
  AlertsGridVariables,
} from "generated-gql-types/AlertsGrid";
import { AlertsSites_sites_edges } from "generated-gql-types/AlertsSites";
import {
  MOTOR_ERROR_MESSAGES,
  MOTOR_WARNING_MESSAGES,
} from "modules/site-manager/presenters/alert";
import { AlertStatusBanner, AlertTriggeredMoment, ALERT_GRID_FONT_SIZE } from "../components";
import { useQueryParams } from "modules/common/hooks";
import { Container } from "../../../components/Container";
import { LeftAlignedHeader } from "../../../components/LeftAlignedHeader";
import { Colors } from "sigil";
import { LocationDescriptor } from "history";
import { NetworkStatus, useLazyQuery } from "@apollo/client";
import { getOpaqueSiteId } from "../../../utils/routing";
import { useTheme } from "@mui/material/styles";
import { presentError } from "modules/site-manager/utils/errors";

interface AlertsGridProps {
  siteId?: string;
}

type AlertsGridQueryParams = {
  search: string;
  filters?: AlertFilter;
  sites?: { id: string; label: string }[];
  orderBy?: AlertOrderByInput;
  pageSize?: number;
};

const DEFAULT_FILTER: AlertFilter = { statuses: NON_CLOSED_STATUSES, sites: [] };
const DEFAULT_SORT: AlertOrderByInput = {
  createdAt: Sort.DESC,
};
const QUERY_PARAM_NAME = "filters";
const FILTER_GROUP_SITE = "Site";
const FILTER_GROUP_STATUS = "Status";
const FILTER_GROUP_ALERT_TYPE = "Alert Types";
const FILTER_GROUP_MOTOR_ERRORS = "Motor Errors";
const FILTER_GROUP_MOTOR_WARNINGS = "Motor Warnings";
const SITE_SEARCH = "Search for Sites";
const LOADING_MESSAGE = "Loading...";
const NO_ROWS_MESSAGE = "Filter returned no result";
const MAX_QUERY_SITES = 4000;

// *** Dropdown menu options ***
const ENABLED_FILTERS = {
  statuses: true,
  alertTypes: true,
  motorErrors: true,
  motorWarnings: false,
};

const FILTER_OPTIONS: MultiSelectAutocompleteOptionType[] = [];
const filterMultiSelectAutocompleteOptions =
  createFilterOptions<MultiSelectAutocompleteOptionType>();

// Status
if (ENABLED_FILTERS.statuses) {
  FILTER_OPTIONS.push(
    ...[
      {
        group: FILTER_GROUP_STATUS,
        label: "Active",
        id: AlertStatusEnum.TRIGGERED,
        filter: "statuses",
      },
      {
        group: FILTER_GROUP_STATUS,
        label: "Acknowledged",
        id: AlertStatusEnum.ACKED,
        filter: "statuses",
      },
      {
        group: FILTER_GROUP_STATUS,
        label: "Closed",
        id: AlertStatusEnum.CLOSED,
        filter: "statuses",
      },
    ]
  );
}

const ALERT_TYPE_LABELS = {
  [AlertType.MC_CONNECTIVITY]: "Motor connectivity",
  [AlertType.MC_ERRORS]: "Motor errors",
  [AlertType.MC_WARNINGS]: "Motor warnings",
  [AlertType.MOTOR_LOW_TORQUE]: "Motor low torque",
  [AlertType.MOTOR_SEIZURE]: "Motor seizure",
  [AlertType.MOTOR_SPEED]: "Motor speed",
  [AlertType.SPV_CONNECTIVITY]: "Supervisor connectivity",
  [AlertType.USER_DEFINED]: "User Defined",
};

let motorErrorFilterOption: MultiSelectAutocompleteOptionType | undefined = undefined;

// Alert Types
if (ENABLED_FILTERS.alertTypes) {
  Object.keys(AlertType).forEach((key) => {
    const option = {
      group: FILTER_GROUP_ALERT_TYPE,
      label: ALERT_TYPE_LABELS[key],
      id: key,
      filter: "alertTypes",
    };

    // allow us to disable Motor Errors when specific errors are selected
    if (key === AlertType.MC_ERRORS) {
      motorErrorFilterOption = option;
    }

    FILTER_OPTIONS.push(option);
  });
}

// Merged filter values
// Some motor errors look identical to other ones to the end-user
// We implement this by including just one representative in the menu, but including a group in the query when we see the representative
const FILTER_MERGE_GROUPS = new Map(); // map filters to any duplicates to be sent along with them
FILTER_MERGE_GROUPS[MotorError.INVERTER_ENCLOSURE_OVERHEAT] = [
  MotorError.INVERTER_HEATSINK_OVERHEAT,
];
FILTER_MERGE_GROUPS[MotorError.SENSOR_A] = [MotorError.SENSOR_B, MotorError.SENSOR_C];

// Each ride-along filter should be a) on the right hand side above and b) included below.
// Ignored filters should also be included below.
const EXCLUDED_FILTERS = {
  [MotorError.INVERTER_HEATSINK_OVERHEAT]: true,
  [MotorError.SENSOR_B]: true,
  [MotorError.SENSOR_C]: true,
  [MotorError.OVERSPEEED]: true,
  [MotorError.BUS_CURRENT_HIGH]: true,
  [MotorError.DEVICE_COMMUNICATION_LOST]: true,
  [MotorError.EEPROM]: true,
  [MotorError.SENSOR_DC]: true,
  [MotorError.FAN_FAULT]: true,
};

// Motor Errors
if (ENABLED_FILTERS.motorErrors) {
  Object.keys(MotorError).forEach((key) => {
    if (!EXCLUDED_FILTERS[key]) {
      FILTER_OPTIONS.push({
        group: FILTER_GROUP_MOTOR_ERRORS,
        label: MOTOR_ERROR_MESSAGES[key],
        id: key,
        filter: "motorErrors",
      });
    }
  });
}

if (ENABLED_FILTERS.motorWarnings) {
  // Motor Warnings
  Object.keys(MotorWarning).forEach((key) => {
    FILTER_OPTIONS.push({
      group: FILTER_GROUP_MOTOR_WARNINGS,
      label: MOTOR_WARNING_MESSAGES[key],
      id: key,
      filter: "motorWarnings",
    });
  });
}

const OPTION_MAP = new Map();
FILTER_OPTIONS.forEach((option) => {
  OPTION_MAP[option.id] = option;
});

type GridLinkProps = {
  to: LocationDescriptor;
  children: React.ReactNode | React.ReactNode[];
  tooltip: string;
};

export const GridLink = ({ to, children, tooltip }: GridLinkProps) => (
  <TextLink to={to} style={{ color: Colors.ttBlue, fontWeight: 500 }} title={tooltip}>
    {children}
  </TextLink>
);

const renderSiteTitle = (params: GridRenderCellParams): ReactNode => {
  if (params && params.id) {
    return (
      <>
        <Typography
          fontSize={ALERT_GRID_FONT_SIZE}
          fontFamily="Barlow"
          title={params.row.site.name}
          mt={2}
          mb={2}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <GridLink
            to={`/sites/${unmarshalNumberId(params.row.site.id)}`}
            tooltip={`Tap to view site ${params.row.site.name}`}
          >
            {params.row.site.name}
            <br />
            <LightCaption style={{ textOverflow: "ellipsis" }}>
              <Typography component={"span"} fontSize={12} title={params.row.site.address || ""}>
                {params.row.site.address || ""}
              </Typography>
            </LightCaption>
          </GridLink>
        </Typography>
      </>
    );
  }
  return params.value;
};

const getSiteTitle = (params: GridValueGetterParams): string => {
  return params?.row?.site?.name ?? "";
};

const renderSiteOrg = (params: GridRenderCellParams): ReactNode => {
  if (params && params.id) {
    return (
      <Typography
        fontSize={ALERT_GRID_FONT_SIZE}
        fontFamily="Barlow"
        title={params.row.site.organization.name}
      >
        {params.row.site.organization.name}
      </Typography>
    );
  }
  return params.value;
};

const getSiteOrg = (params: GridValueGetterParams): string => {
  return params?.row?.site?.organization?.name ?? "";
};

const renderAlertMessage = (params: GridRenderCellParams): ReactNode => {
  if (params && params.id) {
    const devicePath = params.row.presenter.motorId
      ? `/sites/${unmarshalNumberId(params.row.site.id)}/motor/${params.row.presenter.motorId}`
      : `/sites/${unmarshalNumberId(params.row.site.id)}/config`;
    return (
      <Stack>
        <Typography
          fontSize={ALERT_GRID_FONT_SIZE}
          fontFamily="Barlow"
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          <GridLink
            to={`/alerts/${params.row.id}`}
            tooltip={`${params.row.presenter.description}\nTap to view alert`}
          >
            {params.row.presenter.message || params.row.presenter.title}
          </GridLink>
        </Typography>
        <LightCaption>
          <span title={`${params.row.presenter.deviceClass} ${params.row.presenter.deviceName}`}>
            {params.row.presenter.deviceClass} /{" "}
            <GridLink
              to={devicePath}
              tooltip={`Tap to view ${params.row.presenter.deviceClass} ${params.row.presenter.deviceName}`}
            >
              {params.row.presenter.deviceName}
            </GridLink>
          </span>
        </LightCaption>
      </Stack>
    );
  }
  return params.value;
};

const getAlertMessage = (params: GridValueGetterParams): string => {
  return params?.row?.presenter?.title ?? "";
};

const renderTriggeredMoment = (params: GridRenderCellParams): ReactNode => {
  if (params && params.id) {
    return (
      <TextLink to={`/alerts/${params.row.id}`} title="Tap to view alert">
        <AlertTriggeredMoment alert={params.row} />
      </TextLink>
    );
  }
  return params.value;
};

const renderStatus = (params: GridRenderCellParams): ReactNode => {
  if (params && params.id) {
    return (
      <TextLink to={`/alerts/${params.row.id}`} title="Tap to view alert">
        <AlertStatusBanner
          status={params.row.presenter.alert.status}
          label={params.row.presenter.status}
        />
      </TextLink>
    );
  }
  return params.value;
};

const getStatus = (params: GridValueGetterParams): string => {
  return params?.row?.presenter?.alert?.status ?? "";
};

const BASE_SELECTION_BAR_STYLE = {
  minHeight: "50px",
  border: "1px solid lightgrey",
} as any;

const SEARCH_GROUP = "Search";
const getSearchOption = (inputValue: string) => ({
  id: inputValue,
  label: `"${inputValue}"`,
  group: SEARCH_GROUP,
  filter: SITE_SEARCH,
});

const siteAlertFilter = (filter?: AlertFilter) => {
  return {
    ...filter,
    sites: [],
  };
};

const applyPageSize = (pageSize: number, alertsQueryParams: AlertsGridVariables) => {
  alertsQueryParams.first && (alertsQueryParams.first = pageSize);
  alertsQueryParams.last && (alertsQueryParams.last = pageSize);
};

function AlertsGridComponent({ siteId }: AlertsGridProps) {
  // *** Load alerts ***
  const initialFilter = DEFAULT_FILTER;
  if (siteId) {
    initialFilter.sites = [siteId];
  }
  const [urlProcessed, setUrlProcessed] = useState(false);
  const { alertsQueryParams, setAlertsQueryParams, alertsQuery } = useAlertsGridQuery(
    initialFilter,
    DEFAULT_SORT,
    !urlProcessed
  );
  const alerts = alertsQuery.data?.alerts?.edges;

  // State for Sites autocomplete combobox
  const [selectedSites, setSelectedSites] = useState<MultiSelectAutocompleteOptionType[]>([]);
  const [siteSearch, setSiteSearch] = useState<string>("");
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  // Two batches of sites: the default ones
  const { alertsSitesQueryParams, setAlertsSitesQueryParams, alertsSitesQuery } =
    useAlertsSitesQuery(DEFAULT_FILTER);
  const suggestedSites = alertsSitesQuery.data?.sites?.edges;
  // ... and any explicitly-sought sites
  const [getSearchedSites, searchedSitesQuery] = useLazyQuery(ALERTS_SITES_QUERY);
  const searchedSites = searchedSitesQuery.data?.sites?.edges;

  // Only query free-text site search when siteSearch changes
  const fetchSearchedSites = useCallback(
    (search, alertFilter) => {
      getSearchedSites({
        variables: {
          alertFilter: alertFilter,
          first: MAX_QUERY_SITES,
          search: search,
        },
      });
    },
    [getSearchedSites]
  );

  // *** URL can contain a site and/or query params ***
  const [getSite, siteQuery] = useLazyQuery(SITE_NODE_QUERY);
  const [filters, queryParamsLoaded, saveQuery] = useQueryParams<AlertsGridQueryParams>(
    QUERY_PARAM_NAME,
    {
      search: "",
      filters: DEFAULT_FILTER,
      orderBy: DEFAULT_SORT,
      pageSize: DEFAULT_PAGE_SIZE,
    }
  );

  if (queryParamsLoaded && !urlProcessed) {
    // Only parse the URL once per page load, to allow clearing the Site & filters.
    setUrlProcessed(true);

    // TODO: Sites page should use the query string as a 1-site filter, instead of using the path
    if (
      filters.filters !== DEFAULT_FILTER ||
      filters.orderBy !== DEFAULT_SORT ||
      (filters.pageSize && filters.pageSize !== DEFAULT_PAGE_SIZE)
    ) {
      // Filters gets precedence over a Site ID in the route, as there are no filters when coming directly from a Site.
      // When returning to this page from an alert etc., if there was a Site, it'll now be in the filter,
      // so at least in that case it is safe to ignore the site in the route.
      // If we were to force the route site when there's a filter, "Back to Alerts" would behave unexpectedly
      // in the case the user had removed the route site from the filter.

      // Restore filters from the URL query params
      const newQueryParams = {
        ...alertsQueryParams,
        filter: filters.filters,
        orderBy: filters.orderBy,
      };
      if (filters.pageSize && filters.pageSize !== DEFAULT_PAGE_SIZE) {
        setPageSize(filters.pageSize);
        applyPageSize(filters.pageSize, newQueryParams);
      }
      setAlertsQueryParams(newQueryParams);
    }
    if (filters.filters !== DEFAULT_FILTER) {
      const alertFilter = siteAlertFilter(filters.filters);
      setAlertsSitesQueryParams({
        ...alertsSitesQueryParams,
        alertFilter: alertFilter,
      });

      if (filters.search) {
        setSiteSearch(filters.search);
        fetchSearchedSites(filters.search, alertFilter);
      }

      if (filters.sites) {
        setSelectedSites(
          filters.sites.map((site) => ({ ...site, filter: "sites", group: FILTER_GROUP_SITE }))
        );
      }
    } else if (siteId && !siteQuery.loading) {
      getSite({ variables: { siteId } });
    }
  }

  // Effect 1: Wait for Site Node query result
  // Load the site in the route, if present
  // TODO: Remove this effect if we change Sites to use the query string filter instead of the path
  const [urlSiteProcessed, setUrlSiteProcessed] = useState(false);
  useEffect(() => {
    if (siteId && !siteQuery.loading && siteQuery.data && !urlSiteProcessed) {
      setSelectedSites([mapSiteToOption(siteQuery.data, FILTER_GROUP_SITE)]);
      setUrlSiteProcessed(true);
    }
  }, [siteId, siteQuery.loading, siteQuery.data, urlSiteProcessed, setUrlSiteProcessed]);

  // Effect 2: Assemble the sites filter from a) search results and b) explicitly selected sites
  useEffect(() => {
    if (queryParamsLoaded && !searchedSitesQuery.loading && !alertsQuery.loading) {
      let siteIds: string[] = [];
      if (siteSearch) {
        siteIds = searchedSites?.map((site: AlertsSites_sites_edges) => site.node.id) || [];
      }
      siteIds = uniq(
        siteIds.concat(selectedSites?.map((option) => getOpaqueSiteId(option.id)) || [])
      );

      if (!isEqual(siteIds, alertsQueryParams.filter?.sites)) {
        const params = cloneDeep(alertsQueryParams);
        if (!params.filter) {
          params.filter = {};
        }
        params.filter.sites = siteIds;
        setAlertsQueryParams(params);
      }
    }
  }, [
    alertsQuery.loading,
    alertsQueryParams,
    queryParamsLoaded,
    searchedSites,
    searchedSitesQuery.loading,
    selectedSites,
    setAlertsQueryParams,
    siteSearch,
  ]);

  // TODO: Effect 3: determine if search has failed and provide feedback if so

  const { enqueueSnackbar } = useSnackbar();

  // *** Load options for Sites combobox ***
  const sitesOptions = useSitesOptions(suggestedSites, searchedSites, siteSearch, enqueueSnackbar);

  // Effect 4: Keep total across renders, for the grid's sake
  // Reference: https://mui.com/x/react-data-grid/pagination/#server-side-pagination
  const totalCount = alertsQuery.data?.alerts?.totalCount;
  const [rowCountState, setRowCountState] = useState<number>(totalCount ?? 0);
  useEffect(() => {
    setRowCountState((prevRowCountState) => totalCount ?? prevRowCountState);
  }, [setRowCountState, totalCount]);

  // *** Server-side Pagination ***
  const [page, setPage] = useState(0);
  const loadPage = useCallback(
    (newPage: number, _: GridCallbackDetails) => {
      if (alertsQuery.loading || alertsQuery.error || !alertsQuery.data) {
        return;
      }

      const variables = { includeCount: true };
      if (newPage >= page) {
        Object.assign(variables, {
          first: alertsQueryParams.first,
          last: 0,
          after: newPage ? alertsQuery.data!.alerts.pageInfo.endCursor : null,
          before: null,
          filter: alertsQueryParams.filter,
        });
      } else {
        Object.assign(variables, {
          first: 0,
          last: alertsQueryParams.first,
          after: null,
          before: alertsQuery.data!.alerts.pageInfo.startCursor,
          filter: alertsQueryParams.filter,
        });
      }
      setPage(newPage);
      alertsQuery.fetchMore({
        variables: variables,
        updateQuery: (previousResult: any, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult;
          }

          const allAlerts: any = Object.assign({}, previousResult.alerts, {
            edges: [...fetchMoreResult.alerts.edges],
            pageInfo: fetchMoreResult.alerts.pageInfo,
            totalCount: fetchMoreResult.alerts.totalCount,
          });

          return { alerts: allAlerts };
        },
      });
    },
    [alertsQueryParams, alertsQuery, page]
  );

  // Effect 5: detect repagination
  useEffect(() => {
    if (
      !alertsQuery.loading &&
      alertsQueryParams.first !== pageSize &&
      alertsQueryParams.last !== pageSize
    ) {
      const newQueryParams = { ...alertsQueryParams };
      applyPageSize(pageSize, newQueryParams);
      setAlertsQueryParams(newQueryParams);
    }
  }, [alertsQuery.loading, alertsQueryParams, loadPage, pageSize, setAlertsQueryParams]);

  // *** Row action menu handler ***
  const menu = useMenuState();
  const [actionAlert, setActionAlert] = useState<any>(null);
  const handleMenuOpen = (alert: AlertsGrid_alerts_edges_node) => (event: React.MouseEvent) => {
    setActionAlert(alert);
    menu.handleOpen(event);
  };

  const renderActionMenu = (params: GridRenderCellParams): ReactNode => (
    <ActionMenuButton onClick={handleMenuOpen(params.row.presenter.alert)} />
  );

  const { ackAlert, ackResult } = useAckAlertMutation();
  const { closeAlert, closeResult } = useCloseAlertMutation();
  const ack = (alert: AlertsGrid_alerts_edges_node) => ackAlert(alert.id);
  const close = (alert: AlertsGrid_alerts_edges_node) => closeAlert(alert.id);

  // Effect 6: Handle data loading errors
  const error = ackResult.error || closeResult.error;
  useEffect(() => {
    if (error) {
      enqueueSnackbar(presentError("There was an error updating the alert", error), {
        variant: "error",
      });
    }
    if (alertsQuery.error) {
      enqueueSnackbar("There was an error retrieving alerts.", {
        variant: "error",
      });
    }
  }, [error, alertsQuery.error, enqueueSnackbar]);

  const performingAction = ackResult.loading || closeResult.loading;
  const canAck =
    actionAlert && actionAlert.status === AlertStatusEnum.TRIGGERED && !performingAction;
  const canClose =
    actionAlert && actionAlert.canBeClosed && actionAlert.site.viewerIsAdmin && !performingAction;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const ungroupedColumns: GridColumns = [
    createColumn({
      field: "site",
      headerName: "Site",
      minWidth: 100,
      flex: isMobile ? undefined : 0.8,
      width: isMobile ? 160 : undefined,
      renderCell: renderSiteTitle,
      valueGetter: getSiteTitle,
      sortable: false,
    }),
    createColumn({
      field: "org",
      headerName: "Organization",
      minWidth: 104,
      flex: isMobile ? undefined : 0.8,
      width: isMobile ? 180 : undefined,
      maxWidth: 180,
      renderCell: renderSiteOrg,
      valueGetter: getSiteOrg,
      sortable: false,
    }),
    createColumn({
      field: "alert",
      headerName: "Alert Message",
      minWidth: 120,
      flex: isMobile ? undefined : 1,
      width: isMobile ? 160 : undefined,
      renderCell: renderAlertMessage,
      valueGetter: getAlertMessage,
      sortable: false,
    }),
    createColumn({
      field: "createdAt",
      headerName: "Triggered",
      minWidth: 126,
      flex: isMobile ? undefined : 1,
      width: isMobile ? 165 : undefined,
      maxWidth: 165,
      renderCell: renderTriggeredMoment,
      sortable: true,
    }),
    createColumn({
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: isMobile ? undefined : 1,
      width: isMobile ? 145 : undefined,
      maxWidth: 145,
      renderCell: renderStatus,
      valueGetter: getStatus,
      sortable: false,
    }),
    createColumn({
      field: "Action",
      headerName: "",
      width: 3,
      renderCell: renderActionMenu,
      sortable: false,
    }),
  ];

  const ungroupedRows: GridValidRowModel[] = alerts?.length
    ? alerts
        .map(({ node: alert }) => {
          try {
            const presenter = newAlertPresenter(alert);
            return {
              ...alert,
              presenter,
            };
          } catch (e) {
            // newAlertPresenter will throw if needed site data is not passed.
            // Rows for such alerts are filtered out below.
            return { id: undefined };
          }
        })
        .filter((row) => row.id)
    : [];

  // Prevent flicker of incorrect message while loading & rendering grid
  const loading =
    alertsQuery.loading ||
    alertsQuery.networkStatus !== NetworkStatus.ready ||
    alertsSitesQuery.loading ||
    searchedSitesQuery.loading;
  const noRowsMessage = loading || alerts?.length ? LOADING_MESSAGE : NO_ROWS_MESSAGE;

  // When any filter changes:
  // a) return to the first page, to avoid confusing pagination
  // b) clear selection, to prevent permanent selection orphans
  const resetPage = () => {
    setPage(0);
    setSelectionModel([]);
  };

  const onSiteFilterChange = (
    event: React.SyntheticEvent,
    value: AutocompleteValue<MultiSelectAutocompleteOptionType, true, true, false>,
    reason: AutocompleteChangeReason
  ) => {
    const siteOptions: MultiSelectAutocompleteOptionType[] = [];
    let search = "";
    if (value && value.length) {
      value.forEach((option) => {
        // look for a search term
        if (option.filter === SITE_SEARCH) {
          search = option.id;
        } else {
          siteOptions.push(option);
        }
      });
    }
    resetPage();
    if (siteSearch !== search) {
      setSiteSearch(search);
      const alertFilter = siteAlertFilter(alertsQueryParams.filter || undefined);
      fetchSearchedSites(search, alertFilter);
    }
    setSelectedSites(siteOptions);
    saveQuery(QUERY_PARAM_NAME, {
      search,
      filters: alertsQueryParams.filter || undefined,
      sites: siteOptions.map((option) => ({ id: option.id, label: option.label })),
      orderBy: alertsQueryParams.orderBy || undefined,
      pageSize: pageSize,
    });
  };

  // Effect 7: Update Site filter chips' alert counts
  useEffect(() => {
    const predicate =
      (option: MultiSelectAutocompleteOptionType) => (site: AlertsSites_sites_edges) =>
        site.node.id === getOpaqueSiteId(option.id);
    if (!(alertsSitesQuery.loading || searchedSitesQuery.loading)) {
      // Using the functional updater form of setState to avoid creating an infinite loop by depending on selectedSites
      setSelectedSites((currentSelectedSites) =>
        currentSelectedSites.map((siteOption) => {
          const siteData =
            suggestedSites?.find(predicate(siteOption)) ||
            searchedSites?.find(predicate(siteOption)) ||
            siteQuery.data;
          return siteData ? mapSiteToOption(siteData, FILTER_GROUP_SITE) : siteOption;
        })
      );
    }
  }, [searchedSites, searchedSitesQuery, suggestedSites, alertsSitesQuery, siteQuery.data]);

  const getSiteFilterValue = () => {
    const options: MultiSelectAutocompleteOptionType[] = selectedSites.slice();
    const searchOption = sitesOptions.find((option) => option.group === SEARCH_GROUP);
    if (searchOption) {
      options.push(searchOption);
    }

    return options;
  };

  const onStatusFilterChange = (
    event: React.SyntheticEvent,
    value: AutocompleteValue<MultiSelectAutocompleteOptionType, true, true, false>,
    reason: AutocompleteChangeReason
  ) => {
    const filter = {
      ...alertsQueryParams.filter,
    };

    // Clear all filters managed by the Filters autocomplete
    Object.keys(ENABLED_FILTERS).forEach((key) => {
      if (ENABLED_FILTERS[key]) {
        filter[key] = undefined;
      }
    });

    let hasSpecificMotorErrorFilter = false;

    if (value && value.length) {
      value.forEach((option) => {
        filter[option.filter] = [];
      });
      value.forEach((option) => {
        filter[option.filter].push(option.id);
        if (FILTER_MERGE_GROUPS[option.id]) {
          filter[option.filter].push(...FILTER_MERGE_GROUPS[option.id]);
        }
        if (option.group === FILTER_GROUP_MOTOR_ERRORS) {
          hasSpecificMotorErrorFilter = true;
        }
      });
    }

    // Unselect the motor errors chip if any of the individual motor errors are selected
    if (hasSpecificMotorErrorFilter) {
      const motorErrorsIndex = filter.alertTypes?.indexOf(AlertType.MC_ERRORS) ?? -1;
      if (motorErrorsIndex > -1) {
        filter.alertTypes?.splice(motorErrorsIndex, 1);
      }
    }

    resetPage();
    setAlertsQueryParams({
      ...alertsQueryParams,
      filter,
    });
    setAlertsSitesQueryParams({ ...alertsSitesQueryParams, alertFilter: filter });
    saveQuery(QUERY_PARAM_NAME, {
      search: siteSearch,
      filters: filter,
      sites: selectedSites,
      orderBy: alertsQueryParams.orderBy || undefined,
      pageSize: pageSize,
    });
  };

  // Effect 8: disable Motor errors if a specific error is selected
  useEffect(() => {
    if (motorErrorFilterOption) {
      const hasMotorErrors = !!alertsQueryParams?.filter?.motorErrors?.length;
      motorErrorFilterOption.disabled = hasMotorErrors;
    }
  }, [alertsQueryParams.filter]);

  const getStatusFilterValue = () => {
    const options: MultiSelectAutocompleteOptionType[] = [];
    if (alertsQueryParams?.filter) {
      Object.keys(alertsQueryParams.filter).forEach((key) => {
        if (alertsQueryParams.filter) {
          alertsQueryParams.filter[key]?.forEach((id: string) => {
            const statusOption = OPTION_MAP[id];
            if (statusOption) {
              options.push(statusOption);
            }
          });
        }
      });
    }
    return options;
  };

  const toastAlert = (verb: string, count: number) =>
    !error &&
    enqueueSnackbar(`${verb} ${count} alert${count === 1 ? "" : "s"}`, { variant: "success" });

  // Multiple Selection, preserved across pages
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const actionSelectedAlerts = (
    verb: string,
    predicate: (alert?: AlertsGrid_alerts_edges) => boolean
  ) => {
    let count = 0;
    selectionModel.forEach((value: GridRowId) => {
      const alert = alerts?.find(({ node: alert }) => alert.id === value);
      count += predicate(alert) ? 1 : 0;
    });
    toastAlert(verb, count);
    setSelectionModel([]);
  };
  const acknowledgeSelected = () => {
    actionSelectedAlerts(ACKNOWLEDGED_LABEL, (alert?: AlertsGrid_alerts_edges) => {
      if (alert && alert.node.canBeAcked && alert.node.status !== AlertStatusEnum.ACKED) {
        ack(alert.node);
        return true;
      }
      return false;
    });
  };
  const closeSelected = () => {
    actionSelectedAlerts(CLOSED_LABEL, (alert?: AlertsGrid_alerts_edges) => {
      if (alert && alert.node.canBeClosed && alert.node.status !== AlertStatusEnum.CLOSED) {
        close(alert.node);
        return true;
      }
      return false;
    });
  };

  // Color the selection bar only when there are selected rows
  const selectionBarStyle = selectionModel.length
    ? { ...BASE_SELECTION_BAR_STYLE, backgroundColor: "rgb(237, 243, 251)" }
    : BASE_SELECTION_BAR_STYLE;

  // Controlled, server-side sorting
  const handleSortModelChange = React.useCallback(
    (sortModel: GridSortModel) => {
      // Here you save the data you need from the sort model
      const orderBy = {};
      sortModel.forEach((model) => {
        if (model.sort) {
          orderBy[model.field] = model.sort === "asc" ? Sort.ASC : Sort.DESC;
        }
      });
      setAlertsQueryParams({ ...alertsQueryParams, orderBy });
      saveQuery(QUERY_PARAM_NAME, {
        search: siteSearch,
        filters: alertsQueryParams.filter || undefined,
        sites: selectedSites,
        orderBy: orderBy,
        pageSize: pageSize,
      });
    },
    [alertsQueryParams, setAlertsQueryParams, saveQuery, selectedSites, siteSearch, pageSize]
  );
  const sort =
    Object.values(alertsQueryParams.orderBy || DEFAULT_SORT)[0] === Sort.ASC ? "asc" : "desc";
  const orderBy = alertsQueryParams.orderBy || DEFAULT_SORT;
  const sortField = Object.keys(orderBy)[0] || Object.keys(DEFAULT_SORT)[0];

  return (
    <>
      <LeftAlignedHeader loading={loading} title="Alerts"></LeftAlignedHeader>
      <Container paddingBottom={isMobile ? "80px" : "8px"}>
        <Grid item container xs={12}>
          <Grid
            item
            container
            xs={12}
            spacing={0.5}
            justifyContent="center"
            sx={{ fontFamily: "Barlow" }}
          >
            <Grid item xs={6}>
              <MultiSelectAutocomplete
                id="site-combo-box"
                options={sitesOptions}
                onChange={onSiteFilterChange}
                value={!loading && sitesOptions?.length ? getSiteFilterValue() : []}
                title="Site"
                autoFocus
                filterOptions={(options, params) => {
                  // Insert "Search" at the start of the menu if no matches
                  const filtered = filterMultiSelectAutocompleteOptions(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some((option) => inputValue === option.label);
                  if (inputValue !== "" && !isExisting) {
                    filtered.splice(0, 0, getSearchOption(inputValue));
                  }

                  return filtered;
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <MultiSelectAutocomplete
                id="filter-combo-box"
                options={FILTER_OPTIONS}
                onChange={onStatusFilterChange}
                value={loading ? [] : getStatusFilterValue()}
                title="Filter"
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                display={"flex"}
                sx={selectionBarStyle}
                alignItems="center"
                justifyContent="space-between"
                mb={1}
              >
                <Box>
                  <Typography ml={2} fontFamily="Barlow" variant="body2">
                    {`${selectionModel.length} selected`}
                  </Typography>
                </Box>
                <Stack direction="row" spacing={1} mr={1}>
                  <ActionConfirmationDialog
                    actionName="Acknowledge"
                    actionCount={selectionModel.length}
                    objectName={selectionModel.length === 1 ? "alert" : "alerts"}
                    callback={acknowledgeSelected}
                    disabled={!selectionModel.length}
                    iconName="eye"
                  />
                  <ActionConfirmationDialog
                    actionName="Close"
                    actionCount={selectionModel.length}
                    objectName={selectionModel.length === 1 ? "alert" : "alerts"}
                    callback={closeSelected}
                    disabled={!selectionModel.length}
                    iconName="check"
                  />
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DataTable
              autoHeight
              columns={ungroupedColumns}
              rows={ungroupedRows}
              rowCount={rowCountState}
              pagination
              paginationMode="server"
              pageSize={pageSize}
              page={page}
              onPageChange={loadPage}
              onPageSizeChange={(newPageSize) => {
                setPage(0);
                setPageSize(newPageSize);
                saveQuery(QUERY_PARAM_NAME, {
                  search: siteSearch,
                  filters: alertsQueryParams.filter || undefined,
                  sites: selectedSites,
                  orderBy: alertsQueryParams.orderBy || undefined,
                  pageSize: newPageSize,
                });
              }}
              rowsPerPageOptions={[DEFAULT_PAGE_SIZE, 50, 100]}
              checkboxSelection
              disableSelectionOnClick
              keepNonExistentRowsSelected
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              sortingOrder={[sort, sort === "asc" ? "desc" : "asc"]}
              sortModel={[
                {
                  field: sortField,
                  sort: sort,
                },
              ]}
              noRowsMessage={`${noRowsMessage}`}
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
            />
          </Grid>
        </Grid>
      </Container>
      <ActionMenu {...menu.props} disableAutoFocusItem={true} onClick={menu.handleClose}>
        <ActionMenuItem disabled={!canAck} onClick={() => ack(actionAlert)}>
          <Typography fontFamily="Barlow">Acknowledge</Typography>
        </ActionMenuItem>
        <ActionMenuItem disabled={!canClose} onClick={() => close(actionAlert)}>
          <Typography fontFamily="Barlow">Close</Typography>
        </ActionMenuItem>
      </ActionMenu>
    </>
  );
}

const alertCount = (site: AlertsSites_sites_edges) => site.node.filteredAlertCount ?? 0;

const mapSiteToOption = (site: AlertsSites_sites_edges, group: string) =>
  ({
    id: `${unmarshalNumberId(site.node.id)}`,
    label: `${site.node.name} (${alertCount(site)})`,
    group: group,
    filter: "sites",
  } as MultiSelectAutocompleteOptionType);

const mapSitesToOptions = (sites: AlertsSites_sites_edges[], group: string) =>
  sites
    .filter((site) => alertCount(site))
    .sort((a, b) => alertCount(b) - alertCount(a))
    .map((site) => mapSiteToOption(site, group));

const useSitesOptions = (
  suggestedSites: AlertsSites_sites_edges[],
  searchedSites: AlertsSites_sites_edges[],
  siteSearch: string,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey
) => {
  const [sitesOptions, setSitesOptions] = useState<MultiSelectAutocompleteOptionType[]>([]);

  // Effect 9: useSitesOptions
  useEffect(() => {
    if (!(suggestedSites?.length || searchedSites?.length)) {
      return;
    }
    let options: MultiSelectAutocompleteOptionType[] = [];

    if (siteSearch) {
      options.push(getSearchOption(siteSearch));
    }
    if (siteSearch && searchedSites?.length) {
      options = options.concat(
        mapSitesToOptions(searchedSites, `Search results for: ${siteSearch}`)
      );
      if (!options.length) {
        // Feedback for search turning up sites with only closed alerts
        enqueueSnackbar(
          `No alerts found for ${searchedSites.length} sites matching ${siteSearch}`,
          { variant: "success" }
        );
      }
    }
    if (suggestedSites?.length) {
      const suggestedOptions = mapSitesToOptions(suggestedSites, FILTER_GROUP_SITE);
      options = options.concat(suggestedOptions);
    }
    if (options.length) {
      setSitesOptions(uniqBy(options, "id"));
    }
  }, [enqueueSnackbar, searchedSites, siteSearch, suggestedSites]);
  return sitesOptions;
};

export const AlertsGrid = AlertsGridComponent;
