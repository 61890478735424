import * as React from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";

const inputStyles = createStyles({
  root: {
    borderRadius: 50,
    "&$focused::after": {
      borderRadius: 50,
    },
  },
  focused: {},
});

function SearchFieldComponent(props: TextFieldProps & WithStyles<typeof inputStyles>) {
  const { classes, ...other } = props;
  return (
    <TextField
      placeholder="Search"
      type="search"
      fullWidth={true}
      InputProps={{
        classes: classes,
        startAdornment: (
          <InputAdornment position="start">
            <i className="fa fa-search" />
          </InputAdornment>
        ),
      }}
      {...other}
    />
  );
}

export const SearchField = withStyles(inputStyles)(SearchFieldComponent);
