import { gql } from "@apollo/client";
import {
  UpdateOrgAlertSettingsInput,
  UpdateSiteAlertSettingsInput,
} from "generated-gql-types/globalTypes";
import { UpdateOrgAlertSettings } from "generated-gql-types/UpdateOrgAlertSettings";
import { UpdateSiteAlertSettings } from "generated-gql-types/UpdateSiteAlertSettings";
import { composeMutationHook } from "./helpers";

const ALERT_SETTINGS_FRAGMENT = gql`
  fragment AlertSettings on AlertSettings {
    spvConnSettings {
      entryDelay {
        value
        setAt
      }
    }
    motorConnSettings {
      entryDelay {
        value
        setAt
      }
    }
    speedMismatchSettings {
      rpmThreshold {
        value
        setAt
      }
    }
  }
`;

const updateSiteAlertSettingsMutation = gql`
  ${ALERT_SETTINGS_FRAGMENT}
  mutation UpdateSiteAlertSettings($input: UpdateSiteAlertSettingsInput!) {
    updateSiteAlertSettings(input: $input) {
      site {
        id
        alertSettings {
          ...AlertSettings
        }
      }
    }
  }
`;

export const useUpdateSiteAlertSettingsMutation = composeMutationHook<
  UpdateSiteAlertSettings,
  UpdateSiteAlertSettingsInput
>(updateSiteAlertSettingsMutation);

const updateOrgAlertSettingsMutation = gql`
  ${ALERT_SETTINGS_FRAGMENT}
  mutation UpdateOrgAlertSettings($input: UpdateOrgAlertSettingsInput!) {
    updateOrgAlertSettings(input: $input) {
      organization {
        id
        alertSettings {
          ...AlertSettings
        }
      }
    }
  }
`;

export const useUpdateOrgAlertSettingsMutation = composeMutationHook<
  UpdateOrgAlertSettings,
  UpdateOrgAlertSettingsInput
>(updateOrgAlertSettingsMutation);
