import * as React from "react";
import { observer } from "mobx-react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { Grid } from "@mui/material";
import memoizeOne from "memoize-one";

import { Series, SeriesCollection } from "modules/charts/series";
import { BasicTimeseriesChart } from "modules/charts/components/BasicTimeseriesChart";
import { DURATION } from "modules/common/utils/time";
import { APIStore, injectStores } from "modules/common/stores";
import { MotorOpStat } from "modules/site-manager/constants/motor-op";
import { Motor, MotorOp } from "modules/site-manager/models";
import { AnalysisStore, SiteStore } from "modules/site-manager/stores";
import { createSeriesForMotorOpField } from "modules/charts/series";
import { extractYAxisProps } from "modules/charts/axes";
import QueryStatsSharp from "@mui/icons-material/QueryStatsSharp";
import { FancyDivider } from "../FancyDivider";
import { YAxis } from "modules/charts/constants";
import { SOFT_BOX_SHADOW, BOXED_CARD_STYLE } from "modules/site-manager/utils/cardStyles";

type MotorOperationSectionProps = {
  motor: Motor;
};

export enum ChartKey {
  SPEED = "speed",
  POWER = "power",
}

export type Chart = {
  key: ChartKey;
  name: string;
  stats: MotorOpStat[];
  axes: any[];
  units: string[];
};

export const SPEED_UNIT = " RPM";
export const TORQUE_UNIT = " Nm";
export const POWER_UNIT = " W";
export const CURRENT_UNIT = " A";

export const getChart = (motor: Motor, chartKey: ChartKey): Chart => {
  if (chartKey === ChartKey.SPEED) {
    return {
      key: ChartKey.SPEED,
      name: "Speed",
      stats: [MotorOpStat.CurrentSpeed, MotorOpStat.RequestedSpeed, MotorOpStat.Torque],
      axes: [YAxis.Left, YAxis.Left, YAxis.Right],
      units: [SPEED_UNIT, SPEED_UNIT, TORQUE_UNIT],
    };
  }

  const stats = (
    motor?.hasTurntidePower ? [MotorOpStat.TTPower] : [MotorOpStat.PowerIn, MotorOpStat.PowerOut]
  ).concat([MotorOpStat.CurrentA, MotorOpStat.CurrentB, MotorOpStat.CurrentC]);

  return {
    key: ChartKey.POWER,
    name: "Power",
    stats: stats,
    axes: [YAxis.Left, YAxis.Left, YAxis.Right, YAxis.Right, YAxis.Right],
    units: [POWER_UNIT, POWER_UNIT, CURRENT_UNIT, CURRENT_UNIT, CURRENT_UNIT],
  };
};

const OPS_CHART_YAXIS_WIDTH = 60;

const getYAxisProps = (analysisStore: AnalysisStore) =>
  extractYAxisProps(analysisStore.selectedFields).map((props) => ({
    ...props,
    width: OPS_CHART_YAXIS_WIDTH,
    tick: { fontSize: 9, fill: "black" },
  }));

@injectStores("api")
@observer
class MotorOperationSectionComponent extends React.Component<
  MotorOperationSectionProps & {
    apiStore?: APIStore;
  } & RouteComponentProps<{ site_id: any }>
> {
  analysisStoreConstructor = memoizeOne((motor: Motor, chart: Chart) => {
    const { apiStore } = this.props;
    if (!apiStore) {
      throw new Error("API store unavailable");
    }

    // FIXME - break the dependency of AnalysisStore on SiteStore
    let analysisStore = new AnalysisStore(apiStore, {} as SiteStore);
    analysisStore.setStartTime(new Date(Date.now() - DURATION.DAY));

    chart.stats.forEach((stat, index) => {
      const newField = analysisStore.addMotorOpField(new MotorOp(motor, stat));
      analysisStore.setAxis(newField, chart.axes[index]);
      analysisStore.setUnits(newField, chart.units[index]);
    });

    return analysisStore;
  });

  analysisStoreMemo: AnalysisStore[] = [];
  constructor(
    props: Readonly<
      MotorOperationSectionProps & { apiStore?: APIStore | undefined } & RouteComponentProps<any>
    >
  ) {
    super(props);
    this.analysisStoreMemo[0] = this.analysisStoreConstructor(
      this.props.motor,
      getChart(this.props.motor, ChartKey.SPEED)
    );
    this.analysisStoreMemo[1] = this.analysisStoreConstructor(
      this.props.motor,
      getChart(this.props.motor, ChartKey.POWER)
    );
  }

  get analysisUrl() {
    const { match, motor } = this.props;
    const stats = getChart(motor, ChartKey.SPEED).stats.concat(
      getChart(motor, ChartKey.POWER).stats
    );

    const metrics = stats
      .map((stat) => `${motor.id}$${stat.toString()}`)
      .filter((stat) => !stat.includes("current"));

    return `/sites/${match!.params.site_id}/analysis?v=${metrics.join(",")}${
      this.analysisStoreMemo[1] ? this.analysisStoreMemo[1].dateQueryParam : ""
    }`;
  }

  getMotorSeries(i: number) {
    const { selectedMotorOpFields } = this.analysisStoreMemo[i];
    return selectedMotorOpFields.reduce((collection, field) => {
      collection.push(createSeriesForMotorOpField(field));
      return collection;
    }, [] as Series[]);
  }

  render() {
    return (
      <>
        <Grid item xs={12} pl={1} mb={1}>
          <FancyDivider icon={<QueryStatsSharp />}>Operation</FancyDivider>
        </Grid>
        <Grid container spacing={1} pl={2}>
          <Grid container justifyContent="stretch" sx={SOFT_BOX_SHADOW}>
            <Grid container justifyContent="stretch" sx={BOXED_CARD_STYLE}>
              <Grid container justifyContent="flex-end">
                <Link to={this.analysisUrl} style={{ paddingRight: "8px" }}>
                  <Typography variant="overline">
                    View Analysis
                    <i className="fas fa-external-link-alt" style={{ marginLeft: "8px" }} />
                  </Typography>
                </Link>
              </Grid>
              <Grid item justifyContent="stretch" ml={3} mr={1}>
                <Typography variant="h5">Speed and Torque</Typography>
              </Grid>
              <Grid item={true} justifyContent="stretch" xs={12} pl={1} pr={0}>
                <BasicTimeseriesChart
                  key="speedAndTorque"
                  data={this.analysisStoreMemo[0].zippedTimeseriesBuckets}
                  tsKey={"timestamp"}
                  series={new SeriesCollection(...this.getMotorSeries(0))}
                  isLoading={this.analysisStoreMemo[0].isLoadingMotors}
                  yAxisPropsSet={getYAxisProps(this.analysisStoreMemo[0])}
                />
              </Grid>
              <Grid item justifyContent="stretch" ml={3} mr={1} mt={2}>
                <Typography variant="h5">Power and Current</Typography>
              </Grid>
              <Grid item xs={12} pl={1} pr={0}>
                <BasicTimeseriesChart
                  key="powerAndCurrent"
                  data={this.analysisStoreMemo[1].zippedTimeseriesBuckets}
                  tsKey={"timestamp"}
                  series={new SeriesCollection(...this.getMotorSeries(1))}
                  isLoading={this.analysisStoreMemo[1].isLoadingMotors}
                  yAxisPropsSet={getYAxisProps(this.analysisStoreMemo[1])}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
export const MotorOperationSection = withRouter(MotorOperationSectionComponent);
