import { Theme } from "@material-ui/core";
import { Health } from "modules/common/enums";
import { Colors } from "sigil";
// getColorForHealth returns the appropriate hex color for each Health status.
// These colors are hard-coded rather than included in the Material UI theme
// because they should remain constant regardless of theme (they carry semantic
// value to the user). They *may* match certain colors in the theme and small
// variances should be kept to a minimum (i.e. two greens that are almost
// identical should be avoided – make them the same green)
export function getColorForHealth(_: Theme, health: Health) {
  switch (health) {
    case Health.HEALTHY:
      return Colors.grass;
    case Health.WARNING:
      return Colors.sunflower;
    case Health.ERROR:
      return Colors.rust;
    case Health.GERIATRIC:
      return Colors.ttBlueLight;
    case Health.UNKNOWN:
    default:
      return Colors.tin;
  }
}
