import { computed } from "mobx";

import { EnergyDataStat } from "modules/site-manager/constants/energy-data";

type EnergyDataStatDefinition = {
  name: string;
};

const definitions: { [key in EnergyDataStat]: EnergyDataStatDefinition } = {
  [EnergyDataStat.BaselinePower]: {
    name: "Baseline Motor Power",
  },
  [EnergyDataStat.TTPower]: {
    name: "Turntide Power",
  },
  [EnergyDataStat.TTPowerWithCompressorPenalty]: {
    name: "Turntide Power (with compressor penalty)",
  },
};

export class EnergyDataStatPresenter {
  constructor(private stat: EnergyDataStat) {}

  @computed
  get name() {
    return definitions[this.stat].name;
  }
}
