import * as React from "react";
import { TextFieldProps, TextField } from "sigil";
import { Field as FormikField, FieldProps } from "formik";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export type FormikTextFieldProps = TextFieldProps & {
  // Name is required to connect to formik value
  name: string;
};

// FormikTextField is a sigil TextField connected to a Formik controlled form context
// Ref: https://jaredpalmer.com/formik/docs/api/field
export function FormikTextField(props: FormikTextFieldProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <FormikField name={props.name}>
      {({ field, form }: FieldProps) => {
        const fieldError = form.errors[field.name];
        const showError =
          form.touched[field.name] &&
          field.value !== form.initialValues[field.name] &&
          !!fieldError;
        return (
          <TextField
            {...field}
            {...props}
            error={showError}
            helperText={showError ? fieldError : props.helperText}
            disabled={form.isSubmitting || props.disabled}
            style={{ fontSize: isMobile ? "16px" : "inherit" }}
          />
        );
      }}
    </FormikField>
  );
}
