import React from "react";
import { gql } from "@apollo/client";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { MotorInputsTableRow, MotorInputsHeaderRow } from "./row";
import { Grid, Tooltip } from "@mui/material";
import {
  MotorInputsTableRow_baselineMotor,
  MotorInputsTableRow_baselineMotor_motor,
} from "generated-gql-types/MotorInputsTableRow_baselineMotor";
import { MotorInputsTable_site } from "generated-gql-types/MotorInputsTable_site";

interface MotorInputsTableProps {
  site: MotorInputsTable_site;
  baselineMotors: MotorInputsTableRow_baselineMotor[];
  onUpdateBaselineMotor: (baselineMotor: MotorInputsTableRow_baselineMotor) => void;
  disabled: boolean;
}

const defaultMotor: MotorInputsTableRow_baselineMotor = {
  __typename: "BaselineMotor",
  id: "",
  motor: {
    __typename: "Motor",
    id: "",
  },
  voltage: null,
  maxSpeed: null,
  motorPower: null,
  efficiencyType: null,
  applicationType: null,
  tonnage: null,
  replacedAt: null,
};

const getBaselineMotor = (
  baselineMotors: MotorInputsTableRow_baselineMotor[],
  motor: MotorInputsTableRow_baselineMotor_motor
) => {
  const baselineMotor = baselineMotors.find(
    (baselineMotor: MotorInputsTableRow_baselineMotor) => baselineMotor.motor.id === motor.id
  );
  return (
    baselineMotor || {
      ...defaultMotor,
      motor: {
        __typename: "Motor",
        id: motor.id,
      },
    }
  );
};

export function MotorInputsTableComponent(props: MotorInputsTableProps) {
  const { site, baselineMotors, onUpdateBaselineMotor, disabled } = props;

  const handleUpdateBaselineMotor = () => (baselineMotor: MotorInputsTableRow_baselineMotor) => {
    onUpdateBaselineMotor(baselineMotor);
  };

  return (
    <Tooltip
      title={
        site?.viewerIsAdmin ? "" : "Only organization admins may change energy savings settings"
      }
      placement="top"
    >
      <Grid item container direction="column" minWidth={975} data-testid="MotorInputsTable">
        <MotorInputsHeaderRow />
        {site?.motors
          ?.slice()
          .sort((a, b) =>
            (a.displayName || a.name || a.id)?.localeCompare(b.displayName || b.name || b.id)
          )
          .map((motor: any) => (
            <MotorInputsTableRow
              motor={motor}
              baselineMotor={getBaselineMotor(baselineMotors, motor)}
              onUpdateBaselineMotor={handleUpdateBaselineMotor()}
              key={motor.id}
              disabled={disabled}
            />
          ))}
      </Grid>
    </Tooltip>
  );
}

export const MotorInputsTable = createFragmentContainer(MotorInputsTableComponent, {
  site: gql`
    fragment MotorInputsTable_site on Site {
      id
      viewerIsAdmin
      motors {
        id
        ...MotorInputsTableRow_motor
      }
      baselineMotors {
        id
        ...MotorInputsTableRow_baselineMotor
      }
    }
    ${MotorInputsTableRow.fragments.motor}
    ${MotorInputsTableRow.fragments.baselineMotor}
  `,
});
