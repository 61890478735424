import { gql } from "@apollo/client";
import { composeMutationHook } from "./helpers";
import { CreateUtilityRate } from "generated-gql-types/CreateUtilityRate";
import { UpdateUtilityRate } from "generated-gql-types/UpdateUtilityRate";
import { UpdateUtilityRateInput, CreateUtilityRateInput } from "generated-gql-types/globalTypes";

const updateUtilityRateMutation = gql`
  mutation UpdateUtilityRate($input: UpdateUtilityRateInput!) {
    updateUtilityRate(input: $input) {
      utilityRate {
        id
        startDate
        rate
      }
    }
  }
`;

export const useUpdateUtilityRateMutation = composeMutationHook<
  UpdateUtilityRate,
  UpdateUtilityRateInput
>(updateUtilityRateMutation, {});

const createUtilityRateMutation = gql`
  mutation CreateUtilityRate($input: CreateUtilityRateInput!) {
    createUtilityRate(input: $input) {
      utilityRate {
        id
        startDate
        rate
      }
    }
  }
`;

export const useCreateUtilityRateMutation = composeMutationHook<
  CreateUtilityRate,
  CreateUtilityRateInput
>(createUtilityRateMutation, {
  refetchQueries: ["GetSiteSettings"],
});
