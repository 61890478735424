import * as React from "react";
import classNames from "classnames";

import { withStyles, WithStyles } from "@material-ui/core/styles";

import { PlaceholderActionStyles } from "./styles";

interface PlaceholderActionProps
  extends React.HTMLAttributes<HTMLDivElement>,
    WithStyles<typeof PlaceholderActionStyles> {
  action?: () => void;
}

class PlaceholderActionComponent extends React.Component<PlaceholderActionProps> {
  render() {
    const { action, classes, children, ...others } = this.props;

    return (
      <div
        {...others}
        className={classNames(classes.root, {
          [classes.actionable]: action,
        })}
      >
        <div className={classes.action} onClick={action}>
          {children}
        </div>
      </div>
    );
  }
}

export const PlaceholderAction = withStyles(PlaceholderActionStyles)(PlaceholderActionComponent);
