import * as React from "react";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { SmcMuiTheme } from "theme";
import { Box, Grid } from "@material-ui/core";
import { styled, makeStyles, createStyles } from "@material-ui/core/styles";
import { SiteRow_site } from "generated-gql-types/SiteRow_site";
import classNames from "classnames";
import { gql } from "@apollo/client";
import { Colors, DescriptionSmall, DescriptionExtraSmall, DescriptionMedium } from "sigil";

type DotProps = {
  color: string;
};

const Dot = styled(Box)({
  width: "8px",
  height: "8px",
  borderRadius: "8px",
  backgroundColor: (props: DotProps) => props.color,
  flexShrink: 0,
});

const useItemStyles = makeStyles((theme: typeof SmcMuiTheme) =>
  createStyles({
    root: {
      cursor: "pointer",
      borderLeftStyle: "solid",
      borderLeftWidth: theme.spacing(0.25),
      borderLeftColor: theme.palette.background.paper,
      padding: `${theme.spacing(2) + 1}px ${theme.spacing(3)}px`,
      "&:hover, &$active": {
        borderLeftColor: Colors.ttBlue,
        "& $highlight": {
          color: Colors.ttBlue,
        },
      },
      "&:hover": {
        backgroundColor: Colors.sunflowerLightest,
      },
    },
    highlight: {},
    active: {},
    address: {
      width: "100%",
      flexShrink: 0,
    },
  })
);

type SiteRowComponentProps = { site: SiteRow_site; active?: boolean };
export const SiteRowComponent = ({ site, active }: SiteRowComponentProps) => {
  const styles = useItemStyles();
  return (
    <Grid
      container
      item
      className={classNames(styles.root, active && styles.active)}
      alignItems="center"
      wrap="nowrap"
    >
      <Grid item className={styles.address}>
        <DescriptionSmall color="ash" noWrap title={site.organization.name ?? ""}>
          {site.organization.name}
        </DescriptionSmall>
        <DescriptionMedium
          color="slate"
          noWrap
          className={styles.highlight}
          title={site.name ?? ""}
        >
          {site.name}
        </DescriptionMedium>
        <DescriptionExtraSmall color="ash" noWrap title={site.address ?? ""}>
          {site.address}
        </DescriptionExtraSmall>
      </Grid>
      {
        <Dot
          color={site.organization.displayAlerts && site.hasOpenAlerts ? Colors.rust : Colors.wave}
        />
      }
    </Grid>
  );
};

export const SiteRow = createFragmentContainer(SiteRowComponent, {
  site: gql`
    fragment SiteRow_site on Site {
      id
      name
      address
      latitude
      longitude
      hasOpenAlerts
      organization {
        id
        name
        displayAlerts
      }
    }
  `,
});
