export const PENDING_PROMISE = new Promise(() => {
  return;
});

/**
 * Returns a Promise that will never resolve, but is typed to match the provided
 * argument.
 */
export function pendingPromise<T>(v: T): Promise<T> {
  return new Promise(() => {
    return v;
  });
}

/**
 * A higher-order function that will invoke the provided callback, then throw
 * the original error. Meant to be used as the rejection callback to a promise.
 *
 * Example:
 * Promise.resolve().then(
 *   (result) => doSomethingWithResult(result),
 *   rethrow((e) => console.log(e))
 * );
 */
export function rethrow<E>(handler: (error: E) => void) {
  return (error: E) => {
    handler(error);
    throw error;
  };
}
