import * as React from "react";
import classNames from "classnames";
import {
  createStyles,
  WithStyles,
  withStyles,
  withTheme,
  WithTheme,
} from "@material-ui/core/styles";

const styles = () => {
  const shrinkRatio = 0.8;

  return createStyles({
    shrink: {
      fontSize: `${shrinkRatio}em`,
      lineHeight: 1 / shrinkRatio,
      paddingRight: "0.4em",
    },
  });
};

type FontIconProps = WithTheme &
  WithStyles<typeof styles> &
  React.HTMLAttributes<HTMLElement> & {
    name: string;
    color?: string;
    shrink?: boolean;
  };

// This component is used over material-ui's <Icon> component due to incompatibilities with the
// `color` prop and sizing scale. This component more closely mirrors font-awesome-style icons
// based on font-size rather than pixel scales
function FontIconComponent({ classes, className, color, name, shrink, ...rest }: FontIconProps) {
  // TODO: replace the <i> element and loosely-typed `name` prop by using a library such as:
  //   https://github.com/FortAwesome/react-fontawesome
  return (
    <i
      {...rest}
      className={classNames(className, "fa", `fa-${name}`, {
        [classes.shrink]: !!shrink,
      })}
      color={color}
    />
  );
}

export const FontIcon = withTheme(withStyles(styles)(FontIconComponent));
