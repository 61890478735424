import { gql } from "@apollo/client";
import { UpdateSiteInput } from "generated-gql-types/globalTypes";
import { UpdateSite } from "generated-gql-types/UpdateSite";
import { composeMutationHook } from "./helpers";

const SITE_INPUT_FRAGMENT = gql`
  fragment SiteInputs on Site {
    id
    name
    address
    peakDemandCharge
    co2eGPerKwh
    energySavingsEnabled
  }
`;

const updateSiteMutation = gql`
  ${SITE_INPUT_FRAGMENT}
  mutation UpdateSite($input: UpdateSiteInput!) {
    updateSite(input: $input) {
      site {
        ...SiteInputs
      }
    }
  }
`;

export const useUpdateSiteMutation = composeMutationHook<UpdateSite, UpdateSiteInput>(
  updateSiteMutation,
  {
    refetchQueries: ["SiteSavings"],
  }
);
