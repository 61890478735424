import { computed } from "mobx";
import moment from "moment";

type CtrlLogicValueMapJSON = { [key: string]: CtrlLogicValueJSON };
type CtrlLogicValueJSON = {
  analog_value?: number;
  binary_value?: boolean;
  enum_value?: number;
  enum_labels?: { [key: string]: string };
  override_status?: OverrideStatus;
};

type CtrlLogicValueMap = { [key: string]: CtrlLogicValue };
interface CtrlLogicValueMetadata {
  overrideStatus: OverrideStatus;
}
interface CtrlLogicAnalogValue extends CtrlLogicValueMetadata {
  analogValue: number;
}
interface CtrlLogicBinaryValue extends CtrlLogicValueMetadata {
  binaryValue: boolean;
}
interface CtrlLogicEnumValue extends CtrlLogicValueMetadata {
  enumValue: number;
  enumLabels: { [key: string]: string };
}
type CtrlLogicValue = CtrlLogicAnalogValue | CtrlLogicBinaryValue | CtrlLogicEnumValue;

export enum OverrideStatus {
  None = 0,
  FixedOverride = 1,
  ThirdParty = 2,
}

export class CtrlLogicPoint {
  ctrlId!: string;
  recordedAt!: string;
  values?: CtrlLogicValueMap;

  constructor(data: { values?: CtrlLogicValueMapJSON; ctrl_id?: string; recorded_at?: string }) {
    this.ctrlId = data.ctrl_id!;
    this.recordedAt = data.recorded_at!;
    const values = data.values;
    if (values) {
      this.values = Object.keys(values).reduce((memo, key) => {
        if (values[key].hasOwnProperty("analog_value")) {
          memo[key] = { analogValue: values[key].analog_value! };
        } else if (values[key].hasOwnProperty("binary_value")) {
          memo[key] = { binaryValue: values[key].binary_value! };
        } else if (
          values[key].hasOwnProperty("enum_value") &&
          values[key].hasOwnProperty("enum_labels")
        ) {
          memo[key] = {
            enumValue: values[key].enum_value,
            enumLabels: values[key].enum_labels,
          };
        }
        memo[key].overrideStatus = values[key].override_status;
        return memo;
      }, {});
    }
  }

  static isAnalogValue(point: CtrlLogicValue): point is CtrlLogicAnalogValue {
    return point.hasOwnProperty("analogValue");
  }

  static isBinaryValue(point: CtrlLogicValue): point is CtrlLogicBinaryValue {
    return point.hasOwnProperty("binaryValue");
  }

  static isEnumValue(point: CtrlLogicValue): point is CtrlLogicEnumValue {
    return point.hasOwnProperty("enumValue") && point.hasOwnProperty("enumLabels");
  }

  @computed
  get recordedAtTime() {
    return new Date(this.recordedAt);
  }

  @computed
  get recordedAtMs() {
    return this.recordedAtTime.getTime();
  }

  @computed
  get recordedAtMoment() {
    const { recordedAtTime } = this;
    return moment(recordedAtTime);
  }

  valueForVariable = (variableId: string) => {
    const value = this.values && this.values[variableId];
    if (!value) {
      return;
    }

    if (CtrlLogicPoint.isAnalogValue(value)) {
      return value.analogValue;
    } else if (CtrlLogicPoint.isBinaryValue(value)) {
      return value.binaryValue;
    } else if (CtrlLogicPoint.isEnumValue(value)) {
      return value.enumLabels[value.enumValue.toString()];
    }

    return;
  };

  numericValueForVariable = (variableId: string): number | null => {
    const value = this.values && this.values[variableId];
    if (!value) {
      return null;
    }

    if (CtrlLogicPoint.isAnalogValue(value)) {
      return value.analogValue;
    } else if (CtrlLogicPoint.isBinaryValue(value)) {
      return value.binaryValue ? 1 : 0;
    } else if (CtrlLogicPoint.isEnumValue(value)) {
      return value.enumValue;
    }

    return null;
  };

  overrideStatusForVariable = (variableId: string): OverrideStatus => {
    const variableData = this.values && this.values[variableId];
    return (variableData && variableData.overrideStatus) || OverrideStatus.None;
  };
}
