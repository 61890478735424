import { TypographyOptions } from "@material-ui/core/styles/createTypography";
import { Palette } from "@material-ui/core/styles/createPalette";
import { Colors } from "sigil";

export const typography = (palette: Palette): TypographyOptions => ({
  // useNextVariants: true, // Prep for v4

  fontFamily: ["Barlow", "Roboto", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"].join(","),
  fontSize: 14,

  h1: {
    fontSize: 48,
  },

  h2: {
    fontSize: 40,
  },

  h3: {
    fontSize: 32,
    color: Colors.onyx,
  },

  h4: {
    fontSize: 25,
    color: Colors.onyx,
  },

  h5: {
    fontSize: 18,
    fontWeight: 500,
  },

  h6: {
    fontSize: 15,
    fontWeight: 500,
  },

  overline: {
    color: palette.text.hint,
    lineHeight: 1,
  },

  body1: {
    fontSize: 15,
  },

  body2: {
    fontSize: 14,
    color: "black",
  },

  button: {
    // no yelling
    textTransform: "none",
  },

  caption: {
    fontSize: "0.8125rem",
  },
});

export const MuiTypographyProps = {
  variantMapping: {
    div: "div",
  },
  variant: "body1",
};
