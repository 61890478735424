import * as React from "react";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import memoizeOne from "memoize-one";

import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { Button } from "sigil";

import { AsyncButton } from "modules/common/components/AsyncButton";
import { CtrlVariable } from "modules/site-manager/models";
import { LogicVarOverrideCmd } from "modules/site-manager/models/logic-var-override-cmd";
import {
  LogicValueContainer,
  setAppropriateValue,
} from "modules/site-manager/utils/logic-value-container";
import { LogicValueContainerControl } from "modules/site-manager/components/LogicValueContainerControl";
import { CtrlVariablePresenter } from "modules/site-manager/presenters";

interface LogicVarOverrideModalProps {
  variable: CtrlVariable;
  currentValue: number | boolean | undefined | null;
  onClose: () => void;
  onSave: (override: LogicValueContainer) => Promise<LogicVarOverrideCmd>;
}

@observer
export class LogicVarOverrideModal extends React.Component<LogicVarOverrideModalProps> {
  logicValueContainer = memoizeOne((variable: CtrlVariable) => {
    const { behaviorKind, bmsRoleId, displayName } = variable;
    const container = observable({
      behaviorKind,
      bmsRoleId,
      displayName,
      floatValue: undefined,
      booleanValue: undefined,
    });
    setAppropriateValue(container, this.props.currentValue);
    return container;
  });

  @action
  handleValueChange = (value: string | boolean | undefined | null) => {
    const container = this.logicValueContainer(this.props.variable);
    setAppropriateValue(container, value);
  };

  handleSubmit = () => {
    const { variable, onSave } = this.props;
    return onSave(this.logicValueContainer(variable));
  };

  render() {
    const { variable, onClose } = this.props;

    return (
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>Update {new CtrlVariablePresenter(variable).name}</DialogTitle>

        <DialogContent>
          <LogicValueContainerControl
            container={this.logicValueContainer(this.props.variable)}
            onChange={this.handleValueChange}
            TextFieldProps={{
              fullWidth: true,
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <AsyncButton onClick={this.handleSubmit} variant="solid" color="primary">
            Update
          </AsyncButton>
        </DialogActions>
      </Dialog>
    );
  }
}
