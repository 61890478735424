import React, { ReactNode } from "react";
import MonetizationOnOutlined from "@mui/icons-material/MonetizationOnOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import Co2OutlinedIcon from "@mui/icons-material/Co2Outlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import CloudSyncOutlinedIcon from "@mui/icons-material/CloudSyncOutlined";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeterSharp";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeafSharp";
import QueryStatsIcon from "@mui/icons-material/QueryStatsSharp";
import ForestIcon from "@mui/icons-material/Forest";
import DirectionsCarSharp from "@mui/icons-material/DirectionsCarSharp";
import { Stack } from "@mui/material";
import { MotorEnergySavings_motor_Motor } from "generated-gql-types/MotorEnergySavings";
import { isNone } from "modules/common/utils/guards";
import { Colors } from "sigil/src";
import { SiteSavings_site_Site } from "generated-gql-types/SiteSavings";
import { CO2_REDUCTION } from "modules/site-manager/constants";
import { hasUtilityRate, isSite } from "../SiteSetup";
import { Organization_node_Organization } from "generated-gql-types/Organization";

export interface KpiCardProps {
  title?: string;
  icon?: ReactNode;
  currency?: string;
  value?: number | null;
  units?: string;
  tooltip: string;
}

export const getEmptyMotorKpis = () =>
  [
    {
      title: "Utility Savings",
      currency: "USD",
      icon: <MonetizationOnOutlined fontSize="inherit" style={{ marginTop: "-8px" }} />,
      tooltip:
        "Lifetime $ savings from the installation date from energy kWh savings and demand kW savings",
    },
    {
      title: "kWh Savings",
      icon: <SavingsOutlinedIcon fontSize="inherit" style={{ marginTop: "-7px" }} />,
      tooltip:
        "Lifetime energy savings from the installation date comparing baseline motor power, Turntide power, and any compressor penalties. Equipment run hours between the baseline and Turntide are held constant. Baseline power is calculated from the motor load measured by the Turntide motor.",
    },
    {
      title: "CO₂ Emissions Reduction",
      icon: (
        <Stack spacing={0}>
          <CloudSyncOutlinedIcon fontSize="inherit" style={{ marginTop: "6px" }} />
          <Co2OutlinedIcon fontSize="large" style={{ marginLeft: "10px", marginTop: "-16px" }} />
        </Stack>
      ),
      units: "tons",
      tooltip:
        "Metric tons of carbon reduced by the kWh energy savings utilizing the site's CO₂e grid intensity.",
    },
    {
      title: "Energy Savings",
      icon: <SpeedOutlinedIcon fontSize="inherit" style={{ marginTop: "-8px" }} />,
      units: "%",
      tooltip: "Lifetime savings % compared to baseline energy use",
    },
  ] as KpiCardProps[];

export enum SiteKpiSet {
  SAVINGS = 0,
  CARBON = 1,
}

export const getEmptySiteKpis = (set: SiteKpiSet, included = "motors setup below") =>
  set === SiteKpiSet.SAVINGS
    ? [
        {
          title: "Lifetime Utility Savings",
          currency: "USD",
          icon: (
            <ElectricMeterIcon
              fontSize="large"
              style={{ marginTop: "-8px", color: Colors.ttBlueDark }}
            />
          ),
          tooltip: `Lifetime $ savings from the installation date from energy kWh savings and demand kW savings.  Only ${included} are included.`,
        },
        {
          title: "Energy Savings",
          icon: (
            <ElectricMeterIcon
              fontSize="large"
              style={{ marginTop: "-7px", color: Colors.ttBlueDark }}
            />
          ),
          tooltip:
            "Lifetime energy savings from the installation date comparing baseline motor power, Turntide power, and any compressor penalties. Equipment run hours between the baseline and Turntide are held constant. Baseline power is calculated from the motor load measured by the Turntide motor.",
          units: "kWh",
        },
        {
          title: "Percent Energy Savings",
          icon: (
            <QueryStatsIcon
              fontSize="large"
              style={{ marginTop: "-8px", color: Colors.ttBlueDark }}
            />
          ),
          units: "%",
          tooltip: "Lifetime savings % compared to baseline energy use",
        },
      ]
    : ([
        {
          title: CO2_REDUCTION,
          icon: (
            <EnergySavingsLeafIcon
              fontSize="large"
              style={{ marginTop: "6px", color: Colors.ttBlueDark }}
            />
          ),
          units: "tons",
          tooltip:
            "Metric tons of carbon reduced by the kWh energy savings utilizing the site's CO₂e grid intensity.",
        },
        {
          title: "Car Mileage Equivalent",
          icon: (
            <DirectionsCarSharp
              fontSize="large"
              style={{ marginTop: "-7px", color: Colors.ttBlueDark }}
            />
          ),
          tooltip:
            "Equivalent miles driven by the average gasoline-powered passenger vehicle at 4.03 x 10-4 metric tons CO₂e/mile",
          units: "miles",
        },
        {
          title: "New Trees Planted Equivalent",
          icon: (
            <ForestIcon fontSize="large" style={{ marginTop: "-8px", color: Colors.ttBlueDark }} />
          ),
          tooltip: "Equivalent number of trees at 0.060 metric ton CO₂ per urban tree planted",
          units: "trees",
        },
      ] as KpiCardProps[]);

export const getEmptyOrgKpis = (set: SiteKpiSet) =>
  getEmptySiteKpis(set, "sites with motors setup");

export const hydrateMotorKpis = (
  motor: MotorEnergySavings_motor_Motor,
  motorIsConfigured: boolean,
  kpis: KpiCardProps[]
) => {
  kpis.forEach((kpi) => (kpi.value = null));
  if (motor.energySavings && motorIsConfigured && motor.energySavings.turntideKwh) {
    kpis[0].value = motor.energySavings.utilitySavings;
    if (!isNone(motor.energySavings.baselineKwh) && !isNone(motor.energySavings.turntideKwh)) {
      const kwhSavings = motor.energySavings.baselineKwh - motor.energySavings.turntideKwh;
      kpis[1].value = kwhSavings;
      kpis[2].value = motor.energySavings.co2eEmissionsReduction;
      const pctSavings =
        kwhSavings > 0 && motor.energySavings.baselineKwh
          ? (kwhSavings / motor.energySavings.baselineKwh) * 100
          : 0;
      kpis[3].value = parseFloat(pctSavings.toFixed(1));
    }
  }
  return kpis;
};

export const hydrateSiteSavingsKpis = (
  site: SiteSavings_site_Site | Organization_node_Organization,
  energySavingsEnabled: boolean,
  kpis: KpiCardProps[]
) => {
  kpis.forEach((kpi) => (kpi.value = null));
  if (site.energySavings && energySavingsEnabled && (!isSite(site) || hasUtilityRate(site))) {
    kpis[0].value = site.energySavings.utilitySavings;
    if (!isNone(site.energySavings.baselineKwh) && !isNone(site.energySavings.turntideKwh)) {
      const kwhSavings = site.energySavings.baselineKwh - site.energySavings.turntideKwh;
      kpis[1].value = kwhSavings;
      const pctSavings =
        kwhSavings > 0 && site.energySavings.baselineKwh
          ? (kwhSavings / site.energySavings.baselineKwh) * 100
          : 0;
      kpis[2].value = parseFloat(pctSavings.toFixed(1));
    }
  }
  return kpis;
};

const CO2_TONS_PER_TREE = 0.06; // 0.060 metric ton CO2 per urban tree planted
const CO2_TONS_PER_CAR = 4.03 * Math.pow(10, -4); // Miles driven by the average gasoline-powered passenger vehicle at 4.03 x 10-4 metric tons CO2E/mile

export const hydrateSiteCarbonKpis = (
  site: SiteSavings_site_Site | Organization_node_Organization,
  energySavingsEnabled: boolean,
  carbonEnabled: boolean,
  kpis: KpiCardProps[]
) => {
  kpis.forEach((kpi) => (kpi.value = null));
  if (site.energySavings && energySavingsEnabled && carbonEnabled) {
    kpis[0].value = site.energySavings.co2eEmissionsReduction;
    kpis[1].value = (site.energySavings.co2eEmissionsReduction || 0) / CO2_TONS_PER_CAR;
    kpis[2].value = (site.energySavings.co2eEmissionsReduction || 0) / CO2_TONS_PER_TREE;
  }
  return kpis;
};
