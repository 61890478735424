import * as React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { UNAVAILABLE_LABEL } from "modules/site-manager/constants";

interface DipSwitchProps {
  values?: boolean[];
  title: string;
}

export const OPEN_LABEL = "OPEN";
export const CLOSED_LABEL = "CLOSED";

// In Figma, select the components of the visual, then Copy/Paste as > Copy as SVG
const closedPin = (
  <svg width="22" height="50" viewBox="0 0 22 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 0L11 32" stroke="#008FE0" strokeWidth="7" />
    <circle cx="11" cy="39" r="8" fill="#008FE0" stroke="#008FE0" strokeWidth="6" />
  </svg>
);

const openPin = (
  <svg width="22" height="50" viewBox="0 0 22 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 18.5V50" stroke="#979BA2" strokeWidth="7" />
    <circle cx="11" cy="11" r="8" fill="white" stroke="#979BA2" strokeWidth="6" />
  </svg>
);

export const DipSwitch = ({ values, title }: DipSwitchProps) => {
  // Set a max width for these things so they don't stretch ridiculously
  const maxWidth = Math.max(200, 64 + (1 + (values?.length || 0)) * 40);
  return (
    <Grid
      container
      justifyContent="center"
      sx={{ backgroundColor: "white", fontFamily: "Barlow", maxWidth: `${maxWidth}px` }}
    >
      <Grid item container xs="auto" justifyContent="flex-end" alignItems="flex-end">
        <Typography component="span" fontFamily="Barlow" fontSize="15px" fontWeight="500">
          <Stack mr={1}>
            <Box mb={1.7}>{OPEN_LABEL}</Box>
            <Box>{CLOSED_LABEL}</Box>
          </Stack>
        </Typography>
      </Grid>
      <Grid item xs={true}>
        <Grid item xs={true} justifyContent="center" textAlign="center">
          <Typography variant="h6" fontFamily={"Barlow"}>
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={true}
          justifyContent="center"
          textAlign="center"
          direction="row"
          justifyItems="center"
          minHeight={55}
        >
          {values ? (
            values.map((value: boolean, index: number) => (
              <Grid item xs={true} key={index}>
                <Grid item xs justifyContent="center" textAlign="center">
                  <Typography fontFamily="Barlow" fontSize="14px" fontWeight="500">{`${
                    index + 1
                  }`}</Typography>
                </Grid>
                <Grid
                  item
                  xs="auto"
                  justifyContent="center"
                  textAlign="center"
                  key={index}
                  pt={0.5}
                >
                  {value ? closedPin : openPin}
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid
              item
              xs={12}
              justifyContent="center"
              textAlign="center"
              justifyItems="stretch"
              margin="auto"
              pt={1}
            >
              {UNAVAILABLE_LABEL}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
