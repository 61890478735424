import * as React from "react";

import { PropTypes, Theme, Typography } from "@material-ui/core";
import {
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";

import { Health } from "modules/common/enums";
import { getColorForHealth } from "modules/site-manager/utils/health";

const HealthStatusBarStyles = (theme: Theme) => {
  return createStyles({
    root: {
      borderTop: "1px solid transparent",
      padding: "0.4rem",
    },
  });
};

export const useHealthStatusBarStyles = makeStyles(() =>
  createStyles({
    root: {
      marginBottom: "22px",
      marginLeft: "0px",
      marginRight: "0px",
      "& button": {
        float: "right",
        width: "120px",
      },
    },
  })
);

type HealthStatusBarProps = {
  health: Health;
  align?: PropTypes.Alignment;
};

class HealthStatusBarComponent extends React.Component<
  HealthStatusBarProps & WithStyles<typeof HealthStatusBarStyles> & WithTheme
> {
  static defaultProps = {
    align: "center" as PropTypes.Alignment,
  };

  render() {
    const { children, classes, health, theme, align } = this.props;
    const healthColor = getColorForHealth(theme, health);

    return (
      <div
        className={classes.root}
        style={{
          borderTopColor: healthColor,
          backgroundColor: lighten(healthColor, 0.9),
        }}
      >
        <Typography variant="caption" align={align}>
          {children}
        </Typography>
      </div>
    );
  }
}

export const HealthStatusBar = withTheme(
  withStyles(HealthStatusBarStyles)(HealthStatusBarComponent)
);
