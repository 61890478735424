import * as React from "react";
import { Card, Divider, Fade, LinearProgress, CardProps, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export interface PrimaryCardProps extends CardProps {
  loading?: boolean;
}

// PrimaryCard is a Card variant which is accented and has an async loading indicator
export function PrimaryCard({ loading = false, children, ...cardProps }: PrimaryCardProps) {
  return (
    <Card {...cardProps}>
      <Box position="relative" height={4}>
        <Accent absolute={true} />
        <LoadingIndicator loading={loading} />
      </Box>
      {children}
    </Card>
  );
}

const Accent = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    height: 2,
  },
  absolute: {
    top: 0,
    bottom: undefined,
  },
}))(Divider);

const LoadingIndicator = ({ loading = false }) => (
  // Delay display of loading indicator
  <Fade in={loading} style={{ transitionDelay: loading ? "800ms" : "0ms" }} unmountOnExit={true}>
    <LinearProgress />
  </Fade>
);
