import { default as React, useCallback, useEffect, useState } from "react";
import { Box, CircularProgress, Fade } from "@material-ui/core";
import { Button, ButtonProps } from "sigil";

type AsyncButtonProps = ButtonProps & {
  onClick: () => Promise<any>;
};

export function AsyncButton({ children, onClick, ...props }: AsyncButtonProps) {
  const [promise, setPromise] = useState(Promise.resolve());
  const handleClick = useCallback(() => {
    setPromise(onClick());
  }, [onClick]);

  const [isPending, setIsPending] = useState(false);
  useEffect(() => {
    if (!promise) {
      return;
    }

    let canceled = false;

    setIsPending(true);
    promise.finally(() => {
      if (!canceled) {
        setIsPending(false);
      }
    });

    return () => {
      canceled = true;
    };
  }, [promise]);

  return (
    <Button disabled={isPending} onClick={handleClick} {...props}>
      <Fade in={!isPending}>
        <div>{children}</div>
      </Fade>
      {isPending && (
        <Box position="absolute" top=".5em">
          <CircularProgress size="1.5em" />
        </Box>
      )}
    </Button>
  );
}
