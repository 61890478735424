import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import { Section } from "../../SiteConfiguration/ConfigSection";
import { useSnackbar } from "notistack";
import {
  KpiCardProps,
  getEmptySiteKpis,
  hydrateSiteSavingsKpis,
  hydrateSiteCarbonKpis,
  SiteKpiSet,
} from "../EnergySavings/kpis";
import { SiteSavings } from "generated-gql-types/SiteSavings";
import { SiteSavings_site_Site } from "generated-gql-types/SiteSavings";
import { SiteMonthlySavingsChart } from "./SiteMonthlySavingsChart";
import { SiteSetupDialog } from "../SiteSetup/SiteSetupDialog";
import { EnergySavingsComponent } from "modules/site-manager/components/EnergySavingsComponent";
import { isNone } from "modules/common/utils/guards";

const SITE_ENERGY_SAVINGS_QUERY = gql`
  query SiteSavings($siteId: ID!) {
    site: node(id: $siteId) {
      ... on Site {
        id
        baselineMotors {
          id
        }
        motors {
          id
        }
        energySavingsEnabled
        energySavings {
          id
          baselineKwh
          turntideKwh
          utilitySavings
          co2eEmissionsReduction
        }
        ...SiteMonthlySavingsChart_site
        ...SiteSetupDialog_site
      }
    }
  }
  ${SiteMonthlySavingsChart.fragments.site}
  ${SiteSetupDialog.fragments.site}
`;

interface SiteSavingsSectionProps {
  siteId: string;
}

export const SiteSavingsSection = ({ siteId }: SiteSavingsSectionProps) => {
  const siteEnergySavingsQuery = useQuery<SiteSavings>(SITE_ENERGY_SAVINGS_QUERY, {
    variables: { siteId: siteId },
  });
  const site = siteEnergySavingsQuery.data?.site as SiteSavings_site_Site;

  const [kpiSet, setKpiSet] = useState<SiteKpiSet>(SiteKpiSet.SAVINGS);
  const [kpis, setKpis] = useState<KpiCardProps[]>(getEmptySiteKpis(kpiSet));

  const { enqueueSnackbar } = useSnackbar();

  // Effect 1: handle data load error
  const error = siteEnergySavingsQuery.error;
  useEffect(() => {
    if (error) {
      enqueueSnackbar("Energy Savings currently unavailable for this site", { variant: "error" });
    }
  }, [error, enqueueSnackbar]);

  // Effect 2: Load energy savings for the site
  useEffect(() => {
    if (site) {
      const blankKpis = getEmptySiteKpis(kpiSet);
      const hydratedKpis =
        kpiSet === SiteKpiSet.SAVINGS
          ? hydrateSiteSavingsKpis(site, site.energySavingsEnabled, blankKpis)
          : hydrateSiteCarbonKpis(
              site,
              site.energySavingsEnabled,
              !isNone(site.co2eGPerKwh),
              blankKpis
            );
      setKpis(hydratedKpis);
    }
  }, [site, kpiSet]);

  return (
    <Section title="Site Savings">
      <Grid container justifyContent={"stretch"} justifyItems={"stretch"}>
        <Grid item xs={12}>
          <EnergySavingsComponent site={site} kpis={kpis} kpiSet={kpiSet} setKpiSet={setKpiSet} />
        </Grid>
        <Grid item mt={2} xs={12}>
          <SiteMonthlySavingsChart site={site} />
        </Grid>
      </Grid>
    </Section>
  );
};
