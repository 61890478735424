import { gql, useMutation } from "@apollo/client";
import { UpgradeDeviceFirmwareInput } from "generated-gql-types/globalTypes";

const mutation = gql`
  mutation UpgradeDeviceFirmware($input: UpgradeDeviceFirmwareInput!) {
    upgradeDeviceFirmware(input: $input) {
      device {
        id
        firmware {
          currentVersion
          status {
            ... on FirmwarePending {
              pendingVersion
              updatedAt
              step
            }
          }
        }
      }
    }
  }
`;

export function useUpgradeDeviceFirmwareMutation() {
  const [mutate] = useMutation(mutation);
  return (input: UpgradeDeviceFirmwareInput) => mutate({ variables: { input } });
}
