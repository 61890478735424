import * as React from "react";
import { Typography, Grid } from "@mui/material";
import { Colors, DescriptionSmall } from "sigil";
import { gql } from "@apollo/client";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { Link } from "react-router-dom";

interface OrganizationRowProps {
  organization: any;
  Action: React.ReactNode;
}

const SITE_NAME_WIDTH_LG = 7;
const ROLE_WIDTH_LG = 2;
const MENU_WIDTH_LG = 3;
const SITE_NAME_WIDTH_M = 7;
const ROLE_WIDTH_M = 2;
const MENU_WIDTH_M = 3;
const SITE_NAME_WIDTH_XS = 7;
const ROLE_WIDTH_XS = 2;
const MENU_WIDTH_XS = 3;

export function OrganizationRowComponent({ organization, Action }: OrganizationRowProps) {
  const adminVariant = organization.viewerIsAdmin;

  return (
    <Grid
      item
      container
      xs={12}
      sx={{ borderTop: `1px solid ${Colors.salt}` }}
      justifyItems="center"
      alignItems="center"
    >
      <Grid
        item
        justifyContent="stretch"
        xs={SITE_NAME_WIDTH_XS}
        md={SITE_NAME_WIDTH_M}
        lg={SITE_NAME_WIDTH_LG}
        sx={{
          cursor: adminVariant ? "pointer" : "default",
          "&:hover .hoverProxy": {
            textDecoration: adminVariant ? "underline" : "none",
          },
          overflow: "hidden",
          textOverflow: "ellipsis",
          padding: "8px 24px",
        }}
      >
        <Typography variant="subtitle1" className="hoverProxy" fontFamily={"Barlow"}>
          <Link to={`/settings/organization/${organization.id}`}>{organization.name}</Link>
        </Typography>
      </Grid>

      <Grid
        item
        justifyContent="center"
        justifyItems="center"
        alignItems="center"
        xs={ROLE_WIDTH_XS}
        md={ROLE_WIDTH_M}
        lg={ROLE_WIDTH_LG}
        sx={{ padding: "8px 8px" }}
      >
        <DescriptionSmall>{organization.viewerIsAdmin ? "Admin" : "Member"}</DescriptionSmall>
      </Grid>

      <Grid
        item
        justifyContent="center"
        justifyItems="center"
        alignItems="center"
        xs={MENU_WIDTH_XS}
        md={MENU_WIDTH_M}
        lg={MENU_WIDTH_LG}
        sx={{ padding: "8px 8px" }}
      >
        {Action}
      </Grid>
    </Grid>
  );
}

export const OrganizationRow = createFragmentContainer(OrganizationRowComponent, {
  organization: gql`
    fragment OrganizationRow_organization on Organization {
      id
      name
      viewerIsAdmin
    }
  `,
});
