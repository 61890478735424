import * as React from "react";
import Measure, { ContentRect } from "react-measure";
import { RouteComponentProps, withRouter } from "react-router-dom";
import memoizeOne from "memoize-one";
import { action, computed, observable } from "mobx";
import { observer } from "mobx-react";
import { Box, Card } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { APIStore, injectStores } from "modules/common/stores";
import { DURATION } from "modules/common/utils/time";
import { AnalysisStore, SiteStore } from "modules/site-manager/stores";
import { CtrlVariable, Priority, Site } from "modules/site-manager/models";
import { CtrlVariableHeroItem } from "./item";
import { CtrlVariableHeroStyles } from "./style";
import { HeroSlider } from "./slider";

export type CtrlVariableHeroProps = {
  site: Site;
  apiStore?: APIStore;
  className?: string;
};

type Props = CtrlVariableHeroProps &
  WithStyles<typeof CtrlVariableHeroStyles> &
  RouteComponentProps;

@injectStores("models", "api")
@observer
class CtrlVariableHeroComponent extends React.Component<Props> {
  @observable fullWidth = -1;
  @observable slidesWidth = -1;

  analysisStoreMemo = memoizeOne((site: Site) => {
    const { apiStore } = this.props;
    const analysisStore = new AnalysisStore(apiStore!, new SiteStore(site));
    analysisStore.setStartTime(new Date(Date.now() - DURATION.DAY));
    analysisStore.setInterval([1, "h"]);
    this.importantCtrlVariables.forEach((cv) => analysisStore.addCtrlVariableField(cv));
    return analysisStore;
  });

  get analysisStore() {
    return this.analysisStoreMemo(this.props.site);
  }

  @computed get importantCtrlVariables() {
    return this.props.site.allCtrlVariables
      .filter((cv) => cv.priority === Priority.Important)
      .slice()
      .sort(CtrlVariable.compare);
  }

  @computed get isMeasured() {
    return this.fullWidth > -1 && this.slidesWidth > -1;
  }

  @computed get needsSlider() {
    return this.isMeasured && this.slidesWidth > this.fullWidth;
  }

  @action
  handleRootResize = ({ bounds }: ContentRect) => {
    if (!bounds || this.fullWidth === bounds.width) {
      return;
    }
    this.fullWidth = bounds.width;
  };

  @action
  handleSlidesResize = ({ bounds }: ContentRect) => {
    if (!bounds || this.slidesWidth === bounds.width) {
      return;
    }
    this.slidesWidth = bounds.width;
  };

  handleItemClick = (variable: CtrlVariable) => {
    const { history, site } = this.props;
    history && history.push(`/sites/${site.id}/analysis?v=${variable.qualifiedName}`);
  };

  render() {
    const { classes } = this.props;
    const SlidesWrapper = this.needsSlider ? HeroSlider : NoSliderWrapper;

    const ctrlVariableSlides = this.importantCtrlVariables.map((cv) => (
      <CtrlVariableHeroItem
        key={cv.id}
        variable={cv}
        classes={classes}
        analysisStore={this.analysisStore}
        onClick={this.handleItemClick}
      />
    ));

    if (ctrlVariableSlides.length < 1) {
      return null;
    }

    const cloneMeasurer = (
      <div className={classes.hideButMeasure}>
        <Measure bounds={true} onResize={this.handleSlidesResize}>
          {({ measureRef: slidesMeasureRef }) => (
            <Card ref={slidesMeasureRef} className={classes.content}>
              <NoSliderWrapper classes={classes}>
                {ctrlVariableSlides.map((slide) => React.cloneElement(slide))}
              </NoSliderWrapper>
            </Card>
          )}
        </Measure>
      </div>
    );

    const contentOnceMeasured = (
      <Card className={classes.content}>
        <SlidesWrapper classes={classes}>{ctrlVariableSlides}</SlidesWrapper>
      </Card>
    );

    return (
      <Box display="flex" justifyContent="center" className={classes.root}>
        {cloneMeasurer}
        <Measure bounds={true} onResize={this.handleRootResize}>
          {({ measureRef: rootMeasureRef }) => (
            <div ref={rootMeasureRef} style={{ maxWidth: "100%" }}>
              {this.isMeasured && contentOnceMeasured}
            </div>
          )}
        </Measure>
      </Box>
    );
  }
}

const NoSliderWrapper = function ({
  children,
  classes,
}: WithStyles<typeof CtrlVariableHeroStyles> & {
  children: React.ReactNode;
}) {
  return <div className={classes.noSliderWrapper}>{children}</div>;
};

const RoutedCtrlVariableHero = withRouter(CtrlVariableHeroComponent);
export const CtrlVariableHero = withStyles(CtrlVariableHeroStyles)(RoutedCtrlVariableHero);
