import * as React from "react";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { DipSwitch } from "modules/site-manager/components/DipSwitch";
import { MotorStatsList_node_Site_motors } from "generated-gql-types/MotorStatsList";
import { digitalOut, digitalIn } from "modules/site-manager/models/digitalValuesUtil";
import { BOXED_CARD_STYLE, SOFT_BOX_SHADOW } from "modules/site-manager/utils/cardStyles";
import { Section } from "modules/site-manager/routes/Site/SiteConfiguration/ConfigSection";
import SettingsInputComposite from "@mui/icons-material/SettingsInputCompositeSharp";

type MotorIOSectionProps = {
  motorNode?: MotorStatsList_node_Site_motors;
};

function MotorIOSectionComponent(props: MotorIOSectionProps) {
  const digitalValues = props.motorNode?.telemetry?.nodes[0]?.digitalValues || undefined;

  return (
    <Grid ml={1} mr={1}>
      <Section title="Digital I/O" icon={<SettingsInputComposite />}>
        <Grid item container xs={12} sx={SOFT_BOX_SHADOW}>
          <Grid
            item
            container
            xs={12}
            sx={BOXED_CARD_STYLE}
            pl={2}
            pr={2}
            pt={1}
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid item xs={12} sm={7} mb={2}>
              <DipSwitch
                title="INPUTS"
                values={digitalValues ? digitalIn(digitalValues) : undefined}
              />
            </Grid>

            <Grid item xs={12} sm={5} mb={2}>
              <DipSwitch
                title="OUTPUTS"
                values={digitalValues ? digitalOut(digitalValues) : undefined}
              />
            </Grid>
          </Grid>
        </Grid>
      </Section>
    </Grid>
  );
}

export const MotorIOSection = observer(MotorIOSectionComponent) as (
  props: MotorIOSectionProps
) => any;
