import React from "react";
import { observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import { WithSnackbarProps, withSnackbar } from "notistack";
import { AlertHistoryTable } from "./AlertHistoryTable";
import { Box, Grid } from "@mui/material";
import { AlertDetailsProps } from "./types";
import { FancyDivider } from "../components";

function Component(props: AlertDetailsProps & WithSnackbarProps) {
  const { alert, enqueueSnackbar } = props;

  try {
    return (
      <Box>
        <Grid item xs={12}>
          <Grid item={true} xs={12}>
            <FancyDivider>Activity</FancyDivider>
          </Grid>
        </Grid>

        <AlertHistoryTable alert={alert} />
      </Box>
    );
  } catch (exception) {
    enqueueSnackbar("There was an error displaying the alert activity.", {
      variant: "error",
    });
    return <Redirect to="/alerts" />;
  }
}

export const AlertAuditLog = withSnackbar(observer(Component));
