import React from "react";
import { Divider } from "@mui/material";
import { Grid } from "@mui/material";
import { createFragmentContainer } from "../createFragmentContainer";
import { gql } from "@apollo/client";
import { TopNav_viewer } from "generated-gql-types/TopNav_viewer";
import { ProfileDropdown } from "./ProfileDropdown";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "./logo.svg";

export const TopNavComponent = ({ viewer }: { viewer: TopNav_viewer }) => {
  return (
    <Grid position="static" top={0} width="100%" justifyContent="space-between">
      <Grid item container xs={12} justifyContent="space-between" pt={1} pl={1} pb="4px">
        <Grid item xs pl={1.5} pt={1}>
          <Link to="/sites">
            <Logo />
          </Link>
        </Grid>
        <Grid item xs="auto" pr={1.5}>
          <ProfileDropdown viewer={viewer} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export const TopNav = createFragmentContainer(TopNavComponent, {
  viewer: gql`
    fragment TopNav_viewer on User {
      id
      ...ProfileDropdown_viewer
    }
    ${ProfileDropdown.fragments.viewer}
  `,
});
