import { createStyles, Theme } from "@material-ui/core/styles";

export const TextLinkStyles = (theme: Theme) => {
  const { palette, transitions } = theme;
  const { primary } = palette.text;

  return createStyles({
    root: {
      position: "relative",
      color: primary,
      textDecoration: "none",

      "&::after": {
        content: "''",
        position: "absolute",
        bottom: 0,
        left: 0,
        right: "100%",
        backgroundColor: primary,
        transition: `right ${transitions.duration.shortest / 2}ms`,
      },

      "&:hover": {
        color: primary,
        "&::after": {
          right: 0,
        },
      },
    },
  });
};
