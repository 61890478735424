import { useMutation, gql } from "@apollo/client";
import { UpdateOrganizationMemberInput } from "generated-gql-types/globalTypes";

const mutation = gql`
  mutation UpdateOrganizationMember($input: UpdateOrganizationMemberInput!) {
    updateOrganizationMember(input: $input) {
      organization {
        id
        viewerIsAdmin
        members(first: 1000) {
          edges {
            isAdmin
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export function useUpdateOrganizationMemberMutation() {
  const [mutate] = useMutation(mutation);
  return (input: UpdateOrganizationMemberInput) => mutate({ variables: { input } });
}
