import * as React from "react";
import { useCallback } from "react";

// useMenuState is a helper for managing the basic anchorEl bindings for a Mui menu
export function useMenuState() {
  const [anchorEl, setAnchorEl] = React.useState(null as any);

  const handleOpen = useCallback(
    (event: React.MouseEvent) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return {
    handleOpen,
    handleClose,
    props: {
      anchorEl: anchorEl,
      open: !!anchorEl,
      onClose: handleClose,
    },
  };
}
