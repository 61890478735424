import * as React from "react";
import { ReactNode } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { Button } from "sigil";
import { AsyncButton } from "../AsyncButton/AsyncButton";

type ConfirmDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  body?: ReactNode;
  cancelLabel?: string;
  confirmLabel?: string;
  onConfirm: () => Promise<void>;
};

export function SimpleConfirmationDialog(props: ConfirmDialogProps) {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h3">{props.title}</Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>{props.body}</DialogContentText>
      </DialogContent>

      <DialogActions style={{ padding: 16 }}>
        <Button variant="knockout" onClick={props.onClose}>
          {props.cancelLabel || "Cancel"}
        </Button>
        <AsyncButton variant="solid" color="primary" onClick={props.onConfirm}>
          {props.confirmLabel || "Confirm"}
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
}
