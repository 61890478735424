import * as React from "react";
import { useState } from "react";
import { gql } from "@apollo/client";
import { CardHeader } from "@material-ui/core";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { SiteSharingCard_organization } from "generated-gql-types/SiteSharingCard_organization";
import { AddSharePane } from "./AddSharePane";
import { EditSharePane } from "./EditSharePane";
import { ListSharesPane } from "./ListSharesPane";
import { FadeTransition, SlideTransition } from "../styled";

type SiteSharingCardProps = {
  organization: SiteSharingCard_organization;
};

function SiteSharingCardComponent(props: SiteSharingCardProps) {
  const [addingShare, setAddingShare] = useState(false);

  const [editingGuestId, setEditingGuestId] = useState("");
  const editingShare = props.organization?.outboundShares.find(
    (share) => share.guestOrganization.id === editingGuestId
  );

  let pane = (
    <FadeTransition key="listing">
      <ListSharesPane
        organization={props.organization}
        onAdd={() => setAddingShare(true)}
        onEdit={(orgId) => setEditingGuestId(orgId)}
      />
    </FadeTransition>
  );

  if (addingShare) {
    pane = (
      <SlideTransition key="adding">
        <AddSharePane organization={props.organization} onClose={() => setAddingShare(false)} />
      </SlideTransition>
    );
  }

  if (editingShare) {
    pane = (
      <SlideTransition key="editing">
        <EditSharePane share={editingShare} onClose={() => setEditingGuestId("")} />
      </SlideTransition>
    );
  }

  return (
    <>
      <CardHeader title={<>{props.organization?.name}&nbsp;</>} subheader="Access Sharing" />
      <SwitchTransition mode="out-in">
        <CSSTransition timeout={300} key={pane.key!}>
          {pane}
        </CSSTransition>
      </SwitchTransition>
    </>
  );
}

export const SiteSharingCard = createFragmentContainer(SiteSharingCardComponent, {
  organization: gql`
    fragment SiteSharingCard_organization on Organization {
      id
      name
      ...ListSharesPane_organization
      ...AddSharePane_organization
      outboundShares {
        ...EditSharePane_share
      }
    }
    ${ListSharesPane.fragments.organization}
    ${AddSharePane.fragments.organization}
    ${EditSharePane.fragments.share}
  `,
});
