import React, { useState } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
  YAxisProps,
} from "recharts";

import { Series, SeriesCollection } from "modules/charts/series";
import {
  TimeseriesTooltip,
  TimeseriesValueFormatter,
  TooltipValue,
} from "modules/charts/components/TimeseriesTooltip";
import { TimeseriesLegend } from "modules/charts/components/TimeseriesLegend";
import { TimeseriesTick } from "modules/charts/components/TimeseriesTick";
import { Cloak } from "modules/common/components/Cloak";
import { Loading } from "modules/common/components/Loading";
import { ReferenceLabel } from "../TimeseriesTooltip/ReferenceLabel";

interface ChartProps {
  data: object[];
  isLoading?: boolean;
  series: SeriesCollection;
  tsKey: string;
  yAxisPropsSet?: YAxisProps[];
  tooltipProps?: {
    formatValue?: TimeseriesValueFormatter;
  };
}

export const BasicTimeseriesChart = observer(
  ({ data, isLoading, series, tsKey, yAxisPropsSet, tooltipProps }: ChartProps) => {
    const [referenceX, setReferenceX] = useState<number | string>(0);
    const [referenceChartX, setReferenceChartX] = useState<number>(0);
    const [isReferenceVisible, setReferenceVisible] = useState(false);
    const [referencePayload, setReferencePayload] = useState<any>({});

    return (
      <div className="position-relative">
        {isLoading ? (
          <Cloak>
            <Loading />
          </Cloak>
        ) : null}

        {/*
          We can't use the recharts Legend component because it forces
          absolute positioning, and will start overlapping the chart itself
        */}
        <TimeseriesLegend
          series={series}
          onClick={action((s: Series) => (s.visible = !s.visible))}
        />

        <ResponsiveContainer width="98%" height={350} debounce={20}>
          <LineChart
            data={data}
            margin={{ bottom: 50 }}
            onClick={action((s: any) => {
              if (isReferenceVisible && referencePayload?.length) {
                setReferenceVisible(false);
              } else if (s?.activeLabel && s?.activePayload) {
                setReferencePayload(s.activePayload);
                setReferenceX(s.activeLabel);
                setReferenceChartX(s.chartX);
                setReferenceVisible(true);
              }
            })}
          >
            <CartesianGrid strokeDasharray="2 5" />
            <XAxis dataKey={tsKey} tick={TimeseriesTick} />
            {yAxisPropsSet &&
              yAxisPropsSet.map((yAxisProps) => <YAxis key={yAxisProps.yAxisId} {...yAxisProps} />)}
            <Tooltip
              content={(reTooltipProps: TooltipProps<TooltipValue, string>) => {
                return (
                  <TimeseriesTooltip
                    series={series}
                    {...Object.assign({}, reTooltipProps, tooltipProps)}
                  />
                );
              }}
            />

            {series.visible.map((s) => {
              return (
                <Line
                  key={s.id}
                  dataKey={s.getValue}
                  dot={false}
                  animationDuration={300}
                  stroke={s.color}
                  connectNulls={false}
                  yAxisId={s.yAxisId}
                  strokeWidth={1.3}
                  isAnimationActive={!referencePayload?.length}
                />
              );
            })}
            {series.visible.length > 0 && isReferenceVisible && (
              <ReferenceLine
                x={referenceX.toString()}
                stroke="lightgrey"
                label={
                  <ReferenceLabel
                    x={referenceChartX}
                    series={series}
                    label={referenceX.toString()}
                    payload={referencePayload}
                  />
                }
                ifOverflow="extendDomain"
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
);
