import { Box, Typography } from "@material-ui/core";
import * as React from "react";
import { Colors } from "sigil";

type TableHeaderProp = {
  headerName: string;
  maxWidth?: string; // optionally constrain the width of the returned Box
};

export const TableHeader = ({ headerName, maxWidth }: TableHeaderProp) => {
  return (
    <Box pt={2} pb={2} pr={2} style={{ maxWidth: maxWidth || "inherit" }}>
      <Typography
        style={{ textTransform: "uppercase", fontWeight: 700, color: Colors.ttTableTitle }}
        variant="caption"
        color="textSecondary"
      >
        {headerName}
      </Typography>
    </Box>
  );
};
