import React from "react";
import { Grid, Stack, Tooltip } from "@mui/material";
import { Typography } from "@material-ui/core";
import { Colors } from "sigil/src/utils/color";
import { BOXED_CARD_STYLE } from "../../../../utils/cardStyles";
import { isNone } from "modules/common/utils/guards";
import { KpiCardProps } from "../EnergySavings/kpis";
import { formatNumber } from "modules/site-manager/utils/chartUtils";

interface SavingsKpiRowProps {
  kpis: KpiCardProps[];
}

export const KpiCard = (props: KpiCardProps) => (
  <Tooltip title={props.tooltip}>
    <Grid item container sx={BOXED_CARD_STYLE} justifyItems="center" alignItems="center">
      {props.title && (
        <Grid item xs={12}>
          <Typography style={{ fontWeight: 500 }}>{props.title}</Typography>
          <Typography style={{ fontSize: "12px", color: Colors.ash }}>Lifetime</Typography>
        </Grid>
      )}
      {props.icon && (
        <Grid item sm={4} lg={3} color={Colors.ttBlue} pl={0.5}>
          {props.icon}
        </Grid>
      )}
      <Grid
        item
        container
        sm={8}
        lg={9}
        pl={0.5}
        pr={1}
        sx={{ overflow: "hidden" }}
        justifyContent={"stretch"}
        justifyItems={"stretch"}
      >
        <Stack direction="row" alignItems={"baseline"}>
          <Typography style={{ fontSize: "36px", color: "black", fontWeight: 600 }}>
            {isNone(props.value)
              ? "- -"
              : `${formatNumber(props.value, 1000, 0, 1, props.currency)}`}
          </Typography>
          {!isNone(props.value) && props.units && (
            <Typography style={{ fontSize: "24px" }}>&nbsp;{props.units}</Typography>
          )}
        </Stack>
      </Grid>
    </Grid>
  </Tooltip>
);

export const SavingsKpiRow = (props: SavingsKpiRowProps) => (
  <Grid item container xs={12} direction="row" spacing={3} ml={-1} mt={0} mr={-1} pt={0}>
    {props.kpis.map((kpi) => (
      <Grid
        item
        container
        xs={12}
        sm={12 / Math.max(1, props.kpis.length / 2)}
        md={12 / Math.max(1, props.kpis.length)}
        key={`${kpi.currency || ""}${kpi.value || ""} - ${kpi.title || ""} - ${kpi.units || ""}`}
        spacing={2}
        sx={{ fontSize: "36px" }}
      >
        <KpiCard {...kpi} />
      </Grid>
    ))}
  </Grid>
);
