import * as React from "react";
import { Typography } from "@material-ui/core";
import { IconButton } from "@mui/material";
import { Colors } from "sigil/src";

type InitialsIconButtonProps = {
  firstName?: string;
  lastName?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const InitialsIconButton = ({ firstName, lastName, onClick }: InitialsIconButtonProps) => {
  const fi = firstName && firstName[0];
  const li = lastName && lastName[0];
  const initials = fi && li ? `${fi || ""}${li || ""}`.toLocaleUpperCase() : "";
  return (
    <IconButton
      sx={{
        border: `1px solid ${Colors.ttBlueDark}`,
        paddingTop: "8px",
        width: "40px",
        height: "40px",
      }}
      onClick={onClick}
    >
      <Typography style={{ color: Colors.ttBlueDark }}>
        {initials || <i className="fas fa-caret-down fa-xs" style={{ marginLeft: 1 }} />}{" "}
      </Typography>
    </IconButton>
  );
};
