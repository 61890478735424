import * as React from "react";
import { connect as connectFormik, FormikContextType } from "formik";
import { CircularProgress, Fade, Box } from "@material-ui/core";
import { Button, ButtonProps } from "sigil";

type SubmitButtonProps = ButtonProps & {
  formik: FormikContextType<{}>;
};

function SubmitButton({ children, formik, ...props }: SubmitButtonProps) {
  return (
    <Button
      type="submit"
      variant="solid"
      color="primary"
      size="medium"
      disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
      {...props}
    >
      <Fade in={!formik.isSubmitting}>
        <div>{children}</div>
      </Fade>
      {formik.isSubmitting && (
        <Box position="absolute" top=".5em">
          <CircularProgress size="1.5em" />
        </Box>
      )}
    </Button>
  );
}

// FormikSubmitButton is a MuiButton connected to a Formik form
// It is disabled when the form is invalid, and will display a loading indicator while submitting
export const FormikSubmitButton = connectFormik<ButtonProps>(SubmitButton);
