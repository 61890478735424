import * as React from "react";
import { Card } from "@material-ui/core";
import { WithStyles, withStyles, createStyles } from "@material-ui/core/styles";

import { Series, SeriesCollection, SERIES_GROUP_NONE } from "modules/charts/series";

type TooltipValue = string | number | Array<string | number>;

export type TimeseriesValueFormatter = (val: TooltipValue) => string;

const styles = () =>
  createStyles({
    card: {
      padding: "1.5em",
    },
  });

interface TimeseriesTooltipProps extends WithStyles<typeof styles> {
  tooltipData: any[];
  series: SeriesCollection;
}

class TimeseriesTooltipComponent extends React.Component<TimeseriesTooltipProps> {
  render() {
    let { tooltipData, series, classes } = this.props;
    const payload = tooltipData.map((t) => {
      return { value: t.value };
    });
    if (!payload || payload.length < 1) {
      return null;
    }

    const content = Object.keys(series.grouped).map((groupKey) =>
      this.seriesGroup(groupKey, series.grouped[groupKey], payload)
    );

    const formattedLabel =
      tooltipData && tooltipData[0]
        ? new Date(tooltipData[0].timestamp.toString()).toLocaleString()
        : "";

    return (
      <Card className={classes.card}>
        <h5>{formattedLabel}</h5>
        {content}
      </Card>
    );
  }

  seriesGroup(group: string, seriesItems: Series[], payload: any[]) {
    let i = 0;
    const dataPoints = seriesItems
      .filter((s) => s.visible)
      .map((s) => this.seriesGroupItem(s, payload, i++));
    return (
      <div key={group} className="smc-ChartTooltip-group">
        {group === SERIES_GROUP_NONE ? null : (
          <span className={"smc-ChartTooltip-groupTitle"}>{group}</span>
        )}
        {dataPoints}
      </div>
    );
  }

  seriesGroupItem(seriesItem: Series, payload: any[], i: number) {
    const point = payload[i];
    if (!point) {
      return null;
    }
    return (
      <div
        key={seriesItem.id}
        style={{
          color: seriesItem.color,
        }}
      >
        <strong>{seriesItem.label}</strong>
        :&nbsp;
        {this._formatValue(seriesItem, point.value)}
      </div>
    );
  }

  _formatValue(series: Series, value: Readonly<TooltipValue>) {
    const { formatValue } = series;

    if (value !== null && typeof value !== "number") {
      return value;
    }

    return formatValue ? formatValue(value) : value;
  }
}

export const TimeseriesTooltipVx = withStyles(styles)(TimeseriesTooltipComponent);
