import { Colors } from "sigil";

export const MuiTableOverrides = {
  MuiTableRow: {
    root: {
      "&$hover:hover": {
        backgroundColor: Colors.salt,
      },
    },
  },
  MuiTableCell: {
    root: {
      // By default material ui lightens divider to the point that it is invisible, let's set it to
      // salt
      borderBottom: `1px solid ${Colors.salt}`,
    },
    sizeSmall: {
      padding: "4px 24px 4px 24px",
    },
  },
};
