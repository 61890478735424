import * as React from "react";
import { Theme } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { ReactSlick } from "./third-party/react-slick";
import { Colors } from "sigil";

const reset = {
  "html, body": {
    background: Colors.kaolin,
    margin: 0,
    padding: 0,
    fontFamily: `"Barlow", "Helvetica Neue", "Helvetica", "Arial", sans-serif`,
    color: Colors.onyx,
  },

  "ul, ol, dl": {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
  'select:focus, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"]':
    { fontSize: "16px" },

  hr: {
    border: 0,
    borderBottom: `1px solid ${Colors.dust}`,
  },

  a: {
    color: "black",
    textDecoration: "none",

    "&:hover": {
      textDecoration: "none",
    },
  },
  body: {
    /* mobile viewport bug fix */
    minHeight: "-webkit-fill-available",
  },

  html: {
    height: "-webkit-fill-available",
  },
};

const utilities = (theme: Theme) => ({
  ".u-text-primary": { color: theme.palette.primary.main },
  ".u-text-secondary": { color: theme.palette.secondary.main },
  ".u-text-plain": { color: Colors.ash },

  ".u-clickable": { cursor: "pointer" },

  ".u-valign-bottom": { verticalAlign: "bottom" },

  ".u-flex": {
    flexBasis: 0,
    flexGrow: 1,
    maxWidth: "100%",
  },

  ".u-noflex": {
    flexBasis: 0,
    flexShrink: 0,
  },

  ".u-cells-pb-2 td": {
    paddingBottom: "0.5rem",
  },

  ".smc-Layout-bodyContainer": {
    paddingTop: "2rem",
    paddingBottom: "5rem",
  },
});

const globalStyles = (theme: Theme) =>
  createStyles({
    "@global": Object.assign({}, reset, utilities(theme), ReactSlick),
  });

// This component is for injecting global styles via JSS. Global styles should
// be avoided as much as humanly possible; the styles here only exist in order
// to transition from SASS to JSS.
export const GlobalStyles = withStyles(globalStyles)(
  ({ children }: { children: React.ReactNode }) => <React.Fragment>{children}</React.Fragment>
);

export const labelFontStyle = { fontFamily: "Barlow", fontSize: "16px" };
