import * as React from "react";
import { gql } from "@apollo/client";
import { useSnackbar } from "notistack";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { SimpleConfirmationDialog } from "modules/common/components/SimpleConfirmationDialog";
import { useRebootMotorMutation } from "modules/common/mutations/RebootMotorMutation";
import { RebootMotorDialog_device } from "generated-gql-types/RebootMotorDialog_device";

type RebootMotorDialogProps = {
  open: boolean;
  onClose: (success: boolean) => void;
  device: RebootMotorDialog_device;
};

function RebootMotorDialogComponent(props: RebootMotorDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const RebootMotor = useRebootMotorMutation();

  return (
    <SimpleConfirmationDialog
      open={props.open}
      onClose={() => props.onClose(false)}
      title="Reboot Motor?"
      body={
        <>
          The motor <strong>{props.device.displayName}</strong> will reboot and may cause an
          interruption in motor operation. Do you want to proceed?
        </>
      }
      confirmLabel="Yes"
      onConfirm={async () => {
        try {
          await RebootMotor({ motorId: props.device.id });
          enqueueSnackbar("Motor reboot requested", { variant: "success" });
          props.onClose(true);
        } catch (e) {
          enqueueSnackbar("There was an error rebooting the motor.", { variant: "error" });
          props.onClose(false);
        }
      }}
    />
  );
}

export const RebootMotorDialog = createFragmentContainer(RebootMotorDialogComponent, {
  device: gql`
    fragment RebootMotorDialog_device on Device {
      id
      displayName
    }
  `,
});
