import { useEffect } from "react";
import { useAuth } from "./context";

export function Logout() {
  const auth = useAuth();

  useEffect(() => {
    auth.logout();
  }, [auth]);

  return null;
}
