import { useApolloClient, useMutation, gql } from "@apollo/client";
import { RemoveOrganizationMemberInput } from "generated-gql-types/globalTypes";

const mutation = gql`
  mutation RemoveOrganizationMember($input: RemoveOrganizationMemberInput!) {
    removeOrganizationMember(input: $input) {
      organization {
        id
        members(first: 1000) {
          edges {
            node {
              id
            }
          }
        }
      }
      user {
        isViewer
      }
    }
  }
`;

export function useRemoveOrganizationMemberMutation() {
  const client = useApolloClient();
  const [mutate] = useMutation(mutation);

  return (input: RemoveOrganizationMemberInput) =>
    mutate({
      variables: { input },
    }).then((result) => {
      // If we remove ourself from the organization, we need to purge the organization from the cache
      // There isn't a granular way to do this, so just clear the cache and rerun all active queries
      if (result.data?.removeOrganizationMember.user.isViewer) {
        return client.resetStore().then(() => result);
      }
      return result;
    });
}
