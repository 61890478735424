import * as React from "react";
import { SitesList } from "./SitesList";
import { Grid, Card, useMediaQuery, Box } from "@material-ui/core";
import { makeStyles, createStyles, withTheme } from "@material-ui/core/styles";
import { SmcMuiTheme } from "theme";
import { useState } from "react";
import { CreateSiteForm } from "./CreateSiteForm";
import { useMap } from "./hooks";
import { useQuery, gql } from "@apollo/client";
import { SiteIndex_organizations } from "generated-gql-types/SiteIndex_organizations";
import { Button } from "sigil";

const useSiteStyles = makeStyles((theme: typeof SmcMuiTheme) =>
  createStyles({
    card: {
      borderRadius: "2px",
      margin: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      width: "344px",
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0),
        width: "inherit",
      },
    },

    gridFixed: {
      position: "absolute",
      zIndex: 10,
      left: 0,
      top: 0,
      bottom: 0,
      width: "344px",
      [theme.breakpoints.down("sm")]: {
        width: "inherit",
      },
    },
    container: {
      height: "calc(100dvh - 56px)",
      top: "53px",
      [theme.breakpoints.down("sm")]: {
        height: "calc(100dvh - 112px)",
        top: "53px",
      },
      overflow: "hidden",
      position: "absolute",
      left: 0,
      bottom: 0,
      right: 0,
    },
    map: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 0,
    },
  })
);

const query = gql`
  query SiteIndex_organizations {
    viewer {
      id
      canCreateSitesUnrestricted
      organizations(first: 500) {
        nodes {
          id
          viewerIsAdmin
          ...CreateSiteForm_organization
        }
      }
    }
  }
  ${CreateSiteForm.fragments.organizations}
`;

const padding = { left: 300, top: 50, right: 50, bottom: 50 };
export const SitesIndexRoute = withTheme(({ theme }: { theme: typeof SmcMuiTheme }) => {
  const styles = useSiteStyles();
  const [creatingSite, setCreatingSite] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [map, ref, loaded] = useMap(!isMobile, padding);
  const { data } = useQuery<SiteIndex_organizations>(query);
  let canCreateSite = false;
  if (data && data?.viewer) {
    canCreateSite =
      data.viewer.canCreateSitesUnrestricted ||
      data.viewer.organizations.nodes.some((org) => org.viewerIsAdmin);
  }
  return (
    <Grid container item className={styles.container} zeroMinWidth>
      <Grid item container direction="column" wrap="nowrap" className={styles.gridFixed}>
        <Card className={styles.card}>
          {creatingSite && data && data?.viewer?.organizations.nodes ? (
            <CreateSiteForm
              map={map}
              loaded={loaded}
              onClose={() => setCreatingSite(false)}
              organizations={data.viewer.organizations.nodes}
              canCreateSitesUnrestricted={data.viewer.canCreateSitesUnrestricted}
            />
          ) : (
            <SitesList map={map} loaded={loaded} />
          )}
          {canCreateSite && !creatingSite ? (
            <Box mx={3} mb={2} flexGrow={1} flexShrink={0} display="flex" flexDirection="column">
              <Button variant="knockout" color="success" onClick={() => setCreatingSite(true)}>
                + Add Site
              </Button>
            </Box>
          ) : null}
        </Card>
      </Grid>
      <Grid className={styles.map} item ref={ref}></Grid>
    </Grid>
  );
});
