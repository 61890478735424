import { gql } from "@apollo/client";
import { composeMutationHook } from "./helpers";
import { ToggleSiteAlertGen } from "generated-gql-types/ToggleSiteAlertGen";
import { ToggleSiteAlertGenerationInput } from "generated-gql-types/globalTypes";

const toggleMutation = gql`
  mutation ToggleSiteAlertGen($input: ToggleSiteAlertGenerationInput!) {
    toggleSiteAlertGeneration(input: $input) {
      id
      alertsEnabled
    }
  }
`;

export const useToggleSiteAlertGenMutation = composeMutationHook<
  ToggleSiteAlertGen,
  ToggleSiteAlertGenerationInput
>(toggleMutation);
