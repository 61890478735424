import React, { useState } from "react";
import { Grid } from "@mui/material";
import { NumericEdit } from "../SiteEnergySavings/NumericEdit";
import { gql } from "@apollo/client";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import {
  DEMAND_RATE_LABEL,
  DEMAND_RATE_UNITS,
  CO2E_LABEL,
  CO2E_UNITS,
} from "../SiteEnergySavings/SiteSetup/SiteInputsForm";
import { SiteStaticInputsForm_site } from "generated-gql-types/SiteStaticInputsForm_site";

export const SiteStaticInputsFormComponent = ({
  site,
  onChange,
  disabled,
}: {
  site: SiteStaticInputsForm_site;
  onChange: (updatedSite: SiteStaticInputsForm_site) => void;
  disabled?: boolean;
}) => {
  const [peakDemandCharge, setPeakDemandCharge] = useState(site.peakDemandCharge);
  const [co2eGPerKwh, setCO2eGPerKwh] = useState(site.co2eGPerKwh);

  const handleChange = () => {
    onChange({ ...site, peakDemandCharge, co2eGPerKwh });
  };

  return (
    <Grid item xs={12} container sx={{ fontSize: "15px" }} justifyContent="stretch">
      <Grid item container xs={12} sm={7}>
        <NumericEdit
          id="peakDemandCharge"
          value={site.peakDemandCharge || 0}
          autoFocus={false}
          label={DEMAND_RATE_LABEL}
          units={DEMAND_RATE_UNITS}
          disabled={disabled}
          onChange={(e) => {
            setPeakDemandCharge(+e.target.value);
          }}
          onBlur={handleChange}
          decimalScale={2}
        />
        <NumericEdit
          id="co2eGPerKwh"
          value={site.co2eGPerKwh || 0}
          autoFocus={false}
          label={CO2E_LABEL}
          units={CO2E_UNITS}
          disabled={disabled}
          onChange={(e) => {
            setCO2eGPerKwh(+e.target.value);
          }}
          onBlur={handleChange}
          decimalScale={2}
        />
      </Grid>
    </Grid>
  );
};

export const SiteStaticInputsForm = createFragmentContainer(SiteStaticInputsFormComponent, {
  site: gql`
    fragment SiteStaticInputsForm_site on Site {
      id
      viewerIsAdmin
      peakDemandCharge
      co2eGPerKwh
    }
  `,
});
