import * as React from "react";
import { gql } from "@apollo/client";
import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { MotorStatsList_motor } from "generated-gql-types/MotorStatsList_motor";
import { MotorStatsList_motorGroup } from "generated-gql-types/MotorStatsList_motorGroup";
import { MotorStatsList_node_Site_motors } from "generated-gql-types/MotorStatsList";
import { MetricSummaryCard } from "modules/site-manager/components/MetricSummaryCard";
import { Health } from "modules/common/enums";
import { Colors } from "sigil";
import { Route, useHistory, useRouteMatch, Redirect } from "react-router-dom";
import { Site } from "modules/site-manager/models";
import { MotorDetailsDialog } from "modules/site-manager/components/MotorDetailsDialog";
import { MotorGroupDetailsDialog } from "modules/site-manager/components/MotorGroupDetailsDialog";
import { unmarshalStringId } from "modules/common/utils/relay";
import {
  SiteSummary_node_Site_motors,
  SiteSummary_node_Site_motorGroups,
  SiteSummary_node_Site,
  SiteSummary_node_Site_motorGroups_motors,
} from "generated-gql-types/SiteSummary";
import { SortOption } from "modules/site-manager/components/SearchBar";

const useMotorStatsListStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: Colors.kaolin,
    },
  })
);

export const motorQuery = gql`
  fragment MotorStatsList_motor on Motor {
    id
    displayName
    motorSerialNumber
    driveSerialNumber
    modbusAddress
    minSpeed
    maxSpeed
    driveModel
    motorModel
    healthDiagnostics
    group {
      id
    }
    logicFlow {
      currentVersion
    }
    firmware {
      currentVersion
    }
    telemetry(first: 1, ascending: false, relative: "30m") {
      nodes {
        recordedAt
        speedCurrent
        speedRequested
        powerIn
        powerOut
        power
        torque
        motorOn
        errorCount
        warnCount
        wifiRssi
        ipAddress
        digitalValues
      }
    }
  }
`;

export const motorGroupQuery = gql`
  fragment MotorStatsList_motorGroup on MotorGroup {
    id
    name
    displayName
    groupNum
    speedCurrent
    speedRequested
    maxSpeed
    allMotorsOff
    supervisor {
      id
      displayName
    }
    motors {
      ...MotorStatsList_motor
    }
  }
  ${motorQuery}
`;

export type MotorFilter = (motor: SiteSummary_node_Site_motors, searchFilter: string) => boolean;
export type GroupFilter = (
  group: SiteSummary_node_Site_motorGroups,
  searchFilter: string
) => boolean;

// Type Guard for motor groups
const isMotorGroup = (test: any): test is SiteSummary_node_Site_motorGroups => {
  return test.motors !== undefined;
};

export enum SortOptionIndex {
  NAME = 0,
  SERIAL = 1,
}

const motorFilter = (
  motor: SiteSummary_node_Site_motors | SiteSummary_node_Site_motorGroups_motors,
  searchFilter: string
) => {
  const search = searchFilter.trim().toLocaleLowerCase();
  return (
    !searchFilter ||
    motor.displayName.toLocaleLowerCase().includes(search) ||
    motor.driveSerialNumber.toLocaleLowerCase().includes(search) ||
    motor.id.toLocaleLowerCase().includes(search)
  );
};

const groupFilter = (group: SiteSummary_node_Site_motorGroups, searchFilter: string) =>
  !searchFilter ||
  group.displayName.toLocaleLowerCase().includes(searchFilter.trim().toLocaleLowerCase()) ||
  group.motors.reduce(
    (previousValue, currentValue) => previousValue || motorFilter(currentValue, searchFilter),
    false
  );

export type MotorTileDatum =
  | MotorStatsList_node_Site_motors
  | SiteSummary_node_Site_motorGroups
  | SiteSummary_node_Site_motors;

export const SORT_OPTIONS: SortOption[] = [
  {
    comparator: (a: MotorTileDatum, b: MotorTileDatum) =>
      a.displayName.localeCompare(b.displayName),
    label: "NAME",
  },
  {
    comparator: (a: MotorTileDatum, b: MotorTileDatum) => {
      if (isMotorGroup(a) && isMotorGroup(b)) {
        return a.groupNum - b.groupNum;
      } else if (!isMotorGroup(a) && !isMotorGroup(b)) {
        return a.driveSerialNumber.localeCompare(b.driveSerialNumber);
      }
      return 0;
    },
    label: "Serial",
  },
];

export type MotorStatsListProps = {
  site: Site;
  apolloSite: SiteSummary_node_Site;
  searchFilter: string;
  sortOption: SortOption;
  sortAscending: boolean;
};

const sortTiles = (tiles: MotorTileDatum[], sortOption: SortOption, sortAscending: boolean) => {
  tiles.sort((a, b) => (sortAscending ? sortOption.comparator(a, b) : sortOption.comparator(b, a)));
};

export const MotorStatsList = ({
  site,
  apolloSite,
  searchFilter,
  sortOption,
  sortAscending,
}: MotorStatsListProps) => {
  const history = useHistory();
  const match = useRouteMatch();
  const handleOpenDialog = (id: string) => history.replace(`${match.url}/motor/${id}`);
  const handleCloseDialog = () => history.replace(match.url);
  const styles = useMotorStatsListStyles();
  const { motors, motorGroups } = apolloSite;
  const filteredGroups = motorGroups.filter((group) => groupFilter(group, searchFilter));
  const filteredMotors = motors.filter(
    (motor) => motor.group === null && motorFilter(motor, searchFilter)
  );
  sortTiles(filteredGroups, sortOption, sortAscending);
  sortTiles(filteredMotors, sortOption, sortAscending);
  const items = [...filteredGroups, ...filteredMotors];
  return (
    <>
      <MotorDetails
        site={site}
        motors={motors}
        handleCloseDialog={handleCloseDialog}
        handleOpenDialog={handleOpenDialog}
      />
      <Grid container item spacing={2} classes={styles}>
        {items.map((item) => (
          <Grid key={item.id} item xs={12} sm={12} md={6} lg={3} xl={3}>
            {item.__typename === "Motor" ? (
              <MotorSummaryCard
                motor={item}
                onClick={() => handleOpenDialog(unmarshalStringId(item.id))}
              />
            ) : (
              <MotorGroupSummaryCard
                motorGroup={item}
                onClick={() => handleOpenDialog(unmarshalStringId(item.motors[0].id))}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const metricContent = (motor: MotorStatsList_motor) => (metric: number) =>
  <span>{motor.telemetry.nodes[0] && motor.telemetry.nodes[0].motorOn ? metric : "Off"}</span>;

const motorHealth = (motor: MotorStatsList_motor) => {
  const { errorCount } = motor.telemetry.nodes[0] || {};

  let health = Health.HEALTHY;
  if (motor.healthDiagnostics.length || (errorCount || 0) > 0) {
    health = Health.ERROR;
  } else if (!motor.telemetry.nodes[0]) {
    health = Health.UNKNOWN;
  }

  return health;
};

const StatusBar = ({ health }: { health: Health }) => {
  let text = "Status unavailable";
  switch (health) {
    case Health.HEALTHY:
      text = "Operating normally";
      break;
    case Health.ERROR:
      text = "Critical issues";
      break;
    default:
      break;
  }
  return <span>{text}</span>;
};

type MotorSummaryCardProps = {
  motor: MotorStatsList_motor;
  onClick: () => void;
};

const MotorSummaryCard = ({ motor, onClick }: MotorSummaryCardProps) => {
  const { speedCurrent, motorOn } = motor.telemetry.nodes[0] || {};
  const health = motorHealth(motor);

  return (
    <MetricSummaryCard
      title={motor.displayName}
      subTitle={motor.driveSerialNumber}
      statusBarContent={<StatusBar health={health} />}
      health={health}
      GaugeProps={{
        max: motor.maxSpeed,
        metric: motorOn ? speedCurrent || 0 : 0,
        formatMetric: metricContent(motor),
      }}
      onClick={onClick}
    />
  );
};

const MotorGroupStatusBar = ({
  motorGroup,
  health,
}: {
  motorGroup: MotorStatsList_motorGroup;
  health: Health;
}) => {
  if (motorGroup.motors.length === 0) {
    return <span>Status unavailable</span>;
  }
  let count = motorGroup.motors.filter((m) => motorHealth(m) === health).length;
  const Banner = ({ count }: { count: number }) =>
    count > 0 ? (
      <span>
        {count}/{motorGroup.motors.length}
      </span>
    ) : null;
  let description = "unavailable";
  switch (health) {
    case Health.HEALTHY:
      description = "operating normally";
      break;
    case Health.ERROR:
      description = "faults detected";
      break;
    default:
      break;
  }
  // If any motors have unknown status change the text but keep the color of the status bar the same
  const unknown = motorGroup.motors.filter((m) => motorHealth(m) === Health.UNKNOWN).length;
  if (unknown > 0) {
    description = "unavailable";
    count = unknown;
  }
  return (
    <span style={{ marginLeft: "0.25em", marginRight: "0.25em" }}>
      <strong>
        <Banner count={count} />
      </strong>
      &nbsp;
      <span>{description}</span>
    </span>
  );
};

type MotorGroupSummaryCardProps = {
  motorGroup: MotorStatsList_motorGroup;
  onClick: () => void;
};

const MotorGroupSummaryCard = ({ motorGroup, onClick }: MotorGroupSummaryCardProps) => {
  let health = Health.UNKNOWN;
  const motorsHealth = motorGroup.motors.reduce((m, i) => {
    m.add(motorHealth(i));
    return m;
  }, new Set());

  if (motorsHealth.has(Health.HEALTHY)) {
    health = Health.HEALTHY;
  }

  if (motorsHealth.has(Health.ERROR)) {
    health = Health.ERROR;
  }

  return (
    <MetricSummaryCard
      title={motorGroup.displayName}
      subTitle={
        motorGroup.supervisor.displayName +
        (motorGroup.displayName ? `: Motor group ${motorGroup.groupNum}` : "")
      }
      statusBarContent={<MotorGroupStatusBar motorGroup={motorGroup} health={health} />}
      health={health}
      GaugeProps={{
        max: motorGroup.maxSpeed || 1800,
        metric: Math.round(motorGroup.speedCurrent || 0),
        formatMetric: (metric: number) =>
          motorGroup.motors.every((motor) => motor.telemetry.nodes.length === 0)
            ? "?"
            : motorGroup.allMotorsOff
            ? "Off"
            : metric,
        label: "Average Speed (rpm)",
      }}
      onClick={onClick}
    />
  );
};

type MotorDetailsProps = {
  site: Site;
  motors: MotorStatsList_node_Site_motors[];
  handleOpenDialog: (id: string) => void;
  handleCloseDialog: () => void;
};

const MotorDetails = ({ site, motors, handleOpenDialog, handleCloseDialog }: MotorDetailsProps) => {
  const match = useRouteMatch();
  return (
    <Route
      path={`${match.path}/motor/:motor_id`}
      render={(route) => {
        const motor = site.sortedMotors.find((m) => m.id === route.match.params.motor_id);
        if (!motor) {
          return <Redirect to={match.url} />;
        }
        const motorNode = motors.find((m) => m.motorSerialNumber === motor.id);

        return motor.motorGroup ? (
          <MotorGroupDetailsDialog
            motorGroup={motor.motorGroup}
            selectedMotor={motor}
            selectedMotorNode={motorNode}
            onSelectMotor={(motor) => handleOpenDialog(motor.id)}
            open={true}
            onClose={handleCloseDialog}
          />
        ) : (
          <MotorDetailsDialog
            motor={motor}
            motorNode={motorNode}
            open={true}
            onClose={handleCloseDialog}
          />
        );
      }}
    />
  );
};
