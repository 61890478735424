import { useMutation, gql } from "@apollo/client";
import { ChangePasswordInput } from "generated-gql-types/globalTypes";

const mutation = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      ok
    }
  }
`;

export function useChangePasswordMutation() {
  const [mutate] = useMutation(mutation);
  return (input: ChangePasswordInput) => mutate({ variables: { input } });
}
