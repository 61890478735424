import * as React from "react";
import { gql } from "@apollo/client";
import { Card, Grid } from "@mui/material";
import { Section } from "../SiteConfiguration/ConfigSection";
import AlertSubscriptionForm from "../SiteConfiguration/AlertSubscriptionForm";
import SiteAlertsToggleForm from "../SiteConfiguration/SiteAlertsToggleForm";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { AlertsSection_site } from "generated-gql-types/AlertsSection_site";
import BusinessIcon from "@mui/icons-material/Business";

export const AlertsSectionComponent = ({ site }: { site: AlertsSection_site }) => {
  return (
    <Section title="Site Settings" icon={<BusinessIcon />}>
      <Grid container={true} direction="row" spacing={2}>
        <Grid item={true} xs={12} pl={2}>
          <Card sx={{ padding: 2 }}>
            <SiteAlertsToggleForm site={site} />
            <AlertSubscriptionForm site={site} />
          </Card>
        </Grid>
      </Grid>
    </Section>
  );
};

export const AlertsSection = createFragmentContainer(AlertsSectionComponent, {
  site: gql`
    fragment AlertsSection_site on Site {
      ...SiteAlertsToggle_site
      ...AlertsSubscription_site
    }
    ${SiteAlertsToggleForm.fragments.site}
    ${AlertSubscriptionForm.fragments.site}
  `,
});
