import { YAxisProps } from "recharts";

import { AnalysisField } from "modules/site-manager/stores";
import { YAxis } from "modules/charts/constants";

// Maps analysis fields to props used to control Y axis display in chart components.
export const extractYAxisProps = (selectedFields: AnalysisField[]) => {
  const leftFields = selectedFields.filter((fields) => fields.yAxis === YAxis.Left);
  const rightFields = selectedFields.filter((fields) => fields.yAxis === YAxis.Right);

  const leftAxisProps = {
    yAxisId: YAxis.Left,
  };
  Object.assign(
    leftAxisProps,
    leftFields.length
      ? {
          unit: leftFields[0].units ? leftFields[0].units : undefined,
        }
      : {
          domain: [0, 1],
          tickCount: 1,
        }
  );

  const axisProps: YAxisProps[] = [leftAxisProps];
  if (rightFields.length) {
    axisProps.push({
      yAxisId: YAxis.Right,
      orientation: "right",
      unit: rightFields[0].units ? rightFields[0].units : undefined,
    });
  }

  return axisProps;
};
