import { computed } from "mobx";

import { Motor } from "./motor";
import { MotorOpStat } from "modules/site-manager/constants/motor-op";
import { MotorOpStatPresenter } from "modules/site-manager/presenters/motor-op-stat";

export class MotorOp {
  constructor(public motor: Motor, public op: MotorOpStat) {}

  @computed
  get id() {
    const { motor, op } = this;
    return `${motor.id}:${op}`;
  }

  @computed
  get name() {
    const { motor, op } = this;
    const presenter = new MotorOpStatPresenter(op);
    return `${motor.displayName}: ${presenter.name}`;
  }

  static equals(a: MotorOp, b: MotorOp) {
    return a.motor === b.motor && a.op === b.op;
  }
}
