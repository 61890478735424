import { styled } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { CardHeader, TableCell, TableRow } from "@material-ui/core";

export const FadeTransition = styled("div")({
  "&.enter": {
    opacity: 0,
  },
  "&.enter-active": {
    opacity: 1,
  },
  "&.exit": {
    opacity: 1,
  },
  "&.exit-active": {
    opacity: 0,
  },
  "&.enter-active, &.exit-active": {
    transition: "opacity 300ms",
  },
});

export const SlideTransition = styled("div")({
  "&.enter": {
    transform: "translateX(100%)",
  },
  "&.enter-active": {
    transform: "translateX(0%)",
  },
  "&.exit": {
    transform: "translateX(0%)",
  },
  "&.exit-active": {
    transform: "translateX(100%)",
  },
  "&.enter-active, &.exit-active": {
    transition: "transform 300ms",
  },
});

// TableRow that applies hover underline effect to child with class .hoverProxy
export const HoverProxyTableRow = withStyles({
  hover: {
    cursor: "pointer",
    "&:hover .hoverProxy": {
      textDecoration: "underline",
    },
  },
  root: {
    "& td": {
      "&:nth-child(2)": {
        width: "16%",
      },
      "&:nth-child(3)": {
        width: "15%",
      },
      "&:nth-child(4)": {
        width: "22%",
      },
      "&:nth-child(1)": {
        "& div, h6": {
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  },
})(TableRow);

export const PaddedTableCell = withStyles({
  root: {
    padding: "4px 24px",
  },
})(TableCell);

export const StyledCardHeader = withStyles({
  action: {
    margin: 0, // Remove action button offset
  },
})(CardHeader);
