import * as React from "react";
import { MouseEvent, MouseEventHandler, useCallback } from "react";
import { gql } from "@apollo/client";
import { Typography, Box } from "@material-ui/core";
import { MemberRow_member } from "generated-gql-types/MemberRow_member";
import { MemberRow_organization } from "generated-gql-types/MemberRow_organization";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { HoverProxyTableRow, PaddedTableCell } from "../../styled";
import Reinvite from "./Reinvite";

interface MemberRowProps {
  organization: MemberRow_organization;
  member: MemberRow_member;
  onClick: (event: MouseEvent, member: MemberRow_member) => void | undefined;
  Action: React.ReactNode;
}

function MemberRow({ organization, member, onClick, Action }: MemberRowProps) {
  const adminVariant = organization.viewerIsAdmin;
  const user = member.node;

  const handleRowClick = useCallback<MouseEventHandler>(
    (event) => onClick(event, member),
    [member, onClick]
  );

  const reinvite = <Reinvite member={member} organization={organization} />;

  return (
    <HoverProxyTableRow hover={adminVariant} onClick={adminVariant ? handleRowClick : undefined}>
      <PaddedTableCell align="left">
        <Box pt={2} pb={2}>
          <Typography variant="subtitle1" className="hoverProxy">
            <strong>
              {user.fullName || user.email}
              {user.isViewer && (
                <Box display="inline" color="text.hint">
                  {" "}
                  (You)
                </Box>
              )}
            </strong>
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {user.fullName && user.email}
          </Typography>
        </Box>
      </PaddedTableCell>

      <PaddedTableCell align="center">
        {adminVariant
          ? member.isActivated
            ? "activated"
            : reinvite
          : member.isActivated
          ? "activated"
          : "pending"}
      </PaddedTableCell>

      <PaddedTableCell align="center">{member.isAdmin ? "Admin" : "Member"}</PaddedTableCell>

      <PaddedTableCell align="right">{Action}</PaddedTableCell>
    </HoverProxyTableRow>
  );
}

export default createFragmentContainer(MemberRow, {
  organization: gql`
    fragment MemberRow_organization on Organization {
      viewerIsAdmin
      ...Reinvite_organization
    }
    ${Reinvite.fragments.organization}
  `,
  member: gql`
    fragment MemberRow_member on OrganizationUserEdge {
      isAdmin
      isActivated
      isShared
      node {
        id
        fullName
        email
        isViewer
      }
      ...Reinvite_member
    }
    ${Reinvite.fragments.member}
  `,
});
