import * as React from "react";
import { Route, RouteProps } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "./context";

// PrivateRoute is a Route that requires authentication
// Attempting to visit the route while unauthenticated will redirect to login
export function PrivateRoute({ component, render, ...props }: RouteProps) {
  const auth = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  async function checkAuth() {
    if (!(await auth.isAuthenticated())) {
      auth.login();
    } else {
      setIsAuthenticated(true);
    }
  }
  useEffect(() => {
    checkAuth();
  });

  return (
    <Route
      {...props}
      render={(renderProps) => {
        if (!isAuthenticated) {
          return null;
        }

        if (render) {
          return render(renderProps);
        } else if (component) {
          const C = component;
          return <C {...renderProps} />;
        } else {
          return null; // children variant not supported
        }
      }}
    />
  );
}
