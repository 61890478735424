import urljoin from "url-join";
import { AuthService } from "./auth";

export class APIClient {
  protected readonly root: string;
  protected readonly authService: AuthService;

  constructor(root: string, auth: AuthService) {
    this.root = root;
    this.authService = auth;
  }

  async request(method: string, path: string) {
    const url = urljoin(this.root, path);
    const options = {
      method,
      headers: {
        Authorization: `Bearer ${await this.authService.getToken()}`,
      },
    };
    return fetch(url, options).then((response) => response.json());
  }
}
