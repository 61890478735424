// Note: When adding a role, don't forget to add a corresponding record to the
// BmsRolePresenter dictionary, and the bmsBehaviorKindForRole mapping
export enum BmsRole {
  UNASSIGNED = 0,
  MIN_TEMP_SETTING = 1,
  MAX_TEMP_SETTING = 2,
  MIN_STATIC_PRESS_SETTING = 3,
  MAX_STATIC_PRESS_SETTING = 4,
  STATIC_PRESS = 5,
  HVAC_FAN_MODE = 6,
  HVAC_OP_MODE = 7,
  TEMP_OSA = 8,
  TEMP_RETURN = 9,
  TEMP_SUPPLY = 10,
  TEMP_SPACE = 11,
  LIGHT_LVL_SETTING = 12,
  LIGHT_LVL = 13,
  HUMIDITY_INSIDE = 14,
  HUMIDITY_OUTSIDE = 15,
  HUMIDITY_SETTING = 16,
  CUSTOM_1 = 65000,
  CUSTOM_2 = 65001,
  CUSTOM_3 = 65002,
  CUSTOM_4 = 65003,
  CUSTOM_5 = 65004,
}

export const BmsRoles = Object.keys(BmsRole)
  .map((key) => parseInt(key, 10))
  .filter((parsedKey) => !isNaN(parsedKey))
  .map((key) => key as BmsRole);

export function isRole(maybeRole: string | number | undefined): maybeRole is BmsRole {
  if (maybeRole === undefined) {
    return false;
  }
  return BmsRole[maybeRole] !== undefined;
}
