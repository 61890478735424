import { Priority } from "modules/site-manager/models";

const PrioritiesDictionary = {
  [Priority.ShowAll]: "Everything",
  [Priority.Debug]: "Debug",
  [Priority.Detail]: "Detail",
  [Priority.Normal]: "Normal",
  [Priority.Important]: "Important",
};

export class PriorityPresenter {
  constructor(private priority: Priority) {}

  get displayName() {
    return PrioritiesDictionary[this.priority];
  }
}
