import React, { useState } from "react";
import { useCallback } from "react";
import { Grid, Tooltip } from "@mui/material";
import { NumericEdit } from "../NumericEdit";
import { SiteSetupDialog_site } from "generated-gql-types/SiteSetupDialog_site";

export const CO2E_LABEL = "CO₂e Grid Intensity";
export const CO2E_UNITS = "g/kWh";
export const UTILITY_RATE_UNITS = "$/kWh";
export const DEMAND_RATE_UNITS = "$/kW";
export const DEMAND_RATE_LABEL = "Demand Rate";
export const UTILITY_RATE_LABEL = "Utility Rate";

export const SiteInputsForm = ({
  site,
  onChange,
  disabled,
}: {
  site: SiteSetupDialog_site;
  onChange: (updatedSite: SiteSetupDialog_site) => void;
  disabled?: boolean;
}) => {
  const [utilityRate, setUtilityRate] = useState(site.utilityRates[0]?.rate || 0);
  const [peakDemandCharge, setPeakDemandCharge] = useState(site.peakDemandCharge || 0);
  const [co2eGPerKwh, setCO2eGPerKwh] = useState(site.co2eGPerKwh || 0);

  const withFirstUtilityRate = (rate: number) => {
    const utilityRates = site.utilityRates.slice();
    utilityRates[0] = {
      ...utilityRates[0],
      rate: rate,
    };
    return utilityRates;
  };

  const handleChange = useCallback(
    (updatedSite: SiteSetupDialog_site) => {
      onChange(updatedSite);
    },
    [onChange]
  );

  return (
    <Tooltip
      title={
        site.viewerIsAdmin ? "" : "Only organization admins may change energy savings settings"
      }
      placement="top"
    >
      <Grid
        container
        sx={{ fontSize: "16px", maxWidth: "calc(min(90%, 400px))" }}
        justifyContent="center"
        justifyItems={"center"}
        pl={2}
      >
        <NumericEdit
          id="utilityRate"
          value={utilityRate}
          autoFocus={true}
          onChange={(e) => {
            setUtilityRate(+e.target.value);
            handleChange({
              ...site,
              utilityRates: withFirstUtilityRate(+e.target.value),
              peakDemandCharge,
              co2eGPerKwh,
            });
          }}
          label={UTILITY_RATE_LABEL}
          units={UTILITY_RATE_UNITS}
          disabled={disabled}
          decimalScale={4}
        />
        <NumericEdit
          id="peakDemandCharge"
          value={peakDemandCharge}
          autoFocus={false}
          onChange={(e) => {
            setPeakDemandCharge(+e.target.value);
            handleChange({
              ...site,
              utilityRates: withFirstUtilityRate(utilityRate),
              peakDemandCharge: +e.target.value,
              co2eGPerKwh,
            });
          }}
          label={DEMAND_RATE_LABEL}
          units={DEMAND_RATE_UNITS}
          disabled={disabled}
          decimalScale={2}
        />
        <NumericEdit
          id="co2eGPerKwh"
          value={site.co2eGPerKwh || 0}
          autoFocus={false}
          onChange={(e) => {
            setCO2eGPerKwh(+e.target.value);
            handleChange({
              ...site,
              peakDemandCharge,
              co2eGPerKwh: +e.target.value,
            });
          }}
          label={CO2E_LABEL}
          units={CO2E_UNITS}
          disabled={disabled}
          decimalScale={2}
        />
      </Grid>
    </Tooltip>
  );
};
