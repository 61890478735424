import * as React from "react";
import { observer } from "mobx-react";

import { Card } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";

import { ColumnDef } from "./index";
import { CardTableStyles } from "./styles";

interface CardTableRowProps extends WithStyles<typeof CardTableStyles> {
  columns: ColumnDef[];
  columnWidths: number[];
  item: any;
}

@observer
export class CardTableRowComponent extends React.Component<CardTableRowProps> {
  render() {
    const { classes, columns, columnWidths, item } = this.props;

    return (
      <Card
        // https://github.com/mui-org/material-ui/issues/13744
        component={"li" as "div"}
        className={classes.row}
      >
        {columns.map((columnDef, i) => (
          <div
            key={columnDef.key}
            className={classes.cell}
            style={{
              flex: `0 0 ${columnWidths[i]}%`,
              textAlign: columnDef.alignment,
            }}
          >
            {columnDef.getCell(item)}
          </div>
        ))}
      </Card>
    );
  }
}

export const CardTableRow = withStyles(CardTableStyles)(CardTableRowComponent);
