import * as React from "react";

type TickProps = {
  x: number;
  y: number;
  payload: TickPayload;
};

type TickPayload = {
  value: string;
};

export const TimeseriesTick = (props: TickProps) => {
  const { x, y, payload } = props;
  const time = new Date(payload.value);

  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={16} textAnchor="end" transform="rotate(-30)" fontSize={9}>
        {time.toLocaleString()}
      </text>
    </g>
  );
};
