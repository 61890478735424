import * as React from "react";
import { useCallback } from "react";
import { gql } from "@apollo/client";
import { SiteAlertsToggle_site } from "generated-gql-types/SiteAlertsToggle_site";
import { FormGroup, Tooltip } from "@material-ui/core";
import { AlignedLabel } from "./AlignedLabel";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { useToggleSiteAlertGenMutation } from "modules/common/mutations/SiteAlertsToggleMutation";
import { Toggle } from "sigil";

const SiteAlertsToggleForm = ({ site }: { site: SiteAlertsToggle_site }) => {
  const toggle = useToggleSiteAlertGenMutation();
  const { id } = site;

  const handleChange = useCallback(
    (e) => {
      const onChange = async (e: any, siteId: string) =>
        await toggle({ siteId, enable: e.target.checked });
      return onChange(e, id);
    },
    [id, toggle]
  );

  return (
    <FormGroup row={true}>
      <Tooltip
        title={site.viewerIsAdmin ? "" : "Only organization admins may change alert settings"}
        placement="right-start"
      >
        <AlignedLabel
          control={
            <Toggle
              checked={site.alertsEnabled}
              disabled={!site.viewerIsAdmin}
              onChange={handleChange}
            />
          }
          label="Allow new alerts to be created for this site"
        />
      </Tooltip>
    </FormGroup>
  );
};

export default createFragmentContainer(SiteAlertsToggleForm, {
  site: gql`
    fragment SiteAlertsToggle_site on Site {
      id
      alertsEnabled
      viewerIsAdmin
    }
  `,
});
