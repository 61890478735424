
export const MAGNITUDES = ["", "k", "m", "b", "g", "t", "p"];
export const formatNumber = (
  value: number,
  abbreviationThreshold: number = 1000,
  minimumFractionDigits: number = 0,
  maximumFractionDigits: number = 1,
  currency?: string
) => {
  let abbreviatedNumber = value;
  let magnitude = 0;

  while (abbreviatedNumber >= abbreviationThreshold && magnitude < MAGNITUDES.length - 1) {
    magnitude += 1;
    abbreviatedNumber /= 1000;
  }

  // round to at most 1 decimal place, but suppress a trailing .0
  return (
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
      currency: currency || undefined,
      style: currency ? "currency" : "decimal",
    }).format(abbreviatedNumber) + MAGNITUDES[magnitude]
  );
};

export const formatYAxis = (value: number) => {
  return formatNumber(value, 10000);
};

export const Y_AXIS_PROPS = { tickFormatter: formatYAxis, width: 50 };
