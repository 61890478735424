import { makeStyles } from "@material-ui/core/styles";
import iconPrev from "./Icons/IconPrevBlue.svg";
import iconNext from "./Icons/IconNextBlue.svg";
import { Colors } from "sigil";

export const useDateTimePickerStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    icon: {
      fontSize: "15px",
      marginRight: spacing(1),
      color: "lightgray",
    },
    root: {
      "& span.rdrWeekDay": {
        color: theme.palette.primary.main,
      },
      "& div.rdrMonthName": {
        textTransform: "uppercase",
      },
      "& span.rdrMonthPicker select": {
        textTransform: "uppercase",
      },
      "& .rdrMonthAndYearWrapper": {
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: "0px",
      },
      "& .rdrMonth": {
        padding: spacing(2),
      },
      "& .rdrNextPrevButton": {
        margin: spacing(2),
        width: spacing(4),
        background: "none",
        height: spacing(4),
        "&:hover": {
          background: Colors.waveLightest,
        },
      },
      "& .rdrPprevButton": {
        "& i": {
          display: "block",
          height: "18px",
          width: "17px",
          transform: "none",
          border: "none",
          background: `url(${iconPrev})`,
        },
      },
      "& .rdrNextButton": {
        "& i": {
          display: "block",
          height: "18px",
          width: "17px",
          transform: "none",
          border: "none",
          background: `url(${iconNext})`,
        },
      },
      "& .rdrCalendarWrapper": {
        borderBottom: `1px solid ${theme.palette.divider}`,
        width: "516px",
      },
      "& .rdrDefinedRangesWrapper": {
        width: "inherit",
      },
      "& button.rdrStaticRange": {
        border: "none",
        "&:hover": {
          fontWeight: "bold",
        },
        "&.rdrStaticRangeSelected": {
          color: `${Colors.onyx} !important`,
        },
        "&span": {
          padding: "8px 0px 8px 0px",
        },
      },
      "& .rdrStaticRanges": {
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: "8px 24px 24px 8px",
      },
      "& .rdrStartEdge": {
        borderRadius: "0",
      },
      "& .rdrEndEdge": {
        borderRadius: "0",
        left: "0px",
      },
      "& .rdrStartEdge.rdrEndEdge": {
        right: "4px !important",
        left: "4px !important",
        top: "6px",
        bottom: "6px",
      },
      "& .rdrDayInPreview": {
        "&:before": {
          display: "block",
          height: "26px",
          width: "4px",
          position: "absolute",
          left: "-3px",
          top: "2px",
          backgroundColor: Colors.waveLightest,
          content: "close-quote",
        },
        "&:after": {
          content: "close-quote",
          display: "block",
          position: "absolute",
          width: "4px",
          right: "-4px",
          height: "26px",
          top: "2px",
          backgroundColor: Colors.waveLightest,
        },
      },
      "& .rdrInRange": {
        borderRadius: "0",
      },
      "& .rdrDayNumber": {
        "& span::after": {
          display: "none",
        },
        "&:hover": {
          color: Colors.waveLightest,
        },
      },
      "& .rdrDayHovered": {
        borderRadius: "0 !important",
      },
      "& .rdrDayStartPreview, .rdrDayInPreview": {
        borderRadius: "0",
        border: "none",
        "&:hover": {
          backgroundColor: Colors.waveLightest,
        },
      },
      "& .rdrDayEndPreview": {
        borderRadius: "0",
        border: "none",
      },
      "& .rdrDayInPreview + .rdrDayNumber, .rdrDayHovered > .rdrDayEndPreview + .rdrDayNumber, .rdrDayHovered > .rdrDayStartPreview + .rdrDayNumber":
        {
          backgroundColor: Colors.waveLightest,
        },

      "& .rdrDayStartPreview.rdrDayEndPreview ~ .rdrDayNumber": {
        background: "none",
      },
      "& .rdrInputRanges div:nth-child(2)": {
        display: "none",
      },
    },
    input: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      display: "flex",
      "& #startTime": {
        margin: "16px 0px 16px 24px",
        display: "flex",
        alignItems: "center",
      },
      "& #endTime": {
        margin: "16px 24px 16px 32px",
        display: "flex",
        alignItems: "center",
      },
    },
    definedRange: {
      width: spacing(23),
      marginBottom: "-10%",
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
    buttons: {
      margin: "16px 16px 16px 0px",
    },
    grid: {
      width: "100%",
      display: "flex",
    },
  };
});
