import * as React from "react";
import { Typography } from "@mui/material";
import { LightCaption } from "modules/common/components/Typography";
import moment from "moment";
import { AlertsGrid_alerts_edges_node } from "generated-gql-types/AlertsGrid";
import { AlertDetail_alert_Alert } from "generated-gql-types/AlertDetail";
import { ALERT_GRID_FONT_SIZE } from "../components";

interface AlertTriggeredMomentProps {
  alert: AlertsGrid_alerts_edges_node | AlertDetail_alert_Alert;
}

export const AlertTriggeredMoment = ({ alert }: AlertTriggeredMomentProps) => {
  const triggeredMoment = moment(alert.createdAt);
  return (
    <Typography fontSize={ALERT_GRID_FONT_SIZE} fontFamily="Barlow">
      {triggeredMoment.format("MMM D, YYYY h:mma")}
      <br />
      <LightCaption>
        <Typography component={"span"} fontSize={12}>
          {triggeredMoment.fromNow()}
        </Typography>
      </LightCaption>
    </Typography>
  );
};
