import { useMutation, OperationVariables, MutationHookOptions } from "@apollo/client";

export const composeMutationHook =
  <Q, I, V extends OperationVariables = OperationVariables>(
    query: any,
    options?: MutationHookOptions<Q, V>
  ) =>
  () => {
    const [mutate] = useMutation<Q, V>(query);
    return (input: I) => {
      const mutationOptions = Object.assign({} as V, options, { variables: { input } });
      return mutate(mutationOptions);
    };
  };
