import { computed } from "mobx";
import moment from "moment";

import { Motor } from "./motor";
import { EnergyDataStat } from "modules/site-manager/constants/energy-data";
import { EnergyDataStatPresenter } from "modules/site-manager/presenters/energy-data-stat";

export class EnergyData {
  constructor(public motor: Motor, public op: EnergyDataStat) {}

  @computed
  get id() {
    const { motor, op } = this;
    return `${motor.id}:${op}`;
  }

  @computed
  get name() {
    const { motor, op } = this;
    const presenter = new EnergyDataStatPresenter(op);
    return `${motor.displayName}: ${presenter.name}`;
  }

  static equals(a: EnergyData, b: EnergyData) {
    return a.motor === b.motor && a.op === b.op;
  }
}

export type EnergyDataPointProps = {
  ts?: string;
  baseline_power?: number;
  tt_power?: number;
  tt_power_with_compressor_penalty?: number;
};

export class EnergyDataPoint {
  ts!: string;
  baselinePower?: number;
  ttPower?: number;
  ttPowerWithCompressorPenalty?: number;

  constructor(point: EnergyDataPointProps) {
    this.ts = point.ts!; // we assume this is there.
    this.baselinePower = point.baseline_power;
    this.ttPower = point.tt_power;
    this.ttPowerWithCompressorPenalty = point.tt_power_with_compressor_penalty;
  }

  @computed
  get tsTime() {
    return new Date(this.ts);
  }

  @computed
  get recordedAtTime() {
    return new Date(this.ts);
  }

  @computed
  get recordedAtMoment() {
    return moment(this.recordedAtTime);
  }

  @computed
  get recordedAtMs() {
    return this.recordedAtTime.getTime();
  }
}
