import * as React from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TypographyProps, Typography } from "sigil";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    editIcon: {
      display: "none",
      cursor: "pointer",
      color: theme.palette.secondary.main,
      fontSize: 13,
      marginLeft: theme.spacing(1),

      "$root:hover &": {
        display: "inline",
      },
    },
  });

type EditableTypographyProps = TypographyProps & {
  onEdit: () => void;
  disabled?: boolean;
};

export const EditableTypography = withStyles(styles)(
  ({
    classes,
    onEdit,
    disabled,
    ...typographyProps
  }: EditableTypographyProps & WithStyles<typeof styles>) => {
    const typography = <Typography noWrap={false} {...typographyProps} />;

    if (disabled) {
      return typography;
    }

    return (
      <div className={classes.root} onClick={onEdit}>
        {typography}
        <span className={classes.editIcon}>edit</span>
      </div>
    );
  }
);
