import React, { useEffect, useState } from "react";
import { Box, Card, CircularProgress, Grid, Stack } from "@mui/material";
import { extractYAxisProps } from "modules/charts/axes";
import { Typography } from "@material-ui/core";
import { BasicTimeseriesChart } from "modules/charts/components/BasicTimeseriesChart";
import { createSeriesForEnergyDataField, Series, SeriesCollection } from "modules/charts/series";
import { CalendarPopover } from "modules/site-manager/components/CalendarPopover";
import { AnalysisStore, SiteStore } from "modules/site-manager/stores";
import { observer } from "mobx-react";
import { APIStore } from "modules/common/stores";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { exportCSVFile } from "../../SiteAnalysis/ExportCSV";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { gql } from "@apollo/client";
import { Link } from "react-router-dom";
import { MotorPowerComparisonChart_motor } from "generated-gql-types/MotorPowerComparisonChart_motor";
import { injectStores } from "modules/common/stores";
import { ENERGY_CARD_STYLE, getEnergyCardTitleStyle } from "../../../../utils/cardStyles";
import { DURATION } from "modules/common/utils/time";
import { unmarshalNumberId, unmarshalStringId } from "modules/common/utils/relay";
import { EnergyDataStat } from "modules/site-manager/constants/energy-data";
import { EnergyData } from "modules/site-manager/models/energy-data";
import { Motor } from "modules/site-manager/models";
import { Button, Colors } from "sigil";
import { Y_AXIS_PROPS } from "../../../../utils/chartUtils";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

interface MotorPowerComparisonChartProps {
  motor: MotorPowerComparisonChart_motor;
  siteId: string;
  siteStore: SiteStore;
  apiStore?: APIStore;
}

const DEFAULT_RANGE = DURATION.DAY * 7;
const CLIFF_BUSTER = DURATION.MINUTE * 10;

const constructAnalysisStore = (props: MotorPowerComparisonChartProps, motorId: string) => {
  const analysisStore = new AnalysisStore(props.apiStore || ({} as APIStore), props.siteStore);
  analysisStore.setTimes(
    new Date(Date.now() - DEFAULT_RANGE - CLIFF_BUSTER),
    new Date(Date.now() - CLIFF_BUSTER)
  );
  const motor: Motor | undefined = props.siteStore.site.sortedMotors.find((m) => m.id === motorId);
  if (motor) {
    analysisStore.addEnergyDataField(new EnergyData(motor, EnergyDataStat.BaselinePower));
    analysisStore.addEnergyDataField(new EnergyData(motor, EnergyDataStat.TTPower));
    analysisStore.addEnergyDataField(
      new EnergyData(motor, EnergyDataStat.TTPowerWithCompressorPenalty)
    );
  }
  return analysisStore;
};

export const MotorPowerComparisonChartComponent = injectStores("api")(
  observer((props: MotorPowerComparisonChartProps) => {
    const motorId = unmarshalStringId(props.motor?.id);
    const [analysisStore, setAnalysisStore] = useState<AnalysisStore | null>(null);
    const [energySeries, setEnergySeries] = useState<SeriesCollection | null>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // Effect 1: create the analysis store once per motor/props
    useEffect(() => {
      setEnergySeries(null);
      setAnalysisStore(constructAnalysisStore(props, motorId));
    }, [props, motorId]);

    // Effect 2: create everything that depends on the analysis store
    useEffect(() => {
      if (analysisStore) {
        setEnergySeries(
          new SeriesCollection(
            ...analysisStore.selectedEnergyDataFields.map((field) =>
              createSeriesForEnergyDataField(field)
            )
          )
        );
      }
    }, [analysisStore]);

    const handleExportClick = () => {
      if (analysisStore && energySeries) {
        const headers = [...analysisStore.selectedEnergyDataFields].map((field) => field.name);
        const variables = props.motor?.supervisor?.variables;
        const timezone = variables?.length ? props.motor?.supervisor?.variables[0].zone : "UTC";
        const data = analysisStore.zippedTimeseriesBuckets;
        const rows = data.map((datum) => {
          const row: any = {
            timestamp: momentTimezone(datum.timestamp).format("yyyy-MM-DD HH:mm:ss"),
          };
          energySeries.all.forEach(
            (series: Series, index) =>
              series.formatValue &&
              (row[headers[index]] = series.formatValue(series.getValue(datum)))
          );
          return row;
        });
        headers.unshift(`Time (${timezone})`);
        const now = moment();
        const filename = `Energy-Savings-${props.motor.displayName}-${now.format("l-LTS-z")}`;
        exportCSVFile(headers, rows, filename);
      }
    };

    const analysisUrl = `/sites/${unmarshalNumberId(props.siteId)}/analysis?v=${motorId}$tt_power${
      analysisStore ? analysisStore.dateQueryParam : ""
    }`;

    return (
      <Card sx={ENERGY_CARD_STYLE} style={{ overflowX: "auto" }}>
        {analysisStore && energySeries ? (
          <Grid container={true} spacing={2} p={2} justifyItems="stretch" justifyContent="stretch">
            <Grid
              item={true}
              container={true}
              justifyContent="space-between"
              direction="row"
              xs={12}
            >
              <Box display="flex" m={0.5} alignItems="center" justifyContent="space-between" mb={1}>
                <Typography style={getEnergyCardTitleStyle(isMobile)}>{`Motor Power Comparison: ${
                  props.motor?.displayName || ""
                }`}</Typography>
              </Box>
              <Stack direction="row" spacing={1} alignItems="center">
                <CalendarPopover analysisStore={analysisStore} />
                <Button
                  variant="knockout"
                  style={{
                    backgroundColor: Colors.white,
                    marginLeft: "4px",
                    padding: 0,
                    minWidth: "105px",
                  }}
                  onClick={handleExportClick.bind(this)}
                  disabled={!analysisStore || analysisStore.selectedEnergyDataFields.length === 0}
                  title="Download a CSV of the data currently visible in the chart"
                >
                  Export CSV
                </Button>
                <Link to={analysisUrl}>
                  <Typography variant="overline">
                    View Analysis
                    <i className="fas fa-external-link-alt" style={{ marginLeft: "8px" }} />
                  </Typography>
                </Link>
              </Stack>
            </Grid>
            <Grid item justifyContent={"stretch"} justifyItems={"stretch"} xs={12}>
              <BasicTimeseriesChart
                key={props.motor.id}
                data={analysisStore.zippedTimeseriesBuckets}
                tsKey={"timestamp"}
                series={energySeries}
                isLoading={analysisStore.isLoadingMotors}
                yAxisPropsSet={extractYAxisProps(analysisStore.selectedFields).map((props) => ({
                  ...props,
                  ...Y_AXIS_PROPS,
                }))}
              />
            </Grid>
          </Grid>
        ) : (
          <CircularProgress size={28} />
        )}
      </Card>
    );
  })
);

export const MotorPowerComparisonChart = createFragmentContainer(
  MotorPowerComparisonChartComponent,
  {
    motor: gql`
      fragment MotorPowerComparisonChart_motor on Motor {
        id
        displayName
        supervisor {
          id
          variables {
            id
            zone
          }
        }
      }
    `,
  }
);
