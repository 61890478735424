import * as React from "react";
import moment from "moment";
import { Area, AreaChart, Tooltip, TooltipProps } from "recharts";
import {
  withStyles,
  WithStyles,
  createStyles,
  WithTheme,
  withTheme,
} from "@material-ui/core/styles";

import { LightCaption } from "modules/common/components/Typography";
import { TooltipValue } from "modules/charts/components/TimeseriesTooltip";

type ValueFormatter = (value: number | null) => React.ReactNode;

export type SparklinePoint = {
  timestamp: Date;
  value: number | null;
};

type SparklineProps = {
  data: SparklinePoint[];
  formatValue?: ValueFormatter;
};

function SparklineComponent({ data, formatValue, theme }: SparklineProps & WithTheme) {
  const color = theme.palette.secondary.main;
  const gradientId = "gradient";

  return (
    <AreaChart
      width={120}
      height={60}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      data={data}
    >
      <defs>
        <linearGradient id={gradientId} x1={0} x2={0} y1="0%" y2="100%">
          <stop offset="0%" style={{ stopColor: color, stopOpacity: 0.2 }} />
          <stop offset="100%" style={{ stopColor: color, stopOpacity: 0 }} />
        </linearGradient>
      </defs>
      <Area
        dataKey="value"
        stroke={color}
        strokeWidth={2}
        fill={`url(#${gradientId})`}
        isAnimationActive={false}
        connectNulls={false}
      />
      <Tooltip
        content={<SparkTooltip formatValue={formatValue} />}
        wrapperStyle={{
          height: "100%",
          width: "100%",
        }}
      />
    </AreaChart>
  );
}

type SparkTooltipProps = {
  formatValue?: ValueFormatter;
};

const SparkTooltipStyles = () => {
  return createStyles({
    root: {
      visibility: "visible",
      position: "absolute",
      bottom: "-2.2em",
      width: "100%",
      textAlign: "center",
      fontSize: 12,
      lineHeight: 1,
    },
  });
};

const SparkTooltipComponent: React.FunctionComponent<
  SparkTooltipProps & TooltipProps<TooltipValue, string> & WithStyles<typeof SparkTooltipStyles>
> = function ({
  active,
  classes,
  formatValue,
  payload,
}: SparkTooltipProps & TooltipProps<TooltipValue, string> & WithStyles<typeof SparkTooltipStyles>) {
  if (!active || !payload || payload.length < 1) {
    return null;
  }

  const format: ValueFormatter = formatValue || ((n) => (n === null ? "?" : n.toString()));
  const point = payload[0].payload as SparklinePoint;

  return (
    <div className={classes.root}>
      <LightCaption>{moment(point.timestamp).calendar()}</LightCaption>
      <br />
      <strong>{format(point.value)}</strong>
    </div>
  );
};

const SparkTooltip = withStyles(SparkTooltipStyles)(SparkTooltipComponent);

export const Sparkline = React.memo(withTheme(SparklineComponent));
