import * as React from "react";
import { Grid } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { FancyDivider } from "../../../components/FancyDivider";

export const SectionComponent: React.FC<{
  title: string;
  classes: { root: string };
  button?: React.ReactElement;
  icon?: React.ReactElement;
}> = ({ title, children, classes, button, icon }) => (
  <Grid className={classes.root} item={true} container={true} xs={12} justifyContent="stretch">
    <FancyDivider button={button} icon={icon}>
      {title}
    </FancyDivider>
    <Grid container={true} item={true} xs={12} alignItems="stretch" direction="column">
      {children}
    </Grid>
  </Grid>
);
export const Section = withStyles({
  root: {
    marginBottom: "2em",
  },
})(SectionComponent);
