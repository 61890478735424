import { useMutation, gql } from "@apollo/client";
import { UpdateSupervisorNameInput } from "generated-gql-types/globalTypes";

const mutation = gql`
  mutation UpdateSupervisorName($input: UpdateSupervisorNameInput!) {
    updateSupervisorName(input: $input) {
      supervisor {
        id
        name
        displayName
      }
    }
  }
`;

export function useUpdateSupervisorNameMutation() {
  const [mutate] = useMutation(mutation);
  return (input: UpdateSupervisorNameInput) => mutate({ variables: { input } });
}
