import * as React from "react";
import { MouseEvent, useState } from "react";
import { Card, TableCell, TableRow, Typography } from "@material-ui/core";
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import StorageIcon from "@mui/icons-material/Storage";

import {
  SupervisorSection_site,
  SupervisorSection_site_supervisors,
} from "generated-gql-types/SupervisorSection_site";
import { ActionMenu, ActionMenuButton, ActionMenuItem } from "modules/common/components/ActionMenu";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { SimpleTable } from "modules/common/components/SimpleTable";
import { useMenuState } from "modules/common/hooks";

import { AssociateSupervisorButton } from "./AssociateSupervisorDialog";
import { LogicCell, NameCell, UnkCell } from "./Cells";
import { Section } from "./ConfigSection";
import { DeviceFirmwareCell } from "./FirmwareCell";
import { RenameSupervisorDialog } from "./RenameSupervisorDialog";
import { UpgradeFirmwareDialog } from "./UpgradeFirmwareDialog";
import { UploadFlowDialog } from "./UploadFlowDialog";
import { DissociateSupervisorDialog } from "./DissociateSupervisorDialog";

type SupervisorSectionProps = {
  site: SupervisorSection_site;
};
const SupervisorSectionComponent = ({ site }: SupervisorSectionProps) => {
  type supervisor = SupervisorSection_site_supervisors | null;

  const isAdmin = site.viewerIsAdmin;

  const menu = useMenuState();
  const [actionSupervisor, setActionSupervisor] = useState<supervisor>(null);
  const handleMenuOpen = (spv: supervisor) => (event: MouseEvent) => {
    setActionSupervisor(spv);
    menu.handleOpen(event);
  };

  const [renamingSupervisor, setRenamingSupervisor] = useState<supervisor>(null);
  const handleRenameOpen = () => {
    menu.handleClose();
    setRenamingSupervisor(actionSupervisor);
  };

  const canUpgradeFirmware =
    isAdmin &&
    actionSupervisor?.firmware.status?.__typename === "FirmwareOutdated" &&
    actionSupervisor?.firmware.status.isUpgradable;

  const [upgradingSupervisor, setUpgradingSupervisor] = useState<supervisor>(null);
  const handleUpgradeOpen = () => {
    menu.handleClose();
    setUpgradingSupervisor(actionSupervisor);
  };

  const canUploadFlow = isAdmin && (actionSupervisor?.isShadowAware ?? false);

  const [uploadingFlowSupervisor, setUploadingFlowSupervisor] = useState<supervisor>(null);
  const handleUploadFlowOpen = () => {
    menu.handleClose();
    setUploadingFlowSupervisor(actionSupervisor);
  };

  const [dissociatingSupervisor, setDissociatingSupervisor] = useState<supervisor>(null);
  const handleDissociateOpen = () => {
    menu.handleClose();
    setDissociatingSupervisor(actionSupervisor);
  };

  // Check every 10s for a last communication update
  useQuery(supervisorCommunicationRefetchQuery, {
    variables: { siteId: site.id },
    pollInterval: 10000,
    notifyOnNetworkStatusChange: true,
  });

  return (
    <Section
      title="Supervisors"
      button={isAdmin ? <AssociateSupervisorButton site={site} /> : undefined}
      icon={<StorageIcon />}
    >
      <Card>
        <SimpleTable
          headers={
            isAdmin
              ? ["Supervisor", "Cascade Flow", "Last Communication", "Firmware Version", ""]
              : ["Supervisor", "Cascade Flow", "Last Communication", "Firmware Version"]
          }
          emptyMessage="No supervisors."
        >
          {site.supervisors.map((spv: SupervisorSection_site_supervisors) => (
            <SupervisorRow
              key={spv.id}
              spv={spv}
              isAdmin={isAdmin}
              onAction={handleMenuOpen(spv)}
            />
          ))}
        </SimpleTable>

        <ActionMenu {...menu.props} onClick={menu.handleClose} disableAutoFocusItem>
          <ActionMenuItem onClick={handleRenameOpen} disabled={!isAdmin}>
            <Typography>Rename</Typography>
          </ActionMenuItem>
          <ActionMenuItem onClick={handleUpgradeOpen} disabled={!canUpgradeFirmware}>
            <Typography>Update to latest firmware</Typography>
          </ActionMenuItem>
          <ActionMenuItem onClick={handleUploadFlowOpen} disabled={!canUploadFlow}>
            <Typography>Load control logic</Typography>
          </ActionMenuItem>
          <ActionMenuItem onClick={handleDissociateOpen} disabled={!isAdmin}>
            <Typography color="secondary">Remove from site</Typography>
          </ActionMenuItem>{" "}
        </ActionMenu>

        {renamingSupervisor && (
          <RenameSupervisorDialog
            open={true}
            onClose={() => setRenamingSupervisor(null)}
            supervisor={renamingSupervisor}
          />
        )}

        {upgradingSupervisor && (
          <UpgradeFirmwareDialog
            open={true}
            onClose={() => setUpgradingSupervisor(null)}
            device={upgradingSupervisor}
          />
        )}

        {uploadingFlowSupervisor && (
          <UploadFlowDialog
            open={true}
            onClose={() => setUploadingFlowSupervisor(null)}
            device={uploadingFlowSupervisor}
          />
        )}

        {dissociatingSupervisor && (
          <DissociateSupervisorDialog
            open={true}
            onClose={() => setDissociatingSupervisor(null)}
            supervisor={dissociatingSupervisor}
          />
        )}
      </Card>
    </Section>
  );
};

type SupervisorRowProps = {
  spv: SupervisorSection_site_supervisors;
  isAdmin: boolean;
  onAction: (event: MouseEvent) => void;
};
const SupervisorRow = (props: SupervisorRowProps) => {
  const { name, serialNumber, cascadeFlow, lastCommunication } = props.spv;

  return (
    <TableRow style={{ height: 48 }}>
      <NameCell name={name} id={serialNumber} />
      <UnkCell>{cascadeFlow}</UnkCell>
      <LogicCell ts={lastCommunication ? moment(lastCommunication) : null} />
      <DeviceFirmwareCell device={props.spv} isAdmin={props.isAdmin} />
      <TableCell align="right">
        <ActionMenuButton onClick={props.onAction} />
      </TableCell>
    </TableRow>
  );
};

export const SupervisorSection = createFragmentContainer(SupervisorSectionComponent, {
  site: gql`
    fragment SupervisorSection_site on Site {
      id
      viewerIsAdmin
      ...AssociateSupervisorButton_site
      supervisors {
        id
        name
        displayName
        serialNumber
        cascadeFlow
        lastCommunication
        firmware {
          status {
            ... on FirmwareOutdated {
              isUpgradable
            }
          }
        }
        isShadowAware
        ...DeviceFirmwareCell_device
        ...RenameSupervisorDialog_supervisor
        ...UploadFlowDialog_device
        ...UpgradeFirmwareDialog_device
      }
    }
    ${AssociateSupervisorButton.fragments.site}
    ${DeviceFirmwareCell.fragments.device}
    ${RenameSupervisorDialog.fragments.supervisor}
    ${UploadFlowDialog.fragments.device}
    ${UpgradeFirmwareDialog.fragments.device}
  `,
});

const supervisorCommunicationRefetchQuery = gql`
  query SupervisorCommunicationRefetchQuery($siteId: ID!) {
    node(id: $siteId) {
      ... on Site {
        id
        supervisors {
          id
          lastCommunication
        }
      }
    }
  }
`;
