import * as React from "react";
import { Map as MapboxMap, LngLatLike } from "mapbox-gl";
import { useState, useMemo, useRef, useEffect, useCallback, ChangeEvent } from "react";
import geocodeClient, {
  GeocodeService,
  GeocodeFeature,
} from "@mapbox/mapbox-sdk/services/geocoding";
import { useField } from "formik";
import { debounce } from "lodash";
import { useMediaQuery } from "@material-ui/core";
import { styled, withTheme } from "@material-ui/core/styles";
import { LocatorIcon } from "./Icons";
import { SmcMuiTheme } from "theme";
import { Autocomplete } from "sigil";

type FormikAddressInputProps = {
  map?: MapboxMap;
  loaded: boolean;
};
export const FormikAddressInput = ({ map, loaded }: FormikAddressInputProps) => {
  // The currently selected feature, and a list of suggestions
  const [features, setFeatures] = useState<{
    selected: GeocodeFeature | null;
    suggestions: GeocodeFeature[];
  }>({ selected: null, suggestions: [] });

  // Are we making a request?
  const [loading, setLoading] = useState<boolean>(false);
  // A hidden field for latitude derived from the selected feature
  const latitudeField = useField("latitude");
  const [, latitudeMeta, { setValue: setLatitude }] = latitudeField;
  // A hidden field for longitude derived from the selected feature
  const longitudeField = useField("longitude");
  const [, longitudeMeta, { setValue: setLongitude }] = longitudeField;
  // Our hidden field for the actual address derived from the selected feature
  const [addressField, addressMeta, { setValue: setAddress }] = useField("address");
  // What the user has typed into the field
  const [addressInput, setAddressInput] = useState<string | null>("");

  const geocodeService = useRef<GeocodeService | null>(null);
  const geocode = useMemo(
    () =>
      debounce(async (address: string | null, callback: (features: GeocodeFeature[]) => void) => {
        if (address && geocodeService.current) {
          setLoading(true);
          const response = await geocodeService.current
            .forwardGeocode({
              query: address,
              mode: "mapbox.places",
              language: ["en-US", "en-UK"],
              proximity: [-98.585522, 39.8333333],
              autocomplete: true,
            })
            .send();
          setLoading(false);
          callback(response?.body.features ?? []);
        }
      }, 200),
    []
  );

  // Handle reverse geocoding when the user moves the map
  const reverseGeocode = useMemo(
    () =>
      debounce(async (point: LngLatLike, callback: (features: GeocodeFeature[]) => void) => {
        if (geocodeService.current) {
          setLoading(true);
          const response = await geocodeService.current
            .reverseGeocode({
              query: point,
              mode: "mapbox.places",
            })
            .send();
          setLoading(false);
          callback(response?.body.features ?? []);
        }
      }, 200),
    []
  );

  // Handle geocoding, this is an Effect and not a callback because we need to make sure not to
  // update state on an unmounted component.
  useEffect(() => {
    let active = true;
    if (!geocodeService.current) {
      geocodeService.current = geocodeClient({ accessToken: process.env.REACT_APP_MAPBOX_TOKEN! });
    }
    if (addressInput === "") {
      setFeatures({ selected: null, suggestions: [] });
      return undefined;
    }
    geocode(addressInput, (suggestions) => {
      if (active) {
        setFeatures({ selected: null, suggestions });
      }
    });
    return () => {
      active = false;
    };
  }, [map, addressInput, geocode]);

  // Called when the user moves selects an input or when they move the map
  const onChange = useCallback(
    (value: GeocodeFeature) => {
      setFeatures({ selected: value, suggestions: [value] });
      setLatitude(value.geometry.coordinates[1]);
      setLongitude(value.geometry.coordinates[0]);
      setAddress(value.place_name || value.text);
    },
    [setAddress, setLatitude, setLongitude, setFeatures]
  );

  // Handle the user moving the map
  useEffect(() => {
    if (map && loaded) {
      const cb = () => {
        // move the geocoded point to the one under the locator
        const coords = map.getCenter();
        const px = map.project(coords.toArray() as [number, number]);
        const adjusted = map.unproject(px);
        reverseGeocode(adjusted.toArray() as [number, number], (features) => {
          if (features[0]) {
            onChange(features[0]);
          }
        });
      };
      map.on("dragend", cb);
      return () => {
        if (map && loaded) map.off("dragend", cb);
      };
    }
    return;
  }, [map, loaded, reverseGeocode, onChange]);

  // Handle map zooming
  const zoom = useCallback(
    (feature) => {
      // move the map around
      if (map && loaded && feature) {
        if (feature.bbox) {
          map.fitBounds(feature.bbox as [number, number, number, number]);
        } else {
          map.flyTo({
            center: feature.geometry.coordinates,
            zoom: 18,
            speed: 1.6,
          });
        }
      }
    },
    [map, loaded]
  );

  // If any of these are an error show it
  const showError =
    ((addressMeta.touched && !!addressMeta.error) ||
      (longitudeMeta.touched && !!longitudeMeta.error) ||
      (latitudeMeta.touched && !!latitudeMeta.error)) &&
    addressMeta.value !== addressMeta.initialValue;

  return (
    <>
      <Autocomplete
        options={features.suggestions}
        getOptionLabel={(option) => option.place_name || option.text}
        loading={loading}
        onChange={(_e: ChangeEvent<{}>, value: GeocodeFeature | null, reason: string) => {
          if (reason === "select-option") {
            if (value) onChange(value);
            zoom(value);
          }
        }}
        onInputChange={(_e, value, reason) => reason === "input" && setAddressInput(value)}
        value={features.selected}
        filterOptions={(o) => o}
        inputProps={{
          ...addressField,
          label: "Address",
          helperText: showError
            ? addressMeta.error || longitudeMeta.error || latitudeMeta.error
            : null,
          placeholder: "Enter location or choose pin",
          required: true,
        }}
      />
      <LocatorPin map={map} loaded={loaded} />
    </>
  );
};

type LocatorPinProps = {
  map?: MapboxMap;
  loaded: boolean;
  theme: typeof SmcMuiTheme;
};

const PinContainer = styled("div")({
  zIndex: 1,
  position: "absolute",
  display: "none",
  pointerEvents: "none",
  width: 44,
  height: 52,
});

const LocatorPin = withTheme(({ map, loaded, theme }: LocatorPinProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isInteractive = useMediaQuery(theme.breakpoints.up("sm"));
  useEffect(() => {
    const div = ref.current;
    if (div && map && loaded) {
      const coords = map.getCenter();
      const px = map.project([coords.lng, coords.lat]);
      const { x, y } = px;
      div.style.display = "block";
      div.style.top = `${y - 48}px`;
      div.style.left = `${x - 22}px`;
    }
  });
  if (!isInteractive) return null;
  return (
    <PinContainer ref={ref}>
      <LocatorIcon />
    </PinContainer>
  );
});
