import React, { MouseEvent, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Card, TableCell, TableRow, Typography } from "@material-ui/core";
import moment from "moment";
import { gt, RangeOptions } from "semver";

import {
  MotorSection_site,
  MotorSection_site_alerts,
  MotorSection_site_alerts_edges_node_cause_AlertCauseMotorConnectivity,
  MotorSection_site_alerts_edges_node_cause_AlertCauseMotorErrors,
  MotorSection_site_alerts_edges_node_cause_AlertCauseMotorLowTorque,
  MotorSection_site_alerts_edges_node_cause_AlertCauseMotorSeizure,
  MotorSection_site_alerts_edges_node_cause_AlertCauseMotorSpeed,
  MotorSection_site_motors,
} from "generated-gql-types/MotorSection_site";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { ActionMenu, ActionMenuButton, ActionMenuItem } from "modules/common/components/ActionMenu";
import { SimpleTable } from "modules/common/components/SimpleTable";
import { useMenuState } from "modules/common/hooks";

import { LogicCell, NameCell, UnkCell, LinkCell } from "./Cells";
import { Section } from "./ConfigSection";
import { DeviceFirmwareCell } from "./FirmwareCell";
import { UpgradeFirmwareDialog } from "./UpgradeFirmwareDialog";
import { UploadFlowDialog } from "./UploadFlowDialog";
import { RebootMotorDialog } from "./RebootMotorDialog";
import { ClearMotorFaultsDialog } from "./ClearMotorFaultsDialog";
import MotorIcon from "@mui/icons-material/WindPower";
import { unmarshalNumberId, unmarshalStringId } from "modules/common/utils/relay";
import { DissociateMotorDialog } from "./DissociateMotorDialog";

interface MotorSectionProps {
  site: MotorSection_site;
}

const supervisorHasMotorCommands = (actionMotor: MotorSection_site_motors | null) => {
  let version = actionMotor?.supervisor?.firmware?.currentVersion;
  if (version) {
    return gt(version, "1.5.99", { includePrerelease: true } as RangeOptions);
  }
  return false;
};

const hasMotorAlert = (
  alerts: MotorSection_site_alerts,
  actionMotor: MotorSection_site_motors | null
) => {
  const hasAlert = !!(
    actionMotor &&
    alerts.edges.find((edge) => {
      const cause = edge.node?.cause as
        | MotorSection_site_alerts_edges_node_cause_AlertCauseMotorErrors
        | MotorSection_site_alerts_edges_node_cause_AlertCauseMotorConnectivity
        | MotorSection_site_alerts_edges_node_cause_AlertCauseMotorSpeed
        | MotorSection_site_alerts_edges_node_cause_AlertCauseMotorLowTorque
        | MotorSection_site_alerts_edges_node_cause_AlertCauseMotorSeizure;
      return cause?.motor?.id === actionMotor.id;
    })
  );
  return hasAlert;
};

function MotorSectionComponent(props: MotorSectionProps) {
  type motor = MotorSection_site_motors | null;

  const isAdmin = props.site.viewerIsAdmin;
  const motors = props.site.motors;

  const menu = useMenuState();
  const [actionMotor, setActionMotor] = useState<motor>(null);
  const handleMenuOpen = (motor: motor) => (event: React.MouseEvent) => {
    setActionMotor(motor);
    menu.handleOpen(event);
  };

  const [dissociatingMotor, setDissociatingMotor] = useState<motor>(null);
  const handleDissociateOpen = () => {
    menu.handleClose();
    setDissociatingMotor(actionMotor);
  };

  const canUpgradeFirmware =
    isAdmin &&
    actionMotor?.firmware.status?.__typename === "FirmwareOutdated" &&
    actionMotor?.firmware.status.isUpgradable;

  const [upgradingMotor, setUpgradingMotor] = useState<motor>(null);
  const handleUpgradeOpen = () => {
    menu.handleClose();
    setUpgradingMotor(actionMotor);
  };

  const canUploadFlow = isAdmin && (actionMotor?.supervisor?.isShadowAware ?? false);

  const [uploadingFlowMotor, setUploadingFlowMotor] = useState<motor>(null);
  const handleUploadFlowOpen = () => {
    menu.handleClose();
    setUploadingFlowMotor(actionMotor);
  };

  const [clearingFaultsMotor, setClearingFaultsMotor] = useState<motor>(null);
  const [isClearFaultsAllowed, setClearFaultsAllowed] = useState<boolean>(true);
  const handleClearFaultsOpen = () => {
    menu.handleClose();
    setClearingFaultsMotor(actionMotor);
  };

  const [rebootingMotor, setRebootingMotor] = useState<motor>(null);
  const [isRebootMotorAllowed, setRebootMotorAllowed] = useState<boolean>(true);
  const handleRebootOpen = () => {
    menu.handleClose();
    setRebootingMotor(actionMotor);
  };

  const MOTOR_COMMAND_TIMEOUT = 60000; // we should at minimum gray out the option for a set period of time (1 minute)

  const columnHeaders = [
    "Motor",
    "Model",
    "Supervisor",
    "Last Communication",
    "Firmware Version",
    "Logic Flow Version",
  ];
  columnHeaders.push("");

  const motorCommunicationRefetchQuery = gql`
    query MotorCommunicationRefetchQuery($siteId: ID!) {
      node(id: $siteId) {
        ... on Site {
          id
          motors {
            id
            lastCommunication
          }
        }
      }
    }
  `;

  // Check every 10s for a last communication update
  useQuery(motorCommunicationRefetchQuery, {
    variables: { siteId: props.site.id },
    pollInterval: 10000,
    notifyOnNetworkStatusChange: true,
  });

  return (
    <Section title="Motors" icon={<MotorIcon />}>
      <Card>
        <SimpleTable headers={columnHeaders} emptyMessage="No motors.">
          {motors.map((motor) => (
            <MotorRow
              key={motor.id}
              motor={motor}
              onAction={handleMenuOpen(motor)}
              isAdmin={isAdmin}
              siteId={props.site.id}
            />
          ))}
        </SimpleTable>

        <ActionMenu {...menu.props} disableAutoFocusItem={true} onClick={menu.handleClose}>
          <ActionMenuItem onClick={handleDissociateOpen} disabled={!isAdmin}>
            <Typography color="secondary">Remove from site</Typography>
          </ActionMenuItem>
          <ActionMenuItem onClick={handleUpgradeOpen} disabled={!canUpgradeFirmware}>
            <Typography>Update to latest firmware</Typography>
          </ActionMenuItem>
          <ActionMenuItem onClick={handleUploadFlowOpen} disabled={!canUploadFlow}>
            <Typography>Load control logic</Typography>
          </ActionMenuItem>
          <ActionMenuItem
            onClick={handleClearFaultsOpen}
            disabled={
              !isAdmin ||
              !isClearFaultsAllowed ||
              !supervisorHasMotorCommands(actionMotor) ||
              !hasMotorAlert(props.site.alerts, actionMotor)
            }
            title="Clear Alerts"
          >
            <Typography>Clear Motor Faults</Typography>
          </ActionMenuItem>
          <ActionMenuItem
            onClick={handleRebootOpen}
            disabled={!isAdmin || !isRebootMotorAllowed || !supervisorHasMotorCommands(actionMotor)}
          >
            <Typography>Reboot Motor</Typography>
          </ActionMenuItem>
        </ActionMenu>

        {upgradingMotor && (
          <UpgradeFirmwareDialog
            open={true}
            onClose={() => setUpgradingMotor(null)}
            device={upgradingMotor}
          />
        )}

        {uploadingFlowMotor && (
          <UploadFlowDialog
            open={true}
            onClose={() => setUploadingFlowMotor(null)}
            device={uploadingFlowMotor}
          />
        )}

        {clearingFaultsMotor && (
          <ClearMotorFaultsDialog
            open={true}
            onClose={(success) => {
              setClearingFaultsMotor(null);
              if (success) {
                setClearFaultsAllowed(false);
                setTimeout(() => setClearFaultsAllowed(true), MOTOR_COMMAND_TIMEOUT);
              }
            }}
            device={clearingFaultsMotor}
          />
        )}

        {rebootingMotor && (
          <RebootMotorDialog
            open={true}
            onClose={(success) => {
              setRebootingMotor(null);
              if (success) {
                setRebootMotorAllowed(false);
                setTimeout(() => setRebootMotorAllowed(true), MOTOR_COMMAND_TIMEOUT);
              }
            }}
            device={rebootingMotor}
          />
        )}

        {dissociatingMotor && (
          <DissociateMotorDialog
            open={true}
            onClose={() => setDissociatingMotor(null)}
            motor={dissociatingMotor}
          />
        )}
      </Card>
    </Section>
  );
}

type MotorRowProps = {
  motor: MotorSection_site_motors;
  onAction: (event: MouseEvent, motorId: any) => void;
  isAdmin: boolean;
  siteId: string;
};

function MotorRow(props: MotorRowProps) {
  const { motor, isAdmin, onAction, siteId } = props;

  return (
    <TableRow style={{ height: 48 }}>
      <LinkCell
        id={motor.driveSerialNumber}
        name={motor.name}
        to={`/sites/${unmarshalNumberId(siteId)}/motor/${unmarshalStringId(motor.id)}`}
      />
      <UnkCell>{motor.driveModel}</UnkCell>
      <NameCell id={motor.supervisor?.serialNumber || ""} name={motor.supervisor?.name || null} />
      <LogicCell ts={motor.lastCommunication ? moment(motor.lastCommunication) : null} />
      <DeviceFirmwareCell device={motor} isAdmin={isAdmin} />
      <UnkCell>{motor.logicFlow.currentVersion}</UnkCell>
      <TableCell align="right">
        <ActionMenuButton onClick={(event) => onAction(event, motor)} />
      </TableCell>
    </TableRow>
  );
}

export const MotorSection = createFragmentContainer(MotorSectionComponent, {
  site: gql`
    fragment MotorSection_site on Site {
      id
      viewerIsAdmin
      motors {
        id
        name
        driveSerialNumber
        driveModel
        lastCommunication
        firmware {
          status {
            ... on FirmwareOutdated {
              isUpgradable
            }
          }
        }
        supervisor {
          name
          serialNumber
          isShadowAware
          firmware {
            currentVersion
          }
        }
        logicFlow {
          currentVersion
        }
        ...UpgradeFirmwareDialog_device
        ...UploadFlowDialog_device
        ...DeviceFirmwareCell_device
      }
      alerts(includeCount: true) {
        edges {
          node {
            id
            ... on Alert {
              cause {
                __typename
                ... on AlertCauseMotorErrors {
                  motor {
                    id
                  }
                }
                ... on AlertCauseMotorConnectivity {
                  motor {
                    id
                  }
                }
                ... on AlertCauseMotorSpeed {
                  motor {
                    id
                  }
                }
                ... on AlertCauseMotorLowTorque {
                  motor {
                    id
                  }
                }
                ... on AlertCauseMotorSeizure {
                  motor {
                    id
                  }
                }
              }
            }
          }
        }
        totalCount
      }
    }
    ${DeviceFirmwareCell.fragments.device}
    ${UploadFlowDialog.fragments.device}
    ${UpgradeFirmwareDialog.fragments.device}
  `,
});
