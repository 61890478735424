import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Select,
  MenuItem,
  Box,
  SelectChangeEvent,
  FormControl,
  Tooltip,
} from "@mui/material";
import { Colors } from "sigil";
import { gql } from "@apollo/client";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { NumericInput, NumericInputProps } from "modules/common/components/NumericInput";
import moment from "moment";
import { labelFontStyle } from "global-styles";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { isNone } from "modules/common/utils/guards";
import { MotorInputsTableRow_baselineMotor } from "generated-gql-types/MotorInputsTableRow_baselineMotor";
import { MotorInputsTableRow_motor } from "generated-gql-types/MotorInputsTableRow_motor";
import { SelectOption, VOLTAGES, POWER_OPTIONS, EFFICIENCIES, APPLICATIONS } from "./constants";
import { DatePicker, DateValidationError, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { PickerChangeHandlerContext } from "@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue";
import { makeLocalAppearUTC, localToUTC } from "modules/common/utils/time";
import { useSnackbar } from "notistack";
import { presentError } from "modules/site-manager/utils/errors";

const warningIcon = <WarningAmberIcon fontSize="small" />;

interface MotorInputsRowProps {
  motor: MotorInputsTableRow_motor;
  baselineMotor: MotorInputsTableRow_baselineMotor;
  onUpdateBaselineMotor: (baselineMotor: any) => void | undefined;
  disabled: boolean;
}

interface ColumnDef {
  columnWidth: number;
  headerMaxWidth: string;
}

export const MIN_RPM = 1;
export const MAX_RPM = 3600;
export const MAX_TONNAGE = 1000;

const COLUMN_DEFS: ColumnDef[] = [
  {
    // Hardware Name
    columnWidth: 1.5,
    headerMaxWidth: "100%",
  },
  {
    // Voltage
    columnWidth: 1,
    headerMaxWidth: "100%",
  },
  {
    // Max speed
    columnWidth: 1.8,
    headerMaxWidth: "150px",
  },
  {
    // Baseline motor hp
    columnWidth: 1.1,
    headerMaxWidth: "150px",
  },
  {
    // Baseline motor efficiency %
    columnWidth: 1.8,
    headerMaxWidth: "140px",
  },
  {
    // Application Type
    columnWidth: 1.6,
    headerMaxWidth: "120px",
  },
  {
    // Equipment tonnage
    columnWidth: 1.1,
    headerMaxWidth: "100%",
  },
  {
    // Install date
    columnWidth: 1.65,
    headerMaxWidth: "100%",
  },
  {
    // error icon
    columnWidth: 0.4,
    headerMaxWidth: "100%",
  },
];

interface SelectColumnProps {
  columnDef: ColumnDef;
  disabled: boolean;
  label: string;
  options: SelectOption[];
  value?: number | string | null;
  onChange: (event: SelectChangeEvent<any>, child: React.ReactNode) => void;
}

const SelectColumn = ({
  columnDef,
  disabled,
  label,
  options,
  value,
  onChange,
}: SelectColumnProps) => (
  <Grid item xs={columnDef.columnWidth} pl={2}>
    <FormControl variant="standard" fullWidth>
      <Select
        aria-label={label}
        key={label}
        value={value || ""}
        onChange={onChange}
        size="small"
        sx={{ border: 0, width: "100%", marginTop: "3px", ...labelFontStyle }}
        autoFocus={false}
        disabled={disabled}
        label={label}
        disableUnderline
        MenuProps={{
          PaperProps: {
            sx: {
              "& .MuiMenuItem-root": {
                ...labelFontStyle,
              },
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            value={option.value || 0}
            selected={option.value === (value || 0)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Grid>
);

interface NumericEditProps extends NumericInputProps {
  columnDef: ColumnDef;
}

export const NumericEdit = ({
  autoFocus = false,
  columnDef,
  disabled = false,
  id,
  label,
  maxValue,
  minValue,
  units = "",
  value,
  decimalScale,
  onChange,
}: NumericEditProps) => (
  <Grid item xs={columnDef.columnWidth} pl={2}>
    <FormControl variant="standard">
      <NumericInput
        autoFocus={autoFocus}
        onChange={onChange}
        disabled={disabled}
        id={id}
        isError={false}
        label={label}
        maxValue={maxValue}
        minValue={minValue}
        units={units}
        value={value}
        extraStyle={{
          "& fieldset": {
            borderColor: "transparent !important",
          },
          "& input": {
            paddingLeft: 0,
          },
        }}
        decimalScale={decimalScale}
      />
    </FormControl>
  </Grid>
);

export function MotorInputsRowComponent({
  motor,
  baselineMotor,
  onUpdateBaselineMotor,
  disabled,
}: MotorInputsRowProps) {
  const [installDate, setInstallDate] = useState(
    moment(baselineMotor.replacedAt || motor.createdAt)
  );
  const [error, setError] = useState<DateValidationError | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleSelectEvent = (updatedMotor: any) => {
    return onUpdateBaselineMotor({ ...baselineMotor, ...updatedMotor });
  };

  // Effect 1: Handle date validation errors
  useEffect(() => {
    if (error) {
      enqueueSnackbar(presentError("Date validation error", error), {
        variant: "error",
      });
    }
  }, [error, enqueueSnackbar]);

  const shouldShowWarning = () => {
    const anyAreDefault =
      !baselineMotor.maxSpeed ||
      !baselineMotor.applicationType ||
      !baselineMotor.efficiencyType ||
      !baselineMotor.motorPower ||
      !baselineMotor.voltage ||
      isNone<number>(baselineMotor.tonnage);
    const anyAreSet =
      baselineMotor.maxSpeed ||
      baselineMotor.applicationType ||
      baselineMotor.efficiencyType ||
      baselineMotor.motorPower ||
      baselineMotor.voltage ||
      !isNone<number>(baselineMotor.tonnage);
    return anyAreDefault && anyAreSet;
  };

  return (
    <Grid
      item
      container
      xs={12}
      sx={{ borderBottom: `1px solid ${Colors.salt}`, lineHeight: 1 }}
      alignItems="center"
    >
      {/* Hardware Name */}
      <Grid
        item
        container
        direction="column"
        justifyContent="stretch"
        xs={COLUMN_DEFS[0].columnWidth}
        sx={{
          overflow: "hidden",
          paddingLeft: 3,
        }}
      >
        <Typography
          sx={{
            textOverflow: "ellipsis",
            color: Colors.ttTableTitle,
            ...labelFontStyle,
          }}
        >
          {motor.displayName || motor.name}
        </Typography>
        <Typography
          sx={{
            textOverflow: "ellipsis",
            color: Colors.ttTableTitle,
            fontFamily: "Barlow",
            fontSize: "13px",
          }}
        >
          {motor.driveSerialNumber || motor.motorSerialNumber}
        </Typography>
      </Grid>

      {/* Voltage */}
      <SelectColumn
        columnDef={COLUMN_DEFS[1]}
        disabled={disabled}
        label="Select Voltage"
        options={VOLTAGES}
        value={baselineMotor.voltage}
        onChange={(event) => handleSelectEvent({ ...baselineMotor, voltage: +event.target.value })}
      />

      {/* Max speed */}
      <NumericEdit
        columnDef={COLUMN_DEFS[2]}
        disabled={disabled}
        id={"maxSpeed"}
        label={"Max Speed"}
        minValue={MIN_RPM}
        maxValue={MAX_RPM}
        units={"RPM"}
        value={baselineMotor.maxSpeed}
        decimalScale={0} // integers only
        onChange={(event) => handleSelectEvent({ ...baselineMotor, maxSpeed: +event.target.value })}
      />

      {/* Baseline motor HP */}
      <SelectColumn
        columnDef={COLUMN_DEFS[3]}
        disabled={disabled}
        label="Select Baseline motor power"
        options={POWER_OPTIONS}
        value={baselineMotor.motorPower}
        onChange={(event) =>
          handleSelectEvent({ ...baselineMotor, motorPower: +event.target.value })
        }
      />

      {/* Baseline motor efficiency % */}
      <SelectColumn
        columnDef={COLUMN_DEFS[4]}
        disabled={disabled}
        label="Select Baseline motor efficiency % (curve)"
        options={EFFICIENCIES}
        value={baselineMotor.efficiencyType}
        onChange={(event) =>
          handleSelectEvent({ ...baselineMotor, efficiencyType: event.target.value })
        }
      />

      {/* Application Type */}
      <SelectColumn
        columnDef={COLUMN_DEFS[5]}
        disabled={disabled}
        label="Select Application (what the motor is turning)"
        options={APPLICATIONS}
        value={baselineMotor.applicationType}
        onChange={(event) =>
          handleSelectEvent({ ...baselineMotor, applicationType: event.target.value })
        }
      />

      {/* Equipment Tonnage */}
      <NumericEdit
        columnDef={COLUMN_DEFS[6]}
        disabled={disabled}
        id={"tonnage"}
        label="Equipment Tonnage (RTU size)"
        maxValue={MAX_TONNAGE}
        units={""}
        value={baselineMotor.tonnage}
        onChange={(event) => handleSelectEvent({ ...baselineMotor, tonnage: +event.target.value })}
      />

      <Grid
        item
        justifyContent="flex-start"
        xs={COLUMN_DEFS[COLUMN_DEFS.length - 2].columnWidth}
        pl={1}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={installDate ? makeLocalAppearUTC(installDate) : null}
            onChange={(newValue, context: PickerChangeHandlerContext<DateValidationError>) => {
              if (context.validationError) {
                setError(context.validationError);
                return;
              }
              setError(null);
              const newLocalDate = newValue?.startOf("day");
              const newUtcDate = newLocalDate ? localToUTC(newLocalDate) : undefined;
              if (newUtcDate) {
                setInstallDate(newUtcDate);
                handleSelectEvent({ ...baselineMotor, replacedAt: newUtcDate });
              }
            }}
            sx={{ "& input, & label": { fontFamily: "Barlow" } }}
            slotProps={{
              textField: {
                size: "small",
                variant: "standard",
                InputProps: {
                  disableUnderline: true,
                },
              },
            }}
            disabled={disabled}
            onError={(newError: DateValidationError) => setError(newError)}
          />
        </LocalizationProvider>
      </Grid>
      <Grid
        item
        xs={COLUMN_DEFS[COLUMN_DEFS.length - 1].columnWidth}
        sx={{ textAlign: "right" }}
        pt={1}
      >
        {shouldShowWarning() && (
          <Tooltip title="All fields are required">
            <Typography style={{ color: "red" }} variant="caption">
              {warningIcon}
            </Typography>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
}

export const MotorInputsTableRow = createFragmentContainer(MotorInputsRowComponent, {
  motor: gql`
    fragment MotorInputsTableRow_motor on Motor {
      id
      name
      displayName
      createdAt
      driveSerialNumber
      motorSerialNumber
    }
  `,
  baselineMotor: gql`
    fragment MotorInputsTableRow_baselineMotor on BaselineMotor {
      id
      motor {
        id
      }
      voltage
      maxSpeed
      motorPower
      efficiencyType
      applicationType
      tonnage
      replacedAt
    }
  `,
});

const Header = ({ index, title }: { index: number; title: string }) => (
  <Grid item justifyContent="stretch" xs={COLUMN_DEFS[index].columnWidth}>
    <Box sx={{ maxWidth: COLUMN_DEFS[index].headerMaxWidth, paddingLeft: 2 }}>
      <Typography
        style={{
          textTransform: "uppercase",
          fontWeight: 700,
          color: Colors.ttTableTitle,
        }}
        variant="caption"
        color="textSecondary"
      >
        {title}
      </Typography>
    </Box>
  </Grid>
);

export const MotorInputsHeaderRow = () => (
  <Grid
    item
    container
    xs={12}
    justifyItems={"center"}
    alignItems="center"
    sx={{ borderBottom: `1px solid ${Colors.salt}`, lineHeight: 1, padding: 1 }}
  >
    <Header title="Name" index={0} />
    <Header title="Voltage" index={1} />
    <Header title="Baseline Motor Max Speed" index={2} />
    <Header title="Baseline Motor HP" index={3} />
    <Header title="Baseline Motor Efficiency" index={4} />
    <Header title="Application" index={5} />
    <Header title="Equipment Tonnage" index={6} />
    <Header title="Install Date" index={7} />
    <Header title="" index={8} /> {/* Error icon */}
  </Grid>
);
