import { ApplicationType, EfficiencyType } from "generated-gql-types/globalTypes";

export interface SelectOption {
  value: ApplicationType | EfficiencyType | number | string | undefined;
  label: string;
}

export const VOLTAGES: SelectOption[] = [
  { value: 208, label: "208V" },
  { value: 230, label: "230V" },
  { value: 460, label: "460V" },
  { value: 575, label: "575V" },
];

export const POWER_OPTIONS: SelectOption[] = [
  { value: 1, label: "1 HP" },
  { value: 1.5, label: "1.5 HP" },
  { value: 2, label: "2 HP" },
  { value: 3, label: "3 HP" },
  { value: 5, label: "5 HP" },
  { value: 7.5, label: "7.5 HP" },
  { value: 10, label: "10 HP" },
  { value: 15, label: "15 HP" },
  { value: 20, label: "20 HP" },
  { value: 25, label: "25 HP" },
];

export const EFFICIENCIES: SelectOption[] = [
  { value: EfficiencyType.PREMIUM, label: "Premium" },
  { value: EfficiencyType.STANDARD, label: "Standard" },
];

export const APPLICATIONS: SelectOption[] = [
  { value: ApplicationType.SUPPLY_FAN, label: "Supply Fan" },
  { value: ApplicationType.RETURN_FAN, label: "Return Fan" },
  { value: ApplicationType.EXHAUST_FAN, label: "Exhaust Fan" },
];
