import * as React from "react";
import { ButtonBase } from "@material-ui/core";
import { darken, fade, lighten } from "@material-ui/core/styles";
import {
  OverridableComponent,
  OverridableTypeMap,
  OverrideProps,
} from "@material-ui/core/OverridableComponent";
import { CreateCSSProperties, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { capitalize } from "lodash";
import {
  descriptionExtraSmallStyles,
  descriptionMediumStyles,
  descriptionSmallStyles,
} from "../typography";
import { Colors, contrastText } from "../../utils/color";
import { Elevation } from "../../utils/elevation";

const useStyles = makeStyles({
  root: {
    boxSizing: "border-box",
    border: "1px solid transparent",
    borderRadius: "2px",
    transition: "background-color 0.2s, box-shadow 0.2s",
    "&:focus": {
      outline: "none",
    },
  },

  // Styles applied to root element based on `variant` prop
  variantSolid: {
    "&$colorPrimary": createSolidStyles(Colors.wave),
    "&$colorSuccess": createSolidStyles(Colors.grass),
    "&$colorDangeresque": createSolidStyles(Colors.rust),
    "&$colorPlain": createSolidStyles(Colors.pewter),
    "&$colorClear": {
      ...createSolidStyles(Colors.salt),
      "&:focus": {
        border: `1px solid ${darken(Colors.salt, 0.2)}`,
        boxShadow: `${Elevation[2]}, inset 0 0 0 0.5px ${darken(Colors.salt, 0.2)}`,
      },
    },
  },
  variantKnockout: {
    "&$colorPrimary": createKnockoutStyles(Colors.wave),
    "&$colorSuccess": createKnockoutStyles(Colors.grass),
    "&$colorDangeresque": createKnockoutStyles(Colors.rust),
    "&$colorPlain": createKnockoutStyles(Colors.pewter),
    "&$colorClear": {
      ...createKnockoutStyles(Colors.pewter),
      borderColor: Colors.silver,
      "&$disabled": {
        color: Colors.silver,
        borderColor: Colors.silver,
      },
      "&:focus": {
        boxShadow: `${Elevation[2]}, inset 0 0 0 0.5px ${Colors.silver}`,
      },
    },
  },
  variantText: {
    "&$colorPrimary": createTextStyles(Colors.wave),
    "&$colorSuccess": createTextStyles(Colors.grass),
    "&$colorDangeresque": createTextStyles(Colors.rust),
    "&$colorPlain": createTextStyles(Colors.pewter),
    "&$colorClear": {
      ...createTextStyles(Colors.pewter),
      "&$disabled": {
        color: Colors.silver,
      },
      "&:focus": {
        boxShadow: `inset 0 0 0 0.5px ${Colors.silver}`,
      },
    },
  },

  // Styles applied to root element based on `size` prop
  sizeSmall: {
    ...descriptionExtraSmallStyles,
    height: "24px",
    padding: "0px 16px",
  },
  sizeMedium: {
    ...descriptionSmallStyles,
    height: "40px",
    padding: "0 24px",
  },
  sizeLarge: {
    ...descriptionMediumStyles,
    height: "56px",
    padding: "0 40px",
  },

  // Pseudo-classes applied to root element based on `color` prop
  colorPrimary: {},
  colorSuccess: {},
  colorDangeresque: {},
  colorPlain: {},
  colorClear: {},

  // Styles applied to the root element if `fullWidth={true}`
  fullWidth: {
    width: "100%",
  },

  // Pseudo-class applied to root element if `disabled={true}`
  disabled: {},
});

// Styles for solid button variant, parameterized by color
function createSolidStyles(color: string): CreateCSSProperties {
  return {
    color: contrastText(color, Colors.pewter, Colors.white),
    backgroundColor: color,
    "&:hover": {
      boxShadow: Elevation[2],
      backgroundColor: darken(color, 0.08),
    },
    "&:focus": {
      border: `1px solid ${darken(color, 0.4)}`,
      boxShadow: `${Elevation[2]}, inset 0 0 0 0.5px ${darken(color, 0.4)}`,
    },
    "&:active": {
      backgroundColor: darken(color, 0.16),
    },
    "&$disabled": {
      backgroundColor: lighten(color, 0.6),
    },
  };
}

// Styles for knockout button variant, parameterized by color
function createKnockoutStyles(color: string): CreateCSSProperties {
  return {
    color: color,
    borderColor: color,
    "&:hover": {
      boxShadow: Elevation[2],
      backgroundColor: fade(color, 0.08),
    },
    "&:focus": {
      boxShadow: `${Elevation[2]}, inset 0 0 0 0.5px ${color}`,
    },
    "&:active": {
      backgroundColor: fade(color, 0.16),
    },
    "&$disabled": {
      color: lighten(color, 0.6),
      borderColor: lighten(color, 0.6),
    },
  };
}

// Styles for knockout button variant, parameterized by color
function createTextStyles(color: string): CreateCSSProperties {
  return {
    color: color,
    "&:hover": {
      backgroundColor: fade(color, 0.08),
    },
    "&:focus": {
      boxShadow: `inset 0 0 0 0.5px ${color}`,
    },
    "&:active": {
      backgroundColor: fade(color, 0.16),
    },
    "&$disabled": {
      color: lighten(color, 0.6),
    },
  };
}

type ButtonClassKey = keyof ReturnType<typeof useStyles>;

type ButtonTypeMap<P = {}, D extends React.ElementType = "button"> = {
  props: P & {
    children?: React.ReactNode;
    variant?: "solid" | "knockout" | "text";
    color?: "primary" | "success" | "dangeresque" | "plain" | "clear";
    size?: "small" | "medium" | "large";
    fullWidth?: boolean;
    disabled?: boolean;
  };
  defaultComponent: D;
  classKey: ButtonClassKey;
};

export type ButtonProps<
  D extends React.ElementType = ButtonTypeMap["defaultComponent"],
  P = {}
> = OverrideProps<ButtonTypeMap<P, D>, D>;

// Reimplement ExtendButtonBase, without ripple props.
type ExtendButtonBaseMinimal<M extends OverridableTypeMap> = ((
  props: { href: string } & OverrideProps<M, "a">
) => JSX.Element) &
  OverridableComponent<M>;

type ButtonComponent = ExtendButtonBaseMinimal<ButtonTypeMap>;

export const Button: ButtonComponent = function Button({
  variant = "solid",
  color = "clear",
  size = "medium",
  fullWidth = false,
  ...other
}) {
  const classes = useStyles();
  return (
    <ButtonBase
      {...other}
      className={clsx(
        classes.root,
        classes[`variant${capitalize(variant)}`],
        classes[`size${capitalize(size)}`],
        classes[`color${capitalize(color)}`],
        {
          [classes.fullWidth]: fullWidth,
          [classes.disabled]: other.disabled,
        },
        other.className
      )}
      disableRipple
      disableTouchRipple
    />
  );
};
