import * as React from "react";
import { SlantedRect } from "modules/common/components/SlantedRect";
import { AlertStatus as AlertStatusEnum } from "generated-gql-types/globalTypes";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { Box, Stack } from "@mui/material";

type AlertStatusProps = {
  status: AlertStatusEnum;
  label: string;
};

const STATUS_PROPS2 = {};
STATUS_PROPS2[AlertStatusEnum.TRIGGERED] = {
  color: "active",
  variant: "solid",
};
STATUS_PROPS2[AlertStatusEnum.ACKED] = {
  color: "acked",
  variant: "solid",
};

STATUS_PROPS2[AlertStatusEnum.CLOSED] = { color: "light", variant: "outline" };
export function AlertStatusBanner({ status, label }: AlertStatusProps) {
  return (
    <Box style={{ maxWidth: "150px" }}>
      <SlantedRect {...STATUS_PROPS2[status]}>
        <Stack direction="row" alignItems="top" justifyContent="center">
          {status === AlertStatusEnum.TRIGGERED && (
            <NotificationsActiveIcon fontSize="small" className={STATUS_PROPS2[status].icon} />
          )}
          {status === AlertStatusEnum.ACKED && (
            <NotificationsOffIcon fontSize="small" className={STATUS_PROPS2[status].icon} />
          )}
          {label}
        </Stack>
      </SlantedRect>
    </Box>
  );
}
