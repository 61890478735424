import * as React from "react";
import { BreadCrumb, BreadcrumbLink, BreadCrumbLeaf } from "modules/common/components/BreadCrumb";
import { Box } from "@mui/material";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { gql } from "@apollo/client";
import { SiteBreadcrumb_site } from "generated-gql-types/SiteBreadcrumb_site";

type SiteBreadCrumbProps = {
  site: SiteBreadcrumb_site;
};

const SiteBreadCrumbComponent = ({ site }: SiteBreadCrumbProps) => {
  // breadcrumbs: org / site

  const path = [];

  path.push();
  if (site) {
    path.push(
      <BreadcrumbLink to={`/organizations/${site?.organization.id}`}>
        {site?.organization.name}
      </BreadcrumbLink>
    );

    path.push(<BreadCrumbLeaf>{site.name}</BreadCrumbLeaf>);
  } else {
    path.push("Loading...");
  }
  return (
    <Box color="text.hint">
      <BreadCrumb items={path} />
    </Box>
  );
};

export const SiteBreadCrumb = createFragmentContainer(SiteBreadCrumbComponent, {
  site: gql`
    fragment SiteBreadcrumb_site on Site {
      id
      name
      organization {
        id
        name
      }
    }
  `,
});
