import * as React from "react";
import { observer } from "mobx-react";
import memoizeOne from "memoize-one";

import { FormControlLabel, MenuItem, Switch, TextField } from "@material-ui/core";
import { TextFieldProps as muiTextFieldProps } from "@material-ui/core/TextField";

import { isNone } from "modules/common/utils/guards";
import { BMSBehaviorKind } from "modules/site-manager/constants";
import { LogicValueContainerPresenter } from "modules/site-manager/presenters";
import { LogicValueContainer } from "modules/site-manager/utils/logic-value-container";

interface LogicValueContainerControlProps {
  container: LogicValueContainer;
  onChange?: (value: string | boolean | undefined) => void;
  TextFieldProps?: muiTextFieldProps;
}

@observer
export class LogicValueContainerControl extends React.Component<LogicValueContainerControlProps> {
  presenter = memoizeOne(
    (container: LogicValueContainer) => new LogicValueContainerPresenter(container)
  );

  handleFloatChange = (e: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    const { onChange } = this.props;
    if (!onChange) {
      return;
    }

    const { value } = e.target;
    onChange(value);
  };

  handleBooleanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    if (!onChange) {
      return;
    }

    const { target } = e;
    onChange(target.checked);
  };

  get floatContent() {
    const { container, TextFieldProps } = this.props;
    const presenter = this.presenter(container);
    const value = isNone(container.floatValue) ? "" : container.floatValue;

    return (
      <TextField
        {...TextFieldProps}
        type="number"
        value={value}
        onChange={this.handleFloatChange}
        label={presenter.shortName}
      />
    );
  }

  get bitmaskContent() {
    const { container, TextFieldProps } = this.props;
    const presenter = this.presenter(container);
    const value = isNone(container.floatValue) ? "" : container.floatValue;

    return (
      <TextField
        {...TextFieldProps}
        select={true}
        value={value}
        onChange={this.handleFloatChange}
        label={presenter.shortName}
      >
        {presenter.bitmaskOptions.map((opt) => (
          <MenuItem key={opt.mask} value={opt.mask}>
            {opt.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }

  get booleanContent() {
    const { container } = this.props;
    const value = container.booleanValue;

    return (
      <div className="text-center">
        <FormControlLabel
          control={<Switch checked={value} onChange={this.handleBooleanChange} />}
          label={value ? "True / On" : "False / Off"}
        />
      </div>
    );
  }

  render() {
    switch (this.props.container.behaviorKind) {
      case BMSBehaviorKind.Float:
        return this.floatContent;
      case BMSBehaviorKind.Bitmask:
        return this.bitmaskContent;
      case BMSBehaviorKind.Bool:
        return this.booleanContent;
      default:
        return null;
    }
  }
}
