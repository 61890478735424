import * as React from "react";
import classNames from "classnames";
import Slider, { Settings as SlickSettings } from "react-slick";
import { WithStyles } from "@material-ui/core";

import { HoverableCard } from "modules/common/components/HoverableCard";
import { CtrlVariableHeroStyles } from "./style";

type HeroSliderProps = {};

type Props = HeroSliderProps & WithStyles<typeof CtrlVariableHeroStyles> & SlickSettings;

const HeroSliderComponent: React.FunctionComponent<Props> = function ({
  children,
  classes,
  ...settings
}) {
  return (
    <Slider
      {...settings}
      dots={true}
      infinite={true}
      variableWidth={true}
      slidesToShow={1}
      slidesToScroll={1}
      autoplay={true}
      autoplaySpeed={5000}
      pauseOnDotsHover={true}
      pauseOnHover={true}
      className={classes.slider}
      dotsClass={classes.dots}
      prevArrow={<SliderArrow direction="left" />}
      nextArrow={<SliderArrow direction="right" />}
      // appendDots is unfortunately necessary, otherwise slick attaches
      // display: "block" as inline styling to the wrapping <ul>
      appendDots={(dots) => <ul>{dots}</ul>}
    >
      {children}
    </Slider>
  );
};

function SliderArrow({
  className,
  style,
  onClick,
  direction,
}: { direction: "left" | "right" } & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <HoverableCard className={className} style={{ ...style, display: undefined }} onClick={onClick}>
      <i
        className={classNames("fas", {
          "fa-chevron-left": direction === "left",
          "fa-chevron-right": direction === "right",
        })}
      />
    </HoverableCard>
  );
}

export const HeroSlider = HeroSliderComponent;
