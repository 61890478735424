import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { PrimaryCard } from "modules/common/components/PrimaryCard";
import { OrganizationMembersRoute_node_Organization } from "generated-gql-types/OrganizationMembersRoute";
import { OrganizationSharingRoute_node_Organization } from "generated-gql-types/OrganizationSharingRoute";
import { MembersCard } from "./MembersCard";
import { CardSkeleton } from "./CardSkeleton";
import { SiteSharingCard } from "./SiteSharingCard";
import { AlertSettingsCard } from "./AlertSettingsCard";
import { SitesSettingsCard } from "./SitesSettingsCard";
import { AlertSettingsRoute_node_Organization } from "generated-gql-types/AlertSettingsRoute";
import { useSnackbar } from "notistack";
import { presentError } from "modules/site-manager/utils/errors";
import { StyledCardHeader } from "./styled";

export function OrganizationMembersRoute(props: RouteComponentProps<{ orgId: string }>) {
  const { orgId } = props.match.params;
  const { data, loading, error } = useQuery(
    gql`
      query OrganizationMembersRoute($orgId: ID!) {
        node(id: $orgId) {
          id
          ...MembersCard_organization
        }
      }
      ${MembersCard.fragments.organization}
    `,
    {
      variables: { orgId },
    }
  );
  const { enqueueSnackbar } = useSnackbar();

  if (error) {
    enqueueSnackbar(presentError("Error loading organization members", error), {
      variant: "error",
    });
  }

  let card = null;
  if (loading) {
    card = <CardSkeleton subheader="Members" />;
  } else if (!data || !data.node) {
    card = <StyledCardHeader title="NOT FOUND" />;
  } else {
    card = <MembersCard organization={data.node as OrganizationMembersRoute_node_Organization} />;
  }

  return <PrimaryCard loading={loading}>{card}</PrimaryCard>;
}

export function OrganizationSharingRoute(props: RouteComponentProps<{ orgId: string }>) {
  const { match } = props;
  const { orgId } = match.params;
  const { data, loading } = useQuery(
    gql`
      query OrganizationSharingRoute($orgId: ID!) {
        node(id: $orgId) {
          id
          ...SiteSharingCard_organization
        }
      }
      ${SiteSharingCard.fragments.organization}
    `,
    {
      variables: { orgId },
    }
  );

  return (
    <PrimaryCard loading={loading}>
      <SiteSharingCard
        organization={(data?.node as OrganizationSharingRoute_node_Organization) ?? undefined}
      />
    </PrimaryCard>
  );
}

export function AlertSettingsRoute(props: RouteComponentProps<{ orgId: string }>) {
  const { match } = props;
  const { orgId } = match.params;
  const { data, loading } = useQuery(
    gql`
      query AlertSettingsRoute($orgId: ID!) {
        node(id: $orgId) {
          id
          ...AlertSettingsCard_organization
        }
      }
      ${AlertSettingsCard.fragments.organization}
    `,
    {
      variables: { orgId },
    }
  );

  return (
    <PrimaryCard loading={loading}>
      <AlertSettingsCard
        organization={(data?.node as AlertSettingsRoute_node_Organization) ?? undefined}
      />
    </PrimaryCard>
  );
}

export function SitesRoute(props: RouteComponentProps<{ orgId: string }>) {
  const { match } = props;
  const { orgId } = match.params;
  const orgQuery = useQuery(
    gql`
      query SitesRoute($orgId: ID!) {
        node(id: $orgId) {
          id
          ... on Organization {
            ...SitesSettingsCard_organization
          }
        }
      }
      ${SitesSettingsCard.fragments.organization}
    `,
    {
      variables: { orgId },
    }
  );

  const loading = orgQuery.loading;
  return (
    <PrimaryCard loading={loading}>
      {!loading && <SitesSettingsCard organization={orgQuery.data?.node} />}
    </PrimaryCard>
  );
}
