import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { Colors } from "sigil";

// Augment palette type with additional custom colors
declare module "@material-ui/core/styles/createPalette" {
  type SigilPalette = { [P in keyof typeof Colors]: PaletteColor };
  interface Palette extends SigilPalette {
    success: PaletteColor;
    danger: PaletteColor;
    secondaryVariant: PaletteColor;
  }
  type SigilPaletteOptions = { [P in keyof typeof Colors]?: PaletteOptions };
  interface PaletteOptions extends SigilPaletteOptions {
    success?: PaletteColorOptions;
    danger?: PaletteColorOptions;
    secondaryVariant?: PaletteColorOptions;
  }
}
const sigilPalette = Object.keys(Colors).reduce((memo, color) => {
  memo[color] = {
    main: Colors[color],
  };
  return memo;
}, {});
const basePalette: PaletteOptions = {
  primary: {
    main: Colors.ttBlue,
  },
  secondary: {
    main: Colors.ttBlueLight,
  },
  error: {
    main: Colors.sunflower,
  },
  success: {
    main: Colors.grass,
  },
  danger: {
    main: Colors.rust,
  },
  secondaryVariant: {
    main: Colors.pewter,
  },
  divider: Colors.salt,
  ...sigilPalette,
};

export const light: PaletteOptions = {
  type: "light",
  ...basePalette,

  background: {
    default: Colors.kaolin,
    paper: Colors.white,
  },

  text: {
    primary: Colors.onyx,
    secondary: Colors.slate,
    hint: Colors.pewter,
    disabled: Colors.ash,
  },

  action: {
    active: Colors.slate,
  },
};
