import * as React from "react";
import { Box, Paper, Fade, LinearProgress, Grid, Link } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  SimpleStatQuery,
  SimpleStatQuery_node_Supervisor,
  SimpleStatQuery_node_Supervisor_telemetry_nodes,
  SimpleStatQuery_node_Supervisor_telemetry_nodes_points,
} from "generated-gql-types/SimpleStatQuery";
import { useQuery, gql } from "@apollo/client";
import { useMemo, useState, useRef, useEffect } from "react";
import { max, min, bisector } from "d3-array";
import { scaleLinear } from "@vx/scale";
import { curveMonotoneX } from "@vx/curve";
import { AreaClosed, LinePath, Line, Bar } from "@vx/shape";
import { LinearGradient } from "@vx/gradient";
import { withParentSize } from "@vx/responsive";
import {
  DescriptionExtraSmall,
  DescriptionSmall,
  TitleSmall,
  Colors,
  Button,
  TitleExtraSmall,
  Select,
  Cross,
  EditSmall,
} from "sigil";
import {
  WithParentSizeProvidedProps,
  WithParentSizeProps,
} from "@vx/responsive/lib/enhancers/withParentSize";
import moment from "moment";
import { UnitSymbol } from "modules/site-manager/components/UnitSymbol";
import { BacnetUnit } from "modules/site-manager/constants";
import {
  SimpleStatFormQuery,
  SimpleStatFormQuery_node_Site,
} from "generated-gql-types/SimpleStatFormQuery";
import { useSnackbar } from "notistack";
import { SiteSummary_node_Site } from "generated-gql-types/SiteSummary";
import { useUpdateSiteLayoutMutation } from "modules/common/mutations/UpdateSiteLayoutMutation";
import {
  useLayouts,
  removeModule,
  addModule,
} from "modules/site-manager/routes/Site/SiteSummary/hooks/useLayouts";
import { ModuleFormDialog } from "./ModuleForm";

const query = gql`
  query SimpleStatQuery($supervisorId: ID!, $first: Int) {
    node(id: $supervisorId) {
      ... on Supervisor {
        id
        serialNumber
        displayName
        telemetry(first: $first, relative: "30m") {
          nodes {
            recordedAt
            points {
              variable {
                id
                displayName
                bacnetUnitID
                precision
              }
              analogValue
              binaryValue
              enumValue
              enumLabels
            }
          }
        }
      }
    }
  }
`;

export type SimpleStatProps = {
  // The displayName of the variable to show. We use this instead of ids, because ids can change
  // across flow versions
  variableName: string;
  supervisorId: string;
};

export type SimpleStatComponentProps = {
  id?: string;
  editing?: boolean;
  site?: SiteSummary_node_Site;
};

const useSimpleStatStyles = makeStyles(() =>
  createStyles({
    paper: {
      width: "100%",
      height: "100%",
    },
  })
);

export const SimpleStat = ({
  supervisorId,
  variableName,
  editing,
  site,
  id,
}: SimpleStatProps & SimpleStatComponentProps) => {
  const { data, loading } = useQuery<SimpleStatQuery>(query, {
    variables: { supervisorId, first: 12 * 12 }, // 12 hours by 12 checkins an hour
    pollInterval: 30 * 1000,
  });
  const styles = useSimpleStatStyles();
  const supervisor = data?.node as SimpleStatQuery_node_Supervisor;
  return (
    <Box className={styles.paper}>
      <Paper className={styles.paper}>
        <Box minHeight={5}>
          <Fade
            in={loading}
            style={{ transitionDelay: loading ? "200ms" : "0ms" }}
            unmountOnExit={true}
          >
            <LinearProgress />
          </Fade>
        </Box>
        {editing && id && (
          <Controls
            id={id}
            site={site}
            editing={editing}
            initial={{ supervisorId, variableName }}
          />
        )}
        {supervisor && <Graph supervisor={supervisor} name={variableName} debounceTime={10} />}
      </Paper>
    </Box>
  );
};

const useControlsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      right: "0",
      top: "0",
      paddingTop: theme.spacing(0.5),
      zIndex: 10000,
    },
  })
);

type ControlProps = {
  id: string;
  site?: SiteSummary_node_Site;
  editing: boolean;
  initial: SimpleStatProps;
};

const Controls = ({ id, site, editing, initial }: ControlProps) => {
  const styles = useControlsStyles();
  const [mutate] = useUpdateSiteLayoutMutation();
  const layouts = useLayouts(site, editing);
  const [open, setOpen] = useState(false);
  if (!layouts || !site) return null;
  const handleClose = () => setOpen(false);
  const handleSubmit = async (props: SimpleStatProps) => {
    if (!site) return;
    const layout = addModule(removeModule(layouts, id), "simplestat", props);
    await mutate({
      siteId: site.id,
      layout: layout,
    });
  };
  return (
    <Box className={styles.root}>
      <Link
        href="#"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <EditSmall />
      </Link>
      <ModuleFormDialog
        open={open}
        handleClose={handleClose}
        siteId={site.id}
        handleSubmit={handleSubmit}
        initial={initial}
      />
      <Link
        href="#"
        onClick={async (e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          const layout = removeModule(layouts, id);
          await mutate({
            siteId: site.id,
            layout: layout,
          });
        }}
      >
        <Cross size={12} offset={-1} />
      </Link>
    </Box>
  );
};

type GraphProps = {
  supervisor: SimpleStatQuery_node_Supervisor;
  name: string;
} & WithParentSizeProvidedProps &
  WithParentSizeProps;

type GraphValues = {
  analogValue?: number | null;
  binaryValue?: boolean | null;
};

type GraphPoint = {
  recordedAt: number;
  analogValue?: number | null;
  binaryValue?: boolean | null;
} & GraphValues;

const useGraphStyles = makeStyles((theme) =>
  createStyles({
    svg: {
      position: "absolute",
      zIndex: 0,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    text: {
      position: "absolute",
      zIndex: 1,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      padding: theme.spacing(1),
      pointerEvents: "none",
    },
    stat: {
      textAlign: "center",
      paddingTop: theme.spacing(1),
    },
  })
);

type GraphState = {
  point?: SimpleStatQuery_node_Supervisor_telemetry_nodes_points | null;
  x?: number;
  time?: string;
};

const pointText = (
  point?: SimpleStatQuery_node_Supervisor_telemetry_nodes_points | null
): string | number | boolean => {
  if (!point) return "— —";
  if (point.analogValue !== null) {
    return point.variable.precision !== null
      ? point.analogValue.toFixed(point.variable.precision)
      : Math.round(point.analogValue);
  } else if (point.binaryValue !== null) {
    return point.binaryValue ? "true" : "false";
  } else if (point.enumValue !== null && point.enumLabels !== null) {
    return point.enumLabels[point.enumValue] ?? "— —";
  }
  return "— —";
};

const getPoint = (
  points: (SimpleStatQuery_node_Supervisor_telemetry_nodes_points | null)[],
  name: string
) => points?.filter((n) => n?.variable.displayName === name)[0];

const makePoint = (t: SimpleStatQuery_node_Supervisor_telemetry_nodes, name: string) => {
  const point = getPoint(t.points, name) ?? null;
  return {
    recordedAt: Date.parse(t.recordedAt),
    analogValue: point?.analogValue === undefined ? point?.enumValue : point?.analogValue,
    binaryValue: point?.binaryValue,
  };
};
const x = (d: GraphPoint) => d.recordedAt;
const y = (d: GraphValues): number =>
  d.analogValue !== undefined && d.analogValue !== null ? d.analogValue : d.binaryValue ? 1 : 0;

const Graph = withParentSize<GraphProps>(
  ({ supervisor, name, parentWidth, parentHeight }: GraphProps) => {
    const {
      telemetry: { nodes: t },
    } = supervisor;
    const telemetry = useMemo(() => Array.from(t).reverse(), [t]);
    const padding = 0.05;
    const styles = useGraphStyles();
    const latestPoint = useMemo(
      () => getPoint(telemetry[0]?.points ?? [], name),
      [telemetry, name]
    );
    const [active, setActive] = useState<GraphState>({ point: latestPoint });
    // This is here so that we can change it in the admin form.
    useEffect(() => setActive({ point: latestPoint }), [latestPoint]);
    const points = useMemo(
      () => telemetry.map((d) => makePoint(d, name)).filter((t) => t),
      [telemetry, name]
    );
    const xScale = useMemo(
      () =>
        scaleLinear<number>({
          domain: [min(points, x) || 0, max(points, x) || 0],
          range: [0, parentWidth ?? 0],
        }),
      [points, parentWidth]
    );
    const yScale = useMemo(
      () =>
        scaleLinear<number>({
          domain: [(min(points, y) || 0) * (1 - padding), (max(points, y) || 0) * (1 + padding)],
          range: [parentHeight ?? 0, 0],
          nice: true,
        }),
      [points, parentHeight]
    );
    const mouseOver = (e: React.MouseEvent<SVGRectElement>) => {
      const pt = { x: e.nativeEvent.offsetX };
      const x0 = xScale.invert(pt.x);
      const needle = bisector<SimpleStatQuery_node_Supervisor_telemetry_nodes, number>((d) =>
        x(makePoint(d, name))
      ).left;
      const index = needle(telemetry, x0);
      const p = telemetry[index] ?? telemetry[0];
      if (p) {
        const point = getPoint(p.points, name);
        setActive({ point, x: pt.x, time: p.recordedAt });
      }
    };

    const mouseOut = () => setActive({ point: latestPoint });
    const lineRef = useRef<SVGPathElement>(null);
    return (
      <>
        <Box className={styles.text}>
          <Box>
            <DescriptionExtraSmall>
              {active.point?.variable.displayName ?? "?"}
            </DescriptionExtraSmall>
            <DescriptionExtraSmall color="ash">{supervisor.displayName}</DescriptionExtraSmall>
          </Box>
          <Grid container alignItems="center" justify="center" className={styles.text}>
            <Grid item className={styles.stat}>
              <TitleSmall>{pointText(active.point)}</TitleSmall>
              {active.point?.variable?.bacnetUnitID !== undefined && (
                <DescriptionSmall>
                  <UnitSymbol unit={active.point.variable.bacnetUnitID as BacnetUnit} />
                </DescriptionSmall>
              )}
              <Box minHeight={18}>
                {active.time !== undefined && (
                  <DescriptionSmall color="ash">{moment(active.time).fromNow()}</DescriptionSmall>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <svg width={parentWidth} height={parentHeight} className={styles.svg}>
          <LinearGradient
            id="gradient"
            from={Colors.wave}
            to={Colors.wave}
            fromOpacity={0.08}
            toOpacity={0.0}
          />
          <AreaClosed<GraphPoint>
            data={points}
            x={(d) => xScale(x(d))}
            y={(d) => yScale(y(d))}
            yScale={yScale}
            strokeWidth={1}
            stroke="url(#gradient)"
            fill="url(#gradient)"
            curve={curveMonotoneX}
          />
          <LinePath
            data={points}
            x={(d) => xScale(x(d))}
            y={(d) => yScale(y(d))}
            strokeWidth={1}
            stroke={Colors.waveLighter}
            curve={curveMonotoneX}
            innerRef={lineRef}
          />
          <Scrubber {...active} lineRef={lineRef} height={parentHeight} />
          <Bar
            x={0}
            y={0}
            fill="transparent"
            width={parentWidth}
            height={parentHeight}
            onMouseEnter={mouseOver}
            onMouseMove={mouseOver}
            onMouseLeave={mouseOut}
          />
        </svg>
      </>
    );
  }
);

type ScrubberProps = {
  lineRef: React.RefObject<SVGPathElement>;
  height?: number;
} & GraphState;

const Scrubber = ({ x, lineRef, height }: ScrubberProps) => {
  if (x === undefined) return null;
  if (!lineRef.current) return null;
  const path = lineRef.current;
  // Becuase our line is smoothed by curveMonotoneX we can't simply put the scrubber at the
  // projected coordinate. We need to find out what the y coordinate is after smoothing,
  // so we do a binary search. h/t to Soundarya for this technique.
  let start = 0;
  let end = path.getTotalLength();
  let point = path.getPointAtLength(end / 2);
  let iters = 0;
  while (Math.abs(x - point.x) > 1) {
    iters++;
    if (iters > 1000) break;
    const mid = (start + end) / 2;
    point = path.getPointAtLength(mid);
    if (x < point.x) {
      end = mid;
    } else {
      start = mid;
    }
  }

  return (
    <g>
      <Line
        from={{ x: point.x, y: height ?? 0 }}
        to={{ x: point.x, y: point.y }}
        pointerEvents="none"
        strokeWidth={1}
        stroke={Colors.waveLighter}
      />
      <circle fill={Colors.wave} r={2} cx={point.x} cy={point.y} pointerEvents="none" />
    </g>
  );
};

export type SimpleStatFormProps = {
  siteId: string;
  onClose: () => void;
  onSubmit: (props: SimpleStatProps) => Promise<void>;
  initial?: SimpleStatProps;
};

const formQuery = gql`
  query SimpleStatFormQuery($siteId: ID!) {
    node(id: $siteId) {
      ... on Site {
        supervisors {
          id
          displayName
          variables {
            id
            displayName
          }
        }
      }
    }
  }
`;

export const SimpleStatForm = ({ siteId, onClose, onSubmit, initial }: SimpleStatFormProps) => {
  const { data, error, loading } = useQuery<SimpleStatFormQuery>(formQuery, {
    variables: { siteId },
  });
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState<SimpleStatProps | undefined | null>(initial);
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    if (error || (!loading && !data?.node)) {
      enqueueSnackbar("There was an error fetching site variables.", { variant: "error" });
      onClose();
    }
  }, [error, data, loading, enqueueSnackbar, onClose]);
  if (loading || error || !data?.node) {
    return null;
  }
  const site = data.node as SimpleStatFormQuery_node_Site;
  const options = site.supervisors.flatMap((s) =>
    s.variables.map((v) => ({
      supervisorId: s.id,
      displayName: s.displayName,
      variableName: v.displayName,
    }))
  );
  const onClick = async () => {
    if (!selected) {
      enqueueSnackbar("Please choose a variable to display.");
      return;
    }
    setSubmitting(true);
    await onSubmit(selected);
    setSubmitting(false);
    onClose();
  };

  return (
    <Box p={1}>
      <Grid container item xs>
        <Grid container item direction="column" xs spacing={2}>
          <Grid item>
            <TitleExtraSmall>Module Preview</TitleExtraSmall>
          </Grid>
          <Grid container item xs justify="center" alignItems="center">
            <Grid item>
              <Box width={180} height={150} position="relative">
                {selected && (
                  <SimpleStat
                    supervisorId={selected.supervisorId}
                    variableName={selected.variableName}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item direction="column" xs spacing={2}>
          <Grid item xs>
            <TitleExtraSmall>Module Settings</TitleExtraSmall>
          </Grid>
          <Grid item xs>
            <Select
              options={options}
              disabled={submitting}
              getOptionLabel={(option) => `${option.displayName}: ${option.variableName}`}
              onChange={(_e, value, reason) => {
                if (reason === "select-option") setSelected(value);
              }}
              inputProps={{ label: "BACnet Object" }}
            />
          </Grid>
          <Grid item container xs spacing={2}>
            <Grid item>
              <Button color="primary" disabled={submitting} onClick={onClick}>
                {initial ? "Edit" : "Add"}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={onClose}>Cancel</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
