import * as React from "react";
import { useState } from "react";
import { gql } from "@apollo/client";
import { CardActions, CardContent } from "@material-ui/core";
import { Button, TextField, Typography } from "sigil";
import { useSnackbar } from "notistack";
import InputMask from "react-input-mask";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { AsyncButton } from "modules/common/components/AsyncButton";
import { AddSharePane_organization } from "generated-gql-types/AddSharePane_organization";
import { ChonkToggleButton, ChonkToggleButtonGroup } from "../ChonkToggle";
import { useAddSitesShareMutation } from "./mutations";

type AddSharePaneProps = {
  organization: AddSharePane_organization;
  onClose: () => void;
};

function AddSharePaneComponent(props: AddSharePaneProps) {
  const [guestToken, setGuestToken] = useState("");
  const [role, setRole] = useState("viewer");
  const valid = guestToken.length === 9;

  const { enqueueSnackbar } = useSnackbar();
  const addSitesShare = useAddSitesShareMutation();
  const handleSubmit = async () => {
    try {
      await addSitesShare({
        organizationId: props.organization.id,
        guestOrganizationToken: guestToken.replace(/-/g, ""),
        asAdmin: role === "admin",
      });
      return props.onClose();
    } catch (_) {
      return enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  return (
    <>
      <CardContent>
        <Typography variant="title-xs" paragraph>
          Which organization would you like to invite to access your sites?
        </Typography>
        <InputMask
          mask="****-****"
          maskChar=""
          value={guestToken}
          onChange={(event) => setGuestToken(event.target.value.toUpperCase())}
        >
          {() => (
            <TextField
              style={{ maxWidth: "50%" }}
              label="Organization's sharing  code"
              placeholder="Example: 7HX4-8F5L"
              helperText="The organization  you are sharing  with must  provide this code"
            />
          )}
        </InputMask>
      </CardContent>

      <CardContent>
        <Typography variant="title-xs">What level of access should they have?</Typography>
        <Typography variant="description-sm" color="ash" paragraph>
          This is the most access allowed to anyone in the target organization. Not every person in
          that organization will have this level of access - their access can be further restricted
          by their organization.
        </Typography>

        <ChonkToggleButtonGroup
          exclusive
          value={role}
          onChange={(event, val) => val && setRole(val)}
        >
          <ChonkToggleButton value="viewer">
            <Typography variant="title-micro" color="onyx">
              Viewer
            </Typography>
            <Typography variant="description-sm" color="pewter">
              Viewers access grants access to you site dashboards, but prevents users from changing
              or controlling anything.
            </Typography>
          </ChonkToggleButton>
          <ChonkToggleButton value="admin">
            <Typography variant="title-micro" color="onyx">
              Administrator
            </Typography>
            <Typography variant="description-sm" color="pewter">
              Administrator access grants full access to your sites, including the ability to modify
              site configuration and override controls.
            </Typography>
          </ChonkToggleButton>
        </ChonkToggleButtonGroup>
      </CardContent>

      <CardActions style={{ padding: 16 }}>
        <AsyncButton
          variant="solid"
          color="primary"
          size="medium"
          onClick={handleSubmit}
          disabled={!valid}
        >
          Share site access
        </AsyncButton>
        <Button variant="solid" color="clear" size="medium" onClick={props.onClose}>
          Cancel
        </Button>
      </CardActions>
    </>
  );
}

export const AddSharePane = createFragmentContainer(AddSharePaneComponent, {
  organization: gql`
    fragment AddSharePane_organization on Organization {
      id
    }
  `,
});
