import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { AlertsSitesVariables } from "generated-gql-types/AlertsSites";
import { AlertFilter } from "generated-gql-types/globalTypes";

export const ALERTS_SITES_QUERY = gql`
  query AlertsSites($first: Int = 500, $search: String, $alertFilter: AlertFilter) {
    sites(first: $first, search: $search, alertFilter: $alertFilter) {
      edges {
        node {
          id
          name
          filteredAlertCount
          openAlertCount
        }
      }
    }
  }
`;

export const useAlertsSitesQuery = (alertFilter?: AlertFilter, first?: number, search?: string) => {
  const [alertsSitesQueryParams, setAlertsSitesQueryParams] = useState<AlertsSitesVariables>({
    alertFilter: alertFilter || {},
    first,
    search,
  });

  const alertsSitesQuery = useQuery(ALERTS_SITES_QUERY, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only", // prevent redundant fetches
    variables: alertsSitesQueryParams,
  });

  return { alertsSitesQueryParams, setAlertsSitesQueryParams, alertsSitesQuery };
};

export const SITE_NODE_QUERY = gql`
  query SiteNode($siteId: ID!) {
    node: node(id: $siteId) {
      ... on Site {
        id
        name
        filteredAlertCount
        openAlertCount
      }
    }
  }
`;
