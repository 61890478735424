import * as React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { TextFieldProps } from "@material-ui/core/TextField";

const styles = {
  root: {
    "& input": {
      height: "30px",
    },
  },
};

class TextFilterFieldComponent extends React.Component<TextFieldProps & WithStyles> {
  static defaultProps = {
    placeholder: "Filter",
  };

  render() {
    return (
      <TextField
        {...this.props}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <i className="fa fa-filter" />
            </InputAdornment>
          ),
          className: this.props.classes.input,
        }}
      />
    );
  }
}

export const TextFilterField = withStyles(styles)(TextFilterFieldComponent);
