import { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { AlertsGrid, AlertsGridVariables } from "generated-gql-types/AlertsGrid";
import { AlertStatus, AlertFilter, AlertOrderByInput } from "generated-gql-types/globalTypes";

export const ALERTS_GRID_QUERY = gql`
  query AlertsGrid(
    $first: Int = 0
    $last: Int = 0
    $after: String
    $before: String
    $filter: AlertFilter
    $orderBy: AlertOrderByInput
    $includeCount: Boolean
  ) {
    alerts(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
      includeCount: $includeCount
    ) {
      totalCount
      edges {
        node {
          id
          createdAt
          status
          canBeAcked
          canBeClosed
          cause {
            __typename
            ... on AlertCauseCascade {
              ctrlVariable {
                id
                displayName
                bacnetUnitID
                faultPriority
                faultTitle
                faultDetail
                zone
              }
              supervisor {
                id
                displayName
              }
              logicPointValue {
                analogValue
                booleanValue
              }
            }
            ... on AlertCauseMotorErrors {
              motor {
                id
                displayName
              }
              error
              errorCount
            }
            ... on AlertCauseMotorWarnings {
              motor {
                id
                displayName
              }
              warning
              warningCount
            }
            ... on AlertCauseMotorConnectivity {
              motor {
                id
                displayName
              }
            }
            ... on AlertCauseMotorSpeed {
              motor {
                id
                displayName
              }
              currentSpeed
              requestedSpeed
            }
            ... on AlertCauseMotorLowTorque {
              motor {
                id
                displayName
              }
            }
            ... on AlertCauseMotorSeizure {
              motor {
                id
                displayName
              }
            }
            ... on AlertCauseSupervisorConnectivity {
              supervisor {
                id
                displayName
              }
            }
          }
          site {
            id
            name
            address
            viewerIsAdmin
            organization {
              id
              name
            }
          }
          type
        }
      }
      pageInfo {
        startCursor
        hasPreviousPage
        endCursor
        hasNextPage
      }
    }
  }
`;

export const DEFAULT_PAGE_SIZE = 25;
export const NON_CLOSED_STATUSES = [AlertStatus.TRIGGERED, AlertStatus.ACKED];

export const useAlertsGridQuery = (
  filter?: AlertFilter,
  orderBy?: AlertOrderByInput,
  skip = false
) => {
  const [alertsQueryParams, setAlertsQueryParams] = useState<AlertsGridVariables>({
    first: DEFAULT_PAGE_SIZE,
    last: 0,
    filter: filter || {},
    orderBy: orderBy || {},
    includeCount: true,
  });

  const alertsQuery = useQuery<AlertsGrid>(ALERTS_GRID_QUERY, {
    fetchPolicy: "cache-and-network", // fix pagination bug when clearing filters
    nextFetchPolicy: "cache-only", // prevent redundant fetches
    variables: alertsQueryParams,
    notifyOnNetworkStatusChange: true,
    skip: skip,
  });

  return {
    alertsQueryParams,
    setAlertsQueryParams,
    alertsQuery,
  };
};
