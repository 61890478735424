import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { TypographyProps } from "@material-ui/core/Typography";
import { withStyles, createStyles, StyleRules } from "@material-ui/core/styles";
import { defaults } from "lodash";
import { Colors } from "sigil";

const createTypographyVariant = (defaultProps: Partial<TypographyProps>, styles: StyleRules) => {
  const unstyledComponent = (props: TypographyProps) => {
    props = defaults({}, props, defaultProps);
    return <Typography {...props} />;
  };
  return withStyles(styles)(unstyledComponent);
};

export const LightLabel = createTypographyVariant(
  { variant: "body2" },
  createStyles(({ palette }: Theme) => ({
    root: {
      color: palette.text.disabled,
      fontWeight: 600,
    },
  }))
);

export const LightCaption = createTypographyVariant(
  { variant: "caption" },
  createStyles(({ palette }: Theme) => ({
    root: {
      color: palette.text.hint,
    },
  }))
);

export const GreyLabel = createTypographyVariant(
  { variant: "caption" },
  createStyles({
    root: {
      color: Colors.ash,
    },
  })
);

export const BoldTitle = createTypographyVariant(
  {},
  createStyles({
    root: {
      fontWeight: 500,
    },
  })
);
