import moment, { Moment } from "moment";

export const DURATION = {
  SECOND: 1000,
  MINUTE: 1000 * 60,
  HOUR: 1000 * 60 * 60,
  DAY: 1000 * 60 * 60 * 24,
  WEEK: 1000 * 60 * 60 * 24 * 7,
};

export enum DayOfWeek {
  Monday = "MONDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
  Thursday = "THURSDAY",
  Friday = "FRIDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
}

export const DaysOfWeek = Object.keys(DayOfWeek).map((k) => DayOfWeek[k] as DayOfWeek);

// DAY_ZERO is used as the throw-away date associated with times with no date,
// e.g. start and end times for recurring scheduled events
export const DAY_ZERO = new Date(Date.UTC(0, 0));

export function isWithinLast(duration: moment.Duration, date: Date) {
  return moment().subtract(duration).isBefore(date);
}

export const ANALYSIS_DATE_FORMAT = "yyyy-MM-DD HH:mm:ss";

// DatePicker is in local timezone; server stores UTC.
// https://stackoverflow.com/questions/72103096/mui-x-date-pickers-utc-always-please
const getTimezoneOffset = (value: Date) => value.getTimezoneOffset() * 60000;

export const makeLocalAppearUTC = (value: Moment) => {
  const dateTime = value.toDate();
  const utcFromLocal = moment(dateTime.getTime() + getTimezoneOffset(dateTime));
  return utcFromLocal;
};

export const localToUTC = (dateTime: Moment) => {
  const utcFromLocal = moment(dateTime.valueOf() - getTimezoneOffset(dateTime.toDate()));
  return utcFromLocal;
};
