import * as React from "react";
import { IconButton, SvgIcon } from "@material-ui/core";
import { IconButtonProps } from "@material-ui/core/IconButton";

// Standard close button. Inherits color and font size
export function CloseButton(props: IconButtonProps) {
  return (
    <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      style={{ fontSize: "inherit" }}
      {...props}
    >
      <SvgIcon viewBox="0 0 17 17" fontSize="inherit" color="inherit">
        <path
          d="M0.564453 14.2168L6.36523 8.31055L0.599609 2.42188L2.69141 0.259766L8.47461
           6.14844L14.2578 0.259766L16.3496 2.42188L10.584 8.31055L16.3848 14.2168L14.293
           16.3789L8.47461 10.4551L2.65625 16.3789L0.564453 14.2168Z"
        />
      </SvgIcon>
    </IconButton>
  );
}
