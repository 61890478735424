import * as React from "react";
import { Typography, Grid } from "@mui/material";
import { OrgSites_sites_edges_node } from "generated-gql-types/OrgSites";
import { SiteTableRow_organization } from "generated-gql-types/SiteTableRow_organization";
import { FormControlLabel, Switch } from "@mui/material";
import { Colors } from "sigil";
import { gql } from "@apollo/client";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { Link } from "react-router-dom";
import { unmarshalNumberId } from "modules/common/utils/relay";

interface SiteRowProps {
  organization: SiteTableRow_organization;
  site: OrgSites_sites_edges_node;
  onToggleEnergySavings: (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean
  ) => void | undefined;
  Action: React.ReactNode;
}

const SITE_NAME_WIDTH_LG = 9;
const TOGGLE_WIDTH_LG = 2;
const MENU_WIDTH_LG = 1;
const SITE_NAME_WIDTH_M = 8.15;
const TOGGLE_WIDTH_M = 2.85;
const MENU_WIDTH_M = 1;
const SITE_NAME_WIDTH_XS = 7.25;
const TOGGLE_WIDTH_XS = 3.5;
const MENU_WIDTH_XS = 1;

export function SiteRowComponent({
  organization,
  site,
  onToggleEnergySavings,
  Action,
}: SiteRowProps) {
  const adminVariant = organization.viewerIsAdmin;

  return (
    <Grid item container xs={12} sx={{ borderTop: `1px solid ${Colors.salt}` }}>
      <Grid
        item
        justifyContent="stretch"
        xs={SITE_NAME_WIDTH_XS}
        md={SITE_NAME_WIDTH_M}
        lg={SITE_NAME_WIDTH_LG}
        sx={{
          cursor: adminVariant ? "pointer" : "default",
          "&:hover .hoverProxy": {
            textDecoration: adminVariant ? "underline" : "none",
          },
          overflow: "hidden",
          textOverflow: "ellipsis",
          padding: "8px 24px",
        }}
      >
        <Typography variant="subtitle1" className="hoverProxy" fontFamily={"Barlow"}>
          <Link to={`/sites/${unmarshalNumberId(site.id)}`}>
            <strong>{site.name}</strong>
          </Link>
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {site.address}
        </Typography>
      </Grid>

      <Grid
        item
        justifyContent="center"
        xs={TOGGLE_WIDTH_XS}
        md={TOGGLE_WIDTH_M}
        lg={TOGGLE_WIDTH_LG}
        sx={{ padding: "8px 1px" }}
      >
        <FormControlLabel
          control={<Switch checked={site.energySavingsEnabled} disabled={!adminVariant} />}
          label={site.energySavingsEnabled ? "Enabled" : "Disabled"}
          onChange={onToggleEnergySavings}
          componentsProps={{ typography: { variant: "body2", fontFamily: "Barlow" } }}
        />
      </Grid>

      <Grid
        item
        justifyContent="center"
        xs={MENU_WIDTH_XS}
        md={MENU_WIDTH_M}
        lg={MENU_WIDTH_LG}
        sx={{ padding: "8px 8px" }}
      >
        {Action}
      </Grid>
    </Grid>
  );
}

export const SiteTableRow = createFragmentContainer(SiteRowComponent, {
  organization: gql`
    fragment SiteTableRow_organization on Organization {
      viewerIsAdmin
    }
  `,
});

export const SiteHeaderRow = () => (
  <Grid item container xs={12}>
    <Grid
      item
      justifyContent="stretch"
      xs={SITE_NAME_WIDTH_XS}
      md={SITE_NAME_WIDTH_M}
      lg={SITE_NAME_WIDTH_LG}
      sx={{
        cursor: "pointer",
        "&:hover .hoverProxy": {
          textDecoration: "underline",
        },
        overflow: "hidden",
        textOverflow: "ellipsis",
        padding: "8px 24px",
      }}
    >
      Site Name
    </Grid>

    <Grid
      item
      justifyContent="center"
      xs={TOGGLE_WIDTH_XS}
      md={TOGGLE_WIDTH_M}
      lg={TOGGLE_WIDTH_LG}
      sx={{ padding: "8px 1px" }}
    >
      Energy Savings Status
    </Grid>

    <Grid
      item
      justifyContent="center"
      xs={MENU_WIDTH_XS}
      md={MENU_WIDTH_M}
      lg={MENU_WIDTH_LG}
    ></Grid>
  </Grid>
);
