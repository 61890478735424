import * as React from "react";
import { gql } from "@apollo/client";
import { useSnackbar } from "notistack";
import { Map as MapboxMap } from "mapbox-gl";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { useCreateSiteMutation } from "./CreateSiteMutation";
import { CreateSiteForm_organization } from "generated-gql-types/CreateSiteForm_organization";
import { Grid, Box } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import { FormikTextField, FormikSubmitButton } from "modules/common/components/MuiFormik";
import { useCallback } from "react";
import * as Yup from "yup";
import { FormikOrganizationInput } from "./FormikOrganizationInput";
import { FormikAddressInput } from "./FormikAddressInput";
import { SmcMuiTheme } from "theme";
import { useHistory } from "react-router-dom";
import { DescriptionLarge, Colors, Cross, Button } from "sigil";

const SiteSchema = Yup.object({
  name: Yup.string().required("Please enter a site name."),
  address: Yup.string().required("Address not found."),
  organizationId: Yup.string().required("Please select an organization."),
  latitude: Yup.number().min(-90).max(90).required("Couldn't locate the address."),
  longitude: Yup.number().min(-180).max(180).required("Couldn't locate the address."),
});

const useFormStyles = makeStyles((theme: typeof SmcMuiTheme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      overflowX: "hidden",
      flexGrow: 1,
    },
    grid: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    close: {
      cursor: "pointer",
      "& path": {
        fill: Colors.tin,
      },
      "&:hover path": {
        fill: Colors.onyx,
      },
    },
  })
);
type CreateSiteProps = {
  onClose: () => void;
  map?: MapboxMap;
  loaded: boolean;
  organizations: CreateSiteForm_organization[];
  canCreateSitesUnrestricted: boolean;
};
export const CreateSiteFormComponent = ({
  map,
  loaded,
  organizations,
  onClose,
  canCreateSitesUnrestricted,
}: CreateSiteProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const createSite = useCreateSiteMutation();
  const adminOrganizations = organizations.filter(
    (org) => org.viewerIsAdmin || canCreateSitesUnrestricted
  );
  const styles = useFormStyles();
  const close = useCallback(onClose, [onClose]);
  const history = useHistory();
  // We shouldn't get here. But just in case.
  if (adminOrganizations.length === 0) {
    close();
    enqueueSnackbar("Something went wrong", { variant: "error" });
  }

  return (
    <Box className={styles.root}>
      <Grid container item className={styles.grid} direction="column">
        <Grid item>
          <Formik
            initialValues={{
              organizationId: adminOrganizations[0].id,
              name: "",
              address: "",
              latitude: 0,
              longitude: 0,
            }}
            validationSchema={SiteSchema}
            onSubmit={async (values) => {
              try {
                const { data, errors } = await createSite(values);
                if (errors) throw errors;
                history.push(`/sites/${atob(data.createSite.site.id).replace("site:", "")}/config`);
              } catch (e) {
                enqueueSnackbar("Something went wrong", { variant: "error" });
              }
            }}
          >
            <Form>
              <Grid container spacing={1} direction="column">
                <Grid item container wrap="nowrap">
                  <Grid item xs={12}>
                    <DescriptionLarge component="h5" color="slate">
                      Add New Site
                    </DescriptionLarge>
                  </Grid>
                  <Grid item>
                    <Cross inline={false} className={styles.close} onClick={onClose} />
                  </Grid>
                </Grid>
                <Grid item>
                  <FormikTextField
                    name="name"
                    placeholder="Enter site name"
                    label="Site name"
                    required
                    fullWidth
                    autoFocus
                  />
                </Grid>
                {organizations.length > 1 && (
                  <Grid item>
                    <FormikOrganizationInput organizations={organizations} />
                  </Grid>
                )}
                <Grid item>
                  <Box pb={1}>
                    <FormikAddressInput map={map} loaded={loaded} />
                  </Box>
                </Grid>
                <Grid container item justify="flex-end" spacing={1}>
                  <Grid item>
                    <Button onClick={onClose} variant="knockout" size="medium">
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <FormikSubmitButton>Add&nbsp;Site</FormikSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Grid>
      </Grid>
    </Box>
  );
};

export const CreateSiteForm = createFragmentContainer(CreateSiteFormComponent, {
  organizations: gql`
    fragment CreateSiteForm_organization on Organization {
      id
      name
      viewerIsAdmin
    }
  `,
});
