import * as React from "react";

interface HoverableRenderProps {
  isHovering: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

interface HoverableProps {
  children: (props: HoverableRenderProps) => JSX.Element | null;
}

interface HoverableState {
  isHovering: boolean;
}

export class Hoverable extends React.PureComponent<HoverableProps, HoverableState> {
  constructor(props: HoverableProps) {
    super(props);
    this.state = { isHovering: false };
  }

  mouseHandler = (isOver: boolean) => {
    return () => this.setState({ isHovering: isOver });
  };

  get renderProps(): HoverableRenderProps {
    return {
      isHovering: this.state.isHovering,
      onMouseEnter: this.mouseHandler(true),
      onMouseLeave: this.mouseHandler(false),
    };
  }

  render() {
    return this.props.children(this.renderProps);
  }
}
