import * as React from "react";
import { Route, Switch } from "react-router-dom";

import { AlertRoute } from "./Alert";
import { AlertsGridRoute } from "./AlertsIndex";

export function AlertsRoute() {
  return (
    <Switch>
      <Route path="/alerts/sites/:site_id" component={AlertsGridRoute} />
      <Route path="/alerts/:alert_id" component={AlertRoute} />
      <Route path="/alerts" component={AlertsGridRoute} />
    </Switch>
  );
}
