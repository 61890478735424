import * as React from "react";
import { MouseEvent } from "react";
import { Dialog, DialogTitle, DialogContent, Grid, Link, Box, Divider } from "@material-ui/core";
import { useAssociateSupervisorWithSiteMutation } from "./AssociateSupervisorWithSiteMutation";
import { useState } from "react";
import { gql } from "@apollo/client";
import { Formik, Form } from "formik";
import { useSnackbar } from "notistack";
import { FormikTextField, FormikSubmitButton } from "modules/common/components/MuiFormik";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { AssociateSupervisorButton_site } from "generated-gql-types/AssociateSupervisorButton_site";
import { ReactComponent as SupervisorExample } from "./SupervisorExample.svg";
import { FontIcon } from "modules/common/components/FontIcon";
import { DescriptionSmall, DescriptionMedium, TitleSmall, Button } from "sigil";

type AssociateSupervisorButtonProps = { site: AssociateSupervisorButton_site };
const AssociateSupervisorButtonComponent = ({ site }: AssociateSupervisorButtonProps) => {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  return (
    <>
      <Button variant="solid" color="primary" size="small" onClick={() => setOpen(true)}>
        Add Supervisor
      </Button>
      <AssociateSupervisorDialog site={site} open={open} onClose={close} />
    </>
  );
};

export const AssociateSupervisorButton = createFragmentContainer(
  AssociateSupervisorButtonComponent,
  {
    site: gql`
      fragment AssociateSupervisorButton_site on Site {
        id
        name
      }
    `,
  }
);

type AssociateSupervisorDialogProps = {
  site: AssociateSupervisorButton_site;
  open: boolean;
  onClose: () => void;
};
const AssociateSupervisorDialog = ({ site, open, onClose }: AssociateSupervisorDialogProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="associate-title"
    fullWidth
    maxWidth="md"
    style={{ paddingBottom: 0 }}
  >
    <DialogTitle id="associate-title" disableTypography>
      <TitleSmall>Add supervisor to {site.name}</TitleSmall>
    </DialogTitle>
    <AssociateSupervisorForm site={site} onClose={onClose} />
  </Dialog>
);

type AssociateSupervisorFormProps = {
  site: AssociateSupervisorButton_site;
  onClose: () => void;
};
const AssociateSupervisorForm = ({ site, onClose }: AssociateSupervisorFormProps) => {
  const associateSupervisor = useAssociateSupervisorWithSiteMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [drawerOpen, openDrawer] = useState(false);
  return (
    <Formik
      initialValues={{ siteId: site.id, password: "", supervisorSerialNumber: "" }}
      onSubmit={async (values) => {
        try {
          await associateSupervisor(values);
          enqueueSnackbar("Saved", { variant: "success" });
          onClose();
        } catch (e) {
          enqueueSnackbar("Something went wrong", { variant: "error" });
        }
      }}
    >
      <Form>
        <DialogContent>
          <FormikTextField
            name="supervisorSerialNumber"
            placeholder="Example: 27C001234567"
            label="Supervisor Serial Number"
            autoFocus
            required
          />
          <Box pt={1}>
            <FormikTextField
              name="password"
              label="Supervisor Password"
              placeholder="Example: c8jF9x2"
              required
            />
          </Box>
        </DialogContent>
        <DialogContent>
          <Box py={1}>
            <Grid container wrap="nowrap" alignItems="center">
              <Grid item xs={12}>
                <Link
                  href="#"
                  color="secondary"
                  onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                    openDrawer(!drawerOpen);
                    e.preventDefault();
                  }}
                >
                  <DescriptionMedium color="wave">
                    <FontIcon name="question-circle" />
                    <strong> Where can I find this?</strong>
                  </DescriptionMedium>
                </Link>
              </Grid>
              <Grid item>
                <Box px={1}>
                  <Button onClick={onClose} variant="knockout" size="medium">
                    Cancel
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <FormikSubmitButton>Add&nbsp;Supervisor</FormikSubmitButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        {drawerOpen && (
          <DialogContent>
            <Divider color="silver" />
            <Box pt={3} pb={1}>
              <Grid wrap="nowrap" container xs={12}>
                <Grid item>
                  <DescriptionSmall>
                    On the underside of every supervisor, you will find barcode labels that show the
                    serial number and password for that device
                  </DescriptionSmall>
                </Grid>
                <Grid item>
                  <SupervisorExample />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        )}
      </Form>
    </Formik>
  );
};
