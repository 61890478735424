import * as React from "react";
import { Theme, Grid } from "@material-ui/core";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import { TextLink } from "modules/common/components/TextLink";
import { Colors } from "sigil";
import * as H from "history";

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    breadCrumb: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "200px",
    },
    slashDivider: {
      margin: `0 ${theme.spacing(1)}px`,
      color: theme.palette.text.disabled,
    },
  });
};

type BreadCrumbProps = WithStyles<typeof styles> & {
  items: React.ReactNode[];
  divider?: React.ReactNode;
};

function SlashDividerComponent({ classes }: WithStyles<typeof styles>) {
  return <span className={classes.slashDivider}>/</span>;
}

const SlashDivider = withStyles(styles)(SlashDividerComponent);

function BreadCrumbComponent({ items, divider = <SlashDivider />, classes }: BreadCrumbProps) {
  return (
    <Grid container className={classes.root} justify="flex-start">
      {items.map((item, i) => {
        return (
          <Grid item xs="auto" key={getItemKey(item, i)}>
            {i === 0 ? false : divider}
            <span className={classes.breadCrumb}>{item}</span>
          </Grid>
        );
      })}
    </Grid>
  );
}

function getItemKey(node: React.ReactNode, index: number): string {
  if (typeof node === "string") {
    return node;
  } else if (typeof node === "number") {
    return node.toString();
  } else if (node && typeof node === "object" && "key" in node) {
    const key = node.key;
    return key ? key.toString() : index.toString();
  }
  return index.toString();
}

export const BreadCrumb = withStyles(styles)(BreadCrumbComponent);

type BreadcrumbLinkProps = {
  to: H.LocationDescriptor;
  children: React.ReactNode | React.ReactNode[];
};

export const BreadcrumbLink = ({ to, children }: BreadcrumbLinkProps) => (
  <TextLink
    to={to}
    style={{ color: Colors.ttBlue, textDecoration: "underline", fontWeight: "bold" }}
  >
    {children}
  </TextLink>
);

export const BreadCrumbLeafComponent = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => (
  <span
    style={{
      fontWeight: "bold",
    }}
  >
    {children}
  </span>
);

export const BreadCrumbLeaf = withStyles(styles)(BreadCrumbLeafComponent);
