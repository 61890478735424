import { computed } from "mobx";

import { MotorOpStat } from "modules/site-manager/constants/motor-op";

type MotorOpStatDefinition = {
  name: string;
};

const definitions: { [key in MotorOpStat]: MotorOpStatDefinition } = {
  [MotorOpStat.CurrentSpeed]: {
    name: "Current Speed",
  },
  [MotorOpStat.RequestedSpeed]: {
    name: "Requested Speed",
  },
  [MotorOpStat.PowerIn]: {
    name: "Power In",
  },
  [MotorOpStat.PowerOut]: {
    name: "Power Out",
  },
  [MotorOpStat.Torque]: {
    name: "Torque",
  },
  [MotorOpStat.CurrentA]: {
    name: "Current A",
  },
  [MotorOpStat.CurrentB]: {
    name: "Current B",
  },
  [MotorOpStat.CurrentC]: {
    name: "Current C",
  },
  [MotorOpStat.TTPower]: {
    name: "Power",
  },
};

export class MotorOpStatPresenter {
  constructor(private stat: MotorOpStat) {}

  @computed
  get name() {
    return definitions[this.stat].name;
  }
}
