import * as React from "react";
import { Tooltip } from "@material-ui/core";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { TooltipProps } from "@material-ui/core/Tooltip";
import { assign, omit } from "lodash";

const arrowGenerator = (color: string, diagLength: number) => ({
  "&[x-placement*='bottom'] $arrow": {
    top: 0,
    left: 0,
    marginTop: "-0.95em",
    width: `${3 * diagLength}px`,
    height: "1em",
    "&::before": {
      borderWidth: `0 ${diagLength}px ${diagLength}px ${diagLength}px`,
      borderColor: `transparent transparent ${color} transparent`,
    },
  },
  "&[x-placement*='top'] $arrow": {
    bottom: 0,
    left: 0,
    marginBottom: "-0.95em",
    width: `${3 * diagLength}px`,
    height: "1em",
    "&::before": {
      borderWidth: `${diagLength}px ${diagLength}px 0 ${diagLength}px`,
      borderColor: `${color} transparent transparent transparent`,
    },
  },
  "&[x-placement*='right'] $arrow": {
    left: 0,
    marginLeft: "-0.95em",
    height: `${3 * diagLength}px`,
    width: "1em",
    "&::before": {
      borderWidth: `${diagLength}px ${diagLength}px ${diagLength}px 0`,
      borderColor: `transparent ${color} transparent transparent`,
    },
  },
  "&[x-placement*='left'] $arrow": {
    right: 0,
    marginRight: "-0.95em",
    height: `${3 * diagLength}px`,
    width: "1em",
    "&::before": {
      borderWidth: `${diagLength}px 0 ${diagLength}px ${diagLength}px`,
      borderColor: `transparent transparent transparent ${color}`,
    },
  },
});

const styles = (theme: Theme) => {
  return createStyles({
    tooltip: { backgroundColor: theme.palette.text.secondary },
    arrowPopper: arrowGenerator(theme.palette.text.secondary, theme.spacing(1.5)),
    arrow: {
      position: "absolute",
      fontSize: 6,
      width: "3em",
      height: "3em",
      "&::before": {
        content: "''",
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
      },
    },
  });
};

class ArrowTooltipComponent extends React.Component<TooltipProps & WithStyles> {
  state = {
    arrowRef: null,
  };

  handleArrowRef = (node: HTMLElement) => {
    this.setState({
      arrowRef: node,
    });
  };

  render() {
    const { classes, title } = this.props;
    let props: TooltipProps & WithStyles = assign({}, this.props, {
      title: (
        <React.Fragment>
          {title}
          <span className={classes.arrow} ref={this.handleArrowRef} />
        </React.Fragment>
      ),
      classes: assign({ popper: classes.arrowPopper }, omit(classes, "arrow", "arrowPopper")),
      PopperProps: {
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(this.state.arrowRef),
              element: this.state.arrowRef,
            },
          },
        },
      },
    });
    return <Tooltip {...props} />;
  }
}

export const ArrowTooltip = withStyles(styles)(ArrowTooltipComponent);
