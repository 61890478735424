import * as React from "react";
import { observer } from "mobx-react";
import { withStyles, WithStyles, WithTheme, withTheme } from "@material-ui/core/styles";
import { Grid } from "@mui/material";

import { Health } from "modules/common/enums";
import { MetricGauge, MetricGaugeProps } from "modules/charts/components/MetricGauge";
import { HoverableCard } from "modules/common/components/HoverableCard";
import { getColorForHealth } from "modules/site-manager/utils/health";
import { HealthIcon } from "modules/site-manager/components/HealthIcon";
import { HealthStatusBar } from "modules/site-manager/components/HealthStatusBar";
import { LightCaption, BoldTitle } from "modules/common/components/Typography";
import { MetricSummaryCardStyles } from "./styles";

type MetricSummaryCardProps = {
  title: string;
  subTitle?: string | null;
  statusBarContent: React.ReactNode;
  footerContent?: React.ReactNode;
  health: Health;
  GaugeProps: Partial<MetricGaugeProps>;
  onClick: () => void;
};

@observer
class MetricSummaryCardComponent extends React.Component<
  MetricSummaryCardProps & WithTheme & WithStyles<typeof MetricSummaryCardStyles>
> {
  static defaultGaugeProps: MetricGaugeProps = {
    width: 150,
    min: 0,
    max: 1800,
    label: "Speed (rpm)",
    metric: 0,
    formatMetric: (metric) => <>{metric}</>,
  };

  render() {
    const {
      classes,
      footerContent,
      health,
      theme,
      title,
      subTitle,
      statusBarContent,
      GaugeProps,
      onClick,
    } = this.props;
    const healthColor = getColorForHealth(theme, health);

    return (
      <HoverableCard
        className={classes.card}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        <Grid
          container
          width="100%"
          direction="column"
          minHeight="290px"
          justifyContent="space-between"
          display="flex"
        >
          <Grid
            item
            xs="auto"
            container
            direction="row"
            justifyContent="space-between"
            width="100%"
          >
            <Grid item xs>
              <BoldTitle variant="h5">{title}</BoldTitle>
              <LightCaption>{subTitle}</LightCaption>
            </Grid>
            <Grid item xs="auto" className={classes.statusIcon}>
              <HealthIcon health={health} />
            </Grid>
            <Grid item xs={12} className={classes.statusBar}>
              <HealthStatusBar health={health}>{statusBarContent}</HealthStatusBar>
            </Grid>
          </Grid>

          <Grid item xs="auto" pt={1.5} pb={4}>
            <MetricGauge
              {...MetricSummaryCardComponent.defaultGaugeProps}
              color={healthColor}
              {...GaugeProps}
            />

            {footerContent}
          </Grid>
        </Grid>
      </HoverableCard>
    );
  }
}

export const MetricSummaryCard = withTheme(
  withStyles(MetricSummaryCardStyles)(MetricSummaryCardComponent)
);
