import { isNone } from "modules/common/utils/guards";
import { BmsRole, FanModeBitmask } from "modules/site-manager/constants";
import { HvacOpModeBitmask } from "../constants/bms-behavior-bitmask";

export type BitmaskOption = {
  label: string;
  mask: number;
};

export class BMSRoleBitmaskPresenter {
  static labels: {
    [key: number]: { [key: number]: string };
  } = {
    [BmsRole.HVAC_FAN_MODE]: {
      [FanModeBitmask.OFF]: "Off",
      [FanModeBitmask.ON]: "On",
      [FanModeBitmask.AUTO]: "Auto",
    },
    [BmsRole.HVAC_OP_MODE]: {
      [HvacOpModeBitmask.OFF]: "Off",
      [HvacOpModeBitmask.VENT]: "Vent",
      [HvacOpModeBitmask.COOL1]: "Cool 1",
      [HvacOpModeBitmask.COOL2]: "Cool 2",
      [HvacOpModeBitmask.HEAT1]: "Heat 1",
      [HvacOpModeBitmask.HEAT2]: "Heat 2",
    },
  };

  constructor(private role: BmsRole | undefined) {}

  private get labels() {
    return this.role ? BMSRoleBitmaskPresenter.labels[this.role] || {} : {};
  }

  labelFor(bitmask: number | undefined | null): string {
    if (isNone(bitmask)) {
      return "";
    }
    return this.labels[bitmask];
  }

  get options(): BitmaskOption[] {
    const labels = this.labels;
    return Object.keys(labels).map((key) => ({
      label: labels[key],
      mask: parseInt(key, 10),
    }));
  }
}
