import * as React from "react";
import { Theme } from "@material-ui/core";
import { WithStyles, withStyles, createStyles } from "@material-ui/core/styles";

import { Series, SeriesCollection } from "modules/charts/series";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      listStyleType: "none",
      padding: "0 2em",
      textAlign: "center",
      fontSize: "12px",
      marginBottom: "1em",
    },
    item: {
      display: "inline-block",
      marginRight: "2em",
      cursor: "pointer",
    },
    pip: {
      display: "inline-block",
      verticalAlign: "middle",
      width: "10px",
      height: "10px",
      borderRadius: 10,
      marginBottom: "1px",
    },
  });

interface TSLProps extends WithStyles<typeof styles> {
  series: SeriesCollection;
  onClick: (series: Series) => void;
}

class TimeseriesLegendComponent extends React.Component<TSLProps> {
  render() {
    const { series, classes } = this.props;

    return (
      <ul className={classes.root}>
        {series.all.map((seriesItem) => this.legendItem(seriesItem))}
      </ul>
    );
  }

  legendItem(seriesItem: Series) {
    const { onClick, classes } = this.props;
    const { id, label, group } = seriesItem;

    return (
      <li key={id} className={classes.item} onClick={() => onClick(seriesItem)}>
        <span
          className={classes.pip}
          style={{
            backgroundColor: seriesItem.color,
            opacity: seriesItem.visible ? 1.0 : 0.5,
          }}
        />
        &nbsp;
        {group ? `${group}: ` : null}
        {label}
      </li>
    );
  }
}

export const TimeseriesLegend = withStyles(styles)(TimeseriesLegendComponent);
