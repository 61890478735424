import React, { ReactNode } from "react";
import { Card, Stack } from "@mui/material";
import {
  GridCellValue,
  GridColDef,
  GridValueFormatterParams,
  DataGrid,
  GridRowClassNameParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { TableHeader } from "./TableHeader";
import { tableStyles } from "./tableStyles";
import { useTheme } from "@mui/material/styles";

export const DEFAULT_COLUMN_WIDTH = 125;
const SORT_BUTTON_WIDTH = 55;
const HEADER_ALIGN = "left";

export type ValueFormatter = (params: GridValueFormatterParams) => GridCellValue;

export const renderStringWithTooltip = (params: GridRenderCellParams): ReactNode => {
  const value = params.row[params.field] || "";
  return (
    <p
      title={value}
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
      className="MuiDataGrid-cellContent"
    >
      {value}
    </p>
  );
};

export const createColumn = (gridColDef: GridColDef): GridColDef => {
  const colDef: GridColDef = {
    headerAlign: HEADER_ALIGN,
    type: "string",
    filterable: false, // handling filtering outside the grid, for great cheapness
    ...gridColDef,
  };
  if (!gridColDef.renderHeader) {
    colDef.renderHeader = () => (
      <TableHeader
        headerName={colDef.headerName || ""}
        maxWidth={`${
          (colDef.width || DEFAULT_COLUMN_WIDTH) - (colDef.sortable ? SORT_BUTTON_WIDTH : 0)
        }px`} // Avoid re-flow when the sort arrow icon becomes visible
      />
    );
  }
  return colDef;
};

export interface IDataTable extends React.ComponentProps<typeof DataGrid> {
  noRowsMessage: string;
}

type NoRowsProps = {
  noRowsMessage: string;
};

export const NoRowsOverlay = ({ noRowsMessage }: NoRowsProps) => (
  <Stack height="100%" alignItems="center" justifyContent="center">
    {noRowsMessage || "No Rows."}
  </Stack>
);

export const DataTable = (props: IDataTable) => {
  const theme = useTheme();
  const classes = tableStyles(theme);
  return (
    <Card>
      <DataGrid
        components={{
          NoRowsOverlay: () => NoRowsOverlay({ noRowsMessage: props.noRowsMessage }),
          NoResultsOverlay: () => NoRowsOverlay({ noRowsMessage: props.noRowsMessage }),
        }}
        getRowId={(row) => row.identifier || row.id}
        getRowClassName={(params: GridRowClassNameParams) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        sx={classes.table}
        {...props}
      />
    </Card>
  );
};

export default DataTable;
