import * as React from "react";
import { MouseEvent, useCallback, useState } from "react";
import { CircularProgress, Link, Box } from "@material-ui/core";
import { gql } from "@apollo/client";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { useInviteOrganizationMemberMutation } from "modules/common/mutations/SendOrganizationInviteMutation";
import { Reinvite_member } from "generated-gql-types/Reinvite_member";
import { Reinvite_organization } from "generated-gql-types/Reinvite_organization";
import { useSnackbar } from "notistack";
import { Colors } from "sigil";

export interface ReinviteProps {
  organization: Reinvite_organization;
  member: Reinvite_member;
}

function Reinvite(props: ReinviteProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const inviteMember = useInviteOrganizationMemberMutation();
  const handleClick = useCallback(
    async (e: MouseEvent) => {
      e.stopPropagation();
      setIsSubmitting(true);
      try {
        await inviteMember({
          email: props.member.node.email,
          organizationId: props.organization.id,
          isAdmin: props.member.isAdmin,
        });

        enqueueSnackbar(`Resent invitation to ${props.member.node.email}`, {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      } finally {
        setIsSubmitting(false);
      }
    },
    [props.organization, props.member, enqueueSnackbar, inviteMember]
  );

  const resend = (
    <Link component="a" style={{ color: Colors.wave }} noWrap={true} onClick={handleClick}>
      re-send invitation
    </Link>
  );

  const progress = <CircularProgress color="secondary" size="1em" />;

  return (
    <Box width="110px" display="inline-flex" justifyContent="center">
      {isSubmitting ? progress : resend}
    </Box>
  );
}

export default createFragmentContainer(Reinvite, {
  organization: gql`
    fragment Reinvite_organization on Organization {
      id
    }
  `,
  member: gql`
    fragment Reinvite_member on OrganizationUserEdge {
      isAdmin
      node {
        email
      }
    }
  `,
});
