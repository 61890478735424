import * as React from "react";
import moment from "moment";
import { Typography, Box } from "@mui/material";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import {
  AlertDetail_alert_Alert,
  AlertDetail_alert_Alert_history,
} from "generated-gql-types/AlertDetail";
import { AlertStatus } from "generated-gql-types/globalTypes";
import { PrimaryCard } from "modules/common/components/PrimaryCard";

const AlertHistoryRowStyles = (theme: Theme) => {
  return createStyles({
    root: {},
    dateWrapper: {
      paddingRight: theme.spacing(2),
      borderRight: `1px solid ${theme.palette.text.disabled}`,
      fontSize: 13,
      textAlign: "right",
    },
    eventWrapper: {
      paddingLeft: theme.spacing(3),
    },
  });
};

type AlertHistoryRowProps = WithStyles<typeof AlertHistoryRowStyles> & {
  historyItem: AlertDetail_alert_Alert_history;
};

const ACTION_NAMES = {};
ACTION_NAMES[AlertStatus.TRIGGERED] = "Triggered";
ACTION_NAMES[AlertStatus.ACKED] = "Acknowledged";
ACTION_NAMES[AlertStatus.CLOSED] = "Closed";
Object.freeze(ACTION_NAMES);

function AlertHistoryRowComponent({ classes, historyItem }: AlertHistoryRowProps) {
  const historyMoment = moment(historyItem.time);

  return (
    <tr>
      <td>
        <div className={classes.dateWrapper}>
          {historyMoment.format("llll")}
          <Box color="text.disabled">{historyMoment.fromNow()}</Box>
        </div>
      </td>

      <td className={classes.eventWrapper}>
        <Typography>
          {ACTION_NAMES[historyItem.status]} by{" "}
          <strong>{historyItem.user ? historyItem.user.fullName : "system"}</strong>
        </Typography>
      </td>
    </tr>
  );
}

const AlertHistoryRow = withStyles(AlertHistoryRowStyles)(AlertHistoryRowComponent);

type AlertHistoryTableProps = {
  alert: AlertDetail_alert_Alert;
};

export function AlertHistoryTable({ alert }: AlertHistoryTableProps) {
  return (
    <Box mb={1}>
      {/* This Box exists just so its margin makes room for the drop shadow */}
      <PrimaryCard>
        <Box p={2} mb={1}>
          <table>
            <tbody>
              {alert.history
                .map((historyItem, i) => <AlertHistoryRow key={i} historyItem={historyItem} />)
                .reverse()}
            </tbody>
          </table>
        </Box>
      </PrimaryCard>
    </Box>
  );
}
