import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import { ListItemNavLink } from "modules/common/components/ListItemNavLink";
import * as H from "history";
import classNames from "classnames";
import { Colors } from "sigil";

type NavButtonProps = {
  caption: string;
  iconName?: string;
  to: H.LocationDescriptor;
};

const NavButtonComponent = ({ caption, to, iconName, classes }: NavButtonProps & WithStyles) => {
  const iconClasses = classNames("fas", `fa-${iconName}`, classes.icon);
  return (
    <div className={classes.root}>
      <ListItemNavLink to={to} dense={false} exact={false} classes={{ root: classes.navlink }}>
        {iconName && <i className={iconClasses} />}
        {caption}
      </ListItemNavLink>
    </div>
  );
};

export const NavButton = withStyles((_) => ({
  root: {
    marginRight: 10,
  },
  navlink: {
    borderLeft: "none",
    fontSize: "14px",
    fontWeight: 500,
    paddingLeft: 24,
    paddingRight: 24,
    justifyContent: "center",
    minWidth: "110px",

    "a.active > &": {
      border: `1px solid ${Colors.ttBlue}`,
    },
  },
  icon: {
    paddingRight: 6,
    marginTop: 2, // line height does nothing for us since icon is higher than line
    "a.active > div > &": {
      color: Colors.ttBlue,
    },
  },
}))(NavButtonComponent);
