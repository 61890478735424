import React, { useState, useEffect } from "react";
import "./style.css";
// @ts-ignore: type definitions for react-date-range does include DefinedRange
import { DefinedRange, DateRange } from "react-date-range";
import moment from "moment";
import { Icon, Typography } from "@material-ui/core";
import { Grid, Stack } from "@mui/material";
import InputMask from "react-input-mask";
import classNames from "classnames";
import Popover from "@material-ui/core/Popover";
import { useDateTimePickerStyles } from "./styles";
import { Button, Colors, TextField } from "sigil";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

type DatePickerProps = {
  onApply: (item: Item[]) => void;
  startDate: Date;
  endDate: Date;
};

interface Item {
  startDate: Date;
  endDate: Date;
  key: string;
}

const warningIcon = <WarningAmberIcon fontSize="small" />;

export const LAST_DAY_LABEL = "Last 24 hours";
export const LAST_WEEK_LABEL = "Last 7 days";
export const LAST_30_DAYS_LABEL = "Last 30 days";
export const DEFAULT_LABEL = "";
export const WARNING_TEXT = "Max 90 days";
export const BUTTON_FORMAT = "MM/DD/YYYY hh:mm A";

export function DatetimePicker(props: DatePickerProps) {
  const [state, setState] = useState([
    {
      startDate: props.startDate,
      endDate: props.endDate,
      key: "selection",
      label: DEFAULT_LABEL,
    },
  ]);
  const [loaded, setLoaded] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const classes = useDateTimePickerStyles();
  const formatChars = {
    "9": "[0-9]",
    h: "^(1[0-9]|[0-9]|2[0-3])$",
    m: "^[1-5]?[0-9]$",
    a: "[A-Za-z]",
    "*": "[A-Za-z0-9]",
  };

  const format = "YYYY-MM-DD HH:mm:ss";
  const today = moment().toDate();
  const yesterday = moment().subtract(1, "days").startOf("day");
  const last3Hours = {
    label: "Last 3 hours",
    start: moment().subtract(3, "hours").toDate(),
  };
  const last24Hours = {
    label: LAST_DAY_LABEL,
    start: moment().subtract(24, "hours").toDate(),
  };
  const startOfYesterday = {
    label: "Yesterday",
    start: yesterday.startOf("day").toDate(),
  };
  const last7Days = {
    label: LAST_WEEK_LABEL,
    start: moment().subtract(7, "days").toDate(),
  };
  const last30Days = {
    label: LAST_30_DAYS_LABEL,
    start: moment().subtract(30, "days").toDate(),
  };
  const last90Days = {
    label: "Last 90 days",
    start: moment().subtract(90, "days").toDate(),
  };

  const isSelected = (label: string) => {
    return label === state[0].label;
  };

  const endsToday = () => moment(props.endDate).diff(today, "days") === 0;

  const staticRanges = [
    {
      label: last3Hours.label,
      range: () => ({
        startDate: last3Hours.start,
        endDate: today,
        label: last3Hours.label,
      }),
      isSelected: () => isSelected(last3Hours.label),
      test: moment(props.endDate).diff(props.startDate, "hours") === 3,
    },
    {
      label: last24Hours.label,
      range: () => ({
        startDate: last24Hours.start,
        endDate: today,
        label: last24Hours.label,
      }),
      isSelected: () => isSelected(last24Hours.label),
      test:
        moment(props.endDate).diff(today, "hours") === 0 &&
        moment(props.endDate).diff(props.startDate, "hours") === 24,
    },
    {
      label: startOfYesterday.label,
      range: () => ({
        startDate: startOfYesterday.start,
        endDate: yesterday.endOf("day").toDate(),
        label: startOfYesterday.label,
      }),
      isSelected: () => isSelected(startOfYesterday.label),
      test: props.startDate === yesterday.startOf("day").toDate(),
    },
    {
      label: last7Days.label,
      range: () => ({
        startDate: last7Days.start,
        endDate: today,
        label: last7Days.label,
      }),
      isSelected: () => isSelected(last7Days.label),
      test: endsToday() && moment(props.endDate).diff(props.startDate, "days") === 7,
    },
    {
      label: last30Days.label,
      range: () => ({
        startDate: last30Days.start,
        endDate: today,
        label: last30Days.label,
      }),
      isSelected: () => isSelected(last30Days.label),
      test: endsToday() && moment(props.endDate).diff(props.startDate, "days") === 30,
    },
    {
      label: last90Days.label,
      range: () => ({
        startDate: last90Days.start,
        endDate: today,
        label: last90Days.label,
      }),
      isSelected: () => isSelected(last90Days.label),
      test: endsToday() && moment(props.endDate).diff(props.startDate, "days") === 90,
    },
  ];

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Try to label the button by inferring which static range matches the date props
  const inputStaticRange = staticRanges.find((range) => range.test);
  const label =
    inputStaticRange?.label ||
    `${moment(state[0].startDate).format(BUTTON_FORMAT)} - ${moment(state[0].endDate).format(
      BUTTON_FORMAT
    )}`;

  // Effect1: Monitor props until state changes interactively.
  // Initial props may be inaccurate due to async loading.
  useEffect(() => {
    if (!loaded) {
      setState([
        {
          startDate: props.startDate,
          endDate: props.endDate,
          key: "selection",
          label: label,
        },
      ]);
    }
  }, [loaded, props, label]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Prevent future prop updates overriding interactive input
  const updateState = (newState: any[]) => {
    setLoaded(true);
    setState(newState);
  };

  function handleChange(item: any) {
    updateState([Object.assign({}, item.selection, { label: "" })]);
  }

  function handleDefinedRangeChange(item: any) {
    updateState([item.selection]);
  }

  function handleChangeStartTime(e: React.ChangeEvent<HTMLInputElement>) {
    const input = moment(e.target.value);
    const startDate = state[0].startDate;
    const endDate = state[0].endDate;
    if (input.isValid()) {
      if (input.isAfter(endDate)) {
        const diff = input.diff(moment(startDate), "seconds");
        const updatedStartDate = input.toDate();
        const updatedEndDate = input.add(diff, "seconds").toDate();
        setDates(updatedStartDate, updatedEndDate);
      } else {
        const updatedDate = updateDate(e.target.value);
        setDates(updatedDate, endDate);
      }
    } else {
      setDates(startDate, endDate);
    }
  }

  function handleChangeEndTime(e: React.ChangeEvent<HTMLInputElement>) {
    const input = moment(e.target.value);
    const startDate = state[0].startDate;
    const endDate = state[0].endDate;
    if (input.isValid()) {
      if (input.isBefore(startDate)) {
        const diff = moment(startDate).diff(input, "seconds");
        const updatedStartDate = input.subtract(diff, "seconds").toDate();
        setDates(updatedStartDate, moment(e.target.value).toDate());
      } else {
        const updatedDate = updateDate(e.target.value);
        setDates(startDate, updatedDate);
      }
    } else {
      setDates(startDate, endDate);
    }
  }

  function updateDate(date: string) {
    const d = new Date(date);
    const h = d.getHours();
    const m = d.getMinutes();
    const s = d.getSeconds();
    const updatedDate = moment(date).set({ hour: h, minute: m, second: s }).toDate();
    return updatedDate;
  }

  function setDates(start: Date, end: Date) {
    updateState([
      {
        startDate: start,
        endDate: end,
        key: "selection",
        label: "",
      },
    ]);
  }

  function handleApply() {
    props.onApply(state);
    setAnchorEl(null);
  }

  const delta = moment(state[0].endDate).diff(state[0].startDate, "days");

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="knockout"
        style={{
          backgroundColor: Colors.white,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: "8px",
          paddingRight: "8px",
          minWidth: "115px",
        }}
        onClick={handleClick}
      >
        <Icon className={`far fa-clock ${classNames(classes.icon)}`} />
        {state[0].label === "" ? label : state[0].label}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          style={{ marginLeft: "0px" }}
          className={`${classNames(classes.root)}`}
        >
          <Grid item xs={true}>
            <DateRange
              ranges={state}
              // @ts-ignore: type definitions for react-date-range do not include the prop "months"
              months={2}
              onChange={handleChange}
              direction={"horizontal"}
              // @ts-ignore: type definitions for react-date-range do not include the prop "showDateDisplay"
              showDateDisplay={false}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              twoStepChange={false}
              monthDisplayFormat={"MMMM yyyy"}
              weekdayDisplayFormat={"EEEEEE"}
            />
          </Grid>
          <Grid item xs="auto" className={classNames(classes.definedRange)}>
            <DefinedRange
              onChange={handleDefinedRangeChange}
              ranges={state}
              direction={"horizontal"}
              showDateDisplay={false}
              staticRanges={staticRanges}
            />
          </Grid>
        </Grid>
        <Grid container className={classNames(classes.input)} direction="row" alignItems="flex-end">
          <Grid item id="startTime" container direction="row" xs="auto">
            <Grid item xs={12}>
              <Typography variant="caption">Start</Typography>
            </Grid>
            <Grid item xs>
              <InputMask
                mask="9999-99-99 hh\:mm\:mm"
                value={moment(state[0].startDate).format(format)}
                formatChars={formatChars}
                onChange={(event) => handleChangeStartTime(event)}
              >
                {() => <TextField fullWidth={false} canError={false} />}
              </InputMask>
            </Grid>
          </Grid>
          <Grid item id="endTime" container direction="row" xs="auto">
            <Grid item xs={12}>
              <Typography variant="caption">End</Typography>
            </Grid>
            <Grid item xs>
              <InputMask
                mask="9999-99-99 hh\:mm\:mm"
                value={moment(state[0].endDate).format(format)}
                formatChars={formatChars}
                onChange={handleChangeEndTime}
              >
                {() => <TextField fullWidth={false} canError={false} />}
              </InputMask>
            </Grid>
          </Grid>
          {delta > 90 && (
            <Grid item xs="auto">
              <Stack mb={3} direction="row">
                <Typography style={{ color: "red" }} variant="caption">
                  {warningIcon}
                </Typography>
                <Typography
                  style={{ color: "red", transform: "translateY(2px)" }}
                  variant="caption"
                >
                  {` ${WARNING_TEXT}`}
                </Typography>
              </Stack>
            </Grid>
          )}
        </Grid>
        <Grid container className={`row ${classNames(classes.buttons)}`}>
          <Grid item container style={{ margin: "0px 0px 0px 8px" }}>
            <Button variant="knockout" onClick={handleClose} style={{ marginRight: "8px" }}>
              Cancel
            </Button>
            <Button variant="solid" color="primary" onClick={handleApply} disabled={delta > 90}>
              Apply
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
}
