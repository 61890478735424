import { Overrides } from "@material-ui/core/styles/overrides";

export const MuiButton: Overrides["MuiButton"] = {
  root: {
    borderRadius: 2,
  },

  // Flatten
  contained: {
    boxShadow: "none",
  },
};
