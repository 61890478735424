export enum Health {
  UNKNOWN = 0,
  HEALTHY,
  WARNING,
  ERROR,
  GERIATRIC,
}

export enum HealthIssue {
  OK = 0,
  NO_DATA,
  TOO_FAST,
  TOO_SLOW,
  OLD_DATA,
  HAS_WARN,
  HAS_ERR,
}
