import * as React from "react";
import { useCallback, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Box,
} from "@material-ui/core";
import { gql } from "@apollo/client";
import { Button, TextField } from "sigil";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { AsyncButton } from "modules/common/components/AsyncButton";
import { useInviteOrganizationMemberMutation } from "modules/common/mutations/SendOrganizationInviteMutation";
import { RoleSelect, RoleType } from "./RoleSelect";
import { InviteMemberDialog_organization } from "generated-gql-types/InviteMemberDialog_organization";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

export interface InviteMemberDialogProps {
  open: boolean;
  onClose: () => void;
  organization: InviteMemberDialog_organization;
}

const useStyles = makeStyles(() => {
  return {
    paper: {
      "& .MuiDialog-paper": {
        maxWidth: "650px",
      },
      "& .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2": {
        width: "100%",
        margin: "0px",
      },
    },
  };
});

function InviteMemberDialog(props: InviteMemberDialogProps) {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("member" as RoleType);
  const { onClose } = props;
  const isValid = email !== "";
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = useCallback(() => {
    setEmail("");
    setRole("member");
    onClose();
  }, [onClose]);
  const { organization } = props;
  const { enqueueSnackbar } = useSnackbar();
  const inviteMember = useInviteOrganizationMemberMutation();
  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    try {
      await inviteMember({
        email: email,
        organizationId: organization.id,
        isAdmin: role === "admin",
      });
      enqueueSnackbar(`Invitation sent to ${email}`, { variant: "success" });
      setIsSubmitting(false);
      setEmail("");
      setRole("member");
      return;
    } catch (e) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
      setIsSubmitting(false);
      return Promise.reject();
    }
  }, [email, organization, role, enqueueSnackbar, inviteMember]);

  const handleSubmitAndClose = useCallback(
    () => handleSubmit().then(() => handleClose()),
    [handleClose, handleSubmit]
  );

  const handleFormSubmit = useCallback(
    (e) => {
      e.preventDefault();
      handleSubmitAndClose();
    },
    [handleSubmitAndClose]
  );
  const styles = useStyles();
  return (
    <Dialog
      className={classNames(styles.paper)}
      open={props.open}
      onBackdropClick={handleClose}
      fullWidth={true}
      scroll="body"
    >
      <form onSubmit={handleFormSubmit}>
        <DialogTitle>Invite New Member</DialogTitle>
        <DialogContent>
          <Box pb={1}>
            <TextField
              label="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth={true}
              type="email"
              required={true}
              disabled={isSubmitting}
            />
          </Box>

          <Box pb={1}>
            <InputLabel>Role</InputLabel>
            <RoleSelect value={role} onChange={setRole} />
          </Box>
        </DialogContent>
        <DialogContent>
          <DialogActions>
            <Box flexGrow={1}>
              <Button variant="text" onClick={handleClose}>
                Cancel
              </Button>
            </Box>
            <AsyncButton
              variant="knockout"
              color="primary"
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting}
            >
              Send & Invite Another
            </AsyncButton>
            <AsyncButton
              variant="solid"
              color="primary"
              onClick={handleSubmitAndClose}
              disabled={!isValid || isSubmitting}
            >
              Send invitation
            </AsyncButton>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
}

export default createFragmentContainer(InviteMemberDialog, {
  organization: gql`
    fragment InviteMemberDialog_organization on Organization {
      id
    }
  `,
});
