export function mean<T>(collection: T[], fn: (item: T) => number) {
  const len = collection.length;
  if (len < 1) {
    return;
  }

  const sum = collection.reduce((agg, item) => agg + fn(item), 0);
  return sum / len;
}

export function mode<T>(collection: T[], fn: (item: T) => number) {
  let modeNum: number | undefined = undefined;
  const counts: Map<number, number> = new Map();

  collection.forEach((item) => {
    const val = fn(item);
    const count = (counts.get(val) || 0) + 1;
    counts.set(val, count);

    const topCount = modeNum === undefined ? 0 : (counts.get(modeNum) as number);
    if (count > topCount) {
      modeNum = val;
    }
  });

  return modeNum;
}
