import React, { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { RouteComponentProps } from "react-router-dom";
import { Grid, useMediaQuery } from "@mui/material";
import { Container } from "../../../components/Container";

import { SiteStore } from "modules/site-manager/stores";

import { AlertsSection_site } from "generated-gql-types/AlertsSection_site";
import { AlertSettingsSection_site } from "generated-gql-types/AlertSettingsSection_site";
import { AlertsSection } from "./AlertSection";
import { AlertSettingsSection } from "./AlertSettingsSection";
import { GetSiteSettings } from "generated-gql-types/GetSiteSettings";
import { useTheme } from "@mui/material/styles";
import { EnergySavingsSection, EnergySavingsSection_site } from "./EnergySavingsSection";
import { useSnackbar } from "notistack";
import { presentError } from "modules/site-manager/utils/errors";

const siteSettingsQuery = gql`
  query GetSiteSettings($siteId: ID!) {
    site: node(id: $siteId) {
      ... on Site {
        id
        ...AlertsSection_site
        ...EnergySavingsSection_site
        ...AlertSettingsSection_site
      }
    }
  }
  ${AlertsSection.fragments.site}
  ${EnergySavingsSection.fragments.site}
  ${AlertSettingsSection.fragments.site}
`;

interface SiteSettingsRouteProps {
  siteStore: SiteStore;
}

type Props = SiteSettingsRouteProps & RouteComponentProps;

export const SiteSettingsRoute = ({ siteStore }: Props) => {
  const siteId = siteStore.site.globalId;
  const { data, loading, error } = useQuery<GetSiteSettings>(siteSettingsQuery, {
    variables: { siteId },
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();

  // Effect 1: Handle data loading errors
  useEffect(() => {
    if (error) {
      enqueueSnackbar(presentError("Error loading site settings", error), {
        variant: "error",
      });
    }
  }, [error, enqueueSnackbar]);

  if (loading || error || !data || !data.site) {
    return null;
  }

  return (
    <Container paddingBottom={isMobile ? "64px" : "8px"}>
      <Grid item container xs={12} spacing={2}>
        <AlertsSection site={data.site as AlertsSection_site} />
        <EnergySavingsSection site={data.site as EnergySavingsSection_site} />
        <AlertSettingsSection site={data.site as AlertSettingsSection_site} />
      </Grid>
    </Container>
  );
};
