import * as React from "react";
import { gql, useQuery } from "@apollo/client";
import { RouteComponentProps } from "react-router-dom";
import { Grid, useMediaQuery } from "@mui/material";
import { Container } from "../../../components/Container";

import { SiteStore } from "modules/site-manager/stores";

import { GetSiteConfiguration } from "generated-gql-types/GetSiteConfiguration";
import { MotorSection_site } from "generated-gql-types/MotorSection_site";
import { SupervisorSection_site } from "generated-gql-types/SupervisorSection_site";
import { SupervisorSection } from "./SupervisorSection";
import { MotorSection } from "./MotorSection";
import { useTheme } from "@mui/material/styles";

const siteConfigurationQuery = gql`
  query GetSiteConfiguration($siteId: ID!) {
    site: node(id: $siteId) {
      ... on Site {
        id
        ...SupervisorSection_site
        ...MotorSection_site
      }
    }
  }
  ${SupervisorSection.fragments.site}
  ${MotorSection.fragments.site}
`;

interface SiteConfigRouteProps {
  siteStore: SiteStore;
}
type Props = SiteConfigRouteProps & RouteComponentProps;
export const SiteConfigRoute = ({ siteStore }: Props) => {
  const siteId = siteStore.site.globalId;
  const { data, loading, error } = useQuery<GetSiteConfiguration>(siteConfigurationQuery, {
    variables: { siteId },
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (loading || error || !data || !data.site) {
    return null;
  }
  return (
    <Container paddingBottom={isMobile ? "58px" : "8px"}>
      <Grid item container xs={12} spacing={2}>
        <SupervisorSection site={data.site as SupervisorSection_site} />
        <MotorSection site={data.site as MotorSection_site} />
      </Grid>
    </Container>
  );
};
