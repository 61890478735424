import * as React from "react";
import { Observer, observer } from "mobx-react";
import { Box } from "@mui/material";
import { InlineEditableTypography } from "modules/common/components/EditableTypography";
import { Motor, Site } from "modules/site-manager/models";
import { WithSiteRole } from "modules/site-manager/components/WithSiteRole";
import { action } from "mobx";
import { marshalNumberId } from "modules/common/utils/relay";
import { useUpdateMotorNameMutation } from "modules/common/mutations/UpdateMotorNameMutation";
import { useSnackbar } from "notistack";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { MotorStatsList_node_Site_motors } from "generated-gql-types/MotorStatsList";

export type EditableMotorTitleProps = {
  motor: Motor;
  motorNode: MotorStatsList_node_Site_motors;
};

const EditableMotorTitleComponent = ({ motor, motorNode }: EditableMotorTitleProps) => {
  const renameMotor = useUpdateMotorNameMutation();
  const { enqueueSnackbar } = useSnackbar();
  const site = motor.site || ({} as Site);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const handleMotorRename = action(async (newName: string) => {
    motor.assign("name", newName);
    try {
      await renameMotor({ motorId: motorNode.id, name: newName });
      enqueueSnackbar("Saved motor", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("There was an error saving the motor", { variant: "error" });
    }
  });
  return (
    <Box mb={0}>
      <WithSiteRole siteId={marshalNumberId("site", parseInt(site.id, 10))}>
        {(role) => (
          <Observer>
            {() => (
              <InlineEditableTypography
                variant={matches ? "title-md" : "title-sm"}
                value={motor.displayName}
                onSubmit={handleMotorRename}
                disabled={!role.isAdmin}
              />
            )}
          </Observer>
        )}
      </WithSiteRole>
    </Box>
  );
};

export const EditableMotorTitle = observer(EditableMotorTitleComponent) as (
  props: EditableMotorTitleProps
) => any;
