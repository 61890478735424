import { SimpleStatProps } from "modules/site-manager/routes/Site/SiteSummary/modules/SimpleStat";
import { SiteSummary_node_Site } from "generated-gql-types/SiteSummary";
import { useEffect, useState } from "react";
import { Layout, Layouts } from "react-grid-layout";
import { v4 as uuid } from "uuid";

export type ModuleProps =
  | {
      id: string;
      module: "variables";
    }
  | {
      id: string;
      module: "motors";
    }
  | {
      id: string;
      module: "simplestat";
      props: SimpleStatProps;
    };

// Good first stab at a type to serialize, TODO: actually serialize it?
export type SummaryLayout = {
  layouts: Layouts;
  modules: ModuleProps[];
};

export const addModule = (
  layouts: SummaryLayout,
  module: "simplestat",
  props: SimpleStatProps
): SummaryLayout => {
  const id = uuid();
  const layout = { i: id, x: 0, y: 0, w: 1, h: 1, isResizable: true } as Layout;
  const p = { id, module, props } as ModuleProps;
  return {
    layouts: Object.assign({}, layouts.layouts, { lg: [layout, ...layouts.layouts["lg"]] }),
    modules: [p, ...layouts.modules],
  };
};

export const removeModule = (layouts: SummaryLayout, uuid: string): SummaryLayout => {
  return {
    layouts: Object.assign({}, layouts.layouts, {
      lg: layouts.layouts.lg.filter((l) => l.i !== uuid),
    }),
    modules: layouts.modules.filter((m) => m.id !== uuid),
  };
};

export const useLayouts = (site?: SiteSummary_node_Site, edit?: boolean) => {
  const [layout, setLayout] = useState<SummaryLayout | null>(null);
  useEffect(() => {
    const motors = site
      ? [...site.motors.filter((motor) => motor.group === null), ...site.motorGroups]
      : [];
    const motorCount = motors.length;
    if (site && !site.layout) {
      const variableCount = site.supervisors.filter(
        (spv) => spv.variables.filter((v) => v.priority === "IMPORTANT").length > 0
      ).length;
      const lg: Layout[] = [],
        md: Layout[] = [],
        sm: Layout[] = [],
        xs: Layout[] = [],
        xxs: Layout[] = [];
      const modules: ModuleProps[] = [];
      let offset = 0;
      if (variableCount > 0) {
        const id = uuid();
        const hero = { i: id, x: 0, y: offset, isResizable: false };
        lg.push({ ...hero, w: 6, h: 1 });
        md.push({ ...hero, w: 6, h: 1 });
        sm.push({ ...hero, w: 3, h: 1 });
        xs.push({ ...hero, w: 2, h: 1 });
        xxs.push({ ...hero, w: 2, h: 1 });
        modules.push({ id, module: "variables" });
        offset += 1;
      }
      if (motorCount > 0) {
        const id = uuid();
        const motors = { i: id, x: 0, y: offset, isResizable: false };
        lg.push({ ...motors, w: 6, h: Math.ceil(motorCount / 4) * 2 });
        md.push({ ...motors, w: 6, h: Math.ceil(motorCount / 4) * 2 });
        sm.push({ ...motors, w: 4, h: Math.ceil(motorCount / 3) * 2 });
        xs.push({ ...motors, w: 4, h: Math.ceil(motorCount / 2) * 2 });
        xxs.push({ ...motors, w: 2, h: motorCount * 2 });
        modules.push({ id, module: "motors" });
      }
      setLayout({ layouts: { lg, md, sm, xs, xxs }, modules });
    }
    if (site && site.layout) {
      // update motor widget height like above.
      let summaryLayout = site.layout as SummaryLayout;
      const motorModule = summaryLayout.modules.filter((m) => m.module === "motors")[0];
      if (motorModule) {
        const uuid = motorModule.id;
        const updateLayout = (id: string, layout: Layout, height: number): Layout =>
          id === layout.i ? { ...layout, h: height } : layout;
        const layouts = summaryLayout.layouts;
        summaryLayout = {
          layouts: {
            lg: layouts["lg"].map((l) => updateLayout(uuid, l, Math.ceil(motorCount / 4) * 2)),
            md: layouts["md"].map((l) => updateLayout(uuid, l, Math.ceil(motorCount / 4) * 2)),
            sm: layouts["sm"].map((l) => updateLayout(uuid, l, Math.ceil(motorCount / 3) * 2)),
            xs: layouts["xs"].map((l) => updateLayout(uuid, l, Math.ceil(motorCount / 2) * 2)),
            xxs: layouts["xxs"].map((l) => updateLayout(uuid, l, motorCount * 2)),
          },
          modules: [...summaryLayout.modules],
        };
      }
      setLayout(summaryLayout);
    }
  }, [site, edit]);
  return layout;
};
