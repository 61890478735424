import * as React from "react";
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteOwnerState,
  AutocompleteRenderGetTagProps,
  AutocompleteValue,
  Checkbox,
  FilterOptionsState,
  TextField,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { StyledTag } from "./StyledTag";
import { useTheme } from "@mui/material/styles";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface MultiSelectAutocompleteOptionType {
  id: string;
  label: string;
  filter: string;
  group?: string;
  disabled?: boolean;
}

export type MultiSelectAutocompleteAutocompleteProps = {
  id: string;
  options: MultiSelectAutocompleteOptionType[];
  onChange: (
    event: React.SyntheticEvent,
    value: AutocompleteValue<MultiSelectAutocompleteOptionType, true, true, false>,
    reason: AutocompleteChangeReason
  ) => void;
  value: MultiSelectAutocompleteOptionType[];
  title: string;
  autoFocus?: boolean;
  filterOptions?: (
    options: MultiSelectAutocompleteOptionType[],
    state: FilterOptionsState<MultiSelectAutocompleteOptionType>
  ) => MultiSelectAutocompleteOptionType[];
};

const renderTags = (
  value: MultiSelectAutocompleteOptionType[],
  getTagProps: AutocompleteRenderGetTagProps,
  ownerState: AutocompleteOwnerState<
    MultiSelectAutocompleteOptionType,
    true,
    undefined,
    undefined,
    "div"
  >
) => (
  <>
    {value.map(
      (option: MultiSelectAutocompleteOptionType, index: number) =>
        option && <StyledTag label={option.label} {...getTagProps({ index })} />
    )}
  </>
);

// TODO: this becomes unnecessary once the entire app migrates to MUI5 and its theming
// As it stands, the ThemeProvider is from MUI4, providing a MUI4 theme.
export const autocompleteStyles = () => ({
  autocomplete: {
    "& input, && label": {
      fontFamily: "Barlow",
      fontSize: "16px",
    },
  },
});

export const MultiSelectAutocomplete = (props: MultiSelectAutocompleteAutocompleteProps) => {
  const theme = useTheme();
  const classes = autocompleteStyles();

  return (
    <Autocomplete
      multiple
      autoComplete
      autoHighlight
      disableCloseOnSelect
      getOptionLabel={(option: MultiSelectAutocompleteOptionType) => option.label}
      groupBy={(option) => option?.group || ""}
      renderInput={(params) => (
        <TextField
          autoFocus={props.autoFocus}
          label={`${props.title}s`}
          placeholder={`Add ${props.title}...`}
          margin="dense"
          {...params}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ margin: 0, padding: 0, marginRight: 4 }}
            checked={selected}
            disabled={option.disabled || false}
          />
          <Typography
            fontSize={14}
            fontFamily="Barlow"
            color={option.disabled ? theme.palette.text.disabled : theme.palette.text.primary}
          >
            {option.label}
          </Typography>
        </li>
      )}
      filterOptions={props.filterOptions}
      selectOnFocus
      clearOnBlur={false}
      handleHomeEndKeys
      renderTags={renderTags}
      sx={classes.autocomplete}
      isOptionEqualToValue={(
        option: MultiSelectAutocompleteOptionType,
        value: MultiSelectAutocompleteOptionType
      ): boolean => option?.id === value?.id}
      {...props}
    />
  );
};
