import * as React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import {
  FormControl,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { createStyles, withStyles, WithStyles, makeStyles } from "@material-ui/core/styles";

import { descriptionExtraSmallStyles, Elevation } from "sigil";
import { CardTable } from "modules/common/components/CardTable";
import { PlaceholderAction } from "modules/common/components/PlaceholderAction";
import { YAxis } from "modules/charts/constants";
import {
  AnalysisField,
  AnalysisStore,
  CtrlVariableField,
  EnergyDataField,
  MotorOpField,
} from "modules/site-manager/stores";
import { MotorOp } from "modules/site-manager/models/motor-op";
import { CtrlVariable } from "modules/site-manager/models";
import { ExplorerFieldSelector, SelectionModifiers } from "./field-selector";
import { LogicExplorerStyles } from "./styles";
import { MotorOpStat } from "modules/site-manager/constants/motor-op";

const useSmolSelectStyles = makeStyles(() =>
  createStyles({
    select: {
      ...descriptionExtraSmallStyles,
      padding: "2px 11px 3px",
    },
    selectMenu: {
      boxShadow: Elevation[2],
      borderRadius: "2px",
    },
  })
);

function SmolSelect(props: MuiSelectProps) {
  const classes = useSmolSelectStyles();
  return (
    <MuiSelect
      MenuProps={{
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
        transformOrigin: { vertical: "top", horizontal: "left" },
        getContentAnchorEl: null,
        marginThreshold: -10000,
      }}
      classes={{
        ...classes,
      }}
      {...props}
    />
  );
}

interface ExplorerFieldManagerProps extends WithStyles<typeof LogicExplorerStyles> {
  analysisStore: AnalysisStore;
  onChangeCallback: () => void;
}

interface ExplorerFieldManagerState {
  isAdding: boolean;
}

@observer
export class ExplorerFieldManagerComponent extends React.Component<
  ExplorerFieldManagerProps,
  ExplorerFieldManagerState
> {
  state: ExplorerFieldManagerState = {
    isAdding: false,
  };

  selectedFieldColumns = [
    {
      key: "name",
      getCell: (field: AnalysisField) => field.name,
    },
    {
      key: "aggregation",
      getCell: (field: AnalysisField) => {
        const { analysisStore, onChangeCallback } = this.props;

        // average is undefined for bvs
        let hasMean = true;
        if (field instanceof CtrlVariableField && !field.ctrlVariable.logicPointJS.isAnalog) {
          hasMean = false;
        }
        const hasOnlyMean =
          field instanceof EnergyDataField ||
          (field instanceof MotorOpField && field.motorOp.op === MotorOpStat.TTPower);
        if (hasOnlyMean) {
          return (
            <Typography
              style={{
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400,
                padding: "2px 11px 3px",
              }}
            >
              Average
            </Typography>
          );
        }

        return (
          <FormControl>
            <SmolSelect
              value={field.aggregation}
              onChange={(e) => {
                analysisStore.setAggregation(field, e.target.value as string);
                onChangeCallback();
              }}
              fullWidth
            >
              <MenuItem value="mean" disabled={!hasMean || hasOnlyMean}>
                Average
              </MenuItem>
              {!hasOnlyMean && <MenuItem value="max">Maximum</MenuItem>}
              {!hasOnlyMean && <MenuItem value="min">Minimum</MenuItem>}
              {!hasOnlyMean && <MenuItem value="mode">Most Frequent</MenuItem>}
            </SmolSelect>
          </FormControl>
        );
      },
      width: 12,
    },
    {
      key: "axes",
      getCell: (field: AnalysisField) => {
        const { classes, analysisStore, onChangeCallback } = this.props;
        return (
          <span style={{ whiteSpace: "nowrap" }}>
            <Tooltip title="Left Axis" placement="top">
              <i
                className={classNames("fa", "fa-caret-square-left", classes.controlIcon, {
                  [classes.controlIconActive]: field.yAxis === YAxis.Left,
                })}
                onClick={() => {
                  analysisStore.setAxis(field, YAxis.Left);
                  onChangeCallback();
                }}
              />
            </Tooltip>
            &nbsp;
            <Tooltip title="Right Axis" placement="top">
              <i
                className={classNames("fa", "fa-caret-square-right", classes.controlIcon, {
                  [classes.controlIconActive]: field.yAxis === YAxis.Right,
                })}
                onClick={() => {
                  analysisStore.setAxis(field, YAxis.Right);
                  onChangeCallback();
                }}
              />
            </Tooltip>
          </span>
        );
      },
      width: 5,
    },
    {
      key: "toggle",
      getCell: (field: AnalysisField) => {
        const { classes, analysisStore, onChangeCallback } = this.props;
        return (
          <div className="text-right">
            <Tooltip title="Remove" placement="top">
              <i
                className={classNames("fa", "fa-times-circle", classes.controlIcon)}
                onClick={analysisStore.toggleField(field, onChangeCallback)}
              />
            </Tooltip>
          </div>
        );
      },
      width: 5,
    },
  ];

  getSelectedFields = () => this.props.analysisStore.selectedFields;

  getFieldKey = (field: AnalysisField) => field.id;

  toggleAdding = () => {
    const { isAdding } = this.state;
    this.setState({ isAdding: !isAdding });
  };

  handleCtrlVariableSelect = (ctrlVariable: CtrlVariable, modifiers: SelectionModifiers) => {
    const { analysisStore, onChangeCallback } = this.props;
    analysisStore.addCtrlVariableField(ctrlVariable);
    if (!modifiers.isMulti) {
      this.setState({ isAdding: false });
      analysisStore.setSelectionFilter();
      onChangeCallback();
    }
  };

  handleMotorOpSelect = (motorOp: MotorOp, modifiers: SelectionModifiers) => {
    const { analysisStore, onChangeCallback } = this.props;
    analysisStore.addMotorOpField(motorOp);
    if (!modifiers.isMulti) {
      this.setState({ isAdding: false });
      analysisStore.setSelectionFilter();
      onChangeCallback();
    }
  };

  render() {
    const { analysisStore } = this.props;
    const { isAdding } = this.state;
    return (
      <div>
        <CardTable
          getItems={this.getSelectedFields}
          columns={this.selectedFieldColumns}
          getRowKey={this.getFieldKey}
        />

        {isAdding ? (
          <ExplorerFieldSelector
            analysisStore={analysisStore}
            onCtrlVariableSelect={this.handleCtrlVariableSelect}
            onMotorOpSelect={this.handleMotorOpSelect}
            onClose={this.toggleAdding}
          />
        ) : (
          <PlaceholderAction action={this.toggleAdding}>
            <div className="p-1">
              <i className="fa fa-plus mr-1" />
              Add Metric
            </div>
          </PlaceholderAction>
        )}
      </div>
    );
  }
}

export const ExplorerFieldManager = withStyles(LogicExplorerStyles)(ExplorerFieldManagerComponent);
