import * as React from "react";
import { List, ListItem, Grid, Box, Fade, LinearProgress } from "@material-ui/core";
import { Map } from "mapbox-gl";
import { SmcMuiTheme } from "theme";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useState, useEffect, useRef } from "react";
import { SiteRow } from "./SiteRow";
import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { GetSites, GetSites_sites_nodes } from "generated-gql-types/GetSites";
import { SearchBox } from "./SearchBox";
import { debounce } from "lodash";
import { Pagination } from "./Pagination";
import { SiteMarkers } from "./SiteMarkers";

const query = gql`
  query GetSites($after: String, $before: String, $search: String) {
    sites(
      after: $after
      first: 20
      before: $before
      last: 20
      search: $search
      includeCount: true
    ) {
      nodes {
        ...SiteRow_site
      }
      totalCount
      pageInfo {
        ...Pagination_pageInfo
      }
    }
  }
  ${Pagination.fragments.pageInfo}
  ${SiteRow.fragments.site}
`;

const useListStyles = makeStyles((theme: typeof SmcMuiTheme) =>
  createStyles({
    list: {
      paddingBottom: theme.spacing(4),
    },
    container: {
      height: "100%",
      overflowY: "auto",
    },
    grid: {
      overflowY: "auto",
      overflowX: "hidden",
      height: "100vh",
      maskImage: `linear-gradient(to bottom, rgba(0,0,0,1) 95%, rgba(0,0,0,0));`,
    },
    item: {
      padding: "0",
    },
    link: {
      overflow: "hidden",
      borderBottom: "1px solid #F5F6F6",
      width: "100%",
    },
  })
);

type SitesListComponentProps = {
  map?: Map;
  loaded: boolean;
};
export const SitesList = ({ map, loaded }: SitesListComponentProps) => {
  const [page, setPage] = useState<{
    after?: string | null;
    before?: string | null;
    search?: string | null;
  }>();
  const [active, setActive] = useState<string | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const { data, loading } = useQuery<GetSites>(query, {
    fetchPolicy: "cache-and-network", // So that we refetch the query when someone adds a site
    variables: page,
  });
  const nodes = data?.sites?.nodes;
  const pageInfo = data?.sites?.pageInfo;
  const styles = useListStyles();
  // This is to control empty lists on state changes.
  const [sites, setSites] = useState<GetSites_sites_nodes[] | undefined>();
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current != null) ref.current.scrollTop = 0;
    if (nodes) setSites(nodes);
  }, [nodes]);
  return (
    <>
      <Grid container direction="column" wrap="nowrap" className={styles.container}>
        <Box minHeight={5}>
          <Fade
            in={loading}
            style={{ transitionDelay: loading ? "200ms" : "0ms" }}
            unmountOnExit={true}
          >
            <LinearProgress />
          </Fade>
        </Box>
        <SearchBox
          placeholder="Search site or location"
          change={debounce((search?: string) => {
            if (search?.length) {
              setPage({ search });
              setSearch(search);
            } else {
              setPage({});
              setSearch(null);
            }
          }, 200)}
        />
        <Grid item={true} ref={ref} className={styles.grid}>
          <List className={styles.list} disablePadding>
            {(sites ?? []).map((site) => (
              <ListItem
                key={site.id}
                disableGutters
                className={styles.item}
                onMouseOver={() => setActive(site.id)}
                onMouseLeave={() => setActive(null)}
              >
                <Link to={`sites/${atob(site.id).replace("site:", "")}`} className={styles.link}>
                  <SiteRow site={site} />
                </Link>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Pagination
          pageInfo={pageInfo}
          totalCount={data?.sites?.totalCount}
          next={(after: string | null) => setPage({ ...page, after, before: null })}
          prev={(before: string | null) => setPage({ ...page, after: null, before })}
        />
      </Grid>
      {map && (
        <SiteMarkers
          map={map}
          active={active}
          loaded={loaded}
          search={search}
          limit={data?.sites?.totalCount}
        />
      )}
    </>
  );
};
