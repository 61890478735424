import * as React from "react";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export interface ContainerProps {
  children?: React.ReactNode;
  paddingBottom?: string | number;
}

export const Container = ({ children, paddingBottom }: ContainerProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid container justifyContent="center" style={{ overflowY: "auto", width: "100%" }}>
      <Grid
        xs={isMobile ? 12 : 10}
        item
        container
        direction="column"
        justifyContent="center"
        style={{
          minWidth: "200px",
          maxWidth: "1200px",
          paddingTop: "8px",
          paddingRight: "8px",
          paddingLeft: "8px",
        }}
        pb={paddingBottom || 0}
      >
        {children}
      </Grid>
    </Grid>
  );
};
