import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { RouteComponentProps } from "react-router-dom";
import { Responsive, WidthProvider, Layouts } from "react-grid-layout";
import { Section } from "../SiteConfiguration/ConfigSection";
import { Box, Card, Container, Grid } from "@mui/material";
import { useQuery, gql } from "@apollo/client";
import { Site } from "modules/site-manager/models/site";
import {
  SORT_OPTIONS,
  SortOptionIndex,
  motorQuery,
  motorGroupQuery,
  MotorStatsList,
} from "./modules/MotorStatsList";
import { SiteMetricsCard } from "modules/site-manager/components/SiteMetricsCard";
import { CtrlVariableHero } from "modules/site-manager/components/CtrlVariableHero";
import { AlertBanner } from "./AlertBanner";
import { SiteSummary, SiteSummary_node_Site } from "generated-gql-types/SiteSummary";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { marshalNumberId } from "modules/common/utils/relay";
import { SimpleStat } from "./modules/SimpleStat";
import { useUpdateSiteLayoutMutation } from "modules/common/mutations/UpdateSiteLayoutMutation";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useLayouts, SummaryLayout, ModuleProps } from "./hooks/useLayouts";
import { ModuleForm } from "./modules/ModuleForm";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FirmwareBanner } from "./FirmwareBanner";
import { EnergySavingsBanner } from "./EnergySavingsBanner";
import { SearchBar } from "modules/site-manager/components/SearchBar";
import { useSnackbar } from "notistack";
import { presentError } from "modules/site-manager/utils/errors";

interface SSProps {
  site: Site;
}

interface Params {}

type Props = SSProps & RouteComponentProps<Params>;

const ResponsiveGridLayout = WidthProvider(Responsive);

const query = gql`
  query SiteSummary($siteId: ID!) {
    node(id: $siteId) {
      ... on Site {
        id
        layout
        viewerCanEditDashboards
        supervisors {
          id
          variables {
            id
            priority
          }
        }
        ...AlertBanner_site
        ...FirmwareBanner_site
        ...EnergySavingsBanner_site
        motors {
          id
          displayName
          driveSerialNumber
          group {
            id
          }
          ...MotorStatsList_motor
        }
        motorGroups {
          id
          displayName
          ...MotorStatsList_motorGroup
        }
      }
    }
  }
  ${AlertBanner.fragments.site}
  ${FirmwareBanner.fragments.site}
  ${EnergySavingsBanner.fragments.site}
  ${motorQuery}
  ${motorGroupQuery}
`;

const useGridOverrideStyles = makeStyles(() =>
  createStyles({
    "@global": {
      ".react-grid-item.react-grid-placeholder": {
        backgroundColor: "black",
        opacity: 0.05,
      },
    },
  })
);

const SiteSummaryRouteComponent = ({ site }: Props) => {
  useGridOverrideStyles();
  const [mutate, { loading: mutating }] = useUpdateSiteLayoutMutation();
  const siteId = marshalNumberId("site", parseInt(site.id, 10));
  const { data, loading, error } = useQuery<SiteSummary>(query, {
    variables: { siteId },
    pollInterval: 30000,
  });
  const edit =
    (data?.node as SiteSummary_node_Site)?.viewerCanEditDashboards &&
    window.location.search.includes("edit");
  const [searchFilter, setSearchFilter] = useState("");
  const [sortOptionIndex, setSortOptionIndex] = useState<number>(SortOptionIndex.SERIAL);
  const [sortAscending, setSortAscending] = useState(true);
  const layouts = useLayouts(data?.node as SiteSummary_node_Site, edit);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();

  // Effect 1: Handle data loading errors
  useEffect(() => {
    if (error) {
      enqueueSnackbar(presentError("Error loading site summary", error), {
        variant: "error",
      });
    }
  }, [error, enqueueSnackbar]);

  if (!data || loading || error || !layouts) {
    return null;
  }
  const apolloSite = data.node as SiteSummary_node_Site;

  // Unfortunately this needs to be here because we need to pass site
  // to support our older components. TODO: figure out a better way to handle this?
  const Module = (props: ModuleProps) => {
    let ret = null;
    switch (props.module) {
      case "variables":
        ret = <CtrlVariableHero site={site} />;
        break;
      case "motors":
        ret = (
          <MotorStatsList
            site={site}
            apolloSite={apolloSite}
            searchFilter={searchFilter}
            sortOption={SORT_OPTIONS[sortOptionIndex]}
            sortAscending={sortAscending}
          />
        );
        break;
      case "simplestat":
        ret = <SimpleStat {...props.props} site={apolloSite} id={props.id} editing={edit} />;
        break;
    }
    return ret;
  };

  const motors = layouts.modules.filter((m) => m.module === "motors");
  const modules = layouts.modules.filter((m) => m.module !== "motors");

  return (
    <Container
      style={{
        padding: "48px 8px 8px",
        maxWidth: "1200px",
        paddingBottom: isMobile ? "80px" : "8px",
      }}
    >
      <Grid item>{site ? <AlertBanner site={apolloSite} /> : ""}</Grid>
      <Grid item>{site ? <FirmwareBanner site={apolloSite} /> : ""}</Grid>
      <Grid item>{site ? <EnergySavingsBanner site={apolloSite} /> : ""}</Grid>
      {modules.length || edit ? (
        <Section title="Key Metrics">
          <Grid item>{edit ? <ModuleForm site={apolloSite} layouts={layouts} /> : ""}</Grid>
          <Grid item ml={-2} mr={-2} mt={-1}>
            {/* Motor Speedometers */}
            <ResponsiveGridLayout
              isResizable={edit}
              isDraggable={edit}
              className="layout"
              margin={[16, 16]}
              rowHeight={150}
              measureBeforeMount
              breakpoints={{ lg: 1200, md: 940, sm: 480, xs: 320, xxs: 0 }}
              cols={{ lg: 6, md: 6, sm: 3, xs: 2, xxs: 2 }}
              layouts={layouts.layouts}
              onLayoutChange={async (_, allLayouts: Layouts) => {
                // do not persist layout unless in edit mode
                if (!edit) {
                  return;
                }
                // This is the only place we actually make sure we're sending correct data.
                // TODO: type this on the backend.
                const layout: SummaryLayout = {
                  layouts: allLayouts,
                  modules: layouts.modules,
                };
                if (!mutating && !loading)
                  await mutate({
                    siteId: siteId,
                    layout,
                  });
              }}
            >
              {modules.map((i) => (
                <Box key={i.id}>
                  <Module {...i} />
                </Box>
              ))}
            </ResponsiveGridLayout>
          </Grid>
        </Section>
      ) : null}
      {motors?.length || searchFilter ? (
        <Section title="Motors">
          <Card style={{ padding: "8px", marginBottom: "12px" }}>
            <SearchBar
              sortOptions={SORT_OPTIONS}
              onSearch={(query: string) => {
                setSearchFilter(query);
              }}
              onSort={(index: number, sortAscending: boolean) => {
                setSortOptionIndex(index);
                setSortAscending(sortAscending);
              }}
              searchOptions={
                (data?.node as SiteSummary_node_Site).motors?.map((motor) => ({
                  value: motor.id,
                  label:
                    motor.displayName === motor.driveSerialNumber
                      ? motor.displayName
                      : `${motor.displayName} (${motor.driveSerialNumber})`,
                })) || []
              }
            />
          </Card>
          <Grid item>
            {motors.map((i) => (
              <Box key={i.id}>
                <Module {...i} />
              </Box>
            ))}
          </Grid>
        </Section>
      ) : null}
      <Grid item>
        {/* CtrlVariable table */}
        <Section title="All Metrics">
          <SiteMetricsCard site={site} />
        </Section>
      </Grid>
    </Container>
  );
};
export const SiteSummaryRoute = observer(SiteSummaryRouteComponent);
