import { gql } from "@apollo/client";
import { UpdateBaselineMotorInput } from "generated-gql-types/globalTypes";
import { UpdateBaselineMotor } from "generated-gql-types/UpdateBaselineMotor";
import { composeMutationHook } from "./helpers";

export const BASELINE_MOTOR_INPUT_FRAGMENT = gql`
  fragment BaselineMotor on BaselineMotor {
    id
    voltage
    maxSpeed
    motorPower
    efficiencyType
    applicationType
    tonnage
    replacedAt
  }
`;

const updateBaselineMotorMutation = gql`
  ${BASELINE_MOTOR_INPUT_FRAGMENT}
  mutation UpdateBaselineMotor($input: UpdateBaselineMotorInput!) {
    updateBaselineMotor(input: $input) {
      baselineMotor {
        ...BaselineMotor
      }
    }
  }
`;

export const useUpdateBaselineMotorMutation = composeMutationHook<
  UpdateBaselineMotor,
  UpdateBaselineMotorInput
>(updateBaselineMotorMutation, {
  refetchQueries: ["MotorSelection", "MotorEnergySavings"],
});
