import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import { Colors } from "../../utils/color";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes?: Styles;
}

type MergeElementProps<T extends React.ElementType, P extends object = {}> = Omit<
  React.ComponentPropsWithRef<T>,
  keyof P
> &
  P;

type ToggleProps = MergeElementProps<"switch", Props>;

export const Toggle = React.forwardRef((props: ToggleProps, ref: React.Ref<any>) => {
  const styles = useToggleStyles();
  const { focusVisible, ...classes } = styles;
  return (
    <Switch
      focusVisibleClassName={focusVisible}
      disableRipple
      classes={classes}
      ref={ref}
      {...props}
    />
  );
});

const useToggleStyles = makeStyles(() =>
  createStyles({
    disabled: {
      "& + $track": {
        backgroundColor: `${Colors.white} !important`,
        border: "1px solid #F0F0F0 !important",
        opacity: "1 !important",
      },
      "&$checked": {
        "& + $track": {
          backgroundColor: `${Colors.salt} !important`,
        },
      },
      "& $thumb": {
        background: Colors.silver,
      },
    },
    root: {
      width: 40,
      height: 24,
      padding: 0,
      display: "flex",
      borderRadius: 12,
      boxSizing: "border-box",
    },
    track: {
      backgroundColor: Colors.white,
    },
    switchBase: {
      color: Colors.ash,
      "& + $track": {
        border: "1px solid #D3D4D6",
        borderRadius: 12,
        height: "auto",
        opacity: "initial",
      },
      "&$checked": {
        "& $thumb": {
          right: "9px",
        },
        color: Colors.white,
        "& + $track": {
          backgroundColor: Colors.wave,
          border: "none",
          borderRadius: 12,
          opacity: 1,
        },
        "&$focusVisible $thumb": {
          border: "6px solid #fff",
        },
      },
    },
    thumb: {
      position: "relative",
      bottom: 5,
      right: 5,
      boxShadow: "0px 2px 5px rgba(27, 108, 226, 0.06)",
      borderRadius: 8,
      height: 16,
      width: 16,
    },
    checked: {},
    focusVisible: {},
  })
);
