import * as React from "react";
import { observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import { WithSnackbarProps, withSnackbar } from "notistack";
import { PrimaryCard } from "modules/common/components/PrimaryCard";
import { newAlertPresenter } from "modules/site-manager/presenters";
import { AlertStatusBanner, AlertTriggeredMoment } from "../../components";
import { Box, Table, TableCell, TableBody, TableRow } from "@mui/material";
import { AlertDetailsProps } from "../types";

function Component({ alert, enqueueSnackbar }: AlertDetailsProps & WithSnackbarProps) {
  const alertPresenter = newAlertPresenter(alert);

  try {
    return (
      <PrimaryCard>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ fontFamily: "Barlow" }}>Status</TableCell>
              <TableCell>
                <AlertStatusBanner status={alert.status} label={alertPresenter.status} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontFamily: "Barlow", verticalAlign: "top" }}>
                Triggered
              </TableCell>
              <TableCell>
                <AlertTriggeredMoment alert={alert} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontFamily: "Barlow", verticalAlign: "top" }}>
                Description
              </TableCell>
              <TableCell>
                <Box style={{ fontFamily: "Barlow", maxWidth: "800px" }}>
                  {alertPresenter.description}
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </PrimaryCard>
    );
  } catch (exception) {
    enqueueSnackbar("There was an error displaying the alert.", {
      variant: "error",
    });
    return <Redirect to="/alerts" />;
  }
}

export const AlertDetailsCard = withSnackbar(observer(Component));
