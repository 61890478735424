import { CircularProgress, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Container } from "../Container";
import { useTheme } from "@mui/material/styles";

export interface LeftAlignedHeaderProps {
  children?: React.ReactNode;
  loading: boolean;
  title: string | JSX.Element;
}

export const LeftAlignedHeader = ({ children, loading, title }: LeftAlignedHeaderProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid
      item
      container
      xs={12}
      justifyContent="center"
      sx={{ backgroundColor: "white", fontFamily: "Barlow" }}
    >
      <Container>
        <Grid item container xs={12} sm={10} justifyContent="flex-start">
          <Grid item container spacing={1} ml={0} mr={1} alignItems="center">
            <Typography
              variant="h2"
              fontFamily="Barlow"
              fontSize={isMobile ? "24px" : "40px"}
              fontWeight={600}
            >
              {title}
            </Typography>
            {loading && <CircularProgress size={28} />}
          </Grid>
          <Grid item container xs={12} md={10} justifyContent="flex-start" pt={1}>
            {children}
          </Grid>
        </Grid>
      </Container>
      <Grid item xs={12} justifyContent="stretch">
        <Divider />
      </Grid>
    </Grid>
  );
};
