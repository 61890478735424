import * as React from "react";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Button, TextField, TitleSmall } from "sigil";

import { AsyncButton } from "modules/common/components/AsyncButton";
import { UploadFlowDialog_device } from "generated-gql-types/UploadFlowDialog_device";
import { UploadDeviceLogicFlowInput } from "generated-gql-types/globalTypes";

type FileInputProps = {
  value: File | null;
  onChange: (f: File | null) => void;
  accept?: string;
  helperText?: string;
};

function FileInput(props: FileInputProps) {
  return (
    <label style={{ display: "flex" }}>
      <input
        style={{ display: "none" }}
        type="file"
        accept=".smcp"
        onChange={(e) => {
          props.onChange(e.target.files![0]);
        }}
      />
      <Button color="plain">Upload&nbsp;File</Button>
      <Box display="inline-block" width="8px" />
      <TextField
        placeholder="Choose a file"
        helperText={props.helperText}
        value={props.value?.name ?? ""}
        readOnly
        style={{ pointerEvents: "none" }}
      />
    </label>
  );
}

function useUploadDeviceFlowMutation() {
  const [mutate] = useMutation(gql`
    mutation UploadDeviceFlow($input: UploadDeviceLogicFlowInput!) {
      uploadDeviceLogicFlow(input: $input) {
        device {
          id
        }
      }
    }
  `);
  return (input: UploadDeviceLogicFlowInput) => mutate({ variables: { input } });
}

type UploadFlowDialogProps = {
  open: boolean;
  onClose: () => void;
  device: UploadFlowDialog_device;
};

function UploadFlowDialogComponent(props: UploadFlowDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const uploadFlow = useUploadDeviceFlowMutation();

  const [file, setFile] = useState<File | null>(null);

  const handleSubmit = async () => {
    try {
      await uploadFlow({
        deviceId: props.device.id,
        flow: file,
      });
      enqueueSnackbar("Saved", { variant: "success" });
      props.onClose();
    } catch {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle disableTypography>
        <TitleSmall>{`Load control logic for ${props.device.displayName}`}</TitleSmall>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <FileInput
            value={file}
            onChange={setFile}
            accept=".smcp"
            helperText="Upload a .smcp file compiled from SMCUI"
          />
        </DialogContentText>
      </DialogContent>

      <DialogActions style={{ padding: 16 }}>
        <Button variant="knockout" size="medium" onClick={props.onClose}>
          Cancel
        </Button>
        <AsyncButton
          variant="solid"
          color="primary"
          onClick={handleSubmit}
          disabled={file === null}
        >
          Load
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
}

export const UploadFlowDialog = createFragmentContainer(UploadFlowDialogComponent, {
  device: gql`
    fragment UploadFlowDialog_device on Device {
      id
      displayName
    }
  `,
});
