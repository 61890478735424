import * as React from "react";
import { Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FormikSubmitButton, FormikTextField } from "modules/common/components/MuiFormik";
import { useChangePasswordMutation } from "modules/common/mutations/ChangePasswordMutation";
import { useSnackbar } from "notistack";

const formSchema = Yup.object({
  oldPassword: Yup.string().required("Required"),
  newPassword: Yup.string().min(8, "Password must be at least 8 characters").required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Required"),
});

export function PasswordForm() {
  const changePassword = useChangePasswordMutation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{ oldPassword: "", newPassword: "", confirmPassword: "" }}
      validationSchema={formSchema}
      onSubmit={async (values, actions) => {
        try {
          await changePassword(values);
          enqueueSnackbar("Password changed", { variant: "success" });
          actions.resetForm();
        } catch (_) {
          enqueueSnackbar("Something went wrong", { variant: "error" });
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      <Form>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <FormikTextField
              label="Old password"
              name="oldPassword"
              type="password"
              required={true}
              fullWidth={true}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <FormikTextField
              label="New password"
              name="newPassword"
              type="password"
              required={true}
              fullWidth={true}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <FormikTextField
              label="Confirm new password"
              name="confirmPassword"
              type="password"
              required={true}
              fullWidth={true}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <FormikSubmitButton>Change password</FormikSubmitButton>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}
