import * as React from "react";
import { List, ListItemText, Grid, ListItem } from "@mui/material";
import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { NavLink, Route, RouteComponentProps, Switch } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";

import { Colors } from "sigil";
import { OrganizationsMenu } from "generated-gql-types/OrganizationsMenu";
import { ListItemNavLink } from "modules/common/components/ListItemNavLink";
import { AccountSettingsRoute } from "./AccountSettings";
import {
  OrganizationMembersRoute,
  OrganizationSharingRoute,
  AlertSettingsRoute,
  SitesRoute,
} from "./OrganizationSettings";
import { PasswordSettingsRoute } from "./PasswordSettings";
import { NotificationSettingsRoute } from "./NotificationSettings";
import { FontIcon } from "modules/common/components/FontIcon";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Container: React.FC = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container justifyContent="center" style={{ overflowY: "auto", width: "100%" }}>
      <Grid
        xs={12}
        lg={10}
        item
        container
        justifyContent="center"
        style={{
          minWidth: "150px",
          maxWidth: "100%",
          overflowY: "auto",
          paddingTop: isMobile ? "8px" : "48px",
          paddingLeft: "8px",
          paddingRight: "8px",
          paddingBottom: "8px",
          marginBottom: isMobile ? "80px" : 0,
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

const Sidebar: React.FC = ({ children }) => (
  <Grid item style={{ minWidth: "110px", maxWidth: "230px", paddingRight: "16px" }}>
    {children}
  </Grid>
);

const Main: React.FC = ({ children }) => (
  <Grid item xs={12} sm={8}>
    {children}
  </Grid>
);

const SettingsMenu = () => (
  <Route>
    {({ match }: RouteComponentProps<{}>) => (
      <List
        dense={true}
        component="nav"
        subheader={<Typography variant="h5">My Settings</Typography>}
      >
        <ListItemNavLink to={`${match!.path}/account`}>
          <FontIcon name="user" shrink={true} />
          <ListItemText disableTypography={true} primary="Account" />
        </ListItemNavLink>

        <ListItemNavLink to={`${match!.path}/password`}>
          <FontIcon name="key" shrink={true} />
          <ListItemText disableTypography={true} primary="Password" />
        </ListItemNavLink>

        <ListItemNavLink to={`${match!.path}/notifications`} style={{ padding: "3px 16px" }}>
          <FontIcon name="bell" shrink={true} />
          <ListItemText disableTypography={true} primary="Notifications" />
        </ListItemNavLink>
      </List>
    )}
  </Route>
);

const AdminSubNavLink = styled(NavLink)(({ theme }) => ({
  display: "list-item",
  listStyleType: "disc",
  marginLeft: 14,
  color: theme.palette.text.hint,
  "&:hover, &:focus": {
    color: Colors.rustLight,
  },
  "&.active": {
    color: theme.palette.primary.main,
  },
}));

const OrganizationsMenuComponent = () => {
  const { data, loading, error } = useQuery<OrganizationsMenu>(gql`
    query OrganizationsMenu {
      viewer {
        id
        organizations(first: 500) {
          nodes {
            id
            name
            viewerIsAdmin
          }
        }
      }
    }
  `);

  if (error) {
    throw error;
  } else if (loading || !data) {
    return null;
  }

  const myOrganizations = data.viewer.organizations.nodes;

  return (
    <Route>
      {({ match }: RouteComponentProps<{}>) => (
        <List component="nav" subheader={<Typography variant="h5">My Organizations</Typography>}>
          {myOrganizations.map((org) => {
            const orgSettingsPath = `${match!.path}/organization/${org.id}`;
            return (
              <React.Fragment key={org.id}>
                <ListItemNavLink exact={false} to={orgSettingsPath}>
                  <ListItemText disableTypography={true} primary={org.name} />
                </ListItemNavLink>
                {org.viewerIsAdmin && (
                  <Route path={orgSettingsPath}>
                    <List disablePadding dense style={{ marginLeft: 4 }}>
                      <ListItem>
                        <AdminSubNavLink to={`${orgSettingsPath}/sites`}>Sites</AdminSubNavLink>
                      </ListItem>
                      <ListItem>
                        <AdminSubNavLink to={`${orgSettingsPath}/members`}>Members</AdminSubNavLink>
                      </ListItem>
                      <ListItem>
                        <AdminSubNavLink to={`${orgSettingsPath}/alerts`}>
                          Alert Settings
                        </AdminSubNavLink>
                      </ListItem>
                      <ListItem>
                        <AdminSubNavLink to={`${orgSettingsPath}/sharing`}>
                          Access Sharing
                        </AdminSubNavLink>
                      </ListItem>
                    </List>
                  </Route>
                )}
              </React.Fragment>
            );
          })}
        </List>
      )}
    </Route>
  );
};

export function SettingsRoute({ match }: RouteComponentProps<{}>) {
  return (
    <Container>
      <Sidebar>
        <SettingsMenu />
        <OrganizationsMenuComponent />
      </Sidebar>
      <Main>
        <Switch>
          <Route path={`${match.path}/account`} component={AccountSettingsRoute} />
          <Route path={`${match.path}/password`} component={PasswordSettingsRoute} />
          <Route path={`${match.path}/notifications`} component={NotificationSettingsRoute} />
          <Route path={`${match.path}/organization/:orgId/sites`} component={SitesRoute} />
          <Route
            path={`${match.path}/organization/:orgId/members`}
            component={OrganizationMembersRoute}
          />
          <Route path={`${match.path}/organization/:orgId/alerts`} component={AlertSettingsRoute} />
          <Route
            path={`${match.path}/organization/:orgId/sharing`}
            component={OrganizationSharingRoute}
          />
          <Redirect
            from={`${match.path}/organization/:orgId`}
            to={`${match.path}/organization/:orgId/sites`}
          />
          <Redirect to={`${match.path}/account`} />
        </Switch>
      </Main>
    </Container>
  );
}
