import * as React from "react";
import { DocumentNode } from "graphql";

// FragmentMap is a map of propName -> gql fragment
interface FragmentMap {
  [K: string]: DocumentNode;
}

// FragmentContainer is a component with a static fragment map property
type FragmentContainer<P, F extends FragmentMap> = React.ComponentType<P> & {
  fragments: F;
};

// createFragmentContainer returns a FragmentContainer where the keys of the
// associated fragment map are a subset of the keys of the component props
export function createFragmentContainer<P, K extends Extract<keyof P, string>>(
  Component: React.ComponentType<P>,
  fragments: Pick<FragmentMap, K>
): FragmentContainer<P, Pick<FragmentMap, K>> {
  let Container: React.ComponentType<P> = (props: P) => {
    // TODO explore data masking
    return <Component {...props} />;
  };

  const name = Component.displayName || Component.name;
  Container.displayName = `FragmentContainer(${name})`;

  return Object.assign(Container, { fragments });
}
