import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import { ToggleButton, ToggleButtonGroup, ToggleButtonProps } from "@material-ui/lab";
import { Colors } from "sigil";

export const ChonkToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    // separate buttons
    // margin: 16,
    marginLeft: 8,
    marginRight: 8,

    // unstick buttons from each other
    "&:not(:first-child)": {
      marginLeft: 8,
      borderLeft: `1px solid ${Colors.silver}`,
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      marginLeft: 0,
      borderRadius: theme.shape.borderRadius,
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
}))(ToggleButtonGroup);

export const ChonkToggleButton = withStyles({
  root: {
    padding: "16px 24px",

    // make all buttons equal sized
    flex: "1 1 0px",

    // align label with top left
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",

    // selection effect
    border: `1px solid ${Colors.silver}`,
    "&$selected": {
      borderColor: Colors.wave,
      boxShadow: `inset 0 0 0 1px ${Colors.wave}`,
      backgroundColor: Colors.waveLightest,
      "&:hover": {
        backgroundColor: Colors.waveLightest,
      },
    },
    "&:focus": {
      outline: "none",
    },
  },
  label: {
    // leave label text alone
    display: "block",
    textTransform: "none",
    textAlign: "left",
  },
  selected: {},
})((props: ToggleButtonProps) => <ToggleButton disableRipple disableTouchRipple {...props} />);
