import * as React from "react";
import { useQuery, gql } from "@apollo/client";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { AlertDetailsPage } from "./AlertDetailsPage";
import { AlertDetail, AlertDetail_alert_Alert } from "generated-gql-types/AlertDetail";
import { ModelProvider } from "modules/site-manager/components/ModelProvider";
import { Site } from "modules/site-manager/models";
import { SiteStore } from "modules/site-manager/stores";
import { unmarshalNumberId } from "modules/common/utils/relay";
import { injectStores, APIStore } from "modules/common/stores";

const ALERT_DETAILS_QUERY = gql`
  query AlertDetail($alertId: ID!) {
    alert: node(id: $alertId) {
      id
      ... on Alert {
        cause {
          __typename
          ... on AlertCauseCascade {
            ctrlVariable {
              id
              displayName
              bacnetUnitID
              faultPriority
              faultTitle
              faultDetail
              zone
              logicPoint {
                name
              }
            }
            supervisor {
              id
              displayName
            }
            logicPointValue {
              analogValue
              booleanValue
            }
          }
          ... on AlertCauseMotorErrors {
            motor {
              id
              displayName
            }
            error
            errorCount
          }
          ... on AlertCauseMotorWarnings {
            motor {
              id
              displayName
            }
            warning
            warningCount
          }
          ... on AlertCauseMotorConnectivity {
            motor {
              id
              displayName
            }
          }
          ... on AlertCauseMotorSpeed {
            motor {
              id
              displayName
            }
            currentSpeed
            requestedSpeed
          }
          ... on AlertCauseMotorLowTorque {
            motor {
              id
              displayName
            }
          }
          ... on AlertCauseMotorSeizure {
            motor {
              id
              displayName
            }
          }
          ... on AlertCauseSupervisorConnectivity {
            supervisor {
              id
              displayName
            }
          }
        }
        site {
          id
          name
          address
          viewerIsAdmin
          organization {
            id
            name
          }
        }
        history {
          time
          status
          user {
            id
            fullName
            # moar fields
          }
        }
        status
        canBeAcked
        canBeClosed
        isClosable
        createdAt
        updatedAt
        closedAt
        originKey
      }
    }
  }
`;

type AlertRouteProps = {
  apiStore: APIStore;
} & RouteComponentProps<{ alert_id: string }> &
  WithSnackbarProps;

export function Component(props: AlertRouteProps) {
  const { data, loading, error } = useQuery<AlertDetail>(ALERT_DETAILS_QUERY, {
    variables: {
      alertId: props.match.params.alert_id,
    },
  });
  if (error || (!loading && !data)) {
    props.enqueueSnackbar("There was an error fetching details for the alert.", {
      variant: "error",
    });
    return <Redirect to="/alerts" />;
  }
  if (loading || !data || !data.alert) {
    return null;
  }
  const alert = data.alert as AlertDetail_alert_Alert;

  return (
    <React.Fragment>
      {/* FIXME this is here to get access to the alert supervisor, motor, ctrlvar and associated
          models. They should be fetched as part of the graphql query. */}
      <ModelProvider
        modelType={Site}
        id={`${unmarshalNumberId(alert.site.id)}`}
        include={["controllers", "controllers.variables", "motors"]}
        render={(providedProps) => {
          const { model: site, loading: loadingSite } = providedProps;
          if (!loadingSite && !site) {
            props.enqueueSnackbar(
              "The site for this alert could not be found." +
                " You may not be allowed to access it.",
              { variant: "error" }
            );
            return <Redirect to="/alerts" />;
          }
          if (loadingSite || !site) {
            return null;
          }
          const siteStore = new SiteStore(site);
          return <AlertDetailsPage alert={alert} siteStore={siteStore} {...props} />;
        }}
      />
    </React.Fragment>
  );
}

export const AlertRoute = withSnackbar(injectStores("api")(Component));
