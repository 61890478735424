import * as React from "react";
import { observer } from "mobx-react";
import memoizeOne from "memoize-one";
import moment from "moment";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import * as Sentry from "@sentry/react";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { AlertDetail_alert_Alert } from "generated-gql-types/AlertDetail";
import { SiteStore, AnalysisStore } from "modules/site-manager/stores";
import { APIStore, injectStores } from "modules/common/stores";
import { LogicPoint } from "modules/site-manager/utils/logic-point";
import { MotorOpStat } from "modules/site-manager/constants/motor-op";
import { MotorOp } from "modules/site-manager/models";
import { unmarshalStringId, unmarshalNumberId } from "modules/common/utils/relay";
import { DURATION } from "modules/common/utils/time";
import { BasicTimeseriesChartVx } from "modules/charts/components/BasicTimeseriesChartVx";
import { SeriesCollection, Series, BandRangeProp } from "modules/charts/series";
import {
  createSeriesForCtrlVariableField,
  createSeriesForMotorOpField,
} from "modules/charts/series";
import { FontIcon } from "modules/common/components/FontIcon";
import { useEffect, useState } from "react";
import {
  ActiveFaultCondition,
  ActiveFaultCondition_node_FaultCondition,
  ActiveFaultCondition_node_FaultCondition_activeFaultCondRanges,
} from "generated-gql-types/ActiveFaultCondition";
import { gql, useQuery } from "@apollo/client";
import { FancyDivider } from "../../components";
import { PrimaryCard } from "modules/common/components/PrimaryCard";

const ACTIVE_FAULT_CONDITION_QUERY = gql`
  query ActiveFaultCondition($originKey: ID!, $startTime: Time!, $endTime: Time!) {
    node(id: $originKey) {
      ... on FaultCondition {
        id
        activeFaultCondRanges(startTime: $startTime, endTime: $endTime) {
          startTime
          endTime
        }
      }
    }
  }
`;

interface AlertChartProps {
  alert: AlertDetail_alert_Alert;
  siteStore: SiteStore;
  apiStore?: APIStore;
}

interface AlertChartState {
  analysisUrl?: string;
}

export function AlertChartComponent(props: AlertChartProps & AlertChartState & WithSnackbarProps) {
  const { alert } = props;
  const minute = 1000 * 60 * 1;
  const date = new Date();
  const currentTime = new Date(Math.round(date.getTime() / minute) * minute);
  const startTime = new Date(new Date(alert.createdAt).getTime() - 12 * DURATION.HOUR);
  const weekAfterStartTime = moment(startTime).add(1, "weeks").toDate();
  let endTime = alert.closedAt
    ? new Date(new Date(alert.closedAt).getTime() + 12 * DURATION.HOUR)
    : currentTime;
  if (endTime > startTime) {
    endTime = weekAfterStartTime;
  }
  const [alertChartState, setAlertChartState] = useState<AlertChartState>({ analysisUrl: "" });
  const analysisStoreMemo = memoizeOne((siteStore: SiteStore) => {
    const { apiStore } = props;
    if (!apiStore) {
      Sentry.withScope(() => {
        Sentry.captureException(new Error("API store unavailable"));
      });
      return undefined;
    }
    return new AnalysisStore(apiStore, siteStore);
  });
  const store = analysisStoreMemo(props.siteStore) as AnalysisStore;
  const [analysisStore, setAnalysisStore] = useState<AnalysisStore>(store);

  function applyAlertChartStats() {
    const { alert, siteStore } = props;

    if (analysisStore === undefined) {
      return;
    }
    analysisStore!.setStartTime(startTime);
    analysisStore!.setEndTime(endTime);

    // Cause-specific presentation
    switch (alert.cause.__typename) {
      case "AlertCauseMotorSpeed": {
        // add requested and current speed
        const motorId = unmarshalStringId(alert.cause.motor.id);
        const motor = siteStore.site.getMotor(motorId);
        if (!motor) {
          return;
        }
        [MotorOpStat.CurrentSpeed, MotorOpStat.RequestedSpeed].forEach((stat: MotorOpStat) => {
          analysisStore!.addMotorOpField(new MotorOp(motor, stat));
        });
        setAnalysisUrl(
          motorId,
          [MotorOpStat.RequestedSpeed, MotorOpStat.CurrentSpeed],
          analysisStore
        );
        break;
      }
      case "AlertCauseCascade": {
        const logicPoint = new LogicPoint(alert.cause.ctrlVariable.logicPoint.name);
        if (logicPoint.isAnalog) {
          // add control variable
          const spvId = unmarshalStringId(alert.cause.supervisor.id);
          const spv = siteStore.site.getCtrl(spvId);
          const ctrlVar =
            spv &&
            spv.variables &&
            spv.variables.find((v) => v.logicPoint === logicPoint.pointName);
          if (!ctrlVar) {
            return;
          }
          analysisStore!.addCtrlVariableField(ctrlVar);
          setAnalysisUrl(spvId, [logicPoint.pointName], analysisStore);
        }
        break;
      }
      case "AlertCauseMotorLowTorque": {
        // add torque
        const motorId = unmarshalStringId(alert.cause.motor.id);
        const motor = siteStore.site.getMotor(motorId);
        if (!motor) {
          return;
        }
        analysisStore!.addMotorOpField(new MotorOp(motor, MotorOpStat.Torque));
        setAnalysisUrl(motorId, [MotorOpStat.Torque], analysisStore);
        break;
      }
      case "AlertCauseMotorSeizure": {
        // add current_cmd and currents
        const motorId = unmarshalStringId(alert.cause.motor.id);
        const motor = siteStore.site.getMotor(motorId);
        if (!motor) {
          return;
        }
        let stats = [
          MotorOpStat.CurrentSpeed,
          MotorOpStat.CurrentA,
          MotorOpStat.CurrentB,
          MotorOpStat.CurrentC,
        ];
        stats.forEach((stat: MotorOpStat) => {
          analysisStore!.addMotorOpField(new MotorOp(motor, stat));
        });
        setAnalysisUrl(motorId, stats, analysisStore);
        break;
      }
    }
    setAnalysisStore(analysisStore);
  }

  useEffect(applyAlertChartStats, []);

  const { data, loading } = useQuery<ActiveFaultCondition>(ACTIVE_FAULT_CONDITION_QUERY, {
    variables: {
      originKey: alert.originKey,
      startTime: startTime,
      endTime: endTime,
    },
  });

  if (loading || !data) {
    return null;
  }

  function setAnalysisUrl(deviceId: string, varNames: string[], analysisStore: AnalysisStore) {
    const siteId = unmarshalNumberId(props.alert.site.id);
    let qualifiedVars = varNames.map((varName) => `${deviceId}$${varName}`);
    setAlertChartState({
      analysisUrl: `/sites/${siteId}/analysis?v=${qualifiedVars.join(",")}`,
    });
  }

  const analysisUrl = alertChartState.analysisUrl || "";
  if (analysisStore === undefined || analysisStore!.selectedFields.length < 1) {
    return null;
  }

  const ctrlSeries = analysisStore!.selectedCtrlVariableFields.reduce((collection, field) => {
    collection.push(createSeriesForCtrlVariableField(field));
    return collection;
  }, [] as Series[]);

  const motorSeries = analysisStore!.selectedMotorOpFields.reduce((collection, field) => {
    collection.push(createSeriesForMotorOpField(field));
    return collection;
  }, [] as Series[]);

  let bandRanges: BandRangeProp[] = [];
  if (data) {
    const a = data.node as ActiveFaultCondition_node_FaultCondition;
    bandRanges = a.activeFaultCondRanges?.map(
      (r: ActiveFaultCondition_node_FaultCondition_activeFaultCondRanges | null) => {
        return new BandRangeProp({
          startTime: new Date(r?.startTime),
          endTime: new Date(r?.endTime),
        });
      }
    );
  }

  return (
    <Grid container={true} direction="column">
      <FancyDivider>History</FancyDivider>
      <PrimaryCard>
        <Grid item={true} container={true} justifyContent="flex-end" pr={1}>
          <Grid item={true} pt={2} pr={2}>
            <Link to={analysisUrl} style={{ textTransform: "uppercase" }}>
              <small>
                View full history <FontIcon name="external-link-alt" />
              </small>
            </Link>
          </Grid>
        </Grid>
        <Grid item={true}>
          <BasicTimeseriesChartVx
            data={analysisStore!.zippedTimeseriesBuckets}
            series={new SeriesCollection(...[...ctrlSeries, ...motorSeries])}
            isLoading={analysisStore!.isLoadingCtrls}
            bandRanges={bandRanges}
          />
        </Grid>
      </PrimaryCard>
    </Grid>
  );
}

export const AlertChartSection = withSnackbar(injectStores("api")(observer(AlertChartComponent)));
