import { SiteMonthlySavingsChart_site } from "generated-gql-types/SiteMonthlySavingsChart_site";
import { MotorSetupDialog_site } from "generated-gql-types/MotorSetupDialog_site";
import { SiteSetupDialog_site } from "generated-gql-types/SiteSetupDialog_site";
import { isEqual } from "lodash";
import { SiteSavings_site_Site } from "generated-gql-types/SiteSavings";
import { Organization_node_Organization_sites_edges_node } from "generated-gql-types/Organization";

export interface SiteValues {
  utilityRates: [];
  peakDemandCharge: number | null;
  co2eGPerKwh: number | null;
}

export const DEFAULT_SITE_VALUES = {
  utilityRates: [],
  peakDemandCharge: null,
  co2eGPerKwh: null,
} as SiteValues;

export const isSiteEqual = (
  s1?: SiteValues | SiteSetupDialog_site | MotorSetupDialog_site,
  s2?: SiteValues | SiteSetupDialog_site | MotorSetupDialog_site
) => {
  return !!(
    s1 &&
    s2 &&
    isEqual(s1.utilityRates, s2.utilityRates) &&
    s1.peakDemandCharge === s2.peakDemandCharge &&
    s1.co2eGPerKwh === s2.co2eGPerKwh
  );
};

export const hasUtilityRate = (
  site?:
    | SiteValues
    | SiteSetupDialog_site
    | MotorSetupDialog_site
    | SiteMonthlySavingsChart_site
    | Organization_node_Organization_sites_edges_node
) => !!(site && site.utilityRates?.length && site.utilityRates[0].rate !== 0);

export const isSiteSetup = (site?: SiteValues | SiteSetupDialog_site | MotorSetupDialog_site) =>
  site && !isSiteEqual(site, DEFAULT_SITE_VALUES) && hasUtilityRate(site);

// Type Guard for SiteSavings_site_Site
export const isSite = (test: any): test is SiteSavings_site_Site => {
  return test.baselineMotors !== undefined || test.sites === undefined;
};
