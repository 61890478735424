import { CreateSiteInput } from "generated-gql-types/globalTypes";
import { useMutation, gql } from "@apollo/client";

const mutation = gql`
  mutation CreateSite($input: CreateSiteInput!) {
    createSite(input: $input) {
      site {
        id
      }
    }
  }
`;

export const useCreateSiteMutation = () => {
  const [mutate] = useMutation(mutation);
  return (input: CreateSiteInput) => mutate({ variables: { input } });
};
