import { Overrides } from "@material-ui/core/styles/overrides";
import { ComponentsProps } from "@material-ui/core/styles/props";

export const MuiCardOverrides: Overrides = {
  MuiCardContent: {
    root: {
      // collapse spacing if after header
      "header + &": {
        paddingTop: 0,
      },
    },
  },
};

export const MuiCardProps: ComponentsProps = {
  // @ts-ignore: component has incorrect typing
  MuiCardHeader: {
    // Use header element for card header so it can be more easily selected by the CardContent css
    // @ts-ignore: component has incorrect typing
    component: "header",
    titleTypographyProps: {
      variant: "h4",
    },
  },
};
