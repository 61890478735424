import { useMutation, gql } from "@apollo/client";
import { RebootMotorInput } from "generated-gql-types/globalTypes";

const mutation = gql`
  mutation RebootMotor($input: RebootMotorInput!) {
    rebootMotor(input: $input) {
      motorId
      didReceive
    }
  }
`;

export function useRebootMotorMutation() {
  const [mutate] = useMutation(mutation);
  return (input: RebootMotorInput) => mutate({ variables: { input } });
}
