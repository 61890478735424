import * as React from "react";
import { MouseEvent, useCallback, useState } from "react";
import { gql } from "@apollo/client";
import { IconButton, Typography } from "@material-ui/core";
import { OrgSites_sites_edges_node } from "generated-gql-types/OrgSites";
import { SitesTable_organization } from "generated-gql-types/SitesTable_organization";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { useMenuState } from "modules/common/hooks";
import { ActionMenu, ActionMenuItem } from "modules/common/components/ActionMenu";
import { SiteTableRow, SiteHeaderRow } from "./row";
import { Grid } from "@mui/material";

const noop = () => null;

interface AdminActionProps {
  site: OrgSites_sites_edges_node;
  onClick: (event: MouseEvent, site: OrgSites_sites_edges_node) => void;
}

function AdminAction({ site, onClick }: AdminActionProps) {
  const handleClick = useCallback((event: MouseEvent) => onClick(event, site), [site, onClick]);
  return (
    <IconButton onClick={handleClick}>
      <i className="fas fa-ellipsis-v fa-xs" />
    </IconButton>
  );
}

interface SitesTableProps {
  organization: SitesTable_organization;
  sites: OrgSites_sites_edges_node[];
  onRenameSite: (site: OrgSites_sites_edges_node) => void;
  onToggleSiteEnergySavings: (site: OrgSites_sites_edges_node) => void;
}

export function SitesTableComponent(props: SitesTableProps) {
  const { organization, sites, onRenameSite, onToggleSiteEnergySavings } = props;
  const adminVariant = organization.viewerIsAdmin;

  const handleEnergySavingsToggle = (site: OrgSites_sites_edges_node) =>
    adminVariant
      ? (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
          onToggleSiteEnergySavings({
            ...site,
            energySavingsEnabled: checked,
          });
        }
      : noop;

  // Admin actions menu
  const [selectedSite, setSelectedSite] = useState(sites[0]);
  const menuState = useMenuState();

  const handleMenuClick = (event: MouseEvent, site: OrgSites_sites_edges_node) => {
    event.stopPropagation();
    setSelectedSite(site);
    menuState.handleOpen(event);
  };

  const menu = (
    <ActionMenu {...menuState.props} disableAutoFocusItem={true}>
      <ActionMenuItem
        onClick={() => {
          menuState.handleClose();
          onRenameSite(selectedSite);
        }}
      >
        <Typography>Rename Site</Typography>
      </ActionMenuItem>
    </ActionMenu>
  );

  return (
    <React.Fragment>
      <Grid container direction="column">
        <SiteHeaderRow />
        {sites.map((site) => (
          <SiteTableRow
            organization={organization}
            site={site}
            onToggleEnergySavings={handleEnergySavingsToggle(site)}
            Action={adminVariant && <AdminAction site={site} onClick={handleMenuClick} />}
            key={site.id}
          />
        ))}
      </Grid>
      {menu}
    </React.Fragment>
  );
}

export const SitesTable = createFragmentContainer(SitesTableComponent, {
  organization: gql`
    fragment SitesTable_organization on Organization {
      viewerIsAdmin
      ...SiteTableRow_organization
    }
    ${SiteTableRow.fragments.organization}
  `,
});
