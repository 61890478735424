import { APIClient } from "modules/common/api";
import { TSPointModel } from "modules/common/model";

export interface TSBucketGrouped<T> {
  timestamp: string;
  data: {
    [key: string]: T | null;
  };
}

export interface TSBucketAggregated<T> {
  timestamp: string;
  data: {
    [key: string]: { [key: string]: T | null };
  };
}

export interface TSBucketUngrouped<T> {
  timestamp: string;
  data: T[];
}

export class APIStore {
  protected readonly client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  request(method: string, path: string) {
    return this.client.request(method, path);
  }

  getTimeseries<T extends TSPointModel>(
    path: string,
    pointConstructor: new (_: object) => T
  ): Promise<TSBucketGrouped<T>[]> {
    return this.request("GET", path).then((res: { data: TSBucketGrouped<T>[] }) => {
      return res.data.map((bucket) => {
        const newBucket = { ...bucket };
        newBucket.data = this.convertBucketDataToPointModel(bucket.data, pointConstructor);
        return newBucket;
      });
    });
  }

  getAggregatedTimeseries<T extends TSPointModel>(
    path: string,
    pointConstructor: new (_: object) => T,
    defaultValue?: { [key: string]: any | null }[]
  ): Promise<TSBucketGrouped<T>[]> {
    return this.request("GET", path).then((res: { data: TSBucketAggregated<T>[] }) => {
      if (res.data.length || !defaultValue) {
        return res.data.map((bucket) => {
          // flatten aggregations
          let flattened = {};
          Object.keys(bucket.data).forEach((ctrlId) => {
            Object.keys(bucket.data[ctrlId]).forEach((agg) => {
              flattened[`${ctrlId}_${agg}`] = bucket.data[ctrlId][agg];
            });
            // hack: promote avg to be default series for backwards compat
            flattened[ctrlId] = flattened[`${ctrlId}_mean`] ?? flattened[`${ctrlId}_mode`];
          });

          return {
            timestamp: bucket.timestamp,
            data: this.convertBucketDataToPointModel(flattened, pointConstructor),
          };
        });
      } else {
        return defaultValue.map((datum) => ({
          timestamp: datum.ts,
          data: this.convertBucketDataToPointModel(datum, pointConstructor),
        }));
      }
    });
  }

  getUngroupedTimeseries<T extends TSPointModel>(
    path: string,
    pointConstructor: new (_: object) => T
  ): Promise<TSBucketUngrouped<T>[]> {
    return this.request("GET", path).then((res: { data: TSBucketUngrouped<T>[] }) => {
      return res.data.map((bucket) => {
        const newBucket = Object.assign({}, bucket);
        newBucket.data = bucket.data.map((d) => new pointConstructor(d));
        return newBucket;
      });
    });
  }

  convertBucketDataToPointModel<T extends TSPointModel>(
    data: { [key: string]: T | null },
    pointConstructor: new (_: object) => T
  ) {
    return Object.keys(data).reduce((memo, key) => {
      const rawPoint = data[key];
      memo[key] = rawPoint ? new pointConstructor(rawPoint) : null;
      return memo;
    }, {} as { [key: string]: T | null });
  }
}
