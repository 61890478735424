import React, { useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { Grid, Tooltip } from "@mui/material";
import { NumericEdit } from "../SiteEnergySavings/NumericEdit";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { gql } from "@apollo/client";
import {
  UtilityRatesForm_site,
  UtilityRatesForm_site_utilityRates,
} from "generated-gql-types/UtilityRatesForm_site";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, DateValidationError } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import {
  UTILITY_RATE_LABEL,
  UTILITY_RATE_UNITS,
} from "../SiteEnergySavings/SiteSetup/SiteInputsForm";
import {
  useUpdateUtilityRateMutation,
  useCreateUtilityRateMutation,
} from "modules/common/mutations/UtilityRateMutation";
import { useSnackbar } from "notistack";
import moment, { Moment } from "moment";
import { PickerChangeHandlerContext } from "@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { presentError } from "modules/site-manager/utils/errors";
import autoAnimate from "@formkit/auto-animate";
import { makeLocalAppearUTC, localToUTC } from "modules/common/utils/time";

interface UtilityRateComponentProps {
  utilityRate: UtilityRatesForm_site_utilityRates;
  handleChange: (utilityRate: UtilityRatesForm_site_utilityRates, isDateNext?: boolean) => void;
  showLabel: boolean;
  disabled?: boolean;
  autoFocusDate?: boolean;
  shouldDisableDate?: (date: Moment) => boolean;
}

const warningIcon = <WarningAmberIcon fontSize="small" />;

// Component that displays a single utility rate and associated start date.  Optionally includes "Utility Rate" label (for first rate).
// Should allow for undefined start date
const UtilityRateComponent = ({
  utilityRate,
  handleChange,
  showLabel,
  disabled = false,
  autoFocusDate = false,
  shouldDisableDate = (moment: Moment) => false,
}: UtilityRateComponentProps) => {
  const [rate, setRate] = useState(utilityRate.rate);
  const [startDate, setStartDate] = useState<Moment | undefined>(
    utilityRate.startDate ? moment(utilityRate.startDate) : undefined
  );
  const [error, setError] = useState<DateValidationError | null>(null);
  const errorMessage = useMemo(() => {
    switch (error) {
      case "invalidDate": {
        return "Date is not valid";
      }
      case "shouldDisableDate": {
        return "Dates must be unique";
      }
      default: {
        return error || "";
      }
    }
  }, [error]);

  return (
    <Grid item xs={12} container key={utilityRate.id} alignItems="center">
      <Grid item xs={12} sm={7} container>
        <NumericEdit
          id={`utilityRate-${utilityRate.id}`}
          value={rate || 0}
          autoFocus={false}
          onChange={(e) => setRate(+e.target.value)}
          onBlur={(e: any) =>
            handleChange(
              {
                ...utilityRate,
                rate: +e.target.value,
              },
              // hack to prevent focus theft due to rerender upon saving new utility rate
              e.relatedTarget?.placeholder === "MM / DD / YYYY"
            )
          }
          label={showLabel ? UTILITY_RATE_LABEL : " "}
          units={UTILITY_RATE_UNITS}
          disabled={disabled}
          decimalScale={4}
          maxValue={99.9999}
        />
      </Grid>
      <Grid item xs="auto" container p={1} pl={2} direction="row" justifyContent="flex-start">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Start Date"
            value={startDate ? makeLocalAppearUTC(startDate) : null}
            onChange={(newValue, context: PickerChangeHandlerContext<DateValidationError>) => {
              if (context.validationError) {
                setError(context.validationError);
                return;
              }
              const newLocalDate = newValue?.startOf("day");
              if (newLocalDate && shouldDisableDate(newLocalDate)) {
                setError("shouldDisableDate");
                return;
              }

              setError(null);
              const newUtcDate = newLocalDate ? localToUTC(newLocalDate) : undefined;
              setStartDate(newUtcDate);
              handleChange({
                ...utilityRate,
                startDate: newUtcDate,
              });
            }}
            sx={{ "& input, & label": { fontFamily: "Barlow" } }}
            slotProps={{
              textField: { size: "small" },
            }}
            shouldDisableDate={shouldDisableDate}
            autoFocus={autoFocusDate}
            disabled={disabled}
            onError={(newError: DateValidationError) => setError(newError)}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs="auto" justifyContent="flex-start" alignItems="center">
        {error && <Tooltip title={errorMessage}>{warningIcon}</Tooltip>}
      </Grid>
    </Grid>
  );
};

export interface UtilityRatesFormComponentProps {
  site: UtilityRatesForm_site;
  onChange: (utilityRate: UtilityRatesForm_site_utilityRates) => void;
  disabled?: boolean;
}

const BLANK_RATE = {
  __typename: "UtilityRate",
  id: "",
  startDate: undefined,
  rate: 0,
} as UtilityRatesForm_site_utilityRates;

const compareRateDates = (
  a: UtilityRatesForm_site_utilityRates,
  b: UtilityRatesForm_site_utilityRates
) => {
  if (a.startDate === b.startDate) {
    return 0;
  }
  if (a.startDate && !b.startDate) {
    return -1;
  }
  if (!a.startDate && b.startDate) {
    return 1;
  }

  return moment(a.startDate).isAfter(moment(b.startDate)) ? -1 : 1;
};

// Component that builds a list of utility rate components.
// There should be at least one row, and the first row should be labeled "Utility Rate".
// If a start date is added to the last row, add a new row below it.
// When a utility rate or start date is changed (on blur, ie tab/mouse out), call a mutation (create or update as appropriate)
// Validation: Rates must be < 100 and dates must be unique
// - Allows any date to be entered in the blank row at the bottom, to enable adding new utility rates after initial setup.
//   These rates are animated into their chronological list position to mitigate confusion when the page refreshes
export const UtilityRatesFormComponent = ({
  site,
  onChange,
  disabled = false,
}: UtilityRatesFormComponentProps) => {
  const [utilityRates, setUtilityRates] = useState(
    site.utilityRates?.length ? site.utilityRates : [BLANK_RATE]
  );
  const [autoFocusDate, setAutoFocusDate] = useState(false);
  const updateUtilityRateMutation = useUpdateUtilityRateMutation();
  const createUtilityRateMutation = useCreateUtilityRateMutation();
  const { enqueueSnackbar } = useSnackbar();
  const parentRef = React.useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    (utilityRate: UtilityRatesForm_site_utilityRates, isDateNext?: boolean) => {
      const siteUtilityRate = site.utilityRates.find((u) => u.id === utilityRate.id);
      const updateRate =
        siteUtilityRate &&
        utilityRate?.startDate &&
        (siteUtilityRate.rate !== utilityRate.rate ||
          siteUtilityRate.startDate !== utilityRate.startDate);
      if (updateRate) {
        updateUtilityRateMutation({
          ...siteUtilityRate,
          rate: +utilityRate.rate,
          startDate: utilityRate.startDate,
        })
          .then((result) => {
            const updatedUtilityRate = result.data?.updateUtilityRate.utilityRate;
            if (updatedUtilityRate) {
              const staleUtilityRateIndex = utilityRates.findIndex(
                (u) => u.id === updatedUtilityRate.id
              );
              if (staleUtilityRateIndex >= 0 && updatedUtilityRate.startDate) {
                // sort the utility rates according to the updated start date
                const copy = utilityRates.slice();
                copy.splice(staleUtilityRateIndex, 1, updatedUtilityRate);
                copy.sort(compareRateDates);
                if (staleUtilityRateIndex === utilityRates.length - 1) {
                  // create a blank entry at the end, if we updated the last utility rate
                  copy.push(BLANK_RATE);
                }
                setUtilityRates(copy);
              }
              onChange(utilityRate);
            }
          })
          .catch((e: any) =>
            enqueueSnackbar(presentError("Error updating utility rate", e), {
              variant: "error",
            })
          );
      } else if (utilityRate.rate && !utilityRate.id) {
        setAutoFocusDate(!!isDateNext);
        createUtilityRateMutation({
          siteId: site.id,
          startDate: utilityRate.startDate?.toDate(),
          rate: utilityRate.rate,
        })
          .then((result) => {
            const updatedUtilityRate = result.data?.createUtilityRate.utilityRate;
            if (updatedUtilityRate) {
              // Apply the id provided by the backend so we can associate a date later.
              // Exclude the default date provided by the backend,
              // because we can't distinguish user-added dates from defaulted ones
              // and we use the change from blank date to actual date to trigger adding another row.
              const i = utilityRates.findIndex((u) => u.id === utilityRate.id);
              if (i >= 0) {
                const copy = utilityRates.slice();
                copy.splice(i, 1, {
                  ...utilityRate,
                  id: updatedUtilityRate.id,
                });
                setUtilityRates(copy);
              }
              onChange(updatedUtilityRate);
            }
          })
          .catch((e: any) =>
            enqueueSnackbar(presentError("Error creating utility rate", e), {
              variant: "error",
            })
          );
      } else if (!siteUtilityRate?.rate && utilityRate.startDate) {
        // user supplied the date first; just update locally for now
        const i = utilityRates.findIndex((u) => u.id === utilityRate.id);
        if (i >= 0) {
          const copy = utilityRates.slice();
          copy.splice(i, 1, {
            ...utilityRate,
          });
          copy.sort(compareRateDates);
          setUtilityRates(copy);
        }
      }
    },
    [
      onChange,
      site,
      utilityRates,
      updateUtilityRateMutation,
      createUtilityRateMutation,
      enqueueSnackbar,
    ]
  );

  // Effect 1: if we have a start date for the last utility rate, add a blank rate
  useEffect(() => {
    const lastUtilityRate = utilityRates[utilityRates.length - 1];
    if (lastUtilityRate?.id && lastUtilityRate?.startDate) {
      const extended = utilityRates.slice();
      extended.push(BLANK_RATE);
      setUtilityRates(extended);
    }
  }, [utilityRates, setUtilityRates]);

  // Effect 2: auto-animate sorting the list
  useEffect(() => {
    if (parentRef.current) {
      autoAnimate(parentRef.current);
    }
  }, [parentRef]);

  const getShouldDisableDate = (index: number) => (date: Moment) => {
    const utc = localToUTC(date);
    return utilityRates.reduce(
      (previous, current, currentIndex) =>
        previous || (currentIndex !== index && moment(current.startDate).isSame(utc)),
      false
    );
  };

  return (
    <Grid item xs={12} container sx={{ fontSize: "15px" }} justifyContent="stretch" ref={parentRef}>
      {utilityRates.map((utilityRate, index) => (
        <UtilityRateComponent
          key={utilityRate.id}
          utilityRate={utilityRate}
          disabled={disabled}
          handleChange={handleChange}
          showLabel={index === 0}
          shouldDisableDate={getShouldDisableDate(index)}
          autoFocusDate={
            autoFocusDate &&
            index === utilityRates.length - 1 &&
            !!utilityRate.rate &&
            !utilityRate.startDate
          }
        />
      ))}
    </Grid>
  );
};

export const UtilityRatesForm = createFragmentContainer(UtilityRatesFormComponent, {
  site: gql`
    fragment UtilityRatesForm_site on Site {
      id
      viewerIsAdmin
      utilityRates {
        id
        startDate
        rate
      }
    }
  `,
});
