import * as React from "react";
import { useCallback, useState } from "react";
import { gql } from "@apollo/client";
import { FormGroup } from "@material-ui/core";
import {
  useSubscribeToSiteAlertsMutation,
  useUnsubscribeFromSiteAlertsMutation,
} from "modules/common/mutations/AlertsSubscriptionMutation";
import { AlertsSubscription_site } from "generated-gql-types/AlertsSubscription_site";
import { AlignedLabel } from "./AlignedLabel";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { Toggle } from "sigil";

const AlertSubscriptionForm = ({ site }: { site: AlertsSubscription_site }) => {
  const [subscribed, setSubscribed] = useState(site.viewerIsSubscribedToAlerts);
  const subscribe = useSubscribeToSiteAlertsMutation();
  const unsubscribe = useUnsubscribeFromSiteAlertsMutation();
  const { id } = site;

  const handleChange = useCallback(
    async (e) => {
      const subs = e.target.checked
        ? await subscribe({ siteId: id })
        : await unsubscribe({ siteId: id });
      return setSubscribed(!!subs.data && subs.data.response.subscribed);
    },
    [id, subscribe, unsubscribe]
  );

  return (
    <FormGroup row={true}>
      <AlignedLabel
        control={<Toggle checked={subscribed} onChange={handleChange} />}
        label="Send me email notifications about alerts at this site"
      />
    </FormGroup>
  );
};

export default createFragmentContainer(AlertSubscriptionForm, {
  site: gql`
    fragment AlertsSubscription_site on Site {
      id
      viewerIsSubscribedToAlerts
    }
  `,
});
