import * as React from "react";
import { useSnackbar } from "notistack";
import { SimpleConfirmationDialog } from "modules/common/components/SimpleConfirmationDialog";
import { useAssociateSupervisorWithSiteMutation } from "./AssociateSupervisorWithSiteMutation";
import { SupervisorSection_site_supervisors } from "generated-gql-types/SupervisorSection_site";
import { marshalNumberId, unmarshalStringId } from "modules/common/utils/relay";

type DissociateSupervisorDialogProps = {
  open: boolean;
  onClose: (success: boolean) => void;
  supervisor: SupervisorSection_site_supervisors;
};

export const DissociateSupervisorDialog = (props: DissociateSupervisorDialogProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const associateSupervisor = useAssociateSupervisorWithSiteMutation();

  return (
    <SimpleConfirmationDialog
      open={props.open}
      onClose={() => props.onClose(false)}
      title="Remove Supervisor from Site?"
      body={
        <>
          The supervisor{" "}
          <strong>{`${props.supervisor.displayName || props.supervisor.serialNumber}`}</strong> will
          be dissociated from the site. Do you want to proceed?
        </>
      }
      confirmLabel="Yes"
      onConfirm={async () => {
        try {
          await associateSupervisor({
            siteId: marshalNumberId("site", 0),
            supervisorSerialNumber: unmarshalStringId(props.supervisor.id),
            password: "",
          });
          enqueueSnackbar(`Supervisor ${props.supervisor.serialNumber} has been dissociated`, {
            variant: "success",
          });
          props.onClose(true);
        } catch (e) {
          enqueueSnackbar("There was an error removing the supervisor from the site.", {
            variant: "error",
          });
          props.onClose(false);
        }
      }}
    />
  );
};
