import { computed, IObservableArray } from "mobx";
import { prop } from "datx";
import { now } from "mobx-utils";

import { DURATION } from "modules/common/utils/time";
import { Model } from "modules/common/model";
import { CtrlVariable, CtrlLogicPoint, Motor, MotorGroup } from "modules/site-manager/models";

enum AuthStatus {
  Normal = "NORMAL",
  NotInitialized = "NOT_INITIALIZED",
  ChangingPassword = "CHANGING_PASSWORD",
}

export class Ctrl extends Model {
  static type = "controllers";

  //                    ms     s    m
  connectionAllowance = 1000 * 60 * 60;

  // Attributes
  @prop.defaultValue(AuthStatus.Normal)
  authStatus!: AuthStatus;
  @prop.defaultValue("")
  createdAt!: string;
  @prop
  latestCtrlLogicPoint?: object;
  @prop.defaultValue("")
  name!: string;
  @prop.defaultValue("")
  logicFlowVersion!: string;
  @prop
  systemVersion!: string;
  @prop
  bmsScheduleId?: string;
  @prop
  siteId?: string;
  @prop.defaultValue("UTC")
  timezone!: string;

  // Relations
  @prop.toMany("motors")
  motors?: IObservableArray<Motor>;
  @prop.toMany("motorGroups")
  motorGroups?: IObservableArray<MotorGroup>;
  @prop.toMany("controllerVariables")
  variables?: IObservableArray<CtrlVariable>;

  @computed get displayName() {
    return this.name || this.id;
  }

  @computed get createdAtTime() {
    return new Date(this.createdAt);
  }

  @computed.struct
  get latestCtrlLogicPointJS() {
    if (!this.latestCtrlLogicPoint) {
      return null;
    }
    return new CtrlLogicPoint(this.latestCtrlLogicPoint);
  }

  @computed get sortedMotors() {
    return this.motors ? this.motors.slice().sort(Motor.compare) : [];
  }

  @computed get isConnected() {
    if (!this.latestCtrlLogicPointJS) {
      return false;
    }
    const lastRecordedTime = this.latestCtrlLogicPointJS.recordedAtTime;
    const timePassed = now(10 * DURATION.SECOND) - lastRecordedTime.getTime();
    return timePassed < this.connectionAllowance;
  }
}

export enum CtrlOperatingMode {
  OFF = 0,
  VENT,
  COOL1,
  HEAT1,
  COOL2,
  HEAT2,
}

export const CtrlOperatingModes = Object.keys(CtrlOperatingMode)
  .filter((k) => !isNaN(Number(k)))
  .map((m) => Number(m) as CtrlOperatingMode);
