import React, { useState } from "react";
import { Button, CircularProgress, Input, InputAdornment, Theme, Box } from "@material-ui/core";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import { TypographyProps } from "sigil";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { Field, FieldProps, Form, Formik } from "formik";
import { EditableTypography } from "./EditableTypography";
import { useSnackbar } from "notistack";

const AdornmentButton = withStyles(() => ({
  root: {
    minWidth: 0,
    padding: 5,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
  },
}))(Button);

const ResetButton = withStyles((theme) => ({
  root: {
    color: theme.palette.danger.main,
    backgroundColor: lighten(theme.palette.danger.main, 0.95),
  },
}))(AdornmentButton);

const SubmitButton = withStyles((theme) => ({
  root: {
    color: theme.palette.success.main,
    backgroundColor: lighten(theme.palette.success.main, 0.95),
  },
}))(AdornmentButton);

const styles = (theme: Theme) =>
  createStyles({
    adornment: {
      paddingRight: theme.spacing(),
    },
  });

type InlineEditableTypographyProps = WithStyles<typeof styles> & {
  value: string;
  variant: TypographyProps["variant"];
  onSubmit: (value: string) => Promise<any>;
  disabled?: boolean;
};

function InlineEditableTypographyComponent(props: InlineEditableTypographyProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [isEditing, setIsEditing] = useState(false);

  const form = (
    <Formik
      initialValues={{ editableValue: props.value }}
      onReset={() => setIsEditing(false)}
      onSubmit={(values, actions) =>
        props
          .onSubmit(values.editableValue)
          .then(() => setIsEditing(false))
          .catch(() => enqueueSnackbar("Something went wrong", { variant: "error" }))
          .then(() => actions.setSubmitting(false))
      }
    >
      <Form
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            e.stopPropagation();
            setIsEditing(false);
          }
        }}
      >
        <Field name="editableValue">
          {({ field: fieldProps, form: formik }: FieldProps) => (
            <Input
              {...fieldProps}
              disabled={formik.isSubmitting}
              autoFocus={true}
              fullWidth={true}
              onBlur={() => {}} // workaround for crash when hitting Enter to submit https://github.com/stackworx/formik-mui/issues/172
              endAdornment={
                <InputAdornment position="end" className={props.classes.adornment}>
                  {formik.isSubmitting ? (
                    <CircularProgress size="1em" />
                  ) : (
                    <>
                      <ResetButton type="reset">
                        <i className="fas fa-ban" />
                      </ResetButton>
                      <Box mx={1 / 4} />
                      <SubmitButton type="submit" disabled={!formik.isValid}>
                        <i className="fas fa-check" />
                      </SubmitButton>
                    </>
                  )}
                </InputAdornment>
              }
            />
          )}
        </Field>
      </Form>
    </Formik>
  );

  const typography = (
    <EditableTypography
      variant={props.variant}
      onEdit={() => setIsEditing(true)}
      disabled={props.disabled}
      style={{ paddingBottom: "4px" as string, fontWeight: 600 }}
    >
      {props.value}
    </EditableTypography>
  );

  return isEditing ? form : typography;
}

export const InlineEditableTypography = withStyles(styles)(InlineEditableTypographyComponent);
