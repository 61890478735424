import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { CardContent, Grid } from "@material-ui/core";
import { SearchField } from "modules/common/components/SearchField";
import { icontains } from "../utils";
import { OrgSites_sites_edges, OrgSites_sites_edges_node } from "generated-gql-types/OrgSites";
import { SitesSettingsCard_organization } from "generated-gql-types/SitesSettingsCard_organization";
import { StyledCardHeader } from "../styled";
import { SitesTable } from "./table";
import { RenameSiteDialog } from "./RenameSiteDialog";
import { useSnackbar } from "notistack";
import { useUpdateSiteMutation } from "modules/common/mutations/UpdateSiteInputMutation";
import { CardSkeleton } from "../CardSkeleton";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";

type SitesSettingsCardProps = {
  organization: SitesSettingsCard_organization;
};

const SITES_QUERY = gql`
  query OrgSites($first: Int = 500, $search: String, $alertFilter: AlertFilter) {
    sites(first: $first, search: $search, alertFilter: $alertFilter) {
      edges {
        node {
          ... on Site {
            id
            address
            energySavingsEnabled
            organization {
              id
            }
            ...RenameSiteDialog_site
          }
        }
      }
    }
  }
  ${RenameSiteDialog.fragments.site}
`;

type SiteSettingsPaneProps = {
  sites: OrgSites_sites_edges_node[];
  organization: SitesSettingsCard_organization;
};

const SiteSettingsPane = ({ sites, organization }: SiteSettingsPaneProps) => {
  const [filter, setFilter] = useState("");
  const [rows, setRows] = useState(sites);
  const [renamingSite, setRenamingSite] = useState<OrgSites_sites_edges_node | null>(null);

  const onRenameSite = (site: OrgSites_sites_edges_node) => {
    setRenamingSite(site);
  };

  useEffect(
    () =>
      setRows(
        sites.filter(
          (site) =>
            icontains(site.name, filter) || (site.address && icontains(site.address, filter))
        )
      ),
    [filter, sites]
  );
  const { enqueueSnackbar } = useSnackbar();
  const updateSite = useUpdateSiteMutation();

  return (
    <React.Fragment>
      <StyledCardHeader title={organization.name} subheader="Sites" />

      <CardContent>
        <SearchField value={filter} onChange={(event) => setFilter(event.target.value)} />
      </CardContent>

      <Grid>
        <SitesTable
          organization={organization}
          sites={rows}
          onRenameSite={onRenameSite}
          onToggleSiteEnergySavings={async (site) => {
            try {
              await updateSite({
                siteId: site.id,
                energySavingsEnabled: site.energySavingsEnabled,
              });
              enqueueSnackbar("Saved", { variant: "success" });
            } catch (e: any) {
              enqueueSnackbar(`Error toggling site energy savings: ${e.message || e}`, {
                variant: "error",
              });
            }
          }}
        />
      </Grid>
      {renamingSite && (
        <RenameSiteDialog open={true} onClose={() => setRenamingSite(null)} site={renamingSite} />
      )}
    </React.Fragment>
  );
};

export const SitesSettingsComponent = (props: SitesSettingsCardProps) => {
  const sitesQuery = useQuery(SITES_QUERY, {
    variables: { search: props.organization?.name },
    skip: !props.organization?.name,
  });

  // Empty state
  if (sitesQuery.loading || !sitesQuery.data?.sites?.edges) {
    return <CardSkeleton subheader="Sites" />;
  }

  const rows = sitesQuery.data.sites.edges
    .filter((edge: OrgSites_sites_edges) => edge.node.organization.id === props.organization?.id)
    .map((edge: OrgSites_sites_edges) => edge.node);

  return <SiteSettingsPane sites={rows} organization={props.organization} />;
};

export const SitesSettingsCard = createFragmentContainer(SitesSettingsComponent, {
  organization: gql`
    fragment SitesSettingsCard_organization on Organization {
      id
      name
      viewerIsAdmin
    }
  `,
});
