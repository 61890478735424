import { useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import { AddSitesShareInput, RemoveSitesShareInput } from "generated-gql-types/globalTypes";

export function useAddSitesShareMutation() {
  const [mutate] = useMutation(gql`
    mutation AddSiteShare($input: AddSitesShareInput!) {
      addSitesShare(input: $input) {
        guestOrganization {
          id
          name
        }
        organization {
          id
          # refetch outgoing shares
          outboundShares {
            asAdmin
            createdAt
            guestOrganization {
              id
              name
            }
          }
        }
      }
    }
  `);
  return useCallback((input: AddSitesShareInput) => mutate({ variables: { input } }), [mutate]);
}

export function useUpdateSitesShareMutation() {
  const [mutate] = useMutation(gql`
    mutation UpdateSiteShare($input: AddSitesShareInput!) {
      addSitesShare(input: $input) {
        organization {
          id
          outboundShares {
            guestOrganization {
              id
            }
            asAdmin
          }
        }
      }
    }
  `);
  return useCallback((input: AddSitesShareInput) => mutate({ variables: { input } }), [mutate]);
}

export function useRemoveOutboundSiteShareMutation() {
  const [mutate] = useMutation(
    gql`
      mutation RemoveOutboundSiteShare($input: RemoveSitesShareInput!) {
        removeSitesShare(input: $input) {
          organization {
            id
            outboundShares {
              guestOrganization {
                id
              }
            }
            inboundShares {
              organization {
                id
              }
            }
          }
        }
      }
    `
  );
  return useCallback((input: RemoveSitesShareInput) => mutate({ variables: { input } }), [mutate]);
}

export function useRemoveInboundSiteShareMutation() {
  const [mutate] = useMutation(gql`
    mutation RemoveInboundSiteShare($input: RemoveSitesShareInput!) {
      removeSitesShare(input: $input) {
        guestOrganization {
          id
          inboundShares {
            organization {
              id
            }
          }
        }
      }
    }
  `);
  return useCallback((input: RemoveSitesShareInput) => mutate({ variables: { input } }), [mutate]);
}
