import { parse, stringify, ParseOptions, StringifyOptions, ParsedQuery } from "query-string";

const defaultQSOptions: StringifyOptions & ParseOptions = {
  arrayFormat: "bracket",
};

export function parseQuery(query: string): ParsedQuery {
  return parse(query, defaultQSOptions);
}

export function stringifyQuery(params: object): string {
  return stringify(params, defaultQSOptions);
}
