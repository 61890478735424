import { useMutation, gql } from "@apollo/client";
import { ClearMotorFaultsInput } from "generated-gql-types/globalTypes";

const mutation = gql`
  mutation ClearMotorFaults($input: ClearMotorFaultsInput!) {
    clearMotorFaults(input: $input) {
      motorId
      didReceive
    }
  }
`;

export function useClearMotorFaultsMutation() {
  const [mutate] = useMutation(mutation);
  return (input: ClearMotorFaultsInput) => mutate({ variables: { input } });
}
