import * as React from "react";
import { observer } from "mobx-react";
import { Table, TableBody, TableRow, TableCell, TableHead, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import { LightLabel, LightCaption, BoldTitle } from "modules/common/components/Typography";
import { CtrlVariable } from "modules/site-manager/models";
import { CtrlVariablePresenter } from "modules/site-manager/presenters";
import { PriorityBars } from "modules/site-manager/components/PriorityBars";
import { OverrideableCtrlVariableValue } from "modules/site-manager/components/OverrideableCtrlVariableValue";
import { isNone } from "modules/common/utils/guards";

const HeadRow = withStyles({
  root: {
    height: "33px",
  },
})(TableRow);

interface MetricRowProps {
  ctrlVar: CtrlVariable;
  analysisPath: string;
  allowEdit?: boolean;
}

@observer
class MetricRow extends React.Component<MetricRowProps> {
  render() {
    const { ctrlVar: variable, analysisPath, allowEdit } = this.props;
    const varPresenter = new CtrlVariablePresenter(variable);

    return (
      <TableRow key={variable.id} hover={true}>
        <TableCell align="left">
          <Link
            to={{
              pathname: analysisPath,
              search: `v=${variable.qualifiedName}`,
            }}
          >
            <BoldTitle>{varPresenter.displayName}</BoldTitle>
          </Link>
          <LightCaption>{varPresenter.role}</LightCaption>
        </TableCell>

        <TableCell align="left">
          <Typography>{varPresenter.logicPoint}</Typography>
          <LightCaption>{varPresenter.controllerDisplayName}</LightCaption>
        </TableCell>

        <TableCell align="left">
          <OverrideableCtrlVariableValue
            variable={variable}
            showUnits={!isNone(varPresenter.displayValue)}
            unknownLabel="– –"
            allowEdit={allowEdit}
          />
        </TableCell>

        <TableCell align="center">
          <PriorityBars priority={variable.priority} />
        </TableCell>
      </TableRow>
    );
  }
}

interface SiteMetricsTableProps {
  analysisPath: string;
  ctrlVars: CtrlVariable[];
  allowEdit?: boolean;
}

@observer
export class SiteMetricsTable extends React.Component<SiteMetricsTableProps> {
  render() {
    const { ctrlVars, analysisPath, allowEdit } = this.props;
    const vars = ctrlVars.sort(CtrlVariable.compare);

    return (
      <Table size="small">
        <TableHead>
          <HeadRow>
            <TableCell>
              <LightLabel>POINT</LightLabel>
            </TableCell>
            <TableCell>
              <LightLabel>BACNET OBJECT</LightLabel>
            </TableCell>
            <TableCell>
              <LightLabel>VALUE</LightLabel>
            </TableCell>
            <TableCell align="center">
              <LightLabel>PRIORITY</LightLabel>
            </TableCell>
          </HeadRow>
        </TableHead>

        <TableBody>
          {vars.map((ctrlVar) => (
            <MetricRow
              ctrlVar={ctrlVar}
              key={ctrlVar.id}
              analysisPath={analysisPath}
              allowEdit={allowEdit}
            />
          ))}

          {vars.length < 1 && (
            <TableRow>
              <TableCell align="center" colSpan={4}>
                No metrics
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  }
}
