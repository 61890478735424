import * as React from "react";
import { BreadCrumb, BreadcrumbLink } from "modules/common/components/BreadCrumb";
import { RouteComponentProps } from "react-router-dom";
import { AlertsGrid } from "./AlertsGrid";
import { Box, Grid, Stack } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

export function AllAlertsBreadcrumb() {
  // breadcrumbs: Alerts / All

  let path = [];

  path.push(
    <BreadcrumbLink to={"/alerts"}>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <NotificationsIcon fontSize={"small"} /> <Box mt={4}>Alerts / All</Box>
      </Stack>
    </BreadcrumbLink>
  );

  return <BreadCrumb items={path} />;
}

function AlertsGridRouteComponent(props: RouteComponentProps<{ site_id?: string }>) {
  return (
    <Grid container style={{ overflowY: "auto", overflowX: "hidden", width: "100%" }}>
      <Grid item container xs={12} justifyContent="center" style={{ backgroundColor: "white" }}>
        <Grid
          item
          xs={10}
          ml={0}
          mt={1}
          style={{ minWidth: "768px", maxWidth: "1200px", padding: "0px 8px" }}
        >
          {/* <AllAlertsBreadcrumb /> */}
        </Grid>
      </Grid>
      <AlertsGrid siteId={props.match.params.site_id} />
    </Grid>
  );
}

export const AlertsGridRoute = AlertsGridRouteComponent;
