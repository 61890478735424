import * as React from "react";
import { useQuery, gql } from "@apollo/client";
import { Box, CardContent, CardHeader, Divider, Fade } from "@material-ui/core";
import { PrimaryCard } from "modules/common/components/PrimaryCard";
import AccountNameForm from "./AccountNameForm";
import AccountEmailForm from "./AccountEmailForm";
import { AccountOrganizations } from "./AccountOrganizationsForm";

export function AccountSettingsRoute() {
  const { data, loading } = useQuery(gql`
    query AccountSettings {
      viewer {
        id
        ...AccountNameForm_user
        ...AccountEmailForm_user
        ...AccountOrganizationsForm_user
      }
    }
    ${AccountNameForm.fragments.user}
    ${AccountEmailForm.fragments.user}
    ${AccountOrganizations.fragments.user}
  `);

  return (
    <PrimaryCard loading={loading}>
      <Fade in={!loading} appear={false}>
        <Box>
          {!loading && data && (
            <React.Fragment>
              <CardHeader title="Name" />
              <CardContent>
                <AccountNameForm user={data.viewer} />
              </CardContent>

              <Divider />

              <CardHeader title="Email" />
              <CardContent>
                <AccountEmailForm user={data.viewer} />
              </CardContent>

              <Divider />

              <CardHeader title="Organizations" />
              <CardContent>
                <AccountOrganizations user={data.viewer} />
              </CardContent>
            </React.Fragment>
          )}
        </Box>
      </Fade>
    </PrimaryCard>
  );
}
