import * as React from "react";
import { ReactNode, useState } from "react";
import { CircularProgress, TableCell, Tooltip, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { gql, useQuery } from "@apollo/client";
import { Button } from "sigil";
import moment from "moment";
import { FirmwareCell_firmware } from "generated-gql-types/FirmwareCell_firmware";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { ReactComponent as CircledArrowUp } from "./CircledArrowUp.svg";
import { UpgradeFirmwareDialog } from "./UpgradeFirmwareDialog";
import { DeviceFirmwareCell_device } from "generated-gql-types/DeviceFirmwareCell_device";
import { UpgradeFirmwareDialog_device } from "generated-gql-types/UpgradeFirmwareDialog_device";

const Subdued = styled("span")({
  opacity: 0.5,
});

type FirmwareCellProps = {
  firmware: FirmwareCell_firmware;
  onUpgrade?: () => void; // if defined, show an inline upgrade button
  device: DeviceFirmwareCell_device;
};
function FirmwareCellComponent(props: FirmwareCellProps) {
  const status = props.firmware.status;
  const version = props.firmware.currentVersion || "Unknown";

  // default treatment
  let cell = <>{version}</>;
  let tooltip: ReactNode = "";

  // too old for automatic upgrade
  if (status?.__typename === "FirmwareOutdated" && !status.isUpgradable) {
    cell = (
      <span>
        {version} <Subdued>(old)</Subdued>
      </span>
    );
    tooltip = (
      <>
        Firmware too old for automatic updates.
        <br />
        Must be updated using local web UI
      </>
    );
  }

  // upgrade available, but button not enabled
  if (status?.__typename === "FirmwareOutdated" && status.isUpgradable && !props.onUpgrade) {
    cell = (
      <Typography color="primary" display="inline">
        {version} <Subdued>(old)</Subdued>
      </Typography>
    );
    tooltip = (
      <>
        New firmware version available <strong>({status.latestVersion})</strong>
      </>
    );
  }

  // upgrade available
  if (status?.__typename === "FirmwareOutdated" && status.isUpgradable && props.onUpgrade) {
    cell = (
      <Button variant="text" color="primary" onClick={props.onUpgrade} style={{ marginLeft: -8 }}>
        <Typography>
          {version} <Subdued>(old)</Subdued>
          <CircledArrowUp style={{ marginLeft: 6 }} />
        </Typography>
      </Button>
    );
    tooltip = (
      <>
        Click to upgrade this {`${props.device.__typename}`} to the
        <br />
        latest firmware version <strong>({status.latestVersion})</strong>
      </>
    );
  }

  // upgrade in progress
  if (status?.__typename === "FirmwarePending") {
    cell = (
      <Subdued>
        {status.pendingVersion}
        <CircularProgress size={11} thickness={4} color="inherit" style={{ marginLeft: 6 }} />
      </Subdued>
    );
    tooltip = (
      <>
        Firmware update <strong>{status.step.toLowerCase().replace(/_/g, " ")}</strong>
        <br />
        <strong>{moment(status.updatedAt).fromNow()}</strong>
      </>
    );
  }

  // upgrade failed
  if (status?.__typename === "FirmwareFailed") {
    cell = (
      <Button variant="text" color="primary" onClick={props.onUpgrade} style={{ marginLeft: -8 }}>
        <Subdued>
          {status.pendingVersion} <i className="fas fa-exclamation-triangle" />
        </Subdued>
      </Button>
    );
    tooltip = (
      <>
        Firmware update failed:
        <br />
        <strong>{status.error}</strong>
        <br />
        <strong>{moment(status.updatedAt).fromNow()}</strong>
      </>
    );
  }

  return (
    <Tooltip title={tooltip} placement="top" interactive arrow>
      <TableCell>
        <>{cell}</>
      </TableCell>
    </Tooltip>
  );
}

export const FirmwareCell = createFragmentContainer(FirmwareCellComponent, {
  firmware: gql`
    fragment FirmwareCell_firmware on Firmware {
      currentVersion
      status {
        __typename
        ... on FirmwareOutdated {
          latestVersion
          isUpgradable
        }
        ... on FirmwarePending {
          pendingVersion
          updatedAt
          step
        }
        ... on FirmwareFailed {
          pendingVersion
          updatedAt
          error
        }
      }
    }
  `,
});

type DeviceFirmwareCellProps = {
  device: DeviceFirmwareCell_device;
  isAdmin: boolean;
};
function DeviceFirmwareCellComponent(props: DeviceFirmwareCellProps) {
  const firmware = props.device.firmware;

  // poll firmware status if upgrade in progress
  const isUpgrading = firmware.status?.__typename === "FirmwarePending";
  useQuery(firmwareCellRefetchQuery, {
    variables: { deviceId: props.device.id },
    skip: !isUpgrading,
    pollInterval: isUpgrading ? 10000 : 0,
    // force component to rerender on refetch, so the relative timestamp in the tooltip can update
    notifyOnNetworkStatusChange: true,
  });

  const [upgradingDevice, setUpgradingDevice] = useState<UpgradeFirmwareDialog_device | null>(null);
  const handleUpgrade = props.isAdmin ? () => setUpgradingDevice(props.device) : undefined;

  return (
    <>
      <FirmwareCell firmware={firmware} onUpgrade={handleUpgrade} device={props.device} />
      {upgradingDevice && (
        <UpgradeFirmwareDialog
          open={true}
          onClose={() => setUpgradingDevice(null)}
          device={upgradingDevice}
        />
      )}
    </>
  );
}

export const DeviceFirmwareCell = createFragmentContainer(DeviceFirmwareCellComponent, {
  device: gql`
    fragment DeviceFirmwareCell_device on Device {
      id
      firmware {
        ...FirmwareCell_firmware
        status {
          __typename
        }
      }
      ...UpgradeFirmwareDialog_device
    }
    ${FirmwareCell.fragments.firmware}
    ${UpgradeFirmwareDialog.fragments.device}
  `,
});

const firmwareCellRefetchQuery = gql`
  query FirmwareCellRefetchQuery($deviceId: ID!) {
    node(id: $deviceId) {
      # Sadface: Device doesn't implement Node, because our graphql server doesn't support interfaces extending interfaces
      # See https://github.com/graphql/graphql-spec/pull/373
      ... on Supervisor {
        ...DeviceFirmwareCell_device
      }
      ... on Motor {
        ...DeviceFirmwareCell_device
      }
    }
  }
  ${DeviceFirmwareCell.fragments.device}
`;
