import React from "react";
import { Grid } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  YAxisProps,
  XAxisProps,
} from "recharts";
import { Loading } from "modules/common/components/Loading";
import { Colors } from "sigil/src";

export interface BarSeries {
  id: string;
  name: string;
  dataKey: string;
  color: string;
}

interface ChartProps {
  data: object[];
  isLoading?: boolean;
  series: BarSeries[];
  xAxisProps: XAxisProps; // at least provide dataKey
  xAxisProps2?: XAxisProps; // secondary (e.g. year) axis
  yAxisPropsSet?: YAxisProps[];
  tooltipProps?: any;
}

export const SeriesColors = [
  Colors.leaf,
  Colors.ttBlue,
  Colors.rust,
  Colors.grass,
  Colors.wave,
  Colors.bloodOrange,
  Colors.sunflower,
];

const formatLegend = (value: string, entry: any, index: number) => {
  return <span style={{ color: "black" }}>{(entry.payload as any)?.label || value}</span>;
};

export const BasicBarChart = ({
  data,
  isLoading,
  series,
  xAxisProps,
  xAxisProps2,
  yAxisPropsSet,
  tooltipProps,
}: ChartProps) => {
  return (
    <Grid item container justifyContent="stretch" overflow="hidden" xs={12}>
      {isLoading ? (
        <Loading />
      ) : (
        <ResponsiveContainer width="100%" height={350} debounce={20}>
          <BarChart data={data} margin={{ right: 5 }}>
            <CartesianGrid strokeDasharray="2 5" vertical={false} />

            <XAxis xAxisId="0" {...xAxisProps} />
            {xAxisProps2 && <XAxis xAxisId="1" {...xAxisProps2} />}

            {yAxisPropsSet &&
              yAxisPropsSet.map((yAxisProps) => <YAxis key={yAxisProps.yAxisId} {...yAxisProps} />)}
            <Tooltip {...tooltipProps} />
            <Legend formatter={formatLegend} />
            {series.map((s) => {
              return (
                <Bar key={s.id} dataKey={s.dataKey} fill={s.color} label={s.name} barSize={8} />
              );
            })}
          </BarChart>
        </ResponsiveContainer>
      )}
    </Grid>
  );
};
