import * as React from "react";
import { Grid } from "@mui/material";
import { KeyMetric } from "./KeyMetric";
import { Section } from "modules/site-manager/routes/Site/SiteConfiguration/ConfigSection";
import WifiTetheringSharp from "@mui/icons-material/WifiTetheringSharp";
import {
  MotorSummaryTableProps,
  MotorSummarySingleTable,
  MotorSummaryTwinTables,
} from "./MotorSummaryTable";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

function toFixed1(val?: number | null) {
  return val && val.toFixed(1);
}

export function MotorSummarySection(props: MotorSummaryTableProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const telemetry = props.motorNode?.telemetry.nodes[0];

  // Only show "Off" when we know it's off. If no data, we want to show "N/A" instead
  const motorOff = telemetry?.motorOn === false;

  return (
    <Grid container>
      <Grid item xs={12} pl={1} pr={1}>
        <Section title="Motor Controller Info" icon={<WifiTetheringSharp />} />
      </Grid>
      {matches ? <MotorSummaryTwinTables {...props} /> : <MotorSummarySingleTable {...props} />}
      <Grid item container xs={12} justifyContent="space-between" wrap="wrap" spacing={0} mt={1}>
        <Grid item={true} xs={12} sm={6} md={3} pl={1} pr={1} pt={1}>
          <KeyMetric label="Current Speed" value={telemetry?.speedCurrent} units="rpm" />
        </Grid>
        <Grid item={true} xs={12} sm={6} md={3} pl={1} pr={1} pt={1}>
          <KeyMetric
            label="Requested Speed"
            value={motorOff ? "Off" : telemetry?.speedRequested}
            units={motorOff ? "" : "rpm"}
          />
        </Grid>
        <Grid item={true} xs={12} sm={6} md={3} pl={1} pr={1} pt={1}>
          <KeyMetric label="Torque" value={toFixed1(telemetry?.torque)} units="Nm" />
        </Grid>
        <Grid item={true} xs={12} sm={6} md={3} pl={1} pr={1} pt={1}>
          <KeyMetric
            label={`${telemetry?.power === null ? "Power Out" : "Power"}`}
            value={toFixed1(telemetry?.power === null ? telemetry?.powerOut : telemetry?.power)}
            units="W"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
