import * as React from "react";

import { Card } from "@material-ui/core";
import { CardProps } from "@material-ui/core/Card";

import { Hoverable } from "modules/common/components/Hoverable";

export class HoverableCard extends React.Component<CardProps> {
  render() {
    return (
      <Hoverable>
        {({ isHovering, onMouseEnter, onMouseLeave }) => (
          <Card
            {...this.props}
            raised={isHovering}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        )}
      </Hoverable>
    );
  }
}
