import { action, computed, IObservableArray, observable } from "mobx";
import { fromPromise, FULFILLED, PENDING } from "mobx-utils";
import { uniq, compact, merge } from "lodash";

import { APIStore } from "modules/common/stores";
import { DURATION, ANALYSIS_DATE_FORMAT } from "modules/common/utils/time";
import { stringifyQuery } from "modules/common/utils/query-params";
import { TSBucketGrouped } from "modules/common/stores/api";
import { YAxis } from "modules/charts/constants";
import { CtrlLogicPoint, CtrlVariable, MotorOpPoint } from "modules/site-manager/models";
import { SiteStore } from "./site";
import { CtrlVariablePresenter } from "modules/site-manager/presenters";
import { MotorOp } from "modules/site-manager/models/motor-op";
import { MotorOpStat, MotorOpStats } from "modules/site-manager/constants/motor-op";
import { EnergyData, EnergyDataPoint } from "modules/site-manager/models/energy-data";
import { EnergyDataStats } from "modules/site-manager/constants/energy-data";
import { LogicVarEditStatus } from "modules/site-manager/constants";
import moment from "moment";

const DEFAULT_RANGE = DURATION.HOUR * 3;

type IntervalPrecision = "d" | "h" | "m";
type IntervalDefinition = [number, IntervalPrecision];

export enum FieldKind {
  CtrlVariable,
  MotorOp,
  EnergyData,
}

export type ZippedTSPoint = {
  timestamp: string;
  ctrl: TSBucketGrouped<CtrlLogicPoint> | undefined;
  motor: TSBucketGrouped<MotorOpPoint | EnergyDataPoint> | undefined;
  energy: TSBucketGrouped<EnergyDataPoint> | undefined;
};

export interface AnalysisField {
  id: string;
  name: string;
  kind: FieldKind;
  yAxis: YAxis;
  aggregation: string;
  units?: string;
}

function isCtrlVariableField(field: AnalysisField): field is CtrlVariableField {
  return field.kind === FieldKind.CtrlVariable;
}

function isMotorOpField(field: AnalysisField): field is MotorOpField {
  return field.kind === FieldKind.MotorOp;
}

function isEnergyDataField(field: AnalysisField): field is EnergyDataField {
  return field.kind === FieldKind.EnergyData;
}

export class CtrlVariableField implements AnalysisField {
  kind = FieldKind.CtrlVariable;
  @observable yAxis: YAxis = YAxis.Left;
  @observable aggregation: string = "mean";

  constructor(public ctrlVariable: CtrlVariable) {
    this.aggregation = ctrlVariable.logicPointJS.isAnalog ? "mean" : "mode";
  }

  @computed get id() {
    return this.ctrlVariable.id;
  }

  @computed get name() {
    const presenter = new CtrlVariablePresenter(this.ctrlVariable);
    return `${presenter.controllerDisplayName}: ${presenter.name}`;
  }
}

export class MotorOpField implements AnalysisField {
  kind = FieldKind.MotorOp;
  @observable yAxis: YAxis = YAxis.Left;
  @observable aggregation: string = "mean";

  constructor(public motorOp: MotorOp) {}

  @computed get id() {
    return this.motorOp.id;
  }

  @computed get name() {
    return this.motorOp.name;
  }
}

export class EnergyDataField implements AnalysisField {
  kind = FieldKind.EnergyData;
  @observable yAxis: YAxis = YAxis.Left;
  @observable aggregation: string = "mean";

  constructor(public energyDatum: EnergyData) {}

  @computed get id() {
    return this.energyDatum.id;
  }

  @computed get name() {
    return this.energyDatum.name;
  }
}

const zipTimeseriesBucket = (
  target: TSBucketGrouped<any>[],
  sourceBucket: TSBucketGrouped<any>
): void => {
  const sourceTs = moment(sourceBucket.timestamp);
  for (let i = 0; i < target.length; i++) {
    const targetBucket = target[i];
    const targetTs = moment(targetBucket.timestamp);
    if (targetTs.isSame(sourceTs)) {
      merge(targetBucket.data, JSON.parse(JSON.stringify(sourceBucket.data, null, 2)));
      return;
    }
    if (targetTs.isAfter(sourceTs)) {
      target.splice(i, 0, sourceBucket);
      return;
    }
  }
  target.push(sourceBucket);
};

export const zipTimeseriesBuckets = (
  target: TSBucketGrouped<any>[],
  source: TSBucketGrouped<any>[]
): TSBucketGrouped<any>[] => {
  source.forEach((sourceBucket) => {
    zipTimeseriesBucket(target, sourceBucket);
  });
  return target;
};

export class AnalysisStore {
  @observable startTime = new Date(Date.now() - DEFAULT_RANGE);
  @observable endTime = new Date(Date.now());
  @observable interval?: IntervalDefinition;
  @observable selectionFilter = "";

  @observable selectedFields: IObservableArray<AnalysisField> = observable.array();

  constructor(private api: APIStore, public siteStore: SiteStore) {}

  @computed.struct get selectedCtrlVariableFields(): CtrlVariableField[] {
    const { selectedFields } = this;
    return selectedFields.filter((f) => isCtrlVariableField(f)).map((f) => f as CtrlVariableField);
  }

  @computed.struct get selectedMotorOpFields(): MotorOpField[] {
    const { selectedFields } = this;
    return selectedFields.filter((f) => isMotorOpField(f)).map((f) => f as MotorOpField);
  }

  @computed.struct get selectedEnergyDataFields(): EnergyDataField[] {
    const { selectedFields } = this;
    return selectedFields.filter((f) => isEnergyDataField(f)).map((f) => f as EnergyDataField);
  }

  @computed.struct get selectedCtrlVariables() {
    return this.selectedCtrlVariableFields.map((f) => f.ctrlVariable);
  }

  @computed.struct get unusedCtrlVariables() {
    const { selectedCtrlVariables, selectionFilter, siteStore } = this;
    return siteStore.site.allCtrlVariables.filter((v) => {
      return (
        selectedCtrlVariables.indexOf(v) < 0 &&
        v.editStatus === LogicVarEditStatus.READONLY &&
        CtrlVariablePresenter.matchExactText(v, selectionFilter)
      );
    });
  }

  @computed.struct get selectedMotors() {
    return this.selectedMotorOpFields.map((f) => f.motorOp.motor);
  }

  @computed.struct get unusedMotorOps(): MotorOp[] {
    const motors = this.siteStore.motors.all.map((m) => m.model);

    return motors.reduce((motorOps, motor) => {
      // TT Power currently only has ML models for V-series motors
      const stats = motor?.hasTurntidePower
        ? MotorOpStats
        : MotorOpStats.filter((stat) => stat !== MotorOpStat.TTPower);

      const opsForMotor = stats.map((stat) => {
        return new MotorOp(motor, stat);
      });
      return motorOps.concat(opsForMotor);
    }, [] as MotorOp[]);
  }

  @computed.struct get unusedEnergyPoints(): EnergyData[] {
    const motors = this.siteStore.motors.all.map((m) => m.model);

    return motors.reduce((energyPoints, motor) => {
      const pointsForMotor = EnergyDataStats.map((stat) => {
        return new EnergyData(motor, stat);
      });
      return energyPoints.concat(pointsForMotor);
    }, [] as EnergyData[]);
  }

  @computed.struct get timeFilterParams() {
    const { interval, startTime, endTime } = this;
    const filterInterval = interval || intervalFromTimeRange(startTime, endTime);
    return {
      "filter[start_time]": startTime.toISOString(),
      "filter[end_time]": endTime.toISOString(),
      "filter[interval_width]": filterInterval[0],
      "filter[interval_precision]": filterInterval[1],
    };
  }

  @computed.struct get ctrlIdsInUse() {
    const variables = this.selectedCtrlVariables;
    return uniq(compact(variables.map((v) => v.controllerId)));
  }

  @computed get ctrlPointsPromise() {
    if (!this.ctrlIdsInUse.length) {
      return fromPromise(Promise.resolve([]));
    }

    const params = {
      ...this.timeFilterParams,
      controller_ids: this.ctrlIdsInUse,
      sort: "recorded_at",
    };

    const url = `controller_logic_ts_agg/?${stringifyQuery(params)}`;
    return fromPromise(this.api.getAggregatedTimeseries(url, CtrlLogicPoint));
  }

  @computed get ctrlPoints(): TSBucketGrouped<CtrlLogicPoint>[] {
    const promise = this.ctrlPointsPromise;
    return promise.state === FULFILLED ? promise.value.slice() : [];
  }

  @computed get isLoadingCtrls() {
    return this.ctrlPointsPromise.state === PENDING;
  }

  @computed get motorPointsPromise() {
    const { selectedMotorOpFields } = this;
    if (!selectedMotorOpFields.length) {
      return fromPromise(Promise.resolve([]));
    }

    const motorIds = uniq(selectedMotorOpFields.map((f) => f.motorOp.motor.id));
    const params = {
      ...this.timeFilterParams,
      motor_ids: motorIds,
      "fields[motor_op]": [
        "recorded_at",
        "speed_cur",
        "speed_req",
        "power_in",
        "power_out",
        "torque",
        "current_a",
        "current_b",
        "current_c",
        "motor_on",
      ].join(","),
      sort: "recorded_at",
    };

    const motorOpUrl = `motor_op_ts_agg/?${stringifyQuery(params)}`;
    const motorOpPromise = this.api.getAggregatedTimeseries(motorOpUrl, MotorOpPoint);
    if (selectedMotorOpFields.find((field) => field.motorOp.op === MotorOpStat.TTPower)) {
      const params = {
        ...this.timeFilterParams,
        motor_ids: motorIds,
        "fields[motor_op]": ["recorded_at", "tt_power"].join(","),
        sort: "recorded_at",
      };

      const energyUrl = `energy_ts_agg/?${stringifyQuery(params)}`;
      const energyPromise = fromPromise(
        this.api.getAggregatedTimeseries(
          energyUrl,
          EnergyDataPoint,
          this.createFakeEmptyData(motorIds)
        )
      );
      return fromPromise(
        Promise.all([motorOpPromise, energyPromise]).then((values) => {
          return zipTimeseriesBuckets(values[0], values[1]);
        })
      );
    } else {
      return fromPromise(motorOpPromise);
    }
  }

  @computed get motorPoints(): TSBucketGrouped<MotorOpPoint>[] {
    const promise = this.motorPointsPromise;
    return promise.state === FULFILLED ? promise.value.slice() : [];
  }

  @computed get isLoadingMotors() {
    return this.motorPointsPromise.state === PENDING;
  }

  createFakeEmptyData = (motorIds: string[]) => {
    const create0PointMetrics = (motorId: string, t: Date) => ({
      ts: t,
      motor_id: motorId,
      tt_power: 0,
      tt_power_with_compressor_penalty: 0,
      baseline_power: 0,
    });

    const create0PointFlattenedObj = (t: Date) => {
      const defaultValue: { [key: string]: any | null } = { ts: moment(t).format() };
      motorIds.forEach((motorId) => {
        defaultValue[`${motorId}_mean`] = create0PointMetrics(motorId, t);
        defaultValue[motorId] = create0PointMetrics(motorId, t);
      });
      return defaultValue;
    };

    const interval = wideIntervalFromTimeRange(this.startTime, this.endTime);
    const currentTime = moment(this.startTime);
    const endMoment = moment(this.endTime);
    const fakeData = [];
    while (currentTime < endMoment) {
      fakeData.push(create0PointFlattenedObj(currentTime.toDate()));
      currentTime.add(moment.duration(interval[0], interval[1]));
    }
    fakeData.push(create0PointFlattenedObj(this.endTime));

    return fakeData;
  };

  @computed get energyPointsPromise() {
    const { selectedEnergyDataFields } = this;
    if (!selectedEnergyDataFields.length) {
      return fromPromise(Promise.resolve([]));
    }

    const motorIds = uniq(selectedEnergyDataFields.map((f) => f.energyDatum.motor.id));
    const params = {
      ...this.timeFilterParams,
      motor_ids: motorIds,
      "fields[motor_op]": [
        "recorded_at",
        "baseline_power",
        "tt_power",
        "tt_power_with_compressor_penalty",
      ].join(","),
      sort: "recorded_at",
    };

    const url = `energy_ts_agg/?${stringifyQuery(params)}`;
    return fromPromise(
      this.api.getAggregatedTimeseries(url, EnergyDataPoint, this.createFakeEmptyData(motorIds))
    );
  }

  @computed get energyPoints(): TSBucketGrouped<EnergyDataPoint>[] {
    const promise = this.energyPointsPromise;
    return promise.state === FULFILLED ? promise.value.slice() : [];
  }

  @computed get isLoadingEnergy() {
    return this.energyPointsPromise.state === PENDING;
  }

  // FIXME: This should not be done on the frontend. The only guarantee that the
  // buckets are equal throughout the array is that the backend implementation
  // matches for both. All this should be done via one backend endpoint
  @computed get zippedTimeseriesBuckets() {
    const { ctrlPoints, motorPoints, energyPoints } = this;
    const len = Math.max(ctrlPoints.length, motorPoints.length, energyPoints.length);
    const zipped = [];
    for (let i = 0; i < len; i++) {
      const ctrlPoint = ctrlPoints[i];
      const motorPoint = motorPoints[i];
      const energyPoint = energyPoints[i];
      zipped.push({
        timestamp: (ctrlPoint || motorPoint || energyPoint).timestamp,
        ctrl: ctrlPoint,
        motor: motorPoint,
        energy: energyPoint,
      } as ZippedTSPoint);
    }
    return zipped;
  }

  @computed get dateQueryParam() {
    return `&d=${moment(this.startTime).format(ANALYSIS_DATE_FORMAT)},${moment(this.endTime).format(
      ANALYSIS_DATE_FORMAT
    )}`;
  }

  toggleField = (field: AnalysisField, onChangeCallback: () => void) =>
    action("toggleField", () => {
      const { selectedFields } = this;
      const currentIndex = selectedFields.indexOf(field);

      if (currentIndex < 0) {
        selectedFields.push(field);
      } else {
        selectedFields.splice(currentIndex, 1);
      }
      onChangeCallback();
    });

  @action
  addCtrlVariableField = (ctrlVar: CtrlVariable) => {
    const newField = new CtrlVariableField(ctrlVar);
    this.selectedFields.push(newField);
    return newField;
  };

  @action
  addMotorOpField = (motorOp: MotorOp) => {
    const newField = new MotorOpField(motorOp);
    this.selectedFields.push(newField);
    return newField;
  };
  @action
  addEnergyDataField = (energyData: EnergyData) => {
    const newField = new EnergyDataField(energyData);
    this.selectedFields.push(newField);
    return newField;
  };

  @action
  setAxis = (field: AnalysisField, axis: YAxis) => {
    field.yAxis = axis;
  };

  @action
  setUnits = (field: AnalysisField, units: string) => {
    field.units = units;
  };

  @action
  setAggregation = (field: AnalysisField, agg: string) => {
    field.aggregation = agg;
  };

  @action
  setSelectionFilter = (filter?: string) => {
    this.selectionFilter = filter || "";
  };

  @action
  setStartTime = (startTime?: Date | string): void => {
    if (!(startTime instanceof Date)) {
      return;
    }

    this.startTime = startTime;
    if (startTime > this.endTime) {
      this.endTime = new Date(startTime.getTime() + DEFAULT_RANGE);
    }
  };

  @action
  setEndTime = (endTime?: Date | string): void => {
    if (!(endTime instanceof Date)) {
      return;
    }

    this.endTime = endTime;
    if (endTime < this.startTime) {
      this.startTime = new Date(endTime.getTime() - DEFAULT_RANGE);
    }
  };

  @action
  setTimes = (startTime?: Date | string, endTime?: Date | string): void => {
    this.setStartTime(startTime);
    this.setEndTime(endTime);
  };

  @action
  setInterval = (interval?: IntervalDefinition) => {
    this.interval = interval;
  };
}

function intervalFromTimeRange(startTime: Date, endTime: Date): IntervalDefinition {
  const dt = Math.abs(endTime.getTime() - startTime.getTime());
  if (dt > 2 * DURATION.WEEK) {
    return [1, "h"];
  } // 720pts max
  if (dt > 5 * DURATION.DAY) {
    return [30, "m"];
  } // 720pts max
  if (dt > 2 * DURATION.DAY) {
    return [10, "m"];
  } // 576pts max
  if (dt > 12 * DURATION.HOUR) {
    return [5, "m"];
  } // 720pts max
  return [1, "m"];
}

function wideIntervalFromTimeRange(startTime: Date, endTime: Date): IntervalDefinition {
  const dt = Math.abs(endTime.getTime() - startTime.getTime());
  if (dt > 2 * DURATION.DAY) {
    return [12, "h"];
  } // 576pts max
  if (dt > 12 * DURATION.HOUR) {
    return [1, "h"];
  } // 720pts max
  return [5, "m"];
}
