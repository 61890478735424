import * as React from "react";
import { useCallback } from "react";
import { gql } from "@apollo/client";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { SimpleConfirmationDialog } from "modules/common/components/SimpleConfirmationDialog";
import { useRemoveOrganizationMemberMutation } from "modules/common/mutations/RemoveOrganizationMemberMutation";
import { useSnackbar } from "notistack";
import {
  AccountOrganizationsForm_user,
  AccountOrganizationsForm_user_organizations_nodes,
} from "generated-gql-types/AccountOrganizationsForm_user";

interface LeaveOrgDialogProps {
  user: AccountOrganizationsForm_user;
  organization: AccountOrganizationsForm_user_organizations_nodes;
  open: boolean;
  onClose: () => void;
}

function LeaveOrgDialog({ user, organization, open, onClose }: LeaveOrgDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const removeMember = useRemoveOrganizationMemberMutation();
  const handleConfirm = useCallback(async () => {
    try {
      await removeMember({ userId: user.id, organizationId: organization.id });
      return onClose();
    } catch (e) {
      enqueueSnackbar("Error while attempting to leave organization", { variant: "error" });
    }
  }, [user, organization, onClose, enqueueSnackbar, removeMember]);

  return (
    <SimpleConfirmationDialog
      open={open}
      title={`Confirm removal of ${user.fullName || "user"}`}
      body={`Remove user ${user.email} from organization "${organization.name}"?`}
      confirmLabel="Remove"
      onClose={onClose}
      onConfirm={handleConfirm}
    />
  );
}

export default createFragmentContainer(LeaveOrgDialog, {
  user: gql`
    fragment LeaveOrgDialog_user on User {
      id
      fullName
      email
    }
  `,
  organization: gql`
    fragment LeaveOrgDialog_organization on Organization {
      id
      name
    }
  `,
});
