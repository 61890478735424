import { useMutation, gql } from "@apollo/client";
import { UpdateMotorNameInput } from "generated-gql-types/globalTypes";

const mutation = gql`
  mutation UpdateMotorName($input: UpdateMotorNameInput!) {
    updateMotorName(input: $input) {
      motor {
        id
        name
        displayName
      }
    }
  }
`;

export function useUpdateMotorNameMutation() {
  const [mutate] = useMutation(mutation);
  return (input: UpdateMotorNameInput) => mutate({ variables: { input } });
}
