import { BMSBehaviorKind, BmsRole } from "modules/site-manager/constants";
import { action } from "mobx";

export interface LogicValueContainer {
  bmsRoleId?: BmsRole;
  displayName?: string;
  behaviorKind: BMSBehaviorKind;
  floatValue: number | undefined;
  booleanValue: boolean | undefined;
  [key: string]: any;
}

export function getAppropriateValue(container: LogicValueContainer) {
  switch (container.behaviorKind) {
    case BMSBehaviorKind.Float:
    case BMSBehaviorKind.Bitmask:
      return container.floatValue;
    case BMSBehaviorKind.Bool:
      return container.booleanValue;
    default:
      return;
  }
}

export function setAppropriateValue(
  container: LogicValueContainer,
  val: number | string | boolean | undefined | null
) {
  switch (container.behaviorKind) {
    case BMSBehaviorKind.Float:
    case BMSBehaviorKind.Bitmask:
      action(() => {
        container.booleanValue = undefined;
        setFloatValue(container, val);
      })();
      return;
    case BMSBehaviorKind.Bool:
      action(() => {
        container.floatValue = undefined;
        container.booleanValue = !!val;
      })();
      return;
    default:
      return;
  }
}

function setFloatValue(
  container: LogicValueContainer,
  val: number | string | boolean | undefined | null
) {
  if (val === "" || val === undefined || val === null) {
    container.floatValue = undefined;
    return;
  }
  let parsedVal = parseFloat(`${val}`);
  if (!isNaN(parsedVal)) {
    container.floatValue = parsedVal;
  }
}
