import * as React from "react";
import { observer } from "mobx-react";
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  List,
  Theme,
  Typography,
} from "@material-ui/core";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import { CloseButton } from "modules/common/components/CloseButton";
import { Motor, MotorGroup } from "modules/site-manager/models";
import { HealthIcon } from "modules/site-manager/components/HealthIcon";
import { MotorDetailsPane } from "modules/site-manager/components/MotorDetailsPane";
import { StyledListItem, StyledListItemIcon, StyledListItemText } from "./styled";
import { useQuery } from "@apollo/client";
import { HealthDiagnostic_motor_Motor } from "generated-gql-types/HealthDiagnostic";
import { Health } from "modules/common/enums";
import { MOTOR_GROUP_HEALTH_DIAGNOSTIC_QUERY } from "modules/common/queries/GetMotorGroupFaultConditions";
import { marshalStringId } from "modules/common/utils/relay";
import { MotorStatsList_node_Site_motors } from "generated-gql-types/MotorStatsList";
import { EditableMotorTitle } from "../EditableMotorTitle";

const styles = (theme: Theme) =>
  createStyles({
    header: {
      padding: 19,
      textAlign: "center",
      position: "relative",
    },
    sidebar: {
      backgroundColor: theme.palette.background.default,
      position: "relative",
      "&::after": {
        content: "''",
        position: "absolute",
        width: 7,
        right: 0,
        top: 0,
        bottom: 0,
        background: "linear-gradient(-90deg, rgba(0, 0, 0, 0.05), rgba(196, 196, 196, 0))",
        pointerEvents: "none",
      },
    },
    pane: {
      padding: 20,
    },
  });

type MotorGroupDetailsDialogProps = {
  motorGroup: MotorGroup;
  selectedMotor: Motor;
  selectedMotorNode?: MotorStatsList_node_Site_motors;
  onSelectMotor: (motor: Motor) => void;
  open: boolean;
  onClose: () => void;
};

function MotorGroupDetailsDialogComponent({
  motorGroup,
  selectedMotor,
  selectedMotorNode,
  onSelectMotor,
  open,
  onClose,
  classes,
}: MotorGroupDetailsDialogProps & WithStyles<typeof styles>) {
  const marshaledId = marshalStringId("motorGroup", motorGroup.id);
  const { data, loading } = useQuery(MOTOR_GROUP_HEALTH_DIAGNOSTIC_QUERY, {
    variables: { motorGroupId: marshaledId },
  });
  if (loading) {
    return null;
  }
  const motorData = data?.motorGroup?.motors as HealthDiagnostic_motor_Motor[];

  const hasFaultConditions = (motorId: string) => {
    return (
      motorData?.filter((m) => {
        const decoded = atob(m.id),
          colon = decoded.indexOf(":");
        const id = decoded.slice(colon + 1).replace(/^"(.*)"$/, "$1");
        return id.toString() === motorId && m.healthDiagnostics.length > 0;
      }).length > 0
    );
  };

  return (
    <Dialog open={open} scroll="paper" maxWidth="lg" onClose={onClose}>
      <header className={classes.header}>
        <Typography variant="h4">
          <strong>{motorGroup.displayName}</strong>
        </Typography>
        {!!motorGroup.name && (
          <Typography variant="caption">
            <Box color="text.hint">Motor Group {motorGroup.groupNum}</Box>
          </Typography>
        )}

        <Box
          position="absolute"
          top={0}
          bottom={0}
          right={20}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          fontSize="13px"
          color="text.disabled"
        >
          <CloseButton onClick={onClose} />
        </Box>
      </header>
      <Divider />

      <DialogContent>
        <Grid container={true}>
          <Grid item={true} md={3} sm={12} xs={12} className={classes.sidebar}>
            <List component="nav">
              {motorGroup.sortedMotors.map((motor) => (
                <StyledListItem
                  key={motor.id}
                  button={true}
                  selected={motor === selectedMotor}
                  onClick={() => onSelectMotor(motor)}
                >
                  <StyledListItemText
                    primary={motor.displayName}
                    secondary={motor.ctrlModbusAddress}
                  />
                  <StyledListItemIcon>
                    <HealthIcon
                      health={hasFaultConditions(motor.id) ? Health.ERROR : motor.health}
                    />
                  </StyledListItemIcon>
                </StyledListItem>
              ))}
            </List>
          </Grid>

          <Grid item={true} md={9} sm={12} className={classes.pane}>
            <Box pl={1} pb={1}>
              <EditableMotorTitle
                motor={selectedMotor}
                motorNode={selectedMotorNode || ({} as MotorStatsList_node_Site_motors)}
              />
            </Box>
            <MotorDetailsPane motor={selectedMotor} motorNode={selectedMotorNode} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export const MotorGroupDetailsDialog = withStyles(styles)(
  observer(MotorGroupDetailsDialogComponent)
);
