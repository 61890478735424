import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";

export const StyledSnackbarProvider = withStyles((theme) => ({
  variantSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  variantError: {
    backgroundColor: theme.palette.danger.main,
  },
  variantWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  variantInfo: {
    backgroundColor: theme.palette.secondary.main,
  },
}))(SnackbarProvider);

StyledSnackbarProvider.defaultProps = {
  maxSnack: 1,
  iconVariant: {
    success: <i className="fas fa-check-circle fa-lg" style={{ marginRight: "1em" }} />,
    error: "",
    warning: "",
    info: "",
  },
};
