import { Map, NavigationControl, PaddingOptions } from "mapbox-gl";
import { MutableRefObject, useRef, useState, useEffect } from "react";
import { LargeOk, LargeAlert, SmallOk, SmallAlert } from "./Icons";
import * as Sentry from "@sentry/react";

const loadSvg = (svg: string, name: string, map: Map, width: number, height: number) =>
  new Promise((resolve) => {
    if (map.hasImage(name)) {
      resolve(name);
      return;
    }
    const img = new Image(width * 2, height * 2);
    img.onload = () => {
      map.addImage(name, img, { pixelRatio: 2 });
      resolve(name);
    };
    img.src = svg;
  });

// This hook builds a mapbox map instance.
// The returned `map` instance will be null at first so wrap any changes you have in
// if (map) {/* code */}.
export const useMap = (
  interactive: boolean,
  padding?: PaddingOptions
): [Map | undefined, MutableRefObject<HTMLDivElement | null>, boolean] => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [theMap, setMap] = useState<Map | undefined>(undefined);
  const [loaded, setLoaded] = useState<boolean>(false);
  // const [styled, setStyled] = useState<boolean>(false);

  useEffect(() => {
    if (!interactive) {
      // No map on mobile
      return;
    }

    const map = new Map({
      container: (mapContainerRef.current as HTMLElement) ?? "",
      style: "mapbox://styles/softwaremotor/ck4bqv1la0ufd1cmlevram6sk",
    });
    setMap(map);

    map.on("load", async () => {
      const promises = [
        loadSvg(LargeOk, "LargeOk", map, 24, 27),
        loadSvg(LargeAlert, "LargeAlert", map, 24, 27),
        loadSvg(SmallOk, "SmallOk", map, 11, 11),
        loadSvg(SmallAlert, "SmallAlert", map, 11, 11),
      ];
      try {
        await Promise.all(promises);
        setLoaded(true);
      } catch (e: any) {
        Sentry.captureException(e);
      }
    });

    if (interactive) {
      const control = new NavigationControl({ showCompass: false });
      map.addControl(control, "top-right");
    }
    if (/debug/.test(window.location.search)) map.showCollisionBoxes = true;
    // @ts-ignore: mapbox types are too old. This sets the global padding on the map by side effect,
    // which like uhhh ....
    if (padding) map.jumpTo({ padding });

    return () => {
      setLoaded(false);
      map.remove();
      setMap(undefined);
    };
  }, [interactive, padding]);
  return [theMap, mapContainerRef, loaded];
};
