import * as React from "react";
import { Card, Collapse, CardContent, Select, MenuItem, Box } from "@material-ui/core";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import { observable, action, computed } from "mobx";
import { Observer, observer } from "mobx-react";
import { LightLabel } from "modules/common/components/Typography";
import { Site, Priority, Priorities } from "modules/site-manager/models";
import { SiteMetricsTable } from "modules/site-manager/components/SiteMetricsTable";
import { PriorityPresenter, CtrlVariablePresenter } from "modules/site-manager/presenters";
import { PriorityBars } from "modules/site-manager/components/PriorityBars";
import { WithSiteRole } from "modules/site-manager/components/WithSiteRole";
import { TextFilterField } from "modules/common/components/TextFilterField";
import { marshalNumberId } from "modules/common/utils/relay";

interface SiteMetricsCardProps {
  site: Site;
}

const styles = () =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
    },
    textField: {
      flexGrow: 4,
    },
    priorityLabel: {
      marginLeft: "80px",
      marginRight: "10px",
    },
    prioritySelect: {
      flexGrow: 1,
    },
  });

@observer
class SiteMetricsCardComponent extends React.Component<
  WithStyles<typeof styles> & SiteMetricsCardProps
> {
  @observable textFilter = "";
  @observable priorityFilter = Priority.Normal;

  @action
  setTextFilter(value: string) {
    this.textFilter = value;
  }

  @action
  setPriorityFilter(value: Priority) {
    this.priorityFilter = value;
  }

  @computed
  get variables() {
    const {
      textFilter,
      priorityFilter,
      props: { site },
    } = this;
    return site.allCtrlVariables.filter((ctrlVar) => {
      return (
        ctrlVar.priority >= priorityFilter &&
        CtrlVariablePresenter.matchExactText(ctrlVar, textFilter.trim().toLocaleLowerCase())
      );
    });
  }

  render() {
    const { site, classes } = this.props;
    const siteAnalysisPath = `/sites/${site.id}/analysis`;
    return (
      <Card>
        <CardContent className={classes.root}>
          <TextFilterField
            className={classes.textField}
            value={this.textFilter}
            onChange={(e) => this.setTextFilter(e.target.value)}
          />

          <LightLabel className={classes.priorityLabel}>MIN. PRIORITY</LightLabel>
          <Select
            value={this.priorityFilter}
            onChange={(e) =>
              this.setPriorityFilter(parseInt(e.target.value as string, 10) as Priority)
            }
            className={classes.prioritySelect}
          >
            {Priorities.map((prio, index) => (
              <MenuItem value={prio} key={index}>
                <Box display="flex" alignItems="center">
                  <PriorityBars priority={prio} />
                  <div>{new PriorityPresenter(prio).displayName}</div>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </CardContent>

        <Collapse in={true} appear={true} style={{ overflow: "auto" }}>
          <WithSiteRole siteId={marshalNumberId("site", parseInt(site.id, 10))}>
            {({ isAdmin }) => (
              <Observer>
                {() => (
                  <SiteMetricsTable
                    ctrlVars={this.variables}
                    analysisPath={siteAnalysisPath}
                    allowEdit={isAdmin}
                  />
                )}
              </Observer>
            )}
          </WithSiteRole>
        </Collapse>
      </Card>
    );
  }
}

export const SiteMetricsCard = withStyles(styles)(SiteMetricsCardComponent);
