import { BmsRole } from "modules/site-manager/constants/bms-role";

type BmsRolePresentation = {
  name: string;
  shortName?: string;
};

const DefaultPresentation = {
  name: "Unknown Role",
  shortName: "Unknown",
};

const BmsRoleDictionary: { [key: number]: BmsRolePresentation | undefined } = {
  [BmsRole.UNASSIGNED]: {
    name: "Unassigned",
  },
  [BmsRole.MIN_TEMP_SETTING]: {
    name: "Minimum Temperature Setting",
    shortName: "Min. Temp",
  },
  [BmsRole.MAX_TEMP_SETTING]: {
    name: "Maximum Temperature Setting",
    shortName: "Max. Temp",
  },
  [BmsRole.MIN_STATIC_PRESS_SETTING]: {
    name: "Minimum Static Pressure Setpoint",
    shortName: "Min. Static Pressure",
  },
  [BmsRole.MAX_STATIC_PRESS_SETTING]: {
    name: "Maximum Static Pressure Setpoint",
    shortName: "Max. Static Pressure",
  },
  [BmsRole.STATIC_PRESS]: {
    name: "Static Pressure",
  },
  [BmsRole.HVAC_FAN_MODE]: {
    name: "HVAC Fan Mode",
    shortName: "Fan Mode",
  },
  [BmsRole.HVAC_OP_MODE]: {
    name: "HVAC Operating Mode",
    shortName: "Operating Mode",
  },
  [BmsRole.TEMP_OSA]: {
    name: "Outside Air Temperature",
    shortName: "Outside Temp.",
  },
  [BmsRole.TEMP_RETURN]: {
    name: "Return Air Temperature",
    shortName: "Return Temp.",
  },
  [BmsRole.TEMP_SUPPLY]: {
    name: "Supply Air Temperature",
    shortName: "Supply Temp.",
  },
  [BmsRole.TEMP_SPACE]: {
    name: "Space Temperature",
    shortName: "Space Temp.",
  },
  [BmsRole.LIGHT_LVL_SETTING]: {
    name: "Light Level Setting",
  },
  [BmsRole.LIGHT_LVL]: {
    name: "Light Level",
  },
  [BmsRole.HUMIDITY_INSIDE]: {
    name: "Inside Humidity",
  },
  [BmsRole.HUMIDITY_OUTSIDE]: {
    name: "Outside Humidity",
  },
  [BmsRole.HUMIDITY_SETTING]: {
    name: "Humidity Setpoint",
  },
  [BmsRole.CUSTOM_1]: {
    name: "Custom 1",
  },
  [BmsRole.CUSTOM_2]: {
    name: "Custom 2",
  },
  [BmsRole.CUSTOM_3]: {
    name: "Custom 3",
  },
  [BmsRole.CUSTOM_4]: {
    name: "Custom 4",
  },
  [BmsRole.CUSTOM_5]: {
    name: "Custom 5",
  },
};

export class BmsRolePresenter {
  constructor(private role: BmsRole | undefined) {}

  get dictEntry() {
    if (this.role === undefined) {
      return DefaultPresentation;
    }
    return BmsRoleDictionary[this.role];
  }

  get name(): string {
    return this.dictEntry ? this.dictEntry.name : "Unknown Role";
  }

  get shortName(): string {
    const { dictEntry } = this;
    return dictEntry && dictEntry.shortName ? dictEntry.shortName : this.name;
  }
}
