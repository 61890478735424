export enum FanModeBitmask {
  OFF = 0,
  ON,
  AUTO,
}

export enum HvacOpModeBitmask {
  OFF = 0,
  VENT = 1,
  COOL1 = 2,
  COOL2 = 3,
  HEAT1 = 4,
  HEAT2 = 5,
}
