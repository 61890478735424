import { computed } from "mobx";

import { CtrlVariable } from "modules/site-manager/models";
import { BmsRolePresenter } from "./index";
import { compact } from "lodash";

export class CtrlVariablePresenter {
  private readonly variable: CtrlVariable;

  @computed get name() {
    const { bmsRoleId, displayName } = this.variable;
    return bmsRoleId ? new BmsRolePresenter(bmsRoleId).name : displayName;
  }

  @computed get displayName() {
    return this.variable.displayName;
  }

  @computed get role() {
    const { bmsRoleId } = this.variable;
    if (bmsRoleId === 0) {
      return "";
    }
    const rolePresenter = new BmsRolePresenter(bmsRoleId);
    return rolePresenter.name;
  }

  @computed get controllerDisplayName() {
    const { ctrl, controllerId } = this.variable;
    return ctrl ? ctrl.displayName : controllerId || "";
  }

  @computed get logicPoint() {
    return this.variable.logicPointJS.name;
  }

  @computed get displayValue() {
    return this.variable.isSetByClient ? this.variable.clientSetValue : this.variable.currentValue;
  }

  constructor(variable: CtrlVariable) {
    this.variable = variable;
  }

  static matchExactText = (instance: CtrlVariable, filter: string) => {
    const bmsRole = new BmsRolePresenter(instance.bmsRoleId).dictEntry;
    const filteredFields: string[] = compact([
      instance.displayName,
      instance.logicPoint,
      instance.logicPointJS.name || "",
      instance.controllerId || "",
      instance.ctrl ? instance.ctrl.displayName : "",
      bmsRole ? bmsRole.name : "",
    ]);
    return filteredFields.some((val) => val.toLowerCase().includes(filter));
  };
}
