import React, { MouseEvent, useCallback, useState } from "react";
import { gql } from "@apollo/client";
import { Grid } from "@mui/material";
import { Button } from "sigil";
import {
  AccountOrganizationsForm_user,
  AccountOrganizationsForm_user_organizations_nodes,
} from "generated-gql-types/AccountOrganizationsForm_user";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { OrganizationRow } from "./OrganizationRow";
import LeaveOrgDialog from "./LeaveOrgDialog";

interface AccountOrganizationsFormProps {
  user: AccountOrganizationsForm_user;
}

interface LeaveActionProps {
  user: AccountOrganizationsForm_user;
  organization: AccountOrganizationsForm_user_organizations_nodes;
  onClick: (
    event: MouseEvent,
    user: AccountOrganizationsForm_user,
    organization: AccountOrganizationsForm_user_organizations_nodes
  ) => void;
}

function LeaveAction({ user, organization, onClick }: LeaveActionProps) {
  const handleClick = useCallback(
    (event: MouseEvent) => onClick(event, user, organization),
    [user, organization, onClick]
  );
  return (
    <Button variant="knockout" size="small" color="dangeresque" onClick={handleClick}>
      leave
    </Button>
  );
}

function AccountOrganizationsComponent({ user }: AccountOrganizationsFormProps) {
  const [leavingOrg, setLeavingOrg] =
    useState<AccountOrganizationsForm_user_organizations_nodes | null>(null);
  const handleLeaveOrg = (
    event: MouseEvent,
    user: AccountOrganizationsForm_user,
    org: AccountOrganizationsForm_user_organizations_nodes
  ) => setLeavingOrg(org);
  return (
    <>
      <Grid container direction="column">
        {user.organizations.nodes.map((organization) => (
          <OrganizationRow
            organization={organization}
            Action={
              <LeaveAction user={user} organization={organization} onClick={handleLeaveOrg} />
            }
            key={organization.id}
          />
        ))}
      </Grid>
      {leavingOrg && (
        <LeaveOrgDialog
          open={true}
          onClose={() => setLeavingOrg(null)}
          user={user}
          organization={leavingOrg}
        />
      )}
    </>
  );
}

export const AccountOrganizations = createFragmentContainer(AccountOrganizationsComponent, {
  user: gql`
    fragment AccountOrganizationsForm_user on User {
      id
      ...LeaveOrgDialog_user
      organizations(first: 500) {
        nodes {
          id
          name
          viewerIsAdmin
          viewerIsGuest
          ...LeaveOrgDialog_organization
        }
      }
    }
    ${LeaveOrgDialog.fragments.user}
    ${LeaveOrgDialog.fragments.organization}
  `,
});
