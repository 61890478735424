import * as React from "react";
import { BottomNavigation, BottomNavigationAction, Divider } from "@mui/material";
import SitesIcon from "@mui/icons-material/ApartmentSharp";
import AlertsIcon from "@mui/icons-material/NotificationsSharp";
import SettingsIcon from "@mui/icons-material/SettingsSharp";
import MapIcon from "@mui/icons-material/MapSharp";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ToggleBottomNavigationAction } from "../NavButton";

export const BottomNav = () => {
  const [value, setValue] = React.useState("recents");
  const history = useHistory();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    history.push(newValue);
  };

  return (
    <Grid position="fixed" bottom={0} width="100%">
      <Divider />
      <BottomNavigation showLabels sx={{ width: "100%" }} value={value} onChange={handleChange}>
        <ToggleBottomNavigationAction
          label="Sites"
          sitesTo="/sites"
          sitesIcon={<MapIcon />}
          organizationsIcon={<SitesIcon />}
          organizationsTo="/organizations"
          handleChange={handleChange}
        />
        <BottomNavigationAction label="Alerts" value="/alerts" icon={<AlertsIcon />} />
        <BottomNavigationAction label="Settings" value="/settings" icon={<SettingsIcon />} />
      </BottomNavigation>
    </Grid>
  );
};
