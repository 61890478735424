import * as React from "react";
import { useCallback } from "react";
import { gql } from "@apollo/client";
import { RemoveMemberDialog_member } from "generated-gql-types/RemoveMemberDialog_member";
import { RemoveMemberDialog_organization } from "generated-gql-types/RemoveMemberDialog_organization";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { SimpleConfirmationDialog } from "modules/common/components/SimpleConfirmationDialog";
import { useRemoveOrganizationMemberMutation } from "modules/common/mutations/RemoveOrganizationMemberMutation";
import { useSnackbar } from "notistack";

interface RemoveMemberDialogProps {
  member: RemoveMemberDialog_member;
  organization: RemoveMemberDialog_organization;
  open: boolean;
  onClose: () => void;
}

function RemoveMemberDialog({ member, organization, open, onClose }: RemoveMemberDialogProps) {
  const user = member.node;

  const { enqueueSnackbar } = useSnackbar();
  const removeMember = useRemoveOrganizationMemberMutation();
  const handleConfirm = useCallback(async () => {
    try {
      await removeMember({ userId: user.id, organizationId: organization.id });
      return onClose();
    } catch (e) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  }, [user, organization, onClose, enqueueSnackbar, removeMember]);

  return (
    <SimpleConfirmationDialog
      open={open}
      title={`Confirm removal of ${user.fullName || "user"}`}
      body={`Remove user ${user.email} from organization "${organization.name}"?`}
      confirmLabel="Remove"
      onClose={onClose}
      onConfirm={handleConfirm}
    />
  );
}

export default createFragmentContainer(RemoveMemberDialog, {
  member: gql`
    fragment RemoveMemberDialog_member on OrganizationUserEdge {
      node {
        id
        fullName
        email
      }
    }
  `,
  organization: gql`
    fragment RemoveMemberDialog_organization on Organization {
      id
      name
    }
  `,
});
