import { createStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "sigil";

export const LogicExplorerStyles = (theme: Theme) => {
  const { primary } = theme.palette.text;

  return createStyles({
    addMetric: {},
    controlIcon: {
      color: Colors.tin,
      cursor: "pointer",
      "&:hover": {
        color: primary,
      },
    },
    controlIconActive: {
      color: primary,
    },
  });
};
