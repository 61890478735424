import * as React from "react";
import { gql } from "@apollo/client";
import { useSnackbar } from "notistack";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { SimpleConfirmationDialog } from "modules/common/components/SimpleConfirmationDialog";
import { useClearMotorFaultsMutation } from "modules/common/mutations/ClearMotorFaultsMutation";
import { ClearMotorFaultsDialog_device } from "generated-gql-types/ClearMotorFaultsDialog_device";

type ClearMotorFaultsDialogProps = {
  open: boolean;
  onClose: (success: boolean) => void;
  device: ClearMotorFaultsDialog_device;
};

function ClearMotorFaultsDialogComponent(props: ClearMotorFaultsDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const ClearMotorFaults = useClearMotorFaultsMutation();

  return (
    <SimpleConfirmationDialog
      open={props.open}
      onClose={() => props.onClose(false)}
      title="Clear Motor Fault Alerts?"
      body={
        <>
          This will clear all faults on the motor <strong>{props.device.displayName}</strong> that
          are no longer in an active fault state. Do you want to proceed?
        </>
      }
      confirmLabel="Yes"
      onConfirm={async () => {
        try {
          await ClearMotorFaults({ motorId: props.device.id });
          enqueueSnackbar("Motor fault clear requested", { variant: "success" });
          props.onClose(true);
        } catch (e) {
          enqueueSnackbar("There was an error clearing motor faults.", { variant: "error" });
          props.onClose(false);
        }
      }}
    />
  );
}

export const ClearMotorFaultsDialog = createFragmentContainer(ClearMotorFaultsDialogComponent, {
  device: gql`
    fragment ClearMotorFaultsDialog_device on Device {
      id
      displayName
    }
  `,
});
