import React, { useEffect, useState } from "react";
import { Route, RouteComponentProps, Switch, match } from "react-router-dom";
import { observer } from "mobx-react";
import urljoin from "url-join";
import { ModelsStore } from "modules/common/stores";
import { SiteStore } from "modules/site-manager/stores/site";
import { Site } from "modules/site-manager/models/site";
import { TextLink } from "modules/common/components/TextLink";
import { SiteConfigRoute } from "./SiteConfiguration";
import { SiteSummaryRoute } from "./SiteSummary";
import { SiteAnalysisRoute } from "./SiteAnalysis";
import { SiteSettingsRoute } from "./SiteSettings";
import { WithStyles, Typography, Theme } from "@material-ui/core";
import { Grid, Tab, Tabs } from "@mui/material";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Colors } from "sigil";
import { Params } from "../../utils/routing";
import { SiteEnergySavingsRoute } from "./SiteEnergySavings";
import { Site_node_Site } from "generated-gql-types/Site";
import { LeftAlignedHeader } from "modules/site-manager/components/LeftAlignedHeader";
import { labelFontStyle } from "global-styles";
import { SiteBreadCrumb } from "./SiteBreadCrumb";
import { SiteBreadcrumb_site } from "generated-gql-types/SiteBreadcrumb_site";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const styles = (theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 600,
      margin: "1rem 0",
    },
    tabs: {
      height: "28px !important",
      minHeight: "28px !important",
    },

    tab: {
      color: "grey",
      textDecoration: "none",
      marginTop: "auto",
      marginRight: "1.5rem",
      borderBottom: "3px solid transparent",
      transition: "border-bottom-color 100ms ease-in, color 100ms ease-in",

      "&.MuiButtonBase-root.MuiTab-root": {
        fontFamily: "Barlow",
        height: "24px",
        minHeight: "24px",
        marginBottom: "4px",
        textTransform: "none",
      },

      "&:last-child": {
        marginRight: 0,
      },

      "&:hover": {
        color: Colors.onyx,
        textDecoration: "none",
        cursor: "pointer",
        borderBottomColor: Colors.dust,
      },

      "&.active, &.active:hover": {
        color: Colors.onyx,
        borderBottomColor: Colors.ttBlue,
      },
    },
  });

interface SProps extends WithStyles<typeof styles> {
  site?: Site;
  modelsStore: ModelsStore;
  siteNode?: Site_node_Site;
}

type Props = SProps & RouteComponentProps<Params>;

enum TabRoutes {
  SUMMARY = "summary",
  SAVINGS = "savings",
  ANALYSIS = "analysis",
  CONFIG = "config",
  SETTINGS = "settings",
}

// Type Guard for the above enum
// https://stackoverflow.com/a/63853981/30118
const isTabRoute = (test: any): test is TabRoutes => {
  return Object.values(TabRoutes).indexOf(test) !== -1;
};

const getLastSegmentOfCurrentRoute = (history: any, match: match<Params>) =>
  history.location.pathname.replace(match.url, "")?.slice(1);

const getInitialTabRoute = (history: any, match: match<Params>) => {
  const segment = getLastSegmentOfCurrentRoute(history, match);
  if (isTabRoute(segment)) {
    return segment;
  }
  return "summary";
};

const SiteTabs = ({ match, site, siteNode, classes }: Props) => {
  const history = useHistory();
  const [value, setValue] = useState(getInitialTabRoute(history, match));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    history.push(`${match.url}/${newValue}`);
  };

  // Effect 1: update tab after programmatic navigation (e.g. View Analysis)
  useEffect(() => {
    const currentTabRoute = getLastSegmentOfCurrentRoute(history, match);
    if (isTabRoute(currentTabRoute)) {
      setValue(currentTabRoute);
    } else if (!currentTabRoute) {
      // stopgap fix for motor dialog re-routing from hardware tab to summary
      history.push(`${match.url}/${value}`);
    }
  }, [history, match, value, setValue]);
  if (!site) {
    return null;
  }

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons={isMobile ? "auto" : false}
      centered={false}
      aria-label="Site navigation tabs"
      allowScrollButtonsMobile
      classes={classes}
      className={classes.tabs}
    >
      <Tab value={TabRoutes.SUMMARY} label="Summary" className={classes.tab} />
      {(value === TabRoutes.SAVINGS || siteNode?.energySavingsEnabled) && (
        <Tab value={TabRoutes.SAVINGS} label="Energy Savings" className={classes.tab} />
      )}
      <Tab value={TabRoutes.ANALYSIS} label="Analysis" className={classes.tab} />
      <Tab value={TabRoutes.CONFIG} label="Hardware" className={classes.tab} />
      <Tab value={TabRoutes.SETTINGS} label="Settings" className={classes.tab} />
    </Tabs>
  );
};

@observer
class Component extends React.Component<Props> {
  siteStore = (site: Site) => {
    return new SiteStore(site);
  };

  render() {
    const { site, siteNode } = this.props;
    const title = site ? <TextLink to={`/sites/${site.id}`}>{site.name}</TextLink> : "Loading...";

    return (
      <Grid container style={{ overflowY: "auto", overflowX: "hidden", width: "100%" }}>
        <Grid item container xs={12} justifyContent="center" style={{ backgroundColor: "white" }}>
          <Grid
            item
            xs={12}
            sm={10}
            ml={0}
            mt={1}
            style={{ minWidth: "200px", maxWidth: "1200px", padding: "0px 8px 0px 8px " }}
          >
            <SiteBreadCrumb site={siteNode as SiteBreadcrumb_site} />
          </Grid>
        </Grid>
        <LeftAlignedHeader title={title} loading={!site}>
          {site && (
            <Grid item xs={12} pb={1}>
              <Typography variant="caption" style={{ ...labelFontStyle }}>
                {site.address}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <SiteTabs {...this.props} />
          </Grid>
          {/* Site & Ctrl content */}
        </LeftAlignedHeader>
        {this.content}
      </Grid>
    );
  }

  get content() {
    const { match, site } = this.props;
    if (!site) {
      return null;
    }

    return (
      <Switch>
        <Route
          path={urljoin(match.url, TabRoutes.ANALYSIS)}
          render={(routeProps) => (
            <SiteAnalysisRoute siteStore={this.siteStore(site)} {...routeProps} />
          )}
        />
        <Route
          path={`${match.path}/${TabRoutes.CONFIG}`}
          render={(routeProps) => (
            <SiteConfigRoute siteStore={this.siteStore(site)} {...routeProps} />
          )}
        />
        <Route
          path={`${match.path}/${TabRoutes.SETTINGS}`}
          render={(routeProps) => (
            <SiteSettingsRoute siteStore={this.siteStore(site)} {...routeProps} />
          )}
        />
        <Route
          path={`${match.path}/${TabRoutes.SAVINGS}`}
          render={(routeProps) => (
            <SiteEnergySavingsRoute siteStore={this.siteStore(site)} {...routeProps} />
          )}
        />
        {/* Must come last so that it will handle all child routes of
            /sites/:id that are not explicitly handled above */}
        <Route
          path={match.path}
          render={(routeProps) => <SiteSummaryRoute site={site} {...routeProps} />}
        />
      </Switch>
    );
  }
}

// SiteRoute is responsible for rendering a single Site, which includes its
// tabbed navigation and nested controller & motor data
export const SiteRoute = withStyles(styles)(Component);
