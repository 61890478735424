import { createStyles, Theme } from "@material-ui/core/styles";

export const MetricSummaryCardStyles = (theme: Theme) => {
  const space = theme.spacing(1.5);

  return createStyles({
    card: {
      padding: `${theme.spacing(1)}px ${space}px 0px`,
      cursor: "pointer",
    },
    header: {
      display: "flex",
      height: "3rem",
      alignItems: "center",
    },
    statusIcon: {
      fontSize: "1.5rem",
    },
    statusBar: {
      marginBottom: "2.5rem",
    },
  });
};
