import * as React from "react";
import { MouseEvent, useCallback, useState } from "react";
import { gql } from "@apollo/client";
import { IconButton, Table, TableBody, Typography } from "@material-ui/core";
import { MembersTable_members } from "generated-gql-types/MembersTable_members";
import { MembersTable_organization } from "generated-gql-types/MembersTable_organization";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { useMenuState } from "modules/common/hooks";
import { ActionMenu, ActionMenuItem } from "modules/common/components/ActionMenu";
import MemberRow from "./row";
import { Button } from "sigil";

const noop = () => null;

interface AdminActionProps {
  member: MembersTable_members;
  onClick: (event: MouseEvent, member: MembersTable_members) => void;
}
function AdminAction({ member, onClick }: AdminActionProps) {
  const handleClick = useCallback((event: MouseEvent) => onClick(event, member), [member, onClick]);
  return (
    <IconButton onClick={handleClick}>
      <i className="fas fa-ellipsis-v fa-xs" />
    </IconButton>
  );
}

interface LeaveActionProps {
  member: MembersTable_members;
  onClick: (event: MouseEvent, member: MembersTable_members) => void;
}
function LeaveAction({ member, onClick }: LeaveActionProps) {
  const handleClick = useCallback((event: MouseEvent) => onClick(event, member), [member, onClick]);
  return member.node.isViewer ? (
    <Button variant="knockout" size="small" color="dangeresque" onClick={handleClick}>
      leave
    </Button>
  ) : null;
}

interface MembersTableProps {
  organization: MembersTable_organization;
  members: MembersTable_members[];
  onUpdateMember: (member: MembersTable_members) => void;
  onRemoveMember: (member: MembersTable_members) => void;
}

function MembersTable(props: MembersTableProps) {
  const { organization, members, onUpdateMember, onRemoveMember } = props;
  const adminVariant = organization.viewerIsAdmin;

  // Default Row click action
  const handleRowClick = adminVariant
    ? (_: MouseEvent, member: MembersTable_members) => onUpdateMember(member)
    : noop;

  // Admin actions menu
  const [selectedMember, setSelectedMember] = useState(members[0]);
  const menuState = useMenuState();

  const handleMenuClick = (event: MouseEvent, member: MembersTable_members) => {
    event.stopPropagation();
    setSelectedMember(member);
    menuState.handleOpen(event);
  };

  const menu = (
    <ActionMenu {...menuState.props} disableAutoFocusItem={true}>
      <ActionMenuItem
        onClick={() => {
          menuState.handleClose();
          onUpdateMember(selectedMember);
        }}
      >
        <Typography>Update permissions</Typography>
      </ActionMenuItem>
      <ActionMenuItem
        onClick={() => {
          menuState.handleClose();
          onRemoveMember(selectedMember);
        }}
      >
        <Typography color="primary">Remove from organization</Typography>
      </ActionMenuItem>
    </ActionMenu>
  );

  // Non-admin actions
  const handleLeaveClick = (event: MouseEvent, member: MembersTable_members) => {
    event.stopPropagation();
    onRemoveMember(member);
  };

  return (
    <React.Fragment>
      <Table style={{ tableLayout: "auto" }}>
        <TableBody>
          {members.map((member) => (
            <MemberRow
              organization={organization}
              member={member}
              onClick={handleRowClick}
              Action={
                adminVariant ? (
                  <AdminAction member={member} onClick={handleMenuClick} />
                ) : (
                  <LeaveAction member={member} onClick={handleLeaveClick} />
                )
              }
              key={member.node.id}
            />
          ))}
        </TableBody>
      </Table>
      {menu}
    </React.Fragment>
  );
}

export default createFragmentContainer(MembersTable, {
  organization: gql`
    fragment MembersTable_organization on Organization {
      viewerIsAdmin
      ...MemberRow_organization
    }
    ${MemberRow.fragments.organization}
  `,
  members: gql`
    fragment MembersTable_members on OrganizationUserEdge {
      node {
        id
        isViewer
      }
      ...MemberRow_member
    }
    ${MemberRow.fragments.member}
  `,
});
