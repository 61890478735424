import * as React from "react";
import { observer } from "mobx-react";

import { isNone } from "modules/common/utils/guards";
import { CtrlVariable } from "modules/site-manager/models";
import { UnitSymbol } from "modules/site-manager/components/UnitSymbol";
import { BMSRoleBitmaskPresenter } from "modules/site-manager/presenters";
import { BMSBehaviorKind } from "modules/site-manager/constants";

interface CtrlVariableValueProps {
  variable: CtrlVariable;
  fallback: string;
  showUnits?: boolean;
  includeClientSetValue?: boolean;
  format?: (valueNode: React.ReactNode) => React.ReactNode;
  formatValue?: (valueNode: React.ReactNode) => React.ReactNode;
  formatSymbol?: (valueNode: React.ReactNode) => React.ReactNode;
}

@observer
export class CtrlVariableValue extends React.Component<CtrlVariableValueProps> {
  static defaultProps = {
    format: (node: React.ReactNode) => node,
    formatValue: (node: React.ReactNode) => node,
    formatSymbol: (node: React.ReactNode) => node,
    includeClientSetValue: false,
  };

  stringifyValue = (value: number | boolean | string | null | undefined) => {
    const { variable, fallback } = this.props;
    const { behaviorKind, bmsRoleId } = variable;
    if (typeof value === "string") {
      return value;
    }
    if (isNone(value)) {
      return fallback;
    }
    const numericValue = typeof value === "boolean" ? boolToNum(value) : value;

    if (behaviorKind === BMSBehaviorKind.Bitmask) {
      return new BMSRoleBitmaskPresenter(bmsRoleId).labelFor(numericValue) || fallback;
    }

    if (behaviorKind === BMSBehaviorKind.Float) {
      return numericValue.toFixed(variable.precision || 0).replace(/\.0+$/, "");
    }

    return value.toString();
  };

  valueContent = (variable: CtrlVariable, showUnits?: boolean) => {
    const { formatValue, includeClientSetValue } = this.props;
    const showClientSetValue = includeClientSetValue && variable.isSetByClient;

    return (
      <React.Fragment>
        {/* Variable Value */}
        {formatValue!(
          this.stringifyValue(showClientSetValue ? variable.clientSetValue : variable.currentValue)
        )}

        {/* Variable Units Symbol */}
        {showUnits &&
          this.props.formatSymbol!(<UnitSymbol unit={variable.bacnetUnit || undefined} />)}
      </React.Fragment>
    );
  };

  render() {
    const { variable, showUnits, format } = this.props;

    return format!(this.valueContent(variable, showUnits));
  }
}

function boolToNum(bool: boolean): number {
  return bool ? 1 : 0;
}
