import * as React from "react";

import { Box, TableCell } from "@material-ui/core";
import { Moment } from "moment";
import { BoldTitle, GreyLabel } from "modules/common/components/Typography";
import { TextLink } from "modules/common/components/TextLink";
import { Colors } from "sigil/src";

// Shared cells for the Supervisor and Motor tables
export const NameCell: React.FC<{ name?: string | null; id: string }> = ({ id, name }) => (
  <TableCell style={{ minWidth: 230 }}>
    <BoldTitle>{name || id}</BoldTitle>
    {name ? <GreyLabel>{id}</GreyLabel> : null}
  </TableCell>
);

export const LogicCell: React.FC<{
  ts?: Moment | null;
}> = ({ ts }) => (
  <TableCell>
    <Box>{ts ? ts.format("MMM D, YYYY H:mma") : "Unknown"}</Box>
    {ts && <GreyLabel>{ts.fromNow()}</GreyLabel>}
  </TableCell>
);

export const UnkCell: React.FC = ({ children }) => <TableCell>{children || "Unknown"}</TableCell>;

export const LinkCell: React.FC<{ name?: string | null; id: string; to: string }> = ({
  id,
  name,
  to,
}) => (
  <TableCell style={{ minWidth: 230 }}>
    <BoldTitle>
      <TextLink to={to} style={{ color: Colors.ttBlue, fontWeight: 500 }} title={name || id || ""}>
        {name || id}
      </TextLink>
    </BoldTitle>
    {name ? <GreyLabel>{id}</GreyLabel> : null}
  </TableCell>
);
