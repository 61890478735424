import { createStyles, Theme } from "@material-ui/core/styles";

export const CardTableStyles = (theme: Theme) => {
  const { spacing } = theme;

  const rowStyling = {
    display: "flex",
    padding: `${spacing(1.5)}px ${spacing(2)}px`,
    alignItems: "center",
  };

  return createStyles({
    header: {
      ...rowStyling,
      marginBottom: spacing(1),
      paddingTop: 0,
      paddingBottom: 0,
    },
    row: {
      ...rowStyling,
      marginBottom: spacing(1),
    },
    cell: {
      padding: `0 ${spacing(0.5)}px`,
      minWidth: 0,
    },
  });
};
