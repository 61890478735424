import moment from "moment";
import { computed } from "mobx";

import {
  AlertDetail_alert_Alert_cause_AlertCauseMotorErrors,
  AlertDetail_alert_Alert_cause_AlertCauseMotorWarnings,
  AlertDetail_alert_Alert_cause_AlertCauseMotorSpeed,
} from "generated-gql-types/AlertDetail";
import { unmarshalStringId } from "modules/common/utils/relay";
import {
  TableAlerts_alerts_edges_node,
  TableAlerts_alerts_edges_node_cause_AlertCauseCascade,
  TableAlerts_alerts_edges_node_cause_AlertCauseCascade_ctrlVariable,
  TableAlerts_alerts_edges_node_cause_AlertCauseCascade_supervisor,
  TableAlerts_alerts_edges_node_cause_AlertCauseMotorConnectivity_motor,
  TableAlerts_alerts_edges_node_cause_AlertCauseMotorErrors_motor,
  TableAlerts_alerts_edges_node_cause_AlertCauseMotorLowTorque_motor,
  TableAlerts_alerts_edges_node_cause_AlertCauseMotorSeizure_motor,
  TableAlerts_alerts_edges_node_cause_AlertCauseMotorSpeed_motor,
  TableAlerts_alerts_edges_node_cause_AlertCauseMotorWarnings_motor,
} from "generated-gql-types/TableAlerts";
import { BacnetUnitPresenter } from "modules/site-manager/presenters/bacnet-unit";
import { pluralize } from "modules/common/utils/pluralize";
import { AlertStatus, MotorError, MotorWarning } from "generated-gql-types/globalTypes";
import { startCase, toLower } from "lodash";

export interface AlertPresenter {
  title: string;
  description: string;
  deviceName: string;
  supervisor: string;
  siteName: string;
  orgName?: string;
  message?: string;
  deviceClass?: string;
  status: string;
}

// TODO: AlertHistoryTable should use these labels, too
export const STATUS_LABELS = new Map([
  [AlertStatus.TRIGGERED, "Active"],
  [AlertStatus.ACKED, "Acknowledged"],
  [AlertStatus.CLOSED, "Closed"],
]);

export const MOTOR_ERROR_MESSAGES = {
  [MotorError.BUS_CURRENT_HIGH]: "Line current is out of range",
  [MotorError.BUS_VOLTAGE_HIGH]: "Line voltage is out of range",
  [MotorError.CURRENT_OVERLOAD]: "Current overload to motor",
  [MotorError.DEVICE_COMMUNICATION_LOST]: "Lost communication to motor",
  [MotorError.EEPROM]: "EEPROM problem",
  [MotorError.EXTERNAL_FAULT_SIGNAL]: "External input fault on motor",
  [MotorError.FAN_FAULT]: "Fan Fault",
  [MotorError.INCOMPATIBLE]: "Controller and motor are incompatible",
  [MotorError.INVERTER_ENCLOSURE_OVERHEAT]: "Controller is too hot",
  [MotorError.INVERTER_HEATSINK_OVERHEAT]: "Controller is too hot",
  [MotorError.MOTOR_OVERHEAT]: "Motor is too hot",
  [MotorError.NO_MOTOR]: "Unable to power motor",
  [MotorError.OVERSPEEED]: "Motor ran above maximum speed",
  [MotorError.POST_FAILED]: "Controller failed startup checks",
  [MotorError.POWER_LOSS]: "Input power to motor is degraded",
  [MotorError.RESTART_BACKOFF]: "Motor exceeded its maximum number of restarts",
  [MotorError.SENSOR_A]: "Motor has an issue with current sensor",
  [MotorError.SENSOR_B]: "Motor has an issue with current sensor",
  [MotorError.SENSOR_C]: "Motor has an issue with current sensor",
  [MotorError.SENSOR_DC]: "Motor has an issue with current sensor DC",
  [MotorError.SOFTWARE]: "Motor controller update error occurred",
  [MotorError.STALLED]: "Motor is stalled",
  [MotorError.UNCOMMISSIONED]: "Motor has not been successfully commissioned",
};

export const MOTOR_WARNING_MESSAGES = {
  [MotorWarning.BUS_CURRENT_HIGH]: "Line current is outside normal limits",
  [MotorWarning.BUS_VOLTAGE_HIGH]: "Line voltage is outside normal limits",
  [MotorWarning.COMMUNICATION_UNRELIABLE]: "Communication is unreliable",
  [MotorWarning.CURRENT_OVERLOAD]: "Line current overload",
  [MotorWarning.FAN_FAULT]: "Fan fault",
  [MotorWarning.FIRMWARE_UPDATE_FAILED]: "Controller could not update firmware",
  [MotorWarning.INVERTER_ENCLOSURE_OVERHEAT]: "Controller is running hot",
  [MotorWarning.INVERTER_HEATSINK_OVERHEAT]: "Controller is running hot",
  [MotorWarning.MOTOR_OVERHEAT]: "Motor is running hot",
  [MotorWarning.MOTOR_SIZE]: "Controller is missized",
  [MotorWarning.OVERSPEED]: "Motor speed is above normal",
  [MotorWarning.PHASE_IMBALANCE]: "Phase imbalance detected",
  [MotorWarning.PHASE_LOSSED]: "Loss of phase detected",
  [MotorWarning.SPEED_DEVIATION]: "Motor speed deviated from normal",
  [MotorWarning.SPEED_INVALID]: "Motor was commanded to an invalid speed",
  [MotorWarning.USER_LOGIC_ERROR]: "Motor is unable to execute logic flow",
};

export function newAlertPresenter(alert: TableAlerts_alerts_edges_node) {
  let spv = null;
  switch (alert.cause.__typename) {
    case "AlertCauseMotorErrors":
    case "AlertCauseMotorWarnings":
    case "AlertCauseMotorConnectivity":
    case "AlertCauseMotorSpeed":
    case "AlertCauseMotorLowTorque":
    case "AlertCauseMotorSeizure":
      const motor = alert.cause.motor;
      const motorId = unmarshalStringId(alert.cause.motor.id);
      return new MotorAlertPresenter(alert, motorId, motor);
    case "AlertCauseCascade":
      spv = alert.cause.supervisor;
      if (!spv) {
        throw new Error("Attempting to present invalid alert data, needs supervisor");
      }
      return new CascadeAlertPresenter(alert, spv);
    default:
      spv = alert.cause.supervisor;
      if (!spv) {
        throw new Error("Attempting to present invalid alert data, needs supervisor");
      }
      return new SupervisorAlertPresenter(alert, spv);
  }
}

class AlertPresenterBase {
  constructor(
    protected readonly alert: TableAlerts_alerts_edges_node,
    protected readonly spv?: TableAlerts_alerts_edges_node_cause_AlertCauseCascade_supervisor
  ) {}

  @computed get deviceName() {
    return this.supervisor;
  }

  @computed get supervisor() {
    if (!this.spv) {
      return "";
    }
    return this.spv.displayName;
  }

  @computed get siteName() {
    return this.alert.site.name;
  }

  @computed get orgName() {
    return this.alert.site.organization.name;
  }

  @computed get status() {
    return STATUS_LABELS.get(this.alert.status) || "Unknown";
  }

  @computed get deviceClass() {
    if (this.spv) {
      return "Supervisor";
    }
    return "Motor System";
  }
}

class CascadeAlertPresenter extends AlertPresenterBase {
  private readonly cause: TableAlerts_alerts_edges_node_cause_AlertCauseCascade;

  constructor(
    alert: TableAlerts_alerts_edges_node,
    spv: TableAlerts_alerts_edges_node_cause_AlertCauseCascade_supervisor
  ) {
    super(alert, spv);
    this.cause = alert.cause as TableAlerts_alerts_edges_node_cause_AlertCauseCascade;
  }

  @computed get ctrlVarName() {
    return this.cause.ctrlVariable.displayName;
  }

  @computed get faultPriority() {
    const priority = this.cause.ctrlVariable.faultPriority || "";
    if (priority === "NOT_DEFINED") {
      return "Critical";
    }
    return startCase(toLower(priority)).replace(" ", "-");
  }

  @computed get title() {
    const ctrlVariable = this.cause.ctrlVariable;
    let faultTitle = ctrlVariable.faultTitle || "";
    if (faultTitle !== "") {
      return replaceText(faultTitle, ctrlVariable, this.valueWithUnit, this.supervisor);
    }
    return `${this.supervisor}: ${this.ctrlVarName}`;
  }

  @computed get valueWithUnit() {
    if (
      this.cause.logicPointValue.booleanValue !== null &&
      this.cause.logicPointValue.booleanValue !== undefined
    ) {
      return `${this.cause.logicPointValue.booleanValue}`;
    }
    const unitId = this.cause.ctrlVariable.bacnetUnitID;
    let unit = "";
    if (unitId) {
      unit = new BacnetUnitPresenter(unitId).symbolString || "";
    }
    return `${this.cause.logicPointValue.analogValue}${unit}`;
  }

  @computed get description() {
    const ctrlVariable = this.cause.ctrlVariable;
    let faultDesc = ctrlVariable.faultDetail || "";
    if (faultDesc !== "") {
      return replaceText(faultDesc, ctrlVariable, this.valueWithUnit, this.supervisor);
    }
    return (
      `${this.ctrlVarName} on supervisor ${this.supervisor} was observed at ` +
      `${this.valueWithUnit}.`
    );
  }

  @computed get message() {
    return this.cause.ctrlVariable.faultTitle || this.cause.ctrlVariable.displayName || "";
  }
}

class MotorAlertPresenter extends AlertPresenterBase {
  static errorCodeMapping = {
    [MotorError.CURRENT_OVERLOAD]: "E1-0",
    [MotorError.POWER_LOSS]: "E1-1",
    [MotorError.OVERSPEEED]: "E1-2",
    [MotorError.MOTOR_OVERHEAT]: "E1-3",
    [MotorError.INVERTER_HEATSINK_OVERHEAT]: "E1-4",
    [MotorError.INVERTER_ENCLOSURE_OVERHEAT]: "E1-5",
    [MotorError.BUS_CURRENT_HIGH]: "E1-6",
    [MotorError.BUS_VOLTAGE_HIGH]: "E1-7",
    [MotorError.EXTERNAL_FAULT_SIGNAL]: "E1-8",
    [MotorError.NO_MOTOR]: "E1-9",
    [MotorError.DEVICE_COMMUNICATION_LOST]: "E1-10",
    [MotorError.EEPROM]: "E1-11",
    [MotorError.SOFTWARE]: "E1-12",
    [MotorError.INCOMPATIBLE]: "E1-13",
    [MotorError.RESTART_BACKOFF]: "E1-14",
    [MotorError.STALLED]: "E1-15",
    [MotorError.SENSOR_A]: "E2-0",
    [MotorError.SENSOR_B]: "E2-1",
    [MotorError.SENSOR_C]: "E2-2",
    [MotorError.SENSOR_DC]: "E2-3",
    [MotorError.FAN_FAULT]: "E2-4",
    [MotorError.POST_FAILED]: "E2-5",
    [MotorError.UNCOMMISSIONED]: "E1-12",
  };

  static warningCodeMapping = {
    [MotorWarning.CURRENT_OVERLOAD]: "W1-0",
    [MotorWarning.PHASE_IMBALANCE]: "W1-1",
    [MotorWarning.PHASE_LOSSED]: "W1-2",
    [MotorWarning.OVERSPEED]: "W1-3",
    [MotorWarning.SPEED_DEVIATION]: "W1-4",
    [MotorWarning.MOTOR_OVERHEAT]: "W1-5",
    [MotorWarning.INVERTER_HEATSINK_OVERHEAT]: "W1-6",
    [MotorWarning.INVERTER_ENCLOSURE_OVERHEAT]: "W1-7",
    [MotorWarning.BUS_CURRENT_HIGH]: "W1-8",
    [MotorWarning.BUS_VOLTAGE_HIGH]: "W1-9",
    [MotorWarning.COMMUNICATION_UNRELIABLE]: "W1-10",
    [MotorWarning.FIRMWARE_UPDATE_FAILED]: "W1-11",
    [MotorWarning.FAN_FAULT]: "W1-12",
    [MotorWarning.MOTOR_SIZE]: "W1-13",
    [MotorWarning.USER_LOGIC_ERROR]: "W1-14",
    [MotorWarning.SPEED_INVALID]: "W1-15",
  };

  static errorTitleMapping = {
    [MotorError.BUS_VOLTAGE_HIGH]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} line voltage is out of range`,
    [MotorError.CURRENT_OVERLOAD]: (presenter: MotorAlertPresenter) =>
      `Current overload to ${presenter.deviceName} motor`,
    [MotorError.EXTERNAL_FAULT_SIGNAL]: (presenter: MotorAlertPresenter) =>
      `External input fault on ${presenter.deviceName}`,
    [MotorError.INCOMPATIBLE]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} controller and motor are incompatible`,
    [MotorError.INVERTER_ENCLOSURE_OVERHEAT]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} controller is too hot`,
    [MotorError.INVERTER_HEATSINK_OVERHEAT]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} controller is too hot`,
    [MotorError.MOTOR_OVERHEAT]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} is too hot`,
    [MotorError.NO_MOTOR]: (presenter: MotorAlertPresenter) =>
      `Unable to power ${presenter.deviceName} motor`,
    [MotorError.OVERSPEEED]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} motor ran above maximum speed`,
    [MotorError.POST_FAILED]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} controller failed startup checks`,
    [MotorError.POWER_LOSS]: (presenter: MotorAlertPresenter) =>
      `Input power to ${presenter.deviceName} motor is degraded`,
    [MotorError.RESTART_BACKOFF]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} exceeded its maximum number of restarts`,
    [MotorError.SENSOR_A]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} has an issue with current sensor A`,
    [MotorError.SENSOR_B]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} has an issue with current sensor B`,
    [MotorError.SENSOR_C]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} has an issue with current sensor C`,
    [MotorError.SOFTWARE]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} controller update error occurred`,
    [MotorError.STALLED]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} motor is stalled`,
    [MotorError.UNCOMMISSIONED]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} has not been commissioned`,
  };

  // Generic error messages
  static errorDescriptionMapping = {
    [MotorError.BUS_VOLTAGE_HIGH]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} motor stopped because its input line voltage is outside of its` +
      ` operable range.`,
    [MotorError.CURRENT_OVERLOAD]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} stopped due to a high current event. This can indicate a critical` +
      ` failure in the motor, controller, or the wiring between them.`,
    [MotorError.EXTERNAL_FAULT_SIGNAL]: (presenter: MotorAlertPresenter) =>
      `One or more digital inputs on ${presenter.deviceName} is configured to receive a fault` +
      ` from an external system. The motor has been turned off in response to this external` +
      ` fault.`,
    [MotorError.INCOMPATIBLE]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} controller and motor not compatible. Make sure that the controller` +
      ` firmware is up-to-date, and it has been correctly selected to control your motor.`,
    [MotorError.INVERTER_ENCLOSURE_OVERHEAT]: (presenter: MotorAlertPresenter) =>
      `The ${presenter.deviceName} motor stopped because the controller is too hot.`,
    [MotorError.INVERTER_HEATSINK_OVERHEAT]: (presenter: MotorAlertPresenter) =>
      `The ${presenter.deviceName} motor stopped because the controller is too hot.`,
    [MotorError.MOTOR_OVERHEAT]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} motor stopped because it was running at too high a temperature.`,
    [MotorError.NO_MOTOR]: (presenter: MotorAlertPresenter) =>
      `The ${presenter.deviceName} controller was unable to run the motor. It either attempted` +
      ` to power the motor and received no feedback, or was never configured to run this motor.` +
      ` Ensure that the motor and controller are wired and configured correctly.`,
    [MotorError.OVERSPEEED]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} stopped because it was running above its configured maximum` +
      ` speed.`,
    [MotorError.POST_FAILED]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} motor controller failed integrity checks performed on startup.` +
      ` To clear this fault, cycle power to the unit to re-run the checks.`,
    [MotorError.POWER_LOSS]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} motor stopped due to a loss of power to one or more of its AC` +
      ` phases.`,
    [MotorError.RESTART_BACKOFF]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} has restarted too many times due to faults. The motor will no` +
      ` longer attempt to restart until power is cycled to the unit or faults are manually` +
      ` reset.`,
    [MotorError.SENSOR_A]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} current sensor A is reading out-of-range values.`,
    [MotorError.SENSOR_B]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} current sensor B is reading out-of-range values.`,
    [MotorError.SENSOR_C]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} current sensor C is reading out-of-range values.`,
    [MotorError.SOFTWARE]: (presenter: MotorAlertPresenter) =>
      `An unknown update error occurred in the motor ${presenter.deviceName} controller` +
      ` software.`,
    [MotorError.STALLED]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} motor stopped or was unable to start due to the load on the` +
      ` shaft.`,
    [MotorError.UNCOMMISSIONED]: (presenter: MotorAlertPresenter) =>
      `Factory default user settings have been detected for ${presenter.deviceName}.` +
      ` This is an expected condition for new installations prior to commissioning. If the` +
      ` drive had been previously commissioned, the user settings may have been corrupted` +
      ` and fallen back to the factory default values.`,
  };

  static warningTitleMapping = {
    [MotorWarning.BUS_VOLTAGE_HIGH]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} line voltage is outside normal limits`,
    [MotorWarning.FIRMWARE_UPDATE_FAILED]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} controller could not update firmware`,
    [MotorWarning.INVERTER_ENCLOSURE_OVERHEAT]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} controller is running hot`,
    [MotorWarning.INVERTER_HEATSINK_OVERHEAT]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} controller is running hot`,
    [MotorWarning.MOTOR_OVERHEAT]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} motor is running hot`,
    [MotorWarning.MOTOR_SIZE]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} controller is missized`,
    [MotorWarning.SPEED_INVALID]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} was commanded to an invalid speed`,
    [MotorWarning.USER_LOGIC_ERROR]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} is unable to execute logic flow`,
  };

  // Generic warning messages
  static warningMessageMapping = {
    [MotorWarning.BUS_CURRENT_HIGH]: (presenter: MotorAlertPresenter) =>
      `Line current is outside normal limits`,
    [MotorWarning.BUS_VOLTAGE_HIGH]: (presenter: MotorAlertPresenter) =>
      `Line voltage is outside normal limits`,
    [MotorWarning.COMMUNICATION_UNRELIABLE]: (presenter: MotorAlertPresenter) =>
      `Communication is unreliable`,
    [MotorWarning.CURRENT_OVERLOAD]: (presenter: MotorAlertPresenter) => `Line current overload`,
    [MotorWarning.FAN_FAULT]: (presenter: MotorAlertPresenter) => `Fan fault`,
    [MotorWarning.FIRMWARE_UPDATE_FAILED]: (presenter: MotorAlertPresenter) =>
      `Controller could not update firmware`,
    [MotorWarning.INVERTER_ENCLOSURE_OVERHEAT]: (presenter: MotorAlertPresenter) =>
      `Controller is running hot`,
    [MotorWarning.INVERTER_HEATSINK_OVERHEAT]: (presenter: MotorAlertPresenter) =>
      `Controller is running hot`,
    [MotorWarning.MOTOR_OVERHEAT]: (presenter: MotorAlertPresenter) => `Motor is running hot`,
    [MotorWarning.MOTOR_SIZE]: (presenter: MotorAlertPresenter) => `Controller is missized`,
    [MotorWarning.OVERSPEED]: (presenter: MotorAlertPresenter) => `Motor speed is above normal`,
    [MotorWarning.PHASE_IMBALANCE]: (presenter: MotorAlertPresenter) => `Phase imbalance detected`,
    [MotorWarning.PHASE_LOSSED]: (presenter: MotorAlertPresenter) => `Loss of phase detected`,
    [MotorWarning.SPEED_DEVIATION]: (presenter: MotorAlertPresenter) =>
      `Motor speed deviated from normal`,
    [MotorWarning.SPEED_INVALID]: (presenter: MotorAlertPresenter) =>
      `Motor was commanded to an invalid speed`,
    [MotorWarning.USER_LOGIC_ERROR]: (presenter: MotorAlertPresenter) =>
      `Motor is unable to execute logic flow`,
  };

  static warningDescriptionMapping = {
    [MotorWarning.BUS_VOLTAGE_HIGH]: (presenter: MotorAlertPresenter) =>
      `The input line voltage to ${presenter.deviceName} is outside of normal operating limits,` +
      ` the motor may not be running optimally.`,
    [MotorWarning.FIRMWARE_UPDATE_FAILED]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} controller could not update firmware`,
    [MotorWarning.INVERTER_ENCLOSURE_OVERHEAT]: (presenter: MotorAlertPresenter) =>
      `The ${presenter.deviceName} controller is at a high temperature. If operation continues` +
      ` at this temperature, the performance of the motor may degrade and its lifetime may shorten`,
    [MotorWarning.INVERTER_HEATSINK_OVERHEAT]: (presenter: MotorAlertPresenter) =>
      `The ${presenter.deviceName} controller is at a high temperature. If operation continues` +
      ` at this temperature, the performance of the motor may degrade and its lifetime may shorten`,
    [MotorWarning.MOTOR_OVERHEAT]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} is running at a high temperature. If operation continues at this` +
      ` temperature, the performance of the motor may degrade and its lifetime may shorten.`,
    [MotorWarning.MOTOR_SIZE]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} controller is the wrong size to power its connected motor.`,
    [MotorWarning.SPEED_INVALID]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} was commanded to run at a speed that is either outside of its` +
      ` operable range, or in the range of its skip speeds.`,
    [MotorWarning.USER_LOGIC_ERROR]: (presenter: MotorAlertPresenter) =>
      `${presenter.deviceName} encountered an error with its logic flow programming.`,
  };

  motorId: string;

  constructor(
    alert: TableAlerts_alerts_edges_node,
    motorId: string,
    private readonly motor?:
      | TableAlerts_alerts_edges_node_cause_AlertCauseMotorErrors_motor
      | TableAlerts_alerts_edges_node_cause_AlertCauseMotorWarnings_motor
      | TableAlerts_alerts_edges_node_cause_AlertCauseMotorConnectivity_motor
      | TableAlerts_alerts_edges_node_cause_AlertCauseMotorSpeed_motor
      | TableAlerts_alerts_edges_node_cause_AlertCauseMotorLowTorque_motor
      | TableAlerts_alerts_edges_node_cause_AlertCauseMotorSeizure_motor
  ) {
    super(alert);
    this.motorId = motorId;
  }

  static errorCode(motorError: MotorError) {
    return MotorAlertPresenter.errorCodeMapping[motorError] || "unknown";
  }

  static errorTitleRenderer(motorError: MotorError) {
    return (
      MotorAlertPresenter.errorTitleMapping[motorError] ||
      ((presenter: MotorAlertPresenter) =>
        `An error stopped ${presenter.deviceName} motor from running`)
    );
  }

  static errorDescriptionRenderer(motorError: MotorError) {
    return (
      MotorAlertPresenter.errorDescriptionMapping[motorError] ||
      ((presenter: MotorAlertPresenter) =>
        `${presenter.deviceName} is experiencing an error that prevents it from running. The` +
        `error must be cleared manually or power cycled to the unit before operation can continue.`)
    );
  }

  static errorMessageRenderer(motorError: MotorError) {
    const message = MOTOR_ERROR_MESSAGES[motorError] || "An error stopped the motor from running";
    return (presenter: MotorAlertPresenter) => message;
  }

  static warningCode(motorWarning: MotorWarning) {
    return MotorAlertPresenter.warningCodeMapping[motorWarning] || "unknown";
  }

  static warningTitleRenderer(motorWarning: MotorWarning) {
    return (
      MotorAlertPresenter.warningTitleMapping[motorWarning] ||
      ((presenter: MotorAlertPresenter) => `${presenter.deviceName} has a warning`)
    );
  }

  static warningDescriptionRenderer(motorWarning: MotorWarning) {
    return (
      MotorAlertPresenter.warningDescriptionMapping[motorWarning] ||
      ((presenter: MotorAlertPresenter) =>
        `${presenter.deviceName} has a warning that may be affecting its operation. The motor is` +
        ` still running, but the warning may need to be cleared manually.`)
    );
  }

  static warningMessageRenderer(motorWarning: MotorWarning) {
    const message =
      MOTOR_WARNING_MESSAGES[motorWarning] ||
      `Motor has a warning that may be affecting its operation. The motor is` +
        ` still running, but the warning may need to be cleared manually.`;
    return (presenter: MotorAlertPresenter) => message;
  }

  @computed get deviceName() {
    return this.motor ? this.motor.displayName : this.motorId;
  }

  @computed get title() {
    if (this.alert.cause.__typename === "AlertCauseMotorErrors" && this.alert.cause.error) {
      return MotorAlertPresenter.errorTitleRenderer(this.alert.cause.error)(this);
    }
    if (this.alert.cause.__typename === "AlertCauseMotorWarnings" && this.alert.cause.warning) {
      return MotorAlertPresenter.warningTitleRenderer(this.alert.cause.warning)(this);
    }
    return `${this.deviceName}${this.causeTitle}`;
  }

  @computed get message() {
    if (this.alert.cause.__typename === "AlertCauseMotorErrors" && this.alert.cause.error) {
      return MotorAlertPresenter.errorMessageRenderer(this.alert.cause.error)(this);
    }
    if (this.alert.cause.__typename === "AlertCauseMotorWarnings" && this.alert.cause.warning) {
      return MotorAlertPresenter.warningMessageRenderer(this.alert.cause.warning)(this);
    }
    return `Motor ${this.causeTitle}`;
  }

  @computed get description() {
    if (this.alert.cause.__typename === "AlertCauseMotorErrors" && this.alert.cause.error) {
      return (
        MotorAlertPresenter.errorDescriptionRenderer(this.alert.cause.error)(this) +
        ` (Code: ${MotorAlertPresenter.errorCode(this.alert.cause.error)})`
      );
    }
    if (this.alert.cause.__typename === "AlertCauseMotorWarnings" && this.alert.cause.warning) {
      return (
        MotorAlertPresenter.warningDescriptionRenderer(this.alert.cause.warning)(this) +
        ` (Code: ${MotorAlertPresenter.warningCode(this.alert.cause.warning)})`
      );
    }
    return `${this.causeDescription}`;
  }

  @computed get errorCount(): number {
    if (this.alert.cause.hasOwnProperty("errorCount")) {
      return (this.alert.cause as AlertDetail_alert_Alert_cause_AlertCauseMotorErrors).errorCount;
    }
    return 0;
  }

  @computed get warningCount(): number {
    if (this.alert.cause.hasOwnProperty("warningCount")) {
      return (this.alert.cause as AlertDetail_alert_Alert_cause_AlertCauseMotorWarnings)
        .warningCount;
    }
    return 0;
  }

  @computed get currentSpeed(): number {
    if (this.alert.cause.hasOwnProperty("currentSpeed")) {
      return (this.alert.cause as AlertDetail_alert_Alert_cause_AlertCauseMotorSpeed).currentSpeed;
    }
    return 0;
  }

  @computed get requestedSpeed(): number {
    if (this.alert.cause.hasOwnProperty("requestedSpeed")) {
      return (this.alert.cause as AlertDetail_alert_Alert_cause_AlertCauseMotorSpeed)
        .requestedSpeed;
    }
    return 0;
  }

  @computed get causeTitle() {
    switch (this.alert.cause.__typename) {
      case "AlertCauseMotorConnectivity":
        return " has lost connectivity";
      case "AlertCauseMotorErrors":
        return ` cannot run due to ${pluralize(this.errorCount)("an error", "errors")}`;
      case "AlertCauseMotorWarnings":
        return " has warnings that may affect operation";
      case "AlertCauseMotorSpeed":
        return " could not reach requested speed";
      case "AlertCauseMotorLowTorque":
        return " detected torque loss";
      case "AlertCauseMotorSeizure":
        return " seized up.";
      default:
        return " raised an alert";
    }
  }

  @computed get causeDescription() {
    switch (this.alert.cause.__typename) {
      case "AlertCauseMotorConnectivity":
        // FIXME capture fault fields to display actual trigger time
        const start = moment(this.alert.createdAt).subtract(10, "m");
        return `No data has been received from ${this.deviceName} since ${calendarFormat(
          start,
          false
        )}`;
      case "AlertCauseMotorErrors":
        const errorPluralizer = pluralize(this.errorCount);
        return (
          `${this.deviceName} is experiencing ${errorPluralizer(
            "an error",
            `${this.errorCount} errors`
          )}` +
          ` that ${errorPluralizer("prevents", "prevent")} it from running.\
          Connect with SMCUI for more details about ${errorPluralizer(
            "this error",
            "these errors"
          )}.

          This alert cannot be closed until the ${errorPluralizer(
            "error has",
            "errors have"
          )} been cleared` +
          ` on the motor itself.`
        );
      case "AlertCauseMotorWarnings":
        const warningPluralizer = pluralize(this.warningCount);
        return (
          `${this.deviceName} is experiencing ${warningPluralizer(
            "a warning",
            "warnings"
          )} that may affect` +
          ` its operation. Connect with SMCUI for more details about ${warningPluralizer(
            "this warning",
            "these warnings"
          )}.

          This alert cannot be closed until ${warningPluralizer(
            "the warning has",
            "the warnings have"
          )} been` +
          ` cleared on the motor itself.`
        );
      case "AlertCauseMotorSpeed":
        return (
          `${this.deviceName} was observed running at ${this.currentSpeed} rpm, but was` +
          ` requested to run at ${this.requestedSpeed} rpm.`
        );
      case "AlertCauseMotorLowTorque":
        return (
          `${this.deviceName} has detected a severe decrease in torque. This most commonly` +
          ` indicates a mechanical failure in the equipment in which the motor is installed.`
        );
      case "AlertCauseMotorSeizure":
        return (
          `${this.deviceName} is failing to start despite high current. If this persists` +
          ` for more than 30 minutes the motor may be permanently damaged.`
        );
      default:
        return `An unknown alert was raised ${calendarFormat(moment(this.alert.createdAt))}`;
    }
  }

  @computed get faultPriority() {
    return "";
  }
}

class SupervisorAlertPresenter extends AlertPresenterBase {
  @computed get title() {
    return `${this.supervisor}${this.causeTitle}`;
  }

  @computed get causeTitle() {
    return " cloud communication interrupted";
  }

  @computed get description() {
    // FIXME capture fault fields to display actual trigger time
    const start = moment(this.alert.createdAt).subtract(30, "m");
    return `Supervisor ${this.supervisor} stopped sending data ${calendarFormat(start)}.`;
  }

  @computed get faultPriority() {
    return "";
  }

  @computed get message() {
    return "Cloud communication interrupted";
  }
}

function calendarFormat(date: moment.Moment, preposition: boolean = true) {
  return date.calendar(undefined, {
    sameDay: `${preposition ? "[at] " : ""}LT`,
    lastDay: "[yesterday at] LT",
    lastWeek: "[last] dddd [at] LT",
    sameElse: `${preposition ? "[on] " : ""}LL [at] LT`,
  });
}

function replaceText(
  input: string,
  variable: TableAlerts_alerts_edges_node_cause_AlertCauseCascade_ctrlVariable,
  valueWithUnit: string,
  spv: string
) {
  if (input === "") {
    return input;
  }
  input = input
    .replace(/\{name\}/g, variable.displayName)
    .replace(/\{value\}/g, valueWithUnit)
    .replace(/\{supervisor_name\}/g, spv);
  input = input.replace(/(\\n)/g, "\n");
  input = input.replace(/(\\)/g, "");
  if (input.indexOf("{zone}") > -1) {
    if (variable.zone !== null) {
      input = input.replace(/\{zone\}/g, variable.zone);
    } else {
      input = input.replace(/\{zone\}/, "");
    }
  }
  return input;
}
