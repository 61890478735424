import { computed } from "mobx";

import { Motor, Site } from "modules/site-manager/models";
import memoizeOne from "memoize-one";

export class MotorsStore {
  motorIdMap?: Map<string, Motor>;

  constructor(private site: Site) {}

  @computed get all(): MotorStore[] {
    const { site } = this;
    const { sortedMotors } = site;
    return sortedMotors ? sortedMotors.map((m) => new MotorStore(m)) : [];
  }

  byId(motorId: string): Motor | undefined {
    const mapMotors = memoizeOne(() =>
      this.all.reduce((map, motorStore) => {
        map[motorStore.model.id] = motorStore.model;
        return map;
      }, {})
    );
    this.motorIdMap = mapMotors() as Map<string, Motor>;
    return this.motorIdMap[motorId];
  }
}

export class MotorStore {
  constructor(public model: Motor) {}
}
