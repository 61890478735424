import React from "react";
import { Button } from "@mui/material";
import { SimpleConfirmationDialog } from "modules/common/components/SimpleConfirmationDialog";
import { FontIcon } from "modules/common/components/FontIcon";

type ActionConfirmationDialogProps = {
  actionName: string; // e.g. 'Acknowledge'
  actionCount: number; // e.g. number of selected alerts
  objectName: string; // e.g. "alerts"
  callback: () => void;
  disabled: boolean;
  iconName?: string;
  tooltip?: string;
};

export function ActionConfirmationDialog({
  actionName,
  actionCount,
  objectName,
  callback,
  disabled,
  iconName,
  tooltip,
}: ActionConfirmationDialogProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen} disabled={disabled} title={tooltip}>
        {iconName && (
          <FontIcon
            name={iconName}
            color="text.disabled"
            className="mr-1"
            style={{ marginRight: "4px" }}
          />
        )}
        {actionName}
      </Button>
      <SimpleConfirmationDialog
        open={open}
        onClose={handleClose}
        title={`${actionName} ${objectName}`}
        body={`${actionName} ${actionCount} ${objectName}?`}
        confirmLabel={actionName}
        onConfirm={async () => {
          callback();
          handleClose();
        }}
      />
    </>
  );
}
