import * as React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Search, TextField } from "sigil";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const useSearchStyles = makeStyles(() =>
  createStyles({
    grid: {
      width: "100%",
      display: "flex",
      padding: "11px 24px 16px",
    },
  })
);

export const SearchBox = ({
  placeholder,
  change,
}: {
  placeholder: string;
  change: (search?: string) => void;
}) => {
  const styles = useSearchStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container className={styles.grid}>
      <TextField
        autoFocus
        canError={false}
        startAdornment={<Search color="ash" />}
        placeholder={placeholder}
        onChange={(e) => change(e.target.value)}
        style={{ fontSize: isMobile ? "16px" : "inherit" }}
      />
    </Grid>
  );
};
