import { action, observable } from "mobx";
import { inject } from "mobx-react";

// Re-export common store types for easier import syntax
export { APIStore } from "./api";
export { ModelsStore } from "./models";

interface Store {}

export class StoreRegistry {
  @observable stores: Map<string, Store> = new Map();

  @action
  registerStore(type: string, store: Store): void {
    this.stores.set(type, store);
  }
}

export interface StoreRegistryContext {
  storeRegistry: StoreRegistry;
}

// TODO: Make this truly strict on types, to do away with `+ "Store"`
export function injectStores(...stores: string[]) {
  const storesMap = function (context: StoreRegistryContext) {
    const registry = context.storeRegistry;
    return stores.reduce((injection, storeName) => {
      injection[storeName + "Store"] = registry.stores.get(storeName);
      return injection;
    }, {});
  };
  return inject(storesMap);
}
