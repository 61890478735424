import { BMSBehaviorKind } from "modules/site-manager/constants";
import { BMSRoleBitmaskPresenter } from "./bms-behavior-bitmask";
import {
  getAppropriateValue,
  LogicValueContainer,
} from "modules/site-manager/utils/logic-value-container";
import { BmsRolePresenter } from "./bms-role";

export class LogicValueContainerPresenter {
  bmsRolePresenter: BmsRolePresenter;

  constructor(private container: LogicValueContainer) {
    this.bmsRolePresenter = new BmsRolePresenter(container.bmsRoleId);
  }

  get name() {
    return (
      (this.container.bmsRoleId && this.bmsRolePresenter.name) || this.container.displayName || ""
    );
  }

  get shortName() {
    return (this.container.bmsRoleId && this.bmsRolePresenter.shortName) || this.name;
  }

  get bitmaskPresenter() {
    if (this.container.behaviorKind !== BMSBehaviorKind.Bitmask) {
      return null;
    }
    return new BMSRoleBitmaskPresenter(this.container.bmsRoleId);
  }

  get bitmaskOptions() {
    const bitmaskPresenter = this.bitmaskPresenter;
    return bitmaskPresenter ? bitmaskPresenter.options : [];
  }

  get formattedValue() {
    const { container } = this;

    let valueStr = this.bitmaskPresenter
      ? this.bitmaskPresenter.labelFor(container.floatValue)
      : getAppropriateValue(container);

    return valueStr === undefined ? "" : valueStr.toString();
  }
}
