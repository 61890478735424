import { NumberFormatValues, NumericFormat } from "react-number-format";
import * as React from "react";

export interface NumberFormatCustomProps {
  inputRef: (instance: typeof NumericFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  currencySymbol: string;
  locale: string;
  decimalScale?: number;
  maxValue?: number;
  minValue?: number;
}

export function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, currencySymbol, locale, decimalScale, maxValue, minValue, ...other } =
    props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={Number(10000).toLocaleString(locale).charAt(2)}
      decimalSeparator={Number(1.1).toLocaleString(locale).charAt(1)}
      valueIsNumericString
      prefix={currencySymbol}
      allowNegative={false}
      decimalScale={decimalScale}
      isAllowed={(values: NumberFormatValues) => {
        const { value } = values;
        return (!maxValue || +value <= maxValue) && (!minValue || +value >= minValue);
      }}
    />
  );
}

export const RefNumberFormatCustom = React.forwardRef((props, ref) =>
  NumberFormatCustom(props as NumberFormatCustomProps)
);
