import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Box, Grid, SelectChangeEvent } from "@mui/material";
import { MotorSelectionCard, findBaselineMotor } from "./MotorSelectionCard";
import { Section } from "../../SiteConfiguration/ConfigSection";
import { useSnackbar } from "notistack";
import { SavingsKpiRow } from "./SavingsKpiRow";
import {
  MotorEnergySavings_motor_Motor,
  MotorEnergySavings,
} from "generated-gql-types/MotorEnergySavings";
import { MotorSelection, MotorSelection_site_Site } from "generated-gql-types/MotorSelection";
import { MotorPowerComparisonChart } from "./MotorPowerComparisonChart";
import { SiteStore } from "modules/site-manager/stores";
import { useQueryParams } from "modules/common/hooks";
import { BOXED_CARD_STYLE } from "../../../../utils/cardStyles";
import { KpiCardProps, getEmptyMotorKpis, hydrateMotorKpis } from "../EnergySavings/kpis";

const MOTOR_SELECTION_QUERY = gql`
  query MotorSelection($siteId: ID!) {
    site: node(id: $siteId) {
      ... on Site {
        id
        motors {
          id
          name
          displayName
        }
        baselineMotors {
          id
        }
        ...MotorSelectionCard_site
      }
    }
  }
  ${MotorSelectionCard.fragments.site}
`;

const MOTOR_ENERGY_SAVINGS_QUERY = gql`
  query MotorEnergySavings($motorId: ID!) {
    motor: node(id: $motorId) {
      ... on Motor {
        id
        name
        displayName
        driveSerialNumber
        energySavings {
          id
          baselineKwh
          turntideKwh
          utilitySavings
          co2eEmissionsReduction
        }
        ...MotorPowerComparisonChart_motor
      }
    }
  }
  ${MotorPowerComparisonChart.fragments.motor}
`;

interface MotorSavingsSectionProps {
  siteId: string;
  siteStore: SiteStore;
}

type MotorSavingsQueryParams = {
  selectedMotorId: string;
};

const QUERY_PARAM_NAME = "v";

export const MotorSavingsSection = ({ siteId, siteStore }: MotorSavingsSectionProps) => {
  const motorSelectionQuery = useQuery<MotorSelection>(MOTOR_SELECTION_QUERY, {
    variables: { siteId },
  });
  const site = motorSelectionQuery.data?.site as MotorSelection_site_Site;
  const [selectedMotorId, setSelectedMotorId] = useState("");
  const [isMotorConfigured, setMotorConfigured] = useState(false);

  // *** URL can contain a motor ***
  const [urlProcessed, setUrlProcessed] = useState(false);
  const [queryParams, queryParamsLoaded, saveQuery] = useQueryParams<MotorSavingsQueryParams>(
    QUERY_PARAM_NAME,
    {
      selectedMotorId: "",
    }
  );
  const motorEnergySavingsQuery = useQuery<MotorEnergySavings>(MOTOR_ENERGY_SAVINGS_QUERY, {
    variables: { motorId: selectedMotorId },
    skip: !(urlProcessed && selectedMotorId) || (site && !findBaselineMotor(site, selectedMotorId)),
  });
  const motor = (motorEnergySavingsQuery.data?.motor ||
    site?.motors.find((motor) => motor.id === selectedMotorId)) as MotorEnergySavings_motor_Motor;

  const [kpis, setKpis] = useState<KpiCardProps[]>(getEmptyMotorKpis());
  const { enqueueSnackbar } = useSnackbar();

  if (queryParamsLoaded && !urlProcessed) {
    // Only parse the URL once per page load, to allow changing the selected motor
    setUrlProcessed(true);
    setSelectedMotorId(queryParams.selectedMotorId);
  }

  // Effect 1: Select an initial motor
  useEffect(() => {
    if (site?.baselineMotors?.length && !selectedMotorId) {
      const motorId = queryParams.selectedMotorId || site.baselineMotors[0].motor.id;
      setSelectedMotorId(motorId);
      saveQuery(QUERY_PARAM_NAME, {
        selectedMotorId: motorId,
      });
    }
  }, [site, selectedMotorId, saveQuery, queryParams.selectedMotorId]);

  // Effect 2: handle data load error
  const error = motorSelectionQuery.error || motorEnergySavingsQuery.error;
  useEffect(() => {
    if (error) {
      enqueueSnackbar("Energy Savings currently unavailable for this motor", { variant: "error" });
    }
  }, [error, enqueueSnackbar]);

  // Effect 3: Load energy savings for the selected motor
  useEffect(() => {
    const motorIsConfigured = motor && site && findBaselineMotor(site, motor.id) !== undefined;
    if (motor) {
      setKpis(hydrateMotorKpis(motor, motorIsConfigured, getEmptyMotorKpis()));
    }
    setMotorConfigured(motorIsConfigured);
  }, [motor, site, isMotorConfigured]);

  return (
    <Section title="Motor Savings">
      <Grid container={true} direction="row" spacing={0}>
        <Grid item xs={12}>
          <MotorSelectionCard
            site={site}
            selectedMotorId={selectedMotorId}
            onChange={function (event: SelectChangeEvent<any>, child: React.ReactNode): void {
              const motorId = event.target.value;
              setSelectedMotorId(motorId);
              saveQuery(QUERY_PARAM_NAME, {
                selectedMotorId: motorId,
              });
            }}
          />
        </Grid>
        {motor && !isMotorConfigured && (
          <Grid item xs={12} justifyContent="stretch">
            <Box sx={BOXED_CARD_STYLE} p={2} mt={1} mb={1}>
              {motor.displayName || motor.name || motor.driveSerialNumber} is not set up for energy
              savings analysis. Please use the Motor Setup button to initialize the savings
              algorithm.
            </Box>
          </Grid>
        )}
        {motor && isMotorConfigured && !motor.energySavings?.turntideKwh && (
          <Grid item xs={12} justifyContent="stretch">
            <Box sx={BOXED_CARD_STYLE} p={2} mt={1} mb={1}>
              Energy Savings are initialized for the lifetime of{" "}
              {motor.displayName || motor.name || motor.driveSerialNumber}. Please allow 30-60mins
              for results to fully populate after setup.
            </Box>
          </Grid>
        )}
        <SavingsKpiRow kpis={kpis} key={queryParams.selectedMotorId} />
        {site && motor?.energySavings && (
          <MotorPowerComparisonChart motor={motor} siteId={site.id} siteStore={siteStore} />
        )}
      </Grid>
    </Section>
  );
};
