import * as React from "react";
import { Health } from "modules/common/enums";
import { HealthStatusBar } from "modules/site-manager/components/HealthStatusBar";
import { HealthIcon } from "modules/site-manager/components/HealthIcon";

type HealthStatusProps = {
  health: Health;
  message: string;
};

export function HealthStatus(props: HealthStatusProps) {
  return (
    <HealthStatusBar health={props.health} align="left">
      <React.Fragment>
        <HealthIcon health={props.health} />
        &nbsp;&nbsp;{props.message}
      </React.Fragment>
    </HealthStatusBar>
  );
}
