import * as React from "react";
import { useCallback } from "react";
import { Grid } from "@material-ui/core";
import { Typography } from "sigil";
import {
  ChonkToggleButton,
  ChonkToggleButtonGroup,
} from "modules/user-settings/routes/Settings/OrganizationSettings/ChonkToggle";

export type RoleType = "member" | "admin";

export interface RoleSelectProps {
  value: RoleType;
  onChange: (value: RoleType) => void;
}

export function RoleSelect({ value, onChange }: RoleSelectProps) {
  const handleChange = useCallback((_, buttonValue) => onChange(buttonValue), [onChange]);

  return (
    <Grid container={true} spacing={2}>
      <ChonkToggleButtonGroup exclusive value={value} onChange={handleChange}>
        <ChonkToggleButton selected={value === "member"} value="member">
          <Typography variant="title-micro" color="onyx">
            Member
          </Typography>
          <Typography variant="description-sm" color="pewter">
            Members have default privileges within your organization.
          </Typography>
        </ChonkToggleButton>
        <ChonkToggleButton selected={value === "admin"} value="admin">
          <Typography variant="title-micro" color="onyx">
            Administrator
          </Typography>
          <Typography variant="description-sm" color="pewter">
            Administrators have the ability to manage anything within an organization, including
            users, groups, and the sites to which they have been granted access.
          </Typography>
        </ChonkToggleButton>
      </ChonkToggleButtonGroup>
    </Grid>
  );
}
