import { gql, useMutation } from "@apollo/client";

const DISSOCIATE_MOTOR_MUTATION = gql`
  mutation DissociateMotor($motorId: ID!) {
    response: dissociateMotor(id: $motorId) {
      site {
        id
        motors {
          id
        }
      }
    }
  }
`;

export function useDissociateMotorMutation() {
  const [mutate] = useMutation(DISSOCIATE_MOTOR_MUTATION);
  return (motorId: any) => mutate({ variables: { motorId } });
}
