import * as React from "react";
import { ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ListItemProps } from "@material-ui/core/ListItem";
import { NavLink } from "react-router-dom";
import * as H from "history";
import classNames from "classnames";
import { Colors } from "sigil";

export type ListItemNavLinkProps = ListItemProps & {
  to: H.LocationDescriptor;
  exact?: boolean;
  button?: false | undefined;
  onClick?: React.MouseEventHandler;
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.hint,
    borderLeft: "2px solid transparent",
    borderRadius: "2px",
    paddingTop: 9,
    paddingBottom: 9,
    marginTop: 6,
    marginBottom: 6,

    "a.active > &": {
      backgroundColor: Colors.ttBlueLightest,
      color: theme.palette.primary.main,
      borderLeft: `2px solid ${Colors.ttBlue}`,
      fontWeight: "bold",
    },

    "&:hover": {
      backgroundColor: Colors.ttBlueLightest,
    },

    "&:focus": {
      backgroundColor: Colors.ttBlueLightest,
    },
  },
  dense: {
    padding: "3px 16px",
  },
  normal: {
    padding: "9px 16px",
  },
}));

// A ListItem that acts like a NavLink - active when route matches
export function ListItemNavLink({
  to,
  children,
  exact = true,
  dense = true,
  onClick = () => {},
  ...listItemProps
}: ListItemNavLinkProps) {
  const styles = useStyles();
  return (
    <NavLink to={to} exact={exact} onClick={onClick}>
      <ListItem
        className={classNames(dense ? styles.dense : styles.normal, styles.root)}
        dense={true}
        {...listItemProps}
      >
        {children}
      </ListItem>
    </NavLink>
  );
}
