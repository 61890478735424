export enum MotorOpStat {
  CurrentSpeed = "speed_cur",
  RequestedSpeed = "target_speed",
  TTPower = "tt_power",
  PowerIn = "power_in",
  PowerOut = "power_out",
  Torque = "torque",
  CurrentA = "current_a",
  CurrentB = "current_b",
  CurrentC = "current_c",
}

export const MotorOpStats = Object.keys(MotorOpStat).map((key) => MotorOpStat[key]);
