import { Palette } from "@material-ui/core/styles/createPalette";
import { Overrides } from "@material-ui/core/styles/overrides";

export const MuiDialogOverrides = (palette: Palette): Overrides => ({
  MuiDialog: {
    paper: {
      borderTopColor: palette.primary.light,
      borderTopWidth: 2,
    },
  },
});
