import { computed, IObservableArray, observable, reaction } from "mobx";
import { prop } from "datx";
import { maxBy } from "lodash";

import { Model } from "modules/common/model";
import { isNone } from "modules/common/utils/guards";
import {
  BacnetUnit,
  BmsRole,
  LogicVarEditStatus,
  bmsBehaviorKindForLogicPoint,
  bmsBehaviorKindForRole,
} from "modules/site-manager/constants";
import { NO_ZONE } from "modules/site-manager/utils/zone";
import { LogicPoint } from "modules/site-manager/utils/logic-point";
import { getAppropriateValue } from "modules/site-manager/utils/logic-value-container";
import { Ctrl } from "./ctrl";
import { LogicVarOverrideCmd } from "./logic-var-override-cmd";
import { OverrideStatus } from "./ctrl-logic-point";

export enum Priority {
  ShowAll = 0,
  Debug = 16,
  Detail = 32,
  Normal = 64,
  Important = 128,
}

export const Priorities = Object.keys(Priority)
  .map((key) => parseInt(key, 10))
  .filter((parsedKey) => !isNaN(parsedKey))
  .map((key) => key as Priority);

export class CtrlVariable extends Model {
  static type = "controllerVariables";
  static endpoint = "controller_variables";

  @prop.defaultValue("")
  displayName!: string;
  @prop.defaultValue("")
  logicFlowVersion!: string;
  @prop.defaultValue("")
  logicPoint!: string;
  @prop.defaultValue(LogicVarEditStatus.CHANGEABLE)
  editStatus!: LogicVarEditStatus;
  @prop.defaultValue(NO_ZONE)
  zone!: string | null;
  @prop
  enumLabels?: string;
  @prop.defaultValue(0)
  bmsRoleId!: BmsRole;
  @prop
  bacnetUnit?: BacnetUnit | null;
  @prop
  priority!: Priority;
  @prop
  displayOrder!: number;
  @prop
  precision?: number;
  @prop.toOne("controllers")
  controller?: Ctrl;
  @prop.toMany("logicVarOverrideCmds")
  logicVarOverrideCmds?: IObservableArray<LogicVarOverrideCmd>;

  // tslint:disable-next-line:no-any
  constructor(...args: any[]) {
    super(...args);
    reaction(
      () => {
        const point = this.latestCtrlLogicPointJS;
        return point && point.recordedAt;
      },
      () => {
        this.pendingOverrides.forEach((o) => o.acknowledge());
      }
    );
  }

  @computed get ctrl() {
    return this.controller;
  }

  @computed get controllerId() {
    return this.controller?.id;
  }

  @computed get logicPointJS() {
    return new LogicPoint(this.logicPoint, Boolean(this.enumLabels));
  }

  @computed get qualifiedName() {
    return `${this.controllerId || ""}$${this.logicPoint}`;
  }

  @computed get behaviorKind() {
    const { bmsRoleId } = this;
    return bmsRoleId
      ? bmsBehaviorKindForRole(bmsRoleId)
      : bmsBehaviorKindForLogicPoint(this.logicPointJS);
  }

  @computed get isEditable() {
    return this.editStatus !== LogicVarEditStatus.READONLY;
  }

  @computed get latestCtrlLogicPointJS() {
    if (!this.ctrl) {
      return null;
    }
    if (!this.ctrl.latestCtrlLogicPointJS) {
      return null;
    }
    return this.ctrl.latestCtrlLogicPointJS;
  }

  @computed get enumMap() {
    const { enumLabels } = this;
    if (!enumLabels) return undefined;
    return enumLabels.split(",").reduce((m, i) => {
      const kv = i.split("=");
      if (kv.length !== 2) return m;
      m[kv[0]] = kv[1];
      return m;
    }, {});
  }

  @computed get currentNumericValue() {
    const { latestCtrlLogicPointJS } = this;
    if (!latestCtrlLogicPointJS) {
      return null;
    }
    return latestCtrlLogicPointJS.numericValueForVariable(this.id);
  }

  @computed get currentValue() {
    const { latestCtrlLogicPointJS } = this;
    if (!latestCtrlLogicPointJS) {
      return null;
    }
    return latestCtrlLogicPointJS.valueForVariable(this.id);
  }

  @computed get hasUnits() {
    return (
      !isNone(this.bacnetUnit) &&
      this.bacnetUnit !== BacnetUnit.NoUnits &&
      this.bacnetUnit !== BacnetUnit.LogicValue
    );
  }

  @computed get pendingOverrides(): IObservableArray<LogicVarOverrideCmd> {
    const overrides = this.logicVarOverrideCmds || observable.array();
    return observable(overrides.filter((cmd) => !cmd.acknowledged));
  }

  @computed get clientValueSetting(): LogicVarOverrideCmd | undefined {
    return maxBy(this.pendingOverrides, (override) => override.createdAt);
  }

  @computed get isOverridden() {
    const { id, latestCtrlLogicPointJS } = this;
    if (!latestCtrlLogicPointJS) {
      return null;
    }
    return latestCtrlLogicPointJS.overrideStatusForVariable(id) !== OverrideStatus.None;
  }

  @computed get isSetByClient() {
    return !!this.clientValueSetting;
  }

  @computed get clientSetValue() {
    const { behaviorKind, clientValueSetting } = this;
    if (clientValueSetting) {
      return getAppropriateValue({
        behaviorKind,
        floatValue: clientValueSetting.floatValue,
        booleanValue: clientValueSetting.booleanValue,
      });
    }
    return null;
  }

  static compare = (instance1: CtrlVariable, instance2: CtrlVariable) => {
    return (
      instance1.displayOrder - instance2.displayOrder ||
      instance1.displayName.localeCompare(instance2.displayName) ||
      (instance1.controllerId || "").localeCompare(instance2.controllerId || "")
    );
  };
}
