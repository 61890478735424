import * as React from "react";
import { observer } from "mobx-react";
import memoizeOne from "memoize-one";
import { Typography, WithStyles } from "@material-ui/core";

import { TSBucketGrouped } from "modules/common/stores/api";
import { LightCaption } from "modules/common/components/Typography";
import { CtrlVariable } from "modules/site-manager/models";
import { BMSBehaviorKind } from "modules/site-manager/constants";
import { AnalysisStore } from "modules/site-manager/stores";
import { CtrlLogicPoint } from "modules/site-manager/models";
import { CtrlVariablePresenter } from "modules/site-manager/presenters";
import { CtrlVariableValue } from "modules/site-manager/components/CtrlVariableValue";
import { UnitSymbol } from "modules/site-manager/components/UnitSymbol";
import { Sparkline, SparklinePoint } from "./sparkline";
import { CtrlVariableHeroStyles } from "./style";
import { TitleSmall } from "sigil";

type CtrlVariableHeroItemProps = {
  variable: CtrlVariable;
  analysisStore: AnalysisStore;
  onClick?: (variable: CtrlVariable) => void;
};

@observer
class CtrlVariableHeroItemComponent extends React.Component<
  CtrlVariableHeroItemProps & WithStyles<typeof CtrlVariableHeroStyles>
> {
  getPointDataFromStore = memoizeOne(
    (variable: CtrlVariable, points: TSBucketGrouped<CtrlLogicPoint>[]) => {
      return points.map((point) => {
        const sparkPoint: SparklinePoint = {
          timestamp: new Date(point.timestamp),
          value: null,
        };

        const ctrlData = point.data[variable.controllerId || ""];
        if (!ctrlData) {
          return sparkPoint;
        }

        const ctrlPoints = ctrlData.values;
        if (!ctrlPoints) {
          return sparkPoint;
        }

        const variableValue = ctrlPoints[variable.id];
        if (!variableValue) {
          return sparkPoint;
        }

        if (CtrlLogicPoint.isAnalogValue(variableValue)) {
          sparkPoint.value = variableValue.analogValue;
        } else if (CtrlLogicPoint.isBinaryValue(variableValue)) {
          sparkPoint.value = variableValue.binaryValue ? 1 : 0;
        } else if (CtrlLogicPoint.isEnumValue(variableValue)) {
          sparkPoint.value = variableValue.enumValue;
        }

        return sparkPoint;
      });
    }
  );

  get pointDataFromStore() {
    const { variable, analysisStore } = this.props;
    return this.getPointDataFromStore(variable, analysisStore.ctrlPoints);
  }

  handleClick = () => {
    const { variable, onClick } = this.props;
    onClick && onClick(variable);
  };

  render() {
    const { classes, variable, analysisStore } = this.props;
    const presenter = new CtrlVariablePresenter(variable);

    return (
      <div className={classes.item}>
        <div className={classes.itemClickable} onClick={this.handleClick}>
          <div className={classes.itemTextWrapper}>
            {/* Hero Value */}
            <CtrlVariableValue
              variable={variable}
              fallback="– –"
              showUnits={true}
              formatValue={(value) => <TitleSmall component="span">{value}</TitleSmall>}
              formatSymbol={(symbol) => (
                <Typography variant="h3" display="inline">
                  {symbol}
                </Typography>
              )}
            />

            {/* Name */}
            <Typography variant="h6">{presenter.displayName}</Typography>

            {/* Supervisor name */}
            <LightCaption>{presenter.controllerDisplayName}</LightCaption>
          </div>

          {/* Sparkline */}
          <div className={classes.itemSparkWrapper}>
            {variable.behaviorKind === BMSBehaviorKind.Float && (
              <Sparkline
                formatValue={(value) =>
                  value === null ? (
                    "?"
                  ) : (
                    // TODO: The display characteristics of CtrlVariableValue can be extracted
                    // so that this value + unit combination can be a "dumb" component
                    <React.Fragment>
                      {value.toFixed(variable.precision)}
                      <UnitSymbol unit={variable.bacnetUnit || undefined} />
                    </React.Fragment>
                  )
                }
                data={analysisStore.isLoadingCtrls ? [] : this.pointDataFromStore}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const CtrlVariableHeroItem = CtrlVariableHeroItemComponent;
