import * as React from "react";
import { CardContent, CardHeader, Collapse } from "@material-ui/core";
import { SearchField } from "modules/common/components/SearchField";

// Loading state
export function CardSkeleton({ subheader }: { subheader: string }) {
  return (
    <React.Fragment>
      <CardHeader title="&nbsp;" subheader={subheader} />
      <CardContent>
        <SearchField disabled={true} />
      </CardContent>
      <Collapse in={false}>
        <div />
      </Collapse>
    </React.Fragment>
  );
}
