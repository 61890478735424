import React, { useEffect, useState } from "react";
import { BasicBarChart } from "modules/charts/components/BasicBarChart";
import { YAxis } from "modules/charts/constants";
import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import { BOXED_CARD_STYLE, getEnergyCardTitleStyle } from "../../utils/cardStyles";
import { labelFontStyle } from "global-styles";
import { Typography } from "@material-ui/core";
import { SeriesColors } from "modules/charts/components/BasicBarChart";
import moment from "moment";
import { SiteMonthlySavingsChart_site_monthlyEnergySavings } from "generated-gql-types/SiteMonthlySavingsChart_site";
import { OrganizationMonthlySavingsChart_org_monthlyEnergySavings } from "generated-gql-types/OrganizationMonthlySavingsChart_org";
import { CO2E, KWH, UTILITY_COST } from "modules/site-manager/constants";
import { MonthlyBarChartTooltip } from "./MonthlyBarChartTooltip";
import { Y_AXIS_PROPS } from "../../utils/chartUtils";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { SearchOption } from "../SearchBar";

export enum MonthlyChartUnitType {
  MONEY = "MONEY",
  POWER = "POWER",
  CARBON = "CARBON",
}

const SERIES = {};

SERIES[MonthlyChartUnitType.MONEY] = [
  {
    id: "baselineUtilityCost",
    name: "Baseline",
    dataKey: "baselineUtilityCost",
    color: SeriesColors[0],
  },
  {
    id: "turntideUtilityCost",
    name: "Turntide",
    dataKey: "turntideUtilityCost",
    color: SeriesColors[1],
  },
];

SERIES[MonthlyChartUnitType.POWER] = [
  {
    id: "baselineKwh",
    name: "Baseline",
    dataKey: "baselineKwh",
    color: SeriesColors[0],
  },
  { id: "turntideKwh", name: "Turntide", dataKey: "turntideKwh", color: SeriesColors[1] },
];

SERIES[MonthlyChartUnitType.CARBON] = [
  {
    id: "baselineCo2eEmissions",
    name: "Baseline",
    dataKey: "baselineCo2eEmissions",
    color: SeriesColors[0],
  },
  {
    id: "turntideCo2eEmissions",
    name: "Turntide",
    dataKey: "turntideCo2eEmissions",
    color: SeriesColors[1],
  },
];

const MONTHLY_CHART_UNITS_OPTIONS = [
  {
    label: UTILITY_COST,
    value: MonthlyChartUnitType.MONEY,
  },
  {
    label: KWH,
    value: MonthlyChartUnitType.POWER,
  },
  {
    label: CO2E,
    value: MonthlyChartUnitType.CARBON,
  },
];

interface MonthlySavingsData extends SiteMonthlySavingsChart_site_monthlyEnergySavings {
  label: string;
  year: string;
}

export const mapData = (
  monthlyEnergySavings: SiteMonthlySavingsChart_site_monthlyEnergySavings[]
) => {
  return monthlyEnergySavings.map(
    (monthSavings) =>
      ({
        ...monthSavings,
        label: moment.utc(monthSavings.month).format("MMM"),
        year: moment.utc(monthSavings.month).format("YYYY"),
      } as MonthlySavingsData)
  );
};

const getYearAxisFormatter = (data: MonthlySavingsData[]) => (value: string, index: number) => {
  if (index === 0 || (data && data[index]?.label === "Jan")) {
    return value;
  }
  return "";
};

type MonthlySavings =
  | OrganizationMonthlySavingsChart_org_monthlyEnergySavings
  | SiteMonthlySavingsChart_site_monthlyEnergySavings;

interface MonthlySavingsChartProps {
  hasUtilityRate?: boolean;
  co2eGPerKwh?: number | null;
  monthlyEnergySavings: MonthlySavings[];
}

export const MonthlySavingsChart = ({
  hasUtilityRate,
  co2eGPerKwh,
  monthlyEnergySavings,
}: MonthlySavingsChartProps) => {
  const theme = useTheme();
  const [monthlyChartSeries, setSeries] = useState<SearchOption[]>([
    MONTHLY_CHART_UNITS_OPTIONS[1],
  ]);
  const [monthlyChartUnits, setMonthlyChartUnits] = useState(MONTHLY_CHART_UNITS_OPTIONS[1].value);
  const [isUserUnits, setIsUserUnits] = useState(false);
  const [data, setData] = useState(monthlyEnergySavings && mapData(monthlyEnergySavings));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    monthlyEnergySavings && setData(mapData(monthlyEnergySavings));
    const chartUnitOptions = [];
    if (hasUtilityRate) {
      if (!isUserUnits) {
        // default to $ if available
        setMonthlyChartUnits(MONTHLY_CHART_UNITS_OPTIONS[0].value);
      }
      chartUnitOptions.push(MONTHLY_CHART_UNITS_OPTIONS[0]);
    } else if (monthlyChartUnits === MonthlyChartUnitType.MONEY) {
      setMonthlyChartUnits(MONTHLY_CHART_UNITS_OPTIONS[1].value);
    }
    chartUnitOptions.push(MONTHLY_CHART_UNITS_OPTIONS[1]);
    if (co2eGPerKwh) {
      chartUnitOptions.push(MONTHLY_CHART_UNITS_OPTIONS[2]);
    }
    setSeries(chartUnitOptions);
  }, [isUserUnits, monthlyChartUnits, monthlyEnergySavings, hasUtilityRate, co2eGPerKwh]);

  return (
    <>
      {data && data.length ? (
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          sx={BOXED_CARD_STYLE}
          justifyContent={"stretch"}
          justifyItems={"stretch"}
          p={2}
        >
          <Grid item container xs={true} direction="row" justifyContent="space-between" m={1}>
            <Typography style={getEnergyCardTitleStyle(isMobile)}>Last 12 Months</Typography>
            <Grid item xs="auto">
              <FormControl variant="standard">
                <Select
                  aria-label={"Units"}
                  key={"units"}
                  value={monthlyChartUnits}
                  onChange={(value) => {
                    setIsUserUnits(true);
                    setMonthlyChartUnits(value.target.value as MonthlyChartUnitType);
                  }}
                  size="small"
                  sx={{
                    border: 0,
                    width: "100%",
                    marginTop: "3px",
                    ...labelFontStyle,
                    "& .MuiSelect-select": {
                      paddingLeft: "8px",
                    },
                  }}
                  autoFocus={false}
                  disabled={false}
                  label={"Units"}
                  disableUnderline
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        "& .MuiMenuItem-root": {
                          ...labelFontStyle,
                        },
                      },
                    },
                  }}
                >
                  {monthlyChartSeries.map((option) => (
                    <MenuItem
                      key={option.label}
                      value={option.value || 0}
                      selected={option.value === monthlyChartUnits}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <BasicBarChart
            data={data}
            series={SERIES[monthlyChartUnits]}
            xAxisProps={{ dataKey: "label" }}
            xAxisProps2={{
              dataKey: "year",
              axisLine: false,
              tickSize: 0,
              allowDuplicatedCategory: true,
              tickFormatter: getYearAxisFormatter(data),
            }}
            yAxisPropsSet={[{ yAxisId: YAxis.Left, ...Y_AXIS_PROPS }]}
            tooltipProps={{ content: <MonthlyBarChartTooltip /> }}
          />
        </Grid>
      ) : null}
    </>
  );
};
