import * as React from "react";
import { Route, Switch, Redirect, RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react";
import { ModelsStore } from "modules/common/stores";
import {
  Ctrl,
  CtrlVariable,
  LogicVarOverrideCmd,
  Motor,
  MotorGroup,
  Site,
} from "modules/site-manager/models";
import * as H from "history";

import { SiteRoute } from "modules/site-manager/routes/Site";
import { ModelProvider, ModelRenderProps } from "modules/site-manager/components/ModelProvider";
import { SitesIndexRoute } from "./SitesIndex";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { gql, useQuery } from "@apollo/client";
import { StaticContext } from "react-router";
import { getOpaqueSiteId } from "../../utils/routing";
import { Site_node_Site } from "generated-gql-types/Site";
import { SiteBreadCrumb } from "../Site/SiteBreadCrumb";

ModelsStore.addTypes(Ctrl, Site, Motor, MotorGroup, CtrlVariable, LogicVarOverrideCmd);

const query = gql`
  query Site($siteId: ID!) {
    node(id: $siteId) {
      ... on Site {
        id
        energySavingsEnabled
        viewerIsAdmin
        ...SiteBreadcrumb_site
      }
    }
  }
  ${SiteBreadCrumb.fragments.site}
`;

interface SitesRouteAdapterProps {
  routeProps: WithSnackbarProps;
  providedProps: ModelRenderProps<Site>;
  renderProps: RouteComponentProps<any, StaticContext, H.History.UnknownFacade>;
}

// Adapter functional component to inject a gql query hook between the SitesRoute class component and the callback that instantiates it
const SitesRouteAdapter = ({ routeProps, providedProps, renderProps }: SitesRouteAdapterProps) => {
  const { model, modelsStore } = providedProps;
  const { data, error } = useQuery(query, {
    variables: { siteId: getOpaqueSiteId(renderProps.match.params.site_id) },
  });
  React.useEffect(() => {
    if (error) {
      routeProps.enqueueSnackbar(
        `The site was not found. You may not be allowed to access it. ${error ?? ""}`,
        {
          variant: "error",
        }
      );
    }
  });
  if (error) {
    return <Redirect to="/sites" />;
  } else {
    const siteNode = data?.node as Site_node_Site;
    return (
      <SiteRoute
        {...renderProps}
        site={model as Site}
        siteNode={siteNode}
        modelsStore={modelsStore}
      />
    );
  }
};

const SitesRouteComponent = (props: WithSnackbarProps) => {
  return (
    <Switch>
      <Route
        path="/sites/:site_id"
        render={(renderProps) => (
          <ModelProvider
            modelType={Site}
            id={renderProps.match.params.site_id}
            include={["controllers", "controllers.variables", "controllers.motor_groups", "motors"]}
            pollSeconds={15}
            render={(providedProps) => {
              return (
                <SitesRouteAdapter
                  routeProps={props}
                  providedProps={providedProps}
                  renderProps={renderProps}
                />
              );
            }}
          />
        )}
      />

      <Route path="/sites" component={SitesIndexRoute} />
    </Switch>
  );
};

export const SitesRoute = withSnackbar(observer(SitesRouteComponent));
