import * as React from "react";
import { useCallback } from "react";
import { gql } from "@apollo/client";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { DeleteNotificationChannel_channel } from "generated-gql-types/DeleteNotificationChannel_channel";
import { useDeleteNotificationChannelMutation } from "modules/common/mutations/DeleteNotificationChannel";
import { Box } from "@material-ui/core";
import { Colors } from "sigil";

type DeleteNotificationChannelProps = {
  channel: DeleteNotificationChannel_channel;
};

const DeleteNotificationChannelComponent: React.FC<DeleteNotificationChannelProps> = ({
  channel,
}) => {
  const mutation = useDeleteNotificationChannelMutation();
  const handleClick = useCallback(() => mutation({ id: channel.id }), [channel, mutation]);
  return (
    <div onClick={handleClick} style={{ cursor: "pointer", marginTop: "-5px" }}>
      <Box flexGrow={0} textAlign="center" alignContent="center" pl={2}>
        {/* custom X icon, SVG generated by Figma */}
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            // tslint:disable-next-line: max-line-length
            d="M0 10.9406L4.54876 6.48713L0 2.05941L2.03824 0L6.51243 4.50495L10.9618 0L13 2.03366L8.4761 6.48713L13 10.9663L10.9618 13L6.51243 8.49505L2.03824 13L0 10.9406Z"
            fill={Colors.slate}
          />
        </svg>
      </Box>
    </div>
  );
};

export const DeleteNotificationChannel = createFragmentContainer(
  DeleteNotificationChannelComponent,
  {
    channel: gql`
      fragment DeleteNotificationChannel_channel on NotificationChannel {
        id
      }
    `,
  }
);
