import * as React from "react";
import { gql } from "@apollo/client";
import { Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import { FormikSubmitButton, FormikTextField } from "modules/common/components/MuiFormik";
import { AccountNameForm_user } from "generated-gql-types/AccountNameForm_user";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { useUpdateCurrentUserMutation } from "modules/common/mutations/UpdateCurrentUserMutation";
import { useSnackbar } from "notistack";

interface AccountNameFormProps {
  user: AccountNameForm_user;
}

function AccountNameForm({ user }: AccountNameFormProps) {
  const updateUserName = useUpdateCurrentUserMutation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={user}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        updateUserName(values)
          .then(() => enqueueSnackbar("Saved", { variant: "success" }))
          .catch(() => enqueueSnackbar("Something went wrong", { variant: "error" }))
          .then(() => actions.setSubmitting(false));
      }}
    >
      <Form>
        <Grid container={true} spacing={2}>
          <Grid item={true} sm={6}>
            <FormikTextField label="First Name" name="firstName" required={true} fullWidth={true} />
          </Grid>

          <Grid item={true} sm={6}>
            <FormikTextField label="Last Name" name="lastName" required={true} fullWidth={true} />
          </Grid>
          <Grid container={true} item={true} xs={12}>
            <FormikSubmitButton>Change Name</FormikSubmitButton>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}

export default createFragmentContainer(AccountNameForm, {
  user: gql`
    fragment AccountNameForm_user on User {
      id
      firstName
      lastName
    }
  `,
});
