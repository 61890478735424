import * as React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface FancyDividerProps {
  children?: React.ReactNode;
  icon?: React.ReactElement;
  button?: React.ReactElement;
}

export const FancyDivider = ({ children, icon, button }: FancyDividerProps) => {
  const theme = useTheme();
  return (
    <Box
      mt={2}
      mb={2}
      sx={{ display: "flex", justifyContent: "stretch", alignItems: "center", width: "100%" }}
    >
      <Box mr={1}>
        <Stack direction="row" spacing={0.5} alignItems="center" color={theme.palette.primary.main}>
          {icon}
          <Typography
            fontFamily="Barlow"
            fontSize="24px"
            fontWeight="400"
            variant="caption"
            color={theme.palette.text.primary}
          >
            {children}
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Divider />
      </Box>
      {button && (
        <Box pt="6px" pl="24px">
          {button}
        </Box>
      )}
    </Box>
  );
};
