import * as React from "react";
import {
  HealthStatusBar,
  useHealthStatusBarStyles,
} from "modules/site-manager/components/HealthStatusBar";
import { Health } from "modules/common/enums";
import { EnergySavingsBanner_site } from "generated-gql-types/EnergySavingsBanner_site";
import { useHistory } from "react-router-dom";
import { Button } from "sigil";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { gql } from "@apollo/client";
import { Grid } from "@mui/material";
import { useUpdateSiteMutation } from "modules/common/mutations/UpdateSiteInputMutation";
import { useSnackbar } from "notistack";
import { unmarshalNumberId } from "modules/common/utils/relay";

const message = "Enable energy savings calculations for this site";

const EnableEnergySavingsButton = ({ siteId }: { siteId: string }) => {
  const { enqueueSnackbar } = useSnackbar();
  const updateSite = useUpdateSiteMutation();
  const history = useHistory();
  return (
    <Button
      variant="solid"
      color="primary"
      size="small"
      onClick={() => {
        updateSite({
          siteId: siteId,
          energySavingsEnabled: true,
        })
          .then(() => {
            enqueueSnackbar("Energy savings enabled", { variant: "success" });
            history.push(`/sites/${unmarshalNumberId(siteId)}/savings`);
          })
          .catch((e: any) => {
            enqueueSnackbar(`Error enabling site energy savings: ${e.message || e}`, {
              variant: "error",
            });
          });
      }}
    >
      Enable
    </Button>
  );
};

const EnergySavingsBannerComponent = ({ site }: { site: EnergySavingsBanner_site }) => {
  const styles = useHealthStatusBarStyles();
  if (!site.viewerIsAdmin || site.energySavingsEnabled) {
    return null;
  }
  return (
    <HealthStatusBar align="left" health={Health.HEALTHY} classes={styles}>
      <Grid
        container
        sx={{
          fontSize: "15px",
          padding: "8px 10px 11px",
        }}
      >
        <Grid item xs>
          {message}
        </Grid>
        <Grid item xs="auto">
          <EnableEnergySavingsButton siteId={site.id} />
        </Grid>
      </Grid>
    </HealthStatusBar>
  );
};

export const EnergySavingsBanner = createFragmentContainer(EnergySavingsBannerComponent, {
  site: gql`
    fragment EnergySavingsBanner_site on Site {
      id
      energySavingsEnabled
      viewerIsAdmin
    }
  `,
});
