import { LOGIC_POINT_KIND, LogicPoint } from "modules/site-manager/utils/logic-point";
import { BmsRole } from "./bms-role";

export enum BMSBehaviorKind {
  Unknown,
  Float,
  Bool,
  Bitmask,
}

// TODO: This can be removed once bms_role backend record support enumerations
export function bmsBehaviorKindForRole(name: BmsRole | undefined): BMSBehaviorKind {
  switch (name) {
    case BmsRole.MIN_TEMP_SETTING:
    case BmsRole.MAX_TEMP_SETTING:
    case BmsRole.MIN_STATIC_PRESS_SETTING:
    case BmsRole.MAX_STATIC_PRESS_SETTING:
    case BmsRole.STATIC_PRESS:
    case BmsRole.TEMP_OSA:
    case BmsRole.TEMP_RETURN:
    case BmsRole.TEMP_SUPPLY:
    case BmsRole.TEMP_SPACE:
    case BmsRole.LIGHT_LVL_SETTING:
    case BmsRole.LIGHT_LVL:
    case BmsRole.HUMIDITY_INSIDE:
    case BmsRole.HUMIDITY_OUTSIDE:
    case BmsRole.HUMIDITY_SETTING:
    case BmsRole.CUSTOM_1:
    case BmsRole.CUSTOM_2:
    case BmsRole.CUSTOM_3:
    case BmsRole.CUSTOM_4:
    case BmsRole.CUSTOM_5:
      return BMSBehaviorKind.Float;
    case BmsRole.HVAC_FAN_MODE:
    case BmsRole.HVAC_OP_MODE:
      return BMSBehaviorKind.Bitmask;
    default:
      return BMSBehaviorKind.Unknown;
  }
}

export function bmsBehaviorKindForLogicPoint(logicPoint: LogicPoint | undefined): BMSBehaviorKind {
  if (!logicPoint) {
    return BMSBehaviorKind.Unknown;
  }
  return logicPoint.kind === LOGIC_POINT_KIND.ANALOG ? BMSBehaviorKind.Float : BMSBehaviorKind.Bool;
}
