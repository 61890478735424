import * as React from "react";
import { gql } from "@apollo/client";
import { Pagination_pageInfo } from "generated-gql-types/Pagination_pageInfo";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { SmcMuiTheme } from "theme";
import { useState, useEffect } from "react";
import classNames from "classnames";
import { Colors, ArrowLeft, ArrowRight } from "sigil";

const usePaginationStyles = makeStyles((theme: typeof SmcMuiTheme) =>
  createStyles({
    grid: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },

    legend: {
      fontSize: "12px",
      lineHeight: "18px",
      flexGrow: 1,
      marginTop: "3px",
    },

    icon: {
      padding: `0 0 0 ${theme.spacing(1)}px`,
      "&": {
        color: theme.palette.tin.main,
      },
    },

    active: {
      cursor: "pointer",
      "&": {
        color: Colors.onyx,
      },
      "&:hover": {
        color: Colors.wave,
      },
    },
  })
);

type PaginationProps = {
  pageInfo?: Pagination_pageInfo;
  totalCount?: number | null;
  next: (cursor: string | null) => void;
  prev: (cursor: string | null) => void;
};
const PaginationContainer = ({ pageInfo, totalCount, next, prev }: PaginationProps) => {
  const styles = usePaginationStyles();
  // Ensure there is no flashing on state chsnge
  const [page, setPage] = useState<{ pageInfo?: Pagination_pageInfo; totalCount?: number | null }>(
    {}
  );
  useEffect(() => {
    pageInfo && setPage({ pageInfo, totalCount });
  }, [pageInfo, totalCount]);
  if (!page.pageInfo || !page.totalCount) return null;

  return (
    <Grid container className={styles.grid}>
      <Typography className={styles.legend}>
        <strong>{page.pageInfo.startIndex} </strong> to <strong>{page.pageInfo.endIndex} </strong>{" "}
        of <strong>{page.totalCount}</strong>
      </Typography>
      <div
        className={
          page.pageInfo.hasPreviousPage ? classNames(styles.icon, styles.active) : styles.icon
        }
        onClick={() => page?.pageInfo?.hasPreviousPage && prev(page?.pageInfo?.startCursor)}
      >
        <ArrowLeft inline={false} offset={4} />
      </div>
      <div
        className={page.pageInfo.hasNextPage ? classNames(styles.icon, styles.active) : styles.icon}
        onClick={() => page?.pageInfo?.hasNextPage && next(page?.pageInfo.endCursor)}
      >
        <ArrowRight inline={false} offset={4} />
      </div>
    </Grid>
  );
};

export const Pagination = createFragmentContainer(PaginationContainer, {
  pageInfo: gql`
    fragment Pagination_pageInfo on PageInfo {
      startIndex
      startCursor
      endIndex
      endCursor
      hasNextPage
      hasPreviousPage
    }
  `,
});
