import { gql, MutationUpdaterFn } from "@apollo/client";
import { CreateEmailChannelInput, CreateSMSChannelInput } from "generated-gql-types/globalTypes";
import { CreateEmailChannel } from "generated-gql-types/CreateEmailChannel";
import { CreateSMSChannel } from "generated-gql-types/CreateSMSChannel";
import { composeMutationHook } from "./helpers";
import {
  GetNotificationChannel,
  GetNotificationChannel_channels,
} from "generated-gql-types/GetNotificationChannel";

const emailMutationQuery = gql`
  mutation CreateEmailChannel($input: CreateEmailChannelInput!) {
    createEmailChannel(input: $input) {
      emailChannel {
        id
        emailAddress
        isVerified
      }
    }
  }
`;

const smsMutationQuery = gql`
  mutation CreateSMSChannel($input: CreateSMSChannelInput!) {
    createSMSChannel(input: $input) {
      smsChannel {
        id
        phoneNumber
        isVerified
      }
    }
  }
`;

export const getChannelsQuery = gql`
  query GetNotificationChannel {
    channels: notificationChannels {
      ... on EmailChannel {
        id
        emailAddress
        isVerified
      }
      ... on SMSChannel {
        id
        phoneNumber
        isVerified
      }
    }
  }
`;

const addChannelToCache: MutationUpdaterFn<CreateEmailChannel | CreateSMSChannel> = (
  cache,
  { data }
) => {
  if (!data) {
    return;
  }
  const { channels }: GetNotificationChannel = cache.readQuery({ query: getChannelsQuery }) || {
    channels: [],
  };
  let newChannel: GetNotificationChannel_channels;
  if ((data as CreateEmailChannel).createEmailChannel) {
    newChannel = (data as CreateEmailChannel).createEmailChannel.emailChannel;
  } else {
    newChannel = (data as CreateSMSChannel).createSMSChannel.smsChannel;
  }
  cache.writeQuery({
    query: getChannelsQuery,
    data: { channels: channels.concat([newChannel]) },
  });
};

const useCreateEmailMutation = composeMutationHook<CreateEmailChannel, CreateEmailChannelInput>(
  emailMutationQuery,
  {
    update: addChannelToCache,
  }
);

const useCreateSMSMutation = composeMutationHook<CreateSMSChannel, CreateSMSChannelInput>(
  smsMutationQuery,
  {
    update: addChannelToCache,
  }
);

export type CreateChannelInput = CreateEmailChannelInput | CreateSMSChannelInput;
const isEmail = (input: CreateChannelInput): input is CreateEmailChannelInput =>
  (input as CreateEmailChannelInput).emailAddress !== undefined;
const isSMS = (input: CreateChannelInput): input is CreateSMSChannelInput =>
  (input as CreateSMSChannelInput).phoneNumber !== undefined;

export const useCreateNotificationChannelMutation = () => {
  const emailMutation = useCreateEmailMutation();
  const smsMutation = useCreateSMSMutation();
  return (input: CreateChannelInput): Promise<any> => {
    if (isEmail(input)) {
      return emailMutation(input);
    } else if (isSMS(input)) {
      return smsMutation(input);
    }
    // winter is here
    return Promise.resolve();
  };
};
export default useCreateNotificationChannelMutation;
