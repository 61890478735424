import * as React from "react";
import memoizeOne from "memoize-one";

import { BacnetUnit } from "modules/site-manager/constants";
import { BacnetUnitPresenter } from "modules/site-manager/presenters/bacnet-unit";

interface UnitSymbolProps {
  unit?: BacnetUnit;
  trim?: boolean;
}

// Transformers are a hacky shortcut to a true parser for the unit symbols.
// They allow you to capture pieces of a unit's symbol string, and then wrap
// with a specified component.
// Example:
// `killowatt hours per square meter` should be rendered as `kWh/m<sup>2</sup>`
type Transformer = {
  regex: RegExp;
  component: string;
};

const transformers: Transformer[] = [
  { regex: /_([^_]+)_/g, component: "sub" },
  { regex: /\^([^^]+)\^/g, component: "sup" },
];

export class UnitSymbol extends React.Component<UnitSymbolProps> {
  symbolString = memoizeOne((unit: BacnetUnit) => {
    return new BacnetUnitPresenter(unit).symbolString;
  });

  getNodesFromEscapedString = (
    str: string,
    regex: RegExp,
    NodeType: "sub" | "sup",
    key: string
  ): React.ReactNode[] => {
    let shouldUseNode = true;
    return str.split(regex).map((s, i) => {
      shouldUseNode = !shouldUseNode;
      return shouldUseNode ? <NodeType key={`${key}-${i}`}>{s}</NodeType> : s;
    });
  };

  render() {
    const { unit, trim } = this.props;
    if (!unit) {
      return null;
    }

    let symbolString = this.symbolString(unit);
    if (!symbolString) {
      return null;
    }
    if (trim) {
      symbolString = symbolString.trim();
    }

    const parts: React.ReactNode[] = [symbolString];
    transformers.forEach((transformer, ti) => {
      parts.forEach((part, pi) => {
        if (typeof part !== "string") {
          return;
        }

        const nodes = this.getNodesFromEscapedString(
          part,
          transformer.regex,
          transformer.component as "sub" | "sup",
          `${ti}-${pi}`
        );

        parts.splice(pi, 1, ...nodes);
      });
    });

    return <React.Fragment>{parts}</React.Fragment>;
  }
}
