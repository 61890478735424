import * as React from "react";
import classNames from "classnames";

import { WithTheme, withTheme } from "@material-ui/core/styles";

import { Health } from "modules/common/enums";
import { getColorForHealth } from "modules/site-manager/utils/health";

type HealthIconProps = {
  health: Health;
};

const classes = {
  [Health.HEALTHY]: "fa-check-circle",
  [Health.WARNING]: "fa-exclamation-triangle",
  [Health.ERROR]: "fa-window-close",
};

export const HealthIcon = withTheme(({ health, theme }: HealthIconProps & WithTheme) => {
  let iconClass = classes[health] || "fa-question-circle";

  return (
    <i
      className={classNames("fas", iconClass)}
      style={{ color: getColorForHealth(theme, health) }}
    />
  );
});
