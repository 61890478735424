import * as React from "react";
import { action } from "mobx";
import { IJsonapiModel, IRequestOptions, Response } from "datx-jsonapi";
import { injectStores, ModelsStore } from "modules/common/stores";

interface ModelConstructor<T> {
  type: string;
  new (...args: any[]): T;
}
export interface ModelRenderProps<T> {
  model?: T;
  modelsStore: ModelsStore;
  loading: boolean;
}

interface ModelProviderProps<T> {
  id: string | undefined | null;
  modelType: ModelConstructor<T>;
  render: (providedProps: ModelRenderProps<T>) => JSX.Element | null;
  modelsStore?: ModelsStore;
  include?: Array<string>;
  pollSeconds?: number;
}

interface ModelProviderState<T> {
  model?: T;
  loading: boolean;
}

@injectStores("models")
export class ModelProvider<T extends IJsonapiModel> extends React.PureComponent<
  ModelProviderProps<T>,
  ModelProviderState<T>
> {
  pollHandle?: number;

  constructor(props: ModelProviderProps<T>) {
    super(props);
    this.state = { loading: true };
  }

  get fetchOptions() {
    const opts: IRequestOptions = {
      skipCache: true,
    };
    if (this.props.include) {
      opts.include = this.props.include;
    }
    return opts;
  }

  componentDidMount() {
    this._refreshModel();
  }

  componentDidUpdate(prevProps: ModelProviderProps<T>) {
    const { id, modelType, pollSeconds } = this.props;
    if (
      prevProps.id === id &&
      prevProps.modelType === modelType &&
      prevProps.pollSeconds === pollSeconds
    ) {
      return;
    }

    this.setState({ model: undefined, loading: true });
    this._refreshModel();
  }

  componentWillUnmount() {
    window.clearTimeout(this.pollHandle);
  }

  render() {
    const { render, modelsStore } = this.props;
    if (!modelsStore) {
      return null;
    }
    const { model, loading } = this.state;
    return render({ model, modelsStore, loading });
  }

  _refreshModel() {
    window.clearTimeout(this.pollHandle);

    const { id, modelType, modelsStore, pollSeconds } = this.props;
    if (!modelsStore) {
      return;
    }
    if (id === undefined || id === null) {
      return;
    }
    modelsStore
      .fetch(modelType.type.toString(), id, this.fetchOptions)
      .then(
        action("setProvidedStore", (response: Response<IJsonapiModel>) => {
          this.setState({ model: response.data as T, loading: false });
        })
      )
      .catch(() => {
        action(() => {
          this.setState({ model: undefined, loading: false });
        });
      })
      .then(() => {
        if (!pollSeconds) {
          return;
        }
        this.pollHandle = window.setTimeout(() => this._refreshModel(), 1000 * pollSeconds);
      });
  }
}
