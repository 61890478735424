import * as React from "react";
import { Box, Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import { MotorStatsList_node_Site_motors } from "generated-gql-types/MotorStatsList";
import { UNAVAILABLE_LABEL } from "../../constants";
import { Typography } from "@material-ui/core";
import { Colors } from "sigil";
import { BOXED_CARD_STYLE, SOFT_BOX_SHADOW } from "modules/site-manager/utils/cardStyles";

const styles = { "& td, & th": { border: 0, padding: `2px`, color: Colors.slate } };

export type MotorSummaryTableProps = {
  supervisorId?: string;
  motorNode?: MotorStatsList_node_Site_motors;
};

const TOOLTIP_CONTENT =
  "This value requires the latest firmware. \nContact TT support for assistance.";
const UnavailableLabel = () => <Box title={TOOLTIP_CONTENT}>{UNAVAILABLE_LABEL}</Box>;

export const MotorSummarySingleTable = (props: MotorSummaryTableProps) => {
  const motorNode = props.motorNode || ({} as MotorStatsList_node_Site_motors);
  const telemetry = motorNode?.telemetry?.nodes[0];

  return (
    <Grid item container xs={12} sx={SOFT_BOX_SHADOW} ml={1} mr={1}>
      <Grid item container xs={12} sx={BOXED_CARD_STYLE} p={2}>
        <Table size="small" sx={styles}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography component="span" variant="caption">
                  Serial #
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  {motorNode?.driveSerialNumber}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="span" variant="caption">
                  ID
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  {motorNode?.modbusAddress}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="span" variant="caption">
                  Firmware
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  {motorNode?.firmware?.currentVersion || "- –"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="span" variant="caption">
                  Supervisor
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  {props.supervisorId || "N/A"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="span" variant="caption">
                  IP Address
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  {telemetry?.ipAddress || <UnavailableLabel />}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="span" variant="caption">
                  MC Model
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  {motorNode?.driveModel || UNAVAILABLE_LABEL}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="span" variant="caption">
                  Motor Model
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  {motorNode?.motorModel || UNAVAILABLE_LABEL}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="span" variant="caption">
                  Flow
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  {motorNode?.logicFlow?.currentVersion || UNAVAILABLE_LABEL}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="span" variant="caption">
                  Last Seen
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  {telemetry?.recordedAt ? moment(telemetry.recordedAt).fromNow() : "Over 6h Ago"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography component="span" variant="caption">
                  RSSI
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  {telemetry?.wifiRssi ? `${telemetry?.wifiRssi} dBm` : <UnavailableLabel />}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export const MotorSummaryTwinTables = (props: MotorSummaryTableProps) => {
  const motorNode = props.motorNode || ({} as MotorStatsList_node_Site_motors);
  const telemetry = motorNode?.telemetry?.nodes[0];

  return (
    <Grid item container xs={12} sx={SOFT_BOX_SHADOW} ml={1} mr={1}>
      <Grid item container xs={12} sx={BOXED_CARD_STYLE} p={2}>
        <Grid item xs>
          <Table size="small" sx={styles}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography component="span" variant="caption">
                    Serial #
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component="span" variant="body2">
                    {motorNode?.driveSerialNumber}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography component="span" variant="caption">
                    ID
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component="span" variant="body2">
                    {motorNode?.modbusAddress}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography component="span" variant="caption">
                    Firmware
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component="span" variant="body2">
                    {motorNode?.firmware?.currentVersion || "- –"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography component="span" variant="caption">
                    Supervisor
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component="span" variant="body2">
                    {props.supervisorId || "N/A"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography component="span" variant="caption">
                    IP Address
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component="span" variant="body2">
                    {telemetry?.ipAddress || <UnavailableLabel />}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs>
          <Table size="small" sx={styles}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography component="span" variant="caption">
                    MC Model
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component="span" variant="body2">
                    {motorNode?.driveModel || UNAVAILABLE_LABEL}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography component="span" variant="caption">
                    Motor Model
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component="span" variant="body2">
                    {motorNode?.motorModel || UNAVAILABLE_LABEL}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography component="span" variant="caption">
                    Flow
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component="span" variant="body2">
                    {motorNode?.logicFlow?.currentVersion || UNAVAILABLE_LABEL}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography component="span" variant="caption">
                    Last Seen
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component="span" variant="body2">
                    {telemetry?.recordedAt ? moment(telemetry.recordedAt).fromNow() : "Over 6h Ago"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography component="span" variant="caption">
                    RSSI
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component="span" variant="body2">
                    {telemetry?.wifiRssi ? `${telemetry?.wifiRssi} dBm` : <UnavailableLabel />}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Grid>
  );
};
