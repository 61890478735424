import React from "react";
import { FormControl, Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { labelFontStyle } from "global-styles";
import { gql } from "@apollo/client";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import {
  MotorSelectionCard_site,
  MotorSelectionCard_site_baselineMotors,
  MotorSelectionCard_site_motors,
} from "generated-gql-types/MotorSelectionCard_site";
import { MotorSetupDialog } from "../MotorSetup";
import { MotorSetupDialog_site } from "generated-gql-types/MotorSetupDialog_site";
import { POWER_OPTIONS } from "../MotorSetup/constants";
import moment from "moment";
import { Colors } from "sigil/src";

interface MotorSelectionCardProps {
  site?: MotorSelectionCard_site;
  selectedMotorId: string;
  onChange: (event: SelectChangeEvent<any>, child: React.ReactNode) => void;
}

export const findBaselineMotor = (site: MotorSelectionCard_site, motorId: string) => {
  return site.baselineMotors?.find((baselineMotor: any) => baselineMotor.motor.id === motorId);
};

const UNCONFIGURED_MOTOR_LABEL = "Not Set Up";
const getPowerHint = (baselineMotor?: MotorSelectionCard_site_baselineMotors) => {
  if (baselineMotor) {
    const powerOption = POWER_OPTIONS.find((option) => option.value === baselineMotor?.motorPower);
    if (powerOption) {
      return powerOption.label;
    }
  }
  return UNCONFIGURED_MOTOR_LABEL;
};

const sortInstalledThenAlpha = (a: any, b: any) => {
  if (!!a.installDate !== !!b.installDate) {
    return a.installDate && !b.installDate ? -1 : 1;
  }
  return a.name.localeCompare(b.name);
};

const MotorSelectionComponent = ({ site, selectedMotorId, onChange }: MotorSelectionCardProps) => {
  const options = site?.motors
    .map((motor: MotorSelectionCard_site_motors) => {
      const baselineMotor = findBaselineMotor(site, motor.id);
      const powerHint = getPowerHint(baselineMotor);
      return {
        value: motor.id,
        name: motor.displayName || motor.name || motor.id,
        power: powerHint,
        installDate:
          powerHint === UNCONFIGURED_MOTOR_LABEL
            ? ""
            : moment(baselineMotor?.replacedAt || motor.createdAt).format("MM/DD/YY"),
      };
    })
    .sort(sortInstalledThenAlpha);
  return (
    <Grid
      container
      justifyItems="space-between"
      justifyContent={"space-between"}
      alignItems="center"
      direction="row"
      m={0}
    >
      <Grid item xs={12} md={true} alignContent="center">
        <FormControl fullWidth>
          <Select
            aria-label="Motor name"
            key="Motor selection"
            value={
              options?.find((option) => option.value === selectedMotorId) ? selectedMotorId : ""
            }
            onChange={onChange}
            size="small"
            sx={{ ...labelFontStyle, margin: 0 }}
            autoFocus={false}
            label={"Motor selection"}
            notched={false}
            MenuProps={{
              PaperProps: {
                sx: {
                  "& .MuiMenuItem-root": {
                    ...labelFontStyle,
                  },
                },
              },
            }}
          >
            {options?.map((option: any) => (
              <MenuItem
                key={option.value}
                value={option.value || ""}
                selected={option.value === (selectedMotorId || 0)}
              >
                <Grid
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  container
                  color={option.installDate ? Colors.onyx : Colors.ash}
                >
                  <Grid item sm={6} xs={12}>
                    {option.name}
                  </Grid>
                  <Grid item sm={2} xs={6}>
                    {option.power}
                  </Grid>
                  <Grid item sm={4} xs={6}>
                    {option.installDate && `Installed:  ${option.installDate}`}
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs="auto" pt={1} pb={1} pl={2} alignContent="flex-end" textAlign={"right"}>
        {site && <MotorSetupDialog site={site as MotorSetupDialog_site} />}
      </Grid>
    </Grid>
  );
};

export const MotorSelectionCard = createFragmentContainer(MotorSelectionComponent, {
  site: gql`
    fragment MotorSelectionCard_site on Site {
      id
      motors {
        id
        name
        displayName
      }
      baselineMotors {
        id
        motorPower
        motor {
          id
          createdAt
        }
        replacedAt
      }
      ...MotorSetupDialog_site
    }
    ${MotorSetupDialog.fragments.site}
  `,
});
