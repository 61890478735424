import * as React from "react";
import { Grid } from "@mui/material";
import { Motor } from "modules/site-manager/models";
import { MotorOperationSection } from "./MotorOperationSection";
import { MotorSummarySection } from "./MotorSummarySection";
import { MotorIOSection } from "./MotorIOSection";
import { MotorStatusReport } from "./MotorStatusReport";
import { MotorStatsList_node_Site_motors } from "generated-gql-types/MotorStatsList";

type MotorDetailPaneProps = {
  motor: Motor;
  motorNode?: MotorStatsList_node_Site_motors;
};

export function MotorDetailsPane(props: MotorDetailPaneProps) {
  return (
    <Grid
      item
      container
      direction="column"
      spacing={2}
      xs={12}
      justifyContent="stretch"
      justifyItems="stretch"
    >
      <Grid item={true} xs={12} justifyContent="stretch">
        <MotorSummarySection
          supervisorId={props.motor?.controller?.id}
          motorNode={props.motorNode}
        />
      </Grid>
      <Grid item={true} xs={12} justifyContent="stretch">
        <MotorStatusReport motor={props.motor} />
      </Grid>
      <Grid item={true} xs={12} justifyContent="stretch">
        <MotorIOSection motorNode={props.motorNode} />
      </Grid>
      <Grid item={true} xs={12} justifyContent="stretch">
        <MotorOperationSection motor={props.motor} />
      </Grid>
    </Grid>
  );
}
