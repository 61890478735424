import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Grid, useMediaQuery } from "@mui/material";
import { Container } from "../../../components/Container";
import { SiteStore } from "modules/site-manager/stores";
import { MotorSavingsSection } from "./MotorSavings";
import { SiteSavingsSection } from "./SiteSavings/SiteSavingsSection";
import { useTheme } from "@mui/material/styles";

interface SiteEnergySavingsRouteProps {
  siteStore: SiteStore;
}

type Props = SiteEnergySavingsRouteProps & RouteComponentProps;

export const SiteEnergySavingsRoute = ({ siteStore }: Props) => {
  const siteId = siteStore.site.globalId;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container paddingBottom={isMobile ? "80px" : "8px"}>
      <Grid item container xs={12} spacing={2}>
        <SiteSavingsSection siteId={siteId} />
        <MotorSavingsSection siteId={siteId} siteStore={siteStore} />
      </Grid>
    </Container>
  );
};
