export const digitalValueBinaryStr = (digitalValues?: number) => {
  if (digitalValues) {
    let binaryStr = digitalValues.toString(2);
    const missingBits = 16 - binaryStr.length;
    if (missingBits > 0) {
      binaryStr = "0".repeat(missingBits) + binaryStr;
    }
    return binaryStr;
  } else {
    return "1".repeat(16);
  }
};

// Digital out is first 4 values of the binary string
// 0 values is the "ON" state
export const digitalOut = (digitalValues?: number) => {
  return digitalValueBinaryStr(digitalValues)
    .split("")
    .slice(0, 4)
    .map((v) => v === "0");
};

// Digital in is the last 7 values of the binary string in reverse order
// 0 values is the "ON" state
export const digitalIn = (digitalValues?: number) => {
  return digitalValueBinaryStr(digitalValues)
    .split("")
    .reverse()
    .slice(0, 7)
    .map((v) => v === "0");
};
