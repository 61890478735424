import { Theme } from "@mui/material/styles";

// MUI 5 version of tableStyles from Tycho.
export const tableStyles = (theme: Theme) => ({
  table: {
    fontFamily: "Barlow",
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: "#F0F0F0",
    },
    "&.projects": {
      "& .MuiDataGrid-row.Mui-odd": {
        backgroundColor: "white",
      },
    },
    "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus":
      {
        outline: "none !important",
      },
    ".MuiDataGrid-columnHeaderTitle": {
      paddingBottom: "2px",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      mr: 0,
      pr: 0,
      textOverflow: "clip",
      lineHeight: 1,
      whiteSpace: "normal",
    },
    ".MuiDataGrid-iconButtonContainer": {
      ml: 0,
      pl: 0,
    },
    ".MuiBox-root": {
      mr: 0,
      pr: 0,
    },
    ".MuiTablePagination-root p": {
      margin: 0,
      fontFamily: "Barlow",
    },
    ".MuiTablePagination-root div": {
      marginTop: "1px",
      fontFamily: "Barlow",
    },
    ".MuiDataGrid-toolbarContainer": {
      marginLeft: "8px",
      paddingTop: "8px",
    },
  },
  container: {
    border: "black 1px solid",
  },
  button: {
    borderRadius: "2px",
    fontSize: "14px",
    "&.MuiButton-contained": {
      color: "#FFFFFF",
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
      padding: "8px 24px",
      border: "1px solid transparent",
      textTransform: "uppercase",
    },
    "&.MuiButton-outlined": {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: "#FFFFFF",
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
    },
    "&.MuiButton-root:hover": {
      background: "transparent",
    },
    "&.MuiButton-text": {
      padding: 0,
    },
  },
  dataTableHeader: {
    "& > .MuiDataGrid-columnSeparator": {
      minHeight: 0,
    },
  },
});
