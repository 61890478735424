import React, { useState } from "react";
import { gql } from "@apollo/client";
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { SiteInputsForm } from "./SiteInputsForm";
import { useUpdateSiteMutation } from "modules/common/mutations/UpdateSiteInputMutation";
import { useSnackbar } from "notistack";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { useIsMounted } from "modules/common/hooks";
import { SiteSetupDialog_site } from "generated-gql-types/SiteSetupDialog_site";
import { isSiteEqual, isSiteSetup } from "./utils";
import {
  useUpdateUtilityRateMutation,
  useCreateUtilityRateMutation,
} from "modules/common/mutations/UtilityRateMutation";

export const SITE_SETUP_BUTTON_LABEL = "SITE SETUP";
export const SITE_SETUP_PROMPT =
  "Enter site details to calculate Lifetime Energy Savings from Turntide install date";

export const SiteSetupDialogComponent = ({ site }: { site: SiteSetupDialog_site }) => {
  const [open, setOpen] = useState(!isSiteSetup(site));
  const [siteSaveEnabled, setSiteSaveEnabled] = useState(false);
  const [formSite, setFormSite] = useState<SiteSetupDialog_site | undefined>(undefined);
  const updateSite = useUpdateSiteMutation();
  const updateUtilityRateMutation = useUpdateUtilityRateMutation();
  const createUtilityRateMutation = useCreateUtilityRateMutation();
  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useIsMounted();

  const onSave = async () => {
    if (!isSiteEqual(formSite, site)) {
      // Trigger mutation
      const promises: Promise<any>[] = [
        updateSite({
          siteId: site.id,
          peakDemandCharge: formSite?.peakDemandCharge,
          co2eGPerKwh: formSite?.co2eGPerKwh,
        }),
      ];
      if (!site.utilityRates?.length && formSite?.utilityRates?.length) {
        promises.push(
          createUtilityRateMutation({
            siteId: site.id,
            rate: formSite.utilityRates[0].rate,
          })
        );
      } else if (
        site.utilityRates?.length &&
        formSite?.utilityRates?.length &&
        site.utilityRates[0].rate !== formSite.utilityRates[0].rate
      ) {
        promises.push(
          updateUtilityRateMutation({
            ...formSite?.utilityRates[0],
          })
        );
      }
      Promise.all(promises)
        .then(() => {
          if (isMounted.current) {
            enqueueSnackbar(`Saved site setup`, { variant: "success" });
            setSiteSaveEnabled(false);
            setOpen(false);
          }
        })
        .catch((e: any) =>
          enqueueSnackbar(`Error saving site setup: ${e.message || e}`, {
            variant: "error",
          })
        );
    }
  };

  return (
    <>
      {site && (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
          <DialogTitle>Site Setup</DialogTitle>
          <DialogContent>
            <Box ml={1} mb={2}>
              {SITE_SETUP_PROMPT}
            </Box>
            <SiteInputsForm
              site={site}
              onChange={(updatedSite: SiteSetupDialog_site) => {
                setFormSite(updatedSite);
                setSiteSaveEnabled(true);
              }}
              disabled={!site?.viewerIsAdmin}
            />
          </DialogContent>
          <Grid
            container
            direction="row"
            pr={2}
            pb={2}
            textAlign="right"
            width="100%"
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => onSave()}
              disabled={!siteSaveEnabled}
              sx={{ width: 164, marginBottom: 1 }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              onClick={() => setOpen(false)}
              disabled={false}
              sx={{ width: 164, marginLeft: "8px", marginBottom: 1 }}
            >
              Cancel
            </Button>
          </Grid>
        </Dialog>
      )}
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={() => setOpen(true)}
        title={SITE_SETUP_PROMPT}
      >
        {SITE_SETUP_BUTTON_LABEL}
      </Button>
    </>
  );
};

export const SiteSetupDialog = createFragmentContainer(SiteSetupDialogComponent, {
  site: gql`
    fragment SiteSetupDialog_site on Site {
      id
      viewerIsAdmin
      utilityRates {
        id
        startDate
        rate
      }
      peakDemandCharge
      co2eGPerKwh
    }
  `,
});
