import { gql } from "@apollo/client";
import { composeMutationHook } from "./helpers";
import { DeleteNotificationChannel } from "generated-gql-types/DeleteNotificationChannel";
import { DeleteNotificationChannelInput } from "generated-gql-types/globalTypes";
import { getChannelsQuery } from "./CreateNotificationChannelMutation";

const deleteMutationQuery = gql`
  mutation DeleteNotificationChannel($input: DeleteNotificationChannelInput!) {
    deleteNotificationChannel(input: $input) {
      id
    }
  }
`;

export const useDeleteNotificationChannelMutation = composeMutationHook<
  DeleteNotificationChannel,
  DeleteNotificationChannelInput
>(deleteMutationQuery, {
  refetchQueries: [{ query: getChannelsQuery }],
});

export default useDeleteNotificationChannelMutation;
