import { gql } from "@apollo/client";

export const MOTOR_GROUP_HEALTH_DIAGNOSTIC_QUERY = gql`
  query MotorGroupHealthDiagnostic($motorGroupId: ID!) {
    motorGroup: node(id: $motorGroupId) {
      ... on MotorGroup {
        id
        motors {
          ... on Motor {
            id
            healthDiagnostics
          }
        }
      }
    }
  }
`;
