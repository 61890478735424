import * as React from "react";
import { gql } from "@apollo/client";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { Button, Colors, Typography } from "sigil";
import moment from "moment";
import { useSnackbar } from "notistack";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import {
  ListSharesPane_organization,
  ListSharesPane_organization_inboundShares,
  ListSharesPane_organization_outboundShares,
} from "generated-gql-types/ListSharesPane_organization";
import { useRemoveInboundSiteShareMutation } from "./mutations";
import { Grid } from "@mui/material";

const ClickyTableRow = styled(TableRow)({
  "&:hover": {
    cursor: "pointer",
  },
});

const TableHeadCell = styled(TableCell)({
  padding: "4px 16px 4px 16px",
});

function NoShares(props: { children: React.ReactNode }) {
  return (
    <Box borderTop={`1px solid ${Colors.salt}`} borderBottom={`1px solid ${Colors.salt}`} py={5}>
      <Typography variant="description-md" color="ash" align="center">
        {props.children}
      </Typography>
    </Box>
  );
}

function OutboundShareTable(props: {
  outboundShares: ListSharesPane_organization_outboundShares[];
  onEdit: (orgId: string) => void;
}) {
  if (props.outboundShares.length === 0) {
    return (
      <NoShares>
        You have not shared access to your sites with any other organizations yet.
      </NoShares>
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>
              <Typography variant="description-xs" color="ash">
                ORGANIZATION
              </Typography>
            </TableHeadCell>
            <TableHeadCell>
              <Typography variant="description-xs" color="ash">
                SITES
              </Typography>
            </TableHeadCell>
            <TableHeadCell>
              <Typography variant="description-xs" color="ash">
                ACCESS LEVEL
              </Typography>
            </TableHeadCell>
            <TableHeadCell>
              <Typography variant="description-xs" color="ash">
                SHARED SINCE
              </Typography>
            </TableHeadCell>
            <TableCell style={{ padding: "4px 16px 4px 16px" }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.outboundShares.map((share) => (
            <ClickyTableRow
              key={share.guestOrganization.id}
              hover
              onClick={() => props.onEdit(share.guestOrganization.id)}
            >
              <TableCell>
                <Typography variant="description-md" color="onyx">
                  {share.guestOrganization.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="description-md" color="pewter">
                  All Sites
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="description-md" color="pewter">
                  {share.asAdmin ? "Admin" : "Viewer"}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="description-md" color="pewter">
                  {moment(share.createdAt).format("MMM D, YYYY")}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography color="silver">
                  <ChevronRight color="inherit" />
                </Typography>
              </TableCell>
            </ClickyTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function InboundShareTable(props: {
  inboundShares: ListSharesPane_organization_inboundShares[];
  onRemove: (orgId: string) => void;
}) {
  if (props.inboundShares.length === 0) {
    return (
      <NoShares>
        You have not been granted access to sites from any other organization yet.
      </NoShares>
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>
              <Typography variant="description-xs" color="ash">
                ORGANIZATION
              </Typography>
            </TableHeadCell>
            <TableHeadCell>
              <Typography variant="description-xs" color="ash">
                ACCESS LEVEL
              </Typography>
            </TableHeadCell>
            <TableHeadCell>
              <Typography variant="description-xs" color="ash">
                SHARED SINCE
              </Typography>
            </TableHeadCell>
            <TableHeadCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.inboundShares.map((share) => (
            <TableRow key={share.organization.id}>
              <TableCell>
                <Typography variant="description-md" color="onyx">
                  {share.organization.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="description-md" color="pewter">
                  {share.asAdmin ? "Admin" : "Viewer"}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="description-md" color="pewter">
                  {moment(share.createdAt).format("MMM D, YYYY")}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="knockout"
                  color="dangeresque"
                  size="medium"
                  onClick={() => props.onRemove(share.organization.id)}
                >
                  Cancel access
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function Section(props: {
  title: string;
  subheader?: string;
  action?: React.ReactNode;
  children?: React.ReactNode;
}) {
  return (
    <Grid container component="section" pb={8} pr={2} justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={6} component="header" alignItems="center" p={2}>
        <Typography variant="title-xs">{props.title}</Typography>
        <Typography variant="description-sm" color="ash">
          {props.subheader}
        </Typography>
      </Grid>
      <Grid item xs={10} sm={6} textAlign="right">
        {props.action}
      </Grid>
      <Grid item xs={12}>
        {props.children}
      </Grid>
    </Grid>
  );
}

const ClearInput = styled("input")({
  border: "none",
  background: "transparent",
  "&:focus": {
    outline: "none",
  },
  width: "100%",
  textAlign: "inherit",
  fontSize: "22px",
  fontFamily: `"Fira Code", monospace`,
});

function ShareCode(props: { code: string }) {
  const formattedCode = props.code.replace(/(.{4})(.{4})/, "$1 - $2");
  return (
    <Box bgcolor={Colors.porcelain} borderRadius={2} padding={2} flex="1 1 50%">
      <Typography variant="code" align="center">
        <ClearInput readOnly value={formattedCode} onFocus={(e) => e.target.select()} />
      </Typography>
    </Box>
  );
}

function ListSharesPaneComponent(props: {
  organization?: ListSharesPane_organization;
  onAdd: () => void;
  onEdit: (orgId: string) => void;
}) {
  // skeleton state
  const organization = props.organization ?? {
    id: "",
    name: "",
    sharingCode: "",
    outboundShares: [],
    inboundShares: [],
  };

  const { enqueueSnackbar } = useSnackbar();
  const removeShare = useRemoveInboundSiteShareMutation();
  const handleReliquish = async (orgId: string) => {
    try {
      return await removeShare({
        // we are the guest
        guestOrganizationId: organization.id,
        organizationId: orgId,
      });
    } catch (_) {
      return enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  return (
    <div style={{ paddingBottom: 40 }}>
      <Section
        title="Sharing code"
        subheader="You will be asked to provide this code to another organization in order for them to share access to their sites with you."
        action={<ShareCode code={organization.sharingCode} />}
      />

      <Section
        title="Outbound - Shared by us"
        subheader={`${organization.name} is sharing access to their sites with these outside organizations`}
        action={
          <Button variant="solid" color="primary" onClick={props.onAdd}>
            Share&nbsp;site&nbsp;access
          </Button>
        }
      >
        <OutboundShareTable outboundShares={organization.outboundShares} onEdit={props.onEdit} />
      </Section>

      <Section
        title="Inbound - Shared with us"
        subheader={`These organizations are sharing access to their sites with ${organization.name}`}
      >
        <InboundShareTable inboundShares={organization.inboundShares} onRemove={handleReliquish} />
      </Section>
    </div>
  );
}

export const ListSharesPane = createFragmentContainer(ListSharesPaneComponent, {
  organization: gql`
    fragment ListSharesPane_organization on Organization {
      id
      name
      sharingCode
      outboundShares {
        guestOrganization {
          id
          name
        }
        asAdmin
        createdAt
      }
      inboundShares {
        organization {
          id
          name
        }
        asAdmin
        createdAt
      }
    }
  `,
});
