import * as React from "react";
import { Grid } from "@mui/material";

import { Health, HealthIssue } from "modules/common/enums";
import { Motor, MotorOpPoint } from "modules/site-manager/models";
import { HealthStatus } from "./HealthStatus";
import { useQuery } from "@apollo/client";
import {
  HealthDiagnostic,
  HealthDiagnostic_motor_Motor,
} from "generated-gql-types/HealthDiagnostic";
import { marshalStringId } from "modules/common/utils/relay";
import { HEALTH_DIAGNOSTIC_QUERY } from "modules/common/queries/GetMotorFaultConditions";

export function MotorStatusReport(props: { motor: Motor }) {
  const { motor } = props;
  const conditions = motor.healthConditions;
  const marshaledId = marshalStringId("motor", motor.id);
  const { data, loading } = useQuery<HealthDiagnostic>(HEALTH_DIAGNOSTIC_QUERY, {
    variables: { motorId: marshaledId },
  });

  if (loading) {
    return null;
  }

  const motorData = data && (data.motor as HealthDiagnostic_motor_Motor);
  const errorMessages = motorData && motorData.healthDiagnostics;
  const hasErrors = conditions.filter((c) => c.health === Health.ERROR).length > 0;
  if (conditions.length === 0 && errorMessages?.length === 0) {
    return (
      <Grid pl={1} pr={1}>
        <HealthStatus health={Health.HEALTHY} message="Operating normally" />
      </Grid>
    );
  }

  const latestMotorOpPoint = motor.latestMotorOpPointJS || ({} as MotorOpPoint);
  const absoluteDiff = latestMotorOpPoint.absoluteDiff || 0;
  const commLostDurationHuman = Motor.commLostDuration.humanize();
  let errorConditions = [];

  if (errorMessages && errorMessages.length > 0) {
    errorMessages.map((e) => errorConditions.push(e));
  } else if (hasErrors) {
    errorConditions.push("Experiencing critical issues. Connect via SMCUI for more details.");
  }

  const messages = {
    [HealthIssue.NO_DATA]: "Status unavailable",
    [HealthIssue.TOO_SLOW]: `Unable to reach requested speed: running ${absoluteDiff} rpm slow`,
    [HealthIssue.TOO_FAST]: `Running over speed: running ${absoluteDiff} rpm fast`,
    [HealthIssue.OLD_DATA]: `No communication received in the past ${commLostDurationHuman}`,
  };

  return (
    <Grid container={true} spacing={1} direction="column" pl={1}>
      {errorConditions.length > 0
        ? errorConditions.map((msg) => (
            <Grid item={true} key={msg}>
              <HealthStatus health={Health.ERROR} message={msg} />
            </Grid>
          ))
        : conditions
            .filter((c) => c.health !== Health.ERROR && c.health !== Health.WARNING)
            .map((condition) => (
              <Grid item={true} key={condition.issue}>
                <HealthStatus
                  health={condition.health}
                  message={messages[condition.issue] || "Unknown issue"}
                />
              </Grid>
            ))}
    </Grid>
  );
}
