import * as React from "react";
import { TooltipPayload, TooltipProps } from "recharts";
import { Card } from "@material-ui/core";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import { Series, SeriesCollection, SERIES_GROUP_NONE } from "modules/charts/series";
import { Payload } from "recharts/types/component/DefaultTooltipContent";

export type TooltipValue = string | number | Array<string | number>;
export type TimeseriesValueFormatter = (val: TooltipValue) => string;

const styles = () =>
  createStyles({
    card: {
      padding: "1.5em",
    },
  });

interface TimeseriesTooltipProps
  extends TooltipProps<TooltipValue, string>,
    WithStyles<typeof styles> {
  series: SeriesCollection;
}

class TimeseriesTooltipComponent extends React.Component<TimeseriesTooltipProps> {
  render() {
    const { label, payload, series, classes } = this.props;
    if (!payload || payload.length < 1) {
      return null;
    }
    const content = getContent(payload, series);
    const formattedLabel = formatLabel(label);

    return (
      <Card className={classes.card}>
        <h5>{formattedLabel}</h5>
        {content}
      </Card>
    );
  }
}

export const getContent = (payload: Payload<TooltipValue, string>[], series: SeriesCollection) => {
  return Object.keys(series.grouped).map((groupKey) =>
    seriesGroup(groupKey, series.grouped[groupKey], payload)
  );
};

export const formatLabel = (label: { toString: () => string | number | Date } | undefined) =>
  label ? new Date(label.toString()).toLocaleString() : "";

const seriesGroup = (group: string, seriesItems: Series[], payload: Readonly<TooltipPayload[]>) => {
  const dataPoints = seriesItems.map((s) => seriesGroupItem(s, payload));
  return (
    <div key={group} className="smc-ChartTooltip-group">
      {group === SERIES_GROUP_NONE ? null : (
        <span className={"smc-ChartTooltip-groupTitle"}>{group}</span>
      )}
      {dataPoints}
    </div>
  );
};

const seriesGroupItem = (seriesItem: Series, payload: Readonly<TooltipPayload[]>) => {
  const point = payload.find((p) => p.dataKey === seriesItem.getValue);
  if (!point) {
    return null;
  }

  return (
    <div
      key={seriesItem.id}
      style={{
        color: point.color,
      }}
    >
      <strong>{seriesItem.label}</strong>
      :&nbsp;
      {_formatValue(seriesItem, point.value)}
    </div>
  );
};

const _formatValue = (series: Series, value: Readonly<TooltipValue>) => {
  const { formatValue } = series;

  if (value !== null && typeof value !== "number") {
    return value;
  }

  return formatValue ? formatValue(value) : value;
};

export const TimeseriesTooltip = withStyles(styles)(TimeseriesTooltipComponent);
