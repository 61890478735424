export const CO2_REDUCTION = "Lifetime CO₂ Reduction";
export const CO2E = "CO₂e";
export const CO2E_LABEL = "CO₂e Grid Intensity";
export const CO2E_UNITS = "g/kWh";
export const DEMAND_RATE_LABEL = "Demand Rate";
export const DEMAND_RATE_UNITS = "$/kW";
export const KWH = "kWh";
export const UNAVAILABLE_LABEL = "[Unavailable]";
export const UNKNOWN_LABEL = "Unknown";
export const UTILITY_COST = "Utility Cost";
export const UTILITY_RATE_LABEL = "Utility Rate";
export const UTILITY_RATE_UNITS = "$/kWh";
