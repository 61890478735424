import React, { ReactNode } from "react";
import { Group } from "@vx/group";
import { AxisLeft, AxisBottom } from "@vx/axis";
import { LinePath, Line, AreaClosed } from "@vx/shape";
import { scaleTime, scaleLinear } from "@vx/scale";
import { BandRangeProp, Series, SeriesCollection } from "modules/charts/series";
import { TimeseriesLegend } from "modules/charts/components/TimeseriesLegend";
import { TimeseriesTooltipVx } from "./Tooltip";
import moment from "moment";
import { GridColumns, GridRows } from "@vx/grid";
import { WithStyles, withStyles, createStyles, styled } from "@material-ui/core/styles";
import { localPoint, touchPoint } from "@vx/event";
import { bisector } from "d3-array";
import { Motion, spring, PlainStyle } from "react-motion";
import { Delay } from "./Delay";
import { action } from "mobx";
import { observer } from "mobx-react";
import { Cloak } from "modules/common/components/Cloak";
import { Loading } from "modules/common/components/Loading";
import { extent, max, min } from "d3-array";
import { Colors } from "sigil";
import _ from "lodash";
import { ScaleLinear } from "d3-scale";

type ShowProvidedProps = {
  data: object[];
  isLoading?: boolean;
  series: SeriesCollection;
  bandRanges?: BandRangeProp[];
};

interface DataPoint {
  timestamp: Date;
  value: number;
}

const styles = () =>
  createStyles({
    root: {
      "& .vx-axis-left .vx-group.vx-axis-tick text": {
        transform: "translate(0px,1px)",
      },
      position: "relative",
      "& .text-sm": {
        fontSize: "12px",
        fontWeight: 500,
        fontFamily: "Barlow",
        fill: Colors.onyx,
      },
      "& .text-micro": {
        fontSize: "10px",
        fontWeight: 600,
        fontFamily: "Barlow",
        fill: Colors.ash,
      },
    },
  });

const aspectRatio = 0.25;
const width = 1152;
const height = width * aspectRatio;

const margin = { top: 10, left: 70, bottom: 30, right: 35 };

const Tooltip = styled("div")({
  position: "absolute",
  pointerEvents: "none",
});

const bisectDate = bisector((d: DataPoint) => new Date(d.timestamp)).left;

const pathYCache = {};

function findPathYAtX(x: number, path: SVGPathElement, name: number) {
  const key = `${name}-${x}`;
  if (key in pathYCache) {
    return pathYCache[key];
  }
  const error = 0.01;
  const maxIterations = 100;
  let lengthStart = 0;
  let lengthEnd = path.getTotalLength();
  let point = path.getPointAtLength((lengthEnd + lengthStart) / 2);
  let iterations = 0;
  while (x < point.x - error || x > point.x + error) {
    const midpoint = (lengthStart + lengthEnd) / 2;
    point = path.getPointAtLength(midpoint);
    if (x < point.x) {
      lengthEnd = midpoint;
    } else {
      lengthStart = midpoint;
    }
    iterations += 1;
    if (maxIterations < iterations) {
      break;
    }
  }
  pathYCache[key] = point.y;
  return pathYCache[key];
}

const getSeries = _.memoize(
  (visibleSeries: Series[], data: { timestamp: Date; value: number }[]) => {
    let transformed: DataPoint[][] = [];
    let i = 0;
    visibleSeries.forEach((s: Series) => {
      transformed[i] = [];
      data.forEach((d: { timestamp: Date; value: number }) => {
        transformed[i].push({ timestamp: new Date(d.timestamp), value: s.getValue(d) || 0 });
      });
      i++;
    });

    return transformed;
  }
);

@observer
class BasicTimeseriesChartComponent extends React.Component<
  WithStyles<typeof styles> & ShowProvidedProps
> {
  state = {
    tooltipOpen: false,
    tooltipLeft: 0,
    tooltipTop: 0,
    tooltipData: [],
    vertLineLeft: 0,
    pathRefs: {},
  };

  tooltipWidth = 0;
  tooltip = {} as HTMLDivElement;
  svg = {} as SVGSVGElement;
  xMax = 0;
  xScale: any;
  yMax = 0;
  yMin = 0;
  yScale = {} as ScaleLinear<number, number>;

  componentWillMount() {
    this.update();
  }

  componentWillReceiveProps(
    nextProps: Readonly<{ classes: Record<"root", string> } & ShowProvidedProps> &
      Readonly<{ children?: ReactNode }>
  ) {
    if (!_.isEqual(this.props, nextProps)) {
      this.update(nextProps);
    }
  }

  yScaleFormat = (d: number | { valueOf(): number }) => "";

  getXMax() {
    return width - margin.left - margin.right;
  }

  getYMax() {
    return width * aspectRatio - margin.top - margin.bottom;
  }

  getXScale = _.memoize((data: object[], x: any, xMax: number) => {
    const domain: any = extent(data, x);
    return scaleTime({
      domain: typeof domain[0] == "undefined" ? [0, 0] : domain,
      range: [0, xMax],
    });
  });

  getYScale = _.memoize(
    (data: { timestamp: Date; value: number }[], y: (d: DataPoint) => number, yMax: number) => {
      const ymax = max(data, y);
      this.yMin = Number(min(data, y)) || 0;
      let domain = [];
      if (ymax === undefined) {
        domain = [0, 0];
      } else if (this.yMin < 0) {
        domain = [this.yMin, Number(ymax)];
      } else {
        domain = [0, Number(ymax)];
      }
      return scaleLinear({
        domain: domain,
        range: [yMax, 0],
      });
    }
  );

  x = (d: DataPoint) => {
    return d.timestamp;
  };

  y = (d: DataPoint) => {
    return d.value;
  };

  update(props = this.props) {
    this.xMax = this.getXMax();
    this.yMax = this.getYMax();
    const d = props.data as { timestamp: Date; value: number }[];
    const derivedSeries = getSeries(props.series.visible, d);
    const allData = derivedSeries.reduce((acc, arr) => acc.concat(arr), []);
    this.xScale = this.getXScale(allData, this.x, this.xMax);
    this.yScale = this.getYScale(allData, this.y, this.yMax);

    this.yScaleFormat = this.yScale.tickFormat(3, "0");
  }

  setSvgRef = (ref: SVGSVGElement) => {
    this.svg = ref;
  };

  setTooltipRef = (ref: HTMLDivElement) => {
    this.tooltip = ref;

    if (this.tooltip) {
      this.tooltipWidth = ref.getBoundingClientRect().width;
    }
  };

  closeTooltip = () => {
    this.setState({
      tooltipOpen: false,
    });
  };

  showTooltipAt = (x: number, y: number) => {
    const xMax = this.getXMax();
    const yMax = this.getYMax();

    const positionX = x - margin.left;
    const positionY = y - margin.top;
    if (
      positionX < 0 ||
      positionX > xMax ||
      positionY < 0 ||
      positionY > yMax ||
      this.props.series.visible.length === 0 ||
      !this.props.data?.length
    ) {
      this.closeTooltip();
      return;
    }
    this.tooltipWidth = this.tooltip.getBoundingClientRect().width;
    const d = this.props.data as { timestamp: Date; value: number }[];
    const derivedSeries = getSeries(this.props.series.visible, d);
    const dataPoints = derivedSeries.map((d: DataPoint[]) => {
      const xDomain = this.xScale.invert(x - margin.left);
      const index = bisectDate(d, xDomain, 1);
      const dLeft = d[index - 1];
      const dRight = d[index];
      const d1: Date = new Date(dLeft.timestamp);
      const d2: Date = new Date(dRight.timestamp);
      const isRightCloser = xDomain - d1.valueOf() > d2.valueOf() - xDomain;
      return isRightCloser ? dRight : dLeft;
    });

    const xOffset = 18;
    const yOffset = 18;

    const positionXWithOffset = positionX + xOffset;
    const pastRightSide = positionXWithOffset + this.tooltipWidth > xMax;
    const tooltipLeft = pastRightSide
      ? positionX - this.tooltipWidth - xOffset
      : positionXWithOffset;
    const tooltipTop = positionY - yOffset;
    this.setState({
      tooltipOpen: true,
      tooltipData: dataPoints,
      tooltipLeft,
      tooltipTop,
      vertLineLeft: dataPoints.length > 0 ? this.xScale(new Date(dataPoints[0].timestamp)) : 0,
    });
  };

  mouseLeave = (event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
    this.closeTooltip();
  };

  touchMove = (event: React.TouchEvent<SVGRectElement>) => {
    const point = touchPoint(this.svg, event);
    if (point) {
      this.showTooltipAt(point.x, point.y);
    }
  };

  mouseMove = (event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
    const point = localPoint(this.svg, event);
    if (point) {
      this.showTooltipAt(point.x, point.y);
    }
  };

  setPathRef = (ref: SVGPathElement | null) => {
    if (!ref) {
      return;
    }
    const pRefs = this.state.pathRefs;
    const p = ref.getAttribute("data-index") || "";
    pRefs[p] = ref;

    this.setState({ pathRefs: pRefs });
  };

  render() {
    const { data, isLoading, series, classes, bandRanges } = this.props;
    const d = data as { timestamp: Date; value: number }[];
    const derivedSeries = getSeries(series.visible, d);

    const colors = series.visible.map((s) => s.color);
    const getPathYFromX = (index: number, x: number) => {
      const path = this.state.pathRefs[index];
      return findPathYAtX(x, path, index);
    };
    const allData = derivedSeries.reduce((acc, arr) => acc.concat(arr), []);
    const maxYValue = max(allData, this.y);
    const derivedRanges = bandRanges?.map((r) => [
      { timestamp: r.startTime, value: maxYValue || 0 },
      { timestamp: r.endTime, value: maxYValue || 0 },
    ]);

    const tickLabelClasses = ["text-sm", "text-micro"];
    const { tooltipOpen, tooltipLeft, tooltipData, tooltipTop, vertLineLeft } = this.state;

    function formatXAxis(tickItem: Date) {
      const d = moment(tickItem);
      return d.format("MM/DD/YYYY") + "|" + d.format("hh:mm:ss A");
    }

    return (
      <div className={classes.root}>
        {isLoading ? (
          <Cloak>
            <Loading />
          </Cloak>
        ) : null}

        <TimeseriesLegend
          series={series}
          onClick={action((s: Series) => (s.visible = !s.visible))}
        />

        <svg width={width} height={height + 50} ref={this.setSvgRef}>
          <rect x={0} y={0} width={width} height={height} fill="white" />
          <GridRows
            top={margin.top}
            left={margin.left}
            scale={this.yScale}
            numTicks={3}
            strokeDasharray={"2 5"}
            width={this.xMax}
            stroke={Colors.silver}
          />
          <GridColumns
            top={margin.top}
            left={margin.left}
            height={this.yMax}
            scale={this.xScale}
            strokeDasharray={"2 5"}
            width={this.xMax}
            stroke={Colors.silver}
            numTicks={6}
          />
          <Group top={margin.top} left={margin.left}>
            <Motion
              defaultStyle={{ left: 0, opacity: 0 }}
              style={{ left: spring(vertLineLeft || 0), opacity: spring(tooltipOpen ? 0.12 : 0) }}
            >
              {(style) => (
                <Line
                  from={{ x: style.left, y: 0 }}
                  to={{ x: style.left, y: this.yMax }}
                  stroke="black"
                  opacity={style.opacity}
                />
              )}
            </Motion>
            {derivedSeries.map((lineData: DataPoint[], i: number) => (
              <LinePath
                data={lineData}
                key={i}
                data-index={i}
                x={(d: DataPoint) => this.xScale(this.x(d))}
                y={(d) => this.yScale(this.y(d))}
                strokeWidth={1.5}
                strokeLinecap="round"
                stroke={colors[i]}
                shapeRendering="geometricPrecision"
                innerRef={this.setPathRef}
              />
            ))}
            <Motion
              defaultStyle={{ opacity: 0, x: vertLineLeft }}
              style={{
                opacity: spring(tooltipOpen ? 1 : 0),
                x: spring(vertLineLeft),
              }}
            >
              {(style: PlainStyle) =>
                tooltipData && (
                  <g>
                    {tooltipData.map((d, i) => {
                      const y = getPathYFromX(i, style.x);
                      return (
                        <g key={i}>
                          <circle
                            cx={style.x}
                            cy={y}
                            r={3}
                            fill={colors[i]}
                            stroke={"white"}
                            strokeWidth="1"
                            fillOpacity={style.opacity}
                            strokeOpacity={style.opacity}
                          />
                        </g>
                      );
                    })}
                  </g>
                )
              }
            </Motion>
            <rect
              x="0"
              y="0"
              width={this.xMax}
              height={this.yMax}
              fill="transparent"
              onMouseLeave={this.mouseLeave}
              onMouseMove={this.mouseMove}
              onTouchMove={this.touchMove}
            />
            <Delay initial={0} value={this.xMax} period={300}>
              {(delayed) => (
                <Motion defaultStyle={{ x: 0 }} style={{ x: spring(delayed) }}>
                  {(style: PlainStyle) => (
                    <rect
                      x={style.x}
                      y="0"
                      width={Math.max(this.xMax - style.x, 0)}
                      height={this.yMax}
                      fill="white"
                    />
                  )}
                </Motion>
              )}
            </Delay>
            {derivedRanges?.map((lineData: any, i: number) => (
              <AreaClosed
                yScale={this.yScale}
                data={lineData}
                key={i}
                data-index={i}
                x={(d: DataPoint) => this.xScale(this.x(d))}
                y={(d) => this.yScale(this.y(d))}
                fill={Colors.rustLight}
                fillOpacity={0.2}
                pointerEvents={"none"}
              />
            ))}

            {/*Border for red bands*/}
            {series.visible.length > 0 &&
              derivedRanges?.map((lineData: any, i: number) =>
                lineData.map((l: any, j: number) => (
                  <Line
                    key={j}
                    from={{ x: this.xScale(this.x(l)), y: 0 }}
                    to={{ x: this.xScale(this.x(l)), y: this.yMax }}
                    strokeWidth={1}
                    stroke={Colors.rustLight}
                    strokeDasharray={"4 2"}
                    pointerEvents={"none"}
                  />
                ))
              )}
          </Group>
          <AxisLeft
            top={margin.top}
            left={margin.left}
            scale={this.yScale}
            stroke={Colors.silver}
            tickStroke={Colors.silver}
            numTicks={5}
            hideZero={this.yMin < 0 ? false : true}
          />
          <AxisBottom
            numTicks={6}
            top={height - margin.bottom}
            left={margin.left}
            scale={this.xScale}
            stroke={Colors.silver}
            tickFormat={formatXAxis}
            tickStroke={Colors.silver}
            tickComponent={({ x, y, formattedValue }) => (
              <g transform={`translate(${x},${y})`}>
                <text fontWeight={"normal"} x="0" y={8} textAnchor={"middle"}>
                  {formattedValue &&
                    formattedValue
                      .toString()
                      .split("|")
                      .map((v, i) => (
                        <tspan className={tickLabelClasses[i]} key={i} x="0" dx="5" dy={i * 18}>
                          {v}
                        </tspan>
                      ))}
                </text>
              </g>
            )}
          />
        </svg>
        <div
          style={{
            position: "absolute",
            top: margin.top,
            left: margin.left,
            width: this.xMax,
            height: this.yMax,
            pointerEvents: "none",
          }}
        >
          <Motion
            defaultStyle={{ left: tooltipLeft || 0, top: tooltipTop || 0, opacity: 0 }}
            style={{
              left: spring(tooltipLeft || 0),
              top: spring(tooltipTop || 0),
              opacity: spring(tooltipOpen ? 1 : 0),
            }}
          >
            {(style) => (
              <Tooltip
                ref={this.setTooltipRef}
                style={{
                  top: style.top,
                  left: style.left,
                  opacity: style.opacity,
                }}
              >
                <div>
                  <TimeseriesTooltipVx tooltipData={tooltipData} series={series} />
                </div>
              </Tooltip>
            )}
          </Motion>
        </div>
      </div>
    );
  }
}

export const BasicTimeseriesChartVx = withStyles(styles)(BasicTimeseriesChartComponent);
