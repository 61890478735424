import * as React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import { Divider, List, ListItem } from "@material-ui/core";
import { Grid } from "@mui/material";

interface GroupedListProps<T, G> {
  getItems: () => T[];
  getItemKey: (item: T) => string;
  formatItem: (item: T) => React.ReactNode;
  getItemSort: (item: T) => string;
  getGroupSort: (group: G) => string;
  getGroup: (item: T) => G | undefined;
  getGroupKey: (group: G) => string;
  formatGroup: (group: G) => React.ReactNode;
  isItemTest: (maybeItem: T | G) => maybeItem is T;
  onItemClick?: (item: T, e: React.MouseEvent<HTMLElement>) => void;
}

interface GroupedListState {
  selectedGroups: { [key: string]: boolean };
}

@observer
export class GroupedList<T, G> extends React.Component<GroupedListProps<T, G>> {
  state: GroupedListState = {
    selectedGroups: {},
  };

  handleItemClick = (item: T) => (e: React.MouseEvent<HTMLElement>) => {
    const { onItemClick } = this.props;
    if (!onItemClick) {
      return;
    }
    onItemClick(item, e);
  };

  toggleGroup = (group: G) => () => {
    const { selectedGroups } = this.state;
    const groupKey = this.props.getGroupKey(group);
    const selectedState = selectedGroups[groupKey];
    this.setState({
      selectedGroups: {
        ...selectedGroups,
        [groupKey]: !selectedState,
      },
    });
  };

  isGroupSelected = (group: G) => {
    const groupKey = this.props.getGroupKey(group);
    return !!this.state.selectedGroups[groupKey];
  };

  groupContent = (group: G, items: T[]) => {
    const { getGroupKey, formatGroup } = this.props;

    const groupKey = getGroupKey(group);
    const isSelected = this.isGroupSelected(group);
    const hasMultipleItems = items && items.length > 1;

    return hasMultipleItems ? (
      <React.Fragment key={groupKey}>
        <ListItem onClick={this.toggleGroup(group)} button={true}>
          <Grid container alignItems="center">
            <Grid item xs>
              {formatGroup(group)}
            </Grid>
            <Grid item xs="auto">
              <i
                className={classNames("fa", "mr-2", {
                  "fa-chevron-right": !isSelected,
                  "fa-chevron-down": isSelected,
                })}
              />
            </Grid>
          </Grid>
        </ListItem>

        {isSelected ? this.itemListContent(items) : null}
      </React.Fragment>
    ) : (
      this.itemContent(items[0], false)
    );
  };

  itemListContent = (items: T[] | undefined) => {
    if (!items || items.length <= 0) {
      return;
    }

    return <List dense={true}>{items.map((item) => this.itemContent(item, true))}</List>;
  };

  itemContent = (item: T, isNested: boolean) => {
    const { formatItem, getItemKey } = this.props;

    return (
      <ListItem key={getItemKey(item)} onClick={this.handleItemClick(item)} button={true}>
        <div
          className={classNames({
            "ml-4": isNested,
          })}
          style={{ width: "100%" }}
        >
          {formatItem(item)}
        </div>
      </ListItem>
    );
  };

  getKey = (itemOrGroup: T | G) => {
    const { isItemTest, getItemKey, getGroupKey } = this.props;
    return isItemTest(itemOrGroup) ? getItemKey(itemOrGroup) : getGroupKey(itemOrGroup);
  };

  getSortKey = (itemOrGroup: T | G) => {
    const { isItemTest, getItemSort, getGroupSort } = this.props;
    return isItemTest(itemOrGroup) ? getItemSort(itemOrGroup) : getGroupSort(itemOrGroup);
  };

  render() {
    const { getItems, getGroup, isItemTest } = this.props;

    const items = getItems();
    const groups: Map<G, T[]> = new Map();
    const ungrouped: T[] = [];

    items.forEach((item) => {
      const key = getGroup(item);
      if (key === undefined) {
        ungrouped.push(item);
        return;
      }
      const collection = groups.get(key);
      if (collection) {
        collection.push(item);
      } else {
        groups.set(key, [item]);
      }
    });

    const iterItems: (G | T)[] = [...Array.from(groups.keys()), ...ungrouped];

    iterItems.sort((a: G | T, b: G | T) => {
      const aKey = this.getSortKey(a);
      const bKey = this.getSortKey(b);
      return aKey.localeCompare(bKey);
    });

    return (
      <List dense={true}>
        <Divider />
        {iterItems.map((itemOrGroup) => (
          <React.Fragment key={this.getKey(itemOrGroup)}>
            {isItemTest(itemOrGroup)
              ? this.itemContent(itemOrGroup, false)
              : this.groupContent(itemOrGroup, groups.get(itemOrGroup) || [])}
            <Divider />
          </React.Fragment>
        ))}
      </List>
    );
  }
}
