import { useEffect, useState } from "react";
import { parseQuery, stringifyQuery } from "modules/common/utils/query-params";
import { useHistory, useLocation } from "react-router-dom";

// Preserves state, such as filters, in the URL query params to preserve it when navigating away and back.
// Each page should define a single object structure that includes all parameters needed to re-render the page.
// The page should call saveQuery to serialize that object into the parameters part of the current route.
// The useQueryParams hook will parse the query string when the page is re-rendered and pass the object structure to the given callback.
export function useQueryParams<T>(param: string, defaultValue: T) {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const history = useHistory();
  const [query, setQuery] = useState("");
  const [object, setObject] = useState<T>(defaultValue);
  const [queryParamsLoaded, setQueryParamsLoaded] = useState(false);

  // Load params from query string
  useEffect(() => {
    const stringValue = searchParams.get(param) || "";
    if (stringValue !== query) {
      setQuery(stringValue);
      const parsedQuery = parseQuery(stringValue);
      if (parsedQuery) {
        const jsonFilter = parsedQuery[param] as string;
        const filter = jsonFilter ? JSON.parse(jsonFilter) : defaultValue;
        setObject(filter);
      }
    }
    setQueryParamsLoaded(true);
  }, [param, query, searchParams, defaultValue]);

  // Write filter changes to query string
  const saveQuery = (param: string, value: T) => {
    if (value) {
      const stringValue = stringifyQuery({ [param]: JSON.stringify(value) });
      if (stringValue !== query) {
        setQuery(stringValue);
      }
      searchParams.set(param, stringValue);
      history.replace({ search: searchParams.toString() });
    }
  };
  return [object, queryParamsLoaded, saveQuery] as [T, boolean, (param: string, value: T) => void];
}
