import * as React from "react";
import { Dialog, DialogContent, Grid } from "@mui/material";
import { CloseButton } from "modules/common/components/CloseButton";

import { Motor } from "modules/site-manager/models";
import { MotorDetailsPane } from "modules/site-manager/components/MotorDetailsPane";
import { MotorStatsList_node_Site_motors } from "generated-gql-types/MotorStatsList";
import { EditableMotorTitle } from "../EditableMotorTitle";

type MotorDetailsDialogProps = {
  motor: Motor;
  motorNode?: MotorStatsList_node_Site_motors;
  open: boolean;
  onClose: () => void;
};

export function MotorDetailsDialog(props: MotorDetailsDialogProps) {
  return (
    <Dialog open={props.open} scroll="paper" maxWidth="lg" onClose={props.onClose}>
      <Grid container justifyContent="space-between" pl={2} pr={2} pt={2}>
        <Grid item xs pl={2} pr={1} pt={1}>
          <EditableMotorTitle
            motor={props.motor}
            motorNode={props.motorNode || ({} as MotorStatsList_node_Site_motors)}
          />
        </Grid>
        <Grid item xs="auto" fontSize="13px" color="text.disabled" m={0} p={0}>
          <CloseButton
            onClick={props.onClose}
            style={{ margin: 0, padding: 0, fontSize: "13px" }}
          />
        </Grid>
      </Grid>
      <DialogContent>
        <MotorDetailsPane motor={props.motor} motorNode={props.motorNode} />
      </DialogContent>
    </Dialog>
  );
}
