import * as React from "react";
import { gql } from "@apollo/client";
import { Typography } from "@material-ui/core";
import { OrgMenuItems_organizations } from "generated-gql-types/OrgMenuItems_organizations";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { ActionMenuItemLink, ActionMenuSubheader } from "modules/common/components/ActionMenu";
import { Link } from "react-router-dom";

function OrgMenuItems({ organizations }: { organizations: OrgMenuItems_organizations }) {
  // If we have any organizations, list them inline
  if (organizations.nodes.length > 0) {
    const lastIndex = organizations.nodes.length - 1;

    return (
      <React.Fragment>
        <ActionMenuSubheader>
          <Link key={"organizations"} to={`/settings/organizations/`}>
            <Typography variant="overline">My Organizations</Typography>
          </Link>
        </ActionMenuSubheader>

        {organizations.nodes.map((org, index) => (
          <ActionMenuItemLink
            key={org.id}
            to={`/organizations/${org.id}`}
            divider={index === lastIndex}
          >
            <Typography>{org.name}</Typography>
          </ActionMenuItemLink>
        ))}
      </React.Fragment>
    );
  }

  // If we have no organizations, suppress the list
  return null;
}

export default createFragmentContainer(OrgMenuItems, {
  organizations: gql`
    fragment OrgMenuItems_organizations on UserOrganizationsConnection {
      pageInfo {
        hasNextPage
      }
      nodes {
        id
        name
      }
    }
  `,
});
