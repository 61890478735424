import * as React from "react";
import { Priority, Priorities } from "modules/site-manager/models/ctrl-variable";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core/styles";

interface PriorityIconProps {
  priority: Priority;
}

const styles = ({ palette }: Theme) =>
  createStyles({
    root: {
      display: "inline-flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "center",
      width: "8px",
      height: "27px",
      marginLeft: "8px",
      marginRight: "8px",
      marginTop: "3px",
    },
    level: {
      width: "100%",
      height: "2px",
      backgroundColor: palette.secondaryVariant.main,
      marginBottom: "2px",
      "&:last-child": {
        margin: "none",
      },
    },
  });

const PriorityBarsComponent = (props: PriorityIconProps & WithStyles<typeof styles>) => {
  const { classes, priority } = props;
  const levels = surpassedLevels(priority);
  return (
    <div className={classes.root}>
      {levels.map((_, i) => (
        <div key={i} className={classes.level} />
      ))}
    </div>
  );
};

const surpassedLevels = (priority: number) => {
  return Priorities.filter((level) => priority >= level);
};

export const PriorityBars = withStyles(styles)(PriorityBarsComponent);
