import * as React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Grid } from "@mui/material";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { Button } from "sigil";
import { injectStores, ModelsStore } from "modules/common/stores";
import { rethrow } from "modules/common/utils/promise";
import { CtrlVariable, LogicVarOverrideCmd } from "modules/site-manager/models";
import { LogicVarOverrideModal } from "modules/site-manager/components/LogicVarOverrideModal";
import { CtrlVariableValue } from "modules/site-manager/components/CtrlVariableValue";
import { LogicValueContainer } from "modules/site-manager/utils/logic-value-container";
import { OverrideableCtrlVariableValueStyles } from "./styles";
import { ArrowTooltip } from "modules/common/components/ArrowTooltip";
import { action, observable } from "mobx";

interface OverrideableCtrlVariableValueProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    WithStyles<typeof OverrideableCtrlVariableValueStyles> {
  variable: CtrlVariable;
  unknownLabel: string;
  modelsStore?: ModelsStore;
  showUnits?: boolean;
  allowEdit?: boolean;
}

interface OverrideableCtrlVariableValueState {
  isOverriding: boolean;
}

@injectStores("models")
@observer
class OverrideableCtrlVariableValueComponent extends React.Component<
  OverrideableCtrlVariableValueProps,
  OverrideableCtrlVariableValueState
> {
  state = {
    isOverriding: false,
  };

  startOverriding = () => this.setState({ isOverriding: true });
  stopOverriding = () => this.setState({ isOverriding: false });

  @action
  createLogicVarOverride = (container: LogicValueContainer) => {
    const { variable, modelsStore } = this.props;
    if (!modelsStore) {
      return Promise.reject("No models store");
    }

    const overrideProps = {
      variable,
      floatValue: container.floatValue,
      booleanValue: container.booleanValue,
    };

    const override = modelsStore.add<LogicVarOverrideCmd>(overrideProps, LogicVarOverrideCmd.type);
    if (!variable.logicVarOverrideCmds) {
      variable.logicVarOverrideCmds = observable.array();
    }
    variable.logicVarOverrideCmds.push(override);
    return override
      .save()
      .then((o) => {
        this.stopOverriding();
        return o as LogicVarOverrideCmd;
      })
      .catch(rethrow(() => override.destroy()));
  };

  render() {
    const { variable, unknownLabel, showUnits, classes, allowEdit } = this.props;
    const { isOverriding } = this.state;

    const canEdit = variable.isEditable;

    return (
      <Grid container alignItems="center" maxWidth="120px">
        {/* Client setting affordance */}
        {variable.isSetByClient ? (
          <i
            className={classNames(
              "fa",
              "fa-spin",
              "fa-circle-notch",
              "mr-1",
              classes.overriddenIcon
            )}
          />
        ) : null}

        <Grid item xs="auto" style={{ marginRight: "auto" }}>
          <CtrlVariableValue
            variable={variable}
            fallback={unknownLabel}
            showUnits={showUnits}
            includeClientSetValue={true}
          />
        </Grid>

        {variable.isOverridden && (
          <ArrowTooltip title="This point is being manually overridden" placement="top">
            <span className={classes.fixedOverrideIcon}>*</span>
          </ArrowTooltip>
        )}

        {canEdit && allowEdit && (
          <EditButton size="small" variant="solid" color="plain" onClick={this.startOverriding}>
            change
          </EditButton>
        )}

        {canEdit && isOverriding ? (
          <LogicVarOverrideModal
            variable={variable}
            currentValue={variable.currentNumericValue}
            onClose={this.stopOverriding}
            onSave={this.createLogicVarOverride}
          />
        ) : null}
      </Grid>
    );
  }
}

const EditButton = withStyles({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    color: "#FDFDFD",
  },
})(Button);

export const OverrideableCtrlVariableValue = withStyles(OverrideableCtrlVariableValueStyles)(
  OverrideableCtrlVariableValueComponent
);
