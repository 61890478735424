import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { OrganizationDashboard } from "./OrganizationDashboard";

type OrganizationsRouteComponentProps = {
  org_id?: string;
};

// OrganizationsRouteComponent just passes an org ID (if provided in the route) to the dashboard.
function OrganizationsRouteComponent(
  props: RouteComponentProps<{ org_id: string }> & OrganizationsRouteComponentProps
) {
  const { match } = props;
  return <OrganizationDashboard orgId={match.params.org_id} />;
}

export function OrganizationsRoute() {
  return (
    <Switch>
      <Route path="/organizations/:org_id" component={OrganizationsRouteComponent} />
      <Route path="/organizations" component={OrganizationsRouteComponent} />
    </Switch>
  );
}
