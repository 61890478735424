import { computed } from "mobx";

import { BacnetUnit } from "modules/site-manager/constants";

const symbols: { [key: number]: string | undefined } = {
  [BacnetUnit.SquareMeters]: " m^2^",
  [BacnetUnit.SquareFeet]: " ft^2^",
  [BacnetUnit.Milliamperes]: " mA",
  [BacnetUnit.Amperes]: " A",
  [BacnetUnit.Ohms]: " Ω",
  [BacnetUnit.Volts]: " V",
  [BacnetUnit.Kilovolts]: " kV",
  [BacnetUnit.Megavolts]: " MV",
  [BacnetUnit.VoltAmperes]: " VA",
  [BacnetUnit.KilovoltAmperes]: " kVA",
  [BacnetUnit.MegavoltAmperes]: " MVA",
  [BacnetUnit.VoltAmperesReactive]: " VAR",
  [BacnetUnit.KilovoltAmperesReactive]: " kVAR",
  [BacnetUnit.MegavoltAmperesReactive]: " MVAR",
  [BacnetUnit.DegreesPhase]: "°",
  [BacnetUnit.PowerFactor]: "",
  [BacnetUnit.Joules]: " J",
  [BacnetUnit.Kilojoules]: " kJ",
  [BacnetUnit.WattHours]: " Wh",
  [BacnetUnit.KilowattHours]: " kWh",
  [BacnetUnit.Btus]: " Btu",
  [BacnetUnit.Therms]: " thm",
  [BacnetUnit.TonHours]: " ton-hours",
  [BacnetUnit.JoulesPerKilogramDryAir]: " J/Kg dry air",
  [BacnetUnit.BtusPerPoundDryAir]: " Btu/lb dry air",
  [BacnetUnit.CyclesPerHour]: " cycles/hr",
  [BacnetUnit.CyclesPerMinute]: " cycles/min",
  [BacnetUnit.Hertz]: " Hz",
  [BacnetUnit.GramsOfWaterPerKilogramDryAir]: " g H_2_O/kg dry air",
  [BacnetUnit.PercentRelativeHumidity]: "%",
  [BacnetUnit.Millimeters]: " mm",
  [BacnetUnit.Meters]: " mm",
  [BacnetUnit.Inches]: " in",
  [BacnetUnit.Feet]: " ft",
  [BacnetUnit.WattsPerSquareFoot]: " W/ft^2^",
  [BacnetUnit.WattsPerSquareMeter]: " W/m^2^",
  [BacnetUnit.Lumens]: " lm",
  [BacnetUnit.Luxes]: " lx",
  [BacnetUnit.FootCandles]: " fc",
  [BacnetUnit.Kilograms]: " kg",
  [BacnetUnit.PoundsMass]: " lb",
  [BacnetUnit.Tons]: " ton",
  [BacnetUnit.KilogramsPerSecond]: " kg/s",
  [BacnetUnit.KilogramsPerMinute]: " kg/min",
  [BacnetUnit.KilogramsPerHour]: " kg/h",
  [BacnetUnit.PoundsMassPerMinute]: " lb/min",
  [BacnetUnit.PoundsMassPerHour]: " lb/hr",
  [BacnetUnit.Watts]: " W",
  [BacnetUnit.Kilowatts]: " kW",
  [BacnetUnit.Megawatts]: " MW",
  [BacnetUnit.BtusPerHour]: " Btu/hr",
  [BacnetUnit.Horsepower]: " hp",
  [BacnetUnit.TonsRefrigeration]: " TR",
  [BacnetUnit.Pascals]: " Pa",
  [BacnetUnit.Kilopascals]: " kPa",
  [BacnetUnit.Bars]: " bar",
  [BacnetUnit.PoundsForcePerSquareInch]: " psi",
  [BacnetUnit.CentimetersOfWater]: " cmH_2_O",
  [BacnetUnit.InchesOfWater]: '" wc',
  [BacnetUnit.MillimetersOfMercury]: " mm Hg",
  [BacnetUnit.CentimetersOfMercury]: " cm Hg",
  [BacnetUnit.InchesOfMercury]: '" Hg',
  [BacnetUnit.DegreesCelsius]: " °C",
  [BacnetUnit.DegreesKelvin]: " K",
  [BacnetUnit.DegreesFahrenheit]: " °F",
  [BacnetUnit.DegreeDaysCelsius]: " days °C",
  [BacnetUnit.DegreeDaysFahrenheit]: " days °F",
  [BacnetUnit.Years]: " yr",
  [BacnetUnit.Months]: " mo",
  [BacnetUnit.Weeks]: " wk",
  [BacnetUnit.Days]: " days",
  [BacnetUnit.Hours]: " hr",
  [BacnetUnit.Minutes]: " min",
  [BacnetUnit.Seconds]: " s",
  [BacnetUnit.MetersPerSecond]: " m/s",
  [BacnetUnit.KilometersPerHour]: " km/h",
  [BacnetUnit.FeetPerSecond]: " ft/s",
  [BacnetUnit.FeetPerMinute]: " ft/min",
  [BacnetUnit.MilesPerHour]: " mph",
  [BacnetUnit.CubicFeet]: " ft^3^",
  [BacnetUnit.CubicMeters]: " m^3^",
  [BacnetUnit.ImperialGallons]: " Imp. gal",
  [BacnetUnit.Liters]: " l",
  [BacnetUnit.UsGallons]: " gal",
  [BacnetUnit.CubicFeetPerMinute]: " ft^3^/min",
  [BacnetUnit.CubicMetersPerSecond]: " m^3^/s",
  [BacnetUnit.ImperialGallonsPerMinute]: " Imp. gal/min",
  [BacnetUnit.LitersPerSecond]: " l/s",
  [BacnetUnit.LitersPerMinute]: " l/min",
  [BacnetUnit.UsGallonsPerMinute]: " gal/min",
  [BacnetUnit.DegreesAngular]: "'",
  [BacnetUnit.DegreesCelsiusPerHour]: " °C/hr",
  [BacnetUnit.DegreesCelsiusPerMinute]: " °C/min",
  [BacnetUnit.DegreesFahrenheitPerHour]: " °F/hr",
  [BacnetUnit.DegreesFahrenheitPerMinute]: " °F/min",
  [BacnetUnit.NoUnits]: undefined,
  [BacnetUnit.PartsPerMillion]: " ppm",
  [BacnetUnit.PartsPerBillion]: " ppb",
  [BacnetUnit.Percent]: "%",
  [BacnetUnit.PercentPerSecond]: "%/s",
  [BacnetUnit.PerMinute]: "/min",
  [BacnetUnit.PerSecond]: "/s",
  [BacnetUnit.PsiPerDegreeFahrenheit]: "psi/°F",
  [BacnetUnit.Radians]: " rad",
  [BacnetUnit.RevolutionsPerMinute]: " rpm",
  /*
   * Currency1 - Currency10 intentionally undefined
   */
  [BacnetUnit.SquareInches]: " in^2^",
  [BacnetUnit.SquareCentimeters]: " cm^2^",
  [BacnetUnit.BtusPerPound]: " btu/lb",
  [BacnetUnit.Centimeters]: " cm",
  [BacnetUnit.PoundsMassPerSecond]: " lb/s",
  [BacnetUnit.DeltaDegreesFahrenheit]: " Δ °F",
  [BacnetUnit.DeltaDegreesKelvin]: " Δ K",
  // TODO: Units 122 and above
};

export class BacnetUnitPresenter {
  constructor(private unit: BacnetUnit) {}

  @computed
  get symbolString() {
    return symbols[this.unit];
  }
}
