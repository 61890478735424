import * as React from "react";
import { InputBase, InputBaseProps, FormControl, Popper, Box } from "@material-ui/core";
import { useFormControl } from "@material-ui/core/FormControl";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteRenderInputParams,
} from "@material-ui/lab";
import {
  Value,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
} from "@material-ui/lab/useAutocomplete";
import { Elevation } from "../../utils/elevation";
import { Colors } from "../../utils/color";
import { ArrowDown, ArrowUp } from "../icons";
import { descriptionSmallStyles, DescriptionExtraSmall, DescriptionMicro } from "../typography";
import { useState, useRef, FocusEvent, ChangeEvent } from "react";
import classNames from "classnames";

// We're using custom components for Label and Helper, because Material UI's components muck about
// with css too much
const useLabelStyles = makeStyles(() =>
  createStyles({
    root: {
      marginBottom: "0", // our bootstrap css messes with this, remove once we get rid of it
    },
  })
);
const Label: React.FC<{ htmlFor?: string }> = ({ children, htmlFor }) => {
  const fc = useFormControl();
  const styles = useLabelStyles();
  return (
    <label className={classNames(styles.root)} htmlFor={htmlFor}>
      <DescriptionExtraSmall color="pewter">
        {children}{" "}
        {fc?.required && (
          <span aria-hidden style={{ color: Colors.bloodOrange }}>
            &thinsp;*
          </span>
        )}
      </DescriptionExtraSmall>
    </label>
  );
};

// There is an optional minHeight for this helper so that forms don't reflow when an input throws an
// error.
const useHelperStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingLeft: "2px",
      minHeight: ({ canError }: { canError?: boolean }) => (canError ? "14px" : undefined),
    },
  })
);
const Helper: React.FC<{ canError?: boolean }> = ({ children, canError }) => {
  const fc = useFormControl();
  const styles = useHelperStyles({ canError });
  return (
    <DescriptionMicro className={styles.root} color={fc?.error ? "rust" : undefined}>
      {children}
    </DescriptionMicro>
  );
};

const useTextFieldStyles = makeStyles(() =>
  createStyles({
    root: {
      ...descriptionSmallStyles,
      height: "40px",
      boxShadow: Elevation[1],
      backgroundColor: Colors.white,
      border: `1px solid ${Colors.silver}`,
      padding: "12px 16px",
      color: Colors.onyx,
      borderRadius: "2px",
      "&:hover": {
        borderColor: Colors.tin,
      },
      "& input": {
        padding: 0,
      },
      "& input::placeholder": {
        opacity: 1,
        color: Colors.tin,
      },
    },
    error: {
      color: Colors.rust,
      borderColor: Colors.rustLighter,
      backgroundColor: Colors.rustLightest,
      "&$focused": {
        borderColor: Colors.rust,
        backgroundColor: Colors.rustLightest,
      },
      "&:hover": {
        borderColor: Colors.rust,
        backgroundColor: Colors.rustLightest,
      },
      "& input::placeholder": {
        opacity: 1,
        color: Colors.rustLighter,
      },
    },
    focused: {
      borderColor: Colors.wave,
      "&:hover": {
        borderColor: Colors.wave,
      },
    },
    disabled: {
      backgroundColor: Colors.salt,
      cursor: "default",
      "&:hover": {
        borderColor: Colors.silver,
      },
    },
  })
);

export type TextFieldProps = Omit<
  InputBaseProps,
  | "color"
  | "renderPrefix"
  | "renderSuffix"
  | "margin"
  | "hiddenLabel"
  | "helperText"
  | "variant"
  | "size"
> & {
  /**
   * The text for the label on top of the component
   */
  label?: React.ReactNode;
  /**
   * The text for the hint on the bottom of the component
   */
  helperText?: React.ReactNode;
  /**
   * Add a 14px bottom padding in case the form has an error state.
   */
  canError?: boolean;
};

// Be aware that this component has a 14px padding on the bottom for the error message. If you're
// using it for a text field without a helperText set canError to false.
export const TextField = React.forwardRef((props: TextFieldProps, ref: React.Ref<any>) => {
  const {
    classes,
    label,
    helperText,
    canError = true,
    disabled,
    fullWidth = true,
    error,
    required,
    onClick,
    ...rest
  } = props;
  const styles = useTextFieldStyles();
  return (
    <FormControl disabled={disabled} fullWidth={fullWidth} error={error} required={required}>
      {label && <Label htmlFor={props.id}>{label}</Label>}
      <InputBase {...rest} ref={ref} classes={{ ...styles, ...classes }} onClick={onClick} />
      <Helper canError={canError}>{helperText}</Helper>
    </FormControl>
  );
});

const useAutocompleteStyles = makeStyles(() =>
  createStyles({
    option: {
      ...descriptionSmallStyles,
      padding: "8px",
      borderRadius: "2px",
      "&:hover, &:active, &[data-focus=true]": {
        backgroundColor: Colors.waveLightest,
      },
    },
    paper: {
      boxShadow: Elevation[2],
      borderRadius: "2px",
    },
    listbox: {
      padding: "8px",
    },
  })
);

export type AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, "renderInput"> & {
  inputProps?: TextFieldProps;
  required?: boolean;
  error?: boolean;
};
export const Autocomplete = <
  T extends {},
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
) => {
  const { classes, required, error, inputProps, ...rest } = props;
  const styles = useAutocompleteStyles();
  return (
    <MuiAutocomplete
      classes={{ ...styles, ...classes }}
      {...rest}
      renderInput={(props: AutocompleteRenderInputParams) => {
        return (
          <TextField
            disabled={props.disabled}
            required={required}
            error={error}
            ref={props.InputProps.ref}
            inputProps={props.inputProps}
            {...inputProps}
          />
        );
      }}
    />
  );
};

const useSelectStyles = makeStyles(() =>
  createStyles({
    textField: {
      cursor: "pointer",
      // We're faking the color behavior of the TextField in the select here, because it is a controlled component.
      color: ({ selected }: { selected: boolean }) => (selected ? Colors.onyx : Colors.tin),
      "& *": {
        cursor: "pointer",
      },
    },
    popper: {
      boxSizing: "border-box",
      padding: "8px",
      boxShadow: Elevation[2],
      zIndex: 10000,
      backgroundColor: Colors.white,
    },
    paper: {
      boxShadow: "none",
      borderRadius: "0px",
      padding: "0px",
    },
    popperDisablePortal: {
      position: "relative",
    },
    listbox: {
      padding: "0",
    },
  })
);

export type SelectProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, "open">;

export const Select = <
  T extends {},
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: SelectProps<T, Multiple, DisableClearable, FreeSolo>
) => {
  const { disabled, error, required, onClose, onChange, getOptionLabel, inputProps, ...rest } =
    props;
  const [selected, setSelected] = useState<string | T | null>(null);
  const styles = useSelectStyles({ selected: Boolean(selected) });
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const inputRef = useRef<HTMLInputElement>();
  const textFieldRef = useRef<HTMLElement | undefined>();

  const onChangeInternal = (
    event: React.ChangeEvent<{}>,
    value: Value<T, Multiple, DisableClearable, FreeSolo>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>
  ) => {
    const parsed = getOptionLabel ? getOptionLabel(value as T) : (value as T);
    setSelected(parsed);
    onChange && onChange(event, value, reason, details);
    setAnchorEl(null);
  };
  const handleClick = () => {
    if (disabled) return;
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      if (textFieldRef.current) setAnchorEl(textFieldRef.current);
    }
    if (inputRef.current) inputRef.current.blur();
  };

  return (
    <Box>
      <TextField
        value={selected || inputProps?.placeholder || "Choose an option."}
        onClick={handleClick}
        inputRef={inputRef}
        ref={textFieldRef}
        className={styles.textField}
        disabled={disabled}
        error={error}
        required={required}
        endAdornment={
          anchorEl ? (
            <ArrowUp inline={false} color="wave" />
          ) : (
            <ArrowDown inline={false} color="wave" />
          )
        }
        {...inputProps}
      />
      <Popper
        className={styles.popper}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        style={{ width: anchorEl ? anchorEl.clientWidth : undefined }}
        placement="bottom-start"
        modifiers={{ flip: { enabled: false } }}
      >
        <Autocomplete
          disablePortal
          disableCloseOnSelect
          getOptionLabel={getOptionLabel}
          onChange={onChangeInternal}
          onClose={(e: ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
            if (reason === "blur") {
              const event = e as any as FocusEvent;
              if (event.relatedTarget !== inputRef.current) setAnchorEl(null);
            }
            if (onClose) onClose(e, reason);
          }}
          open={true}
          classes={{
            paper: styles.paper,
            popperDisablePortal: styles.popperDisablePortal,
            listbox: styles.listbox,
          }}
          inputProps={{ autoFocus: true, canError: false }}
          {...rest}
        />
      </Popper>
    </Box>
  );
};
