/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AlertStatus {
  ACKED = "ACKED",
  CLOSED = "CLOSED",
  TRIGGERED = "TRIGGERED",
}

export enum AlertType {
  MC_CONNECTIVITY = "MC_CONNECTIVITY",
  MC_ERRORS = "MC_ERRORS",
  MC_WARNINGS = "MC_WARNINGS",
  MOTOR_LOW_TORQUE = "MOTOR_LOW_TORQUE",
  MOTOR_SEIZURE = "MOTOR_SEIZURE",
  MOTOR_SPEED = "MOTOR_SPEED",
  SPV_CONNECTIVITY = "SPV_CONNECTIVITY",
  USER_DEFINED = "USER_DEFINED",
}

export enum ApplicationType {
  EXHAUST_FAN = "EXHAUST_FAN",
  RETURN_FAN = "RETURN_FAN",
  SUPPLY_FAN = "SUPPLY_FAN",
}

export enum CtrlVariablePriority {
  DEBUG = "DEBUG",
  DETAIL = "DETAIL",
  IMPORTANT = "IMPORTANT",
  NORMAL = "NORMAL",
  SHOW_ALL = "SHOW_ALL",
}

export enum EfficiencyType {
  PREMIUM = "PREMIUM",
  STANDARD = "STANDARD",
}

export enum FaultPriority {
  CRITICAL = "CRITICAL",
  INFORMATIONAL = "INFORMATIONAL",
  NON_CRITICAL = "NON_CRITICAL",
  NOT_DEFINED = "NOT_DEFINED",
}

export enum MotorError {
  BUS_CURRENT_HIGH = "BUS_CURRENT_HIGH",
  BUS_VOLTAGE_HIGH = "BUS_VOLTAGE_HIGH",
  CURRENT_OVERLOAD = "CURRENT_OVERLOAD",
  DEVICE_COMMUNICATION_LOST = "DEVICE_COMMUNICATION_LOST",
  EEPROM = "EEPROM",
  EXTERNAL_FAULT_SIGNAL = "EXTERNAL_FAULT_SIGNAL",
  FAN_FAULT = "FAN_FAULT",
  INCOMPATIBLE = "INCOMPATIBLE",
  INVERTER_ENCLOSURE_OVERHEAT = "INVERTER_ENCLOSURE_OVERHEAT",
  INVERTER_HEATSINK_OVERHEAT = "INVERTER_HEATSINK_OVERHEAT",
  MOTOR_OVERHEAT = "MOTOR_OVERHEAT",
  NO_MOTOR = "NO_MOTOR",
  OVERSPEEED = "OVERSPEEED",
  POST_FAILED = "POST_FAILED",
  POWER_LOSS = "POWER_LOSS",
  RESTART_BACKOFF = "RESTART_BACKOFF",
  SENSOR_A = "SENSOR_A",
  SENSOR_B = "SENSOR_B",
  SENSOR_C = "SENSOR_C",
  SENSOR_DC = "SENSOR_DC",
  SOFTWARE = "SOFTWARE",
  STALLED = "STALLED",
  UNCOMMISSIONED = "UNCOMMISSIONED",
}

export enum MotorWarning {
  BUS_CURRENT_HIGH = "BUS_CURRENT_HIGH",
  BUS_VOLTAGE_HIGH = "BUS_VOLTAGE_HIGH",
  COMMUNICATION_UNRELIABLE = "COMMUNICATION_UNRELIABLE",
  CURRENT_OVERLOAD = "CURRENT_OVERLOAD",
  FAN_FAULT = "FAN_FAULT",
  FIRMWARE_UPDATE_FAILED = "FIRMWARE_UPDATE_FAILED",
  INVERTER_ENCLOSURE_OVERHEAT = "INVERTER_ENCLOSURE_OVERHEAT",
  INVERTER_HEATSINK_OVERHEAT = "INVERTER_HEATSINK_OVERHEAT",
  MOTOR_OVERHEAT = "MOTOR_OVERHEAT",
  MOTOR_SIZE = "MOTOR_SIZE",
  OVERSPEED = "OVERSPEED",
  PHASE_IMBALANCE = "PHASE_IMBALANCE",
  PHASE_LOSSED = "PHASE_LOSSED",
  SPEED_DEVIATION = "SPEED_DEVIATION",
  SPEED_INVALID = "SPEED_INVALID",
  USER_LOGIC_ERROR = "USER_LOGIC_ERROR",
}

export enum SettingScope {
  ORG = "ORG",
  SITE = "SITE",
  SYSTEM = "SYSTEM",
}

export enum Sort {
  ASC = "ASC",
  DESC = "DESC",
}

export interface AddSitesShareInput {
  organizationId: string;
  guestOrganizationToken: string;
  asAdmin?: boolean | null;
}

export interface AlertFilter {
  sites?: string[] | null;
  statuses?: AlertStatus[] | null;
  alertTypes?: AlertType[] | null;
  motorErrors?: MotorError[] | null;
}

export interface AlertOrderByInput {
  createdAt?: Sort | null;
}

export interface AlertSettingsInput {
  spvConnEntryDelay?: any | null;
  motorConnEntryDelay?: any | null;
  speedMismatchEntryDelay?: any | null;
  speedMismatchRpmThreshold?: number | null;
}

export interface AssociateSupervisorWithSiteInput {
  siteId: string;
  supervisorSerialNumber: string;
  password: string;
}

export interface ChangePasswordInput {
  oldPassword: string;
  newPassword: string;
}

export interface ClearMotorFaultsInput {
  motorId: string;
}

export interface CreateEmailChannelInput {
  emailAddress: string;
}

export interface CreateSMSChannelInput {
  phoneNumber: string;
}

export interface CreateSiteInput {
  organizationId: string;
  name: string;
  address: string;
  latitude: number;
  longitude: number;
}

export interface CreateUtilityRateInput {
  siteId: string;
  startDate?: any | null;
  rate: number;
}

export interface DeleteNotificationChannelInput {
  id: string;
}

export interface InviteOrganizationMemberInput {
  email: string;
  organizationId: string;
  isAdmin: boolean;
}

export interface RebootMotorInput {
  motorId: string;
}

export interface RemoveOrganizationMemberInput {
  organizationId: string;
  userId: string;
}

export interface RemoveSitesShareInput {
  organizationId: string;
  guestOrganizationId: string;
}

export interface SubscribeToSiteAlertsInput {
  siteId: string;
}

export interface ToggleSiteAlertGenerationInput {
  siteId: string;
  enable: boolean;
}

export interface UnsubscribeFromSiteAlertsInput {
  siteId: string;
}

export interface UpdateBaselineMotorInput {
  motorId: string;
  voltage: number;
  maxSpeed: number;
  motorPower: number;
  tonnage: number;
  efficiencyType: EfficiencyType;
  applicationType: ApplicationType;
  replacedAt?: any | null;
}

export interface UpdateCurrentUserInput {
  firstName?: string | null;
  lastName?: string | null;
}

export interface UpdateMotorNameInput {
  motorId: string;
  name?: string | null;
}

export interface UpdateOrgAlertSettingsInput {
  organizationId: string;
  settings: AlertSettingsInput;
}

export interface UpdateOrganizationMemberInput {
  organizationId: string;
  userId: string;
  isAdmin: boolean;
}

export interface UpdateSiteAlertSettingsInput {
  siteId: string;
  settings: AlertSettingsInput;
}

export interface UpdateSiteInput {
  siteId: string;
  name?: string | null;
  peakDemandCharge?: number | null;
  co2eGPerKwh?: number | null;
  energySavingsEnabled?: boolean | null;
}

export interface UpdateSiteLayoutInput {
  siteId: string;
  layout: any;
}

export interface UpdateSupervisorNameInput {
  supervisorId: string;
  name: string;
}

export interface UpdateUtilityRateInput {
  id: string;
  startDate: any;
  rate: number;
}

export interface UpgradeDeviceFirmwareInput {
  deviceId: string;
}

export interface UploadDeviceLogicFlowInput {
  deviceId: string;
  flow: any;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
