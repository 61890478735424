import * as React from "react";
import {
  HealthStatusBar,
  useHealthStatusBarStyles,
} from "modules/site-manager/components/HealthStatusBar";
import { Health } from "modules/common/enums";
import {
  FirmwareBanner_site,
  FirmwareBanner_site_motors,
  FirmwareBanner_site_supervisors,
} from "generated-gql-types/FirmwareBanner_site";
import { useHistory } from "react-router-dom";
import { Button } from "sigil";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { gql } from "@apollo/client";
import { Grid } from "@mui/material";
import { unmarshalNumberId } from "modules/common/utils/relay";

export const countPart = (count: number, word: string) =>
  count > 0 ? (
    <span>
      <strong>{`${count} `}</strong>
      {`${word}${count > 1 ? "s" : ""}`}
    </span>
  ) : (
    <></>
  );

export const message = (supervisorCount: number, motorCount: number) => {
  if (supervisorCount === 0 && motorCount === 0) {
    return <></>;
  }
  const introPart =
    supervisorCount + motorCount === 1
      ? "There is a firmware update"
      : "There are firmware updates";
  const spvPart = countPart(supervisorCount, "supervisor");
  const motorPart = countPart(motorCount, "motor");
  const conjunction = supervisorCount > 0 && motorCount > 0 ? " and " : "";
  return (
    <>
      {`${introPart} available for `}
      {spvPart}
      {conjunction}
      {motorPart}
    </>
  );
};

const FirmwareButton = ({ siteId }: { siteId: string }) => {
  const history = useHistory();
  return (
    <Button
      variant="solid"
      color="primary"
      size="small"
      onClick={() => history.push(`/sites/${siteId}/config`)}
    >
      View Hardware
    </Button>
  );
};

type FirmwareDevice = FirmwareBanner_site_supervisors | FirmwareBanner_site_motors;

const countOutdatedFirmware = (devices: FirmwareDevice[]) =>
  devices?.reduce((previousValue, currentValue: FirmwareDevice) => {
    return (
      previousValue +
      ((currentValue?.firmware?.status?.__typename || "") === "FirmwareOutdated" ? 1 : 0)
    );
  }, 0) || 0;

const FirmwareBannerComponent = ({ site }: { site: FirmwareBanner_site }) => {
  const styles = useHealthStatusBarStyles();
  const outdatedSpvCount = countOutdatedFirmware(site?.supervisors);
  const outdatedMotorCount = countOutdatedFirmware(site?.motors);
  if (outdatedSpvCount + outdatedMotorCount === 0) {
    return null;
  }
  return (
    <HealthStatusBar align="left" health={Health.GERIATRIC} classes={styles}>
      <Grid
        container
        sx={{
          fontSize: "15px",
          padding: "8px 10px 11px",
        }}
      >
        <Grid item xs>
          {message(outdatedSpvCount, outdatedMotorCount)}
        </Grid>
        <Grid item xs="auto">
          <FirmwareButton siteId={`${unmarshalNumberId(site.id)}`} />
        </Grid>
      </Grid>
    </HealthStatusBar>
  );
};

export const FirmwareBanner = createFragmentContainer(FirmwareBannerComponent, {
  site: gql`
    fragment FirmwareBanner_site on Site {
      id
      supervisors {
        id
        firmware {
          currentVersion
          status {
            ... on FirmwareOutdated {
              isUpgradable
            }
          }
        }
      }
      motors {
        id
        firmware {
          currentVersion
          status {
            ... on FirmwareOutdated {
              isUpgradable
            }
          }
        }
      }
    }
  `,
});
