import React from "react";
import { observer } from "mobx-react";
import { Route, RouteComponentProps, Switch, Redirect } from "react-router-dom";
import { WithSnackbarProps, withSnackbar } from "notistack";
import { AlertDetail_alert_Alert } from "generated-gql-types/AlertDetail";
import { newAlertPresenter } from "modules/site-manager/presenters";
import { AlertAuditLog } from "./AlertAuditLog";
import { AlertSummary } from "./AlertSummary/AlertSummary";
import { SiteStore } from "modules/site-manager/stores";
import { AlertBreadcrumb } from "modules/site-manager/routes/Alerts/components";
import { APIStore } from "modules/common/stores";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { Container } from "../../../components/Container";
import { Colors } from "sigil";
import { Params } from "../../../utils/routing";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
import { LeftAlignedHeader } from "modules/site-manager/components/LeftAlignedHeader";
import { presentError } from "modules/site-manager/utils/errors";

export const styles = () =>
  createStyles({
    title: {
      fontWeight: 600,
      margin: "1rem 0",
    },
    tabs: {
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "row",
    },
    tab: {
      color: "grey",
      textDecoration: "none",
      marginRight: "1.5rem",
      borderBottom: "3px solid transparent",
      transition: "border-bottom-color 100ms ease-in, color 100ms ease-in",

      "&:last-child": {
        marginRight: 0,
      },

      ":hover": {
        color: Colors.onyx,
        textDecoration: "none",
        cursor: "pointer",
        borderBottomColor: Colors.dust,
      },

      "&.active, &.active:hover": {
        color: Colors.onyx,
        borderBottomColor: Colors.ttBlue,
      },
    },
  });

interface AlertDetailsProps extends WithStyles<typeof styles> {
  alert: AlertDetail_alert_Alert;
  apiStore?: APIStore;
  siteStore: SiteStore;
}

type ContentProps = AlertDetailsProps & RouteComponentProps<Params>;

const getContent = (props: ContentProps) => {
  const { match, siteStore } = props;
  if (!siteStore) {
    return null;
  }

  return (
    <Switch>
      {/* TODO: tab-specific routes */}
      {/* Must come last so that it will handle all child routes of
          /alerts/:id that are not explicitly handled above */}
      <Route
        path={match.path}
        render={(routeProps) => (
          <>
            <AlertSummary {...props} {...routeProps} />
            <AlertAuditLog {...props} {...routeProps} />
          </>
        )}
      />
    </Switch>
  );
};

function Component(props: ContentProps & WithSnackbarProps) {
  const { alert, enqueueSnackbar } = props;
  const alertPresenter = newAlertPresenter(alert);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  try {
    return (
      // Primary site navigation header
      <Grid container style={{ overflowY: "auto", overflowX: "hidden", width: "100%" }}>
        <Grid item container xs={12} justifyContent="center" style={{ backgroundColor: "white" }}>
          <Grid
            item
            xs={12}
            sm={10}
            ml={0}
            mt={1}
            style={{ minWidth: "200px", maxWidth: "1200px", padding: "0px 8px 0px 8px " }}
          >
            {/* Alert Breadcrumb */}
            <AlertBreadcrumb alert={alert} presenter={alertPresenter} />
          </Grid>
        </Grid>
        <LeftAlignedHeader loading={false} title={alertPresenter.title}>
          <Box pt={1}>{/* TODO: Tabs */}</Box>{" "}
        </LeftAlignedHeader>

        <Container paddingBottom={isMobile ? "80px" : "8px"}>
          {/* Alert Details content */}
          {getContent(props)}
        </Container>
      </Grid>
    );
  } catch (exception) {
    enqueueSnackbar(presentError("There was an error displaying the alert.", exception), {
      variant: "error",
    });
    return <Redirect to="/alerts" />;
  }
}

export const AlertDetailsPage = withStyles(styles)(withSnackbar(observer(Component)));
