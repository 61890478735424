import * as React from "react";
import { BreadCrumb, BreadcrumbLink, BreadCrumbLeaf } from "modules/common/components/BreadCrumb";
import { AlertPresenter } from "modules/site-manager/presenters";
import { TableAlerts_alerts_edges_node } from "generated-gql-types/TableAlerts";
import { unmarshalNumberId, unmarshalStringId } from "modules/common/utils/relay";
import { Box } from "@mui/material";

type AlertBreadCrumbProps = {
  // TODO make component a fragment container
  alert: TableAlerts_alerts_edges_node;
  presenter: AlertPresenter;
};

export function AlertBreadcrumb({ alert, presenter }: AlertBreadCrumbProps) {
  // breadcrumbs: org / site / (motorgroup?) / (motor name | id)
  // breadcrumbs: org / site / ( supervisor name | id)

  let path = [];

  path.push(alert.site.organization.name);
  path.push(
    <BreadcrumbLink to={`/sites/${unmarshalNumberId(alert.site.id)}`}>
      {alert.site.name}
    </BreadcrumbLink>
  );

  let deviceLink;
  if (alert.cause.hasOwnProperty("motor")) {
    deviceLink = (
      <BreadcrumbLink
        to={`/sites/${unmarshalNumberId(alert.site.id)}/motor/${unmarshalStringId(
          alert.cause["motor"].id
        )}`}
      >
        {presenter.deviceName}
      </BreadcrumbLink>
    );
  } else {
    deviceLink = (
      <BreadcrumbLink to={`/sites/${unmarshalNumberId(alert.site.id)}/config`}>
        {presenter.deviceName}
      </BreadcrumbLink>
    );
  }

  path.push(deviceLink);
  path.push(<BreadCrumbLeaf>Alerts</BreadCrumbLeaf>);

  return (
    <Box color="text.hint">
      <BreadCrumb items={path} />
    </Box>
  );
}
