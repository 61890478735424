import { gql } from "@apollo/client";

export const HEALTH_DIAGNOSTIC_QUERY = gql`
  query HealthDiagnostic($motorId: ID!) {
    motor: node(id: $motorId) {
      ... on Motor {
        id
        healthDiagnostics
      }
    }
  }
`;
