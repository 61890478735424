import * as React from "react";
import { useField } from "formik";
import { CreateSiteForm_organization } from "generated-gql-types/CreateSiteForm_organization";
import { Select } from "sigil";

type FormikOrganizationInputProps = {
  organizations: CreateSiteForm_organization[];
};

export const FormikOrganizationInput = ({ organizations }: FormikOrganizationInputProps) => {
  const [, , { setValue }] = useField("organizationId");
  return (
    <>
      <Select
        options={organizations}
        getOptionLabel={(option) => option.name}
        onChange={(_e: React.ChangeEvent<{}>, value: CreateSiteForm_organization | null) =>
          setValue(value?.id)
        }
        inputProps={{
          label: "Organization",
          required: true,
          placeholder: "Select organization",
        }}
      />
    </>
  );
};
