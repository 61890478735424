export function transformKeys(obj: object, transformFn: (key: string) => string): object {
  return transformKeysOnto({}, obj, transformFn);
}

export function transformKeysOnto<T extends object>(
  base: T,
  obj: object,
  transformFn: (key: string) => string
): T {
  Object.keys(obj).forEach(function (key: string) {
    base[transformFn(key)] = obj[key];
  });
  return base;
}
