import React from "react";
import { getContent, formatLabel } from "./index";

import { SeriesCollection } from "modules/charts/series";

type ReferenceLabelComponentProps = {
  x: number | string;
  label: string;
  payload: any;
  series: SeriesCollection;
};

const padding = 20;
const width = 340 + padding;
const y = 20;

export const ReferenceLabel = ({ x, label, payload, series }: ReferenceLabelComponentProps) => {
  let content = null;

  // not sure how wide the chart is, so put it to the left whenever there's room to do so:
  const textLeft = +x > width + padding ? +x - (width + 4) : +x + 4;
  if (payload && payload.length) {
    content = getContent(payload, series);
  }

  const formattedLabel = formatLabel(label);
  const height = 80 + 15 * (series?.grouped[Object.keys(series.grouped)[0]].length || 0);
  return (
    <g>
      <rect
        x={textLeft}
        y={y}
        width={width}
        height={height + 2}
        fill="white"
        stroke="rgba(0, 0, 0, 0.1)"
      />
      <foreignObject x={textLeft} y={y} width={width} height={height}>
        <div
          style={{
            backgroundColor: "white",
            stroke: "black",
            fontFamily: "Barlow",
            padding: padding,
          }}
        >
          <h5>{formattedLabel}</h5>
          {content}
        </div>
      </foreignObject>
    </g>
  );
};
