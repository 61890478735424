import React, { useState } from "react";
import { gql } from "@apollo/client";
import { Card, Grid, Tooltip } from "@mui/material";
import { Section } from "../SiteConfiguration/ConfigSection";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import { AlertSettingsPane } from "modules/common/components/AlertSettingsPane";
import { AlertSettingsSection_site } from "generated-gql-types/AlertSettingsSection_site";
import { useUpdateSiteAlertSettingsMutation } from "modules/common/mutations/UpdateAlertSettingsMutation";
import { AlertSettingsInput, SettingScope } from "generated-gql-types/globalTypes";

export const AlertSettingsSectionComponent = ({
  site: { id, viewerIsAdmin, alertSettings },
}: {
  site: AlertSettingsSection_site;
}) => {
  const updateSiteAlertSettings = useUpdateSiteAlertSettingsMutation();
  const [alertSettingsKey, setAlertSettingsKey] = useState(0);
  return (
    <Section title="Alert Settings" icon={<SettingsApplicationsIcon />}>
      <Grid container={true} direction="row" spacing={2}>
        <Grid item={true} xs={12} pl={2}>
          <Tooltip
            title={viewerIsAdmin ? "" : "Only organization admins may change alert settings"}
          >
            <Card sx={{ padding: 2 }}>
              <AlertSettingsPane
                key={alertSettingsKey}
                alertSettings={alertSettings}
                parentSettingLevel={SettingScope.ORG}
                updateSettings={(settings: AlertSettingsInput) =>
                  updateSiteAlertSettings({
                    siteId: id,
                    settings: settings,
                  }).then((result) => {
                    // force AlertSettingsPane remount to recompute derived state from props
                    setAlertSettingsKey(alertSettingsKey + 1);
                    return result;
                  })
                }
                disabled={!viewerIsAdmin}
              />
            </Card>
          </Tooltip>
        </Grid>
      </Grid>
    </Section>
  );
};

export const AlertSettingsSection = createFragmentContainer(AlertSettingsSectionComponent, {
  site: gql`
    fragment AlertSettingsSection_site on Site {
      id
      viewerIsAdmin
      alertSettings {
        ...AlertSettingsPane_alertSettings
      }
    }
    ${AlertSettingsPane.fragments.alertSettings}
  `,
});
