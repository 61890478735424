import * as React from "react";
import { Theme } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "inline-block",
      height: "36px",
      lineHeight: "36px",
      borderRadius: "18px",
      padding: "0 18px",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      color: "white",
    },
  });

interface LoadingProps extends WithStyles<typeof styles> {}

export const Loading = withStyles(styles)(({ classes }: LoadingProps) => {
  return <div className={classes.root}>Loading...</div>;
});
