import * as React from "react";
import { useState } from "react";
import { gql } from "@apollo/client";
import { Box, CardActions, CardContent } from "@material-ui/core";
import { Button, Icon, TextField, Typography } from "sigil";
import { useSnackbar } from "notistack";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { AsyncButton } from "modules/common/components/AsyncButton";
import { unmarshalStringId } from "modules/common/utils/relay";
import { EditSharePane_share } from "generated-gql-types/EditSharePane_share";
import { ChonkToggleButton, ChonkToggleButtonGroup } from "../ChonkToggle";
import { useRemoveOutboundSiteShareMutation, useUpdateSitesShareMutation } from "./mutations";

type EditSharePaneProps = {
  share: EditSharePane_share;
  onClose: () => void;
};

function EditSharePaneComponent(props: EditSharePaneProps) {
  const [role, setRole] = useState(props.share.asAdmin ? "admin" : "viewer");
  const [submitting, setSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const updateShare = useUpdateSitesShareMutation();
  const handleUpdate = async () => {
    try {
      setSubmitting(true);
      await updateShare({
        organizationId: props.share.organization.id,
        guestOrganizationToken: unmarshalStringId(props.share.guestOrganization.id),
        asAdmin: role === "admin",
      });
      setSubmitting(false);
      return props.onClose();
    } catch (_) {
      return enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const removeShare = useRemoveOutboundSiteShareMutation();
  const handleRemove = async () => {
    try {
      // close first to avoid rerendering w/ undefined share
      props.onClose();
      return await removeShare({
        organizationId: props.share.organization.id,
        guestOrganizationId: props.share.guestOrganization.id,
      });
    } catch (_) {
      return enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  return (
    <>
      <CardContent>
        <Typography variant="title-xs" paragraph>
          Which organization would you like to invite to access your sites?
        </Typography>
        <TextField
          style={{ maxWidth: "50%" }}
          value={props.share.guestOrganization.name}
          disabled
        />
      </CardContent>

      <CardContent>
        <Typography variant="title-xs">What level of access should they have?</Typography>
        <Typography variant="description-sm" color="ash" paragraph>
          This is the most access allowed to anyone in the target organization. Not every person in
          that organization will have this level of access - their access can be further restricted
          by their organization.
        </Typography>

        <ChonkToggleButtonGroup
          exclusive
          value={role}
          onChange={(event, val) => val && setRole(val)}
        >
          <ChonkToggleButton value="viewer" disabled={true}>
            <Typography variant="title-micro" color="onyx">
              Viewer
            </Typography>
            <Typography variant="description-sm" color="pewter">
              Viewers access grants access to you site dashboards, but prevents users from changing
              or controlling anything.
            </Typography>
          </ChonkToggleButton>
          <ChonkToggleButton value="admin" disabled={true}>
            <Typography variant="title-micro" color="onyx">
              Administrator
            </Typography>
            <Typography variant="description-sm" color="pewter">
              Administrator access grants full access to your sites, including the ability to modify
              site configuration and override controls.
            </Typography>
          </ChonkToggleButton>
        </ChonkToggleButtonGroup>
      </CardContent>

      <CardActions style={{ padding: 16 }}>
        <AsyncButton
          variant="solid"
          color="primary"
          size="medium"
          onClick={handleUpdate}
          disabled={true}
        >
          Share site access
        </AsyncButton>
        <Button variant="solid" color="clear" size="medium" onClick={props.onClose}>
          Cancel
        </Button>
        <Box flexGrow={1} textAlign="right">
          <AsyncButton
            variant="solid"
            color="dangeresque"
            onClick={handleRemove}
            disabled={submitting}
          >
            <Icon variant="ban" /> Stop sharing with this organization
          </AsyncButton>
        </Box>
      </CardActions>
    </>
  );
}

export const EditSharePane = createFragmentContainer(EditSharePaneComponent, {
  share: gql`
    fragment EditSharePane_share on SiteShare {
      organization {
        id
      }
      guestOrganization {
        id
        name
      }
      asAdmin
    }
  `,
});
