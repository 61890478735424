import { Palette } from "@material-ui/core/styles/createPalette";
import { Overrides } from "@material-ui/core/styles/overrides";
import { ComponentsProps } from "@material-ui/core/styles/props";

export const MuiFormOverrides = (palette: Palette): Overrides => ({
  MuiFormLabel: {
    root: {
      "&$focused": {
        color: undefined,
      },
      "&$error": {
        color: undefined,
      },
    },
  },

  MuiFormHelperText: {
    root: {
      marginTop: 0,
      padding: "8px 5px",
      borderRadius: 2,

      "&$error": {
        backgroundColor: palette.error.main,
        color: palette.text.primary,
      },
    },
  },

  MuiInput: {
    // More traditional inset look
    root: {
      background: palette.background.default,
      boxShadow: "inset 0px 1px 2px rgba(0, 0, 0, 0.25)",
      borderRadius: 2,

      "&$disabled": {
        boxShadow: "none",
      },

      "&$focused::after": {
        border: `1px solid ${palette.action.selected}`,
        borderRadius: 2,

        display: "block",
        position: "absolute",
        content: "''",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        pointerEvents: "none",
      },

      "&$error::after": {
        border: `1px solid ${palette.error.main}`,
        borderRadius: 2,

        display: "block",
        position: "absolute",
        content: "''",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        pointerEvents: "none",
      },
    },

    formControl: {
      "label + &": {
        marginTop: 22,
      },
    },

    input: {
      paddingLeft: 8,
      paddingRight: 8,
      fontSize: 15,

      "&[type=password]": {
        letterSpacing: "0.1em",
      },
    },
  },

  MuiInputAdornment: {
    positionStart: {
      marginLeft: 8,
      marginRight: 0,
    },
  },

  MuiInputLabel: {
    root: {
      fontSize: 17.15,
    },
  },

  MuiSelect: {
    root: {
      backgroundColor: palette.background.default,

      "&::after": {
        border: `1px solid #DDDDDD`,
        borderRadius: 2,

        display: "block",
        position: "absolute",
        content: "''",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        pointerEvents: "none",
      },
    },
  },
});

export const MuiFormProps: ComponentsProps = {
  MuiInputLabel: {
    // Always show label above input
    shrink: true,
  },

  MuiInput: {
    disableUnderline: true,
  },

  MuiSelect: {
    // Show empty option now that label is always shrunk
    displayEmpty: true,
  },
};
