import { computed, IObservableArray, observable } from "mobx";
import { prop } from "datx";
import find from "lodash/find";
import groupBy from "lodash/groupBy";
import flatMap from "lodash/flatMap";

import { Model } from "modules/common/model";
import { NO_ZONE } from "modules/site-manager/utils/zone";
import { Ctrl } from "./ctrl";
import { Motor } from "./motor";
import { CtrlVariable } from "./index";

export class Site extends Model {
  static type = "sites";
  // Attributes
  @prop.defaultValue("")
  name!: string;
  @prop.defaultValue(0)
  energyPricePerKwh?: number;
  @prop.defaultValue("")
  organizationId!: string;
  // For graphql queries
  @prop.defaultValue("")
  globalId!: string;
  @prop.defaultValue("")
  address?: string;
  @prop.defaultValue(0)
  openAlertCount!: number;

  // Relations
  @prop.toMany("controllers")
  controllers?: Array<Ctrl>;
  @prop.toMany("motors")
  @observable
  motors?: IObservableArray<Motor>;

  // FIXME: Should use a better comparator than structural - we just care about
  // a shallow equality for all the controllers
  @computed.struct get ctrls(): Array<Ctrl> {
    return this.controllers || observable.array();
  }

  @computed.struct get observableMotors(): Array<Motor> {
    return this.motors || observable.array();
  }

  @computed get sortedMotors() {
    return this.motors ? this.motors.slice().sort(Motor.compare) : [];
  }

  @computed.struct get sortedMotorGroups() {
    return observable(
      flatMap(this.ctrls.slice(), (c) => (c.motorGroups || []).slice()).sort(
        (a, b) => a.groupNum - b.groupNum
      )
    );
  }

  @computed.struct get allCtrlVariables() {
    return this.ctrls
      .reduce((variables, ctrl) => {
        if (!ctrl.variables) {
          return variables;
        }
        return variables.concat(ctrl.variables.slice());
      }, observable([]) as CtrlVariable[])
      .slice()
      .sort((a, b) => a.logicPoint.localeCompare(b.logicPoint));
  }

  @computed.struct
  get ctrlVariablesByZone() {
    const grouped: { [key: string]: CtrlVariable[] } = groupBy(
      this.allCtrlVariables,
      (v) => v.zone || NO_ZONE
    );
    return observable(grouped);
  }

  @computed.struct
  get ctrlVariablesByRole() {
    const grouped: { [key: string]: CtrlVariable[] } = groupBy(
      this.allCtrlVariables,
      (v) => v.bmsRoleId
    );
    return observable(grouped);
  }

  @computed get zones() {
    return Object.keys(this.ctrlVariablesByZone)
      .filter((zone) => zone !== NO_ZONE)
      .sort((a, b) => a.localeCompare(b));
  }

  @computed get hasZones() {
    return this.zones.length > 0;
  }

  getCtrl(id: string) {
    return find(this.ctrls, { id });
  }
  getMotor(id: string) {
    return find(this.motors, { id });
  }
}
