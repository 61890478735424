import * as React from "react";
import { CardContent, CardHeader } from "@material-ui/core";
import { PrimaryCard } from "modules/common/components/PrimaryCard";
import { PasswordForm } from "./PasswordForm";

export function PasswordSettingsRoute() {
  return (
    <PrimaryCard>
      <CardHeader title="Change Password" />
      <CardContent>
        <PasswordForm />
      </CardContent>
    </PrimaryCard>
  );
}
