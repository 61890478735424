import React from "react";
import {
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SiteSavings_site_Site } from "generated-gql-types/SiteSavings";
import { SiteSetupDialog_site } from "generated-gql-types/SiteSetupDialog_site";
import { CO2_REDUCTION } from "modules/site-manager/constants";
import {
  KpiCardProps,
  SiteKpiSet,
} from "modules/site-manager/routes/Site/SiteEnergySavings/EnergySavings/kpis";
import {
  SiteSetupDialog,
  isSite,
} from "modules/site-manager/routes/Site/SiteEnergySavings/SiteSetup";
import { BOXED_CARD_STYLE } from "modules/site-manager/utils/cardStyles";
import { Colors } from "sigil/src";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import LeafOutlinedIcon from "@mui/icons-material/EnergySavingsLeafOutlined";
import { formatNumber } from "modules/site-manager/utils/chartUtils";
import { isNone } from "modules/common/utils/guards";
import { Organization_node_Organization } from "generated-gql-types/Organization";

interface EnergySavingsComponentProps {
  site: SiteSavings_site_Site | Organization_node_Organization;
  kpis: KpiCardProps[];
  kpiSet: SiteKpiSet;
  setKpiSet: React.Dispatch<React.SetStateAction<SiteKpiSet>>;
}

interface KpiBannerProps extends KpiCardProps {
  isMobile: boolean;
  fontWeight?: number;
  fontSize?: string;
  count?: number;
}

export const HeroKpi = (props: KpiBannerProps) => (
  <Tooltip title={props.tooltip}>
    <Stack direction="column" alignItems={"flex-start"} m={0} p={0}>
      {props.title && (
        <Typography
          style={{
            fontWeight: props.fontWeight || 700,
            fontSize: props.fontSize,
            fontFamily: "Barlow",
            lineHeight: 1,
          }}
        >
          {props.title}
        </Typography>
      )}
      <Stack direction="row" alignItems={"baseline"} overflow="visible">
        <Typography
          style={{
            fontSize: props.isMobile ? "36px" : "56px",
            color: "black",
            fontFamily: "Barlow",
            fontWeight: 400,
            lineHeight: 1.3,
          }}
        >
          {isNone(props.value)
            ? "- -"
            : `${formatNumber(
                props.value,
                100000,
                0,
                props.value < 100000 ? 0 : 1,
                props.currency
              )}`}
        </Typography>
        {!isNone(props.value) && props.units && (
          <Typography
            style={{
              fontSize: props.isMobile ? "12px" : "24px",
              lineHeight: 1,
              fontFamily: "Barlow",
            }}
          >
            &nbsp;{props.units}
          </Typography>
        )}
      </Stack>
      <Typography style={{ marginTop: "1px", fontFamily: "Barlow" }}>
        {`WITH ${props.count} TURNTIDE MOTORS INSTALLED`}{" "}
      </Typography>
    </Stack>
  </Tooltip>
);

export const SecondaryKpi = (props: KpiBannerProps) => (
  <Tooltip title={props.tooltip}>
    <Stack direction="row" alignItems={"center"}>
      {props.icon && (
        <Grid item sm={4} lg={3} color={Colors.ttBlue} pl={0.5} textAlign="right">
          {props.icon}
        </Grid>
      )}
      <Stack direction="column" alignItems={"baseline"} m={1} sx={{ minWidth: "240px" }}>
        <Typography
          style={{
            fontWeight: 500,
            fontSize: props.isMobile ? "12px" : "16px",
            fontFamily: "Barlow",
            lineHeight: 1,
          }}
        >
          {props.title}
        </Typography>
        <Stack direction="row" alignItems={"baseline"}>
          <Typography
            style={{
              fontSize: props.isMobile ? "18px" : "28px",
              color: "black",
              fontFamily: "Barlow",
              fontWeight: 400,
              lineHeight: 1.4,
            }}
          >
            {isNone(props.value)
              ? "- -"
              : `${formatNumber(props.value, 100000, 0, props.currency ? 0 : 1, props.currency)}`}
          </Typography>
          {!isNone(props.value) && props.units && (
            <>
              <Typography style={{ fontFamily: "Barlow", fontSize: "24px", lineHeight: 1 }}>
                &nbsp;
              </Typography>
              <Typography
                style={{
                  fontFamily: "Barlow",
                  fontSize: props.isMobile ? "14px" : "18px",
                  lineHeight: 1,
                }}
              >
                {props.units}
              </Typography>
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  </Tooltip>
);

export const EnergySavingsComponent = ({
  site,
  kpis,
  kpiSet,
  setKpiSet,
}: EnergySavingsComponentProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  if (!site) {
    return null;
  }
  const count = isSite(site)
    ? site.baselineMotors?.length
    : site.sites.edges.reduce(
        (previousValue, currentValue) => previousValue + (currentValue.node.motors?.length || 0),
        0
      );
  return (
    <Grid
      item
      container
      xs={12}
      spacing={0}
      sx={BOXED_CARD_STYLE}
      style={{
        backgroundImage: `url("/roi-calculator.png")`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top 16px left 24px",
      }}
      pt={3}
      pl={3}
      pr={0}
      justifyContent="flex-start"
      justifyItems="stretch"
    >
      {/* Primary/Hero KPI*/}
      <Grid
        item
        container
        xs={12}
        sm={true}
        pl={0.5}
        pr={0}
        justifyContent="flex-start"
        justifyItems="stretch"
      >
        <Grid item xs={12} sm={isSite(site) ? 6 : 12}>
          <HeroKpi
            {...kpis[0]}
            count={count}
            isMobile={isMobile}
            fontWeight={isSite(site) ? 700 : 400}
            fontSize={isMobile ? "14px" : isSite(site) ? "38px" : "32px"}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={isSite(site) ? 6 : 12}
          direction="column"
          justifyItems="flex-start"
          alignContent="flex-start"
          alignItems="flex-start"
        >
          {/* stack of secondary/supporting KPIs*/}
          <SecondaryKpi key={kpis[1].title} {...kpis[1]} isMobile={isMobile} />
          <SecondaryKpi key={kpis[2].title} {...kpis[2]} isMobile={isMobile} />
        </Grid>
      </Grid>
      <Grid item xs={12} sm="auto" pt={0} pb={1} pl={1} pr={3} alignContent="flex-start">
        {isSite(site) && <SiteSetupDialog site={site as SiteSetupDialog_site} />}
      </Grid>
      <Grid item container xs={12} direction="row" justifyContent={"center"}>
        <IconButton
          key="page1"
          aria-label="Money and Power"
          color="inherit"
          style={{ fontSize: "inherit" }}
          onClick={() => setKpiSet(SiteKpiSet.SAVINGS)}
          title="Savings"
        >
          <BoltOutlinedIcon
            fontSize="large"
            style={{
              marginBottom: "6px",
              padding: "4px",
              color: kpiSet === SiteKpiSet.SAVINGS ? Colors.ttBlueDarker : Colors.steel,
              backgroundColor: kpiSet === SiteKpiSet.SAVINGS ? Colors.leaf : "transparent",
              border: `2px solid ${kpiSet === SiteKpiSet.SAVINGS ? Colors.leaf : Colors.steel}`,
              borderRadius: 24,
            }}
          />
        </IconButton>
        <IconButton
          key="page2"
          aria-label="Carbon"
          color="inherit"
          style={{ fontSize: "inherit" }}
          onClick={() => setKpiSet(SiteKpiSet.CARBON)}
          title={CO2_REDUCTION}
        >
          <LeafOutlinedIcon
            fontSize="large"
            style={{
              marginBottom: "6px",
              padding: "4px",
              color: kpiSet === SiteKpiSet.CARBON ? Colors.ttBlueDarker : Colors.steel,
              backgroundColor: kpiSet === SiteKpiSet.CARBON ? Colors.leaf : "transparent",
              border: `2px solid ${kpiSet === SiteKpiSet.CARBON ? Colors.leaf : Colors.steel}`,
              borderRadius: 24,
            }}
          />
        </IconButton>
      </Grid>
    </Grid>
  );
};
