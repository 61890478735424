import { computed, IObservableArray, observable } from "mobx";
import { prop } from "datx";
import { Health } from "modules/common/enums";
import { Model } from "modules/common/model";
import { mean, mode } from "modules/common/utils/collections";
import { Motor, MotorOpPoint } from "./motor";
import { Ctrl } from "./ctrl";

export class MotorGroup extends Model {
  static type = "motorGroups";
  static endpoint = "motor_groups";

  // Attributes
  @prop.defaultValue(-1)
  groupNum!: number;
  @prop.defaultValue("")
  name!: string;

  // Relations
  @prop.toOne("controllers")
  controller?: Ctrl;
  @prop.toMany("motors")
  motors?: IObservableArray<Motor>;

  static compareByDisplayName(g1: MotorGroup, g2: MotorGroup) {
    return g1.displayName.localeCompare(g2.displayName);
  }

  @computed get displayName() {
    const { name, groupNum } = this;
    return name ? name : `Motor Group ${groupNum}`;
  }

  @computed get allMotors(): IObservableArray<Motor> {
    const { motors } = this;
    return motors ? motors : observable.array();
  }

  @computed get sortedMotors() {
    return this.motors ? this.motors.slice().sort(Motor.compareByModbus) : [];
  }

  @computed get health() {
    return this.allMotors.reduce((agg, motor) => Math.max(agg, motor.health), Health.UNKNOWN);
  }

  motorCountForHealth(health: Health) {
    const motorsForHealth = this.motorsByHealth[health];
    return motorsForHealth ? motorsForHealth.length : 0;
  }

  @computed get motorsByHealth(): { [key: number]: Motor[] | undefined } {
    return this.allMotors.reduce((agg, motor) => {
      const motorsForHealth = agg[motor.health] || [];
      agg[motor.health] = motorsForHealth.concat(motor);
      return agg;
    }, {});
  }

  @computed get allMotorsOff() {
    return this.allMotors.every((motor) => !motor.isOn);
  }

  // A motor group's current speed is derived from the average of its motors'
  // current speeds, because the commanded speed for the entire group is not
  // stored anywhere.
  // Although the case is rare, we filter out motors that are off, as it can
  // skew the number when e.g. a single motor is taken offline for maintenance.
  @computed get currentSpeed() {
    const avg = mean(
      this.allMotors.filter((motor) => motor.isOn),
      // latestMotorOpPointJS can safely be cast as MotorOpPoint because the
      // motor could not be considered 'on' unless it has a latest point.
      (motor) => (motor.latestMotorOpPointJS as MotorOpPoint).currentSpeedWhenOn
    );
    return Math.round(avg || 0);
  }

  @computed get requestedSpeed() {
    return mode(
      this.allMotors.filter((m) => m.isOn && m.requestedSpeed !== undefined),
      (motor) => motor.requestedSpeed as number
    );
  }
}
