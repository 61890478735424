import { action, computed, IObservableArray, observable } from "mobx";

import { Ctrl, Site } from "modules/site-manager/models";
import memoizeOne from "memoize-one";

export class CtrlsStore {
  @observable models?: IObservableArray<Ctrl>;

  ctrlIdMap?: Map<string, Ctrl>;

  constructor(private site: Site) {}

  @computed get all(): CtrlStore[] {
    const { site } = this;
    const { ctrls } = site;
    return ctrls
      ? observable
          .array(site.ctrls.map((ctrl) => new CtrlStore(ctrl)))
          .slice()
          .sort((a, b) => a.compare(b))
      : [];
  }

  byId(ctrlId: string): Ctrl | undefined {
    const mapCtrls = memoizeOne(() =>
      this.all.reduce((map, ctrlStore) => {
        map[ctrlStore.model.id] = ctrlStore.model;
        return map;
      }, {})
    );
    this.ctrlIdMap = mapCtrls() as Map<string, Ctrl>;
    return this.ctrlIdMap[ctrlId];
  }
}

export class CtrlStore {
  @observable model!: Ctrl;

  constructor(ctrl: Ctrl) {
    action(() => {
      this.model = ctrl;
    })();
  }

  compare(other: CtrlStore) {
    return this.model.id.localeCompare(other.model.id);
  }
}
