import * as React from "react";
import { Link, LinkProps } from "react-router-dom";

import { withStyles, WithStyles } from "@material-ui/core/styles";
import { TextLinkStyles } from "./styles";

interface TextLinkProps extends LinkProps, WithStyles<typeof TextLinkStyles> {}

export const TextLink = withStyles(TextLinkStyles)(({ classes, ...props }: TextLinkProps) => {
  return <Link {...props} className={classes.root} />;
});
