export const ENERGY_CARD_STYLE = {
  display: "flex",
  justifyContent: "stretch",
  alignItems: "center",
  width: "100%",
  marginTop: 2,
  marginBottom: 1,
};

export const getEnergyCardTitleStyle = (isMobile: boolean) => ({
  fontFamily: "Barlow",
  fontSize: isMobile ? "14px" : "20px",
  fontWeight: 500,
  variant: "h1",
});

export const BOXED_CARD_STYLE = {
  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
  backgroundColor: "white",
};

export const SOFT_BOX_SHADOW = {
  boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.07)",
};
