import * as React from "react";
import { useState } from "react";
import { gql } from "@apollo/client";
import { CardContent, Collapse } from "@material-ui/core";
import { Button } from "sigil";
import { MembersCard_organization } from "generated-gql-types/MembersCard_organization";
import { RemoveMemberDialog_member } from "generated-gql-types/RemoveMemberDialog_member";
import { UpdateMemberDialog_member } from "generated-gql-types/UpdateMemberDialog_member";
import { SearchField } from "modules/common/components/SearchField";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { InviteMemberDialog, RemoveMemberDialog, UpdateMemberDialog } from "./Dialog";
import { MembersTable } from "./Table";
import { icontains } from "../utils";
import { StyledCardHeader } from "../styled";

interface MembersCardProps {
  organization: MembersCard_organization;
}

// Manage organization members
function MembersCard({ organization }: MembersCardProps) {
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const toggleInviteDialog = () => setShowInviteDialog((open) => !open);

  const [filter, setFilter] = useState("");
  const filteredMembers = organization.members.edges.filter(
    (edge) => icontains(edge.node.fullName, filter) || icontains(edge.node.email, filter)
  );

  const [updatingMember, setUpdatingMember] = useState<UpdateMemberDialog_member | null>(null);
  const handleUpdateMember = (member: UpdateMemberDialog_member) => setUpdatingMember(member);

  const [removingMember, setRemovingMember] = useState<RemoveMemberDialog_member | null>(null);
  const handleRemoveMember = (member: RemoveMemberDialog_member) => setRemovingMember(member);

  return (
    <React.Fragment>
      <StyledCardHeader
        title={organization.name}
        subheader="Members"
        action={
          organization.viewerIsAdmin && (
            <Button onClick={toggleInviteDialog} variant="solid" color="primary">
              Invite New Users
            </Button>
          )
        }
      />

      <CardContent>
        <SearchField value={filter} onChange={(event) => setFilter(event.target.value)} />
      </CardContent>

      <Collapse in={true} appear={true} style={{ overflowX: "auto" }}>
        <MembersTable
          organization={organization}
          members={filteredMembers}
          onUpdateMember={handleUpdateMember}
          onRemoveMember={handleRemoveMember}
        />
      </Collapse>

      <InviteMemberDialog
        open={showInviteDialog}
        onClose={toggleInviteDialog}
        organization={organization}
      />
      {updatingMember && (
        <UpdateMemberDialog
          open={true}
          onClose={() => setUpdatingMember(null)}
          member={updatingMember}
          organization={organization}
        />
      )}
      {removingMember && (
        <RemoveMemberDialog
          open={true}
          onClose={() => setRemovingMember(null)}
          member={removingMember}
          organization={organization}
        />
      )}
    </React.Fragment>
  );
}

export default createFragmentContainer(MembersCard, {
  organization: gql`
    fragment MembersCard_organization on Organization {
      id
      name
      viewerIsAdmin
      members(first: 1000) {
        edges {
          node {
            id
            fullName
            email
          }
          ...MembersTable_members
          ...UpdateMemberDialog_member
          ...RemoveMemberDialog_member
        }
      }
      ...MembersTable_organization
      ...UpdateMemberDialog_organization
      ...RemoveMemberDialog_organization
      ...InviteMemberDialog_organization
    }
    ${MembersTable.fragments.members}
    ${MembersTable.fragments.organization}
    ${UpdateMemberDialog.fragments.member}
    ${UpdateMemberDialog.fragments.organization}
    ${RemoveMemberDialog.fragments.member}
    ${RemoveMemberDialog.fragments.organization}
    ${InviteMemberDialog.fragments.organization}
  `,
});
