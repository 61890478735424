import * as React from "react";
import { gql } from "@apollo/client";
import { Typography } from "@material-ui/core";
import { AccountEmailForm_user } from "generated-gql-types/AccountEmailForm_user";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";

interface AccountEmailFormProps {
  user: AccountEmailForm_user;
}

function AccountEmailForm({ user }: AccountEmailFormProps) {
  return (
    <form>
      <Typography paragraph={true}>
        Your current email address is: <strong>{user.email}</strong>
      </Typography>
      {/* We don't allow users to update their email because it is the primary key in Auth0.
        * Changing it would require (at minimum) a 2nd round of email verification
      <Grid container={true} spacing={2}>
        <Grid container={true} item={true}>
          <TextField
            label="Change email address"
            type="email"
            fullWidth={true}
            disabled={true}
            style={{ fontSize: "16px !important" }}
          />
        </Grid>

        <Grid container={true} item={true}>
          <TextField
            label="Verify password"
            type="password"
            fullWidth={true}
            disabled={true}
            style={{ fontSize: "16px !important" }}
          />
        </Grid>

        <Grid container={true} item={true}>
          <Button variant="solid" color="plain" disabled={true}>
            Update Email
          </Button>
        </Grid>
      </Grid> */}
    </form>
  );
}

export default createFragmentContainer(AccountEmailForm, {
  user: gql`
    fragment AccountEmailForm_user on User {
      email
    }
  `,
});
