import * as React from "react";
import { gql } from "@apollo/client";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { Button } from "sigil";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";

import { RenameSupervisorDialog_supervisor } from "generated-gql-types/RenameSupervisorDialog_supervisor";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { FormikSubmitButton, FormikTextField } from "modules/common/components/MuiFormik";
import { useUpdateSupervisorNameMutation } from "modules/common/mutations/UpdateSupervisorNameMutation";

type RenameSupervisorDialogComponentProps = {
  supervisor: RenameSupervisorDialog_supervisor;
  open: boolean;
  onClose: () => void;
};

function RenameSupervisorDialogComponent(props: RenameSupervisorDialogComponentProps) {
  const { enqueueSnackbar } = useSnackbar();
  const renameSupervisor = useUpdateSupervisorNameMutation();

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="xs" fullWidth>
      <Formik
        initialValues={{
          spvName: props.supervisor.name,
        }}
        onSubmit={async (values) => {
          try {
            await renameSupervisor({ supervisorId: props.supervisor.id, name: values.spvName });
            enqueueSnackbar("Saved", { variant: "success" });
            props.onClose();
          } catch (e) {
            enqueueSnackbar("Something went wrong", { variant: "error" });
          }
        }}
      >
        <Form>
          <DialogTitle disableTypography>
            <Typography variant="h3">Rename {props.supervisor.serialNumber}</Typography>
          </DialogTitle>

          <DialogContent>
            <FormikTextField
              label="Supervisor Name"
              name="spvName"
              inputProps={{ maxLength: 50 }}
              autoFocus
              fullWidth
            />
          </DialogContent>

          <DialogActions style={{ padding: 16 }}>
            <Button variant="knockout" onClick={props.onClose}>
              Cancel
            </Button>
            <FormikSubmitButton>Rename Supervisor</FormikSubmitButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
}

export const RenameSupervisorDialog = createFragmentContainer(RenameSupervisorDialogComponent, {
  supervisor: gql`
    fragment RenameSupervisorDialog_supervisor on Supervisor {
      id
      serialNumber
      name
    }
  `,
});
