import * as React from "react";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";

const styles = () =>
  createStyles({
    root: {
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      backgroundColor: "rgba(255, 255, 255, 0.8)",
    },
  });

interface CloakProps extends WithStyles<typeof styles> {
  children?: React.ReactNode;
}

export const Cloak = withStyles(styles)(({ children, classes }: CloakProps) => {
  return <div className={classes.root}>{children}</div>;
});
