import * as React from "react";
import { Select, MenuItem, InputBase, Box } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { Formik, Form, FormikProps, Field as FormikField, FormikHelpers, FieldProps } from "formik";
import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js";
import {
  useCreateNotificationChannelMutation,
  CreateChannelInput,
} from "modules/common/mutations/CreateNotificationChannelMutation";
import { useSnackbar } from "notistack";
import { FormikSubmitButton } from "modules/common/components/MuiFormik";
import { FontIcon } from "modules/common/components/FontIcon";
import { InputBaseProps } from "@material-ui/core/InputBase";
import { Colors } from "sigil";
import { useTheme } from "@mui/material/styles";
import { Grid, useMediaQuery } from "@mui/material";

type TextWithSubmitValues = { address: string; type: "email" | "tel" };
const CreateChannelForm: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const addNotificationChannel = useCreateNotificationChannelMutation();

  const handleSubmit = (
    { address, type }: TextWithSubmitValues,
    actions: FormikHelpers<TextWithSubmitValues>
  ) => {
    const input: CreateChannelInput =
      type === "email" ? { emailAddress: address } : { phoneNumber: address };
    return addNotificationChannel(input)
      .catch(() => {
        enqueueSnackbar(validate({ address, type }).address || "Invalid address", {
          variant: "error",
        });
        return Promise.reject();
      })
      .finally(() => {
        actions.setSubmitting(false);
        actions.resetForm();
      });
  };

  return (
    <Formik
      initialValues={{ address: "", type: "email" }}
      onSubmit={handleSubmit}
      validate={validate}
      render={(props: FormikProps<TextWithSubmitValues>) => <TextWithSubmit {...props} />}
    />
  );
};

const validate = (values: TextWithSubmitValues) => {
  const errors: { address?: string } = {};
  switch (values.type) {
    case "email":
      // Regex from https://html.spec.whatwg.org/multipage/input.html#e-mail-state-(type=email)
      if (
        // tslint:disable-next-line:max-line-length
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(
          values.address
        )
      ) {
        errors.address = "Invalid email";
      }
      break;
    case "tel":
      const num = parsePhoneNumberFromString(values.address, "US");
      if (!num || !num.isValid()) {
        errors.address = "Invalid phone number";
      }
      break;
    default:
      break;
  }
  return errors;
};

const format = (address: string, type: "email" | "tel") => {
  return type === "tel" ? new AsYouType("US").input(address) : address;
};

const TextWithSubmitComponent: React.FC<FormikProps<TextWithSubmitValues> & WithStyles> = ({
  values,
  handleChange,
  classes,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Form>
      <Grid container my={2}>
        <Grid
          item
          container
          alignItems="center"
          className={classes.inputs}
          xs={12}
          sm
          height="40px"
        >
          <Grid item minWidth={isMobile ? 115 : 130} className={classes.selectContainer}>
            <Select name="type" onChange={handleChange} value={values.type} input={<FilledInput />}>
              <MenuItem value="email">
                <Box display="inline-block" pl={2}>
                  <FontIcon name="envelope" shrink color="ash" />
                </Box>
                &nbsp;e-mail
              </MenuItem>
              <MenuItem value="tel">
                <Box display="inline-block" pl={2}>
                  <FontIcon name="comment" shrink color="ash" />
                </Box>
                &nbsp;sms
              </MenuItem>
            </Select>
          </Grid>
          <Grid item>
            <NakedTextInput
              type={values.type}
              onChange={(e: any) => {
                return handleChange({
                  ...values,
                  address: format(e.target.value, values.type),
                });
              }}
              name="address"
              className={classes.text}
              fullWidth={true}
            />
          </Grid>
        </Grid>
        <Grid item pt={isMobile ? 1 : 0} pl={isMobile ? 0 : 1} xs="auto">
          <FormikSubmitButton color="primary">Add</FormikSubmitButton>
        </Grid>
      </Grid>
    </Form>
  );
};

const TextWithSubmit = withStyles((theme) => ({
  inputs: {
    border: `1px solid ${Colors.dust}`,
    borderRadius: 1,
    paddingLeft: theme.spacing(1),
    marginRight: 3 * theme.spacing(1),
    background: Colors.kaolin,
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
    },
  },
  selectContainer: {
    borderRight: `1px solid ${Colors.silver}`,
    marginRight: theme.spacing(1),
  },
  text: {
    fontSize: "16px",
    marginLeft: theme.spacing(1),
  },
}))(TextWithSubmitComponent);

export default CreateChannelForm;

const FilledInput = withStyles((theme) => ({
  input: {
    border: "none",
    background: Colors.dust,
    boxShadow: "none",
    fontSize: "16px",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),

    "&:focus": {
      border: "none",
      borderColor: Colors.dust,
      boxShadow: "none",
    },
  },
}))(InputBase);

const NakedTextInput = (props: InputBaseProps) => (
  <FormikField name={props.name}>
    {({ field, form }: FieldProps) => (
      <InputBase {...props} {...field} disabled={form.isSubmitting || props.disabled} />
    )}
  </FormikField>
);
