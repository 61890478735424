import { Box } from "@material-ui/core";
import { DatetimePicker } from "modules/common/components/DatetimePicker";
import { AnalysisStore } from "modules/site-manager/stores";
import React from "react";

export function CalendarPopover(props: {
  analysisStore: AnalysisStore;
  startDate?: Date;
  endDate?: Date;
  onChangeCallback?: () => void;
}) {
  const { analysisStore, startDate, endDate, onChangeCallback } = props;
  const applyInputs = (range: any) => {
    analysisStore.setTimes(range[0].startDate, range[0].endDate);
    if (onChangeCallback) {
      onChangeCallback();
    }
  };

  return (
    <Box>
      <div className="d-flex">
        <DatetimePicker
          onApply={applyInputs}
          startDate={startDate || analysisStore.startTime}
          endDate={endDate || analysisStore.endTime}
        />
      </div>
    </Box>
  );
}
