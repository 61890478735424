import React from "react";
import { Grid } from "@mui/material";
import { NumericInput, NumericInputProps } from "modules/common/components/NumericInput";
import { labelFontStyle } from "global-styles";

export const NumericEdit = ({
  id,
  value,
  label,
  onChange,
  onBlur,
  units,
  maxValue,
  minValue,
  autoFocus = false,
  isError = false,
  disabled = false,
  decimalScale = undefined,
}: NumericInputProps) => (
  <>
    <Grid item xs={4} sx={{ margin: "auto", ...labelFontStyle }}>
      {label}
    </Grid>
    <Grid item xs={8}>
      <NumericInput
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        units={units}
        autoFocus={autoFocus}
        isError={isError}
        disabled={disabled}
        label={label}
        decimalScale={decimalScale}
        maxValue={maxValue}
        minValue={minValue}
      />
    </Grid>
  </>
);
