import { gql } from "@apollo/client";
import { composeMutationHook } from "modules/common/mutations/helpers";
import { AssociateSupervisorWithSiteInput } from "generated-gql-types/globalTypes";
import { AssociateSupervisorWithSite } from "generated-gql-types/AssociateSupervisorWithSite";

// Ideally this would pull from SupervisorSection's fragment, but we have a circular reference
// including it here.
export const associateMutation = gql`
  mutation AssociateSupervisorWithSite($input: AssociateSupervisorWithSiteInput!) {
    response: associateSupervisorWithSite(input: $input) {
      supervisor {
        id
        name
        displayName
        serialNumber
        cascadeFlow
        lastCommunication
        firmware {
          currentVersion
        }
        site {
          id
          supervisors {
            id
          }
        }
      }
    }
  }
`;

export const useAssociateSupervisorWithSiteMutation = composeMutationHook<
  AssociateSupervisorWithSite,
  AssociateSupervisorWithSiteInput
>(associateMutation);
