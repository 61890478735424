/**
 * Source code from: https://github.com/dylanmoz/dylanmoz.github.io/blob/source/src/utils/Delay.js
 * */

import * as React from "react";

interface DelayProps {
  initial: number;
  children: (props: any) => React.ReactNode;
  value: number;
}

export class Delay extends React.Component<DelayProps> {
  unmounting: boolean = false;
  timeout: any;

  static defaultProps = {
    period: 0,
  };

  state = {
    value: this.props.initial,
  };

  componentDidMount() {
    this.refresh(this.props);
  }

  componentWillUnmount() {
    this.unmounting = true;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  refresh(props: any) {
    let { value, period } = props;

    this.timeout = setTimeout(
      () =>
        this.setState({
          value,
        }),
      period
    );
  }

  render() {
    return this.props.children(this.state.value);
  }
}
