import React, { useState } from "react";
import { gql } from "@apollo/client";
import { Box, CardHeader } from "@material-ui/core";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { AlertSettingsPane } from "modules/common/components/AlertSettingsPane";
import { AlertSettingsCard_organization } from "generated-gql-types/AlertSettingsCard_organization";
import { useUpdateOrgAlertSettingsMutation } from "modules/common/mutations/UpdateAlertSettingsMutation";
import { AlertSettingsInput, SettingScope } from "generated-gql-types/globalTypes";
import { FadeTransition } from "./styled";

type AlertSettingsCardProps = {
  organization: AlertSettingsCard_organization;
};

function AlertSettingsCardComponent(props: AlertSettingsCardProps) {
  const updateOrgAlertSettings = useUpdateOrgAlertSettingsMutation();
  const [alertSettingsKey, setAlertSettingsKey] = useState(0);
  const pane = (
    <FadeTransition key="listing">
      <Box pl={2} pr={2} pt={4} pb={4}>
        {props.organization && (
          <AlertSettingsPane
            key={alertSettingsKey}
            alertSettings={props.organization.alertSettings}
            parentSettingLevel={SettingScope.SYSTEM}
            updateSettings={(settings: AlertSettingsInput) =>
              updateOrgAlertSettings({
                organizationId: props.organization.id,
                settings: settings,
              }).then((result) => {
                // force AlertSettingsPane remount to recompute derived state from props
                setAlertSettingsKey(alertSettingsKey + 1);
                return result;
              })
            }
            disabled={!props.organization.viewerIsAdmin}
          />
        )}
      </Box>
    </FadeTransition>
  );

  return (
    <>
      <CardHeader title={<>{props.organization?.name}&nbsp;</>} subheader="Alert Settings" />
      <SwitchTransition mode="out-in">
        <CSSTransition timeout={300} key={pane.key!}>
          {pane}
        </CSSTransition>
      </SwitchTransition>
    </>
  );
}

export const AlertSettingsCard = createFragmentContainer(AlertSettingsCardComponent, {
  organization: gql`
    fragment AlertSettingsCard_organization on Organization {
      id
      name
      viewerIsAdmin
      alertSettings {
        ...AlertSettingsPane_alertSettings
      }
    }
    ${AlertSettingsPane.fragments.alertSettings}
  `,
});
