// prevent an async fn from being called concurrently by throttling subsequent
// invocations while the first resulting promise is unresolved
export function asyncThrottle<T>(fn: () => Promise<T>): () => Promise<T> {
  let inflight: Promise<T> | null;

  return async function () {
    if (inflight) {
      return await inflight;
    }

    inflight = fn();
    const result = await inflight;
    inflight = null;
    return result;
  };
}
