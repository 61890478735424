import React from "react";
import { gql } from "@apollo/client";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { SiteMonthlySavingsChart_site } from "generated-gql-types/SiteMonthlySavingsChart_site";

import { MonthlySavingsChart } from "modules/site-manager/components/MonthlySavingsChart";
import { hasUtilityRate } from "../SiteSetup";

interface MonthlySavingsChartProps {
  site: SiteMonthlySavingsChart_site;
}

export const SiteMonthlySavingsChartComponent = ({ site }: MonthlySavingsChartProps) => {
  return (
    <>
      {site && site.monthlyEnergySavings ? (
        <MonthlySavingsChart
          hasUtilityRate={hasUtilityRate(site)}
          co2eGPerKwh={site.co2eGPerKwh}
          monthlyEnergySavings={site.monthlyEnergySavings}
        />
      ) : null}
    </>
  );
};

export const SiteMonthlySavingsChart = createFragmentContainer(SiteMonthlySavingsChartComponent, {
  site: gql`
    fragment SiteMonthlySavingsChart_site on Site {
      id
      utilityRates {
        id
        rate
      }
      co2eGPerKwh
      monthlyEnergySavings {
        id
        month
        baselineKwh
        turntideKwh
        baselineUtilityCost
        turntideUtilityCost
        baselineCo2eEmissions
        turntideCo2eEmissions
      }
    }
  `,
});
