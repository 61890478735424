import * as React from "react";
import { observer } from "mobx-react";
import memoizeOne from "memoize-one";

import { Typography } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";

import { CardTableRow } from "./row";
import { CardTableStyles } from "./styles";

export interface ColumnDef {
  key: string | number;
  heading?: string;
  getCell: (item: any) => React.ReactNode;
  width?: number | "auto";
  alignment?: "left" | "center" | "right";
}

export interface CardTableProps
  extends React.HTMLAttributes<HTMLDivElement>,
    WithStyles<typeof CardTableStyles> {
  columns: ColumnDef[];
  showHeadings?: boolean;
  getItems: () => any[];
  getRowKey: (item: any) => string;
}

@observer
class CardTableComponent extends React.Component<CardTableProps> {
  static defaultProps = {
    showHeadings: true,
  };

  columnWidths = memoizeOne((columns: ColumnDef[]) => {
    let definedSum = 0;
    let numAuto = 0;

    columns.forEach((columnDef) => {
      const { width } = columnDef;
      if (isAutoWidth(width)) {
        numAuto++;
      } else {
        definedSum += width;
      }
    });

    const autoWidth = (100 - definedSum) / numAuto;

    return columns.map((columnDef) => {
      const { width } = columnDef;
      if (isAutoWidth(width)) {
        return autoWidth;
      }
      return width;
    });
  });

  render() {
    const { columnWidths, props } = this;

    const { classes, columns, getItems, getRowKey, showHeadings, ...other } = props;

    const memoizedWidths = columnWidths(columns);

    return (
      <div {...other}>
        {/* Column Headings */}
        {showHeadings ? (
          <div className={classes.header}>
            {columns.map((columnDef, i) => (
              <div
                key={columnDef.key}
                className={classes.cell}
                style={{
                  width: `${memoizedWidths[i]}%`,
                  textAlign: columnDef.alignment,
                }}
              >
                <Typography variant="caption">{columnDef.heading}</Typography>
              </div>
            ))}
          </div>
        ) : null}

        <ul>
          {/* Rows */}
          {getItems().map((item) => (
            <CardTableRow
              key={getRowKey(item)}
              columns={columns}
              columnWidths={memoizedWidths}
              item={item}
            />
          ))}
        </ul>
      </div>
    );
  }
}

export const CardTable = withStyles(CardTableStyles)(CardTableComponent);

function isAutoWidth(width: number | string | undefined): width is "auto" | undefined {
  return width === undefined || width === "auto";
}
