import * as React from "react";
import { useState } from "react";
import { gql } from "@apollo/client";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
} from "@material-ui/core";
import { Button } from "sigil";
import { UpdateMemberDialog_organization } from "generated-gql-types/UpdateMemberDialog_organization";
import { UpdateMemberDialog_member } from "generated-gql-types/UpdateMemberDialog_member";
import { AsyncButton } from "modules/common/components/AsyncButton";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { useUpdateOrganizationMemberMutation } from "modules/common/mutations/UpdateOrganizationMemberMutation";
import { RoleSelect, RoleType } from "./RoleSelect";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

export interface UpdateMemberDialogProps {
  member: UpdateMemberDialog_member;
  organization: UpdateMemberDialog_organization;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => {
  return {
    paper: {
      "& .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2": {
        width: "100%",
        margin: "0px",
      },
    },
  };
});

function UpdateMemberDialog(props: UpdateMemberDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const updateMember = useUpdateOrganizationMemberMutation();

  const handleSubmit = async () => {
    try {
      await updateMember({
        userId: props.member.node.id,
        organizationId: props.organization.id,
        isAdmin: role === "admin",
      });
      return props.onClose();
    } catch (_) {
      return enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const [role, setRole] = useState(props.member.isAdmin ? "admin" : ("member" as RoleType));
  const styles = useStyles();
  return (
    <Dialog
      className={classNames(styles.paper)}
      maxWidth={"md"}
      open={props.open}
      onBackdropClick={props.onClose}
      fullWidth={true}
      scroll="body"
    >
      <DialogTitle>Update {props.member.node.fullName || "user"}</DialogTitle>
      <DialogContent>
        <form>
          <p>
            Email address: <strong>{props.member.node.email}</strong>
          </p>
          <Box pb={1}>
            <InputLabel>Role</InputLabel>
            <RoleSelect value={role} onChange={setRole} />
          </Box>
        </form>
      </DialogContent>
      <DialogContent>
        <DialogActions>
          <Box flexGrow={1}>
            <Button variant="text" onClick={props.onClose}>
              Cancel
            </Button>
          </Box>
          <AsyncButton variant="solid" color="primary" onClick={handleSubmit}>
            Update
          </AsyncButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default createFragmentContainer(UpdateMemberDialog, {
  member: gql`
    fragment UpdateMemberDialog_member on OrganizationUserEdge {
      isAdmin
      node {
        id
        fullName
        email
      }
    }
  `,
  organization: gql`
    fragment UpdateMemberDialog_organization on Organization {
      id
    }
  `,
});
