interface URL {
  hostname: string;
  pathname: string;
  href: string;
}

// parseUrl is a helper utility that can be used to manipulate URL strings.
// Internally, it uses and returns an instance of an <a> (anchor) tag, which
// allows one to use browsers' implementations of URL parsing methods. As such,
// it is _not_ safe to use in a non-browser environment
export function parseUrl(urlString: string): URL {
  const pseudoURL = document.createElement("a");
  pseudoURL.href = urlString;
  return pseudoURL;
}
