import * as React from "react";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";

import { Series, SeriesCollection } from "modules/charts/series";
import { AnalysisStore } from "modules/site-manager/stores";
import { BasicTimeseriesChart } from "modules/charts/components/BasicTimeseriesChart";
import { ExplorerFieldManager } from "./field-manager";
import { extractYAxisProps } from "modules/charts/axes";
import {
  createSeriesForCtrlVariableField,
  createSeriesForMotorOpField,
} from "modules/charts/series";

interface CtrlLogicExplorerProps {
  analysisStore: AnalysisStore;
  onChangeCallback: () => void;
}

@observer
export class CtrlLogicExplorer extends React.Component<CtrlLogicExplorerProps> {
  get ctrlSeries() {
    const { selectedCtrlVariableFields } = this.props.analysisStore;

    return selectedCtrlVariableFields.reduce((collection, field) => {
      collection.push(createSeriesForCtrlVariableField(field));
      return collection;
    }, [] as Series[]);
  }

  get motorSeries() {
    const { selectedMotorOpFields } = this.props.analysisStore;
    return selectedMotorOpFields.reduce((collection, field) => {
      collection.push(createSeriesForMotorOpField(field));
      return collection;
    }, [] as Series[]);
  }

  render() {
    const { analysisStore, onChangeCallback } = this.props;
    const series = [...this.ctrlSeries, ...this.motorSeries];

    return (
      <Grid container justifyContent="stretch">
        <Grid item xs={12}>
          <BasicTimeseriesChart
            key={series.length}
            data={analysisStore.zippedTimeseriesBuckets}
            tsKey={"timestamp"}
            series={new SeriesCollection(...series)}
            isLoading={analysisStore.isLoadingCtrls}
            yAxisPropsSet={extractYAxisProps(analysisStore.selectedFields)}
          />
        </Grid>
        <Grid item xs={12}>
          <ExplorerFieldManager analysisStore={analysisStore} onChangeCallback={onChangeCallback} />
        </Grid>
      </Grid>
    );
  }
}
