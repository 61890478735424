import { createStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "sigil";

export const PlaceholderActionStyles = (theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
      padding: theme.spacing(1),
      border: `1px dashed ${Colors.tin}`,
      borderRadius: 4,
    },
    actionable: {
      borderColor: Colors.ash,
      cursor: "pointer",
    },

    action: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: "1",
      borderRadius: 2,

      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.05)",
      },
    },
  });
};
