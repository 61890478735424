import { createStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "sigil";

// Note: It is important that this style be one contained in a single definition
// and passed from the parent <CtrlVariableHero /> component down to its children,
// rather than each child separately using withStyles(). There are many inter-
// class relationships (use of `& ...` rules) that depend on the styles being
// injected *once* and getting a single set of unique IDs.
export const CtrlVariableHeroStyles = (theme: Theme) => {
  const space = theme.spacing(1);
  const containerPadding = space * 3;

  const dotSize = 5;
  const arrowSize = 20;

  const slideStyle = {
    padding: `0 ${theme.spacing(4)}px`,
    borderRight: `1px solid ${theme.palette.divider}`,
  };

  return createStyles({
    root: {
      height: "150px",
      overflow: "visible",
    },

    content: {
      overflow: "visible",
      whiteSpace: "nowrap",
      margin: "auto",
      width: "100%",
      height: "100%",
      padding: `${containerPadding}px 0`,
    },

    hideButMeasure: {
      position: "absolute",
      visibility: "hidden",
    },

    slider: {
      position: "relative",
      "& .slick-arrow": {
        display: "flex",
        position: "absolute",
        top: "50%",
        transform: "translate(0, -50%)",
        width: arrowSize,
        height: arrowSize,
        paddingBottom: 1,
        justifyContent: "center",
        alignItems: "center",
        fontSize: 9,
        borderRadius: arrowSize / 2,
        border: 0,
        lineHeight: 0,
        cursor: "pointer",
        background: theme.palette.secondary.main,
        color: "white",
      },

      "& .slick-prev": {
        left: arrowSize / -2 + 2,
        paddingRight: 1,
        zIndex: 1,
      },

      "& .slick-next": {
        right: arrowSize / -2 + 2,
        paddingLeft: 2,
      },

      "& .slick-slide": slideStyle,
      "& .slick-list": {
        height: 150 - containerPadding * 2,
        overflow: "hidden",
      },
    },

    noSliderWrapper: {
      display: "flex",
      justifyContent: "center",

      "& $item": slideStyle,
      "& $item:last-child": {
        borderRight: "none",
      },
    },

    dots: {
      display: "flex",
      position: "absolute",
      width: "100%",
      bottom: -containerPadding,
      height: containerPadding,
      justifyContent: "center",
      alignItems: "center",

      "& li button": {
        display: "block",
        width: dotSize,
        height: dotSize,
        margin: `0 ${dotSize / 1.5}px`,
        padding: 0,
        border: 0,
        fontSize: 0,
        lineHeight: 0,
        outline: 0,
        borderRadius: dotSize,
        background: Colors.silver,
        color: "transparent",
        cursor: "pointer",
      },

      "& li:hover button": {
        background: Colors.slate,
      },

      "& li.slick-active button": {
        width: dotSize + 2,
        height: dotSize + 2,
        background: Colors.ash,
      },
    },

    item: {
      height: 150 - containerPadding * 2,
    },

    itemClickable: {
      display: "flex",
      cursor: "pointer",
      paddingTop: "6px",
    },

    itemTextWrapper: {},

    itemSparkWrapper: {
      padding: `0px 0 0 ${space * 2}px`,
    },
  });
};
