import * as React from "react";
import { Table, TableHead, TableCell, TableBody, TableRow } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { LightLabel } from "modules/common/components/Typography";
import { TableCellProps } from "@material-ui/core/TableCell";
import { TableProps } from "@material-ui/core/Table";

const Header = withStyles({
  root: {
    textTransform: "uppercase",
  },
})(TableHead);

const HeaderRow = withStyles({
  head: {
    height: "inherit", // the height for table rows is 46px by default, reset that
  },
})(TableRow);

type HeaderSpec = string | ({ value: string } & TableCellProps);
const Headers: React.FC<{ headers: HeaderSpec[] }> = ({ headers }) => (
  <>
    {headers.map((header: HeaderSpec) => {
      let args;
      if (typeof header === "string") {
        args = { value: header };
      } else {
        args = header;
      }
      const { value, ...rest } = args;
      return (
        <TableCell key={value} {...rest}>
          <LightLabel>{value}</LightLabel>
        </TableCell>
      );
    })}
  </>
);

type SimpleTableProps = {
  headers: HeaderSpec[];
  emptyMessage: string;
} & TableProps;
export const SimpleTable: React.FC<SimpleTableProps> = ({
  headers,
  emptyMessage,
  children,
  ...tableProps
}) => {
  return (
    <Table {...tableProps} size="small">
      <Header>
        <HeaderRow>
          <Headers headers={headers} />
        </HeaderRow>
      </Header>
      <TableBody>
        {children ? children : <Empty message={emptyMessage} cols={headers.length} />}
      </TableBody>
    </Table>
  );
};

type EmptyProps = { message: string; cols: number };
const Empty: React.FC<EmptyProps> = ({ message, cols }) => (
  <TableRow>
    <TableCell colSpan={cols} style={{ textAlign: "center" }}>
      {message}
    </TableCell>
  </TableRow>
);

export default SimpleTable;
