import React, { useState } from "react";
import { Button, FormControl, FormGroup, Grid, MenuItem, Select, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { AlertSettingsInput, SettingScope } from "generated-gql-types/globalTypes";
import { AlertSettingsPane_alertSettings } from "generated-gql-types/AlertSettingsPane_alertSettings";
import { createFragmentContainer } from "../createFragmentContainer";
import { gql } from "@apollo/client";
import { labelFontStyle } from "global-styles";
import { NumericInput, NumericInputProps } from "modules/common/components/NumericInput";

export const NumericEdit = ({
  id,
  label,
  value,
  maxValue,
  onChange: callback,
  units = "",
  autoFocus = false,
  disabled = false,
}: NumericInputProps) => (
  <FormControl>
    <NumericInput
      id={id}
      value={value}
      onChange={callback}
      units={units}
      autoFocus={autoFocus}
      isError={false}
      disabled={disabled}
      label={label}
      maxValue={maxValue}
    />
  </FormControl>
);

export type AlertSettingComponentProps = {
  id: string;
  label: string;
  qualifier: string;
  parentSettingLevel: SettingScope;
  selectValue: number;
  numberValue: number;
  maxValue?: number | undefined;
  units: string;
  numberCallback: (value: number) => void;
  selectCallback: (value: number) => void;
  autoFocus?: boolean;
  disabled?: boolean;
};

const SETTING_SCOPE_LABELS = {
  [SettingScope.ORG]: "Organization",
  [SettingScope.SITE]: "Site",
  [SettingScope.SYSTEM]: "System",
};

const SETTING_OVERRIDE_LEVEL = {
  [SettingScope.SITE]: SettingScope.SITE,
  [SettingScope.ORG]: SettingScope.SITE,
  [SettingScope.SYSTEM]: SettingScope.ORG,
};

const AlertSettingComponent = ({
  id,
  label,
  qualifier,
  parentSettingLevel,
  selectValue,
  numberValue,
  maxValue,
  units,
  numberCallback,
  selectCallback,
  autoFocus = false,
  disabled = false,
}: AlertSettingComponentProps) => {
  return (
    <Grid container alignItems={"center"}>
      <Grid item sm={6} xs={12}>
        <Typography component="span" sx={labelFontStyle}>
          {"Trigger "}
        </Typography>
        <Typography component="span" fontWeight="Bold" sx={labelFontStyle}>
          {label}
        </Typography>
        <Typography component="span" sx={labelFontStyle}>
          {` alerts ${qualifier} `}
        </Typography>
      </Grid>
      <Grid item sm={3.5} xs={8} padding={1}>
        <Select
          size="small"
          fullWidth
          notched={false}
          label={`Settings Source for ${label}`}
          autoFocus={autoFocus}
          value={selectValue}
          sx={labelFontStyle}
          onChange={(event) => selectCallback(+event.target.value || 0)}
          disabled={disabled}
        >
          <MenuItem value={0} sx={labelFontStyle}>
            Use {SETTING_SCOPE_LABELS[parentSettingLevel]} Settings
          </MenuItem>
          <MenuItem value={1} sx={labelFontStyle}>
            Custom
          </MenuItem>
        </Select>
      </Grid>
      <Grid item sm={2.25} xs={4} padding={0}>
        <NumericEdit
          disabled={disabled || !selectValue}
          id={id}
          label={label}
          value={numberValue}
          onChange={(event) => numberCallback(+event.target.value || 0)}
          maxValue={maxValue}
          units={units}
        />
      </Grid>
    </Grid>
  );
};

const SPV_CONNECTIVITY = "supervisor connectivity";
const MOTOR_CONNECTIVITY = "motor connectivity";
const SPEED_MISMATCH = "speed mismatch";
const MINUTES = "min";
const RPM = "RPM";
const DELAY_QUALIFIER = "after offline for";
const SPEED_QUALIFIER = "at a difference of";
const MAX_DELAY_MINUTES = 1440; // 24 hrs, in minutes
const MAX_SPEED_DELTA = 200;
const NS_PER_MINUTE = 60000000000;

const minutesToDuration = (minutes: number) => `${minutes}m0s`;

const AlertSettingsPaneComponent = ({
  alertSettings,
  parentSettingLevel,
  updateSettings,
  disabled,
}: {
  alertSettings: AlertSettingsPane_alertSettings;
  parentSettingLevel: SettingScope;
  updateSettings: (input: AlertSettingsInput) => Promise<any>;
  disabled: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  // Pull from gql
  const serverSpvDelay = +alertSettings.spvConnSettings.entryDelay.value / NS_PER_MINUTE;
  const serverMotorDelay = +alertSettings.motorConnSettings.entryDelay.value / NS_PER_MINUTE;
  const serverSpeedThreshold = +alertSettings.speedMismatchSettings.rpmThreshold.value;
  const overrideLevel = SETTING_OVERRIDE_LEVEL[parentSettingLevel]; // the level we're currently editing
  const serverSpvCustom = alertSettings.spvConnSettings.entryDelay.setAt === overrideLevel;
  const serverMotorCustom = alertSettings.motorConnSettings.entryDelay.setAt === overrideLevel;
  const serverSpeedThresholdCustom =
    alertSettings.speedMismatchSettings.rpmThreshold.setAt === overrideLevel;

  const [spvDelay, setSpvDelay] = useState(serverSpvDelay);
  const [motorDelay, setMotorDelay] = useState(serverMotorDelay);
  const [speedThreshold, setSpeedThreshold] = useState(serverSpeedThreshold);
  const [spvCustom, setSpvCustom] = useState(serverSpvCustom);
  const [motorCustom, setMotorCustom] = useState(serverMotorCustom);
  const [speedThresholdCustom, setSpeedCustom] = useState(serverSpeedThresholdCustom);

  // Push to gql
  const save = () =>
    updateSettings({
      spvConnEntryDelay: spvCustom ? minutesToDuration(spvDelay) : null,
      motorConnEntryDelay: motorCustom ? minutesToDuration(motorDelay) : null,
      speedMismatchRpmThreshold: speedThresholdCustom ? speedThreshold : null,
    } as AlertSettingsInput)
      .then(() => {
        enqueueSnackbar("Settings saved", { variant: "success" });
      })
      .catch((error: any) =>
        enqueueSnackbar(`Error saving settings: ${error}`, { variant: "error" })
      );

  return (
    <FormGroup>
      <Grid container alignItems={"flex-end"} pl={1}>
        <Grid item md={10.5} xs={12}>
          <AlertSettingComponent
            id={SPV_CONNECTIVITY}
            label={SPV_CONNECTIVITY}
            qualifier={DELAY_QUALIFIER}
            parentSettingLevel={parentSettingLevel}
            selectValue={spvCustom ? 1 : 0}
            numberValue={spvDelay}
            maxValue={MAX_DELAY_MINUTES}
            units={MINUTES}
            numberCallback={setSpvDelay}
            selectCallback={(value: number) => setSpvCustom(!!value)}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={1.5} />

        <Grid item md={10.5} xs={12}>
          <AlertSettingComponent
            id={MOTOR_CONNECTIVITY}
            label={MOTOR_CONNECTIVITY}
            qualifier={DELAY_QUALIFIER}
            parentSettingLevel={parentSettingLevel}
            selectValue={motorCustom ? 1 : 0}
            numberValue={motorDelay}
            maxValue={MAX_DELAY_MINUTES}
            units={MINUTES}
            numberCallback={setMotorDelay}
            selectCallback={(value: number) => setMotorCustom(!!value)}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={1.5} />

        <Grid item container md={10.5} xs={12} alignItems="bottom">
          <AlertSettingComponent
            id={SPEED_MISMATCH}
            label={SPEED_MISMATCH}
            qualifier={SPEED_QUALIFIER}
            parentSettingLevel={parentSettingLevel}
            selectValue={speedThresholdCustom ? 1 : 0}
            numberValue={speedThreshold}
            maxValue={MAX_SPEED_DELTA}
            units={RPM}
            numberCallback={setSpeedThreshold}
            selectCallback={(value: number) => setSpeedCustom(!!value)}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} textAlign="right">
          <Button
            variant="contained"
            color="primary"
            size="medium"
            sx={{ width: 164 }}
            onClick={save}
            disabled={
              disabled ||
              speedThreshold > MAX_SPEED_DELTA ||
              motorDelay > MAX_DELAY_MINUTES ||
              spvDelay > MAX_DELAY_MINUTES ||
              (serverMotorDelay === motorDelay &&
                serverSpvDelay === spvDelay &&
                serverSpeedThreshold === speedThreshold &&
                serverSpvCustom === spvCustom &&
                serverMotorCustom === motorCustom &&
                serverSpeedThresholdCustom === speedThresholdCustom)
            }
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </FormGroup>
  );
};

export const AlertSettingsPane = createFragmentContainer(AlertSettingsPaneComponent, {
  alertSettings: gql`
    fragment AlertSettingsPane_alertSettings on AlertSettings {
      spvConnSettings {
        entryDelay {
          value
          setAt
        }
      }
      motorConnSettings {
        entryDelay {
          value
          setAt
        }
      }
      speedMismatchSettings {
        rpmThreshold {
          value
          setAt
        }
      }
    }
  `,
});
