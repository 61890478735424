import { action, observable } from "mobx";
import { prop } from "datx";
import { Model } from "modules/common/model";
import { CtrlVariable } from "./ctrl-variable";

export class LogicVarOverrideCmd extends Model {
  static type = "logicVarOverrideCmds";
  static endpoint = "logic_var_override_cmds";

  @prop
  createdAt?: string;
  @prop
  floatValue?: number;
  @prop
  booleanValue?: boolean;
  @prop.toOne("controllerVariables")
  variable?: CtrlVariable;

  // client-only indicator of whether this override is still pending; whether or
  // not it has been committed to the device
  @observable acknowledged: boolean = false;

  @action acknowledge = () => (this.acknowledged = true);
}
