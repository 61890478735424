import * as React from "react";
import {
  Typography as MuiTypography,
  TypographyTypeMap as MuiTypographyTypeMap,
  TypographyClassKey,
} from "@material-ui/core";
import { OverrideProps, OverridableComponent } from "@material-ui/core/OverridableComponent";
import { CreateCSSProperties, styled } from "@material-ui/styles";
import "typeface-barlow";
import "typeface-fira-code";
import { Colors, Color } from "../../utils/color";

// These types mirror Material UI's Typography type, unfortunately we can't simplify this, because
// it allows you to pass in extra properties when you pass in custom component. This is undocumented
// and might change in future versions of Material UI.
// ref: https://github.com/mui-org/material-ui/blob/f1831df825038d68e8365369c8dc721c3ef07d11/packages/material-ui/src/Typography/Typography.d.ts
// The nitty gritty: TypographyComponent is a generic, polymorphic type. It has a different
// signature based on whether the component prop is used. There's no way for typescript to infer that
/// if you just extend TypographyProps. So we need to use the low level type helpers from Material.
interface TypographyComponentTypeMap<P = {}, D extends React.ElementType = "span"> {
  props: Omit<MuiTypographyTypeMap<P, D>["props"], "color" | "variant" | "variantMapping"> & {
    /**
     * The color of the text
     */
    color?: Color;
  };
  defaultComponent: D;
  classKey: TypographyClassKey;
}
export type TypographyComponentProps<
  D extends React.ElementType = TypographyComponentTypeMap["defaultComponent"],
  P = {}
> = OverrideProps<TypographyComponentTypeMap<P, D>, D>;
export type TypographyComponent = OverridableComponent<TypographyComponentTypeMap>;

// This component allows us to override the Material UI's color prop on typography and allows us to
// use our fully named color palette.
const TypographyBase: TypographyComponent = ({
  color: _c, // ignore color.
  ...muiProps
}: TypographyComponentProps) => {
  return <MuiTypography {...muiProps} />;
};
const color = (props: TypographyComponentProps) =>
  props.color ? Colors[props.color] : Colors.onyx;
const TypographyBuilder = (styles: CreateCSSProperties): TypographyComponent => {
  const Component = styled(TypographyBase)({
    ...styles,
    color,
  });
  return (props: TypographyComponentProps) => <Component {...props} />;
};

const fontStyles = (styles: CreateCSSProperties): CreateCSSProperties => ({
  fontFamily: `"Barlow", "Helvetica Neue", "Helvetica", "Arial", sans-serif`,
  fontFeatureSettings: "'tnum' on, 'lnum' on",
  ...styles,
});

export const titleExtraLargeStyles = fontStyles({
  fontSize: "96px",
  lineHeight: "92px",
  fontWeight: 100,
});
export const TitleExtraLarge = TypographyBuilder(titleExtraLargeStyles);

export const titleLargeStyles = fontStyles({
  fontSize: "64px",
  lineHeight: "64px",
  fontWeight: 100,
});
export const TitleLarge = TypographyBuilder(titleLargeStyles);

export const titleMediumStyles = fontStyles({
  fontSize: "48px",
  lineHeight: "50px",
  fontWeight: 300,
});
export const TitleMedium = TypographyBuilder(titleMediumStyles);

export const titleSmallStyles = fontStyles({
  fontSize: "32px",
  lineHeight: "36px",
  fontWeight: "normal",
});
export const TitleSmall = TypographyBuilder(titleSmallStyles);

export const titleExtraSmallStyles = fontStyles({
  fontSize: "22px",
  lineHeight: "28px",
  fontWeight: "normal",
});
export const TitleExtraSmall = TypographyBuilder(titleExtraSmallStyles);

export const titleMicroStyles = fontStyles({
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: 500,
});
export const TitleMicro = TypographyBuilder(titleMicroStyles);

export const descriptionExtraLargeStyles = fontStyles({
  fontSize: "22px",
  lineHeight: "32px",
  fontWeight: "normal",
});
export const DescriptionExtraLarge = TypographyBuilder(descriptionExtraLargeStyles);

export const descriptionLargeStyles = fontStyles({
  fontSize: "18px",
  lineHeight: "22px",
  fontWeight: "normal",
});
export const DescriptionLarge = TypographyBuilder(descriptionLargeStyles);

export const descriptionMediumStyles = fontStyles({
  fontSize: "16px",
  lineHeight: "20px",
  fontWeight: 400,
});
export const DescriptionMedium = TypographyBuilder(descriptionMediumStyles);

export const descriptionSmallStyles = fontStyles({
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: 400,
});
export const DescriptionSmall = TypographyBuilder(descriptionSmallStyles);

export const descriptionExtraSmallStyles = fontStyles({
  fontSize: "12px",
  lineHeight: "18px",
  fontWeight: 400,
});
export const DescriptionExtraSmall = TypographyBuilder(descriptionExtraSmallStyles);

export const descriptionMicroStyles = fontStyles({
  fontSize: "10px",
  lineHeight: "14px",
  fontWeight: 400,
});
export const DescriptionMicro = TypographyBuilder(descriptionMicroStyles);

export const smallCapsLargeStyles = fontStyles({
  fontSize: "22px",
  lineHeight: "32px",
  letterSpacing: "3px",
  weight: 600,
  textTransform: "uppercase",
});
export const SmallCapsLarge = TypographyBuilder(smallCapsLargeStyles);

export const smallCapsMediumStyles = fontStyles({
  fontSize: "16px",
  lineHeight: "22px",
  letterSpacing: "3px",
  weight: 600,
  textTransform: "uppercase",
});
export const SmallCapsMedium = TypographyBuilder(smallCapsMediumStyles);

export const smallCapsSmallStyles = fontStyles({
  fontSize: "12px",
  lineHeight: "18px",
  letterSpacing: "3px",
  weight: "bold",
  textTransform: "uppercase",
});
export const SmallCapsSmall = TypographyBuilder(smallCapsSmallStyles);

export const smallCapsMicroStyles = fontStyles({
  fontSize: "10px",
  lineHeight: "14px",
  letterSpacing: "3px",
  weight: "bold",
  textTransform: "uppercase",
});
export const SmallCapsMicro = TypographyBuilder(smallCapsMicroStyles);

export const codeStyles = fontStyles({
  fontSize: "13px",
  lineHeight: "20px",
  fontFamily: `"Fira Code", monospace`,
});
export const Code = TypographyBuilder(codeStyles);

const typographyLookup = {
  "title-xl": TitleExtraLarge,
  "title-l": TitleLarge,
  "title-md": TitleMedium,
  "title-sm": TitleSmall,
  "title-xs": TitleExtraSmall,
  "title-micro": TitleMicro,
  "description-xl": DescriptionExtraLarge,
  "description-l": DescriptionLarge,
  "description-md": DescriptionMedium,
  "description-sm": DescriptionSmall,
  "description-xs": DescriptionExtraSmall,
  "description-micro": DescriptionMicro,
  "smallcaps-lg": SmallCapsLarge,
  "smallcaps-md": SmallCapsMedium,
  "smallcaps-sm": SmallCapsSmall,
  "smallcaps-micro": SmallCapsMicro,
  code: Code,
};

// See note above.
export type TypographyVariant = keyof typeof typographyLookup;
interface TypographyTypeMap<P = {}, D extends React.ElementType = "span"> {
  props: TypographyComponentTypeMap<P, D>["props"] & {
    variant?: TypographyVariant;
  };
  defaultComponent: D;
  classKey: TypographyClassKey;
}
export type TypographyProps<
  D extends React.ElementType = TypographyTypeMap["defaultComponent"],
  P = {}
> = OverrideProps<TypographyTypeMap<P, D>, D>;

export const Typography: OverridableComponent<TypographyTypeMap> = ({
  variant,
  ...rest
}: TypographyProps): ReturnType<TypographyComponent> => {
  const Variant = variant ? typographyLookup[variant] : DescriptionMedium;
  return <Variant {...rest} />;
};
