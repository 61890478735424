import { useMutation, gql } from "@apollo/client";
import { presentError } from "modules/site-manager/utils/errors";
import * as Sentry from "@sentry/react";

export const ACKNOWLEDGED_LABEL = "Acknowledged";
export const CLOSED_LABEL = "Closed";

const ACK_ALERT_MUTATION = gql`
  mutation AckAlertMutation($alertId: ID!) {
    response: ackAlert(id: $alertId) {
      alert {
        id
        status
        canBeAcked
        canBeClosed
      }
    }
  }
`;

const CLOSE_ALERT_MUTATION = gql`
  mutation CloseAlertMutation($alertId: ID!) {
    response: closeAlert(id: $alertId) {
      alert {
        id
        status
        canBeAcked
        canBeClosed
      }
    }
  }
`;

// Submit the alert acknowledgement and update the apollo cache
// so the client is updated even while the mutation is in-flight
export const useAckAlertMutation = () => {
  let [mutate, ackResult] = useMutation(ACK_ALERT_MUTATION);

  const ackAlert = (alertId: string) => {
    mutate({
      variables: { alertId: alertId },
    }).catch((e) => {
      Sentry.captureMessage(
        presentError("Ack alert mutation failed", e),
        "info" // keep it on the DL; meli will presumably have logged the error too
      );
    });
  };

  return { ackAlert, ackResult };
};

// Submit the alert closure and update the apollo cache
// so the client is updated even while the mutation is in-flight
export const useCloseAlertMutation = () => {
  let [mutate, closeResult] = useMutation(CLOSE_ALERT_MUTATION);

  const closeAlert = (alertId: string) => {
    mutate({
      variables: { alertId: alertId },
    }).catch((e) => {
      Sentry.captureMessage(
        presentError("Close alert mutation failed", e),
        "info" // keep it on the DL; meli will presumably have logged the error too
      );
    });
  };

  return { closeAlert, closeResult };
};
