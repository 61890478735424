import { getContrastRatio } from "@material-ui/core/styles";

export enum Colors {
  // Brand color
  bloodOrange = "#F05136",
  // Greys
  onyx = "#2F2F2F",
  slate = "#404040",
  pewter = "#6B6C6F",
  ash = "#999999",
  tin = "#BCBCBC",
  silver = "#D3D4D6",
  dust = "#E8E8E8",
  salt = "#F0F0F0",
  kaolin = "#F8F8F8",
  porcelain = "#F5F6F6",
  white = "#FFFFFF",
  // Secondary colors
  rustDark = "#730000",
  rust = "#C85151",
  rustLight = "#DB8383",
  rustLighter = "#EBB7B7",
  rustLightest = "#FAEDED",
  sunflowerDark = "#9E830E",
  sunflower = "#FAD246",
  sunflowerLight = "#FFE17A",
  sunflowerLighter = "#FFEFB1",
  sunflowerLightest = "#FFFBEB",
  grassDark = "#007230",
  grass = "#3CAB70",
  grassLight = "#75C69B",
  grassLighter = "#AFE0C6",
  grassLightest = "#EBF7F1",
  waveDark = "#003673",
  wave = "#2D84EB",
  waveLight = "#69A9F4",
  waveLighter = "#A7CEFB",
  waveLightest = "#E9F3FE",
  linen = "#FEFBF3",

  // 2022 Colors
  ttBlue = "#008FE0",
  ttBlueDark = "#006AA6",
  ttBlueDarker = "#15507B",
  ttBlueLight = "#5184B7",
  ttBlueLightest = "#D3E4F8",
  ttTableTitle = "#727780",

  // 2023 Colors
  leaf = "#CDDF4A",
  steel = "#6B7B8F",
}

export type Color = keyof typeof Colors;

export const contrastText = (
  background: string,
  dark: string = Colors.onyx,
  light: string = Colors.white
): string => {
  const ratio = getContrastRatio(background, dark);
  return ratio < 3.5 ? light : dark;
};
