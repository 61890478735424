import { computed } from "mobx";
import { prop } from "datx";
import { Model } from "modules/common/model";
import { Health, HealthIssue } from "modules/common/enums";
import { Ctrl } from "./ctrl";
import { Site } from "./site";
import { MotorGroup } from "./motor-group";
import { isWithinLast } from "modules/common/utils/time";
import moment from "moment";
import { digitalValueBinaryStr, digitalOut, digitalIn } from "./digitalValuesUtil";

export class Motor extends Model {
  static type = "motors";

  static speedAllowanceOK = 25;
  static speedAllowanceWarn = 100;

  static commLostDuration = moment.duration(30, "minutes");

  // Attributes
  @prop
  ctrlModbusAddress?: number;
  @prop
  createdAt?: string;
  @prop
  driveFirmwareVersion?: string;
  @prop
  driveModel?: string;
  @prop
  inverterId?: string;
  @prop
  driveSerno?: string;
  @prop
  motorModel?: string;
  @prop
  latestMotorOpPoint?: MotorOpPointProps;
  @prop
  name!: string;

  // Relations
  @prop.toOne("sites")
  site?: Site;
  @prop.toOne("controllers")
  controller?: Ctrl | undefined;
  @prop.toOne("motorGroups")
  motorGroup?: MotorGroup;

  static compare(m1: Model, m2: Model) {
    if (m1.id === m2.id) {
      return 0;
    }
    return m1.id > m2.id ? 1 : -1;
  }

  static compareByModbus(m1: Motor, m2: Motor) {
    if (m1.ctrlModbusAddress === m2.ctrlModbusAddress) {
      // fallback to id comparison
      return Motor.compare(m1, m2);
    } else {
      return (m1.ctrlModbusAddress || 0) - (m2.ctrlModbusAddress || 0);
    }
  }

  @computed
  get ctrl() {
    return this.controller;
  }

  @computed
  get createdAtTime() {
    const { createdAt } = this;
    if (!createdAt) {
      return undefined;
    }
    return new Date(createdAt);
  }

  @computed
  get latestMotorOpPointJS() {
    if (!this.latestMotorOpPoint) {
      return undefined;
    }
    return new MotorOpPoint(this.latestMotorOpPoint);
  }

  @computed
  get displayName() {
    /*
      Customers are more familiar with the drive or enclosure serial number and it should used
      as the first fallback if a custom name isn't used for the motor.
      This mirrors logic used in the motor.go resolver in Melisandre
    */
    const { id, name, driveSerno } = this;
    if (name) {
      return name;
    } else if (driveSerno) {
      return driveSerno;
    } else {
      return id;
    }
  }

  @computed
  get isOn() {
    const point = this.latestMotorOpPointJS;
    return !!(point && point.motorOn);
  }

  @computed
  get requestedSpeed() {
    const point = this.latestMotorOpPointJS;
    return point ? point.speedReq : undefined;
  }

  @computed
  get health(): Health {
    let conditions = this.healthConditions;
    return conditions.length > 0 ? conditions[0].health : Health.HEALTHY;
  }

  // healthConditions is a list of issues currently affecting motor operation, ordered by severity.
  // in the future this should be driven by alerts rather than these heuristics
  @computed
  get healthConditions() {
    const latestPoint = this.latestMotorOpPointJS || ({} as MotorOpPoint);
    let conditions = [];

    // No telemetry
    if (!latestPoint || latestPoint.absoluteDiff === undefined) {
      conditions.push({
        issue: HealthIssue.NO_DATA,
        health: Health.UNKNOWN,
      });
    }

    // Lost communication
    if (!isWithinLast(Motor.commLostDuration, latestPoint.tsTime)) {
      conditions.push({
        issue: HealthIssue.OLD_DATA,
        health: Health.UNKNOWN,
      });
    }

    // Motor is reporting problems - record errors and ignore warnings
    if (!!latestPoint.errorCount) {
      conditions.push({
        issue: HealthIssue.HAS_ERR,
        health: Health.ERROR,
      });
    }

    // order by severity
    conditions.sort((a, b) => b.health - a.health);

    return conditions;
  }

  @computed
  get hasTurntidePower() {
    const model = this.motorModel?.trim().toLocaleUpperCase() || "";
    const series = model.substring(0, model?.indexOf("-"));
    return ["V01", "V02", "V03"].includes(series);
  }
}

export type MotorOpPointProps = {
  ts?: string;
  speed_cur?: number;
  speed_req?: number;
  power_in?: number;
  power_out?: number;
  torque?: number;
  motor_on?: boolean;
  error_count?: number;
  warn_count?: number;
  current_a?: number;
  current_b?: number;
  current_c?: number;
  digital_values?: number;
  power?: number;
};

export class MotorOpPoint {
  ts!: string;
  speedCur?: number;
  speedReq?: number;
  powerIn?: number;
  powerOut?: number;
  torque?: number;
  motorOn?: boolean;
  errorCount?: number;
  warnCount?: number;
  currentA?: number;
  currentB?: number;
  currentC?: number;
  digitalValues?: number;
  power?: number;

  constructor(point: MotorOpPointProps) {
    this.ts = point.ts!; // we assume this is there.
    this.speedCur = point.speed_cur;
    this.speedReq = point.speed_req;
    this.powerIn = point.power_in;
    this.powerOut = point.power_out;
    this.torque = point.torque;
    this.motorOn = point.motor_on;
    this.errorCount = point.error_count;
    this.warnCount = point.warn_count;
    this.currentA = point.current_a;
    this.currentB = point.current_b;
    this.currentC = point.current_c;
    this.digitalValues = point.digital_values;
  }

  @computed
  get tsTime() {
    return new Date(this.ts);
  }

  @computed
  get recordedAtTime() {
    return new Date(this.ts);
  }

  @computed
  get recordedAtMoment() {
    return moment(this.recordedAtTime);
  }

  @computed
  get recordedAtMs() {
    return this.recordedAtTime.getTime();
  }

  // currentRunningSpeed returns the current speed of the motor if it is On,
  // otherwise it returns 0
  @computed
  get currentSpeedWhenOn() {
    const { motorOn, speedCur } = this;
    const currentSpeed = speedCur || 0;
    return motorOn ? currentSpeed : 0;
  }

  @computed
  get digitalValueBinaryStr() {
    return digitalValueBinaryStr(this.digitalValues);
  }

  @computed
  get digitalOut() {
    // Digital out is first 4 values of the binary string
    // 0 values is the "ON" state
    return digitalOut(this.digitalValues);
  }

  @computed
  get digitalIn() {
    // Digital in is the last 7 values of the binary string in reverse order
    // 0 values is the "ON" state
    return digitalIn(this.digitalValues);
  }

  @computed
  get speedDiff(): number | undefined {
    if (typeof this.speedCur !== "number") {
      return undefined;
    }
    if (typeof this.motorOn === "boolean" && !this.motorOn) {
      return this.speedCur;
    }
    if (typeof this.speedReq !== "number") {
      return undefined;
    }
    return this.speedCur - this.speedReq;
  }

  @computed
  get absoluteDiff(): number | undefined {
    const { speedDiff } = this;
    return speedDiff === undefined ? undefined : Math.abs(speedDiff);
  }

  @computed
  get efficiency(): number | undefined {
    const { powerIn, powerOut } = this;
    if (powerIn === undefined) {
      return undefined;
    }
    if (powerOut === undefined) {
      return undefined;
    }
    return (powerOut / powerIn) * 100;
  }

  @computed
  get targetSpeed(): number | undefined {
    const { motorOn, speedReq } = this;
    return !motorOn ? 0 : speedReq;
  }
}
