import { gql, useMutation } from "@apollo/client";
import { UpdateSiteLayoutInput } from "generated-gql-types/globalTypes";

const mutation = gql`
  mutation UpdateSiteLayout($input: UpdateSiteLayoutInput!) {
    updateSiteLayout(input: $input) {
      site {
        id
        layout
      }
    }
  }
`;

export const useUpdateSiteLayoutMutation = () => {
  const [mutate, status] = useMutation(mutation);
  const ret = (input: UpdateSiteLayoutInput) => mutate({ variables: { input } });
  return [ret, status] as const;
};
