import * as React from "react";
import { useQuery, gql } from "@apollo/client";
import { SiteRoleCheck, SiteRoleCheck_node_Site } from "generated-gql-types/SiteRoleCheck";

function useSiteRole(siteId: any) {
  const { data } = useQuery<SiteRoleCheck>(
    gql`
      query SiteRoleCheck($siteId: ID!) {
        node(id: $siteId) {
          id
          ... on Site {
            viewerIsAdmin
          }
        }
      }
    `,
    { variables: { siteId } }
  );

  const node = ((data && data.node) as SiteRoleCheck_node_Site) || {};

  return {
    isAdmin: node.viewerIsAdmin,
  };
}

type WithSiteRoleProps = {
  siteId: any;
  children: (props: { isAdmin: boolean }) => React.ReactNode;
};

// This component only exists because we can't currently mix hooks and mobx observers >_<
export function WithSiteRole(props: WithSiteRoleProps) {
  const role = useSiteRole(props.siteId);
  return <React.Fragment>{props.children(role)}</React.Fragment>;
}
