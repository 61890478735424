import { Overrides } from "@material-ui/core/styles/overrides";
import { Colors } from "sigil";

export const MuiTooltipOverrides: Overrides = {
  MuiTooltip: {
    tooltip: {
      backgroundColor: Colors.slate,
      borderRadius: 2,
      textAlign: "center",
      fontSize: 12,
      padding: "8px 16px 10px",
    },
    arrow: {
      color: Colors.slate,
    },
  },
};
