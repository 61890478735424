import * as React from "react";
import { camelCase } from "lodash";
import { computed, observable } from "mobx";

import { BMSRoleBitmaskPresenter, CtrlVariablePresenter } from "modules/site-manager/presenters";
import { UnitSymbol } from "modules/site-manager/components/UnitSymbol";
import {
  CtrlVariableField,
  EnergyDataField,
  MotorOpField,
  ZippedTSPoint,
} from "modules/site-manager/stores";
import { BMSBehaviorKind } from "modules/site-manager/constants";
import { YAxis } from "./constants";
import { Colors } from "sigil";
import { isNone } from "modules/common/utils/guards";

export interface SeriesProps {
  id: string;
  label: React.ReactNode;
  getValue: (data: object) => number | null;
  formatValue?: (value: number | null) => string;
  group?: string;
  color?: string;
  yAxisId: number;
}

export class Series {
  public id: string;
  public label: React.ReactNode;
  public getValue: (data: any) => number | null;
  public formatValue?: (value: number | null) => string;
  @observable public visible: boolean = true;
  public group?: string;
  public color?: string;

  // Note: technically, the type is string | number, but string is buggy
  // recharts for some reason.
  // ref: https://github.com/recharts/recharts/issues/1473
  public yAxisId: number = YAxis.Left;

  constructor(props: SeriesProps) {
    this.id = props.id;
    this.label = props.label;
    this.getValue = props.getValue;
    this.formatValue = props.formatValue;
    this.group = props.group;
    this.color = props.color;
    this.yAxisId = props.yAxisId;
  }
}

export class SeriesCollection {
  private series: Series[] = [];
  private colorSeq = 0;

  constructor(...series: SeriesProps[]) {
    series.forEach((s) => this.add(s));
  }

  add(props: SeriesProps): Series {
    const s = new Series(props);
    if (!props.color) {
      s.color = this.nextColor();
    }
    this.series = this.series.concat(s);
    return s;
  }

  @computed get all(): Series[] {
    return this.series;
  }

  @computed get visible(): Series[] {
    return this.series.filter((s) => s.visible);
  }

  @computed get grouped(): { [key: string]: Series[] } {
    return this.series.reduce((memo, seriesItem) => {
      const group = seriesItem.group || SERIES_GROUP_NONE;
      const grouped = memo[group] || [];
      memo[group] = grouped.concat(seriesItem);
      return memo;
    }, {});
  }

  nextColor() {
    const color = SeriesColors[this.colorSeq % SeriesColors.length];
    this.colorSeq++;
    return color;
  }
}

export const SERIES_GROUP_NONE = "SERIES_GROUP_NONE";

const SeriesColors = [
  Colors.rust,
  Colors.grass,
  Colors.wave,
  Colors.bloodOrange,
  Colors.sunflower,
  // TODO: We need more colors...
];

export function createSeriesForCtrlVariableField(field: CtrlVariableField) {
  const { ctrlVariable, aggregation } = field;
  const presenter = new CtrlVariablePresenter(ctrlVariable);
  return {
    id: field.id,
    label: (
      <React.Fragment>
        {presenter.controllerDisplayName}:&nbsp;
        {presenter.name}
        {ctrlVariable.hasUnits ? (
          <React.Fragment>
            &nbsp;&nbsp;
            <UnitSymbol unit={ctrlVariable.bacnetUnit || undefined} trim={true} />
          </React.Fragment>
        ) : null}
      </React.Fragment>
    ),
    visible: true,
    yAxisId: field.yAxis,
    getValue({ ctrl }: ZippedTSPoint) {
      const { controllerId } = ctrlVariable;
      if (!controllerId || !ctrl) {
        return null;
      }
      const point = ctrl.data[`${controllerId}_${aggregation}`];
      return point ? point.numericValueForVariable(ctrlVariable.id) : null;
    },
    formatValue(val: number | null) {
      if (val === null) {
        return "Unknown";
      }
      if (ctrlVariable.behaviorKind === BMSBehaviorKind.Bitmask) {
        return new BMSRoleBitmaskPresenter(ctrlVariable.bmsRoleId).labelFor(val);
      }
      if (ctrlVariable.enumMap) {
        return ctrlVariable.enumMap[val.toString()] ?? val.toFixed(2);
      }
      return val.toFixed(2);
    },
  };
}

export function createSeriesForMotorOpField(field: MotorOpField) {
  const { motorOp, aggregation } = field;
  return {
    id: field.id,
    label: motorOp.name,
    visible: true,
    yAxisId: field.yAxis,
    getValue({ motor }: ZippedTSPoint) {
      const { motor: motorOpMotor, op } = motorOp;
      if (!motor) {
        return null;
      }
      const point = motor.data[`${motorOpMotor.id}_${aggregation}`];
      return point ? point[camelCase(op)] : null;
    },
    formatValue(val: number | null) {
      if (isNone(val)) {
        return "Unknown";
      }
      return val.toFixed(2);
    },
  };
}

export function createSeriesForEnergyDataField(field: EnergyDataField): Series {
  const { energyDatum, aggregation } = field;
  return {
    id: field.id,
    label: energyDatum.name,
    visible: true,
    yAxisId: field.yAxis,
    getValue({ energy }: ZippedTSPoint) {
      const { motor: motorOpMotor, op } = energyDatum;
      if (!energy) {
        return null;
      }
      const point = energy.data[`${motorOpMotor.id}_${aggregation}`];
      return point ? point[camelCase(op)] : null;
    },
    formatValue(val: number | null) {
      if (val === null) {
        return "Unknown";
      }
      return val.toFixed(2);
    },
  };
}

export class BandRangeProp {
  public startTime: Date;
  public endTime: Date;
  constructor(props: { startTime: Date; endTime: Date }) {
    this.startTime = props.startTime;
    this.endTime = props.endTime;
  }
}
