import { action, computed } from "mobx";

import { Site } from "modules/site-manager/models";
import { CtrlsStore } from "./ctrl";
import { MotorsStore, MotorStore } from "./motor";

export class SiteStore {
  constructor(public site: Site) {}

  @computed get ctrls() {
    return new CtrlsStore(this.site);
  }

  @computed get motors() {
    return new MotorsStore(this.site);
  }

  @action removeMotor(motor: MotorStore) {
    this.site.motors && this.site.motors.remove(motor.model);
  }
}
