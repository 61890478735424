import { createStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "sigil";

export const OverrideableCtrlVariableValueStyles = (theme: Theme) => {
  return createStyles({
    overriddenIcon: {
      fontSize: "0.66em",
      textDecoration: "none",
      color: Colors.tin,
    },

    fixedOverrideIcon: {
      fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      fontSize: 24,
      height: "1em",
      lineHeight: 1.4,
      color: Colors.waveLighter,
      marginLeft: theme.spacing(1),
    },
  });
};
