import * as React from "react";
import {
  HealthStatusBar,
  useHealthStatusBarStyles,
} from "modules/site-manager/components/HealthStatusBar";
import { Health } from "modules/common/enums";
import { AlertBanner_site } from "generated-gql-types/AlertBanner_site";
import { useHistory } from "react-router-dom";
import { Button } from "sigil";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { gql } from "@apollo/client";
import { Grid } from "@mui/material";

const message = (count: number) =>
  count > 1 ? (
    <>
      There are <strong>{count}</strong> open alerts that need attention
    </>
  ) : (
    <>
      There is <strong>1</strong> open alert that needs attention
    </>
  );

const AlertButton = ({ siteId, count }: { siteId: string; count: number }) => {
  const history = useHistory();
  return (
    <Button
      variant="solid"
      color="dangeresque"
      size="small"
      onClick={() => history.push(`/alerts/sites/${siteId}`)}
    >
      View Alert{count > 1 ? "s" : ""}
    </Button>
  );
};

const AlertBannerComponent = ({ site }: { site: AlertBanner_site }) => {
  const styles = useHealthStatusBarStyles();
  const count = site?.alerts?.totalCount;
  if (count == null || count < 1) {
    return null;
  }
  return (
    <HealthStatusBar align="left" health={Health.ERROR} classes={styles}>
      <Grid
        container
        sx={{
          fontSize: "15px",
          padding: "8px 10px 11px",
        }}
      >
        <Grid item xs>
          {message(count)}
        </Grid>
        <Grid item xs="auto">
          <AlertButton siteId={site.id} count={count} />
        </Grid>
      </Grid>
    </HealthStatusBar>
  );
};

export const AlertBanner = createFragmentContainer(AlertBannerComponent, {
  site: gql`
    fragment AlertBanner_site on Site {
      id
      alerts(includeCount: true) {
        totalCount
      }
    }
  `,
});
