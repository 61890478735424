import { useMutation, gql } from "@apollo/client";
import { InviteOrganizationMemberInput } from "generated-gql-types/globalTypes";

const mutation = gql`
  mutation InviteOrganizationMember($input: InviteOrganizationMemberInput!) {
    inviteOrganizationMember(input: $input) {
      organization {
        id
        members(first: 1000) {
          edges {
            isAdmin
            isActivated
            node {
              id
              fullName
              email
              isViewer
            }
          }
        }
      }
    }
  }
`;

export function useInviteOrganizationMemberMutation() {
  const [mutate] = useMutation(mutation);
  return (input: InviteOrganizationMemberInput) => mutate({ variables: { input } });
}
