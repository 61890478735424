import * as React from "react";
import { observer } from "mobx-react";
import { PolarAngleAxis, RadialBar, RadialBarChart } from "recharts";
import classNames from "classnames";
import { Theme } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { TextLink } from "modules/common/components/TextLink";
import { Colors } from "sigil";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    gaugeWrapper: {
      display: "flex",
      alignItems: "end",
      marginBottom: theme.spacing(0.5),
    },
    metricPositioner: {
      position: "relative",
    },
    metricWrapper: {
      display: "flex",
      position: "absolute",
      right: 0,
      left: 0,
      top: 0,
      bottom: 0,
      alignItems: "center",
      justifyContent: "center",
    },
    metric: {
      fontSize: "28px",
      textAlign: "center",
    },
    tick: {
      flex: 1,
      width: "20px",
      fontSize: "13px",
      alignSelf: "flex-end",
    },
    tickMin: {
      textAlign: "right",
    },
    label: {
      marginTop: "-0.3em",
      fontSize: "16px",
      textAlign: "center",
    },
  });

const DATA_KEY = "value";

export type MetricGaugeProps = {
  width: number;
  metric: number;
  color?: string;
  min?: number;
  max?: number;
  formatMetric: (metric: number) => React.ReactNode;
  label?: string;
  labelLink?: string;
};

@observer
class MetricGaugeComponent extends React.Component<WithStyles<typeof styles> & MetricGaugeProps> {
  static defaultProps: Partial<MetricGaugeProps> = {
    color: Colors.slate,
    min: 0,
    max: 100,
  };

  private wrapAngle = 30;
  private barSize = 20;

  get data() {
    const { metric, max, color } = this.props;
    // We manipulate the metric value so that even when the value is 0, we show
    // a tiny sliver of a bar. Max can safely be typed as `number` because we
    // are using defaultProps above.
    const correctedMetric = Math.max(metric, (max as number) * 0.005);
    return [
      {
        [DATA_KEY]: correctedMetric,
        fill: color,
      },
    ];
  }

  render() {
    const { props, wrapAngle, barSize } = this;
    const { metric, width, min, max, formatMetric, label, classes } = props;

    const radius = width / 2;

    const constrainedAngle = Math.max(Math.min(wrapAngle, 90), 0);
    const angleRadians = (constrainedAngle * Math.PI) / 180;

    const extraHeight = (Math.tan(angleRadians) * width) / 2;
    const height = radius + extraHeight;

    return (
      <div className={classes.root}>
        <div className={classes.gaugeWrapper}>
          <div className={classNames(classes.tick, classes.tickMin)}>{min}</div>
          <div className={classes.metricPositioner}>
            <RadialBarChart
              width={width}
              height={height}
              cy={radius}
              innerRadius={radius - barSize / 2}
              outerRadius={radius + barSize / 2}
              margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
              data={this.data}
              startAngle={180 + constrainedAngle}
              endAngle={-1 * constrainedAngle}
            >
              <PolarAngleAxis
                // PolarAngleAxis is only here to force the domain.
                // tslint:disable-next-line
                // ref: https://github.com/recharts/recharts/issues/171#issuecomment-413205575
                type="number"
                domain={[min || 0, max || metric]}
                tick={false}
              />
              <RadialBar dataKey={DATA_KEY} background={true} animationDuration={120} />
            </RadialBarChart>
            <div className={classes.metricWrapper}>
              <div className={classes.metric} style={{ paddingTop: extraHeight / 2 }}>
                {formatMetric(metric)}
              </div>
            </div>
          </div>
          <div className={classes.tick}>{max}</div>
        </div>

        {label ? (
          <div className={classes.label}>
            {props.labelLink ? <TextLink to={props.labelLink}>{label}</TextLink> : label}
          </div>
        ) : null}
      </div>
    );
  }
}

export const MetricGauge = withStyles(styles)(MetricGaugeComponent);
