import * as React from "react";
import { Typography } from "@material-ui/core";
import { WithStyles, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { BOXED_CARD_STYLE, SOFT_BOX_SHADOW } from "modules/site-manager/utils/cardStyles";
import { Grid } from "@mui/material";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 150,
      height: 75,
      border: `1px solid ${theme.palette.text.disabled}`,
      borderRadius: 1,
      paddingLeft: 10,
    },
  });

type KeyMetricProps = {
  label: string;
  value?: any;
  units: string;
};

function KeyMetricComponent(props: KeyMetricProps & WithStyles<typeof styles>) {
  const maybeValue = props.value !== undefined ? props.value : "N/A";
  return (
    <Grid item sx={SOFT_BOX_SHADOW}>
      <Grid
        item
        container
        sx={BOXED_CARD_STYLE}
        p={2}
        justifyContent="flex-start"
        direction="column"
      >
        <Typography variant="overline">{props.label}</Typography>
        <Grid item container alignItems="flex-end" spacing={1}>
          <Grid item>
            <Typography variant="h3">{maybeValue} </Typography>
            <Typography variant="caption" style={{ lineHeight: 1 }}>
              {props.units}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export const KeyMetric = withStyles(styles)(KeyMetricComponent);
