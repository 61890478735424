import * as React from "react";
import { useHistory } from "react-router-dom";
import { SiteRow } from "./SiteRow";
import { useState, useEffect, useRef, RefObject } from "react";
import { Map, LngLatBounds, LngLat, MapMouseEvent, EventData } from "mapbox-gl";
import { AllSites, AllSites_sites_nodes } from "generated-gql-types/AllSites";
import { SiteRow_site } from "generated-gql-types/SiteRow_site";
import { Feature, Point } from "geojson";
import { Grid, Paper, RootRef } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { SmcMuiTheme } from "theme";
import { useQuery, gql } from "@apollo/client";

const allSites = gql`
  query AllSites($search: String, $limit: Int) {
    sites(first: $limit, search: $search) {
      nodes {
        id
        ...SiteRow_site
      }
    }
  }
  ${SiteRow.fragments.site}
`;

type SiteMarkersProps = {
  map?: Map | null;
  active?: string | null;
  data?: AllSites;
  loaded: boolean;
  search: string | null;
  limit: number | null | undefined;
};
type ActiveProps = { id?: string | null; hover: boolean; zoom?: boolean };
export const SiteMarkers: React.FC<SiteMarkersProps> = ({ map, active, loaded, search, limit }) => {
  const [hoverActive, setHoverActive] = useState<ActiveProps>({
    id: null,
    hover: false,
  });
  const { data } = useQuery<AllSites>(allSites, {
    fetchPolicy: "cache-and-network", // So that we refetch the query when someone adds a site
    variables: { search, limit },
    skip: !limit,
  });
  const sites = data?.sites?.nodes;
  const features = useFeatures(sites);
  useEffect(() => {
    setHoverActive({ id: active, hover: false });
  }, [active]);
  // On first render we won't have a map object yet, because useMap's Effect hasn't run yet.
  let tip = null;
  const ready = useLayers(features, loaded, setHoverActive, map);
  if (ready && map) {
    // We toggle the state to include either what was passed in or what happened in a hover
    toggle(map, hoverActive);
    if (hoverActive.id && hoverActive.hover) {
      tip = map
        ?.querySourceFeatures("sites-hover", {
          filter: ["==", "id", hoverActive.id],
        })
        .map((feature) => {
          // Mapbox json encodes nested props: https://github.com/mapbox/mapbox-gl-js/issues/2434
          const site = {
            ...feature.properties,
            organization: JSON.parse(feature?.properties?.organization || "{}"),
          } as SiteRow_site;
          return <ToolTip site={site} map={map} />;
        })[0];
    }

    if (!hoverActive.hover) fitBounds(map, true, features);
  }

  return <>{tip}</>;
};

// construct our features from edges.
const useFeatures = (sites?: AllSites_sites_nodes[]) => {
  const [features, setFeatures] = useState<PointFeature[]>([]);
  useEffect(() => {
    if (!sites) return;
    const f = sites
      .filter((s) => s.latitude !== null && s.longitude !== null)
      .map(
        (s, i): PointFeature => ({
          id: i,
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [s.longitude!, s.latitude!],
          },
          properties: {
            ...s,
            icon: s.organization.displayAlerts && s.hasOpenAlerts ? "Alert" : "Ok",
          },
        })
      )
      .sort((a) => (a.properties.hasOpenAlerts ? 1 : -1));
    setFeatures(f);
  }, [sites]);
  return features;
};

type MapBoxMouseEvent = MapMouseEvent & { features?: PointFeature[] | null } & EventData;
type FeatureProps = {
  icon: "Alert" | "Ok";
} & SiteRow_site;
type PointFeature = Feature<Point, FeatureProps>;
const useLayers = (
  features: PointFeature[],
  loaded: boolean,
  setHoverActive: (h: ActiveProps) => void,
  map?: Map | null
) => {
  const [ready, setReady] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!map) return undefined;
    if (!loaded) return undefined;
    if (map.getLayer("sites")) return undefined;
    const layout = {
      "icon-allow-overlap": true,
      "symbol-z-order": "source",
    } as const;
    const layer = {
      type: "symbol",
      source: { type: "geojson", data: { type: "FeatureCollection", features } },
    } as const;
    // Weird! There are two layers here, one that is initially hidden, and when a user hovers
    // over it or hovers in the sidebar we show that node's id.
    map.addLayer({
      id: "sites",
      paint: {
        "icon-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 0, 1],
      },
      ...layer,
      layout: {
        "icon-image": "Small{icon}",
        ...layout,
      },
    });

    map.addLayer({
      id: "sites-hover",
      ...layer,
      paint: {
        "icon-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 1, 0],
      },
      layout: {
        "icon-image": "Large{icon}",
        "icon-anchor": "bottom",
        "icon-offset": [0, 8],
        ...layout,
      },
    });

    const mousemove = (e: MapBoxMouseEvent) =>
      maybeFeature(e, (feature: PointFeature) => {
        setHoverActive({ id: feature.properties.id, hover: true });
        map.getCanvas().style.cursor = "pointer";
      });

    // @ts-ignore: 3.9 is stricter about union types and optional properties, and the mapbox types
    // don't allow for generic events that contain arbitrary features.
    // https://devblogs.microsoft.com/typescript/announcing-typescript-3-9/
    map.on("mousemove", "sites", mousemove);

    const mouseleave = () => {
      setHoverActive({ hover: true });
      map.getCanvas().style.cursor = "";
    };
    map.on("mouseleave", "sites", mouseleave);

    const click = (e: MapBoxMouseEvent) =>
      maybeFeature(e, (feature: PointFeature) => {
        const parts = atob(feature.properties.id).split(":");
        if (parts.length === 2) history.push(`/sites/${parts[parts.length - 1]}`);
      });
    // @ts-ignore
    map.on("click", "sites", click);
    fitBounds(map, false, features);
    setReady(true);
    return () => {
      if (map) {
        // Mapbox blows up sometimes on these calls
        try {
          // @ts-ignore
          map.off("mousemove", "sites", mousemove);
          map.off("mouseleave", "sites", mouseleave);
          // @ts-ignore
          map.off("click", "sites", click);

          map.removeLayer("sites");
          map.removeSource("sites");

          map.removeLayer("sites-hover");
          map.removeSource("sites-hover");
        } catch {}
      }
    };
  }, [features, map, setReady, loaded, setHoverActive, history]);

  useEffect(() => {
    if (!map || !loaded || !map.getLayer("sites") || !map.getLayer("sites-hover")) return undefined;
    // @ts-ignore: setData is totally here, but mapbox types are busted.
    map.getSource("sites").setData({ type: "FeatureCollection", features });
    // @ts-ignore
    map.getSource("sites-hover").setData({ type: "FeatureCollection", features });
    return undefined;
  }, [features, map, loaded]);

  return ready;
};

const moveToolTip = (site: SiteRow_site, ref: RefObject<HTMLElement>, map: Map) => {
  const div = ref && ref.current;
  if (div) {
    const coords = [site.longitude ?? 0, site.latitude ?? 0] as [number, number];
    const offset = map.project(coords);
    div.style.display = "block";
    div.style.top = `${offset.y - div.offsetHeight - 19}px`;
    div.style.left = `${offset.x - 12}px`;
  }
};

const useToolTipStyles = makeStyles((theme: typeof SmcMuiTheme) =>
  createStyles({
    root: {
      zIndex: 1,
      position: "absolute",
      display: "none",
      maxWidth: "344px",
      pointerEvents: "none",
      border: "none",
    },
    tip: {
      marginLeft: "4px",
      borderWidth: "8px 8px 0px 8px",
      borderColor: "white transparent transparent transparent",
      borderStyle: "solid",
      boxShadow: theme.shadows[0],
      width: "8px",
      height: "8px",
    },
    paper: {
      borderWidth: 0,
      borderRadius: "0px 2px 2px 0",
    },
  })
);

// our custom popup
const ToolTip = ({ site, map }: { site: SiteRow_site; map: Map }) => {
  const ref = useRef<HTMLElement>(null);
  const styles = useToolTipStyles();
  useEffect(() => {
    moveToolTip(site, ref, map);
    const cb = () => moveToolTip(site, ref, map);
    map.on("move", cb);
    return () => {
      map.off("move", cb);
    };
  }, [site, ref, map]);
  return (
    <RootRef rootRef={ref}>
      <Grid className={styles.root}>
        <Paper className={styles.paper}>
          <SiteRow site={site} active />
        </Paper>
        <div className={styles.tip} />
      </Grid>
    </RootRef>
  );
};

const maybeFeature = (e: MapBoxMouseEvent, cb: (feature: PointFeature) => void) => {
  if (e !== undefined && Array.isArray(e.features) && e.features[0]) {
    cb(e.features[0]);
  }
};
// Swap out icons
const toggle = (map: Map, active: ActiveProps) => {
  map.removeFeatureState({ source: "sites-hover" });
  map.removeFeatureState({ source: "sites" });
  if (active.id) {
    map.querySourceFeatures("sites", { filter: ["==", "id", active.id] }).forEach((feature) => {
      map.setFeatureState({ source: "sites-hover", id: feature.id }, { hover: true });
      map.setFeatureState({ source: "sites", id: feature.id }, { hover: true });
    });
  }
};

// zoom to Fit our map to our features
const fitBounds = (map: Map, animate: boolean, features: PointFeature[]) => {
  if (features.length > 0) {
    const bounds = features.reduce(
      (b, f) => b.extend(new LngLat(...(f.geometry.coordinates as [number, number]))),
      new LngLatBounds()
    );
    try {
      map.fitBounds(bounds, {
        animate,
        maxZoom: 8,
      });
    } catch (e) {}
  }
};
