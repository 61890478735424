import * as React from "react";
import { observer } from "mobx-react";
import { RouteComponentProps } from "react-router-dom";

import { Container } from "../../../components/Container";
import { Grid } from "@mui/material";

import { APIStore, injectStores } from "modules/common/stores";
import { parseQuery } from "modules/common/utils/query-params";
import { AnalysisField, AnalysisStore, FieldKind, SiteStore } from "modules/site-manager/stores";
import { CtrlLogicExplorer } from "modules/site-manager/components/CtrlLogicExplorer";
import { MotorOp } from "modules/site-manager/models/motor-op";
import { MotorOpStat } from "modules/site-manager/constants/motor-op";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { Button, Colors } from "sigil";
import { exportCSVFile } from "./ExportCSV";

import {
  createSeriesForMotorOpField,
  createSeriesForCtrlVariableField,
  createSeriesForEnergyDataField,
} from "modules/charts/series";
import { FancyDivider } from "../../Alerts/components";
import { CalendarPopover } from "modules/site-manager/components/CalendarPopover";
import { EnergyDataStat } from "modules/site-manager/constants/energy-data";
import { EnergyData } from "modules/site-manager/models/energy-data";
import { ANALYSIS_DATE_FORMAT } from "modules/common/utils/time";
import { YAxis } from "modules/charts/constants";

interface Params {}

interface SiteAnalysisRouteProps extends RouteComponentProps<Params> {
  siteStore: SiteStore;
  apiStore?: APIStore;
}

const isObjValue = (val: any, obj: any) => Object.values(obj).includes(val);

@injectStores("api")
@observer
export class SiteAnalysisRoute extends React.Component<SiteAnalysisRouteProps> {
  public analysisStore: AnalysisStore;

  constructor(props: SiteAnalysisRouteProps) {
    super(props);
    const { apiStore, siteStore } = props;
    if (!apiStore) {
      throw new Error("API store unavailable");
    }
    this.analysisStore = new AnalysisStore(apiStore, siteStore);
  }

  get content() {
    const { analysisStore } = this;

    return (
      <Grid container justifyContent="stretch">
        <CtrlLogicExplorer
          analysisStore={analysisStore}
          onChangeCallback={() => this.saveToLocalStorage(true)}
        />
      </Grid>
    );
  }

  componentDidMount() {
    const {
      location: { search },
    } = this.props;
    this.applyInitialCtrlVariables(search);
  }

  componentWillUnmount() {
    // this saves analyses configured by incoming View Analysis links
    this.saveToLocalStorage();
  }

  saveToLocalStorage(hasUserChanges?: boolean) {
    const js = {
      selectedFields: this.analysisStore.selectedFields.map((field) => ({
        id: field.id,
        kind: field.kind,
        yAxis: field.yAxis,
        aggregation: field.aggregation,
      })),
      startTime: moment(this.analysisStore.startTime).format(ANALYSIS_DATE_FORMAT),
      endTime: moment(this.analysisStore.endTime).format(ANALYSIS_DATE_FORMAT),
    };
    const json = JSON.stringify(js, null, 2);
    localStorage.setItem(this.getStorageKey(), json);
    if (hasUserChanges) {
      // also clobber the query string so refresh picks up edits
      this.props.history.replace({ search: "" });
    }
  }

  applyInitialCtrlVariables(queryString: string) {
    const { siteStore } = this.props;
    const { analysisStore } = this;
    const query = parseQuery(queryString);

    // There were variables found for the query
    if (query.v) {
      parseInitialVariableIds(query.v).forEach((id) => {
        let [deviceId, varName] = id.split("$");
        let motor = siteStore.site.getMotor(deviceId);
        if (motor) {
          if (isObjValue(varName, MotorOpStat)) {
            const newField = analysisStore.addMotorOpField(
              new MotorOp(motor, varName as MotorOpStat)
            );
            if (varName === "torque") {
              analysisStore.setAxis(newField, YAxis.Right);
            }
            return newField;
          }
          return analysisStore.addEnergyDataField(new EnergyData(motor, varName as EnergyDataStat));
        }
        let controller = siteStore.site.getCtrl(deviceId);
        if (controller && controller.variables) {
          let ctrlVar = controller.variables.find((v) => v.logicPoint === varName);
          if (ctrlVar) {
            return analysisStore.addCtrlVariableField(ctrlVar);
          }
        }
        return;
      });
    } else {
      this.loadFieldsFromLocalStorage();
    }

    // There were dates found for the query
    if (query.d?.length && typeof query.d === "string") {
      const dates = query.d.split(",");
      this.analysisStore.setTimes(
        moment(dates[0], ANALYSIS_DATE_FORMAT).toDate(),
        moment(dates[1], ANALYSIS_DATE_FORMAT).toDate()
      );
    } else {
      this.loadDatesFromLocalStorage();
    }
  }

  loadFieldsFromLocalStorage() {
    const json = localStorage.getItem(this.getStorageKey());
    if (json) {
      const deserialized = JSON.parse(json);
      deserialized.selectedFields.forEach((field: any) => {
        let newField: AnalysisField | undefined = undefined;
        switch (field.kind) {
          case FieldKind.CtrlVariable:
            // add control variable
            const ctrlVarId = field.id;
            const ctrlVar = this.analysisStore.siteStore.site.allCtrlVariables.find(
              (v) => v.id === ctrlVarId
            );
            if (ctrlVar) {
              newField = this.analysisStore!.addCtrlVariableField(ctrlVar);
            }
            break;
          case FieldKind.MotorOp:
            const [motorOpId, op] = field.id.split(":");
            const motor = this.analysisStore.siteStore.motors.byId(motorOpId);
            if (motor) {
              const motorOp = new MotorOp(motor, op);
              newField = this.analysisStore.addMotorOpField(motorOp);
            }
            break;
          case FieldKind.EnergyData:
            const [energyDatumId, energyOp] = field.id.split(":");
            const energyMotor = this.analysisStore.siteStore.motors.byId(energyDatumId);
            if (energyMotor) {
              const energyDatum = new EnergyData(energyMotor, energyOp);
              newField = this.analysisStore.addEnergyDataField(energyDatum);
            }
            break;
        }
        if (newField) {
          this.analysisStore.setAggregation(newField, field.aggregation);
          this.analysisStore.setAxis(newField, field.yAxis);
        }
      });

      this.analysisStore.setSelectionFilter();
    }
  }

  getStorageKey() {
    return `${this.analysisStore.siteStore.site.id}-analysis`;
  }

  loadDatesFromLocalStorage = () => {
    const json = localStorage.getItem(this.getStorageKey());
    if (json) {
      const deserialized = JSON.parse(json);
      this.analysisStore.setTimes(
        moment(deserialized.startTime, ANALYSIS_DATE_FORMAT).toDate(),
        moment(deserialized.endTime, ANALYSIS_DATE_FORMAT).toDate()
      );
    }
  };

  handleExportClick = () => {
    const ctrlSeries = this.analysisStore.selectedCtrlVariableFields.map((field) =>
      createSeriesForCtrlVariableField(field)
    );
    const motorSeries = this.analysisStore.selectedMotorOpFields.map((field) =>
      createSeriesForMotorOpField(field)
    );
    const energySeries = this.analysisStore.selectedEnergyDataFields.map((field) =>
      createSeriesForEnergyDataField(field)
    );
    const allSeries = [...ctrlSeries, ...motorSeries, ...energySeries];

    const headers = [
      ...this.analysisStore.selectedCtrlVariableFields,
      ...this.analysisStore.selectedMotorOpFields,
      ...this.analysisStore.selectedEnergyDataFields,
    ].map((field) => field.name);

    const supervisors = this.props.siteStore.ctrls?.all;
    let timezone = "UTC";
    if (supervisors) {
      const ctrl = supervisors && supervisors[0];
      if (ctrl?.model?.timezone) {
        timezone = ctrl.model.timezone;
      }
    }

    const data = this.analysisStore.zippedTimeseriesBuckets;
    const rows = data.map((datum) => {
      const row: any = {
        timestamp: momentTimezone(datum.timestamp).format(ANALYSIS_DATE_FORMAT),
      };
      allSeries.forEach(
        (series, index) =>
          series.formatValue && (row[headers[index]] = series.formatValue(series.getValue(datum)))
      );

      return row;
    });

    headers.unshift(`"Time (${timezone.trim()})"`);
    const now = moment();
    const filename = `Site-Analysis-${this.analysisStore.siteStore.site.name}-${now.format(
      "l-LTS-z"
    )}`;
    exportCSVFile(headers, rows, filename);
  };

  render() {
    const { analysisStore } = this;

    return (
      <Container paddingBottom="64px">
        <Grid container>
          <Grid item container justifyContent="space-between" alignItems="center" spacing={1}>
            <Grid item xs>
              <FancyDivider>Data History</FancyDivider>
            </Grid>
            <Grid item xs="auto">
              <CalendarPopover
                analysisStore={analysisStore}
                startDate={analysisStore.startTime}
                endDate={analysisStore.endTime}
                onChangeCallback={() => this.saveToLocalStorage(true)}
              />
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="knockout"
                style={{ backgroundColor: Colors.white, marginLeft: "4px" }}
                onClick={this.handleExportClick.bind(this)}
                disabled={
                  this.analysisStore.selectedCtrlVariableFields.length === 0 &&
                  this.analysisStore.selectedMotorOpFields.length === 0
                }
                title="Download a CSV of the data currently visible in the chart"
              >
                Export CSV
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {this.content}
      </Container>
    );
  }
}

function parseInitialVariableIds(query: string | string[]): string[] {
  const queryArr: string[] = Array.prototype.concat(query);
  return queryArr.reduce((ids, str) => {
    const filteredBMSBehaviors = str.split(",");
    return ids.concat(...filteredBMSBehaviors);
  }, [] as string[]);
}
