import { useMutation, gql } from "@apollo/client";
import { UpdateCurrentUserInput } from "generated-gql-types/globalTypes";

const mutation = gql`
  mutation UpdateCurrentUser($input: UpdateCurrentUserInput!) {
    updateCurrentUser(input: $input) {
      user {
        id
        firstName
        lastName
        fullName
      }
    }
  }
`;

export function useUpdateCurrentUserMutation() {
  const [mutate] = useMutation(mutation);
  return (input: UpdateCurrentUserInput) => mutate({ variables: { input } });
}
