export enum BacnetUnit {
  SquareMeters = 0,
  SquareFeet = 1,
  Milliamperes = 2,
  Amperes = 3,
  Ohms = 4,
  Volts = 5,
  Kilovolts = 6,
  Megavolts = 7,
  VoltAmperes = 8,
  KilovoltAmperes = 9,
  MegavoltAmperes = 10,
  VoltAmperesReactive = 11,
  KilovoltAmperesReactive = 12,
  MegavoltAmperesReactive = 13,
  DegreesPhase = 14,
  PowerFactor = 15,
  Joules = 16,
  Kilojoules = 17,
  WattHours = 18,
  KilowattHours = 19,
  Btus = 20,
  Therms = 21,
  TonHours = 22,
  JoulesPerKilogramDryAir = 23,
  BtusPerPoundDryAir = 24,
  CyclesPerHour = 25,
  CyclesPerMinute = 26,
  Hertz = 27,
  GramsOfWaterPerKilogramDryAir = 28,
  PercentRelativeHumidity = 29,
  Millimeters = 30,
  Meters = 31,
  Inches = 32,
  Feet = 33,
  WattsPerSquareFoot = 34,
  WattsPerSquareMeter = 35,
  Lumens = 36,
  Luxes = 37,
  FootCandles = 38,
  Kilograms = 39,
  PoundsMass = 40,
  Tons = 41,
  KilogramsPerSecond = 42,
  KilogramsPerMinute = 43,
  KilogramsPerHour = 44,
  PoundsMassPerMinute = 45,
  PoundsMassPerHour = 46,
  Watts = 47,
  Kilowatts = 48,
  Megawatts = 49,
  BtusPerHour = 50,
  Horsepower = 51,
  TonsRefrigeration = 52,
  Pascals = 53,
  Kilopascals = 54,
  Bars = 55,
  PoundsForcePerSquareInch = 56,
  CentimetersOfWater = 57,
  InchesOfWater = 58,
  MillimetersOfMercury = 59,
  CentimetersOfMercury = 60,
  InchesOfMercury = 61,
  DegreesCelsius = 62,
  DegreesKelvin = 63,
  DegreesFahrenheit = 64,
  DegreeDaysCelsius = 65,
  DegreeDaysFahrenheit = 66,
  Years = 67,
  Months = 68,
  Weeks = 69,
  Days = 70,
  Hours = 71,
  Minutes = 72,
  Seconds = 73,
  MetersPerSecond = 74,
  KilometersPerHour = 75,
  FeetPerSecond = 76,
  FeetPerMinute = 77,
  MilesPerHour = 78,
  CubicFeet = 79,
  CubicMeters = 80,
  ImperialGallons = 81,
  Liters = 82,
  UsGallons = 83,
  CubicFeetPerMinute = 84,
  CubicMetersPerSecond = 85,
  ImperialGallonsPerMinute = 86,
  LitersPerSecond = 87,
  LitersPerMinute = 88,
  UsGallonsPerMinute = 89,
  DegreesAngular = 90,
  DegreesCelsiusPerHour = 91,
  DegreesCelsiusPerMinute = 92,
  DegreesFahrenheitPerHour = 93,
  DegreesFahrenheitPerMinute = 94,
  NoUnits = 95,
  PartsPerMillion = 96,
  PartsPerBillion = 97,
  Percent = 98,
  PercentPerSecond = 99,
  PerMinute = 100,
  PerSecond = 101,
  PsiPerDegreeFahrenheit = 102,
  Radians = 103,
  RevolutionsPerMinute = 104,
  Currency1 = 105,
  Currency2 = 106,
  Currency3 = 107,
  Currency4 = 108,
  Currency5 = 109,
  Currency6 = 110,
  Currency7 = 111,
  Currency8 = 112,
  Currency9 = 113,
  Currency10 = 114,
  SquareInches = 115,
  SquareCentimeters = 116,
  BtusPerPound = 117,
  Centimeters = 118,
  PoundsMassPerSecond = 119,
  DeltaDegreesFahrenheit = 120,
  DeltaDegreesKelvin = 121,
  Kilohms = 122,
  Megohms = 123,
  Millivolts = 124,
  KilojoulesPerKilogram = 125,
  Megajoules = 126,
  JoulesPerDegreeKelvin = 127,
  JoulesPerKilogramDegreeKelvin = 128,
  Kilohertz = 129,
  Megahertz = 130,
  PerHour = 131,
  Milliwatts = 132,
  Hectopascals = 133,
  Millibars = 134,
  CubicMetersPerHour = 135,
  LitersPerHour = 136,
  KilowattHoursPerSquareMeter = 137,
  KilowattHoursPerSquareFoot = 138,
  MegajoulesPerSquareMeter = 139,
  MegajoulesPerSquareFoot = 140,
  WattsPerSquareMeterDegreeKelvin = 141,
  CubicFeetPerSecond = 142,
  PercentObscurationPerFoot = 143,
  PercentObscurationPerMeter = 144,
  Milliohms = 145,
  MegawattHours = 146,
  KiloBtus = 147,
  MegaBtus = 148,
  KilojoulesPerKilogramDryAir = 149,
  MegajoulesPerKilogramDryAir = 150,
  KilojoulesPerDegreeKelvin = 151,
  MegajoulesPerDegreeKelvin = 152,
  Newton = 153,
  GramsPerSecond = 154,
  GramsPerMinute = 155,
  TonsPerHour = 156,
  KiloBtusPerHour = 157,
  HundredthsSeconds = 158,
  Milliseconds = 159,
  NewtonMeters = 160,
  MillimetersPerSecond = 161,
  MillimetersPerMinute = 162,
  MetersPerMinute = 163,
  MetersPerHour = 164,
  CubicMetersPerMinute = 165,
  MetersPerSecondPerSecond = 166,
  AmperesPerMeter = 167,
  AmperesPerSquareMeter = 168,
  AmpereSquareMeters = 169,
  Farads = 170,
  Henrys = 171,
  OhmMeters = 172,
  Siemens = 173,
  SiemensPerMeter = 174,
  Teslas = 175,
  VoltsPerDegreeKelvin = 176,
  VoltsPerMeter = 177,
  Webers = 178,
  Candelas = 179,
  CandelasPerSquareMeter = 180,
  DegreesKelvinPerHour = 181,
  DegreesKelvinPerMinute = 182,
  JouleSeconds = 183,
  RadiansPerSecond = 184,
  SquareMetersPerNewton = 185,
  KilogramsPerCubicMeter = 186,
  NewtonSeconds = 187,
  NewtonsPerMeter = 188,
  WattsPerMeterPerDegreeKelvin = 189,
  MicroSiemens = 190,
  CubicFeetPerHour = 191,
  UsGallonsPerHour = 192,
  Kilometers = 193,
  Micrometers = 194,
  Grams = 195,
  Milligrams = 196,
  Milliliters = 197,
  MillilitersPerSecond = 198,
  Decibels = 199,
  DecibelsMillivolt = 200,
  DecibelsVolt = 201,
  Millisiemens = 202,
  WattHoursReactive = 203,
  KilowattHoursReactive = 204,
  MegawattHoursReactive = 205,
  MillimetersOfWater = 206,
  PerMille = 207,
  GramsPerGram = 208,
  KilogramsPerKilogram = 209,
  GramsPerKilogram = 210,
  MilligramsPerGram = 211,
  MilligramsPerKilogram = 212,
  GramsPerMilliliter = 213,
  GramsPerLiter = 214,
  MilligramsPerLiter = 215,
  MicrogramsPerLiter = 216,
  GramsPerCubicMeter = 217,
  MilligramsPerCubicMeter = 218,
  MicrogramsPerCubicMeter = 219,
  NanogramsPerCubicMeter = 220,
  GramsPerCubicCentimeter = 221,
  Becquerels = 222,
  Kilobecquerels = 223,
  Megabecquerels = 224,
  Gray = 225,
  Milligray = 226,
  Microgray = 227,
  Sieverts = 228,
  Millisieverts = 229,
  Microsieverts = 230,
  MicrosievertsPerHour = 231,
  DecibelsA = 232,
  NephelometricTurbidityUnit = 233,
  PH = 234,
  GramsPerSquareMeter = 235,
  MinutesPerDegreeKelvin = 236,
  OhmMeterSquaredPerMeter = 237,
  AmpereSeconds = 238,
  VoltAmpereHours = 239,
  KilovoltAmpereHours = 240,
  MegavoltAmpereHours = 241,
  VoltAmpereHoursReactive = 242,
  KilovoltAmpereHoursReactive = 243,
  MegavoltAmpereHoursReactive = 244,
  VoltSquareHours = 245,
  AmpereSquareHours = 246,
  JoulePerHours = 247,
  CubicFeetPerDay = 248,
  CubicMetersPerDay = 249,
  WattHoursPerCubicMeter = 250,
  JoulesPerCubicMeter = 251,
  MolePercent = 252,
  PascalSeconds = 253,
  MillionStandardCubicFeetPerMinute = 254,
  StandardCubicFeetPerDay = 47808,
  MillionStandardCubicFeetPerDay = 47809,
  ThousandCubicFeetPerDay = 47810,
  ThousandStandardCubicFeetPerDay = 47811,
  PoundsMassPerDay = 47812,
  Millirems = 47814,
  MilliremsPerHour = 47815,

  // Values above 256 reserved for vendor-specific units
  LogicValue = 257,
}
