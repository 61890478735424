import { match as routerMatch } from "react-router";
import * as H from "history";
import urljoin from "url-join";
import { marshalNumberId } from "modules/common/utils/relay";

export interface Params {}

export function matchedPathIsSibling(
  sibling: string,
  match: routerMatch<Params>,
  location: H.Location
): boolean {
  const siblingPath = urljoin(match.path, sibling);
  // Include optional trailing slash
  const siblingRegex = new RegExp(siblingPath + "/?");
  return !!location.pathname.match(siblingRegex);
}

export const getOpaqueSiteId = (siteId: string) => marshalNumberId("site", parseInt(siteId, 10));
