import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { AppBar, Divider, Toolbar, Typography, Box, Tooltip } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { NavButton, ToggleNavButton } from "modules/common/components/NavButton/";
import { ReactComponent as Logo } from "./logo.svg";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Link as ExternalLink } from "@mui/material";

const WhiteAppBar = withStyles(
  createStyles({
    root: {
      backgroundColor: "white",
      overflow: "auto",
    },
  })
)(AppBar);

const NavbarComponent = () => {
  return (
    <React.Fragment>
      <WhiteAppBar position="static" elevation={0}>
        <Toolbar>
          <Link to="/sites">
            <Logo />
          </Link>

          {/* Divider */}
          <div
            style={{
              marginLeft: 45,
              marginRight: 45,
              borderLeft: "1px solid #D8D8D8",
              height: 24,
            }}
          />

          <ToggleNavButton
            caption="Sites"
            sitesTo="/sites"
            sitesIconName="map"
            organizationsTo="/organizations"
            organizationsIconName="building"
          />
          <NavButton to="/alerts" caption="Alerts" iconName="bell" />
          <NavButton to="/settings" caption="Settings" iconName="cog" />

          <Box flexGrow={1} />

          <ExternalLink
            href="https://support.turntide.com/hc/en-us/sections/360008469352-BOS"
            color="primary"
            target="_blank"
            rel="noopener"
            style={{ marginRight: 24 }}
          >
            <Tooltip title="Turntide Knowledge Base">
              <HelpOutlineIcon />
            </Tooltip>
          </ExternalLink>
          <Link to="/oauth2/logout" color="black">
            <Tooltip title="Sign out of Turntide">
              <Typography variant="body2">Log out</Typography>
            </Tooltip>
          </Link>
        </Toolbar>
      </WhiteAppBar>
      <Divider />
    </React.Fragment>
  );
};

export const Navbar = withRouter(NavbarComponent);
