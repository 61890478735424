import * as React from "react";
import { gql } from "@apollo/client";
import { useSnackbar } from "notistack";
import { createFragmentContainer } from "modules/common/components/createFragmentContainer";
import { SimpleConfirmationDialog } from "modules/common/components/SimpleConfirmationDialog";
import { useUpgradeDeviceFirmwareMutation } from "modules/common/mutations/UpgradeDeviceFirmwareMutation";
import { UpgradeFirmwareDialog_device } from "generated-gql-types/UpgradeFirmwareDialog_device";

type UpgradeFirmwareDialogProps = {
  open: boolean;
  onClose: () => void;
  device: UpgradeFirmwareDialog_device;
};

function UpgradeFirmwareDialogComponent(props: UpgradeFirmwareDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const upgradeFirmware = useUpgradeDeviceFirmwareMutation();

  return (
    <SimpleConfirmationDialog
      open={props.open}
      onClose={props.onClose}
      title="Upgrade firmware?"
      body={
        <>
          Upgrade {props.device.__typename} <strong>{props.device.displayName}</strong> to latest
          firmware?
        </>
      }
      confirmLabel="Upgrade"
      onConfirm={async () => {
        try {
          await upgradeFirmware({ deviceId: props.device.id });
          enqueueSnackbar("Firmware upgrade requested", { variant: "success" });
          props.onClose();
        } catch (e) {
          enqueueSnackbar("Something went wrong", { variant: "error" });
        }
      }}
    />
  );
}

export const UpgradeFirmwareDialog = createFragmentContainer(UpgradeFirmwareDialogComponent, {
  device: gql`
    fragment UpgradeFirmwareDialog_device on Device {
      id
      __typename
      displayName
    }
  `,
});
